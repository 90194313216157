import { NgModule } from '@angular/core';

import { ComplementaryGoodsFactoryProvider } from './ajs-upgraded-providers';
import { WindowSillsService } from './window-sills/window-sills.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [ComplementaryGoodsFactoryProvider, WindowSillsService],
})
export class ComplementaryGoodsModule {}
