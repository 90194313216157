import angular from 'angular';
import { IssueLevel } from 'issues.service';

/**
 * Fabryka kolorow bram garazowych
 * @param {object} $rootScope               RootScope
 * @param {object} $uibModal                   Dostarcza obsługę okien modalnych
 * @param {object} $filter                  Filtry angularowe
 * @param {object} $location                Obsługa adresów URL
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} ConfiguratorsDataService Fabryka z danymi do konfiguratorów
 * @param {object} CurConfService           Informacje o bieżącym konfiguratorze
 * @param {object} IssuesService            Obługa błędów
 * @param {object} PriceService             Liczenie cen
 * @param {object} Core                     Biblioteka podręcznych funkcji
 */
export default function ColorsFactory($rootScope, $uibModal, $filter, $location, ConfigurationsService, ConfiguratorsDataService, // jshint ignore:line
                       CurConfService, IssuesService, PriceService, Core, EventBusService) {
    'ngInject';

    var configurators = ['garage'];
    var white         = {};
    var windowColorRals = [];
    var defColors     = {outer: {}};

    var factory = {
        garageColors: [],
        colors      : defColors,
        loadedData  : false,

        loadColorsBySystem: loadColorsBySystem,
        loadDefaultData   : loadDefaultData,
        setColor          : setColor,
        openModalColor    : openModalColor,
        validate          : validate
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });

    return factory;

    /**
     * Funkcja uruhamiana po zaladowaniu konfiguratora
     */
    function init() {
        if (configurators.indexOf(CurConfService.conf) === -1) {
            return;
        }
        if (angular.isUndefined(ConfigurationsService.conf)) {
            return;
        }

        IssuesService.addValidateFunction(factory.validate);

        if (angular.isUndefined(ConfigurationsService.conf.Current.Colors.outer)
            && angular.isUndefined(ConfigurationsService.conf.Current.Colors.inner)) {
            loadDefaultData();
        }
        windowColorRals = ConfiguratorsDataService.data.windowColorRals;

        loadColorsBySystem();
        getColorFromVisualizer();
        factory.loadedData = true;
    }

    /**
     * Kolory dla danego systemu
     */
    function loadColorsBySystem() {
        if (angular.isUndefined(ConfigurationsService.conf)) {
            return;
        }
        var allColors = ConfiguratorsDataService.data.garagePanelStructureColors.filter(function(e) {
            return ConfigurationsService.conf.Current.Structure && e.garage_panel_structures.indexOf(ConfigurationsService.conf.Current.Structure.id) >= 0;
        });

        Core.clear(factory.garageColors);
        factory.garageColors = Core.copy(allColors);

        setDefaultColors();
    }

    /**
     * ustawienie domyślnego koloru
     */
    function setDefaultColors() {
        if (angular.isArray(factory.garageColors) && angular.isDefined(ConfigurationsService.conf.Current.System.id)) {
            var visColor = getColorFromVisualizer();
            var allColors = factory.garageColors;
            var visColA = allColors.filter(function(el) {
                return el.color == visColor.toUpperCase() || el.color == visColor.toLowerCase() || el.color_img == visColor;
            });
            var whites = allColors.filter(function(el) {
                return el.color.toLowerCase() == 'ffffff';
            });
            var colorF, whiteF;

            // ustawianie domyślnego koloru
            // wizualizator - biały - pierwszy kolor - pierwszy RAL
            if (angular.isDefined(visColA) && angular.isDefined(visColA[0])) {
                colorF = visColA[0];
            } else if (angular.isDefined(whites) && angular.isDefined(whites[0])) {
                colorF = whites[0];
            } else if (angular.isDefined(factory.garageColors[0])) {
                colorF = factory.garageColors[0];
            }

            setColorSide(defColors.outer, colorF);

            // ustawianie białego koloru
            // biały - pierwszy kolor - pierwszy RAL
            if (angular.isDefined(whites) && angular.isDefined(whites[0])) {
                whiteF = whites[0];
            } else if (angular.isDefined(factory.garageColors[0])) {
                whiteF = factory.garageColors[0];
            }

            setColorSide(white, whiteF);
        }

        if (angular.isDefined(ConfigurationsService.conf.Current.Colors)) {
            if (angular.isDefined(ConfigurationsService.conf.Current.Colors.outer)) {
                if (angular.isUndefined(factory.garageColors.find(el => el.id == ConfigurationsService.conf.Current.Colors.outer.id))) {
                    loadDefaultData();
                }
            }
        }
    }

    function loadDefaultData() {
        ConfigurationsService.conf.Current.Colors = Core.copy(defColors);
        ConfigurationsService.conf.Default.Colors = Core.copy(defColors);

        PriceService.count();
    }

    function setColorSide(object, color) {
        if (angular.isDefined(color)) {
            object.id        = color.id;
            object.color     = color.color;
            object.color_img = color.color_img;
            object.name      = color.name;
            object.group     = color.type;
            object.code      = color.code;

            PriceService.count();
        }
    }

    function setColor(color, type) {
        var object, objectd;
        object  = ConfigurationsService.conf.Current.Colors;
        objectd = ConfigurationsService.conf.Default.Colors;
        if (type == 'RAL') {
            color.type = 'RAL';
        }

        setColorSide(object.outer, color);
        setColorSide(objectd.outer, color);
    }

    function openModalColor(type) {

        var modalInstance = $uibModal.open({
            templateUrl: 'modalGarageColor.html',
            controller : 'ModalColorCtrl as gColor',
            resolve    : {
                colors: function() {
                    return factory.garageColors;
                },
                selColor: function() {
                    return ConfigurationsService.conf.Current.Colors[type];
                },
                type: function() {
                    return type;
                },
                colorGroups: function() {
                    return {};// ConfiguratorsDataService.data.windowColorGroups;
                },
                colorsRAL: function() {
                    return windowColorRals;
                }
            }
        });

        modalInstance.result.then(function(selectedColor) {
            if (angular.isArray(selectedColor)) {
                setColor(selectedColor[0], selectedColor[1]);
            }
        });
    }

    /**
     * Sprawdza, czy został wybrany jakiś kolor
     */
    function validate() {
        if (configurators.indexOf(CurConfService.conf) >= 0) {
            if (ConfigurationsService.conf.Current.Colors.id === null) {
                IssuesService.simpleRegister(
                    'no-garage-color',
                    'Kolor bramy nie został określony.',
                    $filter('translate')('GARAGE|Kolor bramy nie został określony.'),
                    ConfigurationsService.conf.Current,
                    {
                        logLevel: IssueLevel.NONE
                    }
                );
                return false;
            }
        }
        IssuesService.unregister('no-garage-color', ConfigurationsService.conf.Current);
        return true;
    }

    /**
     * Zwraca kolor wybrany z Visualizera
     * @return {string} Kolor wybrany
     */
    function getColorFromVisualizer() {
        var visColors = Core.parseJson(localStorage.getItem('colors'));
        var garageColor = 'FFFFFF';
        if (angular.isString(visColors.brama)) {
            if (visColors.brama.indexOf('okleiny/') > -1) {
                garageColor = visColors.brama.replace('okleiny/', '');
            } else {
                garageColor = visColors.brama.substring(1);
            }

        }
        return garageColor;
    }

}
