import angular from 'angular';

export default function OfferConfiguratorCtrl(
    $rootScope,
    $filter,
    $uibModal,
    $timeout,
    $scope,
    $injector,
    CurConfService,
    Core,
    StepFactory,
    PositionsFactory,
    LayoutFactory,
    ConfigurationsService,
    IssuesService,
    EventBusService,
    SystemsFactory,
    GarageSystemsFactory,
    MosquitoSystemsFactory,
    PriceService
) {
    'ngInject';

    const vm = this;
    vm.openOfferConfModal = openOfferConfModal;
    vm.addConfPosition = addConfPosition;
    vm.loaded = false;

    const subscriptions = [];

    subscriptions.push(
        EventBusService.subscribeWithoutConfiguration('loadedConfiguratorsData', () => {
            vm.loaded = true;
        })
    );

    $scope.$on('$destroy', () => {
        subscriptions.map(el => el.unsubscribe());
    });

    function openOfferConfModal(position, step, getOffer, substep) {
        if (vm.loaded) {
            PriceService.syncingPrice = true;
            PriceService.syncPrice();
            LayoutFactory.autoSelectLayout = false;
            substep = substep || 1;

            if (angular.isUndefined(position.confType)) {
                position.confType = position.configuration.type;
            }

            CurConfService.setConfigurator(position.confType);

            EventBusService.post({
                key: 'initializedConfigurator',
                value: null,
            });

            let templateUrl = 'modalOfferConfigurator.html';
            if (position.confType == 'awning') {
                templateUrl = 'modalOfferConfiguratorAwning.html';
            } else if (position.confType == 'roller_shutter') {
                templateUrl = 'modalOfferConfiguratorRollerShutter.html';
            } else if (position.confType == 'door') {
                templateUrl = 'modalOfferConfiguratorDoor.html';
            } else if (position.confType == 'hs') {
                templateUrl = 'modalOfferConfiguratorHS.html';
            } else if (position.confType == 'garage') {
                templateUrl = 'modalOfferConfiguratorGarage.html';
            } else if (position.confType == 'accessory') {
                templateUrl = 'modalOfferConfiguratorAccessory.html';
            } else if (position.confType == 'complementary_goods') {
                templateUrl = 'modalOfferConfiguratorComplementaryGoods.html';
            } else if (position.confType == 'mosquito') {
                templateUrl = 'modalOfferConfiguratorMosquito.html';
            } else if (position.confType == 'winter_garden') {
                templateUrl = 'modalOfferConfiguratorWinterGarden.html';
            } else if (position.confType == 'coupled_window') {
                templateUrl = 'modalOfferConfiguratorCoupledWindow.html';
            }

            const uibModalInstance = $uibModal.open({
                templateUrl,
                controller: 'ModalOfferConfModal as config',
                windowClass: 'offer-configurator configuratorContent',
                resolve: {
                    stepCode: () => step,
                    subStep: () => substep,
                    position: () => position,
                },
            });

            uibModalInstance.result.then(whereChanges => {
                PriceService.syncingPrice = false;
                if (whereChanges && angular.isFunction(getOffer)) {
                    getOffer();
                }
            });
        }
    }

    function addConfPosition(stepCode) {
        if (vm.loaded) {
            LayoutFactory.autoSelectLayout = ['window', 'hs', 'door', 'folding_door'].includes(
                stepCode
            );
            CurConfService.setConfigurator(stepCode);

            EventBusService.post({
                key: 'initializedConfigurator',
                value: null,
            });

            const uibModalInstance = $uibModal.open({
                templateUrl: 'modalOfferAddForm.html',
                controller: 'ModalOfferAddFormCtrl as addForm',
                windowClass: 'modalOfferAddForm',
                resolve: {
                    offerConf: () => vm,
                    offer: () => $scope.offer,
                    stepCode: () => stepCode,
                },
            });

            uibModalInstance.result.then(success => {
                if (success) {
                    $scope.offersView.getOffer();
                }
            });
        }
    }
}
