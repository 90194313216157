import ComplementaryGoodsFactory from './ComplementaryGoodsFactory';

export function ComplementaryGoodsFactoryFactory(i: any) {
  return i.get('ComplementaryGoodsFactory');
}
export const ComplementaryGoodsFactoryProvider = {
  provide: ComplementaryGoodsFactory,
  useFactory: ComplementaryGoodsFactoryFactory,
  deps: ['$injector']
};
