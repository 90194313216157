import {TimeLimit, TimeLimitSegment} from './time_limit_functions';

export default class TimeLimitGaragePanelStructureColorService {

    constructor(
        private Core
    ) {
        'ngInject';
    }

    /**
     * Pobiera termin realizacji koloru bramy.
     *
     * @param {object} colors   Kolory
     * @return {number} Termin realizacji koloru bramy.
     */
    @TimeLimit({
        shortName: 'garagePanelStructureColor',
        data: {
            colors : 'conf.Colors',
            colorsData: 'data.garagePanelStructureColors'
        }
    })
    garagePanelStructureColorsTimeLimit({timeLimits, colors, colorsData}): TimeLimitSegment[] {
        if (colors == null || !colors.outer || !colors.outer.id) {
            return null;
        }

        const color = this.Core.fIdO(colorsData, colors.outer.id);
        if (!color || !color.time_limit_id) {
            return null;
        }

        return <TimeLimitSegment[]>[{
            type: 'garagePanelStructureColor',
            value: timeLimits[color.time_limit_id],
            data: {
                id  : colors.outer.id,
                name: colors.outer.name,
            }
        }];
    }
}
