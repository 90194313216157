import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import BrowserProfilesService from './profiles.service';
import ProfilesService from 'profiles.service';
import ProfileSetsService from 'profile-sets.service';
import BrowserProfileSetsService from './profile-sets.service';
import { ProfilesModalService } from './profiles-modal.service';

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [],
    exports: [],
    entryComponents: [],
    providers: [
        BrowserProfilesService,
        { provide: ProfilesService, useExisting: BrowserProfilesService },
        { provide: ProfileSetsService, useClass: BrowserProfileSetsService },
        ProfilesModalService,
    ],
})
export class ProfilesModule {}
