import angular from 'angular';

/**
 * Funkcja bezpiecznej szyby
 */
export default function GlassSecurityFilter() {
    'ngInject';

    return function(glass, security, extend) {
        var glassArr = [];
        angular.forEach(glass, function(obj) {
            glassArr.push(obj);
        });
        if (angular.isUndefined(security) || security === null || security === '') {
            return glassArr;
        } else {
            if (extend == 'extend') {
                return glassArr.filter(function(gl) {
                    switch (parseInt(security)) {
                        case 1:
                            if (angular.isDefined(gl.glass_security_inner)) {
                                return parseInt(gl.glass_security_inner) === 0 && parseInt(gl.glass_security_outer) === 0;
                            }
                            break;
                        case 2:
                            if (angular.isDefined(gl.glass_security_inner) && angular.isDefined(gl.glass_security_outer)) {
                                return parseInt(gl.glass_security_inner) > 0 || parseInt(gl.glass_security_outer) > 0;
                            }
                            break;
                        case 3:
                            if (angular.isDefined(gl.glass_security_inner) && angular.isDefined(gl.glass_security_outer)) {
                                return (parseInt(gl.glass_security_inner) == 3 && parseInt(gl.glass_security_outer) == 3)
                                    || (parseInt(gl.glass_security_inner) > 3 || parseInt(gl.glass_security_outer) > 3)
                                        && (parseInt(gl.glass_security_inner) < 6 || parseInt(gl.glass_security_outer) < 6);
                            }
                            break;
                        case 4:
                            if (angular.isDefined(gl.glass_security_inner) && angular.isDefined(gl.glass_security_outer)) {
                                return parseInt(gl.glass_security_inner) == 2 || parseInt(gl.glass_security_outer) == 2;
                            }
                            break;
                        case 5:
                            if (angular.isDefined(gl.glass_security_inner) && angular.isDefined(gl.glass_security_outer)) {
                                return parseInt(gl.glass_security_inner) == 5 || parseInt(gl.glass_security_outer) == 5;
                            }
                            break;
                    }
                    return false;
                });
            } else if (extend == 'extend2') {
                return glassArr.filter(function(gl) {
                    switch (parseInt(security)) {
                        case 1:
                            if (angular.isDefined(gl.glass_security_inner)) {
                                return parseInt(gl.glass_security_inner) === 0 && parseInt(gl.glass_security_outer) === 0;
                            }
                            break;
                        case 4:
                            if (angular.isDefined(gl.glass_security_inner) && angular.isDefined(gl.glass_security_outer)) {
                                return parseInt(gl.glass_security_inner) == 2 || parseInt(gl.glass_security_outer) == 2;
                            }
                            break;
                        case 6:
                            if (angular.isDefined(gl.glass_security_inner) && angular.isDefined(gl.glass_security_outer)) {
                                return (parseInt(gl.glass_security_inner) == 6 || parseInt(gl.glass_security_outer) == 6);
                            }
                            break;
                        case 7:
                            if (angular.isDefined(gl.glass_security_inner) && angular.isDefined(gl.glass_security_outer)) {
                                return parseInt(gl.glass_security_inner) == 7 || parseInt(gl.glass_security_outer) == 7;
                            }
                            break;
                        case 2:
                            if (angular.isDefined(gl.glass_security_inner) && angular.isDefined(gl.glass_security_outer)) {
                                return parseInt(gl.glass_security_inner) == 1 || parseInt(gl.glass_security_outer) == 1;
                            }
                            break;
                    }
                    return false;
                });
            } else {
                return glassArr.filter(function(gl) {
                    switch (parseInt(security)) {
                        case 1:
                            if (angular.isDefined(gl.glass_security_inner)) {
                                return parseInt(gl.glass_security_inner) === 0 && parseInt(gl.glass_security_outer) === 0;
                            }
                            break;
                        case 2:
                            if (angular.isDefined(gl.glass_security_inner)) {
                                return parseInt(gl.glass_security_inner) > 0;
                            }
                            break;
                        case 3:
                            if (angular.isDefined(gl.glass_security_inner) && angular.isDefined(gl.glass_security_outer)) {
                                return parseInt(gl.glass_security_inner) > 0 && parseInt(gl.glass_security_outer) > 0;
                            }
                            break;
                        case 4:
                            if (angular.isDefined(gl.glass_security_inner) && angular.isDefined(gl.glass_security_outer)) {
                                return parseInt(gl.glass_security_inner) > 0 && parseInt(gl.glass_security_outer) >= 4 && parseInt(gl.glass_security_outer) < 6;
                            }
                            break;
                        case 5:
                            if (angular.isDefined(gl.glass_security_inner) && angular.isDefined(gl.glass_security_outer)) {
                                return parseInt(gl.glass_security_inner) == 5 || parseInt(gl.glass_security_outer) == 5;
                            }
                            break;
                    }
                    return false;
                });
            }
        }
    };
}
