import WindowConfiguration from './WindowConfiguration';
import DoorActiveConfiguration from './DoorActiveConfiguration';
import { DoorModel, DoorModelGroup, HandlesType } from './parts/window';

export default class DoorConfiguration extends WindowConfiguration {
    public static is(configuration): configuration is DoorConfiguration {
        return (
            configuration instanceof DoorConfiguration
            || (configuration.$version && !configuration.active && configuration.type === 'door')
        );
    }
    type: 'door' = 'door';

    height = 2000;
    width = 1000;

    model: DoorModel = null;
    modelGroup: DoorModelGroup = null;
    sashesType: 'Fix' | 'FixSash' | 'Func' | 'Mix' = 'Func';
    ownedSashesTypes = {
        window: false,
        doorActive: true,
        doorPassive: false,
    };
    handleType: HandlesType;

    constructor(configuration, dataRequiredToUpdate?: any, conversion = true) {
        super(configuration, dataRequiredToUpdate, false);

        if (DoorConfiguration.is(configuration) && conversion) {
            this.runConversion(configuration, dataRequiredToUpdate);
        } else if (DoorActiveConfiguration.is(configuration)) {
            if (configuration.Model && configuration.Model.id) {
                this.model = {
                    id: Number(configuration.Model.id),
                    name: configuration.Model.name,
                    standardFillings: configuration.Model.standard_fills,
                };
            }
            if (configuration.ModelGroup && configuration.ModelGroup.id) {
                this.modelGroup = {
                    id: Number(configuration.ModelGroup.id),
                    name: configuration.ModelGroup.name,
                };
            }

            this.height = configuration.Height;
            this.sashesType = configuration.SashesType;
            this.width = configuration.Width;
            this.ownedSashesTypes = configuration.OwnedSashesTypes;
        }
    }
}
