import { Component, Input, ViewChildren, ElementRef, OnInit, QueryList } from '@angular/core';
import MeasurementsService from './measurements.service';
import {core} from 'helpers';
import {Inject} from '@angular/core';

@Component({
    selector: 'measurements',
    template: require<string>('./measurements.component.html'),
})
export class MeasurementsComponent {
    measurementNumber = 0;
    measurements = [];

    @ViewChildren('measurementsinput') inputs: QueryList<any>;

    constructor(
        private measurementsService: MeasurementsService,
        @Inject('InfoFactory') private infoFactory,
        @Inject('$translate') private $translate,
    ) {
        this.measurements = this.measurementsService.measurements;
    }

    ngAfterViewInit() {
        this.getDevice();
    }

    async getDevice() {
        try {
            const device = await (navigator as any).bluetooth.requestDevice({
                filters: [{services: ['3ab10100-f831-4395-b29d-570977d5bf94']}]
            });
            this.infoFactory.showInfo(this.$translate.instant('INTERFACE|Parowanie z urządzeniem'), null);
            const server = await device.gatt.connect();
            const service = await server.getPrimaryService('3ab10100-f831-4395-b29d-570977d5bf94');
            const characteristic = await service.getCharacteristic('3ab10101-f831-4395-b29d-570977d5bf94');
            await characteristic.startNotifications();
            this.infoFactory.showInfo(this.$translate.instant('INTERFACE|Sparowano z urządzeniem'), null);
            this.findNextTabStop(null).focus();
            characteristic.addEventListener('characteristicvaluechanged',
                this.handleNotifications.bind(this)
            );
        } catch (error) {
            this.infoFactory.showInfo(this.$translate.instant('INTERFACE|Nie można połączyć się z urządzeniem'), null);
        }
    }

    handleNotifications(event) {
        let value = event.target.value;
        let a = [];
        for (let i = 0; i < value.byteLength; i++) {
            a.push('0x' + ('00' + value.getUint8(i).toString(16)).slice(-2));
        }
        let measurement = core.hexToFloat(core.flipHexString('0x' + (a.join('').replace(/0x/g, '')), 8));
        measurement = Math.round(measurement * 1000);
        const id = document.activeElement.id;
        if (id.includes('-')) {
            const keys = id.split('-');
            this.measurements[this.measurementNumber][keys[0]][keys[1]] = measurement;
        } else {
            this.measurements[this.measurementNumber][id] = measurement;
        }
        const nextElem = this.findNextTabStop(document.activeElement);
        nextElem.focus();
    }

    findNextTabStop(el) {
        const list = this.inputs.toArray().map(e => e.nativeElement);
        const index = list.indexOf(el);
        return list[index + 1] || list[0];
    }

    openModalMountingMethod(measurement) {
        this.measurementsService.openModalMountingMethod(measurement);
    }

    checkField(field) {
        return (
            this.measurements
            && this.measurements[this.measurementNumber]
            && this.measurements[this.measurementNumber].mountingMethod.fields.indexOf(field) > -1
        );
    }

    saveMeasurements() {
        this.measurementsService.saveMeasurements(this.measurements);
    }

    saveAndCalcMeasurements() {
        this.measurementsService.saveAndCalcMeasurements(this.measurements);
    }

    checkMeasurementsWarning() {
        return this.measurementsService.checkMeasurementsWarning();
    }

    discard() {
        this.measurementsService.discard();
    }

    changeFit(event, number, fit) {
        event.srcElement.checked = true;
        this.measurements[number].fit = fit;
    }

    getFit(number) {
        return this.measurements[number].fit;
    }

    addFiles(event) {
        this.measurementsService.addFiles(event, this.measurementNumber);
    }

    downloadFile(file) {
        this.measurementsService.downloadFile(file);
    }

    removeFile(index) {
        this.measurementsService.removeFile(index, this.measurementNumber);
    }
}
