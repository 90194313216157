import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';
import { downgradeInjectable } from '@angular/upgrade/static';

import ProfileSetsService from 'profile-sets.service';
import ProfilesService from 'profiles.service';
import ProfilesFilter from './ProfilesFilter';
import { ProfileSetsModalComponent } from './profile-sets-modal/profile-sets-modal.component';
import { ProfilesModalComponent } from './profiles-modal/profiles-modal.component';
import { ProfilesShapesModalComponent } from './profiles-shapes-modal/profiles-shapes-modal.component';
import { ProfilesPriceService } from 'profiles/profiles-price.service';
import { ProfilesModalService } from './profiles-modal.service';

export default angular
    .module('icc.configurator.steps.window.profiles', [])
    .factory('ProfileSetsService', downgradeInjectable(ProfileSetsService))
    .factory('ProfilesService', downgradeInjectable(ProfilesService))
    .factory('ProfilesPriceService', downgradeInjectable(ProfilesPriceService))
    .factory('ProfilesModalService', downgradeInjectable(ProfilesModalService))
    .component('profileSetsModal', ProfileSetsModalComponent)
    .component('profilesModal', ProfilesModalComponent)
    .component('profilesShapesModal', ProfilesShapesModalComponent)
    .filter('profiles', ProfilesFilter).name;
