import $ from 'jquery';
import angular from 'angular';

export default function ScriptsDirective(Elements, EnvConfig, IccConfig) {
    'ngInject';

    var directive = {
        restrict: 'EA',
        link    : (scope, element) => {
            // GoogleTagManager
            let gtmId;
            if (IccConfig.GTM.allowTags) {
                if (IccConfig.Offer.B2C.marketPerDomain && EnvConfig.domains[window.location.hostname].tagManager != null) {
                    gtmId = EnvConfig.domains[window.location.hostname].tagManager;
                } else {
                    gtmId = IccConfig.Settings.Analytics.tagManager;
                }
            }
            if (gtmId) {
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer',gtmId);

                element.append(angular.element('<noscript></noscript>').append(angular.element('<iframe src="https://www.googletagmanager.com/ns.html?id=' + gtmId + '" height="0" width="0" style="display:none;visibility:hidden"></iframe>')));
            }

            // EuroCookie
            const eurociastko = $.cookie('cookie-zgoda');
            if (eurociastko == null) {
                $('div#eurociastko div.inner > p').html('' + (Elements && Elements.cookies && Elements.cookies.content ? Elements.cookies.content : '')).slideDown(1500);
                $('div#eurociastko').show();
            }

            // Callpage
            if (IccConfig.Configurators.storeCallCenter && IccConfig.Offer.B2C.marketPerDomain && EnvConfig.domains[window.location.hostname].callCenterId) {
                var __cp = { "id": EnvConfig.domains[window.location.hostname].callCenterId, "version": "1.1" };
                (function (window) {
                    var cp = angular.element(document.createElement('script'));
                    cp.attr("src", '//cdn-widget.callpage.io/build/js/callpage.js');
                    element.append(cp);
                    if (window.callpage) {
                        alert('You could have only 1 CallPage code on your website!');
                    } else {
                        window.callpage = function (method) {
                            if (method == '__getQueue') { return this.methods; }
                                else if (method) {
                                    if (typeof window.callpage.execute === 'function') { return window.callpage.execute.apply(this, arguments);
                                    } else {
                                        (this.methods = this.methods || []).push({ arguments: arguments });
                                    }
                                }
                            };
                        window.callpage.__cp = __cp; window.callpage('api.button.autoshow');
                    }
                })(window, document);
            }

            // GoogleAnalytics
            let analyticsId;
            if (IccConfig.Offer.B2C.marketPerDomain && EnvConfig.domains[window.location.hostname].analytics != null) {
                analyticsId = EnvConfig.domains[window.location.hostname].analytics;
            } else if (IccConfig.Settings.Analytics.datasource) {
                analyticsId = IccConfig.Settings.Analytics.datasource;
            }
            if (analyticsId) {
                (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

                ga('create', analyticsId, 'auto');
                ga('send', 'pageview');
            }
        }
    };

    return directive;
}