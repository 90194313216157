import angular from 'angular';
export default function ClientsFactory($q, $rootScope, $filter, $uibModal, $translate, machine, Core, DatabaseManager, UserService, StateService, isElectron, EventBusService) {
    'ngInject';

    var factory = {
        addClient,
        updateClient,
        removeClient,
        listPrivate,
        listCompany,
        list,
        get,
        getMaxReference,
        openClientModal,
        addMulti,
        exportClients,
        importClients,
    };

    return factory;

    /**
     * Konstruktor obiektu klienta
     * @param {object} param parametry nowego klienta
     */
    function Client(param) {
        this.id = param.id || null;
        this.tmp_id = param.tmp_id || 'tmp_client_' + Core.generateUUID();
        this.seller_id = param.seller_id || null;
        this.dealer_seller_id = param.dealer_seller_id || null;
        this.dealer_id = param.dealer_id || null;
        this.clients_type_id = param.clients_type_id || null;
        this.company = param.company || 0;
        this.name = param.name || null;
        this.surname = param.surname || null;
        this.company_name = param.company_name || null;
        this.email = param.email || null;
        this.company_email = param.company_email || null;
        this.phone = param.phone || null;
        this.company_tel = param.company_tel || null;
        this.facture = param.facture || null;
        this.company_facture = param.company_facture || null;
        this.reference = param.reference ? param.reference : 'CLIENT';
        this.nip = param.nip || null;
        this.invoice_data = param.invoice_data || null;
        this.new_client = param.new_client || false;
        this.saldo = param.saldo || 0;
        this.additional_info = param.additional_info || null;
        this.deleted = false;
        this.synced = false;

        this.country = param.country || null;
        this.region = param.region || null;
        this.city = param.city || null;
        this.postcode = param.postcode || null;
        this.street = param.street || null;
        this.adress = param.adress || null;
        this.fax = param.fax || null;
        this.mobile_phone = param.mobile_phone || null;
        this.website = param.website || null;
        this.latitude = param.latitude || null;
        this.longitude = param.longitude || null;
    }

    /**
     * Dodawanie nowego klienta
     * @param  {object} param  elementy klienta
     * @return {object}        Promise
     */
    function addClient(param) {
        var deferred = $q.defer();
        var user = UserService.get();
        var newClient = new Client({
            id              : param.id,
            tmp_id          : param.tmp_id,
            seller_id       : user.access == 'producent' ? Number(user.sellerid) : null,
            dealer_seller_id: user.dealersellerid,
            dealer_id       : param.dealer_id || (user.access != 'producent' ? user.dealerid : null) || null,
            clients_type_id : param.clients_type_id,
            company         : param.company,
            surname         : param.surname,
            name            : param.name,
            email           : param.email,
            phone           : param.phone,
            facture         : param.facture,
            nip             : param.nip,
            invoice_data    : param.invoice_data,
            company_name    : param.company_name,
            company_email   : param.company_email,
            company_tel     : param.company_tel,
            new_client      : param.new_client,
            company_facture : param.company_facture,
            saldo           : param.saldo,
            country         : param.country,
            region          : param.region,
            city            : param.city,
            postcode        : param.postcode,
            street          : param.street,
            adress          : param.adress,
            fax             : param.fax,
            mobile_phone    : param.mobile_phone,
            website         : param.website,
            latitude        : param.latitude,
            longitude       : param.longitude,
            additional_info : param.additional_info
        });
        DatabaseManager.get('Client').create(newClient).then((res) => deferred.resolve(res)).catch(() => {
            updateClient(param._id, param).then((res) => deferred.resolve(res));
        });
        return deferred.promise;
    }

    /**
     * Dodawanie wielu klientów
     * @param {array} clients Klienci
     */
    function addMulti(clients, importing = false) {
        return DatabaseManager.get('Client').createMany(clients, importing);
    }

    /**
     * Edycja klienta
     * @param  {number} _id    Id istniejcego klienta
     * @param  {object} object Nowe dane klienta
     * @return {object}        Promise
     */
    function updateClient(_id, object) {
        var deferred = $q.defer();
        DatabaseManager.get('Client').update(object, {internalId: _id}).then((res) => deferred.resolve(res));
        return deferred.promise;
    }

    /**
     * Usunięcie klienta
     * @param  {number} _id    Id istniejcego klienta
     * @param  {object} object Dane klienta
     * @return {object}        Promise
     */
    function removeClient(_id, object) {
        var deferred = $q.defer();
        DatabaseManager.get('Client').remove(object).then((res) => deferred.resolve(res));
        return deferred.promise;
    }

    /**
     * Lista klientów indywidualnych
     * @return {object}        Promise
     */
    function listPrivate() {
        var deferred = $q.defer();
        var user     = UserService.get();
        let getListUsers;
        if (user.access === 'dealer') {
            getListUsers = DatabaseManager.get('Client').getAll();
        } else if (user.access === 'producent') {
            getListUsers = DatabaseManager.get('Client').getAll('by_seller_id', {key: Number(user.sellerid)});
        } else {
            getListUsers = DatabaseManager.get('Client').getAll('by_dealer_seller_id', {key: user.dealersellerid});
        }
        getListUsers.then((clients) => {

            get(angular.isDefined(StateService.state) && angular.isObject(StateService.state.client) && StateService.state.client.tmp_id
                ? StateService.state.client.tmp_id : '-1'
            ).then(client => {
                if (angular.isDefined(client) && angular.isUndefined(Core.fId(clients, client.tmp_id))) {
                    clients.push({
                        doc: client,
                        id : client.tmp_id
                    });
                }

                let privates = clients.filter((e) => {
                    return e.doc.company == false || e.doc.company === null;
                }).map((e) => {
                    e.doc.saldo = e.doc.saldo * 1;
                    return e;
                }).sort((c1, c2) => new Date(c2.doc.created) - new Date(c1.doc.created));

                deferred.resolve(privates);
            }).catch(err => deferred.reject(err));
        }).catch(err => deferred.reject(err));
        return deferred.promise;
    }

    /**
     * Lista klientów firm
     * @return {object}        Promise
     */
    function listCompany() {
        var deferred = $q.defer();
        var user     = UserService.get();
        let getListUsers;
        if (user.access === 'dealer') {
            getListUsers = DatabaseManager.get('Client').getAll();
        } else if (user.access === 'producent') {
            getListUsers = DatabaseManager.get('Client').getAll('by_seller_id', {key: Number(user.sellerid)});
        } else {
            getListUsers = DatabaseManager.get('Client').getAll('by_dealer_seller_id', {key: user.dealersellerid});
        }
        getListUsers.then((clients) => {
            get(angular.isDefined(StateService.state) && angular.isObject(StateService.state.client) && StateService.state.client.tmp_id
                ? StateService.state.client.tmp_id : '-1'
            ).then(client => {
                if (angular.isDefined(client) && angular.isUndefined(Core.fId(clients, client.tmp_id))) {
                    clients.push({
                        doc: client,
                        id : client.tmp_id
                    });
                }

                let companies = clients.filter((e) => {
                    return e.doc.company == true;
                }).map((e) => {
                    e.doc.saldo = e.doc.saldo * 1;
                    return e;
                }).sort((c1, c2) => new Date(c2.doc.created) - new Date(c1.doc.created));

                deferred.resolve(companies);
            }).catch(err => deferred.reject(err));
        }).catch(err => deferred.reject(err));
        return deferred.promise;
    }

    /**
     * Lista wszystkich klientów
     * @return {object}        Promise
     */
    function list() {
        var deferred = $q.defer();
        var user     = UserService.get();
        let getListUsers;
        if (user.access === 'dealer') {
            getListUsers = DatabaseManager.get('Client').getAll();
        } else if (user.access === 'producent') {
            getListUsers = DatabaseManager.get('Client').getAll('by_seller_id', {key: Number(user.sellerid)});
        } else {
            getListUsers = DatabaseManager.get('Client').getAll('by_dealer_seller_id', {key: user.dealersellerid});
        }
        getListUsers.then((clients) => {
            get(angular.isObject(StateService.state) && angular.isObject(StateService.state.client) && StateService.state.client.tmp_id
                ? StateService.state.client.tmp_id : '-1'
            ).then(client => {
                if (angular.isDefined(client) && angular.isUndefined(Core.fId(clients, client.tmp_id))) {
                    clients.push({
                        doc: client,
                        id : client.tmp_id
                    });
                }

                const sortedClients = clients.sort((c1, c2) => new Date(c2.doc.created) - new Date(c1.doc.created));
                deferred.resolve(sortedClients);
            }).catch(err => deferred.reject(err));
        }).catch(err => deferred.reject(err));
        return deferred.promise;
    }

    /**
     * Dane konkretnego klienta
     * @param  {number} _id Id klienta
     * @return {object}     Promise
     */
    function get(_id) {
        var deferred = $q.defer();
        DatabaseManager.get('Client').get(_id).then((client) => deferred.resolve(client), () => deferred.resolve(undefined));
        return deferred.promise;
    }

    /**
     * Ostatni zajety numer
     * @return {number} ostatnie numer
     */
    function getMaxReference() {
        var deferred = $q.defer();
        var user     = UserService.get();
        let getListUsers;
        if (user.access === 'dealer') {
            getListUsers = DatabaseManager.get('Client').getAll();
        } else if (user.access === 'producent') {
            getListUsers = DatabaseManager.get('Client').getAll('by_seller_id', {key: Number(user.sellerid)});
        } else {
            getListUsers = DatabaseManager.get('Client').getAll('by_dealer_seller_id', {key: user.dealersellerid});
        }
        getListUsers.then((clients) => {
            get(angular.isDefined(StateService.state) && angular.isDefined(StateService.state.client) && StateService.state.client.tmp_id
                ? StateService.state.client.tmp_id : '-1'
            ).then(client => {
                if (angular.isDefined(client) && angular.isUndefined(Core.fId(clients, client.tmp_id))) {
                    clients.push({
                        doc: client,
                        id : client.tmp_id
                    });
                }
                if (clients.length) {
                    deferred.resolve(~~clients.sort((a1, a2) => ~~a1.doc.reference > ~~a2.doc.reference).pop().doc.reference);
                } else {
                    deferred.resolve(0);
                }
            });
        }, () => deferred.reject());
        return deferred.promise;
    }

    /**
     * Otwarcie modala do dodawania klientów
     * @param  {boolean}   company  Czy firma
     * @param  {Function} callback Callback
     */
    function openClientModal(company, callback) {
        var modalInstance = $uibModal.open({
            templateUrl: 'modalNewClient.html',
            controller : 'ModalNewClientCtrl as newClient',
            resolve    : {company}
        });

        modalInstance.result.then((newClient) => {
            if (angular.isObject(newClient)) {
                addClient(newClient).then(() => {
                    callback();
                });
            }
        });
    }

    /**
     * Eksport listy klientów do pliku
     */
    function exportClients() {
        if (isElectron) {
            list().then(clients => {
                const exportedClients = clients.filter(c => c.doc && c.doc.tmp_id && !c.doc.deleted).map(c => {
                    delete c.doc._rev;
                    return c.doc;
                });
                window.ipc.send('save-file', exportedClients, {
                    title: $translate.instant('CLIENT|Eksport listy klientów'),
                    message: $translate.instant('CLIENT|Eksport listy klientów wykonany z sukcesem'),
                });
            });
        }
    }

    /**
     * Import listy klientów z pliku
     */
    function importClients() {
        if (isElectron) {
            window.ipc.send('read-file', 'clients');
            window.ipc.once('read-file-data', (e, id, data) => {
                if (id === 'clients') {
                    addMulti(data, true).then(() => {
                        window.ipc.send('show-message-info', {
                            title: $translate.instant('CLIENT|Import listy klientów'),
                            message: $translate.instant('CLIENT|Import listy klientów wykonany z sukcesem'),
                        });
                        EventBusService.post({
                            key: 'importedClients',
                            value: null
                        });
                    });
                }
            });

        }
    }
}
