import Common from 'Common';
import {Injectable, Inject, Injector, Optional} from '@angular/core';
import {APP_CONFIG, AppConfig} from 'config';
import {logger, core} from 'helpers';
import {EventBusService} from 'event-bus.service';
import UserService from 'user.service';
import ResourceService from '../sync/resource.service';
import * as hash from 'object-hash';
import OnlineStatusService from 'sync/online-status.service';
import {TranslateService} from 'translate.service';

/**
 * Fabryka użytkowników
 * @param {object} $filter             filter
 * @param {object} $q                  q
 * @param {String} USER_ID             id użytkownika
 */
@Injectable()
export default class BrowserUserService extends UserService {

    noCompatibleVersionInfoShowed = false;

    constructor(
        private eventBusService: EventBusService,
        private onlineStatusService: OnlineStatusService,
        private Injector: Injector,
        @Optional() @Inject('InfoFactory') private infoFactory,
        private TranslateService: TranslateService,
        private resourceService: ResourceService,
        @Inject(APP_CONFIG) private config,
    ) {
        super();
        this.loadUser();

        if (!core.isWorker()) {
            window.addEventListener('storage', (event) => {
                if (event.storageArea !== localStorage) {
                    return;
                }
                if (event.key === 'user') {
                    const newUser = core.parseJson(event.newValue);
                    if ((newUser && newUser.id || null) === (this.user && this.user.id || null)) {
                        this.loadUser();
                    }
                }
            });
        }
    }

    /**
     * Ładuje dane użytkownika.
     */
    loadUser() {
        if (core.isWorker()) {
            this.user = this.Injector.get('User');
        } else {
            // nie ma usera w localStorage
            if (!localStorage.getItem('user')
                || localStorage.getItem('user') === 'undefined'
                || localStorage.getItem('user') === 'null'
            ) {
                logger.log('%c USER ', 'border: 3px solid red', 'Brak localStorage.user');
                this.synchronizeUser().then((userFromSync) => {
                    logger.log('%c USER ', 'border: 3px solid red', 'Jest user!', userFromSync);
                    userFromSync.dealer = this.getDealer();
                    userFromSync.dealer_seller = this.getDealerSeller();
                    if (hash(userFromSync) != hash(this.user)) {
                        this.user = userFromSync;
                        this.eventBusService.setCurrentUser(this.user);
                        this.eventBusService.post({
                            key: 'isUser',
                            value: this.user
                        });
                    }
                }).catch(() => {
                    logger.error(new Error('Utracona sesja, przekierowanie'));
                    if (window.cordova) {
                        window.location.hash = '#!/app/login';
                    } else {
                        window.location.pathname = '/app/login';
                    }
                });
            // jest użytkownik w localStorage
            } else {
                const userFromStorage = JSON.parse(localStorage.getItem('user'));
                if (!this.config.USER_ID) {
                    this.config.USER_ID = userFromStorage.id ? userFromStorage.id : '';
                    logger.log('%c USER ', 'border: 3px solid red', 'Brak USER_ID / odtworzone z Session storage');
                }
                if (!this.config.USER_ID) {
                    logger.log('%c USER ', 'border: 3px solid red', 'Brak USER_ID');
                }
                const adminThumb = ~~sessionStorage.getItem('adminThumb');

                if (~~userFromStorage.directadmin === 1 && !~~adminThumb) {
                    window.location.pathname = '/admin';
                }
                userFromStorage.dealer = this.getDealer();
                userFromStorage.dealer_seller = this.getDealerSeller();
                if (hash(userFromStorage) != hash(this.user)) {
                    this.user = userFromStorage;
                    this.eventBusService.setCurrentUser(this.user);
                    this.eventBusService.post({
                        key: 'isUser',
                        value: this.user
                    });
                }
            }
        }
    }

    /**
     * Zwracanie dealera
     * @return {object} Dealer
     */
    getDealer() {
        return localStorage.getItem('dealer') ? JSON.parse(localStorage.getItem('dealer')) : null;
    }

    /**
     * Zwracanie sprzedawcy dealera
     * @return {object}     Sprzedawca dealera
     */
    getDealerSeller() {
        return localStorage.getItem('dealer_seller') ? JSON.parse(localStorage.getItem('dealer_seller')) : null;
    }

    /**
     * Zwracanie sprzedawcy dealera
     * @return {object}     Sprzedawca dealera
     */
    getSeller() {
        return localStorage.getItem('seller') ? JSON.parse(localStorage.getItem('seller')) : null;
    }

    /**
     * Zwracanie klienta
     * @return {object}     Klient
     */
    getClient() {
        return localStorage.getItem('client')
            ? JSON.parse(localStorage.getItem('client'))
            : (localStorage.getItem('client') ? JSON.parse(localStorage.getItem('client')) : null);
    }

    /**
     * Synchronizacja usera
     * @return {object} Promise
     */
    async synchronizeUser() {
        let userData = await this.resourceService.getUserData();
        if (userData.reload) {
            window.location.href = '/';
        }
        if (userData.noCompatibleVersion) {
            this.onlineStatusService.setPermanentOffline();
            if (this.infoFactory && !this.noCompatibleVersionInfoShowed) {
                this.infoFactory.openModal(
                    {
                        title: this.TranslateService.instant('INTERFACE|Nieaktualna wersja aplikacji'),
                        message: userData.noCompatibleVersionInfo
                    }
                );
                this.noCompatibleVersionInfoShowed = true;
            }
        }
        if ((Common.isUndefined(userData) || userData.id === null) && !this.config.IccConfig.Offer.B2C.active) {
            throw new Error('Not logged user and no B2C');
        }

        // user
        if (userData.user_type) {
            localStorage.setItem('user', (JSON.stringify(userData.user_type)));
            localStorage.setItem('offline_profile_' + userData.user_type.email, JSON.stringify(userData.user_type));
        } else {
            localStorage.removeItem('user');
        }

        // dealer
        if (!!userData.dealer) {
            localStorage.setItem('dealer', (JSON.stringify(userData.dealer)));
            localStorage.setItem('dealer_' + userData.dealer.user_id, JSON.stringify(userData.dealer));
        } else {
            localStorage.removeItem('dealer');
        }

        // producent
        if (!!userData.seller) {
            localStorage.setItem('seller', (JSON.stringify(userData.seller)));
            localStorage.setItem('seller_' + userData.seller.user_id, JSON.stringify(userData.seller));
        } else {
            localStorage.removeItem('seller');
        }

        // handlowiec dealera
        if (!!userData.dealer_seller) {
            localStorage.setItem('dealer_seller', (JSON.stringify(userData.dealer_seller)));
            localStorage.setItem('dealer_seller_' + userData.dealer_seller.user_id, JSON.stringify(userData.dealer_seller));
        } else {
            localStorage.removeItem('dealer_seller');
        }

        // handlowiec dealera
        if (!!userData.client) {
            localStorage.setItem('client', (JSON.stringify(userData.client)));
            localStorage.setItem('client_' + userData.client.user_id, JSON.stringify(userData.client));
        } else {
            localStorage.removeItem('client');
        }

        for (const i in userData.user_type) {
            if (!this.user || !this.user.marketId || this.user[i] !== userData.user_type[i]) {
                this.loadUser();
                break;
            }
        }


        return userData;
    }

    setUser(newUser) {
        this.user = newUser;
        this.eventBusService.setCurrentUser(this.user);
        this.eventBusService.post({
            key: 'isUser',
            value: this.user
        });
    }
}
