import $ from 'jquery';
import Common from 'Common';

/**
 * Popup z wyborem koloru
 * @param {object}  $scope             Angular scope
 * @param {object}  $uibModalInstance     Okno modal
 * @param {object}  $timeout           Timeout
 * @param {object}  colorsByTypeFilter Filtr koloru wg typu
 * @param {object}  colors             Kolory
 * @param {object}  colorsRAL          kolory RAl
 * @param {object}  typeColor          Typ koloru
 * @param {object}  selColor           Kolor uszczelki
 * @param {object}  hasRal             Posiada kolor RAl
 * @param {object}  barWidth           Szerokość ramki
 * @param {object}  confColor          Kolor konfiguracja
 */
export default function ModalMuntinsColorCtrl($scope, $uibModalInstance, $timeout, colorsByTypeFilter, colors,
                                selColor, colorGroups, barWidth, muntinType, EventBusService, ScrollbarService) {
    'ngInject';

    var vm = this;
    vm.colors = colors;
    vm.colorGroups = colorGroups;
    vm.selColor = selColor;
    vm.barWidth = barWidth;
    vm.muntinType = muntinType;

    if (Common.isArray(selColor.groups)) {
        const selectedColorGroups = selColor.groups.filter(group => colorGroups.map(gr => Number(gr.id)).includes(Number(group)));
        vm.selectedGroup = Number(selectedColorGroups[0]);
    } else {
        if (colorGroups.length > 0) {
            vm.selectedGroup = Number(colorGroups[0].id);
        } else {
            vm.selectedGroup = 0;
        }
    }

    vm.selectColor = function(color) {
        $uibModalInstance.close(color);
    };

    vm.isSelected = function(color, RAL) {
        if (Common.isDefined(color.id) && Common.isDefined(vm.selColor) && Common.isDefined(vm.selColor.id) && color.id == vm.selColor.id && RAL == color.RAL) {
            return true;
        }
        if (Common.isUndefined(Common.isDefined(vm.selColor) && Common.isDefined(vm.selColor.id) && vm.selColor.id) && color == 'none') {
            return true;
        }
        return false;
    };

    /**
     * Funkcja zamykania okna modal
     */
    vm.close = function() {
        $uibModalInstance.close();
    };

    /**
     * Funkcja odświeżajaca
     */
    vm.refresh = function() {
        ScrollbarService.update();
    };
}
