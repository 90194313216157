export enum IssueLevel {
    NONE,
    DEBUG,
    INFO,
    WARN,
    ERROR,
    FATAL,
}

export class Issue {
    key: string;
    message: string;
    description: string;
    level: IssueLevel = IssueLevel.ERROR;
    logLevel: IssueLevel = IssueLevel.ERROR;
    showMessage: boolean = true;
    blockAddToOffer: boolean = true;
    blockStepsAfter: string | null = null;
    noPrice: boolean = false;
    tags: {
        [id: string]: string;
    } = {};
    extra: any = null;

    constructor({
        key,
        message,
        description,
        level = IssueLevel.ERROR,
        logLevel = IssueLevel.ERROR,
        showMessage = true,
        blockAddToOffer = true,
        blockStepsAfter = null,
        noPrice = false,
        tags = {},
        extra = null,
    }: {
        key: string;
        description: string;
        message?: string;
        level?: IssueLevel;
        logLevel?: IssueLevel;
        showMessage?: boolean;
        blockAddToOffer?: boolean;
        blockStepsAfter?: string | null;
        noPrice?: boolean;
        tags?: {
            [id: string]: string;
        };
        extra?: any;
    }) {
        this.key = key;
        this.description = description;
        this.message = message || description;
        this.level = level;
        this.logLevel = logLevel == null ? level : logLevel;
        this.showMessage = showMessage;
        this.blockAddToOffer = blockAddToOffer;
        this.blockStepsAfter = blockStepsAfter;
        this.noPrice = noPrice;
        this.tags = tags;
        this.extra = extra;
    }
}
