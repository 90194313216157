import {TimeLimit, TimeLimitSegment} from './time_limit_functions';

export default class TimeLimitSealColorService {

    /**
     * Pobiera termin realizacji koloru uszczelki.
     *
     * @param {object} sealColor   Kolor uszczelki
     * @return {number} Termin realizacji koloru uszczelki.
     */
    @TimeLimit({
        shortName: 'sealColor',
        data: {
            sealColor : 'conf.SealColor',
        }
    })
    sealColorTimeLimit({timeLimits, sealColor}): TimeLimitSegment[] {
        if (sealColor == null || !sealColor.id || !sealColor.time_limit_id) {
            return null;
        }

        const timeLimitId = sealColor.time_limit_id;

        return <TimeLimitSegment[]>[{
            type: 'sealColor',
            value: timeLimits[timeLimitId],
            data: {
                id  : sealColor.id,
                name: sealColor.name,
            }
        }];
    }
}
