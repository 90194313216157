import { core } from 'helpers';
import Common from 'Common';
import { IccSill } from 'data-types';

export default class SillsModalController {
    resolve: {
        selectedOuterSill: IccSill;
        selectedInnerSill: IccSill;
    };
    close: ({ $value: any }) => any;
    dismiss: () => any;

    sideTab: 'outer' | 'inner' = 'inner';

    selectedSill = {
        outer: null,
        inner: null,
    };

    $onInit() {
        this.selectedSill.outer = this.resolve.selectedOuterSill;
        this.selectedSill.inner = this.resolve.selectedInnerSill;
    }

    changeTab(tab: 'outer' | 'inner') {}

    getSelectedSillId() {
        return this.selectedSill[this.sideTab] && this.selectedSill[this.sideTab].id;
    }

    closeModal() {
        this.close({ $value: this.selectedSill });
    }

    setSill(sill: IccSill) {
        this.selectedSill[this.sideTab] = sill;
    }
}
SillsModalController.$inject = [];
