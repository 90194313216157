import angular from 'angular';

/**
 * Kontroler napedow
 * @param {object} DrivesFactory Fabryka naoędów
 * @param {object} PriceService  Liczenie cen
 */
export default function DrivesCtrl($scope, $filter, $rootScope, Core, DrivesFactory, PriceService, ConfigurationsService, RollerDimensionsFactory, EventBusService, DrawService, IccConfig) {
    'ngInject';

    var vm = this;
    vm.subStep = 1;
    vm.driveManuals           = [];
    vm.driveManuals           = [];
    vm.hangers                = [];
    vm.hanger                 = null;
    vm.changeHanger           = changeHanger;
    vm.changeOverride         = changeOverride;
    vm.openModalDrives        = DrivesFactory.openModalDrives;
    vm.checkOverride          = DrivesFactory.checkOverride;
    vm.openModalDriveElements = DrivesFactory.openModalDriveElements;
    vm.openModalDrivesManuals = DrivesFactory.openModalDrivesManuals;
    vm.setDrive               = DrivesFactory.setDrive;
    vm.driveType              = DrivesFactory.driveType;
    vm.changeDriveType        = DrivesFactory.changeDriveType;
    vm.changeDriveManual      = DrivesFactory.changeDriveManual;
    vm.driveManualType        = null;
    vm.showDriveElectrical    = DrivesFactory.showDriveElectrical();
    vm.driveElectricalWidth   = {
        width: null
    };
    vm.drawOptions = angular.merge({}, DrawService.options, {
        extendedRollerInWindow: IccConfig.Configurators.extendedRollerInWindow,
    });

    vm.goToSubstep = function(subStep) {
        vm.subStep = subStep;
    };

    vm.countPrice = function() {
        PriceService.count();
    };

    if (DrivesFactory.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        vm.driveManuals = DrivesFactory.driveManuals.reduce((prev, cur) => {
            if (!cur.type || cur.type == 'other') {
                prev.push(cur);
            } else if (cur.type.indexOf('retractor') > -1 && prev.filter(p => p.type == 'retractor').length === 0) {
                prev.push({
                    id      : 'r',
                    price   : '0',
                    type    : 'retractor',
                    name    : $filter('translate')('ROLLERSHUTTER|Zwijacz taśmy'),
                });
            }
            return prev;
        }, []);
        if (vm.driveManuals.length > 0) {
            let manualType = vm.driveManuals[0];
            if (ConfigurationsService.conf.Current.RollerShutter.driveManual
                && ConfigurationsService.conf.Current.RollerShutter.driveManual.type
                && ConfigurationsService.conf.Current.RollerShutter.driveManual.type.indexOf('retractor') > -1){
                manualType = Core.fIdO(vm.driveManuals, 'retractor', 'type');
            }
            vm.driveManualType = manualType.id;
        }
        vm.showDriveElectrical  = DrivesFactory.showDriveElectrical();
        vm.driveElectricalWidth = {
            width: DrivesFactory.getDriveElectricalMinWidth()
        };

        vm.hangers = DrivesFactory.hangers;
        vm.hanger = ConfigurationsService.conf.Current.RollerShutter.hanger ? ConfigurationsService.conf.Current.RollerShutter.hanger.id : null;

        RollerDimensionsFactory.updateDimensions();
    }

    function changeHanger() {
        DrivesFactory.changeHanger(vm.hanger);
    }

    function changeOverride() {
        DrivesFactory.changeOverrideHanger();
        PriceService.count();
    }
}
