import angular from 'angular';
import { logger } from 'helpers';
import { ActiveMullion } from 'layout/active-mullion';
import { ActiveSash } from 'layout/active-sash';
import { IssueLevel } from 'issues.service';

/**
 * Fabryka szablonu
 * @param {Object} $rootScope               rootScope
 * @param {Object} $filter                  filter
 * @param {Object} IccConfig                IccConfig
 * @param {Object} Core                     Core
 * @param {Object} ConfiguratorsDataService ConfiguratorsDataService
 * @param {Object} ConfigurationsService    ConfigurationsService
 * @param {Object} CurConfService           CurConfService
 * @param {Object} SashesLayoutService      SashesLayoutService
 * @param {Object} MullionsLayoutService    MullionsLayoutService
 * @param {Object} DrawDataService          DrawDataService
 * @param {Object} HandlesFactory           HandlesFactory
 * @param {Object} InfoFactory              InfoFactory
 * @param {Object} IssuesService            IssuesService
 * @param {Object} GlazingFactory           GlazingFactory
 * @param {Object} DatabaseManager          DatabaseManager
 * @param {Object} PriceService             PriceService
 * @param {Object} DimensionsFactory        DimensionsFactory
 * @param {Object} StepFactory              StepFactory
 * @param {Object} ParametersService        ParametersService
 * @param {Object} MuntinsFactory           MuntinsFactory
 */
export default function LayoutFactory(
    $rootScope,
    $filter,
    $translate,
    IccConfig,
    Core,
    ConfiguratorsDataService,
    ConfigurationsService,
    CurConfService,
    SashesLayoutService,
    HandlesFactory,
    InfoFactory,
    IssuesService,
    GlazingFactory,
    PriceService,
    DimensionsFactory,
    StepFactory,
    ParametersService,
    MuntinsFactory,
    ShapeService,
    WarrantyFactory,
    ConstructionLimitationFactory,
    LocksFactory,
    ProfilesService,
    EventBusService,
    ValidationService,
    FramesService,
    SashTypesService,
    ConstructionCodeService
) {
    'ngInject';

    var passiveSashTypes = ['F', 'FF', 'OFF', 'DS', 'ODS', 'DSC', 'DRP', 'DOP'];

    var factory = {
        dividers: [],
        sashTypes: [],
        defaultLayouts: [],
        autoSelectLayout: false,
        passiveSashTypes,
        prepareSashesLayout,
        prepareWindowSashesData,
        validateLayout,
        recalc,
        selectLayoutBySizes,
        resetLayout,
        resetDimensions,
        selectLayout,
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });

    $rootScope.$on('changedStep', function onChangedStep(event, newVal, oldVal) {
        if (
            newVal.code != 'sashes'
            && StepFactory.getStepByCode('sashes') > -1
            && newVal.i != StepFactory.getStepByCode('sashes')
            && newVal.code != oldVal.code
        ) {
            if (newVal.i <= StepFactory.getStepByCode('sashes') || validateLayout()) {
                $rootScope.hideDraw = true;
            } else {
                StepFactory.selectStep(oldVal.i);
            }
        }
    });

    return factory;

    /**
     * Funkcja inicjujaca
     */
    function init() {
        IssuesService.addValidateFunction(factory.validateLayout);

        factory.sashTypes = ConfiguratorsDataService.data.sashTypes;
        factory.dividers = ConfiguratorsDataService.data.dividers || [];
        factory.defaultLayouts = ConfiguratorsDataService.layouts || [];
        factory.defaultVariants = ConfiguratorsDataService.layoutsVariants || [];
        var x;
        var orderdata = [];
        var dt;
        var searchers = [];

        // zapisz do tablicy dane pod podane identyfikatory
        for (x = 0; x < factory.defaultLayouts.length; ++x) {
            searchers[~~factory.defaultLayouts[x].SashesLayoutsVariant.id] =
                factory.defaultLayouts[x];
        }
        // posortuj względem wymiarów
        for (x = 0; x < factory.defaultVariants.length; ++x) {
            if (angular.isUndefined(orderdata[x])) {
                orderdata[x] = [];
            }
            dt = factory.defaultVariants[x].SashesLayoutsVariantsChoice;
            orderdata[~~dt.start_y][~~dt.start_x] = factory.defaultVariants[x];
            orderdata[~~dt.start_y][~~dt.start_x].sdm = searchers[~~dt.sashes_layouts_variant_id];
        }

        // zapisz posortowane dane
        factory.orderVariants = orderdata;

        if (factory.autoSelectLayout) {
            selectLayoutBySizes();
        }
    }

    function selectLayout(layout, auto = false) {
        const conf = ConfigurationsService.conf.Current;
        const preparedLayout = prepareSashesLayout(layout);

        if (auto || !conf.Layout || conf.Sashes.length === 0) {
            selectWindowSashesLayout(preparedLayout);
        } else {
            const currentCode = ConstructionCodeService.getConstructionCode({
                width: conf.Width,
                height: conf.Height,
                sashes: conf.Sashes,
                dividers: conf.Mullions,
                frames: conf.Frames,
                couplings: conf.couplings,
                type: conf.type,
                conf,
            }).code.replace(/\[[A-Z]+\]/g, '[▣]');
            const canBeReplaced = currentCode === preparedLayout.layoutBaseCode;
            InfoFactory.confirmModal(
                $translate.instant('WINDOW|Zmiana układu konstrukcji'),
                canBeReplaced
                    ? $translate.instant(
                          'WINDOW|Zmiana układu może zresetować ustawienia wypełnień, klamek i szprosów w skrzydłach. Czy zresetować te ustawienia?'
                      )
                    : $translate.instant(
                          'WINDOW|Zmiana układu zresetuje ustawienia wypełnień, klamek i szprosów w skrzydłach.'
                      ),
                [
                    {
                        name: canBeReplaced
                            ? $translate.instant('INTERFACE|Tak')
                            : $translate.instant('INTERFACE|Ok'),
                        callback: () => {
                            selectWindowSashesLayout(preparedLayout);
                        },
                    },
                    ...(canBeReplaced
                        ? [
                              {
                                  name: $translate.instant('INTERFACE|Nie'),
                                  callback: () => {
                                      replaceSashTypes(preparedLayout, conf);
                                  },
                              },
                          ]
                        : []),
                    {
                        name: $translate.instant('INTERFACE|Anuluj'),
                        callback: () => {},
                        accent: true,
                    },
                ]
            );
        }
    }

    /**
     * Wybiera wariant układ który pasuje do danego rozmiaru.
     * Warianty mają maksymalne wymiay do któryhc pasują.
     * Jeżeli nie znajdzie pasującego wariantu wybiera pierwszy dostępny
     */
    function selectLayoutBySizes() {
        // wpisana wysokość i szerokość
        var width = ConfigurationsService.conf.Current.Width;
        var height = ConfigurationsService.conf.Current.Height;

        // konfiguracja
        var conf = IccConfig.Configurators.window.variantsChoice;

        // odejmij start (poniżej start wymiary powinny być złe) i podziel przez krok, wyjdzie indeks
        width = Math.floor((width - conf.start_x) / conf.krok_x);
        height = Math.floor((height - conf.start_y) / conf.krok_y);

        // wybierz dostępny wariant
        if (
            angular.isDefined(factory.orderVariants[height])
            && angular.isDefined(factory.orderVariants[height][width])
            && angular.isDefined(factory.orderVariants[height][width].sdm)
            && ConfigurationsService.conf.Current.type == 'window'
        ) {
            factory.selectLayout(factory.orderVariants[height][width].sdm, true);
            return;
        }

        // domyślny wariant
        var filteredLayouts = factory.defaultLayouts.filter(function defaultLayoutsFilter(e) {
            return e.SashesLayout.type == ConfigurationsService.conf.Current.type;
        });
        if (ConfigurationsService.conf.Current.Sashes) {
            factory.selectLayout(
                filteredLayouts.length ? filteredLayouts[0] : factory.defaultLayouts[0],
                true
            );
        }
    }

    function indexSashes(branch, index) {
        branch.sashesIds = [];
        for (let i = 0; i < branch.dimensions.length; i++) {
            if (branch.branches[i]) {
                branch.sashesIds.push(-1);
                indexSashes(branch.branches[i], index);
            } else {
                branch.sashesIds.push(index.i);
                index.i++;
            }
        }
    }

    function prepareDividersFromCode(branch, allDivs, divId) {
        const direction = branch.division === '_' ? 'horizontal' : 'vertical';

        for (let i = 0; i < branch.branches.length - 1; i++) {
            const topLeftSashes = [];
            const bottomRightSashes = [];
            if (!branch.branches[i]) {
                topLeftSashes.push(branch.sashesIds[i]);
            } else {
                branch.branches[i].branches.forEach((el, j) => {
                    if (!el) {
                        topLeftSashes.push(branch.branches[i].sashesIds[j]);
                    } else {
                        topLeftSashes.push(el.sashesIds[el.sashesIds.length - 1]);
                    }
                });
            }
            if (!branch.branches[i + 1]) {
                bottomRightSashes.push(branch.sashesIds[i + 1]);
            } else {
                branch.branches[i + 1].branches.forEach((el, j) => {
                    if (!el) {
                        bottomRightSashes.push(branch.branches[i + 1].sashesIds[j]);
                    } else {
                        bottomRightSashes.push(el.sashesIds[0]);
                    }
                });
            }
            allDivs.push({
                id: divId.i,
                position: 1,
                length: 1,
                sashes: {
                    top: direction === 'horizontal' ? topLeftSashes : [],
                    bottom: direction === 'horizontal' ? bottomRightSashes : [],
                    left: direction === 'vertical' ? topLeftSashes : [],
                    right: direction === 'vertical' ? bottomRightSashes : [],
                },
                divs: {
                    top: [],
                    bottom: [],
                    left: [],
                    right: [],
                },
                direction,
            });
            divId.i++;
        }
        branch.branches.forEach(el => {
            if (el) {
                prepareDividersFromCode(el, allDivs, divId);
            }
        });
    }

    function prepareLayoutFromCode(
        branch,
        width,
        height,
        constructionWidth,
        constructionHeight,
        allDivs,
        sashTypes,
        sashes,
        error
    ) {
        const wholeDimension = branch.division === '_' ? height : width;
        let remainingDimension = wholeDimension;
        let flexibleLength = 0;
        let flexibleLengthValue = 0;
        branch.layoutBaseCode = '';

        branch.dimensions.forEach(el => {
            if (String(el).includes('mm')) {
                remainingDimension -= Number(el.replace('mm', ''));
            } else {
                flexibleLength += Number(el);
            }
        });
        if (flexibleLength > 0) {
            flexibleLengthValue = remainingDimension / flexibleLength;
        }
        let noMMIndex = 0;
        let setDimension = 0;
        branch.dimensions.forEach((el, index) => {
            if (String(el).includes('mm')) {
                branch.dimensions[index] = Number(el.replace('mm', ''));
            } else {
                noMMIndex = index;
                branch.dimensions[index] = Math.round(flexibleLengthValue * el);
                if (branch.dimensions[index] < IccConfig.Configurators.minWidth) {
                    error.value = true;
                }
            }
            setDimension += branch.dimensions[index];
        });
        if (setDimension !== wholeDimension && setDimension > 0) {
            branch.dimensions[noMMIndex] += wholeDimension - setDimension;
        }

        for (let i = 0; i < branch.dimensions.length; i++) {
            const sashId = branch.sashesIds[i];
            if (sashId > -1) {
                const s = sashTypes[sashId];
                const neighbors = {
                    top: [],
                    left: [],
                    right: [],
                    bottom: [],
                };
                const divs = {
                    top: -1,
                    left: -1,
                    right: -1,
                    bottom: -1,
                };

                allDivs.forEach(div => {
                    if (div.sashes.bottom.indexOf(sashId) > -1) {
                        divs.top = div.id;
                        neighbors.top.push(...div.sashes.top);
                    } else if (div.sashes.top.indexOf(sashId) > -1) {
                        divs.bottom = div.id;
                        neighbors.bottom.push(...div.sashes.bottom);
                    } else if (div.sashes.left.indexOf(sashId) > -1) {
                        divs.right = div.id;
                        neighbors.right.push(...div.sashes.right);
                    } else if (div.sashes.right.indexOf(sashId) > -1) {
                        divs.left = div.id;
                        neighbors.left.push(...div.sashes.left);
                    }
                });

                branch.layoutBaseCode += (i > 0 ? branch.division : '') + '[▣]';

                sashes.push({
                    id: sashId,
                    type: s.type,
                    active: !s.passive,
                    side: s.handle_position === 'L' ? 'R' : s.handle_position === 'R' ? 'L' : null,
                    height:
                        (branch.division === '_' ? branch.dimensions[i] : height)
                        / constructionHeight,
                    width:
                        (branch.division === '|' ? branch.dimensions[i] : width)
                        / constructionWidth,
                    level: s.level,
                    frame: {
                        top: null,
                        bottom: null,
                        left: null,
                        right: null,
                    },
                    neighbors,
                    divs,
                });
            } else {
                prepareLayoutFromCode(
                    branch.branches[i],
                    branch.division === '|' ? branch.dimensions[i] : width,
                    branch.division === '_' ? branch.dimensions[i] : height,
                    constructionWidth,
                    constructionHeight,
                    allDivs,
                    sashTypes,
                    sashes,
                    error
                );
                branch.layoutBaseCode +=
                    (i > 0 ? branch.division : '') + '(' + branch.branches[i].layoutBaseCode + ')';
            }
        }
    }

    function hasSashType(branch, sashType, sashes, hasType) {
        if (branch.sashesIds && branch.sashesIds.some(el => sashes[el].type == sashType)) {
            hasType.value = true;
        } else {
            branch.branches.forEach(el => hasSashType(el, sashType, sashes, hasType));
        }
    }

    /**
     * Funkcja przygotowania szablonu skrzydeł
     * @param  {Object} sash Skrzydło
     * @return {Object}      Szablon
     */
    function prepareSashesLayout(sash) {
        if (
            !ConfigurationsService.conf
            || !ConfigurationsService.conf.Current
            || !sash
            || !sash.SashesLayout
            || !sash.SashesLayoutsVariant
        ) {
            return;
        }

        let transomsOnSeperateFrames = false;

        if (
            sash.Neighbours
            && sash.Neighbours.middle.some(s => s.type === 'DOA')
            && (sash.Neighbours.middle.some(s => s.type === 'F')
                || sash.Neighbours.top.some(s => s.type === 'F')
                || sash.Neighbours.bottom.some(s => s.type === 'F'))
            && ConfigurationsService.conf.Current.DoorSide
            && sash.SashesLayoutsVariant.side
        ) {
            transomsOnSeperateFrames = true;
            InfoFactory.openWarning(
                $filter('translate')(
                    'WINDOW|Naświetla na osobnych ramach. Wymagane łączniki, do dokupienia w kroku dodatki.'
                )
            );
        }

        if (ConfigurationsService.conf.Current.Shape.shape != sash.SashesLayout.shape_id) {
            DimensionsFactory.setShape(
                sash.SashesLayout.shape_id,
                ConfigurationsService.conf.Current
            );
            DimensionsFactory.setShapeData();
        }

        const Layout = {
            id: sash.SashesLayoutsVariant.id,
            conf: sash.SashesLayout.type,
            countSashes: sash.SashesLayout.all_sashes_count,
            name: sash.SashesLayoutsVariant.name,
            shape: sash.SashesLayout.shape_id,
            sashes: [],
            divs: [],
            changed: false,
            fromCode: false,
            layoutCode: null,
            layoutBaseCode: null,
            sashTypes: sash.Sashes,
            transomsOnSeperateFrames,
        };

        let layoutBaseCode = '';

        if (sash.SashesLayout.layout === 'code' && sash.SashesLayout.layout_code) {
            const sashes = [];
            const divs = [];
            let index = { i: 0 };
            const error = { value: 0 };
            const schema = Core.copy(sash.SashesLayout.layout_code);
            Layout.layoutCode = Core.copy(sash.SashesLayout.layout_code);
            indexSashes(schema, index);
            index = { i: 0 };
            prepareDividersFromCode(schema, divs, index);
            divs.forEach(div => {
                if (div.sashes[div.direction === 'horizontal' ? 'top' : 'left'].length >= 2) {
                    for (
                        let i = 0;
                        i < div.sashes[div.direction === 'horizontal' ? 'top' : 'left'].length - 1;
                        i++
                    ) {
                        div.divs[div.direction === 'horizontal' ? 'top' : 'left'].push(
                            divs.find(
                                el =>
                                    el.sashes[
                                        div.direction === 'horizontal' ? 'left' : 'top'
                                    ].indexOf(
                                        div.sashes[div.direction === 'horizontal' ? 'top' : 'left'][
                                            i
                                        ]
                                    ) > -1
                                    && el.sashes[
                                        div.direction === 'horizontal' ? 'right' : 'bottom'
                                    ].indexOf(
                                        div.sashes[div.direction === 'horizontal' ? 'top' : 'left'][
                                            i + 1
                                        ]
                                    ) > -1
                            ).id
                        );
                    }
                }
                if (div.sashes[div.direction === 'horizontal' ? 'bottom' : 'right'].length >= 2) {
                    for (
                        let i = 0;
                        i
                        < div.sashes[div.direction === 'horizontal' ? 'bottom' : 'right'].length
                            - 1;
                        i++
                    ) {
                        div.divs[div.direction === 'horizontal' ? 'bottom' : 'right'].push(
                            divs.find(
                                el =>
                                    el.sashes[
                                        div.direction === 'horizontal' ? 'left' : 'top'
                                    ].indexOf(
                                        div.sashes[
                                            div.direction === 'horizontal' ? 'bottom' : 'right'
                                        ][i]
                                    ) > -1
                                    && el.sashes[
                                        div.direction === 'horizontal' ? 'right' : 'bottom'
                                    ].indexOf(
                                        div.sashes[
                                            div.direction === 'horizontal' ? 'bottom' : 'right'
                                        ][i + 1]
                                    ) > -1
                            ).id
                        );
                    }
                }
            });
            prepareLayoutFromCode(
                schema,
                ConfigurationsService.conf.Current.Width,
                ConfigurationsService.conf.Current.Height,
                ConfigurationsService.conf.Current.Width,
                ConfigurationsService.conf.Current.Height,
                divs,
                sash.Sashes,
                sashes,
                error
            );

            if (error.value) {
                InfoFactory.openWarning(
                    $filter('translate')('CONFIGURATOR|Podane wymiary są nieprawidłowe.')
                );
            } else {
                if (
                    ConfigurationsService.conf.Current.DoorSide
                    && sash.SashesLayoutsVariant.side
                    && schema.division === '_'
                ) {
                    let hasType = { value: false };
                    for (let i = 1; i < schema.branches.length; i++) {
                        hasSashType(schema.branches[i], 'DOA', sashes, hasType);
                        if (hasType.value) {
                            break;
                        }
                    }
                    if (hasType.value) {
                        hasType = { value: false };
                        for (let i = 0; i < schema.branches.length; i++) {
                            hasSashType(schema.branches[i], 'F', sashes, hasType);
                            if (hasType.value) {
                                Layout.transomsOnSeperateFrames = true;
                                InfoFactory.openWarning(
                                    $filter('translate')(
                                        'WINDOW|Naświetla na osobnych ramach. Wymagane łączniki, do dokupienia w kroku dodatki.'
                                    )
                                );
                                break;
                            }
                        }
                    }
                }

                Layout.sashes = sashes;
                Layout.divs = divs;
                Layout.countSashes = sashes.length;
                Layout.fromCode = true;
                Layout.layoutBaseCode = schema.layoutBaseCode;
            }
        } else {
            let k, s;
            let defaultSashHeightRatio = 0.2;

            if (sash.Neighbours.top.length > 0) {
                layoutBaseCode += '(';
            }

            for (k = 0; k < sash.Neighbours.top.length; k++) {
                s = sash.Neighbours.top[k];
                if (s.type === 'K') {
                    defaultSashHeightRatio =
                        Math.round((500 / ConfigurationsService.conf.Current.Height) * 100) / 100;
                }

                Layout.sashes.push({
                    id: ~~s.position,
                    type: s.type,
                    active: !s.passive,
                    side: s.handle_position === 'L' ? 'R' : s.handle_position === 'R' ? 'L' : null,
                    height: defaultSashHeightRatio,
                    width: 1 / sash.Neighbours.top.length,
                    level: s.level,
                    frame: {
                        top: null,
                        bottom: null,
                        left: null,
                        right: null,
                    },
                    neighbors: {
                        top: [],
                        left: k === 0 || sash.Neighbours.top.length === 1 ? [] : [~~s.position - 1],
                        right:
                            k + 1 === sash.Neighbours.top.length || sash.Neighbours.top.length === 1
                                ? []
                                : [~~s.position + 1],
                        bottom: sash.Neighbours.middle.map(function mapNeighbours(e) {
                            return ~~e.position;
                        }),
                    },
                    divs: {
                        top: -1,
                        left: k === 0 || sash.Neighbours.top.length === 1 ? -1 : ~~s.position - 1,
                        right:
                            k + 1 === sash.Neighbours.top.length || sash.Neighbours.top.length === 1
                                ? -1
                                : ~~s.position,
                        bottom: sash.Neighbours.top.length - 1,
                    },
                });

                if (k > 0) {
                    Layout.divs[~~s.position - 1] = {
                        id: ~~s.position - 1,
                        direction: 'vertical',
                        position: (1 / sash.Neighbours.top.length) * k,
                        length: 1,
                        sashes: {
                            top: [],
                            bottom: [],
                            left: [~~s.position - 1],
                            right: [~~s.position],
                        },
                        divs: {
                            top: [],
                            bottom: [],
                            left: [],
                            right: [],
                        },
                    };
                    layoutBaseCode += '|';
                }

                layoutBaseCode += '[▣]';
            }
            if (sash.Neighbours.top.length > 0 && sash.Neighbours.middle.length > 0) {
                layoutBaseCode += ')_(';
            }

            for (k = 0; k < sash.Neighbours.middle.length; k++) {
                s = sash.Neighbours.middle[k];
                Layout.sashes.push({
                    id: ~~s.position,
                    type: s.type,
                    active: !s.passive,
                    side: s.handle_position === 'L' ? 'R' : s.handle_position === 'R' ? 'L' : null,
                    height:
                        1
                        - (sash.Neighbours.top.length > 0 ? defaultSashHeightRatio : 0)
                        - (sash.Neighbours.bottom.length > 0 ? 0.2 : 0),
                    width: 1 / sash.Neighbours.middle.length,
                    level: s.level,
                    frame: {
                        top: null,
                        bottom: null,
                        left: null,
                        right: null,
                    },
                    neighbors: {
                        top: sash.Neighbours.top.map(function mapNeighbours(e) {
                            return ~~e.position;
                        }),
                        left:
                            k === 0 || sash.Neighbours.middle.length === 1
                                ? []
                                : [~~s.position - 1],
                        right:
                            k + 1 === sash.Neighbours.middle.length
                            || sash.Neighbours.middle.length === 1
                                ? []
                                : [~~s.position + 1],
                        bottom: sash.Neighbours.bottom.map(function mapNeighbours(e) {
                            return ~~e.position;
                        }),
                    },
                    divs: {
                        top: sash.Neighbours.top.length === 0 ? -1 : sash.Neighbours.top.length - 1,
                        left:
                            k === 0 || sash.Neighbours.middle.length === 1 ? -1 : ~~s.position - 1,
                        right:
                            k + 1 === sash.Neighbours.middle.length
                            || sash.Neighbours.middle.length === 1
                                ? -1
                                : ~~s.position,
                        bottom:
                            sash.Neighbours.bottom.length === 0
                                ? -1
                                : sash.Neighbours.top.length + sash.Neighbours.middle.length - 1,
                    },
                });

                if (k > 0) {
                    Layout.divs[~~s.position - 1] = {
                        id: ~~s.position - 1,
                        direction: 'vertical',
                        position: (1 / sash.Neighbours.middle.length) * k,
                        length: 1,
                        sashes: {
                            top: [],
                            bottom: [],
                            left: [~~s.position - 1],
                            right: [~~s.position],
                        },
                        divs: {
                            top: [],
                            bottom: [],
                            left: [],
                            right: [],
                        },
                    };
                    layoutBaseCode += '|';
                }
                layoutBaseCode += '[▣]';
            }

            if (sash.Neighbours.middle.length > 0 && sash.Neighbours.bottom.length > 0) {
                layoutBaseCode += ')_(';
            }

            for (k = 0; k < sash.Neighbours.bottom.length; k++) {
                s = sash.Neighbours.bottom[k];
                Layout.sashes.push({
                    id: ~~s.position,
                    type: s.type,
                    active: !s.passive,
                    side: s.handle_position === 'L' ? 'R' : s.handle_position === 'R' ? 'L' : null,
                    height: 0.2,
                    width: 1 / sash.Neighbours.bottom.length,
                    level: s.level,
                    frame: {
                        top: null,
                        bottom: null,
                        left: null,
                        right: null,
                    },
                    neighbors: {
                        top: sash.Neighbours.middle.map(function mapNeighbours(e) {
                            return ~~e.position;
                        }),
                        left:
                            k === 0 || sash.Neighbours.bottom.length === 1
                                ? []
                                : [~~s.position - 1],
                        right:
                            k + 1 === sash.Neighbours.bottom.length
                            || sash.Neighbours.bottom.length === 1
                                ? []
                                : [~~s.position + 1],
                        bottom: [],
                    },
                    divs: {
                        top: sash.SashesLayout.all_sashes_count - sash.Neighbours.bottom.length - 1,
                        left:
                            k === 0 || sash.Neighbours.bottom.length === 1 ? -1 : ~~s.position - 1,
                        right:
                            k + 1 === sash.Neighbours.bottom.length
                            || sash.Neighbours.bottom.length === 1
                                ? -1
                                : ~~s.position,
                        bottom: -1,
                    },
                });

                if (k > 0) {
                    Layout.divs[~~s.position - 1] = {
                        id: ~~s.position - 1,
                        direction: 'vertical',
                        position: (1 / sash.Neighbours.bottom.length) * k,
                        length: 1,
                        sashes: {
                            top: [],
                            bottom: [],
                            left: [~~s.position - 1],
                            right: [~~s.position],
                        },
                        divs: {
                            top: [],
                            bottom: [],
                            left: [],
                            right: [],
                        },
                    };
                    layoutBaseCode += '|';
                }
                layoutBaseCode += '[▣]';
            }
            if (sash.Neighbours.bottom.length > 0) {
                layoutBaseCode += ')';
            }

            if (sash.Neighbours.top.length > 0) {
                Layout.divs[sash.Neighbours.top.length - 1] = {
                    id: sash.Neighbours.top.length - 1,
                    direction: 'horizontal',
                    position: 0.2,
                    length: 1,
                    sashes: {
                        top: sash.Neighbours.top.map(function mapNeighbours(e) {
                            return ~~e.position;
                        }),
                        bottom: sash.Neighbours.middle.map(function mapNeighbours(e) {
                            return ~~e.position;
                        }),
                        left: [],
                        right: [],
                    },
                    divs: {
                        top: Core.range(0, sash.Neighbours.top.length - 2),
                        bottom: Core.range(
                            sash.Neighbours.top.length,
                            sash.Neighbours.top.length + sash.Neighbours.middle.length - 2
                        ),
                        left: [],
                        right: [],
                    },
                };
            }

            if (sash.Neighbours.bottom.length > 0) {
                Layout.divs[
                    sash.SashesLayout.all_sashes_count - sash.Neighbours.bottom.length - 1
                ] = {
                    id: sash.SashesLayout.all_sashes_count - sash.Neighbours.bottom.length - 1,
                    direction: 'horizontal',
                    position: 0.8,
                    length: 1,
                    sashes: {
                        top: sash.Neighbours.middle.map(function mapNeighbours(e) {
                            return ~~e.position;
                        }),
                        bottom: sash.Neighbours.bottom.map(function mapNeighbours(e) {
                            return ~~e.position;
                        }),
                        left: [],
                        right: [],
                    },
                    divs: {
                        top: Core.range(
                            sash.Neighbours.top.length,
                            sash.Neighbours.top.length + sash.Neighbours.middle.length - 2
                        ),
                        bottom: Core.range(
                            sash.Neighbours.top.length + sash.Neighbours.middle.length,
                            sash.Neighbours.top.length
                                + sash.Neighbours.middle.length
                                + sash.Neighbours.bottom.length
                                - 2
                        ),
                        left: [],
                        right: [],
                    },
                };
            }
            Layout.layoutBaseCode = layoutBaseCode;
        }

        return Layout;
    }

    /**
     * Funkcja przygotowania danych skrzydeł okna
     * @param  {Number} sashesCount Liczba skrzydeł
     * @param  {Object} sashType    Typ skrzydła
     * @return {String}             Typ
     */
    function prepareWindowSashesData(sashesCount) {
        var doorData;
        var confType = 'window';
        if (angular.isObject(sashesCount)) {
            confType = sashesCount.conf;
            doorData = sashesCount;
        }
        const conf = ConfigurationsService.conf.Current;
        let sash = {};
        var sashes = [];
        var dividers = [];
        var leftSash, rightSash;
        var sumWidthTop = 0;
        var sumWidthMiddle = 0;
        var sumWidthBottom = 0;
        var sumHeight = 0;
        var rx, ry, i, divider, sashType;

        ValidationService.invalid(conf, 'sashes');
        ValidationService.invalid(conf, 'fillings');
        ValidationService.invalid(conf, 'frameProfiles');
        ValidationService.invalid(conf, 'sashesProfiles');
        ValidationService.invalid(conf, 'mullionsProfiles');

        for (i = 0; i < doorData.sashes.length; i++) {
            sash = {};
            sash.id = doorData.sashes[i].id;
            if (doorData.sashes[i].neighbors.left.length === 0 || sashes.length === 0) {
                rx = 0;
            } else {
                rx =
                    sashes[doorData.sashes[i].neighbors.left[0]].rx
                    + sashes[doorData.sashes[i].neighbors.left[0]].rWidth;
            }
            if (doorData.sashes[i].neighbors.top.length === 0 || sashes.length === 0) {
                ry = 0;
            } else {
                ry =
                    sashes[doorData.sashes[i].neighbors.top[0]].ry
                    + sashes[doorData.sashes[i].neighbors.top[0]].rHeight;
            }

            sashType = getSashType(doorData.sashes[i], sashType);

            sash = new ActiveSash(doorData.sashes[i].id, {
                rx,
                ry,
                frameId: 0,
                rWidth: Math.round(conf.Width * doorData.sashes[i].width),
                rHeight: Math.round(conf.Height * doorData.sashes[i].height),
                type: sashType,
                frame: doorData.sashes[i].frame,
                divs: {
                    left: doorData.sashes[i].divs.left,
                    right: doorData.sashes[i].divs.right,
                    top: doorData.sashes[i].divs.top,
                    bottom: doorData.sashes[i].divs.bottom,
                },
            });

            if (doorData.sashes[i].divs.top == -1 && doorData.sashes[i].divs.bottom != -1) {
                sumWidthTop += sash.rWidth;
            } else if (doorData.sashes[i].divs.bottom == -1 && doorData.sashes[i].divs.top != -1) {
                sumWidthBottom += sash.rWidth;
            } else {
                if (
                    !(
                        doorData.sashes[i].divs.top > -1
                        && doorData.divs[doorData.sashes[i].divs.top].sashes.top.length
                        && doorData.divs[doorData.sashes[i].divs.top].sashes.top.some(
                            el => doorData.sashes[el].divs.top > -1
                        )
                    )
                ) {
                    sumWidthMiddle += sash.rWidth;
                }
            }

            if (doorData.sashes[i].divs.left == -1) {
                sumHeight += sash.rHeight;
            }
            sashes.push(sash);
        }

        if (!doorData.fromCode) {
            if (sumWidthTop != conf.Width && sumWidthTop > 0) {
                logger.log('TOP', sumWidthTop, conf.Width);
                const topSashes = sashes.filter(
                    s => s.nearMullions.top == -1 && s.nearMullions.bottom != -1
                );
                topSashes[topSashes.length - 1].rWidth += conf.Width - sumWidthTop;
            }
            if (sumWidthBottom != conf.Width && sumWidthBottom > 0) {
                logger.log('BOTTOM', sumWidthBottom, conf.Width);
                const bottomSashes = sashes.filter(
                    s => s.nearMullions.bottom == -1 && s.nearMullions.top != -1
                );
                bottomSashes[bottomSashes.length - 1].rWidth += conf.Width - sumWidthBottom;
            }
            if (sumWidthMiddle != conf.Width && sumWidthMiddle > 0) {
                logger.log('MIDDLE', sumWidthMiddle, conf.Width);
                const middleSashes = sashes.filter(
                    s =>
                        (s.nearMullions.top != -1 && s.nearMullions.bottom != -1)
                        || (s.nearMullions.top == -1 && s.nearMullions.bottom == -1)
                );
                middleSashes[middleSashes.length - 1].rWidth += conf.Width - sumWidthMiddle;
            }
            if (sumHeight != conf.Height && sumHeight > 0) {
                logger.log('HEIGHT', sumHeight, conf.Height);
                sashes
                    .filter(s => s.nearMullions.bottom == -1 && s.nearMullions.top != -1)
                    .map(s => {
                        s.rHeight += conf.Height - sumHeight;
                    });
            }
        }

        for (i = 0; i < doorData.divs.length; i++) {
            const mAT = [];
            for (let j = 0; j < doorData.divs[i].sashes.top.length; j++) {
                mAT.push(sashes[doorData.divs[i].sashes.top[j]]);
            }
            const mAB = [];
            for (let j = 0; j < doorData.divs[i].sashes.bottom.length; j++) {
                mAB.push(sashes[doorData.divs[i].sashes.bottom[j]]);
            }
            const mAL = [];
            for (let j = 0; j < doorData.divs[i].sashes.left.length; j++) {
                mAL.push(sashes[doorData.divs[i].sashes.left[j]]);
            }
            const mAR = [];
            for (let j = 0; j < doorData.divs[i].sashes.right.length; j++) {
                mAR.push(sashes[doorData.divs[i].sashes.right[j]]);
            }
            if (doorData.divs[i].direction == 'horizontal') {
                divider = new ActiveMullion(doorData.divs[i].id, {
                    rx: sashes[doorData.divs[i].sashes.top[0]].rx,
                    ry:
                        sashes[doorData.divs[i].sashes.top[0]].ry
                        + sashes[doorData.divs[i].sashes.top[0]].rHeight,
                    rWidth: doorData.divs[i].sashes.top.reduce(
                        (prev, curr) => prev + sashes[curr].rWidth,
                        0
                    ),
                    frameId: 0,
                    rHeight: 1,
                    multiAlignLeft: mAL,
                    multiAlignRight: mAR,
                    multiAlignTop: mAT,
                    multiAlignBottom: mAB,
                    multiAlignLeftDiv: [],
                    multiAlignRightDiv: [],
                    multiAlignTopDiv: [],
                    multiAlignBottomDiv: [],
                    direction: 'horizontal',
                });
            } else {
                divider = new ActiveMullion(doorData.divs[i].id, {
                    rx:
                        sashes[doorData.divs[i].sashes.left[0]].rx
                        + sashes[doorData.divs[i].sashes.left[0]].rWidth,
                    ry: sashes[doorData.divs[i].sashes.left[0]].ry,
                    rWidth: 1,
                    rHeight: doorData.divs[i].sashes.left.reduce(
                        (prev, curr) => prev + sashes[curr].rHeight,
                        0
                    ),
                    frameId: 0,
                    multiAlignLeft: mAL,
                    multiAlignRight: mAR,
                    multiAlignTop: mAT,
                    multiAlignBottom: mAB,
                    multiAlignLeftDiv: [],
                    multiAlignRightDiv: [],
                    multiAlignTopDiv: [],
                    multiAlignBottomDiv: [],
                    direction: 'vertical',
                });
            }
            dividers.push(divider);
        }
        for (i = 0; i < dividers.length; i++) {
            var div = dividers[i];
            const mAT = [];
            for (let j = 0; j < doorData.divs[i].divs.top.length; j++) {
                mAT.push(dividers[doorData.divs[i].divs.top[j]]);
            }
            const mAB = [];
            for (let j = 0; j < doorData.divs[i].divs.bottom.length; j++) {
                mAB.push(dividers[doorData.divs[i].divs.bottom[j]]);
            }
            const mAL = [];
            for (let j = 0; j < doorData.divs[i].divs.left.length; j++) {
                mAL.push(dividers[doorData.divs[i].divs.left[j]]);
            }
            const mAR = [];
            for (let j = 0; j < doorData.divs[i].divs.right.length; j++) {
                mAR.push(dividers[doorData.divs[i].divs.right[j]]);
            }
            div.multiAlignLeftDiv = mAL;
            div.multiAlignRightDiv = mAR;
            div.multiAlignTopDiv = mAT;
            div.multiAlignBottomDiv = mAB;
        }

        conf.Sashes = sashes;
        conf.EdgeSashes = {
            top: [],
            bottom: [],
            left: [],
            right: [],
        };
        conf.Alignments = [];
        conf.SideProfiles = [];
        conf.Mullions = dividers;
        FramesService.setDefaultFrame(conf);

        if (['window', 'hs', 'door', 'folding_door'].includes(confType)) {
            for (i = 0; i < sashes.length; i++) {
                sash = sashes[i];
                if (sash.nearMullions.left == -1) {
                    conf.EdgeSashes.left.push(sash.id);
                }
                if (sash.nearMullions.right == -1) {
                    conf.EdgeSashes.right.push(sash.id);
                }
                if (sash.nearMullions.top == -1) {
                    conf.EdgeSashes.top.push(sash.id);
                }
                if (sash.nearMullions.bottom == -1) {
                    conf.EdgeSashes.bottom.push(sash.id);
                }
            }
        } else {
            conf.EdgeSashes.top = [].concat(sashes.map(s => s.id));
            conf.EdgeSashes.bottom = [].concat(sashes.map(s => s.id));
            conf.EdgeSashes.left.push(leftSash.id);
            conf.EdgeSashes.right.push(rightSash.id);
        }
        SashTypesService.refreshTypes(conf);
        ValidationService.valid(conf, 'sashes');
        EventBusService.post({
            key: 'changedSashes',
            value: {},
        });
        ProfilesService.setDefaultSashes(conf);
        ShapeService.setShapes(conf);
        MuntinsFactory.hasMuntins(conf);
        SashesLayoutService.setIndex(conf);
        validateLayout();

        HandlesFactory.checkIsOneHandleAndAllHasHandle(conf, true);
        HandlesFactory.refreshTypes(conf);
        ConstructionLimitationFactory.findReinforcement(conf);
        PriceService.count();
        WarrantyFactory.check(conf);
        ParametersService.count(ConfigurationsService.conf.Current);
    }

    function getSashType(sashSchema) {
        let sashType;
        if (sashSchema.type == 'F') {
            sashType = factory.sashTypes.find(el => el.type == 'F');
        } else if (sashSchema.type == 'FF') {
            sashType = factory.sashTypes.find(el => el.type == 'FF');
        } else if (sashSchema.type == 'OFF') {
            sashType = factory.sashTypes.find(el => el.type == 'OFF');
        } else if (sashSchema.type == 'K') {
            sashType = factory.sashTypes.find(el => el.type == 'K');
        } else if (sashSchema.type == 'OKL') {
            sashType = factory.sashTypes.find(el => el.type == 'OKL');
        } else if (sashSchema.type == 'DK' && sashSchema.side == 'R') {
            sashType = factory.sashTypes.find(el => el.type == 'DK' && el.handle_position == 'L');
        } else if (sashSchema.type == 'DK' && sashSchema.side == 'L') {
            sashType = factory.sashTypes.find(el => el.type == 'DK' && el.handle_position == 'R');
        } else if (sashSchema.type == 'D' && sashSchema.side == 'R') {
            sashType = factory.sashTypes.find(el => el.type == 'D' && el.handle_position == 'L');
        } else if (sashSchema.type == 'D' && sashSchema.side == 'L') {
            sashType = factory.sashTypes.find(el => el.type == 'D' && el.handle_position == 'R');
        } else if (sashSchema.type == 'OD' && sashSchema.side == 'R') {
            sashType = factory.sashTypes.find(el => el.type == 'OD' && el.handle_position == 'L');
        } else if (sashSchema.type == 'OD' && sashSchema.side == 'L') {
            sashType = factory.sashTypes.find(el => el.type == 'OD' && el.handle_position == 'R');
        } else if (sashSchema.type == 'S' && sashSchema.side == 'R') {
            sashType = factory.sashTypes.find(
                el => el.type == 'S' && el.handle_position == 'L' && !~~el.passive
            );
        } else if (sashSchema.type == 'S' && sashSchema.side == 'L') {
            sashType = factory.sashTypes.find(
                el => el.type == 'S' && el.handle_position == 'R' && !~~el.passive
            );
        } else if (sashSchema.type == 'SU') {
            sashType = factory.sashTypes.find(el => el.type == 'SU');
        } else if (sashSchema.type == 'SD') {
            sashType = factory.sashTypes.find(el => el.type == 'SD');
        } else if (sashSchema.type == 'SWF') {
            sashType = factory.sashTypes.find(el => el.type == 'SWF');
        } else if (sashSchema.type == 'DS' && sashSchema.side == 'R') {
            sashType = factory.sashTypes.find(el => el.type == 'DS' && el.handle_position == 'L');
        } else if (sashSchema.type == 'DS' && sashSchema.side == 'L') {
            sashType = factory.sashTypes.find(el => el.type == 'DS' && el.handle_position == 'R');
        } else if (sashSchema.type == 'ODS' && sashSchema.side == 'R') {
            sashType = factory.sashTypes.find(el => el.type == 'ODS' && el.handle_position == 'L');
        } else if (sashSchema.type == 'ODS' && sashSchema.side == 'L') {
            sashType = factory.sashTypes.find(el => el.type == 'ODS' && el.handle_position == 'R');
        } else if (sashSchema.type == 'DSH' && sashSchema.side == 'R') {
            sashType = factory.sashTypes.find(el => el.type == 'DSH' && el.handle_position == 'L');
        } else if (sashSchema.type == 'DSH' && sashSchema.side == 'L') {
            sashType = factory.sashTypes.find(el => el.type == 'DSH' && el.handle_position == 'R');
        } else if (sashSchema.type == 'ODSH' && sashSchema.side == 'R') {
            sashType = factory.sashTypes.find(el => el.type == 'ODSH' && el.handle_position == 'L');
        } else if (sashSchema.type == 'ODSH' && sashSchema.side == 'L') {
            sashType = factory.sashTypes.find(el => el.type == 'ODSH' && el.handle_position == 'R');
        } else if (sashSchema.type == 'SP' && sashSchema.side == 'R') {
            sashType = factory.sashTypes.find(
                el => el.type == 'S' && el.handle_position == 'L' && ~~el.passive
            );
        } else if (sashSchema.type == 'SP' && sashSchema.side == 'L') {
            sashType = factory.sashTypes.find(
                el => el.type == 'S' && el.handle_position == 'R' && ~~el.passive
            );
        } else if (sashSchema.type == 'DSC' && sashSchema.side == 'R') {
            sashType = factory.sashTypes.find(el => el.type == 'DSC' && el.handle_position == 'L');
        } else if (sashSchema.type == 'DSC' && sashSchema.side == 'L') {
            sashType = factory.sashTypes.find(el => el.type == 'DSC' && el.handle_position == 'R');
        } else if (sashSchema.type == 'PSK' && sashSchema.side == 'R' && sashSchema.active) {
            sashType = factory.sashTypes.find(
                el => el.type == 'PSK' && el.handle_position == 'L' && !~~el.passive
            );
        } else if (sashSchema.type == 'PSK' && sashSchema.side == 'L' && sashSchema.active) {
            sashType = factory.sashTypes.find(
                el => el.type == 'PSK' && el.handle_position == 'R' && !~~el.passive
            );
        } else if (sashSchema.type == 'PSK' && sashSchema.side == 'R' && !sashSchema.active) {
            sashType = factory.sashTypes.find(
                el => el.type == 'PSK' && el.handle_position == 'L' && ~~el.passive
            );
        } else if (sashSchema.type == 'PSK' && sashSchema.side == 'L' && !sashSchema.active) {
            sashType = factory.sashTypes.find(
                el => el.type == 'PSK' && el.handle_position == 'R' && ~~el.passive
            );
        } else if (sashSchema.type == 'HS' && sashSchema.side == 'R' && sashSchema.active) {
            sashType = factory.sashTypes.find(
                el => el.type == 'HS' && el.handle_position == 'L' && !~~el.passive
            );
        } else if (sashSchema.type == 'HS' && sashSchema.side == 'L' && sashSchema.active) {
            sashType = factory.sashTypes.find(
                el => el.type == 'HS' && el.handle_position == 'R' && !~~el.passive
            );
        } else if (sashSchema.type == 'HS' && sashSchema.side == 'R' && !sashSchema.active) {
            sashType = factory.sashTypes.find(
                el => el.type == 'HS' && el.handle_position == 'L' && ~~el.passive
            );
        } else if (sashSchema.type == 'HS' && sashSchema.side == 'L' && !sashSchema.active) {
            sashType = factory.sashTypes.find(
                el => el.type == 'HS' && el.handle_position == 'R' && ~~el.passive
            );
        } else if (sashSchema.type == 'DRA' && sashSchema.side == 'L' && sashSchema.active) {
            sashType = factory.sashTypes.find(
                el => el.type == 'DRA' && el.handle_position == 'R' && !~~el.passive
            );
        } else if (sashSchema.type == 'DRA' && sashSchema.side == 'R' && sashSchema.active) {
            sashType = factory.sashTypes.find(
                el => el.type == 'DRA' && el.handle_position == 'L' && !~~el.passive
            );
        } else if (sashSchema.type == 'DRP' && sashSchema.side == 'L' && !sashSchema.active) {
            sashType = factory.sashTypes.find(
                el => el.type == 'DRP' && el.handle_position == 'R' && ~~el.passive
            );
        } else if (sashSchema.type == 'DRP' && sashSchema.side == 'R' && !sashSchema.active) {
            sashType = factory.sashTypes.find(
                el => el.type == 'DRP' && el.handle_position == 'L' && ~~el.passive
            );
        } else if (sashSchema.type == 'DOA' && sashSchema.side == 'L' && sashSchema.active) {
            sashType = factory.sashTypes.find(
                el => el.type == 'DOA' && el.handle_position == 'R' && !~~el.passive
            );
        } else if (sashSchema.type == 'DOA' && sashSchema.side == 'R' && sashSchema.active) {
            sashType = factory.sashTypes.find(
                el => el.type == 'DOA' && el.handle_position == 'L' && !~~el.passive
            );
        } else if (sashSchema.type == 'DOP' && sashSchema.side == 'L' && !sashSchema.active) {
            sashType = factory.sashTypes.find(
                el => el.type == 'DOP' && el.handle_position == 'R' && ~~el.passive
            );
        } else if (sashSchema.type == 'DOP' && sashSchema.side == 'R' && !sashSchema.active) {
            sashType = factory.sashTypes.find(
                el => el.type == 'DOP' && el.handle_position == 'L' && ~~el.passive
            );
        } else if (sashSchema.type == 'FD' && sashSchema.side == 'R') {
            sashType = factory.sashTypes.find(el => el.type == 'FD' && el.handle_position == 'L');
        } else if (sashSchema.type == 'FD' && sashSchema.side == 'L') {
            sashType = factory.sashTypes.find(el => el.type == 'FD' && el.handle_position == 'R');
        } else if (sashSchema.type == 'FDO' && sashSchema.side == 'R') {
            sashType = factory.sashTypes.find(el => el.type == 'FDO' && el.handle_position == 'L');
        } else if (sashSchema.type == 'FDO' && sashSchema.side == 'L') {
            sashType = factory.sashTypes.find(el => el.type == 'FDO' && el.handle_position == 'R');
        } else {
            sashType = factory.sashTypes.find(el => el.type == 'FF');
        }
        return sashType;
    }

    /**
     * Funkcja wyboru szablonu skrzydeł okna
     * @param  {Number} sashesCount Liczba skrzydeł
     * @param  {Object} custom      custom
     */
    function selectWindowSashesLayout(sashesCount, custom) {
        const conf = ConfigurationsService.conf.Current;
        if (
            sashesCount.sashes.some(
                sash => sash.width * conf.Width < IccConfig.Configurators.minWidth || sash.height * conf.Height < IccConfig.Configurators.minWidth
            )
        ) {
            IssuesService.simpleRegister(
                'incorrect-window-dimensions',
                'Podane wymiary są nieprawidłowe.',
                $filter('translate')('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                ConfigurationsService.conf.Current,
                {
                    logLevel: IssueLevel.NONE
                }
            );
        } else {
            conf.Layout = sashesCount;
            // ConfigurationsService.conf.Default.Layout = sashesCount;
            prepareWindowSashesData(sashesCount);
            if (sashesCount == 1 && !custom) {
                $rootScope.$broadcast('openSashTypeModalOne');
            }

            if (IccConfig.Configurators.dependencies) {
                EventBusService.post({ key: 'processDependencies', value: null });
            }

            LocksFactory.findLocksBySystem();
            ValidationService.valid(conf, 'shape');
            IssuesService.unregister('fixSashTypeAfterSetSystem', conf);
            EventBusService.post({
                key: 'changedSashes',
                value: {},
            });
            $rootScope.$broadcast('Sashes_goToNextSubstep');
        }
    }

    /**
     * Funkcja walidujca szablon
     * @param  {Object} supress Supress
     * @return {Object}         Zwalidowane
     */
    function validateLayout(supress, showmodal) {
        if (angular.isUndefined(showmodal)) {
            showmodal = true;
        }

        if (
            !['window', 'hs', 'door', 'folding_door'].includes(
                ConfigurationsService.conf.Current.type
            )
        ) {
            return true;
        }
        var divs = ConfigurationsService.conf.Current.Mullions;
        if (ConfigurationsService.conf.Current.Sashes.length === 0) {
            IssuesService.simpleRegister(
                'no-sashes-layout',
                'Brak wybranego układu.',
                $filter('translate')('WINDOW|Brak wybranego układu.'),
                ConfigurationsService.conf.Current,
                {
                    showMessage: !supress,
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }
        IssuesService.unregister('no-sashes-layout', ConfigurationsService.conf.Current);
        var doorCount = 0;
        var shape = ConfigurationsService.conf.Current.Shape;
        var allowedTypes, restrict;

        const falseMullionsMap = {
            DS: ['D', 'DK'],
            DSH: ['D', 'DK'],
            DSC: ['D', 'DK'],
            ODS: ['OD'],
            ODSH: ['OD'],
            DRP: ['DRA'],
            DOP: ['DOA'],
            PSK: ['PSK'],
            S: ['S'],
            HS: ['HS'],
        };

        for (var i = 0; i < ConfigurationsService.conf.Current.Sashes.length; i++) {
            var sash = ConfigurationsService.conf.Current.Sashes[i];
            var lDiv = divs[Core.fId(divs, sash.nearMullions.left)];
            var rDiv = divs[Core.fId(divs, sash.nearMullions.right)];
            var type = ConfigurationsService.conf.Current.Sashes[i].type;

            if (falseMullionsMap[type.type] && type.passive) {
                if (
                    type.handle_position == 'R'
                    && (angular.isUndefined(rDiv)
                        || rDiv.multiAlignRight.length != 1
                        || rDiv.multiAlignRight[0].type.handle_position != 'L'
                        || falseMullionsMap[type.type].indexOf(rDiv.multiAlignRight[0].type.type)
                            === -1
                        || rDiv.multiAlignRight[0].type.passive)
                ) {
                    IssuesService.simpleRegister(
                        'incorrect-layout',
                        'Niepoprawny układ kwater.',
                        $filter('translate')('WINDOW|Niepoprawny układ kwater.'),
                        ConfigurationsService.conf.Current,
                        {
                            showMessage: !supress,
                            logLevel: IssueLevel.NONE
                        }
                    );
                    return false;
                }

                if (
                    type.handle_position == 'L'
                    && (angular.isUndefined(lDiv)
                        || lDiv.multiAlignLeft.length != 1
                        || lDiv.multiAlignLeft[0].type.handle_position != 'R'
                        || falseMullionsMap[type.type].indexOf(lDiv.multiAlignLeft[0].type.type)
                            === -1
                        || lDiv.multiAlignLeft[0].type.passive)
                ) {
                    IssuesService.simpleRegister(
                        'incorrect-layout',
                        'Niepoprawny układ kwater.',
                        $filter('translate')('WINDOW|Niepoprawny układ kwater.'),
                        ConfigurationsService.conf.Current,
                        {
                            showMessage: !supress,
                            logLevel: IssueLevel.NONE
                        }
                    );
                    return false;
                }
            }

            if (['DRA', 'DRP', 'DOA', 'DOP'].indexOf(type.type) > -1) {
                doorCount++;
            }

            // dla koła
            // koło - jedno skrzydło
            if (
                shape.shape == 'circle'
                && sash.nearMullions.top == -1
                && sash.nearMullions.bottom == -1
                && sash.nearMullions.left == -1
                && sash.nearMullions.right == -1
            ) {
                allowedTypes = ['F', 'FF', 'OFF', 'K'];
                restrict = {
                    F: [150],
                    FF: [230],
                    OFF: [230],
                    K: [230],
                };
                if (allowedTypes.indexOf(type.type) == -1) {
                    IssuesService.simpleRegister(
                        'incorrect-layout',
                        'Niepoprawny układ kwater.',
                        $filter('translate')('WINDOW|Niepoprawny układ kwater.'),
                        ConfigurationsService.conf.Current,
                        {
                            showMessage: !supress,
                            logLevel: IssueLevel.NONE
                        }
                    );
                    return false;
                }
                if (
                    angular.isDefined(restrict[type.type])
                    && ((angular.isDefined(restrict[type.type][0])
                        && restrict[type.type][0] > shape.d / 2)
                        || (angular.isDefined(restrict[type.type][1])
                            && restrict[type.type][1] < shape.d / 2))
                ) {
                    IssuesService.simpleRegister(
                        'incorrect-layout',
                        'Niepoprawny układ kwater.',
                        $filter('translate')('WINDOW|Niepoprawny układ kwater.'),
                        ConfigurationsService.conf.Current,
                        {
                            showMessage: !supress,
                            logLevel: IssueLevel.NONE
                        }
                    );
                    return false;
                }
            }

            // koło - dwa skrzydła ||
            if (
                shape.shape == 'circle'
                && sash.nearMullions.top == -1
                && sash.nearMullions.bottom == -1
                && (sash.nearMullions.left > -1 || sash.nearMullions.right > -1)
            ) {
                allowedTypes = ['F', 'FF', 'OFF'];
                restrict = {
                    F: [150],
                    FF: [230],
                    OFF: [230],
                };
                if (allowedTypes.indexOf(type.type) == -1) {
                    IssuesService.simpleRegister(
                        'incorrect-layout',
                        'Niepoprawny układ kwater.',
                        $filter('translate')('WINDOW|Niepoprawny układ kwater.'),
                        ConfigurationsService.conf.Current,
                        {
                            showMessage: !supress,
                            logLevel: IssueLevel.NONE
                        }
                    );
                    return false;
                }
                if (
                    angular.isDefined(restrict[type.type])
                    && ((angular.isDefined(restrict[type.type][0])
                        && restrict[type.type][0] > shape.d / 2)
                        || (angular.isDefined(restrict[type.type][1])
                            && restrict[type.type][1] < shape.d / 2))
                ) {
                    IssuesService.simpleRegister(
                        'incorrect-layout',
                        'Niepoprawny układ kwater.',
                        $filter('translate')('WINDOW|Niepoprawny układ kwater.'),
                        ConfigurationsService.conf.Current,
                        {
                            showMessage: !supress,
                            logLevel: IssueLevel.NONE
                        }
                    );
                    return false;
                }
            }

            // koło - dwa skrzydła = - skrzydło górne
            if (
                shape.shape == 'circle'
                && sash.nearMullions.left == -1
                && sash.nearMullions.right == -1
                && sash.nearMullions.top == -1
                && sash.nearMullions.bottom > -1
            ) {
                allowedTypes = ['F', 'FF', 'OFF', 'K'];
                restrict = {
                    F: [150],
                    FF: [230],
                    OFF: [230],
                    K: [230],
                };
                if (allowedTypes.indexOf(type.type) == -1) {
                    IssuesService.simpleRegister(
                        'incorrect-layout',
                        'Niepoprawny układ kwater.',
                        $filter('translate')('WINDOW|Niepoprawny układ kwater.'),
                        ConfigurationsService.conf.Current,
                        {
                            showMessage: !supress,
                            logLevel: IssueLevel.NONE
                        }
                    );
                    return false;
                }
                if (
                    angular.isDefined(restrict[type.type])
                    && ((angular.isDefined(restrict[type.type][0])
                        && restrict[type.type][0] > shape.d / 2)
                        || (angular.isDefined(restrict[type.type][1])
                            && restrict[type.type][1] < shape.d / 2))
                ) {
                    IssuesService.simpleRegister(
                        'incorrect-layout',
                        'Niepoprawny układ kwater.',
                        $filter('translate')('WINDOW|Niepoprawny układ kwater.'),
                        ConfigurationsService.conf.Current,
                        {
                            showMessage: !supress,
                            logLevel: IssueLevel.NONE
                        }
                    );
                    return false;
                }
            }

            // koło - dwa skrzydła = - skrzydło dolne
            if (
                shape.shape == 'circle'
                && sash.nearMullions.left == -1
                && sash.nearMullions.right == -1
                && sash.nearMullions.bottom == -1
                && sash.nearMullions.top > -1
            ) {
                allowedTypes = ['F', 'FF', 'OFF'];
                restrict = {
                    F: [150],
                    FF: [230],
                    OFF: [230],
                };
                if (allowedTypes.indexOf(type.type) == -1) {
                    IssuesService.simpleRegister(
                        'incorrect-layout',
                        'Niepoprawny układ kwater.',
                        $filter('translate')('WINDOW|Niepoprawny układ kwater.'),
                        ConfigurationsService.conf.Current,
                        {
                            showMessage: !supress,
                            logLevel: IssueLevel.NONE
                        }
                    );
                    return false;
                }
                if (
                    angular.isDefined(restrict[type.type])
                    && ((angular.isDefined(restrict[type.type][0])
                        && restrict[type.type][0] > shape.d / 2)
                        || (angular.isDefined(restrict[type.type][1])
                            && restrict[type.type][1] < shape.d / 2))
                ) {
                    IssuesService.simpleRegister(
                        'incorrect-layout',
                        'Niepoprawny układ kwater.',
                        $filter('translate')('WINDOW|Niepoprawny układ kwater.'),
                        ConfigurationsService.conf.Current,
                        {
                            showMessage: !supress,
                            logLevel: IssueLevel.NONE
                        }
                    );
                    return false;
                }
            }
            if (['PSK'].indexOf(type.type) > -1 && sash.shape.shape !== 'rect') {
                IssuesService.simpleRegister(
                    'psk-shape',
                    'Skrzydło przesuwne musi być prostokątem.',
                    $filter('translate')('WINDOW|Skrzydło przesuwne musi być prostokątem.'),
                    ConfigurationsService.conf.Current,
                    {
                        showMessage: !supress,
                        logLevel: IssueLevel.NONE
                    }
                );
                return false;
            }

            if (
                IccConfig.Configurators.noHingesOnSlope
                && ['F', 'FF', 'OFF'].indexOf(type.type) === -1
                && ((type.handle_position === 'R' && sash.shape.angles.hasLeftSlopeSide)
                    || (type.handle_position === 'L' && sash.shape.angles.hasRightSlopeSide))
            ) {
                IssuesService.simpleRegister(
                    'hinges-on-slope',
                    'Nie można dodawać okuć na elementach skośnych.'.
                    $filter('translate')('WINDOW|Nie można dodawać okuć na elementach skośnych.'),
                    ConfigurationsService.conf.Current,
                    {
                        showMessage: !supress,
                        logLevel: IssueLevel.NONE
                    }
                );
                return false;
            }

            if (
                ['HS'].indexOf(type.type) > -1
                && type.handle_position == 'L'
                && (angular.isUndefined(rDiv)
                    || rDiv.multiAlignRight.length != 1
                    || (rDiv.multiAlignRight[0].type.type != 'FF'
                        && rDiv.multiAlignRight[0].type.type != 'OFF'
                        && rDiv.multiAlignRight[0].type.type != 'HS'))
            ) {
                IssuesService.simpleRegister(
                    'hs-width',
                    'Niepoprawny układ kwater.',
                    $filter('translate')('WINDOW|Niepoprawny układ kwater.'),
                    ConfigurationsService.conf.Current,
                    {
                        showMessage: !supress,
                        logLevel: IssueLevel.NONE
                    }
                );
                return false;
            }

            if (
                ['HS'].indexOf(type.type) > -1
                && type.handle_position == 'R'
                && (angular.isUndefined(lDiv)
                    || lDiv.multiAlignLeft.length != 1
                    || (lDiv.multiAlignLeft[0].type.type != 'FF'
                        && lDiv.multiAlignLeft[0].type.type != 'OFF'
                        && lDiv.multiAlignLeft[0].type.type != 'HS'))
            ) {
                IssuesService.simpleRegister(
                    'hs-width',
                    'Niepoprawny układ kwater.',
                    $filter('translate')('WINDOW|Niepoprawny układ kwater.'),
                    ConfigurationsService.conf.Current,
                    {
                        showMessage: !supress,
                        logLevel: IssueLevel.NONE
                    }
                );
                return false;
            }

            if (
                ['HS'].indexOf(type.type) > -1
                && type.handle_position == 'L'
                && (sash.rWidth > rDiv.multiAlignRight[0].rWidth + 6
                    && (rDiv.multiAlignRight[0].type.type === 'F'
                        || rDiv.multiAlignRight[0].type.type === 'FF'
                        || rDiv.multiAlignRight[0].type.type === 'OFF'))
            ) {
                IssuesService.simpleRegister(
                    'hs-width',
                    'Skrzydło przesuwne nie może być szersze niż skrzydło stałe.',
                    $filter('translate')(
                        'WINDOW|Skrzydło przesuwne nie może być szersze niż skrzydło stałe.'
                    ),
                    ConfigurationsService.conf.Current,
                    {
                        showMessage: !supress,
                        logLevel: IssueLevel.NONE
                    }
                );
                return false;
            }

            if (
                ['HS'].indexOf(type.type) > -1
                && type.handle_position == 'R'
                && (sash.rWidth > lDiv.multiAlignLeft[0].rWidth + 6
                    && (lDiv.multiAlignLeft[0].type.type === 'F'
                        || lDiv.multiAlignLeft[0].type.type === 'FF'
                        || lDiv.multiAlignLeft[0].type.type === 'OFF'))
            ) {
                IssuesService.simpleRegister(
                    'hs-width',
                    'Skrzydło przesuwne nie może być szersze niż skrzydło stałe.',
                    $filter('translate')(
                        'WINDOW|Skrzydło przesuwne nie może być szersze niż skrzydło stałe.'
                    ),
                    ConfigurationsService.conf.Current,
                    {
                        showMessage: !supress,
                        logLevel: IssueLevel.NONE
                    }
                );
                return false;
            }
        }
        if (doorCount === 0 && CurConfService.conf == 'door' && !IccConfig.Configurators.door.singleFix) {
            IssuesService.simpleRegister(
                'no-door-sashes',
                'Brak skrzydeł drzwiowych w układzie.',
                $filter('translate')('DOOR|Brak skrzydeł drzwiowych w układzie.'),
                ConfigurationsService.conf.Current,
                {
                    showMessage: !supress,
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }
        IssuesService.unregister('incorrect-layout', ConfigurationsService.conf.Current);
        IssuesService.unregister('no-sashes-layout', ConfigurationsService.conf.Current);
        IssuesService.unregister('psk-shape', ConfigurationsService.conf.Current);
        IssuesService.unregister('hinges-on-slope', ConfigurationsService.conf.Current);
        IssuesService.unregister('hs-width', ConfigurationsService.conf.Current);
        IssuesService.unregister('no-door-sashes', ConfigurationsService.conf.Current);
        return true;
    }

    /**
     * Rekalkulacja
     */
    function recalc() {
        PriceService.count();
    }

    /**
     * Funkcja przygotowania danych skrzydeł okna
     * @param  {Number} sashesCount Liczba skrzydeł
     * @param  {Object} sashType    Typ skrzydła
     * @return {String}             Typ
     */
    function resetDimensions(sashesCount, conf = ConfigurationsService.conf.Current) {
        var doorData;
        if (angular.isObject(sashesCount)) {
            doorData = sashesCount;
        }
        let sashesSchema = doorData.sashes;
        let divsSchema = doorData.divs;
        if (doorData.fromCode) {
            const schema = Core.copy(doorData.layoutCode);
            const schemaSashes = [];
            const schemaDivs = [];
            const error = { value: 0 };
            let index = { i: 0 };
            indexSashes(schema, index);
            index = { i: 0 };
            prepareDividersFromCode(schema, schemaDivs, index);
            schemaDivs.forEach(div => {
                if (div.sashes[div.direction === 'horizontal' ? 'top' : 'left'].length >= 2) {
                    for (
                        let i = 0;
                        i < div.sashes[div.direction === 'horizontal' ? 'top' : 'left'].length - 1;
                        i++
                    ) {
                        div.divs[div.direction === 'horizontal' ? 'top' : 'left'].push(
                            schemaDivs.find(
                                el =>
                                    el.sashes[
                                        div.direction === 'horizontal' ? 'left' : 'top'
                                    ].indexOf(
                                        div.sashes[div.direction === 'horizontal' ? 'top' : 'left'][
                                            i
                                        ]
                                    ) > -1
                                    && el.sashes[
                                        div.direction === 'horizontal' ? 'right' : 'bottom'
                                    ].indexOf(
                                        div.sashes[div.direction === 'horizontal' ? 'top' : 'left'][
                                            i + 1
                                        ]
                                    ) > -1
                            ).id
                        );
                    }
                }
                if (div.sashes[div.direction === 'horizontal' ? 'bottom' : 'right'].length >= 2) {
                    for (
                        let i = 0;
                        i
                        < div.sashes[div.direction === 'horizontal' ? 'bottom' : 'right'].length
                            - 1;
                        i++
                    ) {
                        div.divs[div.direction === 'horizontal' ? 'bottom' : 'right'].push(
                            schemaDivs.find(
                                el =>
                                    el.sashes[
                                        div.direction === 'horizontal' ? 'left' : 'top'
                                    ].indexOf(
                                        div.sashes[
                                            div.direction === 'horizontal' ? 'bottom' : 'right'
                                        ][i]
                                    ) > -1
                                    && el.sashes[
                                        div.direction === 'horizontal' ? 'right' : 'bottom'
                                    ].indexOf(
                                        div.sashes[
                                            div.direction === 'horizontal' ? 'bottom' : 'right'
                                        ][i + 1]
                                    ) > -1
                            ).id
                        );
                    }
                }
            });
            prepareLayoutFromCode(
                schema,
                ConfigurationsService.conf.Current.Width,
                ConfigurationsService.conf.Current.Height,
                ConfigurationsService.conf.Current.Width,
                ConfigurationsService.conf.Current.Height,
                schemaDivs,
                doorData.sashTypes,
                schemaSashes,
                error
            );

            if (error.value) {
                InfoFactory.openWarning(
                    $filter('translate')('CONFIGURATOR|Podane wymiary są nieprawidłowe.')
                );
            } else {
                sashesSchema = schemaSashes;
                divsSchema = schemaDivs;
            }
        }

        let sash = {};
        var sashes = [];
        var sumWidthTop = 0;
        var sumWidthMiddle = 0;
        var sumWidthBottom = 0;
        var sumHeight = 0;
        var rx, ry, i, divider;

        for (i = 0; i < sashesSchema.length; i++) {
            sash = Core.fIdO(conf.Sashes, sashesSchema[i].id);

            if (sashesSchema[i].neighbors.left.length === 0 || sashes.length === 0) {
                rx = 0;
            } else {
                rx =
                    sashes[sashesSchema[i].neighbors.left[0]].rx
                    + sashes[sashesSchema[i].neighbors.left[0]].rWidth;
            }
            if (sashesSchema[i].neighbors.top.length === 0 || sashes.length === 0) {
                ry = 0;
            } else {
                ry =
                    sashes[sashesSchema[i].neighbors.top[0]].ry
                    + sashes[sashesSchema[i].neighbors.top[0]].rHeight;
            }

            sash.rx = rx;
            sash.ry = ry;
            sash.rWidth = Math.round(conf.Width * sashesSchema[i].width);
            sash.rHeight = Math.round(conf.Height * sashesSchema[i].height);

            if (sashesSchema[i].divs.top == -1 && sashesSchema[i].divs.bottom != -1) {
                sumWidthTop += sash.rWidth;
            } else if (sashesSchema[i].divs.bottom == -1 && sashesSchema[i].divs.top != -1) {
                sumWidthBottom += sash.rWidth;
            } else {
                sumWidthMiddle += sash.rWidth;
            }

            if (sashesSchema[i].divs.left == -1) {
                sumHeight += sash.rHeight;
            }
            sashes.push(sash);
        }

        if (!doorData.fromCode) {
            if (sumWidthTop != conf.Width && sumWidthTop > 0) {
                const topSashes = sashes.filter(
                    s => s.nearMullions.top == -1 && s.nearMullions.bottom != -1
                );
                topSashes[topSashes.length - 1].rWidth += conf.Width - sumWidthTop;
            }
            if (sumWidthBottom != conf.Width && sumWidthBottom > 0) {
                const bottomSashes = sashes.filter(
                    s => s.nearMullions.bottom == -1 && s.nearMullions.top != -1
                );
                bottomSashes[bottomSashes.length - 1].rWidth += conf.Width - sumWidthBottom;
            }
            if (sumWidthMiddle != conf.Width && sumWidthMiddle > 0) {
                const middleSashes = sashes.filter(
                    s =>
                        (s.nearMullions.top != -1 && s.nearMullions.bottom != -1)
                        || (s.nearMullions.top == -1 && s.nearMullions.bottom == -1)
                );
                middleSashes[middleSashes.length - 1].rWidth += conf.Width - sumWidthMiddle;
            }
            if (sumHeight != conf.Height && sumHeight > 0) {
                sashes
                    .filter(s => s.nearMullions.bottom == -1 && s.nearMullions.top != -1)
                    .map(s => {
                        s.rHeight += conf.Height - sumHeight;
                    });
            }
        }

        sashes.forEach(s => {
            if (s.intSashes.length > 0) {
                s.intSashes[0].rx = 0;
                s.intSashes[0].ry = 0;
                s.intSashes[0].rWidth = s.rWidth;
                s.intSashes[0].rHeight = s.rHeight;
            }
        });

        if (divsSchema) {
            for (i = 0; i < divsSchema.length; i++) {
                divider = Core.fIdO(conf.Mullions, divsSchema[i].id);
                if (divsSchema[i].direction == 'horizontal') {
                    divider.rx = sashes[divsSchema[i].sashes.top[0]].rx;
                    divider.ry =
                        sashes[divsSchema[i].sashes.top[0]].ry
                        + sashes[divsSchema[i].sashes.top[0]].rHeight;
                    divider.rWidth = divsSchema[i].sashes.top.reduce(
                        (prev, curr) => prev + sashes[curr].rWidth,
                        0
                    );
                    divider.rHeight = 1;
                } else {
                    divider.rx =
                        sashes[divsSchema[i].sashes.left[0]].rx
                        + sashes[divsSchema[i].sashes.left[0]].rWidth;
                    divider.ry = sashes[divsSchema[i].sashes.left[0]].ry;
                    divider.rWidth = 1;
                    divider.rHeight = divsSchema[i].sashes.left.reduce(
                        (prev, curr) => prev + sashes[curr].rHeight,
                        0
                    );
                }
            }
        }

        FramesService.resetFrameDimensions(conf);

        EventBusService.post({
            key: 'changedSashes',
            value: {},
        });
        ShapeService.setShapes(conf);
        MuntinsFactory.hasMuntins(conf);
        SashesLayoutService.setIndex(conf);

        ConstructionLimitationFactory.findReinforcement(conf);
        PriceService.count();
        WarrantyFactory.check(conf);
        ParametersService.count(ConfigurationsService.conf.Current);
    }

    function resetLayout() {
        ConfigurationsService.conf.Current.Layout = {};
        ConfigurationsService.conf.Current.Sashes = [];
        ConfigurationsService.conf.Current.EdgeSashes = {
            top: [],
            bottom: [],
            left: [],
            right: [],
        };
        PriceService.count();
    }

    function replaceSashTypes(sashesCount, conf = ConfigurationsService.conf.Current) {
        var doorData;
        if (angular.isObject(sashesCount)) {
            doorData = sashesCount;
        }
        let sashesSchema = doorData.sashes;
        if (doorData.fromCode) {
            const schema = Core.copy(doorData.layoutCode);
            const schemaSashes = [];
            const schemaDivs = [];
            const error = { value: 0 };
            let index = { i: 0 };
            indexSashes(schema, index);
            index = { i: 0 };
            prepareDividersFromCode(schema, schemaDivs, index);
            prepareLayoutFromCode(
                schema,
                conf.Width,
                conf.Height,
                conf.Width,
                conf.Height,
                schemaDivs,
                doorData.sashTypes,
                schemaSashes,
                error
            );

            if (error.value) {
                InfoFactory.openWarning(
                    $filter('translate')('CONFIGURATOR|Podane wymiary są nieprawidłowe.')
                );
            } else {
                sashesSchema = schemaSashes;
            }
        }

        sashesSchema.forEach(sashSchema => {
            const sash = Core.fIdO(conf.Sashes, sashSchema.id);
            sash.type = getSashType(sashSchema);
        });
        conf.Layout = sashesCount;
        SashTypesService.refreshTypes(conf);
        ValidationService.valid(conf, 'sashes');
        EventBusService.post({
            key: 'changedSashes',
            value: {},
        });
        ShapeService.setShapes(conf);
        MuntinsFactory.hasMuntins(conf);
        SashesLayoutService.setIndex(conf);
        validateLayout();

        HandlesFactory.checkIsOneHandleAndAllHasHandle(conf, true);
        HandlesFactory.refreshTypes(conf);
        ConstructionLimitationFactory.findReinforcement(conf);
        PriceService.count();
        WarrantyFactory.check(conf);
        ParametersService.count(conf);
        $rootScope.$broadcast('Sashes_goToNextSubstep');
    }
}
