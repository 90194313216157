import * as angular from 'angular';

import TimeLimitService from './time_limit.service';
import TimeLimitSystemService from './time_limit_system.service';
import TimeLimitFillingService from './time_limit_filling.service';
import TimeLimitSealColorService from './time_limit_seal_color.service';
import TimeLimitAccessoryService from './time_limit_accessory.service';
import TimeLimitHandleService from './time_limit_handle.service';
import TimeLimitHingeService from './time_limit_hinge.service';
import TimeLimitLockService from './time_limit_lock.service';
import TimeLimitFittingService from './time_limit_fitting.service';
import TimeLimitShapeService from './time_limit_shape.service';
import TimeLimitRollerShutterSystemService from './time_limit_roller_shutter_system.service';
import TimeLimitRollerShutterProfileService from './time_limit_roller_shutter_profile.service';
import TimeLimitRollerShutterDriveService from './time_limit_roller_shutter_drive.service';
import TimeLimitRollerShutterColorGroupService from './time_limit_roller_shutter_color_group.service';
import TimeLimitProfileService from './time_limit_profile.service';
import TimeLimitGarageControlService from './time_limit_garage_control.service';
import TimeLimitGaragePanelStructureColorService from './time_limit_garage_panel_structure_color.service';

export default angular.module('icc.timeLimit', [])
    .service('TimeLimitService', TimeLimitService)
    .service('TimeLimitSystemService', TimeLimitSystemService)
    .service('TimeLimitFillingService', TimeLimitFillingService)
    .service('TimeLimitSealColorService', TimeLimitSealColorService)
    .service('TimeLimitAccessoryService', TimeLimitAccessoryService)
    .service('TimeLimitHandleService', TimeLimitHandleService)
    .service('TimeLimitHingeService', TimeLimitHingeService)
    .service('TimeLimitLockService', TimeLimitLockService)
    .service('TimeLimitFittingService', TimeLimitFittingService)
    .service('TimeLimitShapeService', TimeLimitShapeService)
    .service('TimeLimitRollerShutterSystemService', TimeLimitRollerShutterSystemService)
    .service('TimeLimitRollerShutterProfileService', TimeLimitRollerShutterProfileService)
    .service('TimeLimitRollerShutterDriveService', TimeLimitRollerShutterDriveService)
    .service('TimeLimitRollerShutterColorGroupService', TimeLimitRollerShutterColorGroupService)
    .service('TimeLimitProfileService', TimeLimitProfileService)
    .service('TimeLimitGarageControlService', TimeLimitGarageControlService)
    .service('TimeLimitGaragePanelStructureColorService', TimeLimitGaragePanelStructureColorService)
    .name;
