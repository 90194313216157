/**
 * Kontroler okna modal skrzydła
 * @param {object} $uibModalInstance Okno modal
 * @param {object} $rootScope     Angular root scope
 */
export default function ModalSashCtrl($uibModalInstance, $rootScope, $filter, InfoFactory) {
    'ngInject';

    this.close = function close() {
        $rootScope.hideDraw = true;
        $uibModalInstance.close();
    };

    this.openInfo = function(youtubeAdrress) {
        youtubeAdrress += '?rel=0';
        InfoFactory.openModal({title: $filter('translate')('INTERFACE|Pomoc - edycja konstukcji'), message: `<iframe width="855" height="500" src="${youtubeAdrress}" frameborder="0" allowfullscreen></iframe>` });
    };
}
