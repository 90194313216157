import angular from 'angular';

/**
 * Kontroler okna modal systemu
 * @param {object} $scope         Angular Scope
 * @param {object} $uibModalInstance Okno modal
 * @param {object} drives         Napędy
 * @param {object} selDrive       Ustawianie napędu
 * @param {object} type           Typ
 */
export default function ModalSystemsCtrl($scope, $uibModalInstance, drives, selDrive, type, EventBusService) {
    'ngInject';

    var vm      = this;
    vm.drives   = drives;
    vm.selDrive = selDrive;
    vm.type     = type;

    vm.selectDrive = function(drive) {
        $uibModalInstance.close(drive);
    };

    vm.isSelected = function(drive) {
        if (angular.isDefined(vm.selDrive) && vm.selDrive !== null && angular.isDefined(drive) && drive !== null) {
            if (drive.id == vm.selDrive.id /* && "RAL"==$scope.data.selColor.colorType*/) {
                return true;
            }
        }
        return false;
    };

    vm.close = function() {
        $uibModalInstance.close();
    };
}
