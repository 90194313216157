import { core } from '../../../helpers';

/**
 * Przeniesienie profili rolet do profili
 */
export function changesInVersion12(oldConfiguration, dataRequiredToUpdate) {
    const configuration = core.copy(oldConfiguration);
    if (typeof configuration.Measurements !== 'undefined' && configuration.Measurements != null) {
        const Measurements = core.copy(configuration.Measurements);
        let widthOutside = Measurements.widthInside
        let widthInside  = Measurements.widthOutside
        let hightOutside = Measurements.hightInside
        let hightInside  = Measurements.hightOutside
        
        Measurements.widthInside  = widthInside 
        Measurements.widthOutside = widthOutside
        Measurements.hightInside  = hightInside 
        Measurements.hightOutside = hightOutside
        
        configuration.Measurements = Measurements;
    }
    return configuration;
}