import angular from 'angular';

/**
 * Funkcja koloru szyb
 */
export default function GlassColorFilter() {
    'ngInject';

    return function(glass, colorId) {
        var glassArr = [];
        angular.forEach(glass, function(obj) {
            glassArr.push(obj);
        });
        if (angular.isUndefined(colorId) || colorId === null || colorId === '') {
            return glassArr;
        } else {
            return glassArr.filter(function(gl) {
                return parseInt(colorId) == parseInt(gl.glass_color_id);
            });
        }
    };
}
