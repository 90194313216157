import Common from '../Common';
import core from '../Core';
import {logger} from '../helpers';

export default function UnitMFilter($filter, UserService, IccConfig) {
    'ngInject';

    /**
     * Filtr do konwersji wymarów.
     * @param  {number} data           Liczba do konwersji
     * @param  {number} hasunit        1 lub 0 - czy do wymiaru dołączany ma być symbol wymiaru (mm, ″)
     * @param  {number} inmm           Czy do wymiaru innego niż w mm dołączyć informacje o wymiarze w jednostkach mm?
     * @param  {number} mb             Metr bieżący - 1 lub 2 - cena za metr bieżący?
     * @param  {string} dimension_unit Docelowa jednostka, przy braku bierze domyślną jednostkę użytkownika / rynku
     * @return {string}                Skonwertowany wymiar.
     */
    return (data, hasunit = 0, inmm = 0, mb = 0, dimension_unit = '') => {
        const Core = new core(logger);
        const dimensionUnit = dimension_unit || UserService.get().dimension_unit;
        const mbUnit = $filter('translate')('DIMENSION|mb');
        const unit = {
            type     : dimensionUnit === 'mm' ? 0 : 1,
            unit     : dimensionUnit,
            separator: dimensionUnit === 'mm' ? ',' : '.'
        };
        const inchToMeter = IccConfig.Configurators.inchToMilimeter / 1000;

        if (Common.isString(data)) {
            data = +data;
        }
        if (!Common.isNumber(data) || isNaN(data) || !Common.isDefined(data)) {
            return 'NaN';
        }

        let unitext = '';
        if (hasunit) {
            if (unit.type === 0) {
                unitext = mb ? mbUnit : 'm';
            } else {
                unitext = '″';
            }
        }

        if (mb === 2 || mb === 3) {
            // milimetry, zwróć po prostu - nie dodawaj nawet drugiego wymiaru, bo po co
            if (unit.type === 0) {
                return mb === 3 ? data : Core.formatNumber(data, 2, 100, ' ', unit.separator) + ' ' + unitext;
            // do konwertowanych jednostek dodaj wymiar
            } else if (inmm) {
                return mb === 3 ? data * inchToMeter : Core.formatNumber(data * inchToMeter, 2, 100, ' ', unit.separator)
                    + ' ' + unitext + ' (' + data + (mb ? ' ' + mbUnit + ')' : ' m)');
            } else {
                return mb === 3 ? data * inchToMeter: Core.formatNumber(data * inchToMeter, 2, 100, ' ', unit.separator)
                    + ' ' + unitext;
            }
        } else {
            // milimetry, zwróć po prostu - nie dodawaj nawet drugiego wymiaru, bo po co
            if (unit.type === 0) {
                return Core.formatNumber(data, 2, 100, ' ', unit.separator) + ' ' + unitext;
            // do konwertowanych jednostek dodaj wymiar
            } else if (inmm) {
                return Core.formatNumber(data / inchToMeter, 2, 100, ' ', unit.separator)
                    + ' ' + unitext + ' (' + data + (mb ? ' ' + mbUnit + ')' : ' m)');
            } else {
                return Core.formatNumber(data / inchToMeter, 2, 100, ' ', unit.separator)
                    + ' ' + unitext;
            }
        }
    };
}
