import {MountingMethod} from 'configurations/parts/window';
import * as $ from 'jquery';

export default class  GlazingUnitModalController {

    resolve: {
        mountingMethods: MountingMethod[],
        selMountingMethod: MountingMethod
    };
    close: ({$value}) => any;
    dismiss: () => any;

    constructor(private ScrollbarService) {}

    $onChanges() {
        this.ScrollbarService.update();
    }

    selectmountingMethod(mountingMethod) {
        this.resolve.selMountingMethod = mountingMethod;
    }

    closeModal(save = false) {
        if (save) {
            this.close({$value: this.resolve.selMountingMethod});
        } else {
            this.close({$value: false});
        }
    }
}
