import angular from 'angular';

import ColorsCtrl from './ColorsCtrl';
import ColorsFactory from './ColorsFactory';
import DimensionsCtrl from './DimensionsCtrl';
import DimensionsFactory from './DimensionsFactory';
import DrivesCtrl from './DrivesCtrl';
import DrivesFactory from './DrivesFactory';
import ModalColorCtrl from './ModalColorCtrl';
import ModalDriveElementCtrl from './ModalDriveElementCtrl';
import ModalDrivesCtrl from './ModalDrivesCtrl';
import ModalDrivesManualCtrl from './ModalDrivesManualCtrl';
import ModalMontagesInfoCtrl from './ModalMontagesInfoCtrl';
import ModalSystemsCtrl from './ModalSystemsCtrl';
import {GuidesModalComponent} from './guides-modal/guides-modal.component';
import {SlatsModalComponent} from './slats-modal/slats-modal.component';
import SchemasCtrl from './SchemasCtrl';
import SchemasFactory from './SchemasFactory';
import TypesCtrl from './TypesCtrl';
import TypesFactory from './TypesFactory';

export default angular.module('icc.configurator.steps.roller_shutter', [])
    .controller('DrivesCtrl', DrivesCtrl)
    .controller('ModalDriveElementController', ModalDriveElementCtrl)
    .controller('ModalDrivesController', ModalDrivesCtrl)
    .controller('ModalDrivesManualController', ModalDrivesManualCtrl)
    .controller('ModalMontagesInfoCtrl', ModalMontagesInfoCtrl)
    .controller('ModalRollerColorCtrl', ModalColorCtrl)
    .controller('ModalSystemsCtrl', ModalSystemsCtrl)
    .component('modalGuidesSelection', GuidesModalComponent)
    .component('modalSlatsSelection', SlatsModalComponent)
    .controller('RollerColorsCtrl', ColorsCtrl)
    .controller('RollerDimensionsCtrl', DimensionsCtrl)
    .controller('SchemasCtrl', SchemasCtrl)
    .controller('TypesCtrl', TypesCtrl)
    .factory('DrivesFactory', DrivesFactory)
    .factory('RollerColorsFactory', ColorsFactory)
    .factory('RollerDimensionsFactory', DimensionsFactory)
    .factory('SchemasFactory', SchemasFactory)
    .factory('TypesFactory', TypesFactory)
    .name;
