import {NgModule} from '@angular/core';

import BrowserGlazingBeadsService from './glazing-beads.service';
import GlazingBeadsService from 'glazing-beads.service';
import {GlazingBeadColorsService} from './glazing-bead-colors.service';
import {SealColorsService} from './seal-colors.service';
import {SiliconeColorsService} from './silicone-colors.service';
import {WarmEdgesService} from './warm-edges.service';
import {FillingsService} from './fillings.service';
import GlazingUnitsService from './glazing-units.service';
import {BondedGlazingService} from './bonded-glazing.service';
import GlazingSizesService from 'configurators/glazing-sizes.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        {provide: GlazingBeadsService, useClass: BrowserGlazingBeadsService},
        GlazingBeadColorsService,
        SealColorsService,
        SiliconeColorsService,
        WarmEdgesService,
        FillingsService,
        GlazingSizesService,
        GlazingUnitsService,
        BondedGlazingService,
    ],
})
export class GlazingsModule {
    constructor() {}
}
