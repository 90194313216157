import { Configuration } from './Configuration';
import { PriceSegment, DiscountGroups, PricePart } from '../price/Prices';
import { TimeLimitSegment } from '../../application/src/time_limit/time_limit_functions';
import { System, Dimensions, Panel, Structure, Control, Guide } from './parts/garage';
import { Accessory, Color } from './parts/common';
import { core } from '../helpers';

import GarageActiveConfiguration from './GarageActiveConfiguration';

export default class GarageConfiguration implements Configuration {

    public static is(configuration): configuration is GarageConfiguration {
        return configuration instanceof GarageConfiguration || configuration.$version;
    }
    $version = 6;
    type: 'garage' = 'garage';
    name = '';
    price: number | null = null;
    priceNoMargin: number | null = null;
    priceAfterDiscounts: number | null = null;
    quantity: number = 1;
    priceSegments: PriceSegment[] = [];
    priceSegmentsNoMargin: PriceSegment[] = [];
    priceParts: PricePart[] = [];
    pricePartsNoMargin: PricePart[] = [];
    discountGroups: DiscountGroups = {};
    discountGroupsNoMargin: DiscountGroups = {};
    timeLimit: number | null = null;
    timeLimitsStack: TimeLimitSegment[] = [];
    title = '';
    description = '';
    attachments: any[] = [];
    valid: {} = {};

    system: System = null;
    height: number = 2000;
    width: number = 2500;
    dimensions: Dimensions = null;
    mountingLocation: 'in' | 'out' = 'in';
    dimensionsType: 'standard' | 'custom' = 'standard';
    panel: Panel = null;
    structure: Structure = null;
    insulation: boolean = false;
    control: Control = null;
    outerColor: Color = null;
    guide: Guide = null;
    accessories: Accessory[] = [];

    constructor(configuration, dataRequiredToUpdate?: any, conversion = true) {
        if (GarageConfiguration.is(configuration) && conversion) {
            this.runConversion(configuration, dataRequiredToUpdate);
        } else {
            if (GarageActiveConfiguration.is(configuration)) {
                this.name = configuration.Name;
                this.price = configuration.Price;
                this.priceNoMargin = configuration.PriceNoMargin;
                this.priceAfterDiscounts = configuration.PriceAfterDiscounts || configuration.Price;
                this.quantity = configuration.Quantity;
                this.priceSegments = configuration.PriceSegments;
                this.priceSegmentsNoMargin = configuration.PriceSegmentsNoMargin;
                this.priceParts = configuration.PriceParts;
                this.pricePartsNoMargin = configuration.PricePartsNoMargin;
                this.discountGroups = configuration.DiscountGroups;
                this.discountGroupsNoMargin = configuration.DiscountGroupsNoMargin;
                this.title = configuration.Title;
                this.description = configuration.Description;
                this.attachments = configuration.Attachments;
                this.valid = configuration.valid;
                this.timeLimit = configuration.timeLimit;
                this.timeLimitsStack = configuration.timeLimitsStack;

                this.system = {
                    id: Number(configuration.System.id),
                    name: configuration.System.name,
                    titleImage: configuration.System.title_image,
                };
                this.height = Number(configuration.Height);
                this.width = Number(configuration.Width);
                this.dimensions = {
                    circuit: Number(configuration.Shape.circuit),
                    width: Number(configuration.Shape.width),
                    height: Number(configuration.Shape.height),
                    w1: Number(configuration.Shape.w1),
                    w2: Number(configuration.Shape.w2),
                    n: Number(configuration.Shape.n),
                    e: Number(configuration.Shape.e),
                };
                this.mountingLocation = configuration.MountingLocation;
                this.dimensionsType = configuration.DimensionsType;
                this.panel = {
                    id: Number(configuration.Panel.id),
                    name: configuration.Panel.name,
                    isolation: configuration.Panel.isolation,
                    symbol: configuration.Panel.symbol,
                };
                this.structure = {
                    id: Number(configuration.Structure.id),
                    name: configuration.Structure.name,
                };
                this.insulation = configuration.Insulation;
                if (configuration.Control) {
                    this.control = {
                        id: Number(configuration.Control.id),
                        name: configuration.Control.name,
                        guide: configuration.Control.guide && configuration.Control.guide.symbol,
                    };
                }
                if (
                    configuration.Colors
                    && configuration.Colors.outer
                    && configuration.Colors.outer.id
                ) {
                    this.outerColor = {
                        id: Number(configuration.Colors.outer.id),
                        name: configuration.Colors.outer.name,
                        code: configuration.Colors.outer.code,
                        isDefault: false,
                        RAL: Boolean(configuration.Colors.outer.RAL),
                    };
                }
                this.guide = configuration.Guide;
                this.accessories = configuration.Accessories.map<Accessory>(
                    accessory => new Accessory(accessory)
                );
            }
        }
    }

    protected runConversion(configuration, dataRequiredToUpdate: any) {
        let updatedConfiguration = core.copy(configuration);
        let updateFunction;
        for (let version = configuration.$version + 1; version <= this.$version; version++) {
            updatedConfiguration.$version = version;
            updateFunction = `changesInVersion${version}`;
            if (this[updateFunction] && typeof this[updateFunction] === 'function') {
                updatedConfiguration = this[updateFunction](
                    updatedConfiguration,
                    dataRequiredToUpdate
                );
            }
        }
        (Object as any).entries(updatedConfiguration).forEach(([key, value]) => (this[key] = value));
    }

    /**
     * Prowadzenie
     */
    private changesInVersion6(oldConfiguration, dataRequiredToUpdate) {
        const configuration = core.copy(oldConfiguration);
        if (configuration.guide) {
            configuration.guide = dataRequiredToUpdate.garageGuides.find(
                guide => guide.symbol === configuration.guide
            );
        }
        return configuration;
    }
}
