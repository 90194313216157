import angular from 'angular';

/**
 * Modal kontrolera dóbr komplementarnych
 * @param {Object} $scope                    scope
 * @param {Object} $uibModalInstance            modalInstance
 * @param {Object} $timeout                  timeout
 * @param {Object} filterFilter              filterFilter
 * @param {Object} Core                      Core
 * @param {Object} ComplementaryGoodsFactory ComplementaryGoodsFactory
 * @param {Object} ConfigurationsService     ConfigurationsService
 * @param {Object} ComplementaryGoodsFilter  ComplementaryGoodsFilter
 * @param {Array} ComplementaryGoods        ComplementaryGoods
 * @param {Object} categories                categories
 * @param {Object} subcategories             subcategories
 * @param {Object} sash                      sash
 * @param {Object} sashNum                   sashNum
 * @param {Object} forIt                     forIt
 * @param {Object} colors                    colors
 * @param {Object} noPrice                   noPrice
 */
export default function ModalComplementaryGoodsCtrl($scope, $uibModalInstance, $timeout, filterFilter, Core,  ComplementaryGoodsFactory,
                            ConfigurationsService, ComplementaryGoodsFilter, ComplementaryGoods, categories,
                            subcategories, sash, sashNum, forIt, colors, noPrice, ScrollbarService) {
    'ngInject';

    var vm = this;
    vm.forIt              = forIt;
    vm.sash               = sash;
    vm.ComplementaryGoods = ComplementaryGoods;
    vm.categories         = categories;
    vm.subcategories      = subcategories;
    vm.config             = ConfigurationsService.conf.Current;
    vm.sashNum            = sashNum;
    vm.colors             = colors;
    vm.add                = add;
    vm.refresh            = refresh;
    vm.close              = closeModal;
    vm.remove             = ComplementaryGoodsFactory.remove;
    vm.noPrice            = noPrice;

    for (var i = vm.ComplementaryGoods.length - 1; i >= 0; i--) {
        if (angular.isArray(vm.ComplementaryGoods[i].colors_ids)) {
            if (vm.ComplementaryGoods[i].price_source === 'colors') {
                vm.ComplementaryGoods[i].selectedColor = vm.ComplementaryGoods[i].colors_ids[0];
            }
        }
    }

    /**
     * Famknięcie modala
     * @return {[type]} [description]
     */
    function closeModal() {
        $uibModalInstance.close([vm.selection]);
    }

    /**
     * Odświeżenie
     */
    function refresh() {
        ScrollbarService.update();
    }

    /**
     * Dodanie
     * @param {Object} accessory Akcesorium
     * @param {Object} color     Kolor
     * @param {Object} $event    Zdarzenie
     */
    function add(accessory, color, $event) {
        ComplementaryGoodsFactory.add(sash, accessory, Core.fIdO(colors, color), $event);
    }
}
