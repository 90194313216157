import {NgModule} from '@angular/core';

import ColorsDefaultsService from './colors-defaults.service';
import SealColorsDefaultsService from './seal-colors-defaults.service';
import SiliconeColorsDefaultsService from './silicone-colors-defaults.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        ColorsDefaultsService,
        SealColorsDefaultsService,
        SiliconeColorsDefaultsService
    ],
})
export class ColorsModule { }
