import Common from '../Common';
import { ConfiguratorsDataService } from 'configurators/configurators-data.service';
import { EventBusService } from 'event-bus.service';
import { APP_CONFIG, AppConfig } from 'config';
import { Injectable, Inject } from '@angular/core';

@Injectable()
export default class HandleHeightService {
    handlerHeights = [];
    systemHeight = false;

    constructor(
        private ConfiguratorsDataService: ConfiguratorsDataService,
        private EventBusService: EventBusService,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {
        if (this.ConfiguratorsDataService.loaded) {
            this.init();
        }
        this.EventBusService.subscribe('loadedConfiguratorsData', data => {
            this.init();
        });
        this.EventBusService.subscribe(['icc-redraw', 'changedSashes', 'changedBalcony'], data => {
            this.validateHandleHeights(data.activeConfiguration);
        });
    }

    init() {
        this.handlerHeights = this.ConfiguratorsDataService.data.windowHandlerHeights;
        this.systemHeight = this.ConfiguratorsDataService.data.systemHeight;
    }

    /**
     * Funkcja domyślnej wysokości
     * @param  {object} sash Skrzydło
     */
    defaultHeight(sash, conf) {
        if (
            !conf
            || !conf.Frames
            || !conf.Frames.length
            || !sash
            || !sash.frame
            || !sash.frame.bottom
        ) {
            return null;
        }

        const frame = conf.Frames.find(f => sash.frameId === f.id);
        if (!frame && frame.frame.length === 0) {
            return null;
        }

        const bottomProfileId =
            sash.nearMullions.bottom !== -1
                ? conf.Mullions.find(mullion => mullion.id === sash.nearMullions.bottom).profileId
                : frame.frame[0].profileId;
        const bottomProfile = conf.UsedProfiles.find(el => el.id === bottomProfileId);

        const sashFrameProfile =
            conf.UsedProfiles.find(el => el.id === sash.frame.bottom.profileId) || {};
        const sashFrameRebate = !this.config.IccConfig.Configurators.handlesWithRebateHeight
            ? Number(sashFrameProfile.rebateWidth) * 2 || 0
            : 0;

        const sashFrame = conf.drawData.sashFrame.find(o => o.sashId === sash.id);
        const sashFittingsHeight = sashFrame ? sashFrame.outer.rect.height - sashFrameRebate : 0;
        const fittingsGroove =
            this.config.IccConfig.Configurators.handlesAddFittingsGroove && bottomProfile
                ? bottomProfile.spaceSash
                : 0;

        let handleHeightData = [];
        if (Common.isArray(this.handlerHeights)) {
            handleHeightData = this.handlerHeights.filter(
                el =>
                    parseFloat(el.window_height_from) <= sashFittingsHeight
                    && parseFloat(el.window_height_to) >= sashFittingsHeight
                    && el.window_lines
                    && el.window_lines.indexOf(conf.System.id) > -1
            );
        }
        if (handleHeightData.length) {
            this.systemHeight = true;
        }
        if (!handleHeightData.length) {
            handleHeightData = this.handlerHeights.filter(
                el =>
                    parseFloat(el.window_height_from) <= sashFittingsHeight
                    && parseFloat(el.window_height_to) >= sashFittingsHeight
                    && el.system_types
                    && el.system_types.indexOf(conf.System.type) > -1
            );
        }
        const handleHeightBalcony = handleHeightData.filter(
            el => el.balcony_window === conf.Balcony
        );
        let defaultHeightV = '0';

        if (!handleHeightData || !handleHeightData.length) {
            return defaultHeightV;
        }
        if (conf.type !== 'door') {
            if (!Common.isUndefined(handleHeightBalcony[0]) && sash.nearMullions.bottom === -1) {
                defaultHeightV = parseInt(handleHeightBalcony[0].height.default) + fittingsGroove;
            } else if (!Common.isUndefined(handleHeightData[0])) {
                defaultHeightV = parseInt(handleHeightData[0].height.default) + fittingsGroove;
            }
        } else {
            if (this.systemHeight && Common.isDefined(handleHeightData[0].height)) {
                if (handleHeightData[0].height.default > 0) {
                    defaultHeightV = parseInt(handleHeightData[0].height.default) + fittingsGroove;
                } else if (handleHeightData[0].height.middle === '1') {
                    defaultHeightV = 'middle';
                } else {
                    defaultHeightV =
                        parseInt(handleHeightData[0].height.option[0]) + fittingsGroove;
                }
            } else {
                defaultHeightV = Math.round(sash.rHeight / 2) + fittingsGroove;
                if (sash.rHeight > 1200) {
                    defaultHeightV = 1000 + fittingsGroove;
                } else {
                    defaultHeightV = Math.round(sash.rHeight / 2) + fittingsGroove;
                }
            }
        }
        return defaultHeightV;
    }

    validateHandleHeights(conf) {
        if (conf.Sashes && conf.Sashes.length) {
            conf.Sashes.forEach(sash => {
                if (sash.handle && sash.handle.id && !sash.rHandleY) {
                    sash.rHandleY = this.defaultHeight(sash, conf);
                }
                const defaultHeight = this.defaultHeight(sash, conf);
                if (
                    Common.isDefined(sash.rHandleY)
                    && sash.rHandleY !== null
                    && (defaultHeight === sash.rHandleY || !sash.defaultHandleHeight)
                    && Common.isDefined(sash.defaultHandleHeight)
                ) {
                    if (sash.handleHeightType === 'middle') {
                        sash.rHandleY = sash.rHeight / 2;
                    }
                } else {
                    sash.rHandleY = defaultHeight;
                    sash.defaultHandleHeight = true;
                }
            });
            this.EventBusService.post({
                key: 'icc-redraw',
                value: 'sash',
            });
        }
    }
}
