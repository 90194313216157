import StateService from 'state.service';
import DatabaseManager, { DatabaseManagerToken } from 'sync/Databases/DatabaseManager';
import { IccDatabase } from 'sync/Databases/IccDatabase';
import Common from 'Common';
import { OfferSummaryService } from './offer-summary.service';
import PositionService from 'offers/PositionService';
import { Injectable, Inject } from '@angular/core';

@Injectable()
export default class ManyPositionsService {
    constructor(
        private StateService: StateService,
        @Inject(DatabaseManagerToken) private databaseManager: DatabaseManager
    ) {}

    async getMany(arr, force = false, noUpdateState = false) {
        let positions = [];
        const offerId = this.StateService.state.offer_id;
        if (force) {
            positions = await (this.databaseManager.get('Position') as IccDatabase).getMany(arr);

            if (!noUpdateState) {
                this.StateService.setKey(
                    'positions',
                    positions.filter(pos => pos.doc.dealer_offer_id === offerId)
                );
            }
        } else {
            if (this.StateService.state.positions && this.StateService.state.positions.length) {
                positions = this.StateService.state.positions.filter(
                    pos => pos.doc && arr.indexOf(pos.doc.tmp_id) > -1
                );
            }
            const foundedPositionIds = positions.map(p => p.doc.tmp_id);
            const leftPositions = arr.filter(p => foundedPositionIds.indexOf(p) === -1);
            if (leftPositions.length > 0) {
                const positionsFromDB = await (this.databaseManager.get(
                    'Position'
                ) as IccDatabase).getMany(leftPositions);
                positions.push(...positionsFromDB);
                if (!noUpdateState) {
                    this.StateService.setKey(
                        'positions',
                        positions.filter(pos => pos.doc.dealer_offer_id === offerId)
                    );
                }
            }
        }
        return positions;
    }

    async updatePosition(position) {
        const offerId = this.StateService.state.offer_id;
        const positions = this.StateService.getKey('positions');
        const matchedPositions = positions.filter(
            pos => pos.doc && pos.doc.tmp_id === position.tmp_id
        );
        if (matchedPositions && matchedPositions.length > 0) {
            Object.assign(matchedPositions[0].doc, position);
        }
    }

    /**
     * Lista pozycji z konkretnymi Id-kami
     * @param  {array} arr      Tabliza kluczy pozycji
     * @param  {string} offerId Id oferty pozycji
     * @return {object}         Promise z lista znalezionych pozycji, lista nieznalezionych pozycji, lista szukanych pozycji
     */
    async listById(
        arr,
        offerId = null,
        fromUpdateOffer = false,
        forceFromDB = false,
        noUpdateState = false
    ) {
        const newPositions = [];
        let notFoundPositions = [];
        const foundPositions = [];
        const posObj = {};
        let i;
        const x = (Math.random() * 1000) % 1000;

        const positions = await this.getMany(arr, forceFromDB, noUpdateState);

        for (i = 0; i < positions.length; i++) {
            if (Common.isObject(positions[i].doc)) {
                positions[i].doc = PositionService.parsePositionValues(positions[i].doc);
                newPositions.push(positions[i]);
                foundPositions.push(positions[i].doc.tmp_id);
            }
        }

        notFoundPositions = arr.filter(tmpId => foundPositions.indexOf(tmpId) === -1);

        return {
            pos: newPositions,
            seq: arr,
            not_found: notFoundPositions,
        };
    }
}
