import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {
    $uibModalProvider,
    $translateProvider,
    $injectorProvider,
    $sceProvider,
    currentConfiguratorServiceProvider,
    infoFactoryProvider,
} from './ajs-upgraded-providers';
import BrowserConfigurationsService from './configurations.service';
import ConfigurationsService from 'configurations/configurations.service';
import ConfiguratorsAvailabilityService from './configurators-availability.service';
import { DrawService } from 'configurators/draw.service';
import { EventBusService } from 'event-bus.service';
import { BrowserIssuesService } from './issues.service';
import { IssuesService } from 'issues.service';
import { StepsModule } from 'configurator/steps/steps.module';
import { LayoutModule } from 'configurator/layout/layout.module';
import { DrawModule } from 'configurator/draw/draw.module';
import { ValidationService } from 'configurators/validation.service';
import { ColorsModule } from 'configurator/colors/colors.module';
import BrowserUserService from 'panel/user.service';
import UserService from 'user.service';
import ParametersService from 'configurators/parameters.service';
import ResourceService from '../sync/resource.service';
import { CoupledWindowModule } from './coupled/coupled-window.module';

@NgModule({
    imports: [
        CommonModule,
        StepsModule,
        LayoutModule,
        ColorsModule,
        DrawModule,
        CoupledWindowModule,
    ],
    declarations: [],
    exports: [],
    entryComponents: [],
    providers: [
        BrowserUserService,
        { provide: UserService, useExisting: BrowserUserService },
        ResourceService,
        EventBusService,
        BrowserConfigurationsService,
        { provide: ConfigurationsService, useExisting: BrowserConfigurationsService },
        $uibModalProvider,
        DrawService,
        $translateProvider,
        $sceProvider,
        infoFactoryProvider,
        { provide: IssuesService, useClass: BrowserIssuesService },
        currentConfiguratorServiceProvider,
        ParametersService,
        ValidationService,
        ConfiguratorsAvailabilityService,
    ],
})
export class ConfiguratorModule {}
