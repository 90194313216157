import PriceBaseService from 'price/price-base.service';
const priceDetailsModalTemplate = require<string>('ngtemplate-loader!../../../application/src/price/details-modal/details-modal.html');
import {logger} from 'helpers';
import {Injectable, Inject} from '@angular/core';
import {EventBusService} from 'event-bus.service';
import ConfigurationsService from 'configurations/configurations.service';

/**
 * Usługa liczenia ceny
 *
 * @export
 * @class PriceService
 */
@Injectable()
export default class PriceService {
    syncRequests: number = 0;
    syncingPrice = false;

    /**
     * Creates an instance of PriceService.
     *
     * @param {ng.ui.bootstrap.IModalService} $uibModal $uibModal
     * @param {any} ConfigurationsService ConfigurationsService
     * @param {PriceBaseService} PriceBaseService PriceBaseService
     *
     * @memberOf PriceService
     */
    constructor(
        @Inject('$uibModal') private $uibModal: angular.ui.bootstrap.IModalService,
        @Inject('$rootScope') private $rootScope: angular.IRootScopeService,
        private configurationsService: ConfigurationsService,
        private PriceBaseService: PriceBaseService,
        private eventBusService: EventBusService,
    ) {
        'ngInject';

        this.eventBusService.subscribe([
            'changedBalcony',
            'changedFillings',
            'changedSashes',
            'changedStep',
            'putAlignmentInField',
            'putExtensionOnSide',
            'removedAlignmentInField',
            'removedAlignment',
            'removedExtensionFromSide',
            'setConstructionColor',
            'setExtensionProfile',
            'setFrameProfile',
            'setGlazingInSash',
            'setGlazingBeadColor',
            'setGlazingBeadInSash',
            'setMullionProfile',
            'setProfileSet',
            'setSashProfile',
            'setSealColor',
            'setSiliconeColor',
            'setShape',
            'setSystem',
            'setWarmEdge',
            'setLowThreshold',
            'unsetLowThreshold',
            'insertMuntins',
            'removeMuntins',
            'updateMuntins',
            'setMuntinColor'
        ], data => {
            try {
                if (data.activeConfiguration) {
                    this.count();
                }
            } catch (err) {
                logger.error(err);
            }
        });
    }

    async syncPrice(fromTimeout = false) {
        if (this.syncRequests > 0 || !this.configurationsService.conf.Current.PriceValid) {
            this.syncRequests = 0;

            this.PriceBaseService.setPrice(this.configurationsService.conf.Current);
            if (fromTimeout) {
                this.$rootScope.$apply();
            }
        }

        if (this.syncingPrice) {
            setTimeout(() => {
                this.syncPrice(true);
            }, 100);
        }
    }

    /**
     * Przelicza cenę
     *
     * @param {*} config Konfiguracja
     * @param {*} [offer]  Oferta
     * @returns {number} Wyliczona cena
     *
     * @memberOf PriceService
     */
    count(config?, offer?) {
        if (config) {
            return this.PriceBaseService.setPrice(config, offer);
        } else {
            if (this.configurationsService.conf.Current.PriceValid) {
                this.configurationsService.conf.Current.PriceValid = false;
            }
            this.syncRequests++;
            return NaN;
        }
    }

    /**
     * Otwiera modal szczegółow wyceny
     *
     * @param {any} config
     *
     * @memberOf PriceService
     */
    showPriceDetailsModal(config) {
        const modalInstance = this.$uibModal.open({
            templateUrl: priceDetailsModalTemplate,
            controller : 'ModalPriceDetailsCtrl as $ctrl',
            resolve    : {
                conf: () => config
            }
        });
    }
}
