import Common from '../../../../common/Common';

/**
 * Fabryka rysowania danych
 */
export default class DrawDataService {

    constructor() {
        'ngInject';
    }

    framesWidth = {
        frame : 35,
        record: 65,
        seal  : 20
    };

    realFramesWidth = {
        frame : 35,
        record: 65,
        seal  : 20
    };

    modalDrawingSizes = {
        width : 736,
        height: 370
    };

    layoutDrawingSizes = {
        width : 300,
        height: 300
    };

    drawingSizes = {
        width : 230,
        height: 200
    };

    hsFrame = 0;

    color = '#FFFFFF';

    colorData = {
        id : null,
        img: ''
    };

    width = 0;
    height = 0;

    ratio = 0;
    ratioOffer = 0;
    modalRatio = this.modalDrawingSizes.width / this.drawingSizes.width;

    getNewScale(width, height) {
        const destWidth = this.drawingSizes.width;
        const destHeight = this.drawingSizes.height;
        const ratioX = destWidth / width;
        const ratioY = destHeight / height;
        return ((ratioX * height) < destHeight) ? ratioX : ratioY;
    }

    getViewBoxSizeWindow(conf, ratio) {
        const x = -(this.reno(conf, 'left') * ratio);
        let y = -(this.reno(conf, 'top') * ratio);
        let height = (conf.SideAccessories.sizes.top + conf.SideAccessories.sizes.bottom + conf.Height + this.reno(conf, 'top')) * ratio + 2 * 35 + 12;
        const width = (conf.SideAccessories.sizes.left + conf.SideAccessories.sizes.right + conf.Width + this.reno(conf, 'left')) * ratio + 2 * 56 + 12;

        if (conf.RollerShutter && conf.hasRoller) {
            y -= parseInt(conf.RollerShutter.realBoxHeight) * ratio || 0;
            height += parseInt(conf.RollerShutter.realBoxHeight) * ratio || 0;
        }

        return {x, y, width, height};
    }

    getViewBoxSizeRollerShutter(conf, ratio) {
        const x = -72;
        const y = 0;
        const height = (Number(conf.RollerShutter.boxHeight) || 0) + (Number(conf.RollerShutter.rollerHeight) || 0) + 1 * 35 + 14;
        const width = (Number(conf.RollerShutter.boxWidth) || 0) + 2 * 58 + 2 * 14;

        return {x, y, width, height};
    }


    getViewBox(conf, ratio) {
        let viewBox;
        if (conf.type === 'roller_shutter') {
            viewBox = this.getViewBoxSizeRollerShutter(conf, ratio);
        } else {
            viewBox = this.getViewBoxSizeWindow(conf, ratio);
        }

        return `${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`;
    }

    reno(conf, position) {
        return 0;
    }
}
