import Common from '../Common';
import {PriceFunc, PriceElemsData, PriceSegment} from './Prices';
import {CustomPricesRecords} from './CustomPrice';
import PriceBaseService from './price-base.service';
import SealColorsDefaultsService from '../colors/seal-colors-defaults.service';
import {APP_CONFIG, AppConfig} from '../config';
import {Injectable, Inject} from '@angular/core';

@Injectable()
export default class PriceSealService {
    constructor(
        @Inject(APP_CONFIG) private config: AppConfig,
        private sealColorsDefaultsService: SealColorsDefaultsService
    ) {}

    /**
     * Wylicza dopłatę procentową za zmianę koloru uszczelki.
     *
     * @param {number} price         Wejsciowa cena
     * @param {object} PriceElems    Składowe wyceny
     * @param {array}  NoPriceCauses Powody braku wyceny
     * @param {array}  constrElems   Lista elementów konstrukcyjnych, do przeliczenia przez dopłatę.
     * @param {object} sealColor     Okucie bezpieczne
     * @return {number} Cena z doliczoną dopłatą.
     */
    @PriceFunc({
        shortName: 'sealColor',
        data: {
            sealColor  : 'conf.SealColor',
            system     : 'conf.System',
            conf       : 'conf',
            user       : 'user',
            customPrice: 'price.WindowSealColor',
            colors     : 'data.windowSealColors'
        }
    })
    suppSealColor({}: PriceElemsData,
        {sealColor, system, conf, user, customPrice, colors}: {sealColor, system, conf, user, customPrice: CustomPricesRecords, colors}
    ): PriceSegment[] {
        let factors = 1;
        if (!this.config.IccConfig.Configurators.sealColorSelect) {
            return [];
        }
        const defaultColor = this.sealColorsDefaultsService.getDefaultColor(colors, conf, user);
        if (Common.isObject(sealColor) && defaultColor && Number(defaultColor.id) !== Number(sealColor.id)) {
            let sealColorPrice;
            if (Common.isObject(customPrice) && customPrice[sealColor.id]) {
                sealColorPrice = customPrice[sealColor.id].getPrice('price');
            } else {
                sealColorPrice = parseFloat(sealColor.price);
            }

            factors *= (100 + sealColorPrice) / 100;
        }

        return <PriceSegment[]>[{
            type: 'sealColor',
            baseValue: factors,
            value: factors,
            valueType: 'percent',
            data: {
                id  : sealColor.id,
                name: sealColor.name,
            }
        }];
    }
}
