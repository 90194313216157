import moment from 'moment';

export default function DateFormatFilter() {
    'ngInject';

    return function(dateTime) {
        const result = moment(dateTime).format('YYYY.MM.DD   HH:mm');
        return result;
    };
}
