import './vendor';
import './console';

import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {UpgradeModule} from '@angular/upgrade/static';
import {AppModule} from './app.module';

import * as angular from 'angular';
import app from './app.module.ajs';
import machine from './machine';

import getUser from '../sync/Infrastructure/GetUser';
import DatabaseManager from '../sync/Databases/DatabaseManager';
import {CONFIG} from './config';
import {logger} from 'helpers';

import * as Raven from 'raven-js';
import RavenAngular from 'raven-js/plugins/angular';

enableProdMode();

if (window.supportedBrowser) {
    getUser((data: any) => {
        USER_ID = data.user_type.id;
        LANG_CODE = data.user_type.user_language || data.langCode;
        IccConfig = data.iccConfig;
        EnvConfig = data.envConfig;
        if (window.remoteHost) {
            EnvConfig.remoteHost = window.remoteHost;
        }
        Langs = data.langs;
        ProjectName = data.projectName;
        RavenDSN = data.ravenDSN;
        LocaleVersion = data.localeVersion;
        IccConfig.Version = VERSION;

        if (RavenDSN) {
            Raven
                .config(RavenDSN, {
                    release: IccConfig.Version
                })
                .addPlugin(RavenAngular, angular)
                .install();
        }

        CONFIG.IccConfig = IccConfig;
        CONFIG.EnvConfig = EnvConfig;
        CONFIG.LocaleVersion = LocaleVersion;
        CONFIG.Langs = Langs;
        CONFIG.CurLang = LANG_CODE;
        CONFIG.marketId = data.user_type.marketId;
        CONFIG.USER_ID = USER_ID;
        CONFIG.ProjectName = ProjectName;
        CONFIG.RavenDSN = RavenDSN;
        CONFIG.offerOptions.user = USER_ID;
        CONFIG.offerOptions.project = ProjectName;
        CONFIG.offerOptions.lang = LANG_CODE;
        CONFIG.Elements = {};
        if (window.elements && window.elements[LANG_CODE]) {
            Object.keys(window.elements[LANG_CODE]).forEach(key => {
                if (window.elements[LANG_CODE][key].markets && window.elements[LANG_CODE][key].content && window.elements[LANG_CODE][key].markets.includes(data.user_type.marketId.toString())) {
                    CONFIG.Elements[key.split('_')[1]] = window.elements[LANG_CODE][key];
                }
            });
        }
        const databaseManager = new DatabaseManager();
        databaseManager.initAll(data.user_type, machine, CONFIG).then(() => {
            app.constant('DatabaseManager', databaseManager);
            app.constant('IccConfig', CONFIG.IccConfig);
            app.constant('EnvConfig', CONFIG.EnvConfig);
            app.constant('LocaleVersion', CONFIG.LocaleVersion);
            app.constant('Langs', CONFIG.Langs);
            app.constant('CurLang', CONFIG.CurLang);
            app.constant('USER_ID', CONFIG.USER_ID);
            app.constant('ProjectName', CONFIG.ProjectName);
            app.constant('RavenDSN', CONFIG.RavenDSN);
            app.constant('machine', CONFIG.machine);
            app.constant('debug', CONFIG.debug);
            app.constant('isSafari', CONFIG.isSafari);
            app.constant('isElectron', CONFIG.isElectron);
            app.constant('isPhonegap', CONFIG.isPhonegap);
            app.constant('isNative', CONFIG.isNative);
            app.constant('appScheme', CONFIG.appScheme);
            app.constant('Elements', CONFIG.Elements);
            app.constant('offerOptions', CONFIG.offerOptions);
            platformBrowserDynamic().bootstrapModule(AppModule).then(platformRef => {
                const upgrade = platformRef.injector.get(UpgradeModule) as UpgradeModule;
                upgrade.bootstrap(document.documentElement, [app.name]);
                angular.module('icc').requires.push('ngRaven');
            });
        });
    }, err => logger.err(err));
}
