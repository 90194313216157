/**
 * Kontroler napędu markiz
 * @param {object} $scope                   Scope
 * @param {object} AwningDriveFactory       Fabryka napędu markiz
 */
export default function DriveCtrl($scope, AwningDriveFactory, EventBusService) {
    'ngInject';

    var vm = this;

    vm.subStep      = 6;
    vm.driveSide    = AwningDriveFactory.driveSide;
    vm.driveType    = AwningDriveFactory.driveType;
    vm.crank        = AwningDriveFactory.crank;
    vm.crankId      = AwningDriveFactory.crankId;
    vm.motor        = AwningDriveFactory.motor;
    vm.motorId      = AwningDriveFactory.motorId;
    vm.cranks       = AwningDriveFactory.cranks;
    vm.motors       = AwningDriveFactory.motors;
    vm.motorOptions = AwningDriveFactory.motorOptions;
    vm.options      = AwningDriveFactory.options;
    vm.allDrive     = true;

    vm.selectSide     = selectSide;
    vm.selectType     = selectType;
    vm.selectCrank    = selectCrank;
    vm.selectMotor    = selectMotor;
    vm.selectOption   = selectOption;

    if (AwningDriveFactory.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    function init() {
        vm.driveSide = AwningDriveFactory.driveSide;
        vm.driveType = AwningDriveFactory.driveType;
        vm.cranks = AwningDriveFactory.cranks;
        vm.motors = AwningDriveFactory.motors;
        vm.crankId = AwningDriveFactory.crankId;
        vm.motorId = AwningDriveFactory.motorId;
        vm.crank = AwningDriveFactory.crank;
        vm.motor = AwningDriveFactory.motor;
        vm.motorOptions = AwningDriveFactory.motorOptions;
    }

    /**
     * Wybór strony
     * @param  {Object} side Strony
     */
    function selectSide(side) {
        vm.driveSide = side;
        AwningDriveFactory.selectSide(side);
    }

    /**
     * Wybór typu
     * @param  {Object} type Typ
     */
    function selectType(type) {
        vm.driveType = type;
        AwningDriveFactory.selectType(type, false, true);
    }

    /**
     * Wybór korby
     * @param  {Object} crank Korba
     */
    function selectCrank(crank) {
        vm.crank = crank;
        vm.crankId = crank.id;
        AwningDriveFactory.selectCrank(crank, false, true);
    }

    /**
     * Wybór silnika
     * @param  {Object} motor Silnik
     */
    function selectMotor(motor) {
        vm.motor = motor;
        vm.motorId = motor.id;
        vm.motorOptions = motor.options;

        vm.options = AwningDriveFactory.clearOptions(vm.options, true);

        AwningDriveFactory.selectMotor(motor, false, true);
    }

    /**
     * Wybór opcji
     * @param  {Object} option Opcja
     */
    function selectOption(option) {
        vm.options = AwningDriveFactory.selectOption(vm.options, option, true);
    }
}
