import Common from '../Common';
import { Injectable } from '@angular/core';
import { IccSideColors, IccColors } from 'data-types';

@Injectable()
export default class ColorsDefaultsService {
    /**
     * Domyslne zestawy dla typów kolorów.
     * @type {Object}
     */
    private defaultColorsTypes = {
        White: {
            outer: 'NONE',
            inner: 'NONE',
            core: 'WHITE',
        },
        Cream: {
            outer: 'NONE',
            inner: 'NONE',
            core: 'CREAM',
        },
        Outer: {
            outer: 'COLOR',
            inner: 'NONE',
            core: 'WHITE',
        },
        Inner: {
            outer: 'NONE',
            inner: 'COLOR',
            core: 'WHITE',
        },
        Bilateral: {
            outer: 'COLOR',
            inner: 'COLOR',
            core: 'WHITE',
        },
        Bicolor: {
            outer: 'COLOR',
            inner: 'COLOR',
            core: 'WHITE',
        },
        '3D': {
            outer: 'COLOR',
            inner: 'COLOR',
            core: 'WHITE',
        },
        Transparent: {
            outer: 'COLOR',
            inner: 'COLOR',
            core: 'WHITE',
        },
        Opaque: {
            outer: 'COLOR',
            inner: 'COLOR',
            core: 'WHITE',
        },
    };

    getDefaultColorType(set, side, coreColorInBilateralAlwaysAsDefault = false) {
        if (this.defaultColorsTypes[set] && this.defaultColorsTypes[set][side]) {
            if (
                (set === 'Bilateral' || set === 'Bicolor')
                && side === 'core'
                && coreColorInBilateralAlwaysAsDefault
            ) {
                return 'COLOR';
            }
            return this.defaultColorsTypes[set][side];
        }
        return null;
    }

    /**
     * Zwraca rodzaj koloru ustawionego po danej stronie konstrukcji.
     * @memberof ColorsFactory
     * @param  {object} colors Obiekt z kolorami
     * @param  {string} side   Miejsce konfiguracji
     * @return {string}        Rodzaj koloru
     */
    getColorValue(colors: IccColors, side: keyof IccColors['frame']) {
        let value = 'NONE';
        if (colors.frame && colors.frame[side] && colors.sash && colors.sash[side]) {
            if (colors.frame[side].type === 'white' && colors.sash[side].type === 'white') {
                value = 'WHITE';
            } else if (colors.frame[side].type === 'cream' && colors.sash[side].type === 'cream') {
                value = 'CREAM';
            } else if (
                Common.isDefined(colors.frame[side].id)
                && Common.isDefined(colors.sash[side].id)
                && colors.frame[side].type !== 'white'
                && colors.sash[side].type !== 'white'
                && colors.frame[side].type !== 'cream'
                && colors.sash[side].type !== 'cream'
                && colors.frame[side].id === colors.sash[side].id
                && colors.frame[side].RAL === colors.sash[side].RAL
            ) {
                value = 'COLOR';
            } else if (
                Common.isDefined(colors.frame[side].id)
                || Common.isDefined(colors.sash[side].id)
            ) {
                value = 'BICOLOR';
            }
        }
        return value;
    }

    /**
     * Zwraca typ kolorów dla kolorów
     *
     * zew/wew
     * @param  {object} colors Kolory
     * @param  {number} sashesCount Liczba skrzydeł
     * @return {string}        Typ kolorów
     */
    getColorTypeForColors(colors: IccColors) {
        let colorType = 'Bicolor';
        if (Common.isObject(colors)) {
            const outer = this.getColorValue(colors, 'outer');
            const inner = this.getColorValue(colors, 'inner');
            const core = this.getColorValue(colors, 'core');

            // Biały
            if (
                this.getDefaultColorType('White', 'outer') === outer
                && this.getDefaultColorType('White', 'inner') === inner
                && this.getDefaultColorType('White', 'core') === core
            ) {
                colorType = 'White';
                // Krem w masie
            } else if (
                this.getDefaultColorType('Cream', 'outer') === outer
                && this.getDefaultColorType('Cream', 'inner') === inner
                && this.getDefaultColorType('Cream', 'core') === core
            ) {
                colorType = 'Cream';
                // zewnętrzny
            } else if (
                (['WHITE', this.getDefaultColorType('Outer', 'outer')] as any).includes(outer)
                && this.getDefaultColorType('Outer', 'inner') === inner
                && this.getDefaultColorType('Outer', 'core') === core
            ) {
                colorType = 'Outer';
                // wewnętrzny
            } else if (
                this.getDefaultColorType('Inner', 'outer') === outer
                && (['WHITE', this.getDefaultColorType('Inner', 'inner')] as any).includes(inner)
                && this.getDefaultColorType('Inner', 'core') === core
            ) {
                colorType = 'Inner';
                // Kolor obustronny
            } else if (
                this.getDefaultColorType('Bilateral', 'outer') === outer
                && this.getDefaultColorType('Bilateral', 'inner') === inner
                && colors.frame.outer.id === colors.frame.inner.id
            ) {
                colorType = 'Bilateral';
                // Bicolor
            } else {
                colorType = 'Bicolor';
            }
        }
        return colorType;
    }
}
