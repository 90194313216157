import Common from '../Common';
import { ConfigurationType } from './configurations.service';

export default class WindowSmallConfiguration {
    public static is(configuration): configuration is WindowSmallConfiguration {
        return configuration instanceof WindowSmallConfiguration;
    }
    /**
     * Wersja konfiguracji (obecnie 4).
     */
    $version: number = 4;
    /**
     * Rodzaj konfiguracji.
     */
    type: 'window' = 'window';
    /**
     * Cena netto konfiguracji.
     */
    price: number | null = null;
    /**
     * Liczba sztuk danej pozycji.
     */
    quantity: number = 1;
    /**
     * Nazwa konfiguracji ustawiana przez klienta
     */
    title: string = '';
    /**
     * Wysokość konstrukcji [mm].
     */
    height: number = 1500;
    /**
     * Szerokość konstrukcji [mm].
     */
    width: number = 1500;
    /**
     * System produktów
     */
    system: { id: string } = null;
    colors: {
        /**
         * Kolor ramy
         */
        frame: Colors;
        /**
         * Kolor skrzydeł
         */
        sash: Colors;
    } = {
        frame: {
            alushell: null,
            core: null,
            inner: null,
            outer: null,
        },
        sash: {
            alushell: null,
            core: null,
            inner: null,
            outer: null,
        },
    };
    /**
     * Rodzaj drewna
     */
    wood: Simple = null;
    /**
     * Czy z nakładką aluminiową lub system częściowo aluminiowy
     */
    hasAlushell: boolean = false;
    /**
     * Kształt konstrukcji
     */
    shape: Shape = {
        width: 1500,
        height: 1500,
        shape: 'rect',
        h1: 1500,
        h2: 0,
        h3: 1500,
        s1: 0,
        s2: 0,
        s3: 0,
        d: 0,
        type: null,
    };
    /**
     * Kwatery konstrukcji
     */
    sashes: Sash[] = [];
    /**
     * Słupki/poprzeczki
     */
    mullions: Mullion[] = [];
    /**
     * Ramka międzyszybowa
     */
    glazingSpacer: Simple = null;
    /**
     * Kolor uszczelki
     */
    sealColor: Color = null;
    /**
     * Kolor silikonu
     */
    siliconeColor: Color = null;
    /**
     * Dodatkowe informacje o szprosach
     */
    muntins: MuntinsConfig = null;
    /**
     * Okucie bezpieczne.
     */
    fitting: Simple = null;
    /**
     * Rama konstrukcji
     */
    frame: Simple = null;
    /**
     * Dodatki do konstrukcji
     */
    accessories: Accessory[] = [];
    /**
     * Dodatki do krawędzi konstrukcji
     */
    sideAccessories: {
        /**
         * Dodatki do dolnej krawędzi
         */
        bottom: Accessory[];
        /**
         * Dodatki do lewej krawędzi
         */
        left: Accessory[];
        /**
         * Dodatki do prawej krawędzi
         */
        right: Accessory[];
        /**
         * Dodatki do górnej krawędzi
         */
        top: Accessory[];
    } = {
        top: [],
        bottom: [],
        left: [],
        right: [],
    };

    constructor(conf) {
        this.$version = conf.$version || this.$version;
        this.type = conf.type || this.type;
        this.price = conf.price || this.price;
        this.quantity = conf.quantity || this.quantity;
        this.title = conf.title || this.title;
        this.height = conf.height || this.height;
        this.width = conf.width || this.width;
        this.system =
            conf.system && conf.system.id
                ? conf.system
                : conf.system
                ? { id: conf.system + '' }
                : this.system;
        if (conf.colors) {
            this.colors.frame.alushell = conf.colors.frame.alushell || this.colors.frame.alushell;
            this.colors.frame.core = conf.colors.frame.core || this.colors.frame.core;
            this.colors.frame.inner = conf.colors.frame.inner || this.colors.frame.inner;
            this.colors.frame.outer = conf.colors.frame.outter || this.colors.frame.outer;
            this.colors.sash.alushell = conf.colors.sash.alushell || this.colors.sash.alushell;
            this.colors.sash.core = conf.colors.sash.core || this.colors.sash.core;
            this.colors.sash.inner = conf.colors.sash.inner || this.colors.sash.inner;
            this.colors.sash.outer = conf.colors.sash.outter || this.colors.sash.outer;
        }
        this.wood = conf.wood || this.wood;
        this.hasAlushell = conf.hasAlushell || this.hasAlushell;
        if (conf.shape) {
            this.shape.width = conf.width || this.shape.width;
            this.shape.height = conf.height || this.shape.height;
            this.shape.shape = conf.shape.shape || this.shape.shape;
            this.shape.h1 = conf.shape.h1 || this.shape.h1;
            this.shape.h2 = conf.shape.h2 || this.shape.h2;
            this.shape.h3 = conf.shape.h3 || this.shape.h3;
            this.shape.s1 = conf.shape.s1 || this.shape.s1;
            this.shape.s2 = conf.shape.s2 || this.shape.s2;
            this.shape.s3 = conf.shape.s3 || this.shape.s3;
            this.shape.d = conf.shape.d || this.shape.d;
            this.shape.type = conf.shape.type || this.shape.type;
        }
        const maxSashId = Math.max.apply(Math, conf.sashes.map(el => el.id));
        this.sashes = conf.sashes.map(this.mapSashes.bind(this, maxSashId));
        // this.sashes = conf.sashes || this.sashes;
        this.mullions = conf.mullions || this.mullions;
        this.glazingSpacer = conf.glazingSpacer || this.glazingSpacer;
        this.sealColor = conf.sealColor || this.sealColor;
        this.siliconeColor = conf.siliconeColor || this.siliconeColor;
        this.muntins = conf.muntins || this.muntins;
        this.fitting = conf.fitting || this.fitting;
        this.frame = conf.frames[0].frame || this.frame;
        this.accessories = conf.accessories || this.accessories;
        if (conf.sideAccessories) {
            this.sideAccessories.top = conf.sideAccessories.top || this.sideAccessories.top;
            this.sideAccessories.bottom =
                conf.sideAccessories.bottom || this.sideAccessories.bottom;
            this.sideAccessories.left = conf.sideAccessories.left || this.sideAccessories.left;
            this.sideAccessories.right = conf.sideAccessories.right || this.sideAccessories.right;
        }
    }

    private mapSashes(maxSashId, sash) {
        const newSash: Sash = {
            id: Number(sash.id),
            index: Number(sash.id + 1),
            x: Number(sash.x),
            y: Number(sash.y),
            width: Number(sash.width),
            height: Number(sash.height),
            muntins: sash.muntins,
            nearMullions: sash.nearMullions,
            filling: sash.filling,
            hardware: sash.hardware || [],
        };

        if (sash.parentId != null) {
            newSash.parentId = Number(sash.parentId);
            newSash.id = ++maxSashId;
        } else {
            newSash.type = sash.type;
            newSash.intSashes = sash.intSashes.map(this.mapSashes.bind(this, maxSashId));
            newSash.intMullions = sash.intMullions || [];
            newSash.handleInner = sash.handleInner;
            newSash.frame = {
                top: {},
                bottom: {},
                left: {},
                right: {},
            };
        }

        return newSash;
    }
}

interface Simple {
    id: number;
}

interface Color {
    /**
     * API ID koloru
     */
    id: number;
    /**
     * Czy kolor jest RALem.
     */
    RAL?: boolean;
    /**
     * Ustawione automatycznie przez system czy wybrane przez użytkownika
     */
    isDefault: boolean;
}

interface Colors {
    /**
     * Kolor nakładki aluminiowej
     */
    alushell: Color | null;
    /**
     * Kolor rdzenia (barwienie w masie)
     */
    core: Color | null;
    /**
     * Kolor wewnętrzny (lakierowanie lub okleinowanie)
     */
    inner: Color | null;
    /**
     * Kolor zewnętrzny (lakierowanie lub okleinowanie)
     */
    outer: Color | null;
}

interface Shape {
    /**
     * Szerokość konstrukcji.
     */
    width: number;
    /**
     * wysokość konstrukcji.
     */
    height: number;
    /**
     * Kształt konstrukcji.
     */
    shape: 'rect' | 'triangle' | 'circle' | 'poligon' | 'arc';
    /**
     * Wysokość niełukowej części w przypadku łuku lub wysokość całkowita konstrukcji w pozostałych kształtach.
     */
    h1: number;
    /**
     * Wysokość prawej pionowej krawędzi wielokątu lub wysokość łuku. W przypadku prostokątu, trójkątu, okręgu równa 0.
     */
    h2: number;
    /**
     * Wysokość lewej pionowej krawędzi wielokątu. W przypadku prostokątu równa wysokości całkowitej, a w przypadku trójkątu, okręgu, łuku równa 0.
     */
    h3: number;
    /**
     * Długość rzutu poziomego lewej skośnej krawędzi w przypadku wielokątu, trójkąta. W przypadku prostokątu, łuku, okręgu równa szerokości całkowitej.
     */
    s1: number;
    /**
     * Szerokość górnej krawędzi wielokątu. W przypadku prostokątu, łuku, okręgu, trójkąta równa 0.
     */
    s2: number;
    /**
     * Długość rzutu poziomego prawej skośnej krawędzi w przypadku wielokątu, trójkąta. W przypadku prostokątu, łuku, okręgu równa 0.
     */
    s3: number;
    /**
     * Średnica okręgu. W przypadku prostokątu, łuku, wielokąta, trójkąta równa 0.
     */
    d: number;
    /**
     * Rodzaj łuku. F - obustronny, L - lewy, R - prawy. Pozostałe kształty - null
     */
    type: ('F' | 'L' | 'R') | null;
}

interface Sash {
    /**
     * Unikalny identyfikator kwatery w ramach konstrukcji
     */
    id: number;

    index?: number;
    /**
     * Przesunięcie poziome [mm] lewego górnego punktu kwatery od lewego górnego punktu konstrukcji. Punkt graniczny kwatery to zewnętrzna krawędź ramy lub oś słupka.
     */
    x: number;
    /**
     * Przesunięcie pionowe [mm] lewego górnego punktu kwatery od lewego górnego punktu konstrukcji. Punkt graniczny kwatery to zewnętrzna krawędź ramy lub oś poprzeczki.
     */
    y: number;
    /**
     * Szerokość [mm] kwatery. Punkt graniczny kwatery to zewnętrzna krawędź ramy lub oś słupka.
     */
    width: number;
    /**
     * Wysokość [mm] kwatery. Punkt graniczny kwatery to zewnętrzna krawędź ramy lub oś poprzeczki.
     */
    height: number;
    /**
     * Szprosy w kwaterze
     */
    muntins: Muntin[];
    nearMullions: {
        /**
         * Unikalny identyfikator poprzeczki sąsiadującej z kwaterą od dołu. -1 jeśli kwatera graniczy z ramą.
         */
        bottom: number;
        /**
         * Unikalny identyfikator słupka sąsiadującego z kwaterą z lewej. -1 jeśli kwatera graniczy z ramą.
         */
        left: number;
        /**
         * Unikalny identyfikator słupka sąsiadującego z kwaterą z prawej. -1 jeśli kwatera graniczy z ramą.
         */
        right: number;
        /**
         * Unikalny identyfikator poprzeczki sąsiadującej z kwaterą od góry. -1 jeśli kwatera graniczy z ramą.
         */
        top: number;
    };
    filling: {
        /**
         * API ID wypełnienia (pakietu szybowego lub panelu)
         */
        id: number;
    };
    /**
     * Dodatki do kwatery
     */
    hardware?: Accessory[];
    type?: Simple;
    /**
     * Unikalny identyfikator kwatery, w której znajduje się pole, w ramach konstrukcji
     */
    parentId?: number;
    /**
     * Pola kwatery. Minimum jedno pole wymagane dla kwatery innej niż fix w ramie.
     */
    intSashes?: Sash[];
    /**
     * Szczebliny
     */
    intMullions?: Mullion[];
    /**
     * Klamka
     */
    handleInner?: {
        /**
         * API ID klamki
         */
        id: number;
        /**
         * Kolor klamki
         */
        color: Color;
    };
    frame?;
}

interface Mullion {
    id: number;
    dividerId: number;
    direction: 'vertical' | 'horizontal';
    adjacentSashes: {
        top?: number[];
        bottom?: number[];
        left?: number[];
        right?: number[];
    };
    length: number;
    position: number;
}

interface Muntin {
    id: number;
    start: {
        x: number;
        y: number;
    };
    end: {
        x: number;
        y: number;
    };
}

interface MuntinsConfig {
    /**
     * Kolor wewnętrzny szprosów
     */
    colorInner: Color;
    /**
     * Kolor zewnętrzny szprosów
     */
    colorOuter: Color;
    /**
     * Czy szprosy z duplexem?
     */
    duplex: boolean;
    /**
     * Rozmiar szprosów
     */
    size: string;
    /**
     * Typ szprosów
     */
    type: Simple;
}

interface Accessory {
    id: number;
    amount: number;
    count: number;
    color: {
        accessory: Color;
    };
}
