import {TimeLimit, TimeLimitSegment} from './time_limit_functions';

export default class TimeLimitLockService {

    /**
     * Pobiera termin realizacji zamku.
     *
     * @param {object} lock   Zamek
     * @return {number} Termin realizacji zamku.
     */
    @TimeLimit({
        shortName: 'lock',
        data: {
            lock : 'conf.Lock',
        }
    })
    lockTimeLimit({timeLimits, lock}): TimeLimitSegment[] {
        if (lock == null || !lock.id || !lock.time_limit_id) {
            return null;
        }

        const timeLimitId = lock.time_limit_id;

        return <TimeLimitSegment[]>[{
            type: 'lock',
            value: timeLimits[timeLimitId],
            data: {
                id  : lock.id,
                name: lock.name,
            }
        }];
    }
}
