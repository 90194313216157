import {APP_CONFIG, AppConfig} from 'config';
import {Injectable, Inject} from '@angular/core';
import {EventBusService} from 'event-bus.service';
import {HttpClient} from '@angular/common/http';
import UserService from 'user.service';
import DatabaseManager, {DatabaseManagerToken} from 'sync/Databases/DatabaseManager';
import ConfigurationsService from 'configurations/configurations.service';
import BrowserConfiguratorsDataService from 'configurator/configurators-data.service';
import {Location} from '@angular/common';

@Injectable()
export default class LangService {

    lang = 'pol';

    constructor(
        private eventBusService: EventBusService,
        @Inject(APP_CONFIG) private config: AppConfig,
        private httpClient: HttpClient,
        private userService: UserService,
        @Inject(DatabaseManagerToken) private databaseManager: DatabaseManager,
        private configuratorsDataService: BrowserConfiguratorsDataService,
        private configurationsService: ConfigurationsService,
        private location: Location,
    ) {
        this.lang = this.config.CurLang;
    }

    getLang() {
        return this.lang;
    }

    setLang(lang) {
        this.lang = lang;
    }

    changeLangOnline(lang) {
        this.httpClient.get(`${this.config.EnvConfig.remoteHost || window.location.origin}/lang/${lang}`).toPromise().then(async () => {
            this.setLang(lang);
            const user = this.userService.get();
            user.user_language = lang;
            await this.databaseManager.initAll(user, this.config.machine, {IccConfig});
            this.eventBusService.post({
                key: 'changedLang',
                value: {
                    lang: this.lang,
                    user,
                    worker: true
                }
            });
            if (this.configuratorsDataService.loaded) {
                this.configuratorsDataService.reload();
            }
            if (this.configurationsService.conf) {
                this.configurationsService.conf.Current = this.configurationsService.createActiveConfiguration();
                this.configurationsService.conf.Default = this.configurationsService.createActiveConfiguration();
            }
            if (this.location.path().includes('/app/login')) {
                this.location.go('/app/login');
            } else {
                this.location.go('/app/sync');
            }
        });
    }

    changeLangLogin(lang) {
        const user = this.userService.get();
        this.setLang(lang);
        this.eventBusService.post({
            key: 'changedLang',
            value: {
                lang: this.lang,
                user,
                worker: false
            }
        });
    }
}
