import angular from 'angular';
import $ from 'jquery';

/**
 * Funkcja filtra akcesoriów
 */
export default function AccessoriesFilter() {
    'ngInject';

    return (accessories, categoryId, subcatId) => {
        const accessoriesArr = [];
        angular.forEach(accessories, obj => {
            accessoriesArr.push(obj);
        });
        $('.scroll.ps-container').scroll();
        if (angular.isUndefined(categoryId) || categoryId === null || categoryId === '') {
            return accessories;
        } else {
            return accessoriesArr.filter(gl => {
                if (gl.parent_id == categoryId || gl.window_accessories_category_id == categoryId) {
                    if (angular.isUndefined(subcatId) || subcatId === null || subcatId === '') {
                        return true;
                    } else if (gl.window_accessories_category_id == subcatId) {
                        return true;
                    }
                }
                return false;
            });
        }
    };
}
