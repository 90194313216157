import {Component, Input, Output, Inject, EventEmitter, NgZone } from '@angular/core';
import SystemsByTagFilter from './SystemsByTagFilter';
import {APP_CONFIG, AppConfig} from 'config';

@Component({
  selector: 'tags',
  template: require<string>('./tags.component.html')
})
export class TagsComponent {
    @Input() iccConfig;
    @Input() tags;
    @Input() items;
    @Input() multiple;
    @Output() filtered: EventEmitter<any> = new EventEmitter();

    seltags: any;

    constructor(
        @Inject(SystemsByTagFilter) private systemsByTagFilter: any,
        @Inject(APP_CONFIG) private config: AppConfig,
        private zone: NgZone
    ) {
    }

    ngOnChanges() {
        this.selectTag();
    }

    changed() {
        const selectedItems = this.systemsByTagFilter(this.items, this.seltags);
        this.filtered.emit(selectedItems);
    }

    selectTag(tag: any = null) {
        if (!this.multiple) {
            const selected = tag && tag.selected;
            this.tags = this.tags.map(item => Object.assign(item, {selected: false}));
            if (selected) {
                tag.selected = true;
            }
        }
        const stags = this.tags.filter(t => t.selected === true);
        this.seltags = stags.map(item => item.id);
        this.changed();
    }
}
