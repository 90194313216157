import * as angular from 'angular';
import * as moment from 'moment';

export default class PostCodeService {

    constructor(private $http, private $cookies, private EnvConfig) {
        'ngInject';
    }

    /**
     * Pobieranie danych
     * @param {string} callback   Callback
     */
    getData() {
        return new Promise(resolve => {
            this.$http.get((this.EnvConfig.remoteHost || window.location.origin) + '/country/post_codes/index.json').then(res => {
                resolve(res.data);
            });
        });
    }

    getPostCode() {
        const cookie = this.$cookies.get('postCodeVisited');
        if (cookie && cookie != '1') {
            return cookie.replace(/\_.*/, '');
        }
        return null;
    }
}
