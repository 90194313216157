import angular from  'angular';

import Locks from './locks/locks';

import ModalModelInfoCtrl from './ModalModelInfoCtrl';
import ModelsCtrl from './ModelsCtrl';
import ModelsFactory from './ModelsFactory';

export default angular.module('icc.configurator.steps.door', [Locks])
    .controller('ModalModelInfoCtrl', ModalModelInfoCtrl)
    .controller('ModelsCtrl', ModelsCtrl)
    .factory('ModelsFactory',  ModelsFactory)
    .name;
