import {NgModule} from '@angular/core';
import {ProfilesPriceService} from './profiles-price.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        ProfilesPriceService
    ],
})
export class CommonProfilesModule { }
