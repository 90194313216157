import { ActiveConfiguration } from './ActiveConfiguration';
import MosquitoConfiguration from './MosquitoConfiguration';
import { PriceSegment, DiscountGroups, PricePart } from '../price/Prices';
import { ConfiguratorsDataService } from '../configurators/configurators-data.service';

export default class MosquitoActiveConfiguration implements ActiveConfiguration {
    public static is(configuration): configuration is MosquitoActiveConfiguration {
        return configuration instanceof MosquitoActiveConfiguration || !configuration.$version;
    }
    type: 'mosquito' = 'mosquito';
    Name: string = '';
    Price: number = 0;
    PriceNoMargin: number = 0;
    PriceAfterDiscounts: number = 0;
    Quantity: number = 1;
    Issues: any[] = [];
    PriceSegments: PriceSegment[] = [];
    PriceSegmentsNoMargin: PriceSegment[] = [];
    PriceParts: PricePart[] = [];
    PricePartsNoMargin: PricePart[] = [];
    DiscountGroups: DiscountGroups = {};
    DiscountGroupsNoMargin: DiscountGroups = {};
    Title = '';
    Description = '';
    Attachments = [];
    valid: {} = {};
    PriceValid = false;
    drawData: any;

    System: any = {};
    Height = 1300;
    Width = 1300;
    HeightModify = 0;
    WidthModify = 0;
    Shape: any = {};
    Type: any = {};
    Colors: any = {};
    Direction: any = null;
    Montage: any = null;
    WindowLine: any = null;
    ProfilePositions: any = {};
    HoldersWidth: any = null;
    MuntinPosition: any = null;
    MuntinType: any = null;

    constructor(configuration?, private configuratorsDataService?: ConfiguratorsDataService) {
        if (configuration) {
            if (MosquitoActiveConfiguration.is(configuration)) {
                for (const propName in configuration) {
                    if (this.hasOwnProperty(propName)) {
                        this[propName] = configuration[propName];
                    }
                }
            } else {
                if (MosquitoConfiguration.is(configuration) && configuratorsDataService) {
                    this.Name = configuration.name;
                    this.Price = configuration.price;
                    this.PriceNoMargin = configuration.priceNoMargin;
                    this.PriceAfterDiscounts = configuration.priceAfterDiscounts;
                    this.Quantity = configuration.quantity;
                    this.PriceSegments = configuration.priceSegments;
                    this.PriceSegmentsNoMargin = configuration.priceSegmentsNoMargin;
                    this.PriceParts = configuration.priceParts;
                    this.PricePartsNoMargin = configuration.pricePartsNoMargin;
                    this.DiscountGroups = configuration.discountGroups;
                    this.DiscountGroupsNoMargin = configuration.discountGroupsNoMargin;
                    this.Title = configuration.title;
                    this.Description = configuration.description;
                    this.valid = configuration.valid;

                    this.System = configuratorsDataService.get(
                        'mosquitoLines',
                        configuration.system.id
                    );
                    this.Height = configuration.height;
                    this.Width = configuration.width;
                    this.HeightModify = configuration.heightModify;
                    this.WidthModify = configuration.widthModify;
                    this.Shape = {
                        width: configuration.width,
                        height: configuration.height,
                        circuit: configuration.circuit,
                    };
                    if (configuration.variant) {
                        this.Type = configuratorsDataService.get(
                            'mosquitoTypes',
                            String(configuration.system.id),
                            configuration.variant.id
                        );
                        if (configuration.variant.hasDirection) {
                            this.Direction = configuration.direction === 'L' ? 'left' : 'right';
                        }
                    }
                    if (configuration.colors.frame && configuration.colors.frame.name) {
                        if (
                            configuration.colors.frame.RAL
                            || (configuration.colors.frame.group
                                && configuration.colors.frame.group === 'RAL')
                        ) {
                            this.Colors.frame = this.configuratorsDataService.get(
                                'windowColorRals',
                                configuration.colors.frame.id
                            );
                        } else {
                            this.Colors.frame = this.configuratorsDataService.get(
                                'windowColorsAll',
                                configuration.colors.frame.id
                            );
                        }
                    }
                    if (configuration.colors.net && configuration.colors.net.name) {
                        this.Colors.net = {
                            code: configuration.colors.net.code,
                            name: configuration.colors.net.name,
                            color: configuration.colors.net.code === 'black' ? '000000' : 'A9A9A9',
                        };
                    }
                    if (configuration.colors.panel && configuration.colors.panel.name) {
                        this.Colors.panel = {
                            code: configuration.colors.panel.code,
                            name: configuration.colors.panel.name,
                            color:
                                configuration.colors.panel.code === 'white' ? 'FFFFFF' : '654321',
                        };
                    }

                    if (configuration.montage) {
                        this.Montage = configuratorsDataService.get(
                            'mosquitoMontages',
                            configuration.montage.id
                        );
                    }
                    if (configuration.windowLine) {
                        this.WindowLine = configuratorsDataService.get(
                            'mosquitoWindowLines',
                            String(configuration.system.id),
                            configuration.windowLine.id
                        );
                    }
                    ['up', 'down', 'left', 'right'].forEach(side => {
                        if (configuration.profilePositions[side]) {
                            this.ProfilePositions[side] = configuration.profilePositions[side];
                        }
                    });
                    this.HoldersWidth = configuration.holdersWidth;
                    this.MuntinType = configuration.muntinPosition.type;
                    if (configuration.muntinPosition.type === 'custom') {
                        this.MuntinPosition = configuration.muntinPosition.position;
                    }
                }
            }
        }
        this.configuratorsDataService = null;
    }
}
