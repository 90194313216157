import { Injectable } from '@angular/core';
import { logger, core } from 'helpers';
import Common from 'Common';
import { EventBusService } from 'event-bus.service';
import { ResizeSashService } from './resize-sash.service';
import { ActiveMullion } from 'layout/active-mullion';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import { ActiveSash } from 'layout/active-sash';
import { Coupling, Frame } from 'configurations/parts/window';
import { BrowserFramesService } from './frames.service';

@Injectable()
export class EqualDivisionService {
    constructor(
        private eventBusService: EventBusService,
        private resizeSashService: ResizeSashService,
        private framesService: BrowserFramesService
    ) {}

    /**
     * Równy podział
     * @param  {Object} divider Podział
     */
    equalDivision(
        divider: ActiveMullion | Coupling,
        conf: WindowActiveConfiguration,
        type: 'axis' | 'glazing'
    ) {
        if (Common.isUndefined(divider) || Common.isUndefined(divider.id)) {
            return;
        }

        const i = 0;

        const dividers: { divider: ActiveMullion | Coupling; position: number }[] = [];
        const sizes = {
            size: 0,
            count: 0,
            glazing: 0,
        };

        const check = this.checkDivision(
            dividers,
            divider,
            sizes,
            conf,
            this.oneSide(divider.direction)
        );
        this.checkDivision(
            dividers,
            divider,
            sizes,
            conf,
            this.otherSide(divider.direction),
            check
        );

        dividers.sort((a, b) => a.position - b.position);
        if (type === 'axis') {
            const newSize = Math.round(sizes.size / sizes.count);
            dividers.map(div =>
                this.isMullion(div.divider)
                    ? this.changeSashWidthByWidth(div.divider, newSize, conf)
                    : this.changeFrameWidthByWidth(div.divider, newSize, conf)
            );
        } else {
            const newSize = Math.round(sizes.glazing / sizes.count);
            dividers.map(div =>
                this.isMullion(div.divider)
                    ? this.changeSashWidthByGlassWidth(div.divider, newSize, conf)
                    : this.changeFrameWidthByGlassWidth(div.divider, newSize, conf)
            );
        }

        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
    }

    /**
     * Zmiana skrzydła według szerokości
     * @param  {Object} divider   Podział
     * @param  {Number} size     Szerokość
     * @param  {String} direction Kierunek
     * @param  {String} turn      Obrót
     */
    changeSashWidthByWidth(divider: ActiveMullion, size, conf: WindowActiveConfiguration) {
        let diff = 0;
        if (size > IccConfig.Configurators.minWidth) {
            if (divider.direction === 'horizontal') {
                diff = divider.multiAlignTop[0].rHeight - size;
            } else {
                diff = divider.multiAlignLeft[0].rWidth - size;
            }
            this.resizeSashService.changeSashWidth(divider, diff, divider.direction, conf);
        }
    }

    /**
     * Zmiana skrzydła według szerokości
     * @param  {Object} coupling   Podział
     * @param  {Number} size     Szerokość
     * @param  {String} direction Kierunek
     * @param  {String} turn      Obrót
     */
    changeFrameWidthByWidth(coupling: Coupling, size: number, conf: WindowActiveConfiguration) {
        if (size > IccConfig.Configurators.minWidth) {
            const frame = conf.Frames.find(f => coupling.framesId[0].id === f.id);
            const nextCoupling: Coupling = conf.couplings.find(c =>
                c.otherFramesId.some(f => f.id === frame.id)
            );
            let newCouplingPosition;
            if (coupling.direction === 'horizontal') {
                newCouplingPosition =
                    (nextCoupling ? frame.y - Math.floor(nextCoupling.width / 2) : frame.y) + size;
            } else {
                newCouplingPosition =
                    (nextCoupling ? frame.x - Math.floor(nextCoupling.width / 2) : frame.x) + size;
            }

            this.framesService.moveCoupling(coupling, newCouplingPosition, conf);
        }
    }

    /**
     * Zmiana szerokości skrzydła wg szerokości szkła
     * @param  {Object} divider   Podział
     * @param  {Number} width     Szerokość
     * @param  {String} direction Kierunek
     * @param  {String} turn      Obrót
     */
    private changeSashWidthByGlassWidth(divider, width, conf: WindowActiveConfiguration) {
        let diff = 0;
        if (width > IccConfig.Configurators.minWidth) {
            if (divider.direction === 'horizontal') {
                let curHeight = divider.multiAlignTop[0].glazingSizes.height;
                if (
                    divider.multiAlignTop[0].intSashes
                    && divider.multiAlignTop[0].intSashes.length
                ) {
                    curHeight = divider.multiAlignTop[0].intSashes[0].glazingSizes.height;
                }
                diff = curHeight - width;
            } else {
                let curWidth = divider.multiAlignLeft[0].glazingSizes.width;
                if (
                    divider.multiAlignLeft[0].intSashes
                    && divider.multiAlignLeft[0].intSashes.length
                ) {
                    curWidth = divider.multiAlignLeft[0].intSashes[0].glazingSizes.width;
                }
                diff = curWidth - width;
            }
            this.resizeSashService.changeSashWidth(divider, diff, divider.direction, conf);
        }
    }

    private changeFrameWidthByGlassWidth(
        coupling: Coupling,
        width: number,
        conf: WindowActiveConfiguration
    ) {
        let diff = 0;
        if (width > IccConfig.Configurators.minWidth) {
            const frame = conf.Frames.find(f => coupling.framesId[0].id === f.id);
            const sash = conf.Sashes.find(s => s.frameId === frame.id);

            let position;
            if (coupling.direction === 'vertical') {
                let curWidth = sash.glazingSizes.width;
                if (sash.intSashes && sash.intSashes.length) {
                    curWidth = sash.intSashes[0].glazingSizes.width;
                }
                diff = curWidth - width;
                position = frame.x + frame.width + Math.round(coupling.width / 2) - diff;
            } else {
                let curHeight = sash.glazingSizes.height;
                if (sash.intSashes && sash.intSashes.length) {
                    curHeight = sash.intSashes[0].glazingSizes.height;
                }
                diff = curHeight - width;
                position = frame.y + frame.height + Math.round(coupling.width / 2) - diff;
            }
            this.framesService.moveCoupling(coupling, position, conf);
        }
    }

    /*
     * Sprawdza lewy podział skrzydła
     * @param  {Object} divider  Podział
     * @param  {Object} widthObj Szerokość
     */
    private checkDivision(
        dividers: { divider: ActiveMullion | Coupling; position: number }[],
        divider: ActiveMullion | Coupling,
        sizes: {
            size: number;
            count: number;
            glazing: number;
        },
        conf: WindowActiveConfiguration,
        direction: 'left' | 'right' | 'top' | 'bottom',
        check: boolean = true
    ) {
        if (this.isMullion(divider)) {
            check = this.checkSashesDivision(divider, direction, check, dividers, sizes, conf);
        } else {
            this.checkFramesDivision(conf, divider, direction, check, dividers, sizes);
        }
        return check;
    }

    private checkFramesDivision(
        conf: WindowActiveConfiguration,
        divider: Coupling,
        direction: 'left' | 'right' | 'top' | 'bottom',
        check: boolean,
        dividers: { divider: ActiveMullion | Coupling; position: number }[],
        sizes: { size: number; count: number; glazing: number }
    ) {
        const adjacentFrame = conf.Frames.find(f => f.id === divider[this.frames(direction)][0].id);
        if (check) {
            this.addCoupling(dividers, divider, conf.Frames);
            const nextCoupling = this.getNextCoupling(adjacentFrame, direction, conf);
            this.pickSizesFrame(
                sizes,
                adjacentFrame,
                conf.Sashes,
                direction,
                divider.width / 2 + (nextCoupling ? Math.ceil(nextCoupling.width / 2) : 0)
            );
            if (nextCoupling) {
                this.checkDivision(dividers, nextCoupling, sizes, conf, direction);
            }
        }
    }

    private checkSashesDivision(
        divider: ActiveMullion,
        direction: 'left' | 'right' | 'top' | 'bottom',
        check: boolean,
        dividers: { divider: ActiveMullion | Coupling; position: number }[],
        sizes: { size: number; count: number; glazing: number },
        conf: WindowActiveConfiguration
    ) {
        const adjacentSash = divider[this.multiAlign(direction)][0];
        check = check && this.canResize(divider, adjacentSash, direction);
        if (check) {
            this.addDivider(dividers, divider, direction);
            this.pickSizesSash(sizes, adjacentSash, direction);
            const nextMullion = this.getNextMullion(adjacentSash, direction, conf);
            if (nextMullion) {
                this.checkDivision(dividers, nextMullion, sizes, conf, direction);
            }
        }
        return check;
    }

    private getNextMullion(
        adjacentSash: ActiveSash,
        direction: 'left' | 'right' | 'top' | 'bottom',
        conf: WindowActiveConfiguration
    ) {
        let nextMullion: ActiveMullion;
        if (adjacentSash.nearMullions[direction] > -1) {
            if (adjacentSash.parentId != null) {
                const parentSash = conf.Sashes.find(s => s.id === adjacentSash.parentId);
                nextMullion = core.fIdO(
                    parentSash.intMullions,
                    adjacentSash.nearMullions[direction]
                );
            } else {
                nextMullion = core.fIdO(conf.Mullions, adjacentSash.nearMullions[direction]);
            }
        }
        return nextMullion;
    }

    private getNextCoupling(
        adjacentFrame: Frame,
        direction: 'left' | 'right' | 'top' | 'bottom',
        conf: WindowActiveConfiguration
    ) {
        const nextCoupling: Coupling = conf.couplings.find(c =>
            c[this.frames(core.opposite(direction))].some(f => f.id === adjacentFrame.id)
        );
        return nextCoupling;
    }

    private addDivider(
        dividers: { divider: ActiveMullion | Coupling; position: number }[],
        divider: ActiveMullion | Coupling,
        direction: 'left' | 'right' | 'top' | 'bottom'
    ) {
        if (dividers.findIndex(d => d.divider.id === divider.id) === -1) {
            dividers.push({
                position: divider[this.positionR(direction)],
                divider,
            });
        }
    }

    private addCoupling(
        dividers: { divider: ActiveMullion | Coupling; position: number }[],
        divider: Coupling,
        frames: Frame[]
    ) {
        if (dividers.findIndex(d => d.divider.id === divider.id) === -1) {
            const frame = frames.find(f => divider.framesId.some(fId => fId.id === f.id));
            const couplingPosition =
                divider.direction === 'vertical' ? frame.x + frame.width : frame.y + frame.height;
            dividers.push({
                position: couplingPosition,
                divider,
            });
        }
    }

    private pickSizesSash(
        sizes: { size: number; count: number; glazing: number },
        adjacentSash: ActiveSash,
        direction: 'left' | 'right' | 'top' | 'bottom'
    ) {
        sizes.size += adjacentSash[this.sizeR(direction)];
        if (Common.isDefined(adjacentSash.intSashes) && adjacentSash.intSashes.length > 0) {
            const internalSash = adjacentSash.intSashes.find(
                s => s.nearMullions[core.opposite(direction)] === -1
            );
            sizes.glazing += internalSash.glazingSizes[this.size(direction)];
        } else {
            sizes.glazing += adjacentSash.glazingSizes[this.size(direction)];
        }
        sizes.count++;
    }

    private pickSizesFrame(
        sizes: { size: number; count: number; glazing: number },
        adjacentFrame: Frame,
        sashes: WindowActiveConfiguration['Sashes'],
        direction: 'left' | 'right' | 'top' | 'bottom',
        couplingsSize: number
    ) {
        sizes.size += adjacentFrame[this.size(direction)] + couplingsSize;
        const adjacentSash = sashes.find(s => s.frameId === adjacentFrame.id);
        if (Common.isDefined(adjacentSash.intSashes) && adjacentSash.intSashes.length > 0) {
            const internalSash = adjacentSash.intSashes.find(
                s => s.nearMullions[core.opposite(direction)] === -1
            );
            sizes.glazing += internalSash.glazingSizes[this.size(direction)];
        } else {
            sizes.glazing += adjacentSash.glazingSizes[this.size(direction)];
        }
        sizes.count++;
    }

    private canResize(
        mullion: ActiveMullion,
        adjacentSash: ActiveSash,
        direction: 'left' | 'right' | 'top' | 'bottom'
    ) {
        let check = true;

        let adjacentMullions: Partial<ActiveMullion>[] = [];
        if (adjacentSash.nearMullions[direction] > -1) {
            adjacentMullions = mullion[this.multiAlignDiv(direction)];
        }
        if (Common.isDefined(adjacentMullions)) {
            for (let i = 0; i < adjacentMullions.length; i++) {
                if (
                    adjacentMullions[i][this.oneSide(adjacentMullions[i].direction)].length > 1
                    || adjacentMullions[i][
                        this.multiAlign(this.otherSide(adjacentMullions[i].direction))
                    ].length > 1
                ) {
                    check = false;
                    return false;
                }
            }
        }

        return check;
    }

    private multiAlign(
        side: 'top' | 'bottom' | 'left' | 'right'
    ): 'multiAlignTop' | 'multiAlignLeft' | 'multiAlignRight' | 'multiAlignBottom' {
        switch (side) {
            case 'top':
                return 'multiAlignTop';
            case 'bottom':
                return 'multiAlignBottom';
            case 'left':
                return 'multiAlignLeft';
            case 'right':
                return 'multiAlignRight';
        }
    }

    private multiAlignDiv(
        side: 'top' | 'bottom' | 'left' | 'right'
    ): 'multiAlignTopDiv' | 'multiAlignLeftDiv' | 'multiAlignRightDiv' | 'multiAlignBottomDiv' {
        return (this.multiAlign(side) + 'Div') as
            | 'multiAlignTopDiv'
            | 'multiAlignLeftDiv'
            | 'multiAlignRightDiv'
            | 'multiAlignBottomDiv';
    }

    private size(side: 'top' | 'bottom' | 'left' | 'right') {
        if (side === 'top' || side === 'bottom') {
            return 'height';
        } else {
            return 'width';
        }
    }

    private sizeR(side: 'top' | 'bottom' | 'left' | 'right') {
        if (side === 'top' || side === 'bottom') {
            return 'rHeight';
        } else {
            return 'rWidth';
        }
    }

    private positionR(side: 'top' | 'bottom' | 'left' | 'right') {
        if (side === 'top' || side === 'bottom') {
            return 'ry';
        } else {
            return 'rx';
        }
    }

    private oneSide(orientation: 'vertical' | 'horizontal') {
        return orientation === 'horizontal' ? 'top' : 'left';
    }

    private otherSide(orientation: 'vertical' | 'horizontal') {
        return orientation === 'horizontal' ? 'bottom' : 'right';
    }

    private isMullion(divider: ActiveMullion | Coupling): divider is ActiveMullion {
        return (
            (divider as ActiveMullion).parentSash != null
            || (divider as ActiveMullion).frameId != null
        );
    }

    private frames(side: 'top' | 'bottom' | 'left' | 'right') {
        return side === 'top' || side === 'left' ? 'framesId' : 'otherFramesId';
    }
}
