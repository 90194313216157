import angular from 'angular';

/**
 * Kontroler akcesoriów
 * @param {object} $scope                 Angular Scope
 * @param {object} $timeout               Timeout
 * @param {object} AccessoriesFactory     Fabryka akcesoriów
 * @param {object} MosquitoSystemsFactory Fabryka systemów siatek
 */
export default function AccessoriesCtrl($scope, $timeout, Core, AccessoriesFactory, ConfigurationsService, DrawService, $filter, EventBusService, ScrollbarService) {
    'ngInject';

    var vm = this;

    const conf = ConfigurationsService.conf.Current;

    vm.colors               = [];
    vm.refresh              = refresh;
    vm.openModalAccessories = AccessoriesFactory.openModalAccessories;
    vm.remove               = AccessoriesFactory.remove;
    vm.getCountHoles        = AccessoriesFactory.getCountHoles;
    vm.placeDowelHole       = AccessoriesFactory.placeDowelHole;
    vm.hasDowelHole         = hasDowelHole;
    vm.hasVentilatorHole    = hasVentilatorHole;
    vm.countUp              = AccessoriesFactory.countUp;
    vm.countDown            = AccessoriesFactory.countDown;
    vm.countPrice           = AccessoriesFactory.countPrice;
    vm.subStep              = 1;
    vm.goToSubstep          = goToSubstep;

    vm.drawOptions = angular.merge({}, DrawService.options, {
        shape: {
            buttons: [
                {
                    class: 'ico-plus',
                    condition: response => (response.index === 'bottom' && conf.Shape.shape !== 'circle')
                                        || (response.index !== 'bottom' && !conf.hasRoller && conf.Shape.shape === 'rect'),
                    title: $filter('translate')('INTERFACE|Wybierz akcesoria dla boku'),
                    onClick: response => AccessoriesFactory.openModalAccessories(response.index),
                    positions: ['bottom', 'left', 'right', 'top'],
                },
            ],
        },
        sash: {
            buttons: [
                {
                    class: 'ico-plus',
                    positions: ['center'],
                    title: $filter('translate')('INTERFACE|Wybierz akcesoria dla kwatery'),
                    condition: response => typeof response.sash.parentId === 'undefined',
                    onClick  : response => {
                        const sash = Core.fIdO(conf.Sashes, response.sash.id);
                        AccessoriesFactory.openModalAccessories(sash, sash.index);
                    },
                },
            ],
        },
        onInterfaceClick: () => {
            $scope.$digest();
        }
    });

    if (AccessoriesFactory.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    ScrollbarService.update();

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        vm.colors = AccessoriesFactory.colorsAll;
    }

    /**
     * Funkcja odświeżajaca
     */
    function refresh() {
        ScrollbarService.update();
    }

    /**
     * Funkcja sprawdzajaca dyble
     * @param  {object}  accessories Akcesoria
     */
    function hasDowelHole(accessories) {
        return accessories.some(function(el) {
            return el.accessory.type == 'dowel_hole';
        });
    }

    /**
     * Funkcja sprawdzajaca otwór pod nawiewnik
     * @param  {object}  accessories Akcesoria
     */
    function hasVentilatorHole(accessories) {
        return accessories.some(function(el) {
            return el.accessory.type == 'ventilator_hole';
        });
    }

    function goToSubstep(subStep) {
        vm.subStep = subStep;
    }
}
