import angular from 'angular';
import { IssueLevel } from 'issues.service';

/**
 * Fabryka rozmiarów
 * @param {object} $rootScope               Angular Root Scope
 * @param {object} $filter                  Filtr
 * @param {object} $uibModal                   Dostarcza obsługę okien modalnych
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} CurConfService           Bieżaca konfiguracja
 * @param {object} RollerDimensionsFactory  Fabryka rozmiarów rolet
 * @param {object} IssuesService            Fabryka Issues
 * @param {object} ConfiguratorsDataService Fabryka danych konfiguracji
 * @param {object} PriceService             Liczenie ceny
 * @param {object} GlazingFactory           Fabryka szklenia
 * @param {object} RollerColorsFactory      Fabryka kolorów rolet
 * @param {object} SchemasFactory           Fabryka schematów
 * @param {object} DrivesFactory            Fabryka napedów
 * @param {object} TypesFactory             Fabryka typów
 * @param {object} ParametersService        Fabryka parametrów
 * @param {object} PriceBaseService         Fabryka bazowej ceny
 * @param {object} StepFactory              Fabryka krokow
 */
export default function DimensionsFactory(
    $rootScope,
    $filter,
    $uibModal,
    IccConfig,
    ConfigurationsService,
    CurConfService,
    RollerDimensionsFactory,
    IssuesService,
    ConfiguratorsDataService,
    PriceService,
    GlazingFactory,
    RollerColorsFactory,
    SchemasFactory,
    DrivesFactory,
    TypesFactory,
    ParametersService,
    PriceBaseService,
    StepFactory,
    ShapeService,
    ConstructionLimitationFactory,
    ValidationService,
    EventBusService,
    ProfilesService,
    ScrollbarService
) {
    'ngInject';

    var configurators = ['window', 'hs', 'door', 'folding_door'];
    var factory = {
        setShape,
        setShapeData,
        defaultShapes,
        setDimensions,
        valid,
        validDoorModelDimensions,
        changeMountingMethod,
        openModalMountingMethod,
        loadedData: false,
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    $rootScope.$on('resetData', init);
    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });
    EventBusService.subscribe(['changedSashes', 'setFrameProfile'], data => {
        valid(data.activeConfiguration.Shape);
    });
    return factory;

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        if (configurators.indexOf(CurConfService.conf) === -1) {
            return;
        }
        const availableShapes = ShapeService.getAvailableShapes(
            ConfigurationsService.conf.Current.System.id
        );
        if (
            angular.isUndefined(ConfigurationsService.conf.Current.Shape.shape)
            || availableShapes.indexOf(ConfigurationsService.conf.Current.Shape.shape) === -1
        ) {
            setShape(availableShapes[0] || 'rect', null, true);
        }
        if (
            ConfigurationsService.conf.Current.Width === null
            || ConfigurationsService.conf.Current.Height === null
        ) {
            setGlobalDimensions();
        }
        if (ConfigurationsService.conf.Current.hasRoller) {
            StepFactory.enable('rollershutter');
        }
        factory.loadedData = true;
    }

    /**
     * Funkcja ustawiania kształtu
     * @param {object} shape Kształt
     * @param {object} conf  Konfiguracja
     * @param {object} def   Def
     */
    function setShape(shape, conf, def) {
        if (angular.isUndefined(conf) || conf === null) {
            conf = ConfigurationsService.conf.Current;
        }
        conf.Shape = angular.copy(factory.defaultShapes()[shape]);
        if (def) {
            ConfigurationsService.conf.Default.Shape = angular.copy(factory.defaultShapes()[shape]);
        }
        setGlobalDimensions();
        ValidationService.valid(conf, 'shape');
        EventBusService.post({
            key: 'setShape',
            value: {},
        });

        ScrollbarService.update();
    }

    /**
     * Funkcja domyślnego kształtu
     */
    function defaultShapes() {
        return {
            rect: {
                shape: 'rect',
                width: ConfigurationsService.conf.Current.Width,
                height: ConfigurationsService.conf.Current.Height,
                circuit:
                    (ConfigurationsService.conf.Current.Width
                        + ConfigurationsService.conf.Current.Height)
                    * 2,
                s1: ConfigurationsService.conf.Current.Width,
                h1: ConfigurationsService.conf.Current.Height,
                h3: ConfigurationsService.conf.Current.Height,
                s2: 0,
                s3: 0,
                h2: 0,
            },
            triangle: {
                shape: 'triangle',
                width: ConfigurationsService.conf.Current.Width,
                height: ConfigurationsService.conf.Current.Height,
                s1: ConfigurationsService.conf.Current.Width * 0.5,
                s3: ConfigurationsService.conf.Current.Width * 0.5,

                circuit: Math.round(
                    Math.sqrt(
                        ConfigurationsService.conf.Current.Height
                            * ConfigurationsService.conf.Current.Height
                            + ConfigurationsService.conf.Current.Width
                                * 0.5
                                * ConfigurationsService.conf.Current.Width
                                * 0.5
                    )
                        + Math.sqrt(
                            ConfigurationsService.conf.Current.Height
                                * ConfigurationsService.conf.Current.Height
                                + ConfigurationsService.conf.Current.Width
                                    * 0.5
                                    * ConfigurationsService.conf.Current.Width
                                    * 0.5
                        )
                        + ConfigurationsService.conf.Current.Width
                ),
                type: 'F',
            },
            circle: {
                shape: 'circle',
                d: ConfigurationsService.conf.Current.Height,
                radius: ConfigurationsService.conf.Current.Height / 2,
                circuit: Math.round(ConfigurationsService.conf.Current.Width * 3.1416),
            },
            poligon: {
                shape: 'poligon',
                width: ConfigurationsService.conf.Current.Width,
                height: ConfigurationsService.conf.Current.Height,
                s1: ConfigurationsService.conf.Current.Width * 0.3,
                s2: ConfigurationsService.conf.Current.Width * 0.4,
                s3: ConfigurationsService.conf.Current.Width * 0.3,
                h1: ConfigurationsService.conf.Current.Height,
                h2: ConfigurationsService.conf.Current.Height * 0.2,
                h3: ConfigurationsService.conf.Current.Height * 0.2,
                circuit: Math.round(
                    Math.sqrt(
                        ConfigurationsService.conf.Current.Width
                            * 0.3
                            * ConfigurationsService.conf.Current.Width
                            * 0.3
                            + (ConfigurationsService.conf.Current.Height
                                - ConfigurationsService.conf.Current.Height * 0.2)
                                * (ConfigurationsService.conf.Current.Height
                                    - ConfigurationsService.conf.Current.Height * 0.2)
                    )
                        + Math.sqrt(
                            ConfigurationsService.conf.Current.Width
                                * 0.3
                                * ConfigurationsService.conf.Current.Width
                                * 0.3
                                + (ConfigurationsService.conf.Current.Height
                                    - ConfigurationsService.conf.Current.Height * 0.2)
                                    * (ConfigurationsService.conf.Current.Height
                                        - ConfigurationsService.conf.Current.Height * 0.2)
                        )
                        + ConfigurationsService.conf.Current.Height * 0.2
                        + ConfigurationsService.conf.Current.Height * 0.2
                        + ConfigurationsService.conf.Current.Width
                ),
                type: 'DLC',
            },
            arc: {
                shape: 'arc',
                h1: ConfigurationsService.conf.Current.Height * 0.7,
                h2: ConfigurationsService.conf.Current.Height * 0.3,
                width: ConfigurationsService.conf.Current.Width,
                height: ConfigurationsService.conf.Current.Height,
                circuit: Math.round(
                    (3.1416
                        * ((3 / 2)
                            * (ConfigurationsService.conf.Current.Height * 0.3
                                + ConfigurationsService.conf.Current.Width / 2)
                            - Math.sqrt(
                                (ConfigurationsService.conf.Current.Height
                                    * 0.3
                                    * ConfigurationsService.conf.Current.Width)
                                    / 2
                            )))
                        / 2
                        + ConfigurationsService.conf.Current.Height * 0.7 * 2
                        + ConfigurationsService.conf.Current.Width
                ),
                arcType: 'radialArc',
                type: 'F',
            },
        };
    }

    /**
     * Funkcja ustawiania globalnego rozmiaru
     */
    function setGlobalDimensions() {
        switch (ConfigurationsService.conf.Current.Shape.shape) {
            case 'rect':
                ConfigurationsService.conf.Current.Width = angular.copy(
                    parseInt(ConfigurationsService.conf.Current.Shape.width)
                );
                ConfigurationsService.conf.Current.Height = angular.copy(
                    parseInt(ConfigurationsService.conf.Current.Shape.height)
                ); // -roller;
                break;
            case 'triangle':
                ConfigurationsService.conf.Current.Width = angular.copy(
                    parseInt(ConfigurationsService.conf.Current.Shape.s1)
                        + parseInt(ConfigurationsService.conf.Current.Shape.s3)
                );
                ConfigurationsService.conf.Current.Height = angular.copy(
                    parseInt(ConfigurationsService.conf.Current.Shape.height)
                );
                break;
            case 'circle':
                ConfigurationsService.conf.Current.Width = angular.copy(
                    parseInt(ConfigurationsService.conf.Current.Shape.d)
                );
                ConfigurationsService.conf.Current.Height = angular.copy(
                    parseInt(ConfigurationsService.conf.Current.Shape.d)
                );
                break;
            case 'poligon':
                ConfigurationsService.conf.Current.Width = angular.copy(
                    parseInt(ConfigurationsService.conf.Current.Shape.s1)
                        + parseInt(ConfigurationsService.conf.Current.Shape.s2)
                        + parseInt(ConfigurationsService.conf.Current.Shape.s3)
                );
                ConfigurationsService.conf.Current.Height = angular.copy(
                    parseInt(ConfigurationsService.conf.Current.Shape.h1)
                );
                break;
            case 'arc':
                ConfigurationsService.conf.Current.Width = angular.copy(
                    parseInt(ConfigurationsService.conf.Current.Shape.width)
                );
                ConfigurationsService.conf.Current.Height = angular.copy(
                    parseInt(
                        parseInt(ConfigurationsService.conf.Current.Shape.h1)
                            + parseInt(ConfigurationsService.conf.Current.Shape.h2)
                    )
                );
                break;
        }
    }

    /**
     * Funkcja przeliczajaca obwód koła
     */
    function recalculateCircuit() {
        switch (ConfigurationsService.conf.Current.Shape.shape) {
            case 'rect':
                ConfigurationsService.conf.Current.Shape.circuit =
                    (ConfigurationsService.conf.Current.Width
                        + ConfigurationsService.conf.Current.Height)
                    * 2;
                break;
            case 'triangle':
                ConfigurationsService.conf.Current.Shape.circuit = Math.round(
                    Math.sqrt(
                        ConfigurationsService.conf.Current.Shape.height
                            * ConfigurationsService.conf.Current.Shape.height
                            + ConfigurationsService.conf.Current.Shape.s1
                                * ConfigurationsService.conf.Current.Shape.s1
                    )
                        + Math.sqrt(
                            ConfigurationsService.conf.Current.Shape.height
                                * ConfigurationsService.conf.Current.Shape.height
                                + ConfigurationsService.conf.Current.Shape.s3
                                    * ConfigurationsService.conf.Current.Shape.s3
                        )
                        + ConfigurationsService.conf.Current.Shape.s1
                        + ConfigurationsService.conf.Current.Shape.s3
                );
                break;
            case 'circle':
                ConfigurationsService.conf.Current.Shape.circuit = Math.round(
                    ConfigurationsService.conf.Current.Shape.d * 3.1416
                );
                break;
            case 'poligon':
                ConfigurationsService.conf.Current.Shape.circuit = Math.round(
                    Math.sqrt(
                        Math.pow(ConfigurationsService.conf.Current.Shape.s1, 2)
                            + Math.pow(
                                ConfigurationsService.conf.Current.Shape.h1
                                    - ConfigurationsService.conf.Current.Shape.h3,
                                2
                            )
                    )
                        + Math.sqrt(
                            Math.pow(ConfigurationsService.conf.Current.Shape.s3, 2)
                                + Math.pow(
                                    ConfigurationsService.conf.Current.Shape.h1
                                        - ConfigurationsService.conf.Current.Shape.h2,
                                    2
                                )
                        )
                        + ConfigurationsService.conf.Current.Shape.h3
                        + ConfigurationsService.conf.Current.Shape.h2
                        + ConfigurationsService.conf.Current.Shape.s2
                        + ConfigurationsService.conf.Current.Shape.s1
                        + ConfigurationsService.conf.Current.Shape.s2
                        + ConfigurationsService.conf.Current.Shape.s3
                );
                break;
            case 'arc':
                ConfigurationsService.conf.Current.Shape.circuit =
                    ConfigurationsService.conf.Current.Shape.type === 'F'
                        ? Math.round(
                              (3.1416
                                  * ((3 / 2)
                                      * (ConfigurationsService.conf.Current.Shape.h2
                                          + ConfigurationsService.conf.Current.Shape.width / 2)
                                      - Math.sqrt(
                                          (ConfigurationsService.conf.Current.Shape.h2
                                              * ConfigurationsService.conf.Current.Shape.width)
                                              / 2
                                      )))
                                  / 2
                                  + ConfigurationsService.conf.Current.Shape.h1
                                  + ConfigurationsService.conf.Current.Shape.h1
                                  + ConfigurationsService.conf.Current.Shape.width
                          )
                        : Math.round(
                              (3.1416
                                  * ((3 / 2)
                                      * (ConfigurationsService.conf.Current.Shape.h2
                                          + ConfigurationsService.conf.Current.Shape.width)
                                      - Math.sqrt(
                                          ConfigurationsService.conf.Current.Shape.h2
                                              * ConfigurationsService.conf.Current.Shape.width
                                      )))
                                  / 4
                                  + ConfigurationsService.conf.Current.Shape.h2
                                  + ConfigurationsService.conf.Current.Shape.h1
                                  + ConfigurationsService.conf.Current.Shape.h1
                                  + ConfigurationsService.conf.Current.Shape.width
                          );
                break;
        }
    }

    /**
     * Funkcja getArcRadius
     * @param  {object} coords Koordynaty
     */
    function getArcRadius(coords) {
        var aa = coords[0].x;
        var bb = coords[0].y;
        var cc = coords[1].x;
        var dd = coords[1].y;
        var ee = coords[2].x;
        var ff = coords[2].y;

        var linchk = cc * ff + aa * dd + ee * bb - aa * ff - cc * bb - ee * dd;
        if (linchk === 0) {
            var slope = Math.round(100000 * ((bb - ff) / (aa - ee))) / 100000;
            var yint = Math.round(100000 * (bb - (aa * (bb - ff)) / (aa - ee))) / 100000;
            return null;
        } else if (linchk !== 0) {
            var hh =
                ((dd - ff) * (aa * aa + bb * bb - cc * cc - dd * dd)
                    - (dd - bb) * (ee * ee + ff * ff - cc * cc - dd * dd))
                / (2 * linchk);
            var kk =
                ((ee - cc) * (aa * aa + bb * bb - cc * cc - dd * dd)
                    - (aa - cc) * (ee * ee + ff * ff - cc * cc - dd * dd))
                / (2 * linchk);
            var rr = Math.sqrt((aa - hh) * (aa - hh) + (bb - kk) * (bb - kk));
            return rr;
        }
    }

    /**
     * Funkcja ustawiajace dane kształtu
     */
    function setShapeData() {
        if (ConfigurationsService.conf.Current.Shape.shape == 'rect') {
            var roller = 0;
            if (ConfigurationsService.conf.Current.hasRoller) {
                roller = parseInt(ConfigurationsService.conf.Current.RollerShutter.realBoxHeight);
            }

            ConfigurationsService.conf.Current.Shape.s1 =
                ConfigurationsService.conf.Current.Shape.width;
            ConfigurationsService.conf.Current.Shape.h1 =
                ConfigurationsService.conf.Current.Shape.height; // - roller;
            ConfigurationsService.conf.Current.Shape.h3 =
                ConfigurationsService.conf.Current.Shape.height; // - roller;

            ConfigurationsService.conf.Current.Shape.s2 = 0;
            ConfigurationsService.conf.Current.Shape.s3 = 0;
            ConfigurationsService.conf.Current.Shape.h2 = 0;
        }

        if (ConfigurationsService.conf.Current.Shape.shape == 'triangle') {
            ConfigurationsService.conf.Current.Shape.s1 =
                ConfigurationsService.conf.Current.Shape.s1;
            // ConfigurationsService.conf.Current.Shape.s3 = ConfigurationsService.conf.Current.Shape.s2;
            ConfigurationsService.conf.Current.Shape.s2 = 0;

            ConfigurationsService.conf.Current.Shape.h1 =
                ConfigurationsService.conf.Current.Shape.height;
            ConfigurationsService.conf.Current.Shape.h2 = 0;
            ConfigurationsService.conf.Current.Shape.h3 = 0;
        }

        if (ConfigurationsService.conf.Current.Shape.shape == 'arc') {
            var coords;
            if (ConfigurationsService.conf.Current.Shape.type == 'F') {
                coords = [
                    {
                        x: 0,
                        y: ConfigurationsService.conf.Current.Shape.h2,
                    },
                    {
                        x: ConfigurationsService.conf.Current.Shape.width / 2,
                        y: 0,
                    },
                    {
                        x: ConfigurationsService.conf.Current.Shape.width,
                        y: ConfigurationsService.conf.Current.Shape.h2,
                    },
                ];
                ConfigurationsService.conf.Current.Shape.radius = getArcRadius(coords);
            }

            if (
                ConfigurationsService.conf.Current.Shape.type == 'R'
                || ConfigurationsService.conf.Current.Shape.type == 'L'
            ) {
                if (
                    ConfigurationsService.conf.Current.Shape.width
                    >= ConfigurationsService.conf.Current.Shape.h2
                ) {
                    coords = [
                        {
                            x: ConfigurationsService.conf.Current.Shape.width * -1,
                            y: ConfigurationsService.conf.Current.Shape.h2,
                        },
                        {
                            x: 0,
                            y: 0,
                        },
                        {
                            x: ConfigurationsService.conf.Current.Shape.width,
                            y: ConfigurationsService.conf.Current.Shape.h2,
                        },
                    ];
                } else {
                    coords = [
                        {
                            x: 0,
                            y: ConfigurationsService.conf.Current.Shape.h2 * -1,
                        },
                        {
                            x: ConfigurationsService.conf.Current.Shape.width,
                            y: 0,
                        },
                        {
                            x: 0,
                            y: ConfigurationsService.conf.Current.Shape.h2,
                        },
                    ];
                }
                ConfigurationsService.conf.Current.Shape.radius = getArcRadius(coords);
            }
        }
        if (ConfigurationsService.conf.Current.Shape.shape == 'circle') {
            ConfigurationsService.conf.Current.Shape.radius =
                ConfigurationsService.conf.Current.Shape.d / 2;
        }
    }

    /**
     * Funkcja ustawiajaca rozmiar
     * @param {object} dimensions Rozmiar
     */
    function setDimensions(dimensions) {
        ConfigurationsService.conf.Current.Shape = angular.copy(dimensions);
        setShapeData();
        setGlobalDimensions();
        ValidationService.valid(ConfigurationsService.conf.Current, 'shape');
        EventBusService.post({
            key: 'setShape',
            value: {},
        });
        RollerDimensionsFactory.loadBoxHeights();
        ParametersService.count(ConfigurationsService.conf.Current);

        // odśwież wymiary akcesorii (#21491)
        const confacc = ConfigurationsService.conf.Current.SideAccessories;
        for (let x = 0; x < confacc.bottom.length; ++x) {
            if (~~confacc.bottom[x].accessory.price_type != 1) {
                confacc.bottom[x].amount =
                    dimensions.width + confacc.sizes.left + confacc.sizes.right;
            }
        }
        for (let x = 0; x < confacc.top.length; ++x) {
            if (~~confacc.top[x].accessory.price_type != 1) {
                confacc.top[x].amount = dimensions.width + confacc.sizes.left + confacc.sizes.right;
            }
        }
        for (let x = 0; x < confacc.left.length; ++x) {
            if (~~confacc.left[x].accessory.price_type != 1) {
                confacc.left[x].amount = dimensions.height;
            }
        }
        for (let x = 0; x < confacc.right.length; ++x) {
            if (~~confacc.right[x].accessory.price_type != 1) {
                confacc.right[x].amount = dimensions.height;
            }
        }

        PriceService.count();
        recalculateCircuit();
    }

    /**
     * Funkcja walidacji kształtu
     * @param  {object} shapeData Dane ksztłtu
     */
    function valid(shapeData) {
        const conf = ConfigurationsService.conf.Current;
        if (shapeData.shape == 'rect' && (!shapeData.width || !shapeData.height)) {
            IssuesService.simpleRegister(
                'no-window-dimensions',
                'Wymiary nie zostały określone.',
                $filter('translate')('DIMENSION|Wymiary nie zostały określone.'),
                conf,
                {
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }

        if (
            shapeData.shape == 'rect'
            && (shapeData.width < 250
                || shapeData.height < 250
                || (conf.Sashes.length
                    && conf.Sashes.some(
                        sash =>
                            (sash.rWidth / conf.Width) * shapeData.width < IccConfig.Configurators.minWidth
                            || (sash.rHeight / conf.Height) * shapeData.height < IccConfig.Configurators.minWidth
                    )))
        ) {
            IssuesService.simpleRegister(
                'incorrect-window-dimensions',
                'Podane wymiary są nieprawidłowe.',
                $filter('translate')('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                conf,
                {
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }

        const arcFramesEdges = ProfilesService.getArcFramesEdges(conf);

        if (arcFramesEdges) {
            for (const edge of arcFramesEdges) {
                const arcProfile = ProfilesService.getProfile(edge.profileId);

                const coords =
                    shapeData.type === 'F'
                        ? [
                              { x: 0, y: shapeData.h2 },
                              { x: shapeData.width / 2, y: 0 },
                              { x: shapeData.width, y: shapeData.h2 },
                          ]
                        : [
                              { x: shapeData.width * -1, y: shapeData.h2 },
                              { x: 0, y: 0 },
                              { x: shapeData.width, y: shapeData.h2 },
                          ];

                const arcRadius = getArcRadius(coords);

                if (
                    arcProfile
                    && ((shapeData.shape == 'circle'
                        && shapeData.radius < Number(arcProfile.minBendRadius))
                        || (shapeData.shape == 'arc'
                            && arcRadius < Number(arcProfile.minBendRadius))
                        || (shapeData.shape == 'arc'
                            && Number(shapeData.h1) + Number(shapeData.h2) < 250))
                ) {
                    IssuesService.simpleRegister(
                        'incorrect-window-dimensions',
                        'Podane wymiary są nieprawidłowe.',
                        $filter('translate')('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                        conf,
                        {
                            logLevel: IssueLevel.NONE
                        }
                    );
                    return false;
                }
            }
        }

        const angles = ShapeService.getAngles(shapeData);
        const defaultProfile = ProfilesService.getProfile(conf.ProfileSet.frameSide);
        if (conf.drawData && conf.drawData.shape[0]) {
            const shapeDrawData = conf.drawData.shape[0];

            if (
                (shapeData.shape == 'triangle' || shapeData.shape == 'poligon')
                && angles.some((angle, index) => {
                    const angleEdges = shapeDrawData.angles[index] || shapeDrawData.angles[0];
                    const oneFrame = conf.Frames.find(
                        f => angleEdges[0] && f.id === angleEdges[0].frameId
                    );
                    const sideProfile = ProfilesService.getProfile(
                        ((oneFrame && oneFrame.frame[angleEdges[0].frameEdgeIndex]) || {}).profileId
                    );
                    const nextFrame = conf.Frames.find(
                        f => angleEdges[1] && f.id === angleEdges[1].frameId
                    );
                    const sideProfileNext = ProfilesService.getProfile(
                        ((nextFrame && nextFrame.frame[angleEdges[1].frameEdgeIndex]) || {})
                            .profileId
                    );
                    return (
                        angle
                        < Math.max(
                            (sideProfile || defaultProfile).minAngle || 0,
                            (sideProfileNext || defaultProfile).minAngle || 0
                        )
                    );
                })
            ) {
                IssuesService.simpleRegister(
                    'incorrect-window-dimensions',
                    'Podane wymiary są nieprawidłowe.',
                    $filter('translate')('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                    conf,
                    {
                        logLevel: IssueLevel.NONE
                    }
                );
                return false;
            }
        }

        if (
            shapeData.shape == 'triangle'
            && (Number(shapeData.s1) + Number(shapeData.s3) < 250 || shapeData.height < 250)
        ) {
            IssuesService.simpleRegister(
                'incorrect-window-dimensions',
                'Podane wymiary są nieprawidłowe.',
                $filter('translate')('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                conf,
                {
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }

        if (
            ['h1', 'h2', 'h3', 's1', 's2', 's3', 'height', 'width', 'radius', 'd'].some(
                field => angular.isDefined(shapeData[field]) && Number(shapeData[field]) < 0
            )
        ) {
            IssuesService.simpleRegister(
                'incorrect-window-dimensions',
                'Podane wymiary są nieprawidłowe.',
                $filter('translate')('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                conf,
                {
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }

        IssuesService.unregister('no-window-dimensions', conf);
        IssuesService.unregister('incorrect-window-dimensions', conf);
        IssuesService.unregister('incorrect-window-fins', conf);
        return true;
    }

    /**
     * Funkcja walidacji rozmiaru modelu drzwi
     * @param  {object} shapeData Dane kształtu
     */
    function validDoorModelDimensions(shapeData) {
        if (
            shapeData.shape == 'rect'
            && CurConfService.conf == 'door'
            && parseInt(ConfigurationsService.conf.Current.Model.min_width) > 0
            && parseInt(ConfigurationsService.conf.Current.Model.min_height) > 0
        ) {
            var sashes = ConfigurationsService.conf.Current.Sashes;
            for (var i = sashes.length - 1; i >= 0; i--) {
                if (
                    sashes[i].rWidth < ~~ConfigurationsService.conf.Current.Model.min_width
                    || sashes[i].rHeight < ~~ConfigurationsService.conf.Current.Model.min_height
                ) {
                    IssuesService.simpleRegister(
                        'incorrect-window-model-dimensions',
                        'Wartości przycięcia płetwy są niepoprawne',
                        $filter('translate')(
                            'CONFIGURATOR|Wartości przycięcia płetwy są niepoprawne'
                        ),
                        ConfigurationsService.conf.Current,
                        {
                            logLevel: IssueLevel.NONE
                        }
                    );
                    return false;
                }
            }
        }
        IssuesService.unregister(
            'incorrect-window-model-dimensions',
            ConfigurationsService.conf.Current
        );
    }

    function changeMountingMethod(measurements, mountingMethod) {
        measurements.mountingMethod = mountingMethod;
    }

    function openModalMountingMethod(measurements) {
        const modalInstance = $uibModal.open({
            templateUrl: 'modalMountingMethod.html',
            controller: 'ModalMountingMethodCtrl as mmounting',
            resolve: {
                selMountingMethod: () => (measurements || {}).mountingMethod,
                mountingMethods: () => [],
            },
        });

        modalInstance.result.then(mountingMethod => {
            if (mountingMethod) {
                changeMountingMethod(measurements, mountingMethod);
            }
        });
    }
}
