import {
    ActiveRollerShutter,
    ColorOptions,
    Type,
    RoundReel,
    Drive,
    System,
    Profile,
    Shutter
} from '../roller_shutter';
import {
    Color,
    SimpleAccessory,
} from '../common';
import {GuideRail} from './GuideRail';
import {Slat} from './Slat';

export class RollerShutter {
    type: Type;
    colors: {
        boxInner?: Color;
        boxGuideOuter?: Color;
        profile?: Color;
        endslat?: Color;
        guideRail?: Color;
        box?: Color;
        revision?: Color;
        boxSideSurface?: Color;
    };
    colorsOptions: {
        boxInner?: ColorOptions;
        boxGuideOuter?: ColorOptions;
        profile?: ColorOptions;
        endslat?: ColorOptions;
        guideRail?: ColorOptions;
        box?: ColorOptions;
        revision?: ColorOptions;
        boxSideSurface?: ColorOptions;
    };
    roundReel: RoundReel;
    guideType: 'pvc' | 'alu';
    changedBoxHeight: boolean;
    boxHeight: number;
    boxWidth: number;
    boxHeightLevel: number;
    rollerHeight: number;
    hasAssembly: boolean;
    inInsulation: boolean;
    concealedStrip: boolean;
    driveSide: 'L' | 'R';
    hasOverride: boolean;
    driveAccessories: SimpleAccessory[];
    drive: Drive;
    hanger: any;
    system: System;
    profile: Profile;
    schemaId: number;
    shutters: Shutter[];
    guideRails: GuideRail[];
    slats: Slat[];
    railsBottomCut: string;
    railsHeightModify: number;

    constructor(rollerShutter: ActiveRollerShutter) {
        this.mapRollerShutter(rollerShutter);
    }

    public mapRollerShutter(rollerShutter: ActiveRollerShutter) {
        this.type = rollerShutter.type ? {
            id: Number(rollerShutter.type.id),
            type: rollerShutter.type.type,
            name: rollerShutter.type.name,
            static: rollerShutter.type.static,
            titleImage: rollerShutter.type.title_image
        } : null;
        this.colors = {
            boxInner: null,
            boxGuideOuter: null,
            profile: null,
            endslat: null,
            guideRail: null,
            box: null,
            revision: null,
        };
        this.colorsOptions = {
            boxInner: null,
            boxGuideOuter: null,
            profile: null,
            endslat: null,
            guideRail: null,
            box: null,
            revision: null,
        };
        if (rollerShutter.roundReel) {
            this.roundReel = {
                id: Number(rollerShutter.roundReel.id),
                name: rollerShutter.roundReel.name
            };
        }
        this.guideType = rollerShutter.guideType;
        this.guideRails = rollerShutter.guideRails;
        this.slats = rollerShutter.slats;
        this.changedBoxHeight = rollerShutter.changedSize;
        this.boxHeight = Number(rollerShutter.realBoxHeight);
        this.boxHeightLevel = Number(rollerShutter.boxHeightLevel);
        this.boxWidth = Number(rollerShutter.realBoxWidth);
        this.rollerHeight = Number(rollerShutter.realRollerHeight);
        this.hasAssembly = rollerShutter.hasAssembly;
        this.inInsulation = rollerShutter.inInsulation;
        this.concealedStrip = rollerShutter.concealedStrip;
        this.driveSide = rollerShutter.driveSide;
        this.hasOverride = rollerShutter.driveType === 'override';
        this.driveAccessories = [];
        this.railsBottomCut = rollerShutter.railsBottomCut;
        this.railsHeightModify = rollerShutter.railsHeightModify;
        this.drive = rollerShutter.drive ? {
            id: Number(rollerShutter.drive.id),
            name: rollerShutter.drive.name,
            producer: rollerShutter.drive.producer,
            type: rollerShutter.drive.type
        } : null;
        this.hanger = rollerShutter.hanger;
        this.system = rollerShutter.system ? {
            id: Number(rollerShutter.system.id),
            name: rollerShutter.system.name,
            availableRevisionColor: rollerShutter.system.available_revision_color,
            dimensionsInfo: rollerShutter.system.dimensionsInfo,
        } : null;
        this.profile = rollerShutter.profile ? {
            id: Number(rollerShutter.profile.id),
            name: rollerShutter.profile.name,
        } : null;
        this.schemaId = rollerShutter.schemaId;
        this.shutters = rollerShutter.shutters.map<Shutter>(this.mapShutter.bind(this));
        ['boxInner', 'boxGuideOuter', 'profile', 'endslat', 'guideRail', 'box', 'revision', 'boxSideSurface'].forEach(side => {
            if (rollerShutter.colors[side] && rollerShutter.colors[side].id) {
                this.colors[side] = {
                    id: Number(rollerShutter.colors[side].id),
                    name: rollerShutter.colors[side].name,
                    code: rollerShutter.colors[side].code,
                    RAL: Boolean(rollerShutter.colors[side].RAL),
                    isDefault: rollerShutter.colors[side].isDefault,
                };
                this.colorsOptions[side] = {
                    group: rollerShutter.colors[side + 'Group'],
                    charge: {
                        percent: Number(rollerShutter.colors[side + 'Charge']),
                        m2: Number(rollerShutter.colors[side + 'ChargeM2']),
                        height: Number(rollerShutter.colors[side + 'ChargeH']),
                        width: Number(rollerShutter.colors[side + 'ChargeW']),
                    },
                };
            }
        });

        if (this.drive && this.drive.type === 'manual' && rollerShutter.driveManual && rollerShutter.driveManual.id) {
            this.driveAccessories = [{
                id: Number(rollerShutter.driveManual.id),
                name: rollerShutter.driveManual.name,
                manufacturerCode: '',
                type: 'rollerShutterManual',
                priceSource: 'table',
                color: {type: rollerShutter.driveManual.type}
            }];
        } else {
            this.driveAccessories = rollerShutter.driveElements.filter(el => el).map<SimpleAccessory>(this.mapSimpleAccessory.bind(this));
        }
    }

    public mapShutter(shutter): Shutter {
        return {
            id: Number(shutter.id),
            x: Number(shutter.x),
            width: Number(shutter.realWidth),
            height: Number(shutter.realHeight),
            railsHeightModify: Number(shutter.railsHeightModify),
            controlSide: shutter.handling === 'l' ? 'L' : 'R',
            commonRail: Boolean(shutter.commonRail),
            withMosquito: Boolean(shutter.mosquito),
            railCutHeight: Number(shutter.railCutHeight),
        };
    }

    private mapSimpleAccessory(accessory): SimpleAccessory {
        const newAccessory: SimpleAccessory = {
            id: Number(accessory.id),
            name: accessory.name,
            priceSource: accessory.price_source,
            type: accessory.type,
            manufacturerCode: accessory.manufacturer_code,
        };

        return newAccessory;
    }
}
