import Common from '../Common';
import {PriceFunc, PriceElemsData, PriceSegment} from './Prices';
import PriceColorsService from './price-colors.service';
import {Injectable, Inject} from '@angular/core';
import {APP_CONFIG, AppConfig} from '../config';

@Injectable()
export default class PriceDoorService {

    constructor(
        @Inject(APP_CONFIG) private config,
        private PriceColorsService: PriceColorsService,
    ) {}

    /**
     * Cena modelu drzwi
     * @param  {Object} model       Model
     * @param  {Object} colors      Kolory
     * @param  {Number} sashesCount Liczba skrzydeł
     * @return {Number}             Cena
     */
    priceDoorModel(model, colors, sashesCount) {
        const colorType = this.PriceColorsService.getColorTypeForColors(colors, 2);

        let field = 'price_bi_color';
        if (['White'].indexOf(colorType) > -1) {
            field = 'price_white';
        } else if (['Inner', 'Outer'].indexOf(colorType) > -1) {
            field = 'price_one_color';
        } else if (['Cream', 'Bilateral', 'Bicolor'].indexOf(colorType) > -1) {
            field = 'price_bi_color';
        }

        let price = 0;
        if (Common.isObject(model) && Common.isDefined(model[field])) {
            price = parseFloat(model[field]) * ~~sashesCount;
        }
        return price;
    }

    /**
     * Dodawanie ceny za model drzwi
     * @param  {Number} price         Cena
     * @param  {Array} PriceElems     Składowe ceny
     * @param  {Object} model         Model
     * @param  {Object} colors        Kolory
     * @param  {Number} sashesCount   Liczba skrzydeł
     * @param  {Object} NoPriceCauses Powody braku ceny
     * @return {Number}               Cena
     */
    @PriceFunc({
        shortName: 'doorModel',
        data: {
            model: 'conf.Model',
            colors: 'conf.Colors',
            sashesCount: 'conf.Sashes.length',
        }
    })
    suppDoorModel({PriceStack, PriceElems, NoPriceCauses}: PriceElemsData, {model, colors, sashesCount}): PriceSegment[] {
        if (this.config.IccConfig.Configurators.door.version > 1) {
            return [];
        }
        let supp = this.priceDoorModel(model, colors, sashesCount);
        if (Common.isUndefined(supp) || supp === null || isNaN(supp)) {
            supp = NaN;
            NoPriceCauses.push('no price for model');
        } else {
            PriceElems.doorModel = {
                id           : model.id,
                name         : model.name,
                count        : sashesCount,
                price        : supp,
                // colorField   : getAccessoryPriceField(colors),
            };
        }

        return <PriceSegment[]>[{
            type: 'doorModel',
            baseValue: supp,
            value: supp,
            valueType: 'value',
            data: {
                id   : model.id,
                name : model.name,
                count: sashesCount,
            }
        }]
    }
}

