import { Injectable } from '@angular/core';
import { Coupling, Profile, SideProfile } from 'configurations/parts/window';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import CoupledWindowActiveConfiguration from 'configurations/CoupledWindowActiveConfiguration';
import WindowConfiguration from 'configurations/WindowConfiguration';

@Injectable()
export class CouplingsService {
    static getIdForNew(conf: WindowActiveConfiguration | CoupledWindowActiveConfiguration) {
        const couplingsId = conf.couplings.reduce((prev, el) => (el.id > prev ? el.id : prev), -1);
        return couplingsId + 1;
    }

    static getLastCouplingByFrameIndex(
        frameEdges: {
            frameId: number;
            frameEdgeIndex: number;
            positionId?: string;
        }[],
        conf: WindowActiveConfiguration | CoupledWindowActiveConfiguration | WindowConfiguration
    ) {
        const firstProfiles: Coupling[] = conf.couplings.filter(s =>
            s.framesId.some(f =>
                frameEdges.some(
                    fE =>
                        f.id === fE.frameId
                        && f.edges.includes(fE.frameEdgeIndex)
                        && f.positionId === fE.positionId
                )
            )
        );
        return this.getLastCoupling(firstProfiles, conf);
    }


    static getLastCoupling(
        firstProfiles: Coupling[],
        conf: WindowActiveConfiguration | CoupledWindowActiveConfiguration | WindowConfiguration
    ) {
        const profiles = firstProfiles
            .map(s =>
                s.adjacentOtherSideProfileId.length > 0
                    ? this.getNextProfiles(
                        s,
                        conf.type === 'coupled_window' || WindowConfiguration.is(conf)
                            ? conf.sideProfiles
                            : conf.SideProfiles,
                        conf.couplings,
                        'coupling'
                      )
                    : s
            )
            .reduce<Coupling[]>((acc, val) => acc.concat(val), []);
        return profiles[0];
    }

    static getNextProfiles(
        baseProfile: Coupling | SideProfile,
        sideProfiles: SideProfile[],
        couplings: Coupling[],
        type: 'coupling' | 'sideProfile'
    ): Coupling[] {
        const nextProfiles: (Coupling | SideProfile)[] = [
            ...sideProfiles.filter(s =>
                type === 'coupling'
                    ? baseProfile.adjacentOtherSideProfileId.includes(s.id)
                    : baseProfile.adjacentOtherCouplingId
                      && baseProfile.adjacentOtherCouplingId.includes(s.id)
            ),
            ...couplings.filter(s =>
                type === 'coupling'
                    ? baseProfile.adjacentOtherSideProfileId.includes(s.id)
                    : baseProfile.adjacentOtherCouplingId
                      && baseProfile.adjacentOtherCouplingId.includes(s.id)
            ),
        ];
        const allNextProfiles = nextProfiles
            .map(s =>
                s.adjacentOtherSideProfileId.length > 0
                    ? this.getNextProfiles(s, sideProfiles, couplings, 'sideProfile')
                    : s.adjacentOtherCouplingId.length > 0
                        ? this.getNextProfiles(s, sideProfiles, couplings, 'sideProfile')
                        : s as Coupling
            )
            .reduce<Coupling[]>((acc, val) => acc.concat(val), []);
        return allNextProfiles;
    }
}
