import { ScrollbarService } from 'app/scrollbar.service.ajs';
import { WindowSillsService } from './window-sills.service';

export default class WindowSillsController {
    sillsCategory = 'w';
    sills = [];
    added: ({ sill, event }) => any;
    selectedSillId: number = null;
    singleSelect = false;
    constructor(
        private $translate: ng.translate.ITranslateService,
        private scrollbarService: ScrollbarService,
        private windowSillsService: WindowSillsService
    ) {
        this.sills = this.windowSillsService.sills;
    }

    $onChanges(changes) {}

    refresh() {
        this.scrollbarService.update();
    }

    calcSill(sill) {
        return this.windowSillsService.calcSill(sill);
    }

    getSillWidths(sill) {
        return this.windowSillsService.getSillWidths(sill);
    }

    getSillLengths(sill) {
        return this.windowSillsService.getSillLengths(sill);
    }

    setInRange(sill) {
        this.windowSillsService.setInRange(sill);
    }

    add(sill, event) {
        this.added({ sill, event });
    }
}
WindowSillsController.$inject = ['$translate', 'ScrollbarService', 'WindowSillsService'];
