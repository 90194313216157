import { NgModule } from '@angular/core';
import { stepFactoryProvider, glazingFactoryProvider } from './ajs-upgraded-providers';
import { WindowModule } from 'configurator/steps/window/window.module';
import { ComplementaryGoodsModule } from 'configurator/steps/complementary_goods/complementary-goods.module';

@NgModule({
    imports: [WindowModule, ComplementaryGoodsModule],
    exports: [],
    declarations: [],
    providers: [stepFactoryProvider, glazingFactoryProvider],
})
export class StepsModule {}
