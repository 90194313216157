import Common from 'Common';

/**
 * Konstruktor fabryki edycji pozycji oferty
 */
export default function EditPositionFactory(
    $rootScope,
    $location,
    $timeout,
    $q,
    ConfigurationsService,
    ConfiguratorsDataService,
    Core,
    PositionsFactory,
    IccConfig,
    UserService,
    CoupledWindowService
) {
    'ngInject';

    let factory = {
        addSimilar,
        editPosition,
    };

    return factory;

    /**
     * Dodawanie podobnej pozycji do oferty
     * @param {object} position  Pozycja
     */
    function addSimilar(position, offer) {
        let object = Core.copy(position);
        object.doc.NewPosition = true;
        object.doc.tmp_id = 'tmp_DealerOfferPosition_' + Core.generateUUID();
        delete object.doc.id;
        delete object.doc._id;
        delete object.doc._rev;
        if (object.doc.confType === 'coupled_window') {
            PositionsFactory.copyPositionsFromScope(offer, object.doc.dealer_offer_id, [
                ...object.doc.details.windows.map(window => window.positionId),
                ...object.doc.details.rollerShutters.map(rollerShutter => rollerShutter.positionId),
            ]).then((relations: Record<string, string>) => {
                object.doc.details.windows = object.doc.details.windows.map(window => {
                    window.positionId = relations[window.positionId];
                    return window;
                });
                object.doc.details.rollerShutters = object.doc.details.rollerShutters.map(
                    rollerShutter => {
                        rollerShutter.positionId = relations[rollerShutter.positionId];
                        return rollerShutter;
                    }
                );
                object.doc.details.couplings = object.doc.details.couplings.map(
                    coupling => {
                        coupling.framesId.forEach(rel => {
                            rel.positionId = relations[rel.positionId];
                        });
                        coupling.otherFramesId.forEach(rel => {
                            rel.positionId = relations[rel.positionId];
                        });
                        return coupling;
                    }
                );
                object.doc.details.sideProfiles = object.doc.details.sideProfiles.map(
                    sideprofile => {
                        sideprofile.framesId.forEach(rel => {
                            rel.positionId = relations[rel.positionId];
                        });
                        return sideprofile;
                    }
                );
                object.doc.details.windowSills = object.doc.details.windowSills.map(
                    sill => {
                        sill.framesId.forEach(rel => {
                            rel.positionId = relations[rel.positionId];
                        });
                        return sill;
                    }
                );
                editPosition(object);
            });
        } else {
            editPosition(object);
        }
    }

    /**
     * Resetowanie konfiguracji do edycji w konfiguratorze
     * @param {object} confFactory           confFactory
     * @param {object} position              position
     * @param {string} url                   url
     */
    function editPositionConf(type, position, attachments?, url?) {
        ConfiguratorsDataService.load().then(() => {
            let user = UserService.get();
            if (IccConfig.Offer.demoOffer && (user.access === 'klient' || !user.access)) {
                position.doc.group_discounts = {};
                PositionsFactory.updatePrices(position.doc);
            }

            const confFactory = ConfigurationsService.getOrInitConfiguratorConfigurations(type);
            confFactory.Edit = ConfigurationsService.createActiveConfiguration(
                position.doc.details,
                ConfiguratorsDataService
            );
            if (attachments) {
                confFactory.measurementsAttachments = attachments.filter(
                    el => el.position_number != null
                );
            }
            confFactory.Edit.Quantity = position.doc.quantity;
            confFactory.Edit.Title = position.doc.custom_title;
            confFactory.Edit.Description = position.doc.description;
            confFactory.Edit._id = position.doc.tmp_id;
            confFactory.Edit._rev = position.doc._rev;

            const customFillings = Core.parseJson(localStorage.getItem('customFillings') || '[]');
            if (confFactory.Edit.CustomFillings) {
                confFactory.Edit.CustomFillings.forEach(custom => {
                    if (Common.isUndefined(Core.fId(customFillings, custom.id))) {
                        customFillings.push(custom);
                    }
                });
            }

            localStorage.setItem('customFillings', JSON.stringify(customFillings));

            if (position.doc.NewPosition) {
                confFactory.NewPosition = true;
            }

            if (type === 'coupled_window') {
                CoupledWindowService.setCoupledWindow(confFactory.Edit, true);
            }

            if (url) {
                $timeout(() => {
                    $location.url(`/app/${type}`);
                });
            }
        });
    }

    /**
     * Przejscie pod konfigurator do edycji pozycji
     *  @param {object} object              Obiekt
     */
    function editPosition(object, attachments?) {
        $rootScope.completelyNewPosition = false;
        $rootScope.editedPositionId = object.doc.tmp_id;
        editPositionConf(object.doc.configuration.type, object, attachments, true);
    }
}
