import * as angular from 'angular';
import {Injectable, Inject, Injector} from '@angular/core';
import {APP_CONFIG, AppConfig} from './config';
import {logger} from './helpers';
import Common from 'Common';

declare var WorkerGlobalScope: any;

/**
 * Fabryka użytkowników
 * @param {object} $filter             filter
 * @param {object} $q                  q
 * @param {object} SynchronizeService  SynchronizeService
 * @param {String} USER_ID             id użytkownika
 */
@Injectable()
export default abstract class UserService {

    protected user = null;

    /**
     * Funkcja do zwracania użytkownika
     * @return {Object} Użytkownik
     */
    get() {
        if (!this.user) {
            this.loadUser();
        }
        return this.user;
    }

    get loggedIn() {
        return Common.isDefined(this.user) && this.user !== null && this.user.id != null && localStorage.getItem('token');
    }

    get offerModuleAccess() {
        return Common.isDefined(this.user) && this.user !== null && (this.user.dealerOfferingModuleAccess || ['dealer', 'dealerh', 'dealerm'].indexOf(this.user.access) === -1);
    }

    /**
     * Ładuje dane użytkownika.
     */
    abstract loadUser();

    abstract setUser(user);

    abstract synchronizeUser(): Promise<any>;

}
