const MountingMethodModalTemplate = require<string>('ngtemplate-loader!./mounting-method-modal.component.html');
import MountingMethodModalController from './mounting-method-modal.controller';

export const MountingMethodModalComponent: ng.IComponentOptions = {
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  templateUrl: MountingMethodModalTemplate,
  controller: MountingMethodModalController
};
