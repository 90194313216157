import {Injectable, Inject} from '@angular/core';
import ResourceService from 'sync/resource.service';
import UserService from 'user.service';
import {HttpClient} from '@angular/common/http';
import {Location} from '@angular/common';
import {EventBusService} from 'event-bus.service';
import {APP_CONFIG, AppConfig} from 'config';

@Injectable()
export class LicenseService {

    constructor(
        private resourceService: ResourceService,
        private userService: UserService,
        private httpClient: HttpClient,
        private location: Location,
        private eventBusService: EventBusService,
        @Inject(APP_CONFIG) private config: AppConfig,
    ) {
        this.checkLicense();
    }

    async getLicenseContent() {
        const licenseContent = (await this.httpClient.get(`${this.config.EnvConfig.remoteHost || window.location.origin}/application/dist/application/license/contents/${this.userService.get().marketId}.${this.config.ProjectName}.html`, {responseType: 'text'}).toPromise());
        return licenseContent;
    }

    accepted() {
        const licenseInfo = localStorage.getItem('license+' + this.userService.get().id);
        if (licenseInfo) {
            const [licenseAcceptedVersion, licenseContentVersion] = licenseInfo.split('+');
            return Number(licenseAcceptedVersion) > 0;
        }
        return false;
    }

    accept() {
        this.checkLicense(this.userService.get().marketId, 1).then(() => {
            setTimeout(() => {
                this.location.go('/app/update');
            });
        });
    }

    async checkLicense(contentVersion = '1', acceptedVersion?: number) {
        let dataToSend = {};
        if (acceptedVersion && contentVersion) {
            dataToSend = {
                acceptedVersion: Number(acceptedVersion),
                contentVersion
            };
        }
        const licenseData = await this.resourceService.license(dataToSend);
        if (licenseData) {
            localStorage.setItem('license+' + this.userService.get().id, licenseData.acceptedVersion + '+' + licenseData.contentVersion);
            return Number(licenseData.acceptedVersion);
        }
        localStorage.removeItem('license+' + this.userService.get().id);
        return false;
    }
}
