import angular from 'angular';
import {logger} from 'helpers';

export default function SynchronizeCtrl(
    $scope,
    $rootScope,
    $http,
    $location,
    $cookies,
    ResourceService,
    SynchronizeService,
    OffersFactory,
    DatabaseManager,
    UserService,
    IccConfig,
    StateService,
    EventBusService,
    OfferSummaryService,
) {
    'ngInject';

    const vm = this;
    vm.progressBar = SynchronizeService.progressBar;
    $scope.slides = [];
    $scope.postCodesSynced = localStorage.getItem('post_codes_synced' + USER_ID) || null;
    synchronizeMySqlClient();
    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('progress', () => vm.progressBar = SynchronizeService.progressBar));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    ResourceService.getSynchronizeElements().then(res => {
        $scope.slides = res.data;
    });
    async function synchronizeMySqlClient(repeat = false) {
        try {
            const redirectData = window.location.search.replace('?', '').split(/[=\&]+/);
            if (redirectData[0] === 'thumb') {
                sessionStorage.removeItem('adminMistake');
                sessionStorage.setItem('adminThumb', redirectData[1]);
                sessionStorage.setItem('adminLayout', redirectData[3]);
                // idz do konfigurowania produktow z pomylek
            } else if (redirectData[0] === 'mistake') {
                sessionStorage.removeItem('adminThumb');
                sessionStorage.removeItem('adminLayout');
                sessionStorage.setItem('adminMistake', redirectData[3] || true);
                // utowrz pusta oferte i idz do niej
            } else {
                sessionStorage.removeItem('adminMistake');
                sessionStorage.removeItem('adminThumb');
                sessionStorage.removeItem('adminLayout');
            }
            if (!$scope.postCodesSynced) {
                await SynchronizeService.synchronizeFirst(repeat);
            }
            localStorage.removeItem('post_codes_synced' + USER_ID);
            const user = UserService.get();

            // idź do generowania miniatury układu
            if (redirectData[0] === 'thumb') {
                if (sessionStorage.getItem('adminThumb')) {
                    logger.error('Przekierowanie na thumb /app/' + (redirectData[5] || 'window'));
                    // $location.path('/app/' + (redirectData[5] || 'window'));
                    window.location.replace('/app/' + (redirectData[5] || 'window'));
                }
                // idź do konfigurowania produktów z pomyłek
            } else if (redirectData[0] === 'mistake') {
                if (sessionStorage.getItem('adminMistake')) {
                    logger.error('Przekierowanie na mistake /app/' + redirectData[1]);
                    $location.path('/app/' + redirectData[1]);
                }
                // jeżeli było zaplanowane przekierowanie
            } else if (angular.isDefined(sessionStorage.redirect)) {
                const redirect = sessionStorage.redirect;
                sessionStorage.removeItem('redirect');
                $location.path(redirect);
                // utwórz pustą ofertę i idź do niej
            } else if (!user.access) {
                const client = UserService.getClient() || {};
                if (!StateService.state.offer_id) {
                    const id = await OffersFactory.add({
                        status       : 99,
                        client_offer : 1,
                        dealer_status: 0,
                        dealer_id    : angular.isDefined(client.dealer_id) ? client.dealer_id : null,
                        b2c_offer_source : 1,
                    }, client || {});

                    StateService.setState({
                        offer_id: id,
                        offers  : null,
                        client  : null,
                        key     : null,
                        offer   : { id }
                    });
                    $location.path('/app/offers_view/' + id);
                    $scope.$apply()

                } else {
                    $location.path('/app/offers_view/' + StateService.state.offer_id);
                }
                // po zalogowaniu wyswietl komunikat
            } else if (user.access == 'klient') {
                sessionStorage.setItem('justLogged', true);
                $location.path('/app/start');
                // idz do panelu uzytkownika
            } else {
                $location.path('/app/start');
            }
        } catch (err) {
            return synchronizeMySqlClient(true);
        }
    }
}
