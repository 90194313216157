import { NgModule } from '@angular/core';
import ReinforcementsService from 'configurators/reinforcements.service';
import { MeasurementsComponent } from './measurements.component';
import MeasurementsService from './measurements.service';
import { TranslatePipeModule } from '../../../../app/translate.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { constructionLimitationFactoryProvider } from './ajs-upgraded-providers';
import TranslateService from 'translations/TranslateService'

@NgModule({
    imports: [CommonModule, FormsModule, TranslatePipeModule],
    exports: [MeasurementsComponent],
    declarations: [MeasurementsComponent],
    providers: [ReinforcementsService, MeasurementsService, constructionLimitationFactoryProvider, TranslateService],
    entryComponents: [MeasurementsComponent],
})
export class DimensionsModule {
    constructor() {}
}
