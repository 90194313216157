import StepFactory from './StepFactory';

export function stepFactoryFactory(i: any) {
    return i.get('StepFactory');
}
export const stepFactoryProvider = {
    provide: StepFactory,
    useFactory: stepFactoryFactory,
    deps: ['$injector'],
};

export function glazingFactoryFactory(i: any) {
    return i.get('GlazingFactory');
}

export const glazingFactoryProvider = {
    provide: 'GlazingFactory',
    useFactory: glazingFactoryFactory,
    deps: ['$injector'],
};
