import { ActiveConfiguration } from './ActiveConfiguration';
import WinterGardenConfiguration from './WinterGardenConfiguration';
import { PriceSegment, DiscountGroups, PricePart } from '../price/Prices';
import { ConfiguratorsDataService } from '../configurators/configurators-data.service';

export default class WinterGardenActiveConfiguration implements ActiveConfiguration {
    public static is(configuration): configuration is WinterGardenActiveConfiguration {
        return configuration instanceof WinterGardenActiveConfiguration || !configuration.$version;
    }
    type: 'winter_garden' = 'winter_garden';
    Name: string = '';
    Price: number = NaN;
    PriceNoMargin: number = NaN;
    PriceAfterDiscounts: number = 0;
    Quantity: number = 1;
    Issues: any[] = [];
    PriceSegments: PriceSegment[] = [];
    PriceSegmentsNoMargin: PriceSegment[] = [];
    PriceParts: PricePart[] = [];
    PricePartsNoMargin: PricePart[] = [];
    DiscountGroups: DiscountGroups = {};
    DiscountGroupsNoMargin: DiscountGroups = {};
    Title = '';
    Attachments = [];
    valid: {} = {};
    PriceValid = false;
    drawData: any;

    Garden: any = {};
    Description: string = '';

    constructor(configuration?, private configuratorsDataService?: ConfiguratorsDataService) {
        if (configuration) {
            if (WinterGardenActiveConfiguration.is(configuration)) {
                for (const propName in configuration) {
                    if (this.hasOwnProperty(propName)) {
                        this[propName] = configuration[propName];
                    }
                }
            } else {
                if (WinterGardenConfiguration.is(configuration) && configuratorsDataService) {
                    this.Name = configuration.name;
                    this.Price = configuration.price;
                    this.PriceNoMargin = configuration.priceNoMargin;
                    this.PriceAfterDiscounts = configuration.priceAfterDiscounts;
                    this.Quantity = configuration.quantity;
                    this.PriceSegments = configuration.priceSegments;
                    this.PriceSegmentsNoMargin = configuration.priceSegmentsNoMargin;
                    this.PriceParts = configuration.priceParts;
                    this.PricePartsNoMargin = configuration.pricePartsNoMargin;
                    this.DiscountGroups = configuration.discountGroups;
                    this.DiscountGroupsNoMargin = configuration.discountGroupsNoMargin;
                    this.Title = configuration.title;
                    this.valid = configuration.valid;

                    this.Garden = configuratorsDataService.get(
                        'winterGardens',
                        configuration.garden.id
                    );
                    this.Garden.dimensions = {};
                    for (const label in configuration.dimensions) {
                        if (configuration.dimensions.hasOwnProperty(label)) {
                            this.Garden.dimensions[label] = configuration.dimensions[label];
                        }
                    }
                    this.Garden.handle_facet = configuration.handleFacet;
                    this.Description = configuration.description;
                }
            }
        }
        this.configuratorsDataService = null;
    }
}
