import { Configuration } from './Configuration';
import { PriceSegment, DiscountGroups, PricePart } from '../price/Prices';
import { Garden, Dimensions } from './parts/winter_garden';

import WinterGardenActiveConfiguration from './WinterGardenActiveConfiguration';

export default class WinterGardenConfiguration implements Configuration {

    public static is(configuration): configuration is WinterGardenConfiguration {
        return configuration instanceof WinterGardenConfiguration || configuration.$version;
    }
    $version = 5;
    type: 'winter_garden' = 'winter_garden';
    name = '';
    price: number | null = null;
    priceNoMargin: number | null = null;
    priceAfterDiscounts: number | null = null;
    quantity: number = 1;
    priceSegments: PriceSegment[] = [];
    priceSegmentsNoMargin: PriceSegment[] = [];
    priceParts: PricePart[] = [];
    pricePartsNoMargin: PricePart[] = [];
    discountGroups: DiscountGroups = {};
    discountGroupsNoMargin: DiscountGroups = {};
    title = '';
    attachments: any[] = [];
    valid: {} = {};

    garden: Garden = null;
    dimensions: Dimensions = {};
    handleFacet: string = null;
    description: string = '';

    constructor(configuration) {
        if (WinterGardenConfiguration.is(configuration)) {
            for (const propName in configuration) {
                if (this.hasOwnProperty(propName)) {
                    this[propName] = configuration[propName];
                }
            }
        } else {
            if (WinterGardenActiveConfiguration.is(configuration)) {
                this.name = configuration.Name;
                this.price = configuration.Price;
                this.priceNoMargin = configuration.PriceNoMargin;
                this.priceAfterDiscounts = configuration.PriceAfterDiscounts || configuration.Price;
                this.quantity = configuration.Quantity;
                this.priceSegments = configuration.PriceSegments;
                this.priceSegmentsNoMargin = configuration.PriceSegmentsNoMargin;
                this.priceParts = configuration.PriceParts;
                this.pricePartsNoMargin = configuration.PricePartsNoMargin;
                this.discountGroups = configuration.DiscountGroups;
                this.discountGroupsNoMargin = configuration.DiscountGroupsNoMargin;
                this.title = configuration.Title;
                this.attachments = configuration.Attachments;
                this.valid = configuration.valid;

                this.garden = {
                    id: Number(configuration.Garden.id),
                    name: configuration.Garden.name,
                };
                for (const label in configuration.Garden.dimensions) {
                    if (configuration.Garden.dimensions.hasOwnProperty(label)) {
                        this.dimensions[label] = Number(configuration.Garden.dimensions[label]);
                    }
                }
                this.handleFacet = configuration.Garden.handle_facet;
                this.description = configuration.Description;
            }
        }
    }
}
