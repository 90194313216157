import {vendorModules} from './vendor';

import * as angular from 'angular';

import Routes from './routes';

import iccConfigurator from '../configurator/configurator.module.ajs';
import iccSync from '../sync/sync';
import iccPanel from '../panel/panel.module.ajs';
import iccStart from '../start/start';
import iccVisualizer from '../visualizer/visualizer';
import iccPriceCommon from '../../../common/price/price.module.ajs';
import iccPrice from '../price/price.module.ajs';
import iccOffer from '../offer/offer';
import iccComplaint from '../complaint/complaint';
import iccHeader from '../header/header';
import iccFooter from '../footer/footer';
import iccPostCode from '../post_code/post_codes';
import iccTutorial from '../tutorials/tutorials';
import iccDependencies from '../dependencies/dependencies.module.ajs';
import iccGtm from '../gtm/gtm';
import iccTimeLimits from '../time_limit/time_limits';
import iccLicense from '../license/license.module.ajs';
import AppDirectives from './directives/directives';
import Filters from './filters/filters';
import Interceptors from './interceptors/interceptors';

import AppCtrl from './AppCtrl';
import Core from './Core';
import StateService from 'state.service';
import TokenService from '../authorization/token.service';
import AuthService from '../authorization/auth.service';
import LangService from './lang.service';
import {RoutesGuardsService} from './routes-guard.service.ajs';
import {ScrollbarService} from './scrollbar.service.ajs';

import {downgradeComponent, downgradeInjectable} from '@angular/upgrade/static';
import {IccDrawComponent} from '@icc/draw';
import {LogoutCtrl} from 'authorization/logout.ctrl';
import {LoginCtrl} from 'authorization/login.ctrl';

const modules = [
    ...vendorModules,
    AppDirectives,
    Filters,
    Interceptors,
    iccConfigurator,
    iccSync,
    iccPanel,
    iccStart,
    iccVisualizer,
    iccPriceCommon,
    iccPrice,
    iccOffer,
    iccComplaint,
    iccHeader,
    iccFooter,
    iccPostCode,
    iccTutorial,
    iccDependencies,
    iccGtm,
    iccTimeLimits,
    iccLicense,
];

export default angular.module('icc', modules)
.controller('AppCtrl', AppCtrl)
.controller('LogoutCtrl', LogoutCtrl)
.controller('LoginCtrl', LoginCtrl)
.service('Core', Core)
.service('RoutesGuardsService', RoutesGuardsService)
.service('ScrollbarService', ScrollbarService)
.factory('StateService', downgradeInjectable(StateService))
.factory('TokenService', downgradeInjectable(TokenService))
.factory('AuthService', downgradeInjectable(AuthService))
.factory('LangService', downgradeInjectable(LangService))
.directive('iccDraw', downgradeComponent({component: IccDrawComponent}) as angular.IDirectiveFactory)
.config(Routes)
.config(function($httpProvider) {
    'ngInject';

    $httpProvider.interceptors.push('IccInterceptor');
})
.config((hotkeysProvider) => {
    'ngInject';

    hotkeysProvider.includeCheatSheet = false;
    hotkeysProvider.templateTitle = 'Skróty klawiaturowe';
    hotkeysProvider.cheatSheetDescription  = 'Pokaż/ukryj ten ekran';
})
.config(function($translateProvider, tmhDynamicLocaleProvider) {
    'ngInject';

    $translateProvider.useStaticFilesLoader({
        prefix: '/application/dist/locale/',
        suffix: '.project.json'
    });
    $translateProvider.useSanitizeValueStrategy(null);
    $translateProvider.useMessageFormatInterpolation();
    $translateProvider.preferredLanguage(LANG_CODE);

    tmhDynamicLocaleProvider.localeLocationPattern('/application/dist/application/locale/{{locale}}.js');
})
.config([
    '$compileProvider',
    function($compileProvider) {
        $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|icc-res|mailto|chrome-extension|file|cdvfile):/);
        $compileProvider.imgSrcSanitizationWhitelist(/^\s*(https?|icc-res|file|cdvfile|blob|content):|data:image\//);
    },
])
.run(function ($rootScope, tmhDynamicLocale, UserService, StateService, SynchronizeService, DatabaseManager, RoutesGuardsService) {
    'ngInject';

    RoutesGuardsService.init();
    $rootScope.Math = Math;
    DatabaseManager.setEmitter($rootScope.$emit.bind($rootScope));
    tmhDynamicLocale.set(LANG_CODE);
    StateService.init();
    SynchronizeService.refreshIDB();
});
