import angular from 'angular';

/**
 * Funkcja kategorii szyby
 */
export default function GlassCatFilter() {
    'ngInject';

    return function(glass, categoryId, subcatId) {
        var glassArr = [];
        angular.forEach(glass, function(obj) {
            glassArr.push(obj);
        });
        if (angular.isUndefined(categoryId) || categoryId === null || categoryId === '') {
            return glass;
        } else {
            return glassArr.filter(function(gl) {
                if (gl.fillings_parent_id == categoryId || gl.fillings_category_id == categoryId) {
                    if (angular.isUndefined(subcatId) || subcatId === null || subcatId === '') {
                        return true;
                    } else if (gl.fillings_category_id == subcatId) {
                        return true;
                    }
                }
                return false;
            });
        }
    };
}
