import angular from 'angular';

/**
 * Funkcja Rw szyby
 */
export default function GlassRwFilter() {
    'ngInject';

    return function(glass, rw) {
        var glassArr = [];
        angular.forEach(glass, function(obj) {
            glassArr.push(obj);
        });
        if (angular.isUndefined(rw) || rw === null || rw === '') {
            return glassArr;
        } else {
            return glassArr.filter(function(gl) {
                if (angular.isString(rw) && rw[0] == '<') {
                    return parseFloat(rw.substr(1)) > parseFloat(gl.rw);
                } else if (angular.isString(rw) && rw[0] == '>') {
                    return parseFloat(rw.substr(1)) <= parseFloat(gl.rw);
                } else {
                    return parseFloat(rw) <= parseFloat(gl.rw);
                }
            });
        }
    };
}
