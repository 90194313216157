export function iccDrawingMathFactoryFactory(i: any) {
    return i.get('IccDrawingMathFactory');
}
export const iccDrawingMathFactoryProvider = {
    provide: 'IccDrawingMathFactory',
    useFactory: iccDrawingMathFactoryFactory,
    deps: ['$injector'],
};

export function drawDataServiceFactory(i: any) {
    return i.get('DrawDataService');
}
export const drawDataServiceProvider = {
    provide: 'DrawDataService',
    useFactory: drawDataServiceFactory,
    deps: ['$injector'],
};
