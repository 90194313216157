export default class NewPositionService {
    constructor(
        private $uibModal,
        private $location,
        private $rootScope,
        private CurConfService,
        private OfferViewFactory,
        private IccConfig,
        private EventBusService,
        private TutorialsService
    ) {
        'ngInject';
    }

    openModal(offer, callbackCustomPosition, callbackPosition?, callbackClose?) {
        const modalInstance = this.$uibModal.open({
            templateUrl: '/application/offer/offer_view/offer_new_position.project.html',
            controller: 'ModalNewPositionCtrl as $ctrl',
            resolve: {
                offer: () => offer,
                fromCoupling: () => false,
                toCoupledWindow: () => false,
            },
            windowClass: 'newProduct',
        });
        if (this.IccConfig.GTM.allowTags) {
            this.EventBusService.post({
                key: 'googleTags',
                value: 'newPosition',
            });
        }

        modalInstance.closed.then(() => {
            if (this.IccConfig.Configurators.tutorialAvailable) {
                this.EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });

        return modalInstance.result.then(configurator => {
            if (configurator) {
                this.$rootScope.coupledPosition = false;
                this.TutorialsService.newPositionFlag = 1;
                if (configurator === 'custom') {
                    this.OfferViewFactory.addCustomPosition(offer, () => {
                        callbackCustomPosition();
                    });
                } else {
                    if (
                        this.IccConfig.Offer.completelyNewPosition
                        && !this.$rootScope.coupledPosition
                    ) {
                        this.$rootScope.completelyNewPosition = true;
                    }
                    if (configurator === this.CurConfService.conf) {
                        if (typeof callbackPosition === 'function') {
                            callbackPosition();
                        }
                    } else {
                        this.$location.url(`/app/${configurator}`);
                    }
                }
            } else {
                if (typeof callbackClose === 'function') {
                    callbackClose();
                }
            }
        });
    }
}
