import Common from '../Common';
import {PriceFunc, PriceElemsData, PriceSegment} from './Prices';
import {CustomPricesRecords} from './CustomPrice';
import {core} from '../helpers';
import {APP_CONFIG, AppConfig} from 'config';
import {Injectable, Inject} from '@angular/core';

/**
 * Factory dopłat za system.
 *
 * @export
 * @param {object} Core Core
 * @return {object} Factory
 */
@Injectable()
export default class PriceSystemService {

    constructor(@Inject(APP_CONFIG) private config: AppConfig) {}

    /**
     * Dopłata procentowa za system.
     *
     * @param {number} price          Cena wejściowa.
     * @param {object} PriceElems     Składowe ceny
     * @param {array}  NoPriceCauses  Powody braku wyceny.
     * @param {array}  constrElems    Lista elementów konstrukcyjnych, do przeliczenia przez dopłatę.
     * @param {object} system         System.
     * @param {bool}   sum            Czy dopłata za system na być sumowana do dopłaty za kształt.
     * @param {object} multiplier     Jeżeli dealer ma ustawiony mnoznik to uzyj zamiast wpolczynnika
     * @return {number} Cena z doliczoną dopłatą.
     */
    @PriceFunc({
        shortName: 'system',
        data: {
            system     : 'conf.System',
            multiplier : 'multipliers',
            customPrice: 'price.WindowLine',
            dealerId   : 'dealerId',
            marketId   : 'marketId'
        }
    })
    suppSystem({}: PriceElemsData,
        {system, multiplier, customPrice, dealerId}: {system: any; multiplier: any; customPrice: CustomPricesRecords, dealerId}
    ): PriceSegment[] {
        let factors      = 1;
        let systemFactor = 1;

        if (Common.isObject(multiplier)
            && Common.isDefined(multiplier[dealerId])
            && Common.isDefined(multiplier[dealerId].WindowLine)
            && Common.isDefined(multiplier[dealerId].WindowLine[system.id])
            && !this.config.IccConfig.Dealer.surchargeMultipliers
        ) {
            systemFactor = core.num(multiplier[dealerId].WindowLine[system.id]) ? core.num(multiplier[dealerId].WindowLine[system.id]) : 1;
        } else if (Common.isObject(multiplier)
            && Common.isDefined(multiplier.WindowLine)
            && Common.isDefined(multiplier.WindowLine[system.id])
            && !this.config.IccConfig.Dealer.surchargeMultipliers
        ) {
            systemFactor = core.num(multiplier.WindowLine[system.id]) ? core.num(multiplier.WindowLine[system.id]) : 1;
        } else if (Common.isObject(customPrice) && customPrice[system.id]) {
            systemFactor = (customPrice[system.id].getPrice('price_factor') + 100) / 100;
        } else {
            systemFactor = (parseFloat(system.price_factor) + 100) / 100;
        }
        factors *= (systemFactor);
        return [{
            type: 'system',
            baseValue: systemFactor,
            value: systemFactor,
            valueType: 'percent',
            data: {
                id: system.id,
                name: system.name,
                type: system.type
            }
        }];
    }
}
