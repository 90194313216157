export function $uibModalFactory(i: any) {
  return i.get('$uibModal');
}
export const $uibModalProvider = {
  provide: '$uibModal',
  useFactory: $uibModalFactory,
  deps: ['$injector']
};

export function $translateFactory(i: any) {
  return i.get('$translate');
}
export const $translateProvider = {
  provide: '$translate',
  useFactory: $translateFactory,
  deps: ['$injector']
};

export function infoFactoryFactory(i: any) {
  return i.get('InfoFactory');
}
export const infoFactoryProvider = {
  provide: 'InfoFactory',
  useFactory: infoFactoryFactory,
  deps: ['$injector']
};

export function currentConfiguratorServiceFactory(i: any) {
  return i.get('CurConfService');
}
export const currentConfiguratorServiceProvider = {
  provide: 'CurConfService',
  useFactory: currentConfiguratorServiceFactory,
  deps: ['$injector']
};

export function $sceFactory(i: any) {
  return i.get('$sce');
}
export const $sceProvider = {
  provide: '$sce',
  useFactory: $sceFactory,
  deps: ['$injector']
};

export function $injectorFactory(i: any) {
  return i.get('$injector');
}
export const $injectorProvider = {
  provide: '$injector',
  useFactory: $injectorFactory,
  deps: ['$injector']
};