import angular from 'angular';

/**
 * Funkcja Ug szyby
 */
export default function GlassUgFilter() {
    'ngInject';

    return function (glass, ug) {
        var glassArr = [];
        angular.forEach(glass, (obj) => {
            glassArr.push(obj);
        });
        if (angular.isUndefined(ug) || ug === null || ug === '') {
            return glassArr;
        } else {
            return glassArr.filter(function(gl) {
                if (angular.isString(ug) && ug[0] == '>') {
                    return parseFloat(ug.substr(1)) < parseFloat(gl.u);
                } else if (angular.isString(ug) && ug[0] == '<') {
                    return parseFloat(ug.substr(1)) >= parseFloat(gl.u);
                } else {
                    return parseFloat(ug) >= parseFloat(gl.u);
                }

            });
        }
    };
}
