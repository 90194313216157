import Common from '../Common';
import {PriceFunc, PriceElemsData, PriceSegment} from './Prices';
import {Injectable} from '@angular/core';

@Injectable()
export default class PriceGarageService {

    constructor() {}

    /**
     * Decyduje ktory cennik ma być uzyty do wyceny
     * @param  {object} colors       Kolory konstrukcji
     * @return {number}              Grupa koloru
     */
    getGarageColorTypeForColors(colors) {
        let colorType = '';
        if (Common.isDefined(colors.outer) ) {
            if (colors.outer.group === 'white') {
                colorType = 'b';
            } else if (colors.outer.group === 'RAL') {
                colorType = 'r';
            } else if (colors.outer.group === 'srenolit') {
                colorType = 'o';
            } else if (colors.outer.group === 'scolor') {
                colorType = 's';
            } else if (colors.outer.group === 'sRAL') {
                colorType = 'p';
            } else if (colors.outer.group === 'standard') {
                colorType = 'std';
            }
        }

        return colorType;
    }

    /**
     * Wylicza cene bramy garazowej
     * @param  {number} height       Wysokosc konstrukcji
     * @param  {number} width        Szerokosc konstrukcji
     * @param  {number} priceId      Id cennika
     * @param  {object} PriceElems   Wycena
     * @param  {object} NoPriceElems Elementy bez wyceny
     * @param  {object} matrixes     Cennik
     * @return {number}              Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'garageSize',
        data     : {
            height      : 'conf.Height',
            width       : 'conf.Width',
            matrixes    : 'data.garagePrices',
            priceDepends: 'data.garagePricesDepends',
            system      : 'conf.System',
            colors      : 'conf.Colors',
            panel       : 'conf.Panel',
            structure   : 'conf.Structure',
            guide       : 'conf.Guide',
            shape       : 'conf.Shape',
            control: 'conf.Control'
        }
    })
    priceForGarage({PriceStack, PriceElems, NoPriceCauses}: PriceElemsData, {
        height, width, matrixes, priceDepends, system, colors, panel, structure, guide, shape, control
    }): PriceSegment[] {
        const priceId = this.getPriceForGarage(priceDepends, system, colors, panel, structure, NoPriceCauses, shape.width, shape.height, guide, control);
        if (Common.isDefined(priceId) && priceId !== null) {
            const price = matrixes[priceId];

            if (Common.isDefined(priceId) && priceId !== null) {
                const priceField = price.data.filter(el =>
                    el.height_from <= height && el.height_to >= height && el.width_from <= width && el.width_to >= width
                );
                if (Common.isArray(priceField) && priceField.length > 0) {
                    PriceElems.size = {
                        price  : parseFloat(priceField[0].price),
                        priceId: priceId,
                        height : height,
                        width  : width,
                    };
                    return [{
                        type     : 'size',
                        baseValue: parseFloat(priceField[0].price),
                        value    : parseFloat(priceField[0].price),
                        valueType: 'value',
                        data     : {
                            priceId: priceId,
                            height : height,
                            width  : width
                        }
                    }];
                } else {
                    NoPriceCauses.push('no price in matrix');
                    return [{
                        type     : 'size',
                        baseValue: null,
                        value    : null,
                        valueType: 'value',
                        data     : {
                            priceId: priceId,
                            height : height,
                            width  : width
                        }
                    }];
                }
            } else {
                NoPriceCauses.push('no matrix');
                return [{
                    type     : 'size',
                    baseValue: null,
                    value    : null,
                    valueType: 'value',
                    data     : {
                        priceId: priceId,
                        height : height,
                        width  : width
                    }
                }];
            }
        } else {
            NoPriceCauses.push('no matrix');
            return [{
                type     : 'size',
                baseValue: null,
                value    : null,
                valueType: 'value',
                data     : {
                    priceId: priceId,
                    height : height,
                    width  : width
                }
            }];
        }
    }

    /**
     * Dolicza dopłatę za ral dowolny
     * @param  {number} price        Cena wejściowa
     * @param  {object} PriceElems   Wycena
     * @param  {object} NoPriceElems Elementy bez wyceny
     * @param  {object} colors       Kolory konstrukcji
     * @param  {object} height       Wysokosc kontrukcji
     * @param  {object} width        Szerokosc konstrukcji
     * @return {number}              Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'garageColor',
        data: {
            height: 'conf.Height',
            width : 'conf.Width',
            colors: 'conf.Colors'
        }
    })
    suppGarageColor({PriceStack, PriceElems, NoPriceCauses}: PriceElemsData, {colors, height, width}): PriceSegment[] {
        let supp = 0;
        let area = 0;
        const noPriceRals = [
            'RAL3007', 'RAL5004', 'RAL7021', 'RAL8022', 'RAL9004', 'RAL9005', 'RAL9011', 'RAL9017',
            'RAL4006', 'RAL4007', 'RAL9021'
        ];
        if (colors.outer) {
            if (colors.outer.group === 'RAL' && noPriceRals.indexOf(colors.outer.name.toUpperCase().replace(/\s/g, ''))) {
                supp = NaN;
                NoPriceCauses.push('imposible colors');
            } else if (colors.outer.group === 'RAL') {
                area = height * width / 1000000;
                supp = area * 110;
            }

            if (supp > 0) {
                PriceElems.colors.outer = {
                    price        : supp,
                    area         : area,
                    name         : colors.outer.name,
                    id           : colors.outer.id,
                };
            }
            return [{
                type     : 'colors',
                baseValue: supp,
                value    : supp,
                valueType: 'value',
                data     : {
                    area: area,
                    name: colors.outer.name,
                    id  : colors.outer.id,
                }
            }];
        } else {
            return [{
                type     : 'colors',
                baseValue: supp,
                value    : supp,
                valueType: 'value',
                data     : {
                    area: area,
                    name: null,
                    id  : null,
                }
            }];
        }
    }

    /**
     * Szukanie prowadzenia wg wymiarow
     * @param {object} prices    Dostępne cenniki
     * @param {object} panel     Przetłoczenie
     * @param {object} structure Struktura powierzchni
     * @param {string} colorType Grupa kolorow
     * @param {number} width     Szerokosc
     * @param {number} height    Wysokosc
     */
    setGuideByDimension(prices, panel, structure, colorType, width, height) {
        for (let i in prices) {
            if (prices[i].color === colorType
                && prices[i].garage_panel_id === panel.id
                && prices[i].garage_panel_structure_id === structure.id
                ) {
                for (let j = 0; j < prices[i].data.length; j++) {
                    if (~~prices[i].data[j].height_to === height
                        && ~~prices[i].data[j].width_to === width
                    ) {
                        return prices[i].guide;
                    }
                }
            }
        }
        return null;
    }

    /**
     * Dolicza dopłatę za naped/mechanizm otwierania
     * @param  {number} price        Cena wejściowa
     * @param  {object} PriceElems   Wycena
     * @param  {object} NoPriceElems Elementy bez wyceny
     * @param  {object} control      Mechanizm otwierania
     * @param  {number} height       Wysokosc konstrukcji
     * @return {number}              Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'garageControl',
        data     : {
            height : 'conf.Height',
            control: 'conf.Control'
        }
    })
    suppGarageControl({PriceStack, PriceElems, NoPriceCauses}: PriceElemsData, {control, height}): PriceSegment[] {
        const supp = control ? control.price * 1 : NaN;
        if (isNaN(supp)) {
            NoPriceCauses.push('no control for selected height');
        } else {
            PriceElems.control = {
                price        : supp,
                height       : height,
                name         : control.name,
                id           : control.id,
            };
        }
        if (control) {
            return [{
                type     : 'control',
                baseValue: supp,
                value    : supp,
                valueType: 'value',
                data     : {
                    height: height,
                    name  : control.name,
                    id    : control.id
                }
            }];
        } else {
            return [{
                type     : 'control',
                baseValue: supp,
                value    : supp,
                valueType: 'value',
                data     : {
                    height: height,
                    name  : null,
                    id    : null
                }
            }];
        }

    }

    /**
     * Zwraca cennik dla wybranej bramy garażowej.
     * @param  {object}  priceDepends  Zalezności między liniami, przetłoczeniami, strukturami, kolorami i cennikami.
     * @param  {array}   system        System
     * @param  {object}  colors        Kolory
     * @param  {object}  panel         Przetłoczenie panelu
     * @param  {object}  structure     Struktura powierzchni
     * @param  {object}  NoPriceCauses Powody braku ceny
     * @return {array}                 Cenniki
     */
    getPriceForGarage(priceDepends, system, colors, panel, structure, NoPriceCauses, width, height, guide, control) {
        const colorType = this.getGarageColorTypeForColors(colors);

        if (Common.isObject(priceDepends) && system && panel && structure && control && guide) {
            let key = system.id + '|' + colorType + '|' + panel.id + '|' + structure.id + '|';
            const matchedTail = [`|`, `|${control.id}`, `${guide.symbol}|`, `${guide.symbol}|${control.id}`].find(tail => (
                Common.isDefined(priceDepends[key + tail])
            ));
            return priceDepends[key + matchedTail];
        } else {
            NoPriceCauses.push('no prices for system');
        }
    }
}
