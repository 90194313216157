import { Injectable } from '@angular/core';
import PriceColorsService from '../price/price-colors.service';
import { core } from 'helpers';
import StateService from 'state.service';
import { CustomPricesService } from '../../common/price/custom-prices.service';
import { ProfileType } from 'configurations/parts/window';
import { Colors } from 'configurations/parts/common';
import { IccColors } from 'data-types';
import { CustomPrices, CustomPricesRecords } from 'price/CustomPrice';

@Injectable()
export class ProfilesPriceService {
    constructor(
        private priceColorsService: PriceColorsService,
        private stateService: StateService,
        private customPricesService: CustomPricesService
    ) {}

    getProfilePrice(
        profileId: number,
        type: string,
        system: { id: string | number; type: string } | null,
        colors: IccColors,
        prices: any[],
        customPrice?: CustomPricesRecords
    ) {
        if (
            !prices
            || !prices[profileId]
            || !((system && prices[profileId][system.id]) || prices[profileId].default)[type]
            || !colors
            || !colors.sash
            || ((!colors.sash.core || !colors.sash.core.groups)
                && system
                && system.type !== 'wood')
            || (system && !system.id)
        ) {
            return null;
        }

        if (!customPrice) {
            const offer = this.stateService.getCurrentOffer();
            const dealerId = offer ? offer.dealer_id : null;
            const customPrices = this.customPricesService.get(dealerId);
            customPrice = customPrices && customPrices.Profile ? customPrices.Profile : null;
        }
        const profilePrices = ((system && prices[profileId][system.id])
            || prices[profileId].default)[type];
        const colorSide = this.priceColorsService.getColorTypeForColors(colors, 1);
        let colorGroups = [];
        let colorGroupsOut = [];
        let side = 'double';
        switch (colorSide) {
            case 'White':
            case 'Cream':
                if (!colors.sash.core || !colors.sash.core.groups) {
                    return null;
                }
                colorGroups = colors.sash.core.groups.map(Number);
                break;
            case 'Outer':
                if (!colors.sash.outer || !colors.sash.outer.groups) {
                    return null;
                }
                colorGroups = colors.sash.outer.groups.map(Number);
                side = 'outer';
                break;
            case 'Inner':
                if (!colors.sash.inner || !colors.sash.inner.groups) {
                    return null;
                }
                colorGroups = colors.sash.inner.groups.map(Number);
                side = 'inner';
                break;
            case 'Bilateral':
                if (!colors.sash.inner || !colors.sash.inner.groups) {
                    return null;
                }
                colorGroups = colors.sash.inner.groups.map(Number);
                break;
            case 'Bicolor':
                if (
                    !colors.sash.inner
                    || !colors.sash.inner.groups
                    || !colors.sash.outer
                    || !colors.sash.outer.groups
                ) {
                    return null;
                }
                colorGroups = colors.sash.inner.groups.map(Number);
                colorGroupsOut = colors.sash.outer.groups.map(Number);
                side = 'bicolor';
                break;
        }
        const profilePrice = profilePrices
            .map((p, index) => {
                p.id = index;
                return p;
            })
            .filter(
                p =>
                    colorGroups.indexOf(Number(p.colorGroup)) > -1
                    && (side !== 'bicolor'
                        || (side === 'bicolor'
                            && colorGroupsOut.indexOf(Number(p.colorGroupOut)) > -1))
                    && (p.side === side
                        || (p.side === 'single' && (['outer', 'inner'] as any).includes(side)))
            )[0];

        if (profilePrice && customPrice && customPrice[profileId]) {
            const customPriceProfile = customPrice[profileId].fields
                .map(c => {
                    c.json = core.parseJson(c.json);
                    ['price_length', 'price_piece'].forEach(field => {
                        if (c.json[field] != null) {
                            c.json[field] = c.price;
                        }
                    });
                    return c;
                })
                .filter(
                    c =>
                        c.json.colorGroup === profilePrice.colorGroup
                        && c.json.colorGroupOut === profilePrice.colorGroupOut
                        && c.json.side === profilePrice.side
                        && c.json.profileType === type
                );
            const defaultCustomPrices = customPriceProfile.filter(
                c => c.field === 'default_prices' && c.relation === null
            );
            const systemCustomPrices = system
                ? customPriceProfile.filter(
                      c => c.field === 'WindowLine' && c.relation === Number(system.id)
                  )
                : [];
            defaultCustomPrices.forEach(c => {
                ['price_length', 'price_piece'].forEach(field => {
                    if (c.json[field] != null) {
                        profilePrice[field] = c.json[field];
                    }
                });
            });
            systemCustomPrices.forEach(c => {
                ['price_length', 'price_piece'].forEach(field => {
                    if (c.json[field] != null) {
                        profilePrice[field] = c.json[field];
                    }
                });
            });
        }

        if (profilePrice) {
            const pricesFields = [
                'price_piece',
                'price_length',
                'price_field',
                'price_percent',
                'price_sash',
                'price_two_sashes',
                'price_area',
            ];
            Object.keys(profilePrice).map(key =>
                pricesFields.includes(key) && profilePrice[key] != null
                    ? (profilePrice[key] = parseFloat(profilePrice[key]))
                    : null
            );

            return profilePrice;
        }
        return null;
    }

    getProfileAlushellPrice(profileId, type, hasAlushell, alushells, colors, prices, customPrice) {
        if (!alushells || alushells.length === 0) {
            return null;
        }
        if (!hasAlushell) {
            return {
                price_length: 0,
                price_piece: 0,
            };
        }
        const alushell = alushells[0];
        if (
            !prices
            || !prices[profileId]
            || !prices[profileId][alushell.id]
            || !prices[profileId][alushell.id][type]
            || !colors
            || !colors.sash
            || !colors.sash.alushell
            || !colors.sash.alushell.groups
            || !alushell
            || !alushell.id
        ) {
            return null;
        }
        const profilePrices = prices[profileId][alushell.id][type];
        let colorGroups = [];
        const colorGroupsOut = [];
        if (!colors.sash.alushell || !colors.sash.alushell.groups) {
            return null;
        }
        colorGroups = colors.sash.alushell.groups.map(Number);
        const side = 'outer';
        const profilePrice = profilePrices
            .map((p, index) => {
                p.id = index;
                return p;
            })
            .filter(p => colorGroups.indexOf(Number(p.colorGroup)) > -1 && p.side === side)[0];

        if (profilePrice && customPrice && customPrice[profileId]) {
            const customPriceProfile = customPrice[profileId].fields
                .map(c => {
                    c.json = core.parseJson(c.json);
                    ['price_length', 'price_piece'].forEach(field => {
                        if (c.json[field] != null) {
                            c.json[field] = c.price;
                        }
                    });
                    return c;
                })
                .filter(
                    c =>
                        c.json.colorGroup === profilePrice.colorGroup
                        && c.json.colorGroupOut === profilePrice.colorGroupOut
                        && c.json.side === profilePrice.side
                        && c.json.profileType === type
                );
            const alushellCustomPrices = customPriceProfile.filter(
                c => c.field === 'Alushell' && c.relation === Number(alushell.id)
            );
            alushellCustomPrices.forEach(c => {
                ['price_length', 'price_piece'].forEach(field => {
                    if (c.json[field] != null) {
                        profilePrice[field] = c.json[field];
                    }
                });
            });
        }

        if (profilePrice) {
            const pricesFields = [
                'price_piece',
                'price_length',
                'price_field',
                'price_percent',
                'price_sash',
                'price_two_sashes',
            ];
            Object.keys(profilePrice).map(key =>
                pricesFields.includes(key)
                    ? (profilePrice[key] = parseFloat(profilePrice[key]) || 0)
                    : null
            );

            return profilePrice;
        }
        return null;
    }

    getThresholdPrice(profileId, type, system, prices, customPrice) {
        if (
            !prices[profileId]
            || !prices[profileId][system.id]
            || !prices[profileId][system.id][type]
            || !system
            || !system.id
        ) {
            return null;
        }
        const profilePrice = prices[profileId][system.id][type][0];

        if (profilePrice && customPrice && customPrice[profileId]) {
            const customPriceProfile = customPrice[profileId].fields
                .map(c => {
                    c.json = core.parseJson(c.json);
                    ['price_length', 'price_piece'].forEach(field => {
                        if (c.json[field] != null) {
                            c.json[field] = c.price;
                        }
                    });
                    return c;
                })
                .filter(c => c.json.side === profilePrice.side && c.json.profileType === type);
            const defaultCustomPrices = customPriceProfile.filter(
                c => c.field === 'default_prices' && c.relation === null
            );
            const systemCustomPrices = customPriceProfile.filter(
                c => c.field === 'WindowLine' && c.relation === Number(system.id)
            );
            defaultCustomPrices.forEach(c => {
                ['price_length', 'price_piece'].forEach(field => {
                    if (c.json[field] != null) {
                        profilePrice[field] = c.json[field];
                    }
                });
            });
            systemCustomPrices.forEach(c => {
                ['price_length', 'price_piece'].forEach(field => {
                    if (c.json[field] != null) {
                        profilePrice[field] = c.json[field];
                    }
                });
            });
        }

        if (profilePrice) {
            const pricesFields = [
                'price_piece',
                'price_length',
                'price_field',
                'price_percent',
                'price_sash',
                'price_two_sashes',
            ];
            Object.keys(profilePrice).map(key =>
                pricesFields.includes(key)
                    ? (profilePrice[key] = parseFloat(profilePrice[key]) || 0)
                    : null
            );

            return profilePrice;
        }
        return null;
    }

    getRollerGuidePrice(profileId) {
        return null;
    }
}
