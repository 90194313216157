import angular from 'angular';

/**
 * Fabryka typów
 * @param {object} $rootScope               Angular root scope
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} StepFactory              Fabryka kroków
 * @param {object} ConfiguratorsDataService Fabryka danych konfiguracji
 * @param {object} DrivesFactory            Fabryka napedow
 * @param {object} PriceService             Liczenie cen
 * @param {object} CurConfService           Bieżaca konfiguracja
 * @param {object} RollerColorsFactory      Fabryka kolorów rolet
 * @param {object} RollerDimensionsFactory  Fabryka rozmiarów rolet
 * @param {obejct} SchemasFactory           Fabryka schematów
 */
export default function TypesFactory($rootScope, AccessoriesFactory, ConfigurationsService, StepFactory, ConfiguratorsDataService, DrivesFactory,
    PriceService, CurConfService, RollerColorsFactory, RollerDimensionsFactory, SchemasFactory, Core, EventBusService, IccConfig) {
    'ngInject';

    var factory = {
        types     : [],
        loadedData: false,

        selectRollerType: selectRollerType
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });

    return factory;

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        if (CurConfService.conf != 'window' && CurConfService.conf != 'roller_shutter' && CurConfService.conf != 'hs') {
            return;
        }
        var defaultType;
        factory.types = ConfiguratorsDataService.data.shutterLines || [];
        if ((!angular.isObject(ConfigurationsService.conf.Current.RollerShutter.type)
                || angular.isUndefined(ConfigurationsService.conf.Current.RollerShutter.type.type)
                || angular.isUndefined(ConfigurationsService.conf.Current.RollerShutter.type.static)
                || angular.isUndefined(ConfigurationsService.conf.Current.RollerShutter.type.id))
            && factory.types.length > 0
        ) {
            defaultType = factory.types.filter(function filterTypes(e) {
                return e.static == 'nadstawna';
            })[0] || factory.types[0];
            selectRollerType(defaultType);
        }
        factory.loadedData = true;
    }

    /**
     * Funkcja wyboru typu rolety
     * @param  {object} object   Obiekt
     * @param  {object} nextStep Nastepny krok
     */
    function selectRollerType(object, nextStep) {
        var type;
        if (object.static == 'podtynkowa') {
            type = 'I';
        } else if (object.static == 'natynkowa') {
            type = 'Z';
        } else {
            type = 'N';
        }

        ConfigurationsService.conf.Current.RollerShutter.type = {
            type       : type,
            id         : object.id,
            static     : object.static,
            name       : object.name,
            title_image: object.title_image
        };
        ConfigurationsService.conf.Default.RollerShutter.type = Core.copy(ConfigurationsService.conf.Current.RollerShutter.type);

        SchemasFactory.setDefaultSystem();
        RollerDimensionsFactory.setBoundaryDimensionsFromPrices();
        DrivesFactory.loadRetractor();
        if (
            ConfigurationsService.conf.Current.System
            && ConfigurationsService.conf.Current.System.id
            && (nextStep || IccConfig.Configurators.selectSystemAutoNextStep)
            && StepFactory.getStepByCode('system') === StepFactory.getStep()
        ) {
            StepFactory.goToNextStep(true);
        }
        PriceService.count();
        AccessoriesFactory.findAccessories();
    }

}
