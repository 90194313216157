import {TimeLimit, TimeLimitSegment} from './time_limit_functions';

export default class TimeLimitGarageControlService {

    /**
     * Pobiera termin realizacji sterowania bramy.
     *
     * @param {object} garageControl   Sterowanie bramy
     * @return {number} Termin realizacji sterowania bramy.
     */
    @TimeLimit({
        shortName: 'garageControl',
        data: {
            garageControl : 'conf.Control',
        }
    })
    garageControlTimeLimit({timeLimits, garageControl}): TimeLimitSegment[] {
        if (garageControl == null || !garageControl.id || !garageControl.time_limit_id) {
            return null;
        }

        const timeLimitId = garageControl.time_limit_id;

        return <TimeLimitSegment[]>[{
            type: 'garageControl',
            value: timeLimits[timeLimitId],
            data: {
                id  : garageControl.id,
                name: garageControl.name,
            }
        }];
    }
}
