import angular from 'angular';

/**
 * Fabryka typow bram garazowych
 * @param {object} $rootScope               Angular Root Scope
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} CurConfService           Bieżaca konfiguracja
 * @param {object} ConfiguratorsDataService Fabryka danych konfiguracjia
 * @param {object} MosquitoMontagesFactory  Fabryka montażu siatek
 * @param {object} PriceService             Liczenie cen
 */
export default function TypesFactory($rootScope, ConfigurationsService, CurConfService, ConfiguratorsDataService, MosquitoMontagesFactory,
    PriceService, EventBusService, StepFactory, MosquitoColorsFactory
) {
    'ngInject';

    var factory = {
        types           : [],
        loadedData      : false,
        getTypes        : getTypes,
        selectType      : selectType,
        setDefaultValues: setDefaultValues
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });

    return factory;

    /**
     * Funkcja wywołana po zaladowaniu konfiguracji
     */
    function init() {
        if (CurConfService.conf == 'mosquito') {
            factory.types = getTypes(ConfigurationsService.conf.Current);
            if (angular.isUndefined(ConfigurationsService.conf.Current.Type.id)) {
                factory.setDefaultValues();
            }
            factory.loadedData = true;
        } else {
            return;
        }
    }

    /**
     * Pobranie typeow garazowych
     */
    function getTypes(conf = ConfigurationsService.conf.Current) {
        if (conf && conf.System && conf.System.id && ConfiguratorsDataService.data.mosquitoTypes[conf.System.id]) {
            return ConfiguratorsDataService.data.mosquitoTypes[conf.System.id];
        } else {
            return [];
        }
    }

    /**
     * Wybor typeu
     * @param  {object}  type     Wybrany type
     * @param  {boolean} price    Czy przeliczyc ceny
     */
    function selectType(type, price) {
        if (CurConfService.conf != 'mosquito') {
            return;
        }
        ConfigurationsService.conf.Current.Type = type;
        ConfigurationsService.conf.Default.Type = type;

        if (type.has_direction && ConfigurationsService.conf.Current.Direction === null) {
            ConfigurationsService.conf.Current.Direction = 'left';
        } else if (!type.has_direction) {
            ConfigurationsService.conf.Current.Direction = null;
        }
        if (price) {
            PriceService.count();
        }
        MosquitoMontagesFactory.setDefaultValues();
        MosquitoColorsFactory.loadColorsBySystem(ConfigurationsService.conf.Current.System.id, type.id);

        if (ConfiguratorsDataService.data.mosquitoWindowLines[ConfigurationsService.conf.Current.System.id]
            || ConfigurationsService.conf.Current.Type
                && ConfigurationsService.conf.Current.Type.id
                && (ConfiguratorsDataService.data.mosquitoMontages.some(el => el.types.indexOf(ConfigurationsService.conf.Current.Type.id) > -1)
                    || ConfigurationsService.conf.Current.Type.profile_position
                    || ConfigurationsService.conf.Current.Type.muntin_position)
        ) {
            StepFactory.enable('montages');
        } else {
            StepFactory.disable('montages');
        }
    }

    /**
     * Ustawienie domyslnych wartosci po uruchomieniu
     */
    function setDefaultValues() {
        factory.types = getTypes();
        if (angular.isDefined(factory.types[0])) {
            selectType(factory.types[0]);
        } else {
            selectType({});
        }
    }

}
