import angular from 'angular';

/**
 * Fabryka współdzielonych obliczeń dotyczących rysunku.
 * Funkcje do obliczeń punktów po przecięciu się figur, punktu położenia na figurze, operacje na wektorach,
 * zaokrąglania, sortowania punktów zgodnie i przeciwnie z ruchem wskazówek zegara, wyszukiwanie
 * centroidów, punktów grawitacji figury i inne.
 * Dokładny opis w poszczególnych funkcjach.
 *
 * @param {$rootScope} rootScope rootScope
 * @param {IccConfig}  IccConfig Konfiguracyja.
 * @param {Core}       Core      Core
 *
 * @returns {Object} Lista dostępnych funkcji.
 */
export default function IccDrawingMathFactory($rootScope, IccConfig, Core) {
    'ngInject';

    let unit = null;

    /**
     * Pobranie wymiaru w przypadku gdy go nie ma
     * @return {[type]} [description]
     */
    function getUnit() {
        if (unit === null) {
            if (
                angular.isDefined($rootScope.user)
                && angular.isDefined($rootScope.user.dimension_unit)
            ) {
                unit = {
                    type: $rootScope.user.dimension_unit === 'mm' ? 0 : 1,
                    unit: $rootScope.user.dimension_unit,
                    separator: $rootScope.user.dimension_unit === 'mm' ? ',' : '.',
                };
            } else {
                return {
                    type: 0,
                    unit: 'mm',
                    separator: ',',
                };
            }
        }
        return unit;
    }

    return {
        round: roundValue,
        milimetersToDim,
        dimToMilimeters,
        milimetersToDimNoFormat,
    };

    /**
     * Zaokrąglenie liczby o podaną ilość miejsc po przecinku.
     * @param  {double} val Liczba do zaokrąglenia.
     * @return {double}     Zaokrąglona wartość.
     */
    function roundValue(val) {
        return ~~(val + 0.5);
    }

    /**
     * Milimetry na wymiary podane na rynku.
     * @param  {numeric} data Wymiary w milimetrach.
     * @return {numeric}      Wymiary rynkowe.
     */
    function milimetersToDim(data) {
        const unitObj = getUnit();
        if (unitObj.type === 0) {
            return data;
        } else {
            return Core.formatNumber(
                data / IccConfig.Configurators.inchToMilimeter,
                2,
                100,
                ' ',
                unitObj.separator
            );
        }
    }

    /**
     * Wymiary rynku na milimetry.
     * @param  {numeric} data Wymiary rynkowe.
     * @return {numeric}      Wymiary w milimetrach.
     */
    function dimToMilimeters(data) {
        const unitObj = getUnit();
        if (unitObj.type === 0) {
            return data;
        } else {
            return roundValue(data * IccConfig.Configurators.inchToMilimeter);
        }
    }

    /**
     * Milimetry na wymiary podane na rynku - bez formatowania, wysypywało.
     * @param  {numeric} data Wymiary w milimetrach.
     * @return {numeric}      Wymiary rynkowe.
     */
    function milimetersToDimNoFormat(data) {
        const unitObj = getUnit();
        if (unitObj.type === 0) {
            return data;
        } else {
            return roundValue((data / IccConfig.Configurators.inchToMilimeter) * 100) / 100;
        }
    }
}
