import angular from 'angular';
import $ from 'jquery';

/**
 * Kontroler okna modal klamek
 * @param {object} $scope                Angular Scope
 * @param {object} $uibModalInstance     Okno modal
 * @param {object} $timeout              Timeout
 * @param {object} hinges                Zawiasy
 * @param {object} selHinge              Ustawianie zawiasów
 * @param {object} ConfigurationsService Fabryka konfiguracji
 * @param {object} hingeColors           Kolory zawiasów
 * @param {object} selHingeColor         Ustawianie koloru zawiasów
 */
export default function ModalHingeCtrl($scope, $uibModalInstance, $timeout, hinges, selHinge, ConfigurationsService, ColorsMappingService, hingeColors, selHingeColor, EventBusService, b2c, ScrollbarService) {
    'ngInject';

    var vm = this;
    vm.config = 'door';
    if (angular.isDefined(ConfigurationsService.conf)) {
        vm.config = ConfigurationsService.conf.Current.conf;
    }
    vm.b2c = b2c;
    vm.hinges        = hinges;
    vm.selHinge      = selHinge;
    vm.colors        = hingeColors;
    vm.selHingeColor = selHingeColor.id;
    let defaultHingeColor = selHingeColor.default;

    /**
     * Funkcja ustawiania zawiasów
     * @param  {object} hinge zawias
     */
    vm.selectHinge = function(hinge) {
        vm.selHinge = hinge;
        if (hinge.colors_ids.indexOf(vm.selHingeColor) == -1 || defaultHingeColor) {
            const windowColorId = ColorsMappingService.getWindowColorId(ConfigurationsService.conf.Current, 'inner');
            const windowColorRal = ColorsMappingService.getWindowColorId(ConfigurationsService.conf.Current, 'inner', 'RAL');
            const matchedColors = ColorsMappingService.getColors(windowColorId, windowColorRal ? 'ral' : 'window', 'accessory');
            const colors = matchedColors.map(m => hinge.colors_ids.filter(c => c == m)[0]).filter(m => m);
            if (colors.length) {
                vm.selHingeColor = colors[0];
            } else {
                vm.selHingeColor = hinge.colors_ids[0];
            }
            defaultHingeColor = true;
        }
    };

    /**
     * Funkcja ustawiania koloru zawiasów
     * @param  {object} hingeColor Kolor zawiasów
     */
    vm.selectHingeColor = function(hingeColor) {
        vm.selHingeColor = hingeColor.id;
        defaultHingeColor = false;
    };

    /**
     * Funkcja zamykania okna modal
     */
    vm.close = () => {
        $uibModalInstance.close({
            hinge       : vm.selHinge,
            color       : vm.selHingeColor,
            defaultColor: defaultHingeColor
        });
    };

    /**
     * Funkcja odświeżajaca
     */
    vm.refresh = function() {
        ScrollbarService.update();
    };
}
