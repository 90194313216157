import angular from 'angular';

/**
 * Funkcja ornamentu szyby
 */
export default function GlassOrnamentFilter() {
    'ngInject';

    return function(glass, ornament) {
        var glassArr = [];
        angular.forEach(glass, function(obj) {
            glassArr.push(obj);
        });
        if (angular.isUndefined(ornament) || ornament === null || ornament === '') {
            return glassArr;
        } else {
            return glassArr.filter(function(gl) {
                return !!~~gl.ornament === !!~~ornament;
            });
        }
    };
}
