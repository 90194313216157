import { Injectable } from '@angular/core';
import { IssuesService, IssueLevel } from 'issues.service';
import { TranslateService } from 'translate.service';
import { Frame, Coupling } from 'configurations/parts/window';
import { ActiveSash } from './active-sash';
import { ActiveMullion } from './active-mullion';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import Common from 'Common';

@Injectable()
export class ConstructionCodeService {
    constructor(private issuesService: IssuesService, private translateService: TranslateService) {}

    getConstructionCode({ width, height, sashes, dividers, frames, couplings, type, conf }) {
        this.issuesService.unregister('incorrect-construction-code', conf);
        const decodedCode = this.decodeConstructionCode(
            this.generateConstructionCode(
                { x: 0, y: 0, width, height },
                { sashes, dividers, type, conf, frames, couplings }
            )
        );
        if (decodedCode && decodedCode.sashMapping.length !== sashes.length) {
            this.issuesService.simpleRegister(
                'incorrect-construction-code',
                'Błąd konstrukcji. Wybierz inną konstrukcję aby rozwiązać problem.',
                this.translateService.instant(
                    'WINDOW|Błąd konstrukcji. Wybierz inną konstrukcję aby rozwiązać problem.'
                ),
                conf,
                {
                    level: IssueLevel.FATAL,
                    logLevel: IssueLevel.ERROR
                }
            );
            return;
        }
        return decodedCode;
    }

    /**
     * Generuje kod konstrukcji
     * @param  {Object} scanRect    scanRect
     * @param  {Object} conf        Konfiguracja
     * @param  {Object} level       Poziom
     * @return {Object}             Kod konstrukcji
     */
    generateConstructionCode(
        scanRect,
        {
            frames,
            couplings,
            sashes,
            dividers,
            type,
            conf,
        }: {
            frames: Frame[];
            couplings: Coupling[];
            sashes: ActiveSash[];
            dividers: ActiveMullion[];
            type: string;
            conf: WindowActiveConfiguration;
        },
        level = 0
    ) {
        let constructionCode = '';
        const splitPoints: {
            position: number;
            start: boolean;
        }[] = [];
        let fullDividerDirection: 'vertical' | 'horizontal' = 'vertical';
        let isCoupling = false;
        let steps: number[][];
        let i = 0;
        if (Common.isUndefined(level)) {
            level = 0;
        }
        if (sashes.length === 0) {
            return;
        }
        if (type === 'hs') {
            for (i = 0; i < sashes.length; i++) {
                constructionCode = constructionCode + '#' + i + '@[' + sashes[i].type.type + ']';
                if (i < sashes.length - 1) {
                    constructionCode += this.getDividerSign('vertical');
                }
            }
        } else {
            i = 0;
            let scanRegions = [];
            if (couplings && couplings.length) {
                for (const coupling of couplings) {
                    if (coupling.direction === 'horizontal' && coupling.length === scanRect.width) {
                        const couplingPosition = coupling.framesId
                            .map(fId => frames.find(f => f.id === fId.id))
                            .reduce(
                                (prev, cur) =>
                                    cur.y + cur.height > prev ? cur.y + cur.height : prev,
                                0
                            );
                        fullDividerDirection = 'horizontal';
                        isCoupling = true;
                        splitPoints.push({
                            position: couplingPosition,
                            start: false,
                        });
                        splitPoints.push({
                            position: couplingPosition + coupling.width,
                            start: true,
                        });
                    }
                    if (coupling.direction === 'vertical' && coupling.length === scanRect.height) {
                        const couplingPosition = coupling.framesId
                            .map(fId => frames.find(f => f.id === fId.id))
                            .reduce(
                                (prev, cur) =>
                                    cur.x + cur.width > prev ? cur.x + cur.width : prev,
                                0
                            );
                        fullDividerDirection = 'vertical';
                        isCoupling = true;
                        splitPoints.push({
                            position: couplingPosition,
                            start: false,
                        });
                        splitPoints.push({
                            position: couplingPosition + coupling.width,
                            start: true,
                        });
                    }
                }
            } else {
                for (const divider of dividers) {
                    const frame = frames.find(f => f.id === divider.frameId);
                    if (divider.rWidth === scanRect.width) {
                        if (
                            frame.x + divider.rx >= scanRect.x
                            && divider.rWidth + frame.x + divider.rx <= scanRect.x + scanRect.width
                            && frame.y + divider.ry > scanRect.y
                            && divider.rHeight + frame.y + divider.ry
                                <= scanRect.y + scanRect.height
                        ) {
                            fullDividerDirection = 'horizontal';
                            splitPoints.push({
                                position: frame.y + divider.ry,
                                start: false,
                            });
                        }
                    }
                    if (divider.rHeight === scanRect.height) {
                        if (
                            frame.x + divider.rx > scanRect.x
                            && divider.rWidth + frame.x + divider.rx <= scanRect.x + scanRect.width
                            && frame.y + divider.ry >= scanRect.y
                            && divider.rHeight + frame.y + divider.ry
                                <= scanRect.y + scanRect.height
                        ) {
                            fullDividerDirection = 'vertical';
                            splitPoints.push({
                                position: frame.x + divider.rx,
                                start: false,
                            });
                        }
                    }
                }
            }
            splitPoints.sort((a, b) => a.position - b.position);
            if (fullDividerDirection === 'horizontal') {
                steps = [[scanRect.y]];
                let index = 0;
                for (const position of splitPoints) {
                    if (position.start) {
                        index++;
                        steps.push([]);
                    }
                    steps[index].push(position.position);
                }
                steps[index].push(scanRect.height + scanRect.y);

                scanRegions = [];
                for (const step of steps) {
                    step.sort((a, b) => a - b);
                    for (i = 0; i < step.length - 1; i++) {
                        scanRegions.push({
                            x: scanRect.x,
                            y: step[i],
                            width: scanRect.width,
                            height: step[i + 1] - step[i],
                        });
                    }
                }
            } else {
                // verical
                steps = [[scanRect.x]];
                let index = 0;
                for (const position of splitPoints) {
                    if (position.start) {
                        index++;
                        steps.push([]);
                    }
                    steps[index].push(position.position);
                }
                steps[index].push(scanRect.width + scanRect.x);
                scanRegions = [];

                for (const step of steps) {
                    step.sort((a, b) => a - b);
                    for (i = 0; i < step.length - 1; i++) {
                        scanRegions.push({
                            x: step[i],
                            y: scanRect.y,
                            height: scanRect.height,
                            width: step[i + 1] - step[i],
                        });
                    }
                }
            }

            for (i = 0; i < scanRegions.length; i++) {
                let sashCount = 0;
                let currentSash = null;
                let currentSashIndex = -1;

                for (let j = 0; j < sashes.length; j++) {
                    const frame = frames.find(f => f.id === sashes[j].frameId);
                    if (
                        frame.x + sashes[j].rx >= scanRegions[i].x
                        && frame.y + sashes[j].ry >= scanRegions[i].y
                        && sashes[j].rx + frame.x + sashes[j].rWidth
                            <= scanRegions[i].x + scanRegions[i].width
                        && frame.y + sashes[j].ry + sashes[j].rHeight
                            <= scanRegions[i].y + scanRegions[i].height
                    ) {
                        sashCount++;
                        currentSash = sashes[j];
                        currentSashIndex = j;
                    }
                }
                const dividerSign = this.getDividerSign(fullDividerDirection, isCoupling);
                if (sashCount === 1) {
                    constructionCode += dividerSign;
                    constructionCode =
                        constructionCode
                        + '#'
                        + currentSashIndex
                        + '@['
                        + currentSash.type.type
                        + ']';
                } else if (sashCount > 1) {
                    constructionCode =
                        constructionCode
                        + dividerSign
                        + '('
                        + this.generateConstructionCode(
                            scanRegions[i],
                            { frames, couplings: [], sashes, dividers, type, conf },
                            level + 1
                        )
                        + ')';
                } else {
                    this.issuesService.simpleRegister(
                        'incorrect-construction-code',
                        'Błąd konstrukcji. Wybierz inną konstrukcję aby rozwiązać problem.',
                        this.translateService.instant(
                            'WINDOW|Błąd konstrukcji. Wybierz inną konstrukcję aby rozwiązać problem.'
                        ),
                        conf,
                        {
                            level: IssueLevel.FATAL,
                            logLevel: IssueLevel.ERROR,
                        }
                    );
                }
            }

            if (level === 0) {
                constructionCode = constructionCode.substring(1);
            }
        }
        for (let k = 0; k < 10; k++) {
            constructionCode = constructionCode
                .replace('(_', '(')
                .replace('(|', '(')
                .replace('(U', '(')
                .replace('(=', '(');
        }

        return constructionCode;
    }

    /**
     * Dekoduje kod konstrukcji
     * @param  {Object} constructionCode    Kod konstrukcji
     * @return {Object}                     Dekodowany kod
     */
    decodeConstructionCode(constructionCode) {
        let retarder = 0;
        const sashesIds = [];
        let i = 0;
        let index;
        let sashId;
        if (Common.isString(constructionCode)) {
            for (i = 0; i < constructionCode.length; i++) {
                if (constructionCode[i] === '@') {
                    if (constructionCode[i - 2] === '#') {
                        sashId = constructionCode[i - 1];
                    } else {
                        sashId = constructionCode[i - 2] + constructionCode[i - 1];
                    }
                    retarder = retarder + 3;
                    index = i - retarder + 1;
                    // sashesIds[(i-retarder+1)] = constructionCode[i-1];
                    sashesIds.push({
                        index,
                        sashId,
                    });
                }
            }
            const split = constructionCode.split('@');
            let out = '';
            for (i = 0; i < split.length; i++) {
                const split2 = split[i].split('#');
                out = out + split2[0];
            }
            return { code: out, sashMapping: sashesIds };
        }
    }

    private getDividerSign(orientation: 'vertical' | 'horizontal', isCoupling = false) {
        switch (`${orientation}_${isCoupling ? 'coupling' : 'mullion'}`) {
            case 'vertical_mullion':
                return '|';
            case 'horizontal_mullion':
                return '_';
            case 'vertical_coupling':
                return '+';
            case 'horizontal_coupling':
                return '=';
        }
    }
}
