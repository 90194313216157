import WindowConfiguration from './WindowConfiguration';
import HSActiveConfiguration from './HSActiveConfiguration';
import { core } from '../helpers';

export default class HSConfiguration extends WindowConfiguration {
    public static is(configuration): configuration is HSConfiguration {
        return (
            configuration instanceof HSConfiguration
            || (configuration.$version && !configuration.active)
        );
    }
    type: 'hs' | 'folding_door' = 'hs';

    height = 2000;
    width = 2000;

    sashesType: 'Fix' | 'FixSash' | 'Func' | 'Mix' = 'Func';

    constructor(configuration, dataRequiredToUpdate?: any, conversion = true) {
        super(configuration, dataRequiredToUpdate, false);

        if (HSConfiguration.is(configuration) && conversion) {
            this.runConversion(configuration, dataRequiredToUpdate);
        } else if (HSActiveConfiguration.is(configuration)) {
            this.height = configuration.Height;
            this.sashesType = configuration.SashesType;
            this.width = configuration.Width;
        }
    }
}
