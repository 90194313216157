import TranslateService from './translations/TranslateService';

export default class Completion {
    constructor(groups, translatedData = null) {
        const confTypes = ['door', 'folding_door', 'hs', 'window'];

        const confSegments = [
            'accessories',
            'alignments',
            'colors',
            'couplings',
            'dividers',
            'extensions',
            'fittings',
            'frames',
            'glazings',
            'glazingBeads',
            'handles',
            'hinges',
            'locks',
            'muntins',
            'sashes',
            'sashFrames',
            'seals',
            'steels',
            'warmEdges',
        ];

        let functionName;

        if (groups && groups.length) {
            for (const group of groups) {
                if (group && group.rows && group.rows.length) {
                    for (let row of group.rows) {
                        row = row.doc || row;

                        if (
                            row.details
                            && row.details.type
                            && confTypes.some(e => e === row.details.type)
                        ) {
                            this[row.details.type] = this[row.details.type] || {};

                            for (const segmentName of confSegments) {
                                functionName = `get${segmentName
                                    .charAt(0)
                                    .toUpperCase()}${segmentName.slice(1)}`;

                                if (functionName && typeof this[functionName] === 'function') {
                                    this[row.details.type][segmentName] =
                                        this[row.details.type][segmentName] || [];

                                    this[functionName](
                                        row,
                                        this[row.details.type][segmentName],
                                        translatedData
                                    );
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    getAccessories(doc, segments, translatedData) {
        const accessories = [];

        // akcesoria przypinane do okna
        accessories.push(...doc.details.accessories);

        // akcesoria przypinane do boków
        Object.keys(doc.details.sideAccessories).map(side =>
            accessories.push(...doc.details.sideAccessories[side])
        );

        // akcesoria przypisane do skrzydeł
        doc.details.sashes.map(sash => accessories.push(...sash.hardware));

        // wszystkie akcesoria
        for (const accessory of accessories) {
            const segment = segments.find(o => o.id === accessory.id);

            if (segment) {
                segment.count += accessory.count * doc.quantity;
                segment.mb += (accessory.amount / 1e3) * doc.quantity;
                segment.area += ((accessory.height * accessory.amount) / 1e6) * doc.quantity;
            } else {
                segments.push({
                    id: accessory.id,
                    doc: {
                        ...accessory,
                        name: TranslateService.translate(
                            translatedData,
                            accessory,
                            'WindowAccessory',
                            'name'
                        ),
                    },
                    count: accessory.count * doc.quantity,
                    mb: (accessory.amount / 1e3) * doc.quantity,
                    area: ((accessory.height * accessory.amount) / 1e6) * doc.quantity,
                    price: 0,
                    total: 0,
                });
            }
        }

        for (const segment of segments) {
            segment.total += this.priceFromSegments(doc, 'accessories', { id: segment.id });
            segment.total += this.priceFromSegments(doc, 'sideAccessories', { id: segment.id });
            segment.total += this.priceFromSegments(doc, 'sashesHardware', { id: segment.id });
        }
    }

    getAlignments(doc, segments, translatedData) {
        if (doc.details.alignments == null) {
            return;
        }
        const alignments = [];

        // wyrównania w ramie
        alignments.push(...doc.details.alignments);

        // wyrównania w skrzydle
        doc.details.sashes.map(sash => alignments.push(...sash.intAlignments));

        // wszystkie wyrównania
        for (const alignment of alignments) {
            if (alignment.profileId) {
                const profile = doc.details.usedProfiles.find(o => o.id === alignment.profileId);
                const segment = segments.find(o => o.id === alignment.profileId);

                if (segment) {
                    segment.count += doc.quantity;
                } else {
                    segments.push({
                        id: alignment.profileId,
                        doc: {
                            ...profile,
                            name: TranslateService.translate(
                                translatedData,
                                profile,
                                'Profile',
                                'name'
                            ),
                        },
                        count: doc.quantity,
                        price: 0,
                        total: 0,
                    });
                }
            }
        }

        for (const segment of segments) {
            segment.total += this.priceFromSegments(doc, 'alignment', { profileId: segment.id });
        }
    }

    getColors(doc, segments, translatedData) {
        const colSh = {
            bilateral: -1,
            frameAlushell: -1,
            frameInner: -1,
            frameOuter: -1,
            frameCore: -1,
            sashAlushell: -1,
            sashInner: -1,
            sashOuter: -1,
            sashCore: -1,
        };

        const colF = doc.details.colors.frame;
        const colS = doc.details.colors.sash;

        if (colF.alushell && colF.alushell.id && doc.details.hasAlushell) {
            colSh.frameAlushell = colF.alushell.id;
        }
        if (
            colF.outer
            && colF.outer.id
            && doc.details.colorType === 'Bilateral'
            && !doc.details.colorsSashExt
        ) {
            colSh.bilateral = colF.outer.id;
        } else {
            if (colF.inner && colF.inner.id) {
                colSh.frameInner = colF.inner.id;
            }
            if (colF.outer && colF.outer.id) {
                colSh.frameOuter = colF.outer.id;
            }
        }
        if (colF.core && colF.core.id) {
            colSh.frameCore = colF.core.id;
        }

        if (doc.details.colorsSashExt) {
            if (colS.alushell && colS.alushell.id && doc.details.hasAlushell) {
                colSh.sashAlushell = colS.alushell.id;
            }
            if (colS.inner && colS.inner.id) {
                colSh.sashInner = colS.inner.id;
            }
            if (colS.outer && colS.outer.id) {
                colSh.sashOuter = colS.outer.id;
            }
            if (colS.core && colS.core.id) {
                colSh.sashCore = colS.core.id;
            }
        }

        const segment = segments.find(
            o =>
                colSh.bilateral === o.bilateral.id
                && colSh.frameAlushell === o.frameAlushell.id
                && colSh.frameInner === o.frameInner.id
                && colSh.frameOuter === o.frameOuter.id
                && colSh.frameCore === o.frameCore.id
                && colSh.sashAlushell === o.sashAlushell.id
                && colSh.sashInner === o.sashInner.id
                && colSh.sashOuter === o.sashOuter.id
                && colSh.sashCore === o.sashCore.id
        );

        if (segment) {
            segment.count += doc.quantity;
            segment.total +=
                this.priceFromSegments(doc, 'sashes') + this.priceFromSegments(doc, 'shape');
        } else {
            segments.push({
                bilateral: {
                    id: colSh.bilateral,
                    doc: {
                        ...colF.outer,
                        name: TranslateService.translate(
                            translatedData,
                            colF.outer,
                            'WindowColor',
                            'name'
                        ),
                    },
                },
                frameAlushell: {
                    id: colSh.frameAlushell,
                    doc: {
                        ...colF.alushell,
                        name: TranslateService.translate(
                            translatedData,
                            colF.alushell,
                            'WindowColor',
                            'name'
                        ),
                    },
                },
                frameOuter: {
                    id: colSh.frameOuter,
                    doc: {
                        ...colF.outer,
                        name: TranslateService.translate(
                            translatedData,
                            colF.outer,
                            'WindowColor',
                            'name'
                        ),
                    },
                },
                frameInner: {
                    id: colSh.frameInner,
                    doc: {
                        ...colF.inner,
                        name: TranslateService.translate(
                            translatedData,
                            colF.inner,
                            'WindowColor',
                            'name'
                        ),
                    },
                },
                frameCore: {
                    id: colSh.frameCore,
                    doc: {
                        ...colF.core,
                        name: TranslateService.translate(
                            translatedData,
                            colF.core,
                            'WindowColor',
                            'name'
                        ),
                    },
                },
                sashAlushell: {
                    id: colSh.sashAlushell,
                    doc: {
                        ...colS.alushell,
                        name: TranslateService.translate(
                            translatedData,
                            colS.alushell,
                            'WindowColor',
                            'name'
                        ),
                    },
                },
                sashOuter: {
                    id: colSh.sashOuter,
                    doc: {
                        ...colS.outer,
                        name: TranslateService.translate(
                            translatedData,
                            colS.outer,
                            'WindowColor',
                            'name'
                        ),
                    },
                },
                sashInner: {
                    id: colSh.sashInner,
                    doc: {
                        ...colS.inner,
                        name: TranslateService.translate(
                            translatedData,
                            colS.inner,
                            'WindowColor',
                            'name'
                        ),
                    },
                },
                sashCore: {
                    id: colSh.sashCore,
                    doc: {
                        ...colS.core,
                        name: TranslateService.translate(
                            translatedData,
                            colS.core,
                            'WindowColor',
                            'name'
                        ),
                    },
                },
                count: doc.quantity,
                price: 0,
                total: this.priceFromSegments(doc, 'sashes') + this.priceFromSegments(doc, 'shape'),
            });
        }
    }

    getDividers(doc, segments, translatedData) {
        const mullions = [];

        // słupki w ramie
        mullions.push(...doc.details.mullions);

        // słupki w skrzydle
        doc.details.sashes.map(sash => mullions.push(...sash.intMullions));

        // wszystkie słupki
        for (const mullion of mullions) {
            if (mullion.profileId) {
                const profile = doc.details.usedProfiles.find(o => o.id === mullion.profileId);
                const segment = segments.find(o => o.id === mullion.profileId);

                if (segment) {
                    segment.count += doc.quantity;
                } else {
                    segments.push({
                        id: mullion.profileId,
                        doc: {
                            ...profile,
                            name: TranslateService.translate(
                                translatedData,
                                profile,
                                'Profile',
                                'name'
                            ),
                        },
                        count: doc.quantity,
                        price: 0,
                        total: 0,
                    });
                }
            }
        }

        for (const segment of segments) {
            segment.total += this.priceFromSegments(doc, 'dividers', { profileId: segment.id });
        }
    }

    getExtensions(doc, segments, translatedData) {
        let sideProfiles = doc.details.sideProfiles;
        if (doc.details.sideProfiles.top) {
            sideProfiles = [
                ...doc.details.sideProfiles.top,
                ...doc.details.sideProfiles.bottom,
                ...doc.details.sideProfiles.left,
                ...doc.details.sideProfiles.right,
            ];
        }
        for (const extension of sideProfiles) {
            if (extension.profileId) {
                const profile = doc.details.usedProfiles.find(o => o.id === extension.profileId);
                const segment = segments.find(o => o.id === extension.profileId);

                if (segment) {
                    segment.count += doc.quantity;
                } else {
                    segments.push({
                        id: extension.profileId,
                        doc: {
                            ...profile,
                            name: TranslateService.translate(
                                translatedData,
                                profile,
                                'Profile',
                                'name'
                            ),
                        },
                        count: doc.quantity,
                        price: 0,
                        total: 0,
                    });
                }
            }
        }

        for (const segment of segments) {
            segment.total += this.priceFromSegments(doc, 'sideProfile', { profileId: segment.id });
        }
    }

    getCouplings(doc, segments, translatedData) {
        for (const coupling of doc.details.couplings) {
            if (coupling.profileId) {
                const profile = doc.details.usedProfiles.find(o => o.id === coupling.profileId);
                const segment = segments.find(o => o.id === coupling.profileId);

                if (segment) {
                    segment.count += doc.quantity;
                } else {
                    segments.push({
                        id: coupling.profileId,
                        doc: {
                            ...profile,
                            name: TranslateService.translate(
                                translatedData,
                                profile,
                                'Profile',
                                'name'
                            ),
                        },
                        count: doc.quantity,
                        price: 0,
                        total: 0,
                    });
                }
            }
        }

        for (const segment of segments) {
            segment.total += this.priceFromSegments(doc, 'couplings', { profileId: segment.id });
        }
    }

    getFittings(doc, segments, translatedData) {
        if (doc.details.fitting && doc.details.fitting.id) {
            const sashesF = doc.details.sashes.filter(sash => sash.type.type !== 'F').length;
            const segment = segments.find(o => o.id === doc.details.fitting.id);

            if (segment) {
                segment.count += sashesF * doc.quantity;
            } else {
                segments.push({
                    id: doc.details.fitting.id,
                    doc: {
                        ...doc.details.fitting,
                        name: TranslateService.translate(
                            translatedData,
                            doc.details.fitting,
                            'WindowFitting',
                            'name'
                        ),
                    },
                    count: sashesF * doc.quantity,
                    price: 0,
                    total: 0,
                });
            }
        }

        for (const segment of segments) {
            segment.total += this.priceFromSegments(doc, 'fittings', { id: segment.id });
        }
    }

    getFrames(doc, segments, translatedData) {
        if (doc.details.frames) {
            for (const frame of doc.details.frames) {
                for (const frameProfile of frame.frame) {
                    if (frameProfile.profileId) {
                        const profile = doc.details.usedProfiles.find(
                            o => o.id === frameProfile.profileId
                        );
                        const segment = segments.find(o => o.id === frameProfile.profileId);

                        if (segment) {
                            segment.count += doc.quantity;
                        } else {
                            segments.push({
                                id: frameProfile.profileId,
                                doc: {
                                    ...profile,
                                    name: TranslateService.translate(
                                        translatedData,
                                        profile,
                                        'Profile',
                                        'name'
                                    ),
                                },
                                count: doc.quantity,
                                price: 0,
                                total: 0,
                                frameId: frame.id,
                            });
                        }
                    }
                }
            }
        }

        for (const segment of segments) {
            segment.total += this.priceFromSegments(doc, 'frame', {
                profileId: segment.id,
                frameId: segment.frameId,
            });
        }
    }

    getGlazings(doc, segments, translatedData) {
        const sashes = [];

        doc.details.sashes.map(sash =>
            sash.intSashes && sash.intSashes.length
                ? sashes.push(...sash.intSashes)
                : sashes.push(sash)
        );

        for (const sash of sashes) {
            if (sash.filling && sash.filling.id) {
                const segment = segments.find(o => o.id === sash.filling.id);

                if (segment) {
                    segment.area += ((sash.width * sash.height) / 1e6) * doc.quantity;
                } else {
                    segments.push({
                        id: sash.filling.id,
                        doc: {
                            ...sash.filling,
                            name: TranslateService.translate(
                                translatedData,
                                sash.filling,
                                'Filling',
                                'name'
                            ),
                        },
                        area: ((sash.width * sash.height) / 1e6) * doc.quantity,
                        price: 0,
                        total: 0,
                    });
                }
            }
        }

        for (const segment of segments) {
            segment.total +=
                this.priceFromSegments(doc, 'filling', { id: segment.id })
                + this.priceFromSegments(doc, 'fillingShape', { id: segment.id });
        }
    }

    getGlazingBeads(doc, segments, translatedData) {
        const sashes = [];

        doc.details.sashes.map(sash =>
            sash.intSashes && sash.intSashes.length
                ? sashes.push(...sash.intSashes)
                : sashes.push(sash)
        );

        for (const sash of sashes) {
            if (sash.glazingBead && sash.glazingBead.profileId) {
                const profile = doc.details.usedProfiles.find(
                    o => o.id === sash.glazingBead.profileId
                );
                const segment = segments.find(o => o.id === sash.glazingBead.profileId);

                if (segment) {
                    segment.count += doc.quantity;
                } else {
                    segments.push({
                        id: sash.glazingBead.profileId,
                        doc: {
                            ...profile,
                            name: TranslateService.translate(
                                translatedData,
                                profile,
                                'Profile',
                                'name'
                            ),
                        },
                        count: doc.quantity,
                        price: 0,
                        total: 0,
                    });
                }
            }
        }

        for (const segment of segments) {
            segment.total += this.priceFromSegments(doc, 'glazingBeads', { profileId: segment.id });
        }
    }

    getHandles(doc, segments, translatedData) {
        for (const sash of doc.details.sashes) {
            if (sash.handleInner && sash.handleInner.id) {
                const segment = segments.find(
                    o => o.id === sash.handleInner.id && o.color.id === sash.handleInner.color.id
                );

                if (segment) {
                    segment.count += doc.quantity;
                } else {
                    segments.push({
                        id: sash.handleInner.id,
                        color: {
                            ...sash.handleInner.color,
                            name: TranslateService.translate(
                                translatedData,
                                sash.handleInner.color,
                                'WindowHandlesColor',
                                'name'
                            ),
                        },
                        doc: {
                            ...sash.handleInner,
                            name: TranslateService.translate(
                                translatedData,
                                sash.handleInner,
                                'WindowAccessory',
                                'name'
                            ),
                        },
                        count: doc.quantity,
                        price: 0,
                        total: 0,
                    });
                }
            }
        }

        for (const segment of segments) {
            segment.total += this.priceFromSegments(doc, 'handles', { id: segment.id });
        }
    }

    getHinges(doc, segments, translatedData) {
        if (doc.details.type === 'door' && doc.details.hinge && doc.details.hinge.id) {
            const segment = segments.find(o => o.id === doc.details.hinge.id);

            if (segment) {
                segment.count += doc.quantity;
            } else {
                segments.push({
                    id: doc.details.hinge.id,
                    doc: {
                        ...doc.details.hinge,
                        name: TranslateService.translate(
                            translatedData,
                            doc.details.hinge,
                            'WindowAccessory',
                            'name'
                        ),
                    },
                    count: doc.quantity,
                    price: 0,
                    total: 0,
                });
            }
        }

        for (const segment of segments) {
            segment.total += this.priceFromSegments(doc, 'hinges', { id: segment.id });
        }
    }

    getLocks(doc, segments, translatedData) {
        if (doc.details.type === 'door' && doc.details.lock && doc.details.lock.id) {
            const sashesF = doc.details.sashes.filter(sash => sash.type.type !== 'F').length;
            const segment = segments.find(o => o.id === doc.details.lock.id);

            if (segment) {
                segment.count += sashesF * doc.quantity;
            } else {
                segments.push({
                    id: doc.details.lock.id,
                    doc: {
                        ...doc.details.lock,
                        name: TranslateService.translate(
                            translatedData,
                            doc.details.lock,
                            'WindowAccessory',
                            'name'
                        ),
                    },
                    count: sashesF * doc.quantity,
                    price: 0,
                    total: 0,
                });
            }
        }

        for (const segment of segments) {
            segment.total += this.priceFromSegments(doc, 'locks', { id: segment.id });
        }
    }

    getMuntins(doc, segments, translatedData) {
        if (doc.details.muntins && doc.details.muntins.type) {
            const sashes = [];

            doc.details.sashes.map(sash =>
                sash.intSashes && sash.intSashes.length
                    ? sashes.push(...sash.intSashes)
                    : sashes.push(sash)
            );

            let fields = 0;
            let size = 0;

            for (const sash of sashes) {
                if (sash.muntins && sash.muntins.length) {
                    const muntinsH = sash.muntins.filter(muntin => muntin.start.y === muntin.end.y);
                    const muntinsV = sash.muntins.filter(muntin => muntin.start.x === muntin.end.x);

                    fields += (muntinsH.length + 1) * (muntinsV.length + 1);
                    size += muntinsH
                        .map(o => Math.abs(o.end.x - o.start.x))
                        .reduce((a, b) => a + b, 0);
                    size += muntinsV
                        .map(o => Math.abs(o.end.y - o.start.y))
                        .reduce((a, b) => a + b, 0);
                }
            }

            if (fields && size) {
                const segment = segments.find(
                    o =>
                        o.id === doc.details.muntins.type.id
                        && o.size === doc.details.muntins.size
                        && doc.details.muntins.colorInner
                        && o.color.id === doc.details.muntins.colorInner.id
                );

                if (segment) {
                    segment.mb += (size / 1e3) * doc.quantity;
                    segment.fields += fields * doc.quantity;
                    segment.total += this.priceFromSegments(doc, 'muntins');
                } else {
                    segments.push({
                        id: doc.details.muntins.type.id,
                        color: {
                            ...doc.details.muntins.colorInner,
                            name: TranslateService.translate(
                                translatedData,
                                doc.details.muntins.colorInner,
                                'WindowBarColor',
                                'name'
                            ),
                        },
                        doc: {
                            ...doc.details.muntins.type.type,
                            name: TranslateService.translate(
                                translatedData,
                                doc.details.muntins.type.type,
                                'WindowBarType',
                                'name'
                            ),
                        },
                        size: doc.details.muntins.size,
                        mb: (size / 1e3) * doc.quantity,
                        fields: fields * doc.quantity,
                        price: 0,
                        total: this.priceFromSegments(doc, 'muntins'),
                    });
                }
            }
        }
    }

    getSashes(doc, segments, translatedData) {
        for (const sash of doc.details.sashes) {
            if (
                sash.type
                && sash.type.id
                && sash.type.type !== 'F'
                && sash.type.type !== 'FF'
                && sash.type.type !== 'OFF'
            ) {
                const segment = segments.find(o => o.id === sash.type.id);

                if (segment) {
                    segment.count += doc.quantity;
                } else {
                    segments.push({
                        id: sash.type.id,
                        doc: {
                            ...sash.type,
                            name: TranslateService.translate(
                                translatedData,
                                sash.type,
                                'SashType',
                                'name'
                            ),
                        },
                        count: doc.quantity,
                        price: 0,
                        total: 0,
                    });
                }
            }
        }
    }

    getSashFrames(doc, segments, translatedData) {
        for (const sash of doc.details.sashes) {
            if (sash.frame && sash.type.type !== 'F') {
                for (const side of Object.keys(sash.frame)) {
                    const profile = doc.details.usedProfiles.find(
                        o => o.id === sash.frame[side].profileId
                    );
                    const segment = segments.find(o => o.id === sash.frame[side].profileId);

                    if (segment) {
                        segment.count += doc.quantity;
                    } else {
                        segments.push({
                            id: sash.frame[side].profileId,
                            doc: {
                                ...profile,
                                name: TranslateService.translate(
                                    translatedData,
                                    profile,
                                    'Profile',
                                    'name'
                                ),
                            },
                            count: doc.quantity,
                            price: 0,
                            total: 0,
                        });
                    }
                }
            }
        }

        for (const segment of segments) {
            segment.total += this.priceFromSegments(doc, 'sashFrames', { profileId: segment.id });
        }
    }

    getSeals(doc, segments, translatedData) {
        if (doc.details.sealColor && doc.details.sealColor.id) {
            const segment = segments.find(o => o.id === doc.details.sealColor.id);

            if (segment) {
                segment.count += doc.quantity;
            } else {
                segments.push({
                    id: doc.details.sealColor.id,
                    doc: {
                        ...doc.details.sealColor,
                        name: TranslateService.translate(
                            translatedData,
                            doc.details.sealColor,
                            'WindowSealColor',
                            'name'
                        ),
                    },
                    count: doc.quantity,
                    price: 0,
                    total: 0,
                });
            }
        }

        for (const segment of segments) {
            segment.total += this.priceFromSegments(doc, 'sealColor', { id: segment.id });
        }
    }

    getSteels(doc, segments, translatedData) {
        if (doc.details.steel) {
            const segment = segments.find(o => o.id === doc.details.steel);

            if (segment) {
                segment.count += doc.quantity;
            } else {
                segments.push({
                    id: doc.details.steel,
                    doc: doc.details.steel,
                    count: doc.quantity,
                    price: 0,
                    total: 0,
                });
            }
        }
    }

    getWarmEdges(doc, segments, translatedData) {
        if (doc.details.glazingSpacer && doc.details.glazingSpacer.id) {
            const segment = segments.find(o => o.id === doc.details.glazingSpacer.id);

            if (segment) {
                segment.area += ((doc.details.width * doc.details.height) / 1e6) * doc.quantity;
            } else {
                segments.push({
                    id: doc.details.glazingSpacer.id,
                    doc: {
                        ...doc.details.glazingSpacer,
                        name: TranslateService.translate(
                            translatedData,
                            doc.details.glazingSpacer,
                            'WindowWarmEdge',
                            'name'
                        ),
                    },
                    area: ((doc.details.width * doc.details.height) / 1e6) * doc.quantity,
                    price: 0,
                    total: 0,
                });
            }
        }

        for (const segment of segments) {
            segment.total += this.priceFromSegments(doc, 'warmEdge', { id: segment.id });
        }
    }

    private priceFromSegments(doc, type, data?) {
        const segments = doc.details.priceSegments
            .filter(
                o =>
                    o
                    && o.type === type
                    && (!data || Object.keys(data).every(key => o.data[key] === data[key]))
            )
            .map(o => o.id);

        const parts = doc.details.priceParts
            .filter(o => o && (segments.includes(o.percentId) || segments.includes(o.valueId)))
            .map(o => o.value);

        return parts.reduce((a, b) => a + b, 0);
    }
}
