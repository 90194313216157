import { ActiveConfiguration } from './ActiveConfiguration';
import GarageConfiguration from './GarageConfiguration';
import { PriceSegment, DiscountGroups, PricePart } from '../price/Prices';
import { TimeLimitSegment } from '../../application/src/time_limit/time_limit_functions';
import { ConfiguratorsDataService } from '../configurators/configurators-data.service';
import { ActiveAccessory } from './parts/common';

export default class GarageActiveConfiguration implements ActiveConfiguration {
    public static is(configuration): configuration is GarageActiveConfiguration {
        return configuration instanceof GarageActiveConfiguration || !configuration.$version;
    }
    type: 'garage' = 'garage';
    Name: string = '';
    Price: number = 0;
    PriceNoMargin: number = 0;
    PriceAfterDiscounts: number = 0;
    Quantity: number = 1;
    Issues: any[] = [];
    PriceSegments: PriceSegment[] = [];
    PriceSegmentsNoMargin: PriceSegment[] = [];
    PriceParts: PricePart[] = [];
    PricePartsNoMargin: PricePart[] = [];
    DiscountGroups: DiscountGroups = {};
    DiscountGroupsNoMargin: DiscountGroups = {};
    timeLimit: number = 0;
    timeLimitsStack: TimeLimitSegment[] = [];
    Title = '';
    Description = '';
    Attachments = [];
    valid: {} = {};
    PriceValid = false;
    drawData: any;

    System: any = {};
    Height = 2000;
    Width = 2500;
    Shape: any = {};
    MountingLocation: 'in' | 'out' = 'in';
    DimensionsType: 'standard' | 'custom' = 'standard';
    Panel: any = {};
    Structure: any = {};
    Insulation = false;
    Control: any = {};
    Colors: any = {};
    Guide: any = null;
    Accessories: any[] = [];

    constructor(configuration?, private configuratorsDataService?: ConfiguratorsDataService) {
        if (configuration) {
            if (GarageActiveConfiguration.is(configuration)) {
                for (const propName in configuration) {
                    if (this.hasOwnProperty(propName)) {
                        this[propName] = configuration[propName];
                    }
                }
            } else {
                if (GarageConfiguration.is(configuration) && configuratorsDataService) {
                    this.Name = configuration.name;
                    this.Price = configuration.price;
                    this.PriceNoMargin = configuration.priceNoMargin;
                    this.PriceAfterDiscounts = configuration.priceAfterDiscounts;
                    this.Quantity = configuration.quantity;
                    this.PriceSegments = configuration.priceSegments;
                    this.PriceSegmentsNoMargin = configuration.priceSegmentsNoMargin;
                    this.PriceParts = configuration.priceParts;
                    this.PricePartsNoMargin = configuration.pricePartsNoMargin;
                    this.DiscountGroups = configuration.discountGroups;
                    this.DiscountGroupsNoMargin = configuration.discountGroupsNoMargin;
                    this.Title = configuration.title;
                    this.Description = configuration.description;
                    this.valid = configuration.valid;
                    this.timeLimit = configuration.timeLimit;
                    this.timeLimitsStack = configuration.timeLimitsStack;

                    this.System = configuratorsDataService.get(
                        'garageLines',
                        configuration.system.id
                    );
                    this.Height = configuration.height;
                    this.Width = configuration.width;
                    this.Shape = configuration.dimensions;
                    this.Shape.shape = 'rect';
                    this.MountingLocation = configuration.mountingLocation;
                    this.DimensionsType = configuration.dimensionsType;
                    this.Panel = configuratorsDataService.get('garagePanels.GaragePanel', {
                        key: 'GaragePanel.id',
                        val: configuration.panel.id + '',
                    });
                    this.Structure = configuratorsDataService.get(
                        'garagePanelStructures.GaragePanelStructure',
                        { key: 'GaragePanelStructure.id', val: configuration.structure.id + '' }
                    );
                    this.Insulation = false;
                    if (configuration.control) {
                        this.Control = configuratorsDataService.get(
                            'garageControls',
                            `${configuration.system.id}.${configuration.guide}`,
                            configuration.control.id
                        );
                    }
                    if (configuration.outerColor) {
                        const color = configuratorsDataService.get(
                            'garagePanelStructureColors',
                            configuration.outerColor.id
                        );
                        if (color) {
                            this.Colors = {
                                outer: {
                                    id: configuration.outerColor.id,
                                    color: color.color,
                                    color_img: color.color_img,
                                    name: configuration.outerColor.name,
                                    group: color.type,
                                    code: configuration.outerColor.code,
                                },
                            };
                        }
                    }

                    this.Guide = configuratorsDataService.get(
                        'garageGuides',
                        configuration.guide.id
                    );
                    this.Accessories = configuration.accessories.map(
                        accessory =>
                            new ActiveAccessory(accessory, null, this.configuratorsDataService)
                    );
                }
            }
        }
        this.configuratorsDataService = null;
    }
}
