import angular from 'angular';

/**
 * Funkcja podkategorii szyby
 */
export default function GlassSubCatsFilter() {
    'ngInject';

    return function(cats, categoryId) {
        var catArr = [];
        angular.forEach(cats, function(obj) {
            catArr.push(obj);
        });
        if (angular.isUndefined(categoryId) || categoryId === null || categoryId === '') {
            return [];
        }
        return catArr.filter(function(gl) {
            if (gl.parent_id == categoryId) {
                return true;
            }
            return false;
        });
    };
}
