/**
 * Kontroler linii markiz
 * @param {object} $scope                   Scope
 * @param {object} AwningSystemsFactory     Fabryka linii markiz
 */
export default function SystemsCtrl($scope, AwningSystemsFactory, EventBusService) {
    'ngInject';

    var vm = this;

    vm.subStep      = 1;
    vm.systems      = AwningSystemsFactory.systems;
    vm.allSystems   = true;
    vm.selectSystem = selectSystem;

    if (AwningSystemsFactory.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    /**
     * Funkcja inicjujaca
     */
    function init() {
        vm.systems = AwningSystemsFactory.systems;
    }

    /**
     * Wybór systemu
     * @param  {Object} system System
     */
    function selectSystem(system) {
        AwningSystemsFactory.selectSystem(system, true, true);
    }

}
