import angular from 'angular';

/**
 * Kontroler klamek
 * @param {object} $scope         Angular Scope
 * @param {object} HandlesFactory Fabryka klamek
 * @param {object} StepFactory    Fabryka krokow
 */
export default function HandlesCtrl($rootScope, $scope, Core, HandlesFactory, StepFactory, ConfigurationsService, IccConfig, systemsByTagFilter, DrawService, EventBusService, GtmService, ScrollbarService, $filter) {
    'ngInject';

    const vm = this;
    let conf = ConfigurationsService.conf.Current;
    vm.subStep = 1;
    vm.isMatch               = HandlesFactory.isMatch;
    vm.openModalHandle       = openModalHandle;
    vm.openModalHinge        = HandlesFactory.openModalHinge;
    vm.openModalSashHardware = HandlesFactory.openModalSashHardware;
    vm.selectFitting         = HandlesFactory.selectFitting;
    vm.selectHandleColor     = HandlesFactory.setHandleColor;
    vm.setHandleType         = HandlesFactory.setHandleType;
    vm.fittings              = [];
    vm.colors                = [];
    vm.selectedFittings      = [];
    vm.tags                  = [];
    vm.hasHandles            = true;
    vm.isLastStep            = false;
    vm.isDoorVersion2        = IccConfig.Configurators.door.version == 2 && conf.type == 'door';
    vm.showInnerLeverBox     = () =>
        conf.HandleType == 'DoubleLever'
        || conf.HandleType == 'LeverPull'
        || conf.HandleType == 'InnerLever';
    vm.showInnerPullBox      = () => conf.HandleType == 'DoublePull';
    vm.showOuterPullBox      = () => conf.HandleType == 'DoublePull' || conf.HandleType == 'LeverPull';
    vm.showWindowLeverBox    = () => conf.OwnedSashesTypes.window && conf.Sashes.some(el => !['F', 'FF', 'OFF', 'DRA', 'DRP', 'DOA', 'DOP'].includes(el.type.type)) && conf.HasHandle && vm.isDoorVersion2;
    vm.selectedTag           = selectedTag;

    vm.drawOptions = angular.merge({}, DrawService.options, {
        sashFrame: {
            buttons: [
                {
                    condition: response => ['F', 'FF', 'OFF', 'DS', 'DSC', 'ODS', 'DRP', 'DOP', 'FD', 'FDO'].indexOf(response.sash.type.type) === -1,
                    innerHtml: `<img src="/application/dist/web/img/icons/handler.png">`,
                    title: $filter('translate')('INTERFACE|Wybierz klamkę'),
                    onClick: response => {
                        const sash = Core.fIdO(ConfigurationsService.conf.Current.Sashes, response.sash.id);
                        openModalHandle(sash, 'innerLever');
                    },
                },
            ],
        },
        onInterfaceClick: () => {
            $scope.$digest();
        }
    });

    vm.innerLeverBox = () => {
        let img = '/files/windowaccessory/' + conf.Handle.img;

        if (angular.isObject(conf.Handle.colors_imgs)
            && angular.isString(conf.Handle.colors_imgs[conf.HandleColor.id])
            && conf.Handle.colors_imgs[conf.HandleColor.id] != ''
        ) {
            img = '/files/windowhandlescolorswindowaccessory/' + conf.Handle.colors_imgs[conf.HandleColor.id];
        }

        let name = conf.Handle.name;
        let color = conf.HandleColor.name;
        let colorOuter = conf.HandleOuterColor.name;
        let selectType = 'default';
        let oneHandle = conf.Handle && conf.OneHandle && conf.HasHandle;
        let varHandle = !conf.OneHandle && conf.HasHandle;
        let hasHandle = conf.HasHandle;
        if (vm.isDoorVersion2
            && conf.OwnedSashesTypes.window && conf.Sashes.some(el => !['F', 'FF', 'OFF', 'DRA', 'DRP', 'DOA', 'DOP'].includes(el.type.type))
            && conf.HasHandle
        ) {
            name = conf.Handle.name;
            selectType = 'door';

            if (angular.isObject(conf.OneHandleSash.door)) {
                name = conf.OneHandleSash.door.name;
                color = conf.OneHandleSash.doorColor.name;
                colorOuter = conf.OneHandleOuterSash.doorColor.name;
                oneHandle = conf.OneHandleSash.door && conf.HasHandle;
                varHandle = !conf.OneHandleSash.door && conf.HasHandle;
                hasHandle = conf.HasHandle;
            }
        }
        return {
            img,
            name,
            color,
            colorOuter,
            selectType,
            oneHandle,
            varHandle,
            hasHandle
        };
    };

    vm.innerPullBox = () => {
        let img = '/files/windowaccessory/' + conf.Handle.img;

        if (angular.isObject(conf.Handle.colors_imgs)
            && angular.isString(conf.Handle.colors_imgs[conf.HandleColor.id])
            && conf.Handle.colors_imgs[conf.HandleColor.id] != ''
        ) {
            img = '/files/windowhandlescolorswindowaccessory/' + conf.Handle.colors_imgs[conf.HandleColor.id];
        }

        const name = conf.Handle.name;
        const color = conf.HandleColor.name;
        const colorOuter = conf.HandleOuterColor.name;
        const selectType = 'door';
        const description = conf.Handle.description;
        const oneHandle = conf.Handle && conf.OneHandle && conf.HasHandle;
        const varHandle = !conf.OneHandle && conf.HasHandle;
        const hasHandle = conf.HasHandle;
        return {
            img,
            name,
            color,
            colorOuter,
            selectType,
            description,
            oneHandle,
            varHandle,
            hasHandle
        };
    };

    vm.outerPullBox = () => {
        let img = '/files/windowaccessory/' + conf.HandleOuter.img;

        if (angular.isObject(conf.HandleOuter.colors_imgs)
            && angular.isString(conf.HandleOuter.colors_imgs[conf.HandleOuterColor.id])
            && conf.HandleOuter.colors_imgs[conf.HandleOuterColor.id] != ''
        ) {
            img = '/files/windowhandlescolorswindowaccessory/' + conf.HandleOuter.colors_imgs[conf.HandleOuterColor.id];
        }

        const name = conf.HandleOuter.name;
        const color = conf.HandleColor.name;
        const colorOuter = conf.HandleOuterColor.name;
        const selectType = 'door';
        const description = conf.HandleOuter.description;
        const oneHandle = conf.HandleOuter && conf.OneHandle && conf.HasHandle;
        const varHandle = !conf.HandleOuter && conf.OneHandle && conf.HasHandle;
        const hasHandle = conf.HasHandle;
        return {
            img,
            name,
            color,
            colorOuter,
            selectType,
            description,
            oneHandle,
            varHandle,
            hasHandle
        };
    };

    vm.windowLeverBox = () => {
        let img = '/files/windowaccessory/' + conf.OneHandleSash.window.img;

        if (angular.isObject(conf.OneHandleSash.window)
            && angular.isObject(conf.OneHandleSash.window.colors_imgs)
            && angular.isString(conf.OneHandleSash.window.colors_imgs[conf.OneHandleOuterSash.windowColor.id])
            && conf.OneHandleSash.window.colors_imgs[conf.OneHandleOuterSash.windowColor.id] != ''
        ) {
            img = '/files/windowhandlescolorswindowaccessory/' + conf.OneHandleSash.window.colors_imgs[conf.HandleOuterColor.id];
        }

        const name = conf.OneHandleSash.window.name;
        const color = conf.OneHandleSash.windowColor.name;
        const colorOuter = conf.OneHandleOuterSash.windowColor.name;
        const selectType = 'window';
        const description = conf.OneHandleSash.window.description;
        const oneHandle = angular.isObject(conf.OneHandleSash.window);
        const varHandle = !oneHandle;
        const hasHandle = vm.showWindowLeverBox;
        return {
            img,
            name,
            color,
            colorOuter,
            selectType,
            description,
            oneHandle,
            varHandle,
            hasHandle
        };
    };

    vm.goToSubstep = subStep => {
        vm.subStep = subStep;
        if (IccConfig.GTM.allowTags) {
            EventBusService.post({
                key: 'googleTags',
                value: $scope.config.stepCode + vm.subStep
            });
        }
        if (IccConfig.Configurators.tutorialAvailable) {
            if (subStep == 2) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'fittingSub'
                });
            } else if (subStep == 1) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg'
                });
            }
        }

    };

    if (HandlesFactory.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));
    $scope.$on('refreshHandlesColors', init);

    /**
     * Funkcja inicjalizacyjna
     */
    function init() {
        var lastStep = StepFactory.getLastStep();

        vm.fittings   = HandlesFactory.fittings;
        vm.colors     = HandlesFactory.handleColors;
        vm.isLastStep = lastStep.code === 'fitting';
        vm.hasHandles = HandlesFactory.handles.length > 1;
        vm.tags       = HandlesFactory.tags.filter(tag => systemsByTagFilter(vm.fittings, [tag.id]).length > 0);
    }

    function openModalHandle(sash, place) {
        if (place == 'innerLever' && (sash === 'window' || sash && sash.type && sash.type.type != 'DRA' && sash.type.type != 'DOA')) {
            HandlesFactory.openModalHandle(sash, place, 'InnerLever');
            if (IccConfig.Configurators.tutorialAvailable) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'modalHandleSash'
                });
            }
        } else {
            HandlesFactory.openModalHandle(sash, place, ConfigurationsService.conf.Current.HandleType);
            if (IccConfig.Configurators.tutorialAvailable) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'modalHandle'
                });
            }
        }
    }

    function selectedTag(fittings) {
        vm.selectedFittings = fittings;
        ScrollbarService.update();
    }
}
