import { Injectable, Inject } from '@angular/core';
import { core } from 'helpers';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import Common from 'Common';
import { ActiveSash } from 'layout/active-sash';
import { TranslateService } from 'translate.service';
import { EventBusService } from 'event-bus.service';
import PriceService from 'price/price.service';
import { AlignmentsService } from './alignments.service';
import BrowserShapeService from './shape.service';
import { Alignment } from 'configurations/parts/window';
import ParametersService from 'configurators/parameters.service';
import { MullionsLayoutService } from './mullions-layout.service';
import { SashTypesService } from './sash-types.service';
import { SashesLayoutService } from './sashes-layout.service';
import { BrowserFramesService } from './frames.service';

@Injectable()
export class RemoveSashService {
    constructor(
        private translateService: TranslateService,
        private eventBusService: EventBusService,
        private priceService: PriceService,
        private alignmentsService: AlignmentsService,
        private shapeService: BrowserShapeService,
        private parametersService: ParametersService,
        @Inject('ConstructionLimitationFactory') private constructionLimitationFactory,
        @Inject('WarrantyFactory') private warrantyFactory,
        private mullionsLayoutService: MullionsLayoutService,
        private sashTypesService: SashTypesService,
        private sashesLayoutService: SashesLayoutService,
        private framesService: BrowserFramesService
    ) {}

    /**
     * Usuwa skrzydło
     */
    removeSash(sash: ActiveSash, conf: WindowActiveConfiguration, removedFrame = false) {
        if (Common.isUndefined(sash) || sash === null) {
            return;
        }

        let keepSearching = true;
        let sashes = conf.Sashes;
        let edgeSashes = conf.EdgeSashes;
        let dividers = conf.Mullions;
        const layout = conf.Layout;

        // gdy nie jest ustawione stałe szklenie (zostawia stałe szklenie)
        if (
            !Common.isUndefined(sash.parentId)
            && !Common.isUndefined(core.fId(conf.Sashes, sash.parentId))
            && !removedFrame
        ) {
            const intSash = core.fIdO(conf.Sashes, sash.parentId);
            sashes = intSash.intSashes;
            edgeSashes = intSash.intEdgeSashes;
            dividers = intSash.intMullions;

            const fixTypes = this.sashTypesService.sashTypes.filter(function filterSashTypes(el) {
                return el.type === 'F';
            });
            const fixType = fixTypes.length ? fixTypes[0] : this.sashTypesService.sashTypes[0];

            if (Common.isUndefined(sashes[1])) {
                intSash.intSashes = [];
                intSash.intMullions = [];
                intSash.intAlignments = [];
                intSash.intEdgeSashes = {
                    top: [],
                    bottom: [],
                    left: [],
                    right: [],
                };
                intSash.type = fixType;
                this.constructionLimitationFactory.findReinforcement(conf);
                this.eventBusService.post({
                    key: 'changedSashes',
                    value: {},
                });

                this.sashTypesService.checkSashesType(conf);
                this.shapeService.setShapes(conf);

                layout.changed = true;
                this.eventBusService.post({
                    key: 'icc-redraw',
                    value: 'frame',
                });
                return;
            }
        }
        // Wypełnienie luki od góry
        keepSearching = this.removeSashFromSide(
            sash,
            ['top', 'bottom', 'left', 'right'],
            'height',
            false,
            keepSearching,
            sashes,
            dividers,
            edgeSashes,
            conf
        );

        // Wypełnienie luki od dołu
        keepSearching = this.removeSashFromSide(
            sash,
            ['bottom', 'top', 'left', 'right'],
            'height',
            true,
            keepSearching,
            sashes,
            dividers,
            edgeSashes,
            conf
        );

        // Wypełnienie luki od lewej
        keepSearching = this.removeSashFromSide(
            sash,
            ['left', 'right', 'top', 'bottom'],
            'width',
            false,
            keepSearching,
            sashes,
            dividers,
            edgeSashes,
            conf
        );

        // Wypełnienie luki od prawej
        keepSearching = this.removeSashFromSide(
            sash,
            ['right', 'left', 'top', 'bottom'],
            'width',
            true,
            keepSearching,
            sashes,
            dividers,
            edgeSashes,
            conf
        );

        // Wypełnienie luki od lewej
        keepSearching = this.removeSashFromSideMulti(
            sash,
            ['left', 'right', 'top', 'bottom'],
            'width',
            false,
            keepSearching,
            sashes,
            dividers,
            edgeSashes,
            conf
        );

        // Wypełnienie luki od prawej
        keepSearching = this.removeSashFromSideMulti(
            sash,
            ['right', 'left', 'top', 'bottom'],
            'width',
            true,
            keepSearching,
            sashes,
            dividers,
            edgeSashes,
            conf
        );

        // Wypełnienie luki od góry
        keepSearching = this.removeSashFromSideMulti(
            sash,
            ['top', 'bottom', 'left', 'right'],
            'height',
            false,
            keepSearching,
            sashes,
            dividers,
            edgeSashes,
            conf
        );

        // Wypełnienie luki od dołu
        keepSearching = this.removeSashFromSideMulti(
            sash,
            ['bottom', 'top', 'left', 'right'],
            'height',
            true,
            keepSearching,
            sashes,
            dividers,
            edgeSashes,
            conf
        );

        if (keepSearching && !removedFrame) {
            const frame = conf.Frames.find(f => f.id === sash.frameId);
            const coupling = conf.couplings.find(
                c =>
                    c.otherFramesId.some(fId => fId.id === frame.id)
                    || c.framesId.some(fId => fId.id === frame.id)
            );
            if (coupling) {
                this.framesService.joinFrame(
                    {
                        id: 0,
                    },
                    coupling,
                    conf
                );

                keepSearching = this.removeSash(sash, conf, true);
            }
        }

        // Jeśli nie znaleziono nic do wypelnienia luki to komunikat
        if (keepSearching) {
            alert(
                this.translateService.instant(
                    'INTERFACE|Nie można usunąć skrzydła w aktualnej konfiguracji'
                )
            );
        }

        if (!removedFrame) {
            // factory.hideCrossbars();
            this.eventBusService.post({
                key: 'icc-redraw',
                value: 'frame',
            });
            this.shapeService.setShapes(conf);
            this.eventBusService.post({
                key: 'changedSashes',
                value: {},
            });

            this.sashesLayoutService.setIndex(conf);

            this.constructionLimitationFactory.findReinforcement(conf);
            this.priceService.count();
            this.parametersService.count(conf);
            this.warrantyFactory.check(conf);
            layout.changed = true;

            this.sashTypesService.checkSashesType(conf);

            this.eventBusService.post({
                key: 'icc-redraw',
                value: 'frame',
            });
        }
        return keepSearching;
    }
    /**
     * Usuń skrzydło z boku
     * @param  {Object} sash       Skrzydło
     * @param  {Array}  sides      Strony
     * @param  {[type]} direction  [description]
     * @param  {[type]} rev        [description]
     * @param  {[type]} door       [description]
     * @param  {[type]} search     [description]
     * @param  {[type]} sashes     [description]
     * @param  {[type]} dividers   [description]
     * @param  {[type]} edgeSashes [description]
     * @param  {[type]} internal   [description]
     * @return {bool}              [description]
     */
    private removeSashFromSide(
        sash: ActiveSash,
        sides: (keyof ActiveSash['nearMullions'])[],
        direction: 'width' | 'height',
        rev: boolean,
        search: boolean,
        sashes: WindowActiveConfiguration['Sashes'],
        dividers: WindowActiveConfiguration['Mullions'],
        edgeSashes: WindowActiveConfiguration['EdgeSashes'],
        conf: WindowActiveConfiguration
    ) {
        if (search) {
            const div1 = core.fId(dividers, sash.nearMullions[sides[0]]);
            if (
                sash.nearMullions[sides[0]] > -1
                && dividers[div1]['multiAlign' + core.capitalize(sides[0])].length === 1
                && dividers[div1]['multiAlign' + core.capitalize(sides[1])].length === 1
            ) {
                const fromSash: ActiveSash =
                    dividers[div1]['multiAlign' + core.capitalize(sides[0])][0];
                this.mergeSashes(fromSash, sash, direction, sides[0]);
                fromSash.nearMullions[sides[1]] = -1;

                if (sash.nearMullions[sides[1]] !== -1) {
                    const div2 = core.fId(dividers, sash.nearMullions[sides[1]]);
                    dividers[div2]['multiAlign' + core.capitalize(sides[0])] = dividers[div2][
                        'multiAlign' + core.capitalize(sides[0])
                    ].filter(el => el.id !== sash.id);
                    dividers[div2]['multiAlign' + core.capitalize(sides[0])].push(fromSash);
                    fromSash.nearMullions[sides[1]] = sash.nearMullions[sides[1]];
                } else {
                    edgeSashes[sides[1]].push(fromSash.id);
                }

                if (sash.nearMullions[sides[2]] !== -1) {
                    const div3 = core.fId(dividers, sash.nearMullions[sides[2]]);
                    dividers[div3]['multiAlign' + core.capitalize(sides[3])] = dividers[div3][
                        'multiAlign' + core.capitalize(sides[3])
                    ].filter(el => el.id !== sash.id);
                    dividers[div3]['multiAlign' + core.capitalize(sides[3]) + 'Div'] = dividers[
                        div3
                    ]['multiAlign' + core.capitalize(sides[3]) + 'Div'].filter(
                        el => el.id !== dividers[div1].id
                    );
                }
                if (sash.nearMullions[sides[3]] !== -1) {
                    const div4 = core.fId(dividers, sash.nearMullions[sides[3]]);
                    dividers[div4]['multiAlign' + core.capitalize(sides[2])] = dividers[div4][
                        'multiAlign' + core.capitalize(sides[2])
                    ].filter(el => el.id !== sash.id);
                    dividers[div4]['multiAlign' + core.capitalize(sides[2]) + 'Div'] = dividers[
                        div4
                    ]['multiAlign' + core.capitalize(sides[2]) + 'Div'].filter(
                        el => el.id !== dividers[div1].id
                    );
                }

                this.alignmentsService.expandAlignments(
                    conf,
                    sides[2],
                    sides[1],
                    sides[0],
                    fromSash,
                    sash
                );
                this.alignmentsService.expandAlignments(
                    conf,
                    sides[3],
                    sides[1],
                    sides[0],
                    fromSash,
                    sash
                );
                this.alignmentsService.rearrangeAlignments(
                    conf,
                    sides[1],
                    fromSash,
                    dividers[div1].id
                );
                if (rev) {
                    const prop = direction === 'width' ? 'x' : 'y';
                    fromSash['r' + prop] = sash['r' + prop];
                }
                this.removeAlignmentsAroundField(sash, conf);
                this.removeFromEdge(edgeSashes, sash.id);
                core.removeById(sashes, sash.id);
                core.removeById(dividers, sash.nearMullions[sides[0]]);
                return false;
            }
        }
        return search;
    }

    /**
     * Usuwanie skrzydła z boku multi
     * @param  {Object} sash       Skrzydło
     * @param  {Array} sides       Boki
     * @param  {Object} direction  Kierunek
     * @param  {Object} rev        Rev
     * @param  {Object} door       Drzwi
     * @param  {Object} search     Szukanie
     * @param  {Array} sashes      Skrzydła
     * @param  {Array} dividers    Podziały
     * @param  {Array} edgeSashes  Skrzydła krawędzi
     * @param  {Object} internal   Wewnatrz
     * @return {Object}            Szukanie
     */
    private removeSashFromSideMulti(
        sash: ActiveSash,
        sides: ('left' | 'right' | 'top' | 'bottom')[],
        direction: 'width' | 'height',
        rev: boolean,
        search: boolean,
        sashes: WindowActiveConfiguration['Sashes'],
        dividers: WindowActiveConfiguration['Mullions'],
        edgeSashes: WindowActiveConfiguration['EdgeSashes'],
        conf: WindowActiveConfiguration
    ) {
        let prop: 'x' | 'y';
        let prop2: 'Width' | 'Height';
        if (direction === 'width') {
            prop = 'x';
            prop2 = 'Height';
        } else {
            prop = 'y';
            prop2 = 'Width';
        }
        if (search) {
            const div1 = core.fId(dividers, sash.nearMullions[sides[0]]);
            if (
                sash.nearMullions[sides[0]] > -1
                && dividers[div1]['multiAlign' + core.capitalize(sides[0])].length > 1
                && dividers[div1]['multiAlign' + core.capitalize(sides[1])].length === 1
                && dividers[div1]['r' + prop2] === sash['r' + prop2]
            ) {
                const fromArray: ActiveSash[] =
                    dividers[div1]['multiAlign' + core.capitalize(sides[0])];
                this.expandSashes(fromArray, sash, direction, sides[0]);
                this.mullionsLayoutService.expandMullions(
                    dividers[div1]['multiAlign' + core.capitalize(sides[0]) + 'Div'],
                    sash,
                    direction
                );

                Common.forEach(fromArray, fromSash => {
                    fromSash.nearMullions[sides[1]] = -1;
                    if (sash.nearMullions[sides[1]] !== -1) {
                        const div2 = core.fId(dividers, sash.nearMullions[sides[1]]);
                        dividers[div2]['multiAlign' + core.capitalize(sides[0])] = dividers[div2][
                            'multiAlign' + core.capitalize(sides[0])
                        ].filter(el => el.id !== sash.id);
                        dividers[div2]['multiAlign' + core.capitalize(sides[0])].push(fromSash);
                        fromSash.nearMullions[sides[1]] = sash.nearMullions[sides[1]];
                        if (fromSash.nearMullions[sides[3]] !== -1) {
                            const div5 = core.fIdO(dividers, fromSash.nearMullions[sides[3]]);
                            dividers[div2]['multiAlign' + core.capitalize(sides[0]) + 'Div'].push(
                                div5
                            );
                        }
                    } else {
                        edgeSashes[sides[1]].push(fromSash.id);
                    }

                    if (rev) {
                        fromSash['r' + prop] = sash['r' + prop];
                    }
                    this.alignmentsService.expandAlignments(
                        conf,
                        sides[2],
                        sides[1],
                        sides[0],
                        fromSash,
                        sash
                    );
                    this.alignmentsService.expandAlignments(
                        conf,
                        sides[3],
                        sides[1],
                        sides[0],
                        fromSash,
                        sash
                    );
                    this.alignmentsService.rearrangeAlignments(
                        conf,
                        sides[1],
                        fromSash,
                        dividers[div1].id
                    );
                });

                if (rev) {
                    Common.forEach(
                        dividers[div1]['multiAlign' + core.capitalize(sides[0]) + 'Div'],
                        divider => {
                            divider['r' + prop] = sash['r' + prop];
                        }
                    );
                }

                if (sash.nearMullions[sides[2]] !== -1) {
                    const div3 = core.fId(dividers, sash.nearMullions[sides[2]]);
                    dividers[div3]['multiAlign' + core.capitalize(sides[3])] = dividers[div3][
                        'multiAlign' + core.capitalize(sides[3])
                    ].filter(el => el.id !== sash.id);
                    dividers[div3]['multiAlign' + core.capitalize(sides[3]) + 'Div'] = dividers[
                        div3
                    ]['multiAlign' + core.capitalize(sides[3]) + 'Div'].filter(
                        el => el.id !== dividers[div1].id
                    );
                }
                if (sash.nearMullions[sides[3]] !== -1) {
                    const div4 = core.fId(dividers, sash.nearMullions[sides[3]]);
                    dividers[div4]['multiAlign' + core.capitalize(sides[2])] = dividers[div4][
                        'multiAlign' + core.capitalize(sides[2])
                    ].filter(el => el.id !== sash.id);
                    dividers[div4]['multiAlign' + core.capitalize(sides[2]) + 'Div'] = dividers[
                        div4
                    ]['multiAlign' + core.capitalize(sides[2]) + 'Div'].filter(
                        el => el.id !== dividers[div1].id
                    );
                }
                this.removeAlignmentsAroundField(sash, conf);
                this.removeFromEdge(edgeSashes, sash.id);
                core.removeById(sashes, sash.id);
                core.removeById(dividers, sash.nearMullions[sides[0]]);
                return false;
            }
        }
        return search;
    }

    /**
     * Rozszerza skrzydła o sąsiednie skrzydło.
     *
     * @param {Sash[]}             fromArray Skrzydła do rozszerzenia.
     * @param {Sash}               toSash    Skrzydło o które rozszerzamy
     * @param {"height" | "width"} direction Czy rozszerzamy po wysokości czy szerokości
     * @param {boolean}            internal  Czy pole w skrzydle
     * @param {string}             turn      Czy od góry, dołu, lewej, prawej
     */
    private expandSashes(
        fromArray: ActiveSash[],
        toSash: ActiveSash,
        direction: 'width' | 'height',
        turn: 'top' | 'bottom' | 'left' | 'right'
    ) {
        let iES;
        Common.forEach(fromArray, sash => {
            const exSash = sash;

            if (direction === 'height') {
                exSash.rHeight = Math.round(exSash.rHeight + toSash.rHeight);

                if (!Common.isUndefined(exSash.intEdgeSashes)) {
                    if (turn === 'top') {
                        iES = exSash.intEdgeSashes.bottom;
                    }
                    if (turn === 'bottom') {
                        iES = exSash.intEdgeSashes.top;
                    }

                    if (iES.length > 0 || !Common.isUndefined(exSash.intMullions)) {
                        for (let j = 0; j < iES.length; j++) {
                            const isash = core.fIdO(exSash.intSashes, iES[j]);
                            isash.rHeight = Math.round(isash.rHeight + toSash.rHeight);
                            if (isash.nearMullions.right !== -1) {
                                const iDiv =
                                    exSash.intMullions[
                                        core.fId(exSash.intMullions, isash.nearMullions.right)
                                    ];
                                iDiv.rHeight = iDiv.rHeight + toSash.rHeight;
                            }
                        }
                    }
                }
            } else if (direction === 'width') {
                exSash.rWidth = Math.round(exSash.rWidth + toSash.rWidth);

                if (!Common.isUndefined(exSash.intEdgeSashes)) {
                    if (turn === 'left') {
                        iES = exSash.intEdgeSashes.right;
                    }
                    if (turn === 'right') {
                        iES = exSash.intEdgeSashes.left;
                    }

                    if (iES.length > 0 || !Common.isUndefined(exSash.intMullions)) {
                        for (let j = 0; j < iES.length; j++) {
                            const isash = core.fIdO(exSash.intSashes, iES[j]);
                            isash.rWidth = Math.round(isash.rWidth + toSash.rWidth);
                            if (isash.nearMullions.bottom !== -1) {
                                const iDiv =
                                    exSash.intMullions[
                                        core.fId(exSash.intMullions, isash.nearMullions.bottom)
                                    ];
                                iDiv.rWidth = iDiv.rWidth + toSash.rWidth;
                            }
                        }
                    }
                }
            }
        });
    }

    /**
     * Łączy dwa skrzydła
     *
     * @param {Sash}               fromSash  Skrzydło, z którym następuje złączenie
     * @param {Sash}               toSash    Skrzydlo dołączane
     * @param {"height" | "width"} direction Czy rozszerzamy po wysokości czy szerokości
     * @param {boolean}            internal  Czy pole w skrzydle
     * @param {string}             turn      Czy od góry, dołu, lewej, prawej
     */
    private mergeSashes(
        fromSash: ActiveSash,
        toSash: ActiveSash,
        direction: 'height' | 'width',
        turn: 'left' | 'right' | 'top' | 'bottom'
    ) {
        let isash: ActiveSash;
        let iES;
        if (direction === 'height') {
            fromSash.rHeight = Math.round(fromSash.rHeight + toSash.rHeight);

            if (!Common.isUndefined(fromSash.intEdgeSashes)) {
                if (turn === 'top') {
                    iES = fromSash.intEdgeSashes.bottom;
                }
                if (turn === 'bottom') {
                    iES = fromSash.intEdgeSashes.top;
                }

                if (iES.length > 0 || !Common.isUndefined(fromSash.intMullions)) {
                    for (let j = 0; j < iES.length; j++) {
                        isash = core.fIdO(fromSash.intSashes, iES[j]);
                        isash.rHeight = Math.round(isash.rHeight + toSash.rHeight);
                        if (isash.nearMullions && isash.nearMullions.right !== -1) {
                            const iDiv =
                                fromSash.intMullions[
                                    core.fId(fromSash.intMullions, isash.nearMullions.right)
                                ];
                            iDiv.rHeight = iDiv.rHeight + toSash.rHeight;
                        }
                    }
                }
            }
        } else if (direction === 'width') {
            fromSash.rWidth = Math.round(fromSash.rWidth + toSash.rWidth);
            if (Common.isDefined(fromSash.intEdgeSashes)) {
                if (turn === 'left') {
                    iES = fromSash.intEdgeSashes.right;
                }
                if (turn === 'right') {
                    iES = fromSash.intEdgeSashes.left;
                }

                if (iES.length > 0 || Common.isDefined(fromSash.intMullions)) {
                    for (let j = 0; j < iES.length; j++) {
                        isash = core.fIdO(fromSash.intSashes, iES[j]);
                        isash.rWidth = Math.round(isash.rWidth + toSash.rWidth);
                        if (isash.nearMullions && isash.nearMullions.bottom !== -1) {
                            const iDiv =
                                fromSash.intMullions[
                                    core.fId(fromSash.intMullions, isash.nearMullions.bottom)
                                ];
                            iDiv.rWidth = iDiv.rWidth + toSash.rWidth;
                        }
                    }
                }
            }
        }
    }

    /**
     * Usuwa skrzydło z krawędzi.
     *
     * @param {any} edgeSashes Skrzydła na krawędziach
     * @param {any} id         Id skrzydła
     */
    private removeFromEdge(edgeSashes: WindowActiveConfiguration['EdgeSashes'], id: number) {
        edgeSashes.top = edgeSashes.top.filter(el => el !== id);
        edgeSashes.bottom = edgeSashes.bottom.filter(el => el !== id);
        edgeSashes.left = edgeSashes.left.filter(el => el !== id);
        edgeSashes.right = edgeSashes.right.filter(el => el !== id);
    }

    private removeAlignmentsAroundField(field: ActiveSash, conf: WindowActiveConfiguration) {
        let alignments: Alignment[];
        if (Common.isDefined(field.parentId) && field.parentId !== null) {
            const sash = core.fIdO(conf.Sashes, field.parentId);
            alignments = sash.intAlignments;
        } else {
            alignments = conf.Alignments;
        }
        ['left', 'right', 'top', 'bottom'].forEach((side: 'left' | 'right' | 'top' | 'bottom') => {
            const nearAlignment = core.fIdO(alignments, field.nearAlignments[side]);
            if (
                nearAlignment
                && nearAlignment.adjacentSashes[this.reverseSide(side)].length === 1
                && nearAlignment.parallelAlignments[this.reverseSide(side)].length === 0
            ) {
                this.alignmentsService.removeAlignmentInField(field, conf, side);
            }
        });
    }

    private reverseSide(side: 'left' | 'right' | 'top' | 'bottom') {
        switch (side) {
            case 'left':
                return 'right';
            case 'right':
                return 'left';
            case 'top':
                return 'bottom';
            case 'bottom':
                return 'top';
        }
    }
}
