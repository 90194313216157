import { Injectable, Inject } from '@angular/core';
import { TranslateService } from 'translate.service';

@Injectable()
export class BrowserTranslateService extends TranslateService {
    constructor(@Inject('$translate') private $translate: ng.translate.ITranslateService) {
        super();
    }

    instant(
        translationId: string,
        interpolateParams?: any,
        interpolationId?: string,
        forceLanguage?: string,
        sanitizeStrategy?: string
    ): string {
        return this.$translate.instant(
            translationId,
            interpolateParams,
            interpolationId,
            forceLanguage,
            sanitizeStrategy
        );
    }
}
