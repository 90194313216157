import angular from 'angular';

/**
 * Funkcja filtrów typów skrzydeł
 * @param  {object} $rootScope            Angular root scope
 * @param  {object} Core                  Core
 * @param  {object} ConfigurationsService Fabryka konfiguracji
 * @param  {object} CurConfService        Bieżaca konfiguracja
 */
export default function SashTypeFilter($rootScope, Core, ConfigurationsService, CurConfService, IccConfig, ProfilesService) {
    'ngInject';

    return function(sashTypes, sash) {
        var typesArr = [];
        angular.forEach(sashTypes, function(obj) {
            typesArr.push(obj);
        });
        var divs = ConfigurationsService.conf.Current.Mullions;
        var shape = ConfigurationsService.conf.Current.Shape;
        var lDiv = divs[Core.fId(divs, sash.nearMullions.left)];
        var rDiv = divs[Core.fId(divs, sash.nearMullions.right)];

        const conf = ConfigurationsService.conf.Current;
        const minWidthGlass = IccConfig.Configurators.minWidthGlass;
        const profileInner =  ProfilesService.getProfile(conf.ProfileSet['sash']);
        const profileOuter =  ProfilesService.getProfile(conf.ProfileSet['sashOutward']);

        return typesArr.filter(function(type) {
            if (['HS', 'S'].includes(type.type) && !IccConfig.Configurators.hs.layoutEditing) {
                return false;
            }
            if (type.type == 'PSK' && !IccConfig.Configurators.selectPSK) {
                return false;
            }
            if (['DRA', 'DRP', 'DOA', 'DOP'].includes(type.type) && CurConfService.conf == 'window') {
                return false;
            }

            if (type.passive
                && type.handle_position == 'R'
                && (angular.isUndefined(rDiv)
                    || rDiv.multiAlignRight.length != 1
                    || rDiv.multiAlignLeft.length != 1
                )
            ) {
                return false;
            }
            if (type.passive
                && type.handle_position == 'L'
                && (angular.isUndefined(lDiv)
                    || lDiv.multiAlignLeft.length != 1
                    || lDiv.multiAlignRight.length != 1
                )
            ) {
                return false;
            }

            const doorSashTypes = ['DRA', 'DRP', 'DOA', 'DOP', 'F'];
            if (IccConfig.Configurators.fixInFixDoor) {
                doorSashTypes.push('FF');
            }
            if (CurConfService.conf == 'door' && doorSashTypes.indexOf(type.type) === -1) {
                return false;
            }

            if (CurConfService.conf == 'door' && type.type == 'F'
                && sash.nearMullions.top == -1 && sash.nearMullions.bottom == -1
                && sash.nearMullions.left == -1 && sash.nearMullions.right == -1) {
                return false;
            }

            if (CurConfService.conf == 'door' && sash.type.type == 'F' && sash.nearMullions.top == -1 && sash.nearMullions.bottom > -1) {
                return false;
            }

            var allowedTypes;
            var restrict;
            //koło - jedno skrzydło
            if (shape.shape == 'circle'
                && sash.nearMullions.top == -1 && sash.nearMullions.bottom == -1
                && sash.nearMullions.left == -1 && sash.nearMullions.right == -1) {
                allowedTypes = ['F', 'FF', 'OFF','K'];
                restrict = {
                    F  : [150, ],
                    FF : [230, ],
                    OFF: [230, ],
                    K  : [230, 600]
                };
                if (allowedTypes.indexOf(type.type) == -1) {
                    return false;
                }
                if (angular.isDefined(restrict[type.type])
                    && ((angular.isDefined(restrict[type.type][0]) && restrict[type.type][0] > shape.d / 2)
                    || (angular.isDefined(restrict[type.type][1]) && restrict[type.type][1] < shape.d / 2))) {
                    return false;
                }
            }

            //koło - dwa skrzydła ||
            if (shape.shape == 'circle'
                && sash.nearMullions.top == -1 && sash.nearMullions.bottom == -1
                && (sash.nearMullions.left > -1 || sash.nearMullions.right > -1)) {
                allowedTypes = ['F', 'FF', 'OFF'];
                restrict = {
                    F  : [150, ],
                    FF : [230, ],
                    OFF: [230, ]
                };
                if (allowedTypes.indexOf(type.type) == -1) {
                    return false;
                }
                if (angular.isDefined(restrict[type.type])
                    && ((angular.isDefined(restrict[type.type][0]) && restrict[type.type][0] > shape.d / 2)
                    || (angular.isDefined(restrict[type.type][1]) && restrict[type.type][1] < shape.d / 2))) {
                    return false;
                }
            }

            //koło - dwa skrzydła = - skrzydło górne
            if (shape.shape == 'circle'
                && sash.nearMullions.left == -1 && sash.nearMullions.right == -1
                && sash.nearMullions.top == -1 && sash.nearMullions.bottom > -1) {
                allowedTypes = ['F', 'FF', 'OFF', 'K'];
                restrict = {
                    F  : [150, ],
                    FF : [230, ],
                    OFF: [230, ],
                    K  : [230, ]
                };
                if (allowedTypes.indexOf(type.type) == -1) {
                    return false;
                }
                if (angular.isDefined(restrict[type.type])
                    && ((angular.isDefined(restrict[type.type][0]) && restrict[type.type][0] > shape.d / 2)
                    || (angular.isDefined(restrict[type.type][1]) && restrict[type.type][1] < shape.d / 2))) {
                    return false;
                }
            }

            //koło - dwa skrzydła = - skrzydło dolne
            if (shape.shape == 'circle'
                && sash.nearMullions.left == -1 && sash.nearMullions.right == -1
                && sash.nearMullions.bottom == -1 && sash.nearMullions.top > -1) {
                allowedTypes = ['F', 'FF', 'OFF'];
                restrict = {
                    F  : [150, ],
                    FF : [230, ],
                    OFF: [230, ]
                };
                if (allowedTypes.indexOf(type.type) == -1) {
                    return false;
                }
                if (angular.isDefined(restrict[type.type])
                    && ((angular.isDefined(restrict[type.type][0]) && restrict[type.type][0] > shape.d / 2)
                    || (angular.isDefined(restrict[type.type][1]) && restrict[type.type][1] < shape.d / 2))) {
                    return false;
                }
            }

            if (['PSK'].indexOf(type.type) > -1 && sash.shape.shape !== 'rect') {
                return false;
            }

            if (
                IccConfig.Configurators.noHingesOnSlope &&
                ['F', 'FF', 'OFF'].indexOf(type.type) === -1 &&
                ((type.handle_position === 'R' && sash.shape.angles.hasLeftSlopeSide) ||
                    (type.handle_position === 'L' && sash.shape.angles.hasRightSlopeSide))
            ) {
                return false;
            }

            if (conf.drawData && type.type !== 'F') {
                const glazingBeadData = conf.drawData.glazingBead.find(gb => gb.sashId === sash.id);
                const sashData = conf.drawData.sash.find(s => s.sashId === sash.id);

                if (glazingBeadData && sashData) {
                    const minWidthSash = glazingBeadData.inner.rect.y - sashData.outer.rect.y + minWidthGlass + (sashData.outer.rect.y + sashData.outer.rect.height) - ( glazingBeadData.inner.rect.y + glazingBeadData.inner.rect.height);
                    if (profileInner && !type.out_open && sash.rWidth < minWidthSash + profileInner.width * 2) {
                        return false;
                    }

                    if (profileOuter && type.out_open && sash.rWidth < minWidthSash + profileOuter.width * 2) {
                        return false;
                    }
                }
            }

            return true;
        });
    };
}
