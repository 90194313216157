import angular from 'angular';

/**
 * Funkcja Glass Often
 */
export default function GlassOftenFilter() {
    'ngInject';

    return function(glass, often) {
        var glassArr = [];
        angular.forEach(glass, function(obj) {
            glassArr.push(obj);
        });
        if (angular.isUndefined(often) || often === null || often === '' || !~~often) {
            return glassArr;
        } else {
            return glassArr.filter(function(gl) {
                return !!~~gl.selected === !!~~often;
            });
        }
    };
}
