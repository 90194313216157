import angular from 'angular';

/**
 * Funkcja liczenia szyb
 */
export default function GlassCountFilter() {
    'ngInject';

    return function(glass, count) {
        var glassArr = [];
        angular.forEach(glass, function(obj) {
            glassArr.push(obj);
        });
        if (angular.isUndefined(count) || count === null || count === '') {
            return glassArr;
        } else {
            return glassArr.filter(function(gl) {
                return parseInt(count) == parseInt(gl.glazing_count);
            });
        }
    };
}
