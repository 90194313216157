import { NgModule } from '@angular/core';

import PriceColorsService from './price-colors.service';
import PriceCassonettoService from './price-cassonetto.service';
import PriceFittingService from './price-fitting.service';
import PriceAccessoryService from './price-accessory.service';
import PriceBaseService from './price-base.service';
import { DiscountsAndMultipliersService } from './discounts-and-multipliers.service';
import PriceSashService from './price-sash.service';
import PriceSystemService from './price-system.service';
import PriceProfileService from './price-profile.service';
import PriceMuntinsService from './price-muntins.service';
import PriceHandlesService from './price-handles.service';
import PriceGlazingService from './price-glazing.service';
import PriceSealService from './price-seal.service';
import PriceSiliconeService from './price-silicone.service';
import PriceGlazingBeadService from './price-glazing-bead.service';
import PriceMosquitoService from './price-mosquito.service';
import PriceDependenciesService from './price-dependencies.service';
import PriceHandlePositionService from './price-handle-position.service';
import PriceRollerService from './price-roller.service';
import { CommonProfilesModule } from '../profiles/profiles.module';
import PriceDoorService from './price-door.service';
import PriceAwningService from './price-awning.service';
import PriceGarageService from './price-garage.service';
import PriceOuterDoorService from './price-outer-door.service';
import PriceShapeService from './price-shape.service';
import PriceCoupledWindowService from './price-coupled-window.service';

@NgModule({
    imports: [CommonProfilesModule],
    exports: [],
    declarations: [],
    providers: [
        PriceColorsService,
        PriceCassonettoService,
        PriceFittingService,
        PriceAccessoryService,
        PriceBaseService,
        PriceSashService,
        PriceSystemService,
        PriceProfileService,
        PriceMuntinsService,
        PriceHandlesService,
        PriceGlazingService,
        PriceSealService,
        PriceSiliconeService,
        PriceGlazingBeadService,
        PriceMosquitoService,
        PriceDependenciesService,
        PriceHandlePositionService,
        PriceRollerService,
        PriceDoorService,
        PriceAwningService,
        PriceGarageService,
        PriceOuterDoorService,
        PriceShapeService,
        PriceCoupledWindowService,
    ],
})
export class PriceModule {}
