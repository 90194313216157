import angular from 'angular';

import OfferConfiguratorCtrl from './OfferConfiguratorCtrl';
import ModalOfferAddFormCtrl from './ModalOfferAddFormCtrl';
import ModalOfferConfModal from './ModalOfferConfModal';
import EditPositionFactory from './EditPositionFactory';
import ModalOfferHistoryCtrl from './ModalOfferHistoryCtrl';
import ModalPdfOptionsCtrl from './pdf_options/ModalPdfOptionsCtrl';
import PdfService from './PdfService';

export default angular.module('icc.offer', [])
    .controller('OfferConfiguratorCtrl', OfferConfiguratorCtrl)
    .controller('ModalOfferAddFormCtrl', ModalOfferAddFormCtrl)
    .controller('ModalOfferConfModal', ModalOfferConfModal)
    .factory('EditPositionFactory', EditPositionFactory)
    .controller('ModalOfferHistoryCtrl', ModalOfferHistoryCtrl)
    .controller('ModalPdfOptionsCtrl', ModalPdfOptionsCtrl)
    .service('PdfService', PdfService)
    .name;
