/**
 * Kontroler schematów
 * @param {object} $scope         Angular Scope
 * @param {object} $window        Okno
 * @param {object} $rootScope     Angular root scope
 * @param {object} $uibModal         Dostarcza obsługę okien modalnych
 * @param {object} SchemasFactory Fabryka schematów
 */
export default function SchemasCtrl($scope, $window, $rootScope, $uibModal, SchemasFactory, ConfigurationsService, RollerDimensionsFactory) {
    'ngInject';

    var vm = this;

    vm.subStep = 1;
    vm.openModalSystems = SchemasFactory.openModalSystems;
    vm.changeSchema = changeSchema;

    init();

    function init() {
        RollerDimensionsFactory.updateDimensions();
    }

    function changeSchema() {
        SchemasFactory.setShuttersBySchema({nextStep: ConfigurationsService.conf.Current.type === 'roller_shutter'});
    }

    vm.goToSubstep = function(subStep) {
        vm.subStep = subStep;
    };

}
