export default class TutorialsService {

    constructor(
        private $http,
        private UserService,
        private EnvConfig
    ) {
        'ngInject';
        this.init();
    }
    public data = [];
    public tempData = [];
    public sessionData;
    public newPositionFlag = null;
    private user = this.UserService.get() || {};

    /**
     * Pobieranie danych
     */
    async getData() {
        return (await this.$http.get((this.EnvConfig.remoteHost || window.location.origin) + '/window/tutorial_views/index.json')).data.tutorialViews;
    }

    async init() {
        this.tempData = await this.getData();
        if (this.tempData) {
            this.tempData.forEach( el => {
                if ( el.markets.includes(this.user.marketId)) {
                    this.data.push(el);
                }
            });
        }
        this.sessionData = this.getVisited();

    }
    getVisited() {
        if (sessionStorage.getItem('tutorial')) {
            return  JSON.parse(sessionStorage.getItem('tutorial'));
        } else {
            sessionStorage.setItem('tutorial', JSON.stringify({window: [], hs: [], door: [], roller_shutter: []}));
            return  {window: [], hs: [], door: [], roller_shutter: []};
        }
    }
}
