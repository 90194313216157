import angular from 'angular';

/**
 * Fabryka systemow bram garazowych
 * @param {object} $rootScope               Angular RootScope
 * @param {object} $sce                     Sce
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} CurConfService           Bieżaca konfiguracja
 * @param {object} ConfiguratorsDataService Fabryka danych konfiguracji
 * @param {object} StepFactory              Fabryka kolejnych krokow
 * @param {object} PriceService             Liczenie cen
 * @param {object} AccessoriesFactory       Fabryka akcesoriów
 * @param {object} GaragePanelsFactory      Fabryka paneli bram
 * @param {object} GarageColorsFactory      Fabryka kolorów bram
 * @param {object} GarageAccessoriesFactory Fabryka akcesoriów bram
 */
export default function SystemsFactory($rootScope, $sce, ConfigurationsService, CurConfService, ConfiguratorsDataService, // jshint ignore:line
                              StepFactory, PriceService, AccessoriesFactory, GaragePanelsFactory, GarageColorsFactory, GarageAccessoriesFactory, EventBusService) {
    'ngInject';

    var factory = {
        systems         : [],
        loadedData      : false,
        selectSystem    : selectSystem,
        setDefaultValues: setDefaultValues
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });

    return factory;

    /**
     * Funkcja wywołana po zaladowaniu konfiguracji
     */
    function init() {
        if (CurConfService.conf == 'garage') {
            factory.systems = getSystems();
            if (angular.isUndefined(ConfigurationsService.conf.Current.System.id)) {
                factory.setDefaultValues();
            }
            factory.loadedData = true;
        } else {
            return;
        }
    }

    /**
     * Pobranie systemow garazowych
     */
    function getSystems() {
        return ConfiguratorsDataService.data.garageLines.map(function(e) {
            return angular.extend(e, {content: $sce.trustAsHtml(e.content)});
        });
    }

    /**
     * Wybor systemu
     * @param  {object}  system   Wybrany system
     * @param  {boolean} nextStep Czy przejsc do nastepnego kroku
     * @param  {boolean} price    Czy przeliczyc ceny
     */
    function selectSystem(system, nextStep, price) {
        if (CurConfService.conf != 'garage') {
            return;
        }
        ConfigurationsService.conf.Current.System = system;
        ConfigurationsService.conf.Default.System = system;

        ConfigurationsService.conf.Current.Name = system.name;
        ConfigurationsService.conf.Default.Name = system.name;

        ConfigurationsService.conf.Current.Insulation = false;
        ConfigurationsService.conf.Default.Insulation = false;

        AccessoriesFactory.findAccessories();
        GaragePanelsFactory.setDefaultPanel();
        GarageAccessoriesFactory.setDefaultValues();
        GarageColorsFactory.loadColorsBySystem();

        if (nextStep) {
            StepFactory.goToNextStep(true);
        }
        if (price) {
            PriceService.count();
        }

        if (ConfiguratorsDataService.data.garagePanels.filter(function(e) {
            return e.GaragePanel.window_line_id == system.id;
        }).length > 0) {
            StepFactory.enable('fills');
        } else {
            StepFactory.disable('fills');
        }

        if (GarageColorsFactory.garageColors.length) {
            StepFactory.enable('colors');
        } else {
            StepFactory.disable('colors');
        }
    }

    /**
     * Ustawienie domyslnych wartosci po uruchomieniu
     */
    function setDefaultValues() {
        factory.selectSystem(factory.systems[0]);
    }

}
