import { Injectable, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { EventBusService } from 'event-bus.service';
import { LoadedConfiguratorsDataValue } from 'configurators/configurators-data.service';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import Common from 'Common';
import { core } from 'helpers';
import { IssuesService, IssueLevel } from 'issues.service';

@Injectable()
export class GlazingBeadColorsService {
    colors = [];
    colorRals = [];

    private loadedData = false;

    constructor(
        private eventBusService: EventBusService,
        private location: Location,
        @Inject('$uibModal') private $uibModal: ng.ui.bootstrap.IModalService,
        private issuesService: IssuesService
    ) {
        eventBusService.subscribe<LoadedConfiguratorsDataValue>('loadedConfiguratorsData', data => {
            this.loadColors(data.value);
            this.loadedData = true;
        });

        eventBusService.subscribe('setConstructionColor', data => {
            if (
                WindowActiveConfiguration.is(data.activeConfiguration)
                && WindowActiveConfiguration.is(data.defaultConfiguration)
            ) {
                this.setDefaultColor(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
            }
        });
    }

    loadColors(data) {
        this.colors = data.glazingBeadsColors || [];
        this.colorRals = data.windowColorRals || [];
    }

    getDefaultColor() {
        if (this.colors.length === 0) {
            return null;
        }
        if (this.hasRALs()) {
            this.colors = this.colors.concat(this.colorRals);
        }
        const windows = this.colors.filter(el => el.type === 'window');
        const whites = this.colors.filter(
            el => el.type === 'white' || el.color.toUpperCase() === 'FFFFFF'
        );
        if (windows.length > 0) {
            return windows[0];
        } else if (whites.length > 0) {
            return whites[0];
        } else {
            return this.colors[0];
        }
    }

    setDefaultColor(conf: WindowActiveConfiguration, defaultConf) {
        if (!this.loadedData) {
            return;
        }
        if (this.colors.length === 0) {
            this.issuesService.registerDataProblem(
                'no-glazing-bead-colors',
                'Brak kolorów listw przyszybowych!',
                conf,
                {
                    level: IssueLevel.FATAL,
                }
            );
            return;
        }
        this.issuesService.unregister('no-glazing-bead-colors', conf);
        const color = this.getDefaultColor();
        this.setColor(color, conf, defaultConf);
    }

    setColor(color, conf: WindowActiveConfiguration, defaultConf) {
        if (color.type === 'window' && Common.isObject(conf.Colors.frame.inner)) {
            const colorPrice = color.price;
            color =
                conf.Colors.frame.inner && conf.Colors.frame.inner.id
                    ? core.copy(conf.Colors.frame.inner)
                    : core.copy(conf.Colors.frame.core);
            color.type = 'window';
            color.price = colorPrice;
        }
        const systemType = conf.System.type;
        let type;
        if (systemType === 'alu') {
            type = 'Outer';
        } else {
            type = 'Inner';
        }

        const absUrl = this.location.prepareExternalUrl(this.location.path());
        if (
            (color.color !== null && color.color !== '')
            || (color.color_img !== null && color.color_img !== '')
        ) {
            if (color.type === 'window') {
                color.colorUrlFrame = 'url(' + absUrl + '#window' + type + 'FrameColor)';
                color.colorUrlSash = 'url(' + absUrl + '#window' + type + 'SashColor)';
            } else {
                color.colorUrlFrame = 'url(' + absUrl + '#beadColor)';
                color.colorUrlSash = 'url(' + absUrl + '#beadColor)';
            }
        } else {
            color.colorUrlFrame = 'url(' + absUrl + '#window' + type + 'FrameColor)';
            color.colorUrlSash = 'url(' + absUrl + '#window' + type + 'SashColor)';
        }

        conf.GlazingBeadColor = color;
        defaultConf.GlazingBeadColor = color;
        this.eventBusService.post({
            key: 'setGlazingBeadColor',
            value: {
                colorId: color.id,
            },
        });
    }

    openModal(conf: WindowActiveConfiguration, defaultConf) {
        this.$uibModal
            .open({
                templateUrl: 'modalBeadColor.html',
                controller: 'ModalBeadColorCtrl as mbeadcolor',
                resolve: {
                    colors: () => this.colors,
                    colorsRAL: () => this.colorRals,
                    typeColor: () => conf.GlazingBeadColor.type,
                    selColor: () => conf.GlazingBeadColor,
                    hasRal: () => this.hasRALs(),
                    confColor: () => core.copy(conf.Colors.frame.inner),
                },
            })
            .result.then(selectedColor => {
                if (Common.isDefined(selectedColor)) {
                    this.setColor(selectedColor, conf, defaultConf);
                }
            });
    }

    /**
     * Funkcja sprawdzajaca czy sa kolory RAL
     * @return {bool} Sprawdza czy są RALe pośród kolorów listew przyszybowych.
     */
    private hasRALs() {
        return this.colors.filter(el => el.type === 'RAL').length > 0;
    }
}
