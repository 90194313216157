import io from 'socket.io-client';
import {logger, core} from 'helpers';
import {Injectable, Inject} from '@angular/core';
import {Location} from '@angular/common';

import {TranslateService} from 'translate.service';
import ResourceService from './resource.service';
import {APP_CONFIG, AppConfig} from 'config';
import {EventBusService} from 'event-bus.service';
import StateService from 'state.service';
import TokenService from '../authorization/token.service';
import {SocketService} from './socket.service';

@Injectable()
export default class OnlineStatusService {

    status = false;
    lock = false;
    logged = 1;

    socket: SocketIO.Socket;

    constructor(
        private TranslateService: TranslateService,
        private ResourceService: ResourceService,
        private EventBusService: EventBusService,
        private TokenService: TokenService,
        private location: Location,
        @Inject(APP_CONFIG) protected config: AppConfig,
        SocketService: SocketService
    ) {
        this.socket = SocketService.socket;
        this.checkOnlineCon();
    }

    /**
     * Pobranie statusu
     * @return {boolean} Status
     */
    getStatus() {
        return this.status;
    }

    /**
     * Pobranie informacji o zalogowaniu
     * @return {number} Informacja o zalogowaniu
     */
    getLogged() {
        return this.logged;
    }

    /**
     * Ustawienie nowego statusu
     * @param {boolean} newStatus Nowy status
     */
    setStatus(newStatus) {
        if (!this.lock) {
            const oldStatus = this.status;
            this.status = newStatus;
            this.setOptions();
            logger.isOffline = !newStatus;
            if (oldStatus === false && newStatus === true) {
                logger.onOnline();
            }
        }
    }

    /**
     * Ustawienie informacji o zalogowaniu
     * @param {number} newLogged Informacja o zalogowaniu
     */
    setLogged(newLogged) {
        this.logged = newLogged;
        this.setOptions();
    }

    setPermanentOffline() {
        this.setStatus(false);
        this.lock = true;
        this.logOnline();
    }

    /**
     * Ustawienie opcji
     */
    setOptions() {
        if (this.status || this.config.isNative) {
            window.onbeforeunload = null;
        } else {
            window.onbeforeunload = () => this.TranslateService.instant('SYNCHRONIZE|Sprawdź czy masz połączenie z internetem?');
        }
    }

    pingHandler(data) {
        this.setStatus(true);
        if (data.logged === true) {
            this.setLogged(3);
        } else {
            this.setLogged(2);
        }
        this.logOnline();
    }

    /**
     * Sprawdzenie czy jesteśmy online. Wywoływane co 3 sekundy.
     * @return {object} Promise
     */
    checkOnline() {
        return new Promise<boolean>(resolve => {
            this.ResourceService.online().then((data) => {
                if (data) {
                    this.pingHandler(data);
                } else {
                    this.setStatus(false);
                }
                resolve(this.status);
            }).catch(() => {
                this.setStatus(false);
                this.setLogged(1);
                resolve(this.status);
            });
        });
    }

    /**
     * Sprawdzenie czy jesteśmy online. Wywoływane co 3 sekundy.
     */
    logOnline() {
        if (this.getStatus()) {
            if (this.getLogged() === 3) {
                logger.info('%conline %c zalogowany', 'background:green;font-size:14px;color:white', 'background:green;font-size:14px;color:white');
            } else if (this.getLogged() === 2) {
                logger.info('%conline %c niezalogowany', 'background:green;font-size:14px;color:white', 'background:red;font-size:14px;color:white');
            } else {
                logger.info('%conline %c oczekiwanie', 'background:green;font-size:14px;color:white', 'background:orange;font-size:14px;color:white');
            }
        } else {
            logger.info('%coffline', 'background:red;font-size:14px;color:white');
        }
    }

    /**
     * Wywoływanie sprawdzania czy jesteśmy online, co 3 sekundy
     * @return {object} Promise
     */
    checkOnlineCon() {
        const that = this;
        this.socket.on('disconnect', () => {
            that.setStatus(false);
            that.setLogged(1);
            that.logOnline();
        });

        this.socket.on('error', () => {
            that.setStatus(false);
            that.setLogged(1);
            that.logOnline();
        });

        this.socket.on('reconnect_error', () => {
            that.setStatus(false);
            that.setLogged(1);
            that.logOnline();
        });

        this.socket.on('reconnect_failed', () => {
            that.setStatus(false);
            that.setLogged(1);
            that.logOnline();
        });

        this.socket.on('reconnecting', () => {
            that.setStatus(false);
            that.setLogged(1);
            that.logOnline();
        });

        this.socket.on('connect', () => {
            that.setStatus(true);
            that.setLogged(1);
            that.logOnline();
            that.EventBusService.post({key: 'connected', value: null});
        });

        this.socket.on('reconnect', () => {
            that.setStatus(true);
            that.setLogged(1);
            that.logOnline();
            that.EventBusService.post({key: 'connected', value: null});
        });

        this.socket.on('logged', this.pingHandler.bind(this));

        this.socket.on('offerUsersChanged', usersOnOffer => {
            this.EventBusService.post({
                key: 'offerUsersChanged',
                value: usersOnOffer
            });
        });
    }

    /**
     * Próba przejścia do adresu online
     * @param  {string} url URL
     */
    goOnline(url, openInDefaultBrowser = false) {
        if (!(core.isWorker())) {
            if (this.getStatus()) {
                if (this.config.isPhonegap && url !== '/' && !url.includes('/app') && !url.match(/^https?:\/\//)) {
                    url = remoteHost + url;
                }
                if (openInDefaultBrowser && this.config.isElectron) {
                    window.ipc.send('open-url', url);
                } else if (url.match(/^https?:\/\//)) {
                    window.location.href = url + (this.config.isNative ? '/?token=' + this.TokenService.getToken() : '');
                } else if (this.config.isNative && (url === '/' || url.includes('/app'))) {
                    this.location.go(url);
                } else {
                    window.location.pathname = url + (this.config.isNative && url !== '/' && !url.includes('/app') ? '/?token=' + this.TokenService.getToken() : '');
                }
            } else if (this.config.isElectron && (url === '/' || url.includes('/app'))) {
                this.location.go(url);
            } else {
                alert(this.TranslateService.instant('SYNCHRONIZE|Ten moduł jest dostępny tylko online. Połącz się z internetem, aby do niego przejść.'));
            }
        }
    }
}
