import $ from 'jquery';

export default function ModalDecoPanelOptionsCtrl($scope, $uibModalInstance, $timeout, fillings, fillingsCategories,
                                modalData, selGlazing, panelType, glassCatFilter, filling, filteredFillings, ScrollbarService) {
    'ngInject';

    var vm = this;
    vm.panelsGlazings = fillings.filter(filling => filling.type == 'panels_glazing');
    vm.filteredFillings = filteredFillings;
    vm.selGlazing = selGlazing;
    vm.glassTab = modalData.glassTab;
    vm.categories = {
        panelsGlazings: fillingsCategories.filter(cat =>
            cat.type == 'panels_glazing' && cat.parent_id === null && glassCatFilter(vm.panelsGlazings, cat.id, null).length > 0)
    };
    vm.selectedCategory = modalData.selectedCategory;
    vm.panelType = panelType;
    vm.filling = filling;
    vm.selectedFilling = filling;
    vm.changeFilling = changeFilling;

    vm.refresh = refresh;
    vm.selectGlass = selectGlass;
    vm.close = close;

    /**
      * Funkcja zmieniająca wypełnienie
      */
    function changeFilling(filling) {
        vm.selectedFilling = filling;
    }

    /**
     * Funkcja odświeżajaca
     */
    function refresh() {
        ScrollbarService.update();
    }

    /**
     * Funkcja wybierajaca szybe
     * @param  {object} glazing Typ szkła
     */
    function selectGlass(glazing) {
        vm.selGlazing = glazing;
    }

    /**
     * Funkcja zamykajaca okno modal
     */
    function close() {
        $uibModalInstance.close({
            selGlazing      : vm.selGlazing,
            panelType       : vm.panelType,
            selectedCategory: vm.selectedCategory,
            filling         : vm.selectedFilling,
        });
    }
}
