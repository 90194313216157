import {DEBUG} from './helpers';
/* tslint:disable */
const oldConsole = window.console;
/**
 * Funkcja wyłaczajca konsolę
 */
function disableConsole() {
    'use strict';
    window.console.log = function() {};
    window.console.debug = function() {};
    window.console.info = function() {};
    window.console.warn = function() {};
    window.console.clear = function() {};
    window.console.count = function() {};
    window.console.dir = function() {};
    window.console.dirxml = function() {};
    window.console.group = function() {};
    window.console.groupCollapsed = function() {};
    window.console.groupEnd = function() {};
    window.console.profile = function() {};
    window.console.profileEnd = function() {};
    window.console.time = function() {};
    window.console.timeEnd = function() {};
    window.console.trace = function() {};
    window.console.table = function() {};
}

/**
 * Właczajaca konsole
 */
function enableConsole() {
    'use strict';
    (<any>window).console = oldConsole;
}


if (!DEBUG) {
    disableConsole();
}
