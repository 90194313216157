import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from 'config';
import { EventBusService } from 'event-bus.service';
import ParametersService from 'configurators/parameters.service';
import PriceService from 'price/price.service';
import BrowserShapeService from './shape.service';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import { core } from 'helpers';
import Common from 'Common';
import { AlignmentsService } from './alignments.service';
import { ActiveSash } from 'layout/active-sash';
import { BrowserFramesService } from './frames.service';
import { Frame } from 'configurations/parts/window';

@Injectable()
export class ResizeService {
    minWidth: number = this.config.IccConfig.Configurators.minWidth;

    constructor(
        @Inject(APP_CONFIG) private config: AppConfig,
        private eventBusService: EventBusService,
        @Inject('WarrantyFactory') private warrantyFactory,
        private parametersService: ParametersService,
        private priceService: PriceService,
        @Inject('GlazingFactory') private glazingFactory,
        private shapeService: BrowserShapeService,
        private alignmentsService: AlignmentsService,
        private framesService: BrowserFramesService,
        @Inject('ConstructionLimitationFactory') private constructionLimitationFactory,
        @Inject('LayoutFactory') private layoutFactory
    ) {}

    /**
     * Skaluje układ
     *
     * @param {any} widthDiff  Różnica w szerokości
     * @param {any} heightDiff Różnica w wysokości
     */
    resizeLayout(widthDiff: number, heightDiff: number, conf: WindowActiveConfiguration) {
        const sides = ['left', 'right', 'top', 'bottom'];

        const wDiff = Math.floor(widthDiff / 2);
        const hDiff = Math.floor(heightDiff / 2);
        const oldWidth = conf.Width - widthDiff;
        const oldHeight = conf.Height - heightDiff;
        const frames = conf.Frames;
        const sashes = conf.Sashes;
        const divs = conf.Mullions;
        const layout = conf.Layout;
        const canBeResized = sides.every(side =>
            conf.EdgeSashes[side]
                .map(sashId => core.fIdO(sashes, sashId))
                .every(
                    finded =>
                        ((side === 'left' || side === 'right')
                            && finded.rWidth + wDiff >= this.minWidth)
                        || ((side === 'top' || side === 'bottom')
                            && finded.rHeight + hDiff >= this.minWidth)
                )
        );

        if (widthDiff !== 0 || heightDiff !== 0) {
            if (canBeResized) {
                if (Common.isDefined(layout.changed) && !layout.changed) {
                    this.layoutFactory.resetDimensions(conf.Layout);
                } else {
                    let corW = 0;
                    let corH = 0;
                    for (let i = 0; i < sashes.length; i++) {
                        const finded = sashes[i];
                        const frame = conf.Frames.find(f => f.id === finded.frameId);
                        if (
                            wDiff !== 0
                            || ((widthDiff % 2 === 1 || widthDiff % 2 === -1)
                                && frame.x + finded.rx + finded.rWidth === oldWidth)
                        ) {
                            corW = 0;
                            if (
                                (widthDiff % 2 === 1 || widthDiff % 2 === -1)
                                && frame.x + finded.rx + finded.rWidth === oldWidth
                            ) {
                                corW = 1;
                            }
                        }
                        if (
                            hDiff !== 0
                            || ((heightDiff % 2 === 1 || heightDiff % 2 === -1)
                                && frame.y + finded.ry + finded.rHeight === oldHeight)
                        ) {
                            corH = 0;
                            if (
                                (heightDiff % 2 === 1 || heightDiff % 2 === -1)
                                && frame.y + finded.ry + finded.rHeight === oldHeight
                            ) {
                                corH = 1;
                            }
                        }
                        this.resizeInternal(
                            finded,
                            frame,
                            wDiff,
                            hDiff,
                            corW,
                            corH,
                            oldWidth,
                            oldHeight,
                            conf
                        );
                        if (
                            wDiff !== 0
                            || ((widthDiff % 2 === 1 || widthDiff % 2 === -1)
                                && frame.x + finded.rx + finded.rWidth === oldWidth)
                        ) {
                            if (
                                frame.x + finded.rx === 0
                                && frame.x + finded.rx + finded.rWidth === oldWidth
                            ) {
                                finded.rWidth += 2 * wDiff + corW;
                            } else if (frame.x + finded.rx === 0) {
                                finded.rWidth += wDiff;
                            } else if (frame.x + finded.rx + finded.rWidth === oldWidth) {
                                finded.rWidth += wDiff + corW;
                                if (frame.x === 0) {
                                    finded.rx += wDiff;
                                }
                            } else if (frame.x === 0) {
                                finded.rx += wDiff;
                            }
                        }
                        if (
                            hDiff !== 0
                            || ((heightDiff % 2 === 1 || heightDiff % 2 === -1)
                                && frame.y + finded.ry + finded.rHeight === oldHeight)
                        ) {
                            if (
                                frame.y + finded.ry === 0
                                && frame.y + finded.ry + finded.rHeight === oldHeight
                            ) {
                                finded.rHeight += 2 * hDiff + corH;
                            } else if (frame.y + finded.ry === 0) {
                                finded.rHeight += hDiff;
                            } else if (frame.y + finded.ry + finded.rHeight === oldHeight) {
                                finded.rHeight += hDiff + corH;
                                if (frame.y === 0) {
                                    finded.ry += hDiff;
                                }
                            } else if (frame.y === 0) {
                                finded.ry += hDiff;
                            }
                        }
                        ['left', 'right', 'top', 'bottom'].forEach(side =>
                            this.alignmentsService.rearrangeAlignments(conf, side, finded)
                        );
                    }

                    frames.forEach(frame => {
                        if (
                            wDiff !== 0
                            || ((widthDiff % 2 === 1 || widthDiff % 2 === -1)
                                && frame.x + frame.width === oldWidth)
                        ) {
                            let cor = 0;
                            if (
                                (widthDiff % 2 === 1 || widthDiff % 2 === -1)
                                && frame.x + frame.width === oldWidth
                            ) {
                                cor = 1;
                            }
                            if (frame.x === 0 && frame.x + frame.width === oldWidth) {
                                frame.width += 2 * wDiff + cor;
                            } else if (frame.x === 0) {
                                frame.width += wDiff;
                            } else if (frame.x + frame.width === oldWidth) {
                                frame.width += wDiff + cor;
                                frame.x += wDiff;
                            } else {
                                frame.x += wDiff;
                            }
                        }
                        if (
                            hDiff !== 0
                            || ((heightDiff % 2 === 1 || heightDiff % 2 === -1)
                                && frame.y + frame.height === oldHeight)
                        ) {
                            let cor = 0;
                            if (
                                (heightDiff % 2 === 1 || heightDiff % 2 === -1)
                                && frame.y + frame.height === oldHeight
                            ) {
                                cor = 1;
                            }

                            if (frame.y === 0 && frame.y + frame.height === oldHeight) {
                                frame.height += 2 * hDiff + cor;
                            } else if (frame.y === 0) {
                                frame.height += hDiff;
                            } else if (frame.y + frame.height === oldHeight) {
                                frame.height += hDiff + cor;
                                frame.y += hDiff;
                            } else {
                                frame.y += hDiff;
                            }
                        }
                    });

                    for (let i = 0; i < divs.length; i++) {
                        const div = divs[i];
                        const frame = conf.Frames.find(f => f.id === div.frameId);
                        if (div.direction === 'vertical') {
                            if (div.multiAlignLeft.length > 0) {
                                div.rx = div.multiAlignLeft[0].rx + div.multiAlignLeft[0].rWidth;
                                const findedL = div.multiAlignLeft.filter(
                                    el => frame.y + el.ry === 0
                                );
                                const findedR = div.multiAlignLeft.filter(
                                    el => frame.y + el.ry + el.rHeight === conf.Height
                                );
                                let cor = 0;
                                if (
                                    (heightDiff % 2 === 1 || heightDiff % 2 === -1)
                                    && findedR.length > 0
                                ) {
                                    cor = 1;
                                }
                                if (findedL.length > 0 && findedR.length > 0) {
                                    div.rHeight += 2 * hDiff + cor;
                                    div.ry = findedL[0].ry;
                                } else if (findedL.length > 0) {
                                    div.rHeight += hDiff;
                                    div.ry = findedL[0].ry;
                                } else if (findedR.length > 0) {
                                    div.rHeight += hDiff + cor;
                                    let minR = div.multiAlignLeft[0].ry;
                                    for (let k = 0; k < div.multiAlignLeft.length; k++) {
                                        if (minR > div.multiAlignLeft[k].ry) {
                                            minR = div.multiAlignLeft[k].ry;
                                            break;
                                        }
                                    }
                                    div.ry = minR;
                                } else {
                                    let minR = div.multiAlignLeft[0].ry;
                                    for (let k = 0; k < div.multiAlignLeft.length; k++) {
                                        if (minR > div.multiAlignLeft[k].ry) {
                                            minR = div.multiAlignLeft[k].ry;
                                            break;
                                        }
                                    }
                                    div.ry = minR;
                                }
                            }
                        } else {
                            if (div.multiAlignTop.length > 0) {
                                div.ry = div.multiAlignTop[0].ry + div.multiAlignTop[0].rHeight;
                                const findedT = div.multiAlignTop.filter(
                                    el => frame.x + el.rx === 0
                                );
                                const findedB = div.multiAlignTop.filter(
                                    el => frame.x + el.rx + el.rWidth === conf.Width
                                );
                                let cor = 0;
                                if (
                                    (widthDiff % 2 === 1 || widthDiff % 2 === -1)
                                    && findedB.length > 0
                                ) {
                                    cor = 1;
                                }
                                if (findedT.length > 0 && findedB.length > 0) {
                                    div.rWidth += 2 * wDiff + cor;
                                    div.rx = findedT[0].rx;
                                } else if (findedT.length > 0) {
                                    div.rWidth += wDiff;
                                    div.rx = findedT[0].rx;
                                } else if (findedB.length > 0) {
                                    div.rWidth += wDiff + cor;
                                    let minR = div.multiAlignTop[0].rx;
                                    for (let k = 0; k < div.multiAlignTop.length; k++) {
                                        if (minR > div.multiAlignTop[k].rx) {
                                            minR = div.multiAlignTop[k].rx;
                                            break;
                                        }
                                    }
                                    div.rx = minR;
                                } else {
                                    let minR = div.multiAlignTop[0].rx;
                                    for (let k = 0; k < div.multiAlignTop.length; k++) {
                                        if (minR > div.multiAlignTop[k].rx) {
                                            minR = div.multiAlignTop[k].rx;
                                            break;
                                        }
                                    }
                                    div.rx = minR;
                                }
                            }
                        }
                    }
                    for (const coupling of conf.couplings) {
                        if (coupling.direction === 'vertical') {
                            let cor = 0;
                            if (heightDiff % 2 === 1 || heightDiff % 2 === -1) {
                                cor = 1;
                            }
                            coupling.length += 2 * hDiff + cor;
                        } else {
                            let cor = 0;
                            if (widthDiff % 2 === 1 || widthDiff % 2 === -1) {
                                cor = 1;
                            }
                            coupling.length += 2 * wDiff + cor;
                        }
                    }

                    layout.changed = true;
                }
            } else {
                conf.EdgeSashes = {
                    left: [],
                    right: [],
                    top: [],
                    bottom: [],
                };
                conf.Sashes = [];
                conf.Frames = [];
                this.layoutFactory.prepareWindowSashesData(conf.Layout);
            }
            if (IccConfig.Configurators.dependencies) {
                this.eventBusService.post({ key: 'processDependencies', value: null });
            }
            this.shapeService.setShapes(conf);
            this.eventBusService.post({
                key: 'changedSashes',
                value: {},
            });
            this.constructionLimitationFactory.findReinforcement(conf);
            this.shapeService.setShapes(conf);
            this.priceService.count();
            this.warrantyFactory.check(conf);
            this.parametersService.count(conf);
        }
    }

    /**
     * Skaluje pole
     *
     * @param {Sash}   sash     Pole
     * @param {number} wDiff    Różnica w szerokości w px
     * @param {number} hDiff    Różnica w wysokości  w px
     * @param {number} corW     Poprawka do szerokości
     * @param {number} corH     Poprawka do wysokości
     */
    private resizeInternal(
        sash: ActiveSash,
        frame: Frame,
        wDiff: number,
        hDiff: number,
        corW: number,
        corH: number,
        oldWidth: number,
        oldHeight: number,
        conf: WindowActiveConfiguration
    ) {
        const sashes = sash.intSashes;
        const divs = sash.intMullions;
        for (let i = 0; i < sashes.length; i++) {
            const finded = sashes[i];
            if (
                (wDiff !== 0 || corW > 0)
                && (frame.x + sash.rx === 0 || frame.x + sash.rx + sash.rWidth === oldWidth)
            ) {
                if (
                    frame.x + sash.rx + finded.rx === 0
                    && frame.x + sash.rx + finded.rx + finded.rWidth === oldWidth
                ) {
                    finded.rWidth += 2 * wDiff + corW;
                } else if (frame.x + sash.rx + finded.rx === 0) {
                    finded.rWidth += wDiff + corW;
                } else if (frame.x + sash.rx + finded.rx + finded.rWidth === oldWidth) {
                    finded.rWidth += wDiff + corW;
                    if (frame.x + sash.rx === 0) {
                        finded.rx += wDiff;
                    }
                } else if (frame.x + sash.rx === 0) {
                    finded.rx += wDiff;
                }
            }
            if (
                (hDiff !== 0 || corH > 0)
                && (frame.y + sash.ry === 0 || frame.y + sash.ry + sash.rHeight === oldHeight)
            ) {
                if (
                    frame.y + sash.ry + finded.ry === 0
                    && frame.y + sash.ry + finded.ry + finded.rHeight === oldHeight
                ) {
                    finded.rHeight += 2 * hDiff + corH;
                } else if (frame.y + sash.ry + finded.ry === 0) {
                    finded.rHeight += hDiff + corH;
                } else if (frame.y + sash.ry + finded.ry + finded.rHeight === oldHeight) {
                    finded.rHeight += hDiff + corH;
                    if (frame.y + sash.ry === 0) {
                        finded.ry += hDiff;
                    }
                } else if (frame.y + sash.ry === 0) {
                    finded.ry += hDiff;
                }
            }
            ['left', 'right', 'top', 'bottom'].forEach(side =>
                this.alignmentsService.rearrangeAlignments(conf, side, finded)
            );
        }
        for (let i = 0; i < divs.length; i++) {
            const div = divs[i];
            if (div.direction === 'vertical') {
                if (div.multiAlignLeft.length > 0) {
                    div.rx = div.multiAlignLeft[0].rx + div.multiAlignLeft[0].rWidth;
                    const findedL = div.multiAlignLeft.filter(el => el.nearMullions.top === -1);
                    const findedR = div.multiAlignLeft.filter(el => el.nearMullions.bottom === -1);
                    if (
                        findedL.length > 0
                        && findedR.length > 0
                        && frame.y + sash.ry === 0
                        && frame.y + sash.ry + sash.rHeight === oldHeight
                    ) {
                        div.rHeight += 2 * hDiff + corH;
                        div.ry = findedL[0].ry;
                    } else if (findedL.length > 0 && frame.y + sash.ry === 0) {
                        div.rHeight += hDiff;
                        div.ry = findedL[0].ry;
                    } else if (
                        findedR.length > 0
                        && frame.y + sash.ry + sash.rHeight === oldHeight
                    ) {
                        div.rHeight += hDiff;
                        let minR = div.multiAlignLeft[0].ry;
                        for (let k = 0; k < div.multiAlignLeft.length; k++) {
                            if (minR > div.multiAlignLeft[k].ry) {
                                minR = div.multiAlignLeft[k].ry;
                                break;
                            }
                        }
                        div.ry = minR;
                    } else {
                        let minR = div.multiAlignLeft[0].ry;
                        for (let k = 0; k < div.multiAlignLeft.length; k++) {
                            if (minR > div.multiAlignLeft[k].ry) {
                                minR = div.multiAlignLeft[k].ry;
                                break;
                            }
                        }
                        div.ry = minR;
                    }
                }
            } else {
                if (div.multiAlignTop.length > 0) {
                    div.ry = div.multiAlignTop[0].ry + div.multiAlignTop[0].rHeight;
                    const findedT = div.multiAlignTop.filter(el => el.nearMullions.left === -1);
                    const findedB = div.multiAlignTop.filter(el => el.nearMullions.right === -1);
                    if (
                        findedT.length > 0
                        && findedB.length > 0
                        && frame.x + sash.rx === 0
                        && frame.x + sash.rx + sash.rWidth === oldWidth
                    ) {
                        div.rWidth += 2 * wDiff + corW;
                        div.rx = findedT[0].rx;
                    } else if (findedT.length > 0 && frame.x + sash.rx === 0) {
                        div.rWidth += wDiff;
                        div.rx = findedT[0].rx;
                    } else if (findedB.length > 0 && frame.x + sash.rx + sash.rWidth === oldWidth) {
                        div.rWidth += wDiff;
                        let minR = div.multiAlignTop[0].rx;
                        for (let k = 0; k < div.multiAlignTop.length; k++) {
                            if (minR > div.multiAlignTop[k].rx) {
                                minR = div.multiAlignTop[k].rx;
                                break;
                            }
                        }
                        div.rx = minR;
                    } else {
                        let minR = div.multiAlignTop[0].rx;
                        for (let k = 0; k < div.multiAlignTop.length; k++) {
                            if (minR > div.multiAlignTop[k].rx) {
                                minR = div.multiAlignTop[k].rx;
                                break;
                            }
                        }
                        div.rx = minR;
                    }
                }
            }
        }
    }
}
