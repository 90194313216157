import { Injectable, Inject } from '@angular/core';
import { EventBusService } from 'event-bus.service';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import { LoadedConfiguratorsDataValue } from 'configurators/configurators-data.service';
import { core } from 'helpers';
import Common from 'Common';
import { IssuesService, IssueLevel } from 'issues.service';
import { ValidationService } from 'configurators/validation.service';
import { APP_CONFIG, AppConfig } from 'config';
import SiliconeColorsDefaultsService from 'colors/silicone-colors-defaults.service';

@Injectable()
export class SiliconeColorsService {
    colors = [];

    private loadedData = false;

    constructor(
        private eventBusService: EventBusService,
        @Inject('$uibModal') private $uibModal: ng.ui.bootstrap.IModalService,
        @Inject(APP_CONFIG) private config: AppConfig,
        private issuesService: IssuesService,
        private validationService: ValidationService,
        private siliconeColorsDefaultsService: SiliconeColorsDefaultsService,
        @Inject('$translate') private $translate
    ) {
        if (this.config.IccConfig.Configurators.siliconeColor) {
            eventBusService.subscribe<LoadedConfiguratorsDataValue>('setSystem', data => {
                if (
                    (data.activeConfiguration as WindowActiveConfiguration).SystemType.static
                    !== 'wood'
                ) {
                    this.issuesService.unregister('no-silicone-colors', data.activeConfiguration);
                }
            });
            eventBusService.subscribe<LoadedConfiguratorsDataValue>(
                'setConstructionColor',
                data => {
                    if (
                        WindowActiveConfiguration.is(data.activeConfiguration)
                        && WindowActiveConfiguration.is(data.defaultConfiguration)
                    ) {
                        this.setDefaultColor(data.activeConfiguration, data.defaultConfiguration);
                    }
                }
            );

            eventBusService.subscribe<LoadedConfiguratorsDataValue>(
                'loadedConfiguratorsData',
                data => {
                    this.loadColors(data.value);
                    this.loadedData = true;
                }
            );
        }
    }

    loadColors(data) {
        this.colors = data.windowSiliconeColors || [];
    }

    setDefaultColor(conf: WindowActiveConfiguration, defaultConf) {
        if (!this.loadedData || conf.SystemType.static !== 'wood') {
            return;
        }
        this.issuesService.unregister('no-silicone-colors', conf);
        if (this.colors.length === 0) {
            this.issuesService.simpleRegister(
                'no-silicone-colors',
                'Brak kolorów silikonów!',
                this.$translate.instant('WINDOW|Brak kolorów silikonów!'),
                conf,
                {
                    level: IssueLevel.ERROR,
                    logLevel: IssueLevel.WARN,
                    showMessage: true,
                }
            );
            this.validationService.invalid(conf, 'siliconeColor');
            return;
        }
        const shouldBeChanged =
            !Common.isObject(conf.SiliconeColor)
            || !conf.SiliconeColor.id
            || conf.SiliconeColor.isDefault;
        if (shouldBeChanged) {
            const defaultColor = this.siliconeColorsDefaultsService.getDefaultColor(
                this.colors,
                conf,
                defaultConf
            );
            this.setColor(defaultColor, true, conf, defaultConf);
        } else {
            this.validationService.valid(conf, 'siliconeColor');
        }
    }

    setColor(color, isDefault = false, conf: WindowActiveConfiguration, defaultConf) {
        color = core.copy(color);
        color.isDefault = isDefault;
        conf.SiliconeColor = core.copy(color);
        defaultConf.SiliconeColor = color;
        this.validationService.valid(conf, 'siliconeColor');
        this.eventBusService.post({
            key: 'setSiliconeColor',
            value: {
                colorId: color.id,
            },
        });
    }

    openModal(conf: WindowActiveConfiguration, defaultConf) {
        this.$uibModal
            .open({
                templateUrl: 'modalSiliconeColor.html',
                controller: 'ModalSiliconeColorCtrl as msiliconecolor',
                resolve: {
                    colors: () => this.colors,
                    selColor: () => conf.SiliconeColor,
                },
            })
            .result.then(selectedColor => {
                if (selectedColor) {
                    this.setColor(selectedColor, false, conf, defaultConf);
                }
            });
    }
}
