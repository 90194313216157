import { ActiveConfiguration } from './ActiveConfiguration';

import WindowActiveConfiguration from './WindowActiveConfiguration';
import HSConfiguration from './HSConfiguration';
import { ConfiguratorsDataService } from '../configurators/configurators-data.service';

export default class HSActiveConfiguration extends WindowActiveConfiguration
    implements ActiveConfiguration {
    public static is(configuration): configuration is HSActiveConfiguration {
        return configuration instanceof HSActiveConfiguration || !configuration.$version;
    }
    type: 'hs' | 'folding_door' = 'hs';

    Height = 2000;
    Width = 2000;

    SashesType: 'Fix' | 'FixSash' | 'Func' | 'Mix' = 'Func';

    constructor(configuration?, configuratorsDataService?: ConfiguratorsDataService) {
        super(configuration, configuratorsDataService);
        if (configuration) {
            if (HSActiveConfiguration.is(configuration)) {
                for (const propName in configuration) {
                    if (this.hasOwnProperty(propName)) {
                        this[propName] = configuration[propName];
                    }
                }
            } else {
                if (HSConfiguration.is(configuration) && configuratorsDataService) {
                    this.Height = configuration.height;
                    this.SashesType = configuration.sashesType;
                    this.Width = configuration.width;
                }
            }
        }
    }
}
