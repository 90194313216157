import angular from 'angular';

/**
 * Fabryka typeow bram garazowych
 * @param {object} $rootScope               Angular Root Scope
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} CurConfService           Bieżaca konfiguracji
 * @param {object} ConfiguratorsDataService Fabryka danych konfiguracji
 * @param {object} PriceService             Liczenie cen
 */
export default function MontagesFactory($rootScope, ConfigurationsService, CurConfService, ConfiguratorsDataService, PriceService, EventBusService, Core) {
    'ngInject';

    var factory = {
        montages                 : [],
        loadedData               : false,
        getMontages              : getMontages,
        getWindowLines           : getWindowLines,
        selectMontage            : selectMontage,
        setDefaultValues         : setDefaultValues,
        setDefaultMountinPosition: setDefaultMountinPosition,
        selectProfile            : selectProfile,
        setLine                  : setLine,
        profilePositions         : ['up', 'dow', 'left', 'right'],
        windowLines              : {}
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });

    return factory;

    /**
     * Funkcja wywołana po zaladowaniu konfiguracji
     */
    function init() {
        if (CurConfService.conf == 'mosquito') {
            factory.montages = getMontages();
            if (
                (
                    (angular.isObject(ConfigurationsService.conf.Current.Montage)
                    && angular.isUndefined(ConfigurationsService.conf.Current.Montage.id))
                    || ConfigurationsService.conf.Current.Montage === null
                ) && (
                    !angular.isObject(ConfigurationsService.conf.Current.WindowLine)
                    || angular.isUndefined(ConfigurationsService.conf.Current.WindowLine.id)
                ) && (
                    !ConfigurationsService.conf.Current.HoldersWidth
                )
            ) {
                factory.setDefaultValues();
            }
            factory.windowLines = getWindowLines();
            factory.loadedData  = true;
        } else {
            return;
        }
    }

    /**
     * Pobranie montazu bram garazowych
     */
    function getMontages(conf = ConfigurationsService.conf.Current) {
        if (conf && conf.Type && conf.Type.id && ConfiguratorsDataService.data.mosquitoMontages) {
            return ConfiguratorsDataService.data.mosquitoMontages.filter(e => e.types.indexOf(conf.Type.id) >= 0);
        } else {
            return [];
        }
    }

    /**
     * Pobranie powiązanych systemów
     */
    function getWindowLines() {
        return Core.copy(ConfiguratorsDataService.data.mosquitoWindowLines);
    }

    /**
     * Wybor montazu
     * @param  {object}  montage     Wybrany montage
     * @param  {boolean} price    Czy przeliczyc ceny
     */
    function selectMontage(montage, price) {
        if (CurConfService.conf != 'mosquito') {
            return;
        }
        ConfigurationsService.conf.Current.Montage = montage;
        ConfigurationsService.conf.Default.Montage = montage;

        if (price) {
            PriceService.count();
        }
    }

    /**
     * Ustawienie domyslnych wartosci po uruchomieniu
     */
    function setDefaultValues() {
        factory.montages = getMontages();

        if (angular.isDefined(factory.montages[0])) {
            selectMontage(factory.montages[0]);
        } else {
            selectMontage(null);
        }

        ConfigurationsService.conf.Current.ProfilePositions = {};

        if (~~ConfigurationsService.conf.Current.Type.muntin_position > 0) {
            ConfigurationsService.conf.Current.MuntinType = 'standard';
            ConfigurationsService.conf.Current.MuntinPosition = ~~ConfigurationsService.conf.Current.Type.muntin_position;
        } else {
            ConfigurationsService.conf.Current.MuntinType = null;
            ConfigurationsService.conf.Current.MuntinPosition = null;
        }

        if ((angular.isObject(ConfigurationsService.conf.Current.WindowLine)
                && angular.isArray(factory.windowLines[ConfigurationsService.conf.Current.System.id])
                && factory.windowLines[ConfigurationsService.conf.Current.System.id].length)
            || (ConfigurationsService.conf.Current.WindowLine === null
                && ConfigurationsService.conf.Current.HoldersWidth != null)
        ) {
            // Do nothing
        } else if (!angular.isArray(factory.windowLines[ConfigurationsService.conf.Current.System.id])
            || factory.windowLines[ConfigurationsService.conf.Current.System.id].length === 0
        ) {
            setLine(null);
        } else {
            setLine(factory.windowLines[ConfigurationsService.conf.Current.System.id][0]);
        }
    }

    /**
     * Ustawia na starcie domyslna wysokosc szprosa
     * @param {string} position Pozycja standardowa
     */
    function setDefaultMountinPosition(position) {
        ConfigurationsService.conf.Current.MuntinPosition = ~~position;
    }

    /**
     * Dopasuj do linii okiennej
     * @param {object} line Wybrana linia
     */
    function setLine(line) {
        ConfigurationsService.conf.Current.WindowLine = line;
        ConfigurationsService.conf.Current.HoldersWidth = null;
    }

    /**
     * Funkcja po wyborze profilu
     */
    function selectProfile() {
        PriceService.count();
    }

}
