import Common from 'Common';
import { IssueLevel } from 'issues.service';

export class ValidationService {
    static valid(conf, IssuesService, garageGuides, translate, shapeData?) {
        let i, j, o;
        if (Common.isUndefined(shapeData)) {
            shapeData = conf.Shape;
        }
        if (!Common.isUndefined(shapeData)) {
            return true;
        }
        if (!shapeData.width || !shapeData.height || !shapeData.w1 || !shapeData.w2 || !shapeData.n || !shapeData.e) {
            IssuesService.simpleRegister(
                'no-garage-dimensions',
                'Wymiary nie zostały określone.',
                translate.instant('DIMENSION|Wymiary nie zostały określone.'),
                conf,
                {
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }
        if (shapeData.width < 250 || shapeData.height < 250) {
            IssuesService.simpleRegister(
                'incorrect-garage-dimensions',
                'Podane wymiary są nieprawidłowe.',
                translate.instant('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                conf,
                {
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }
        if (Common.isDefined(garageGuides)) {
            for (i = 0; i < garageGuides.length; i++) {
                if (garageGuides[i].symbol === conf.Guide.symbol) {
                    for (j = 0; j < garageGuides[i].limits.length; j++) {
                        for (o in shapeData) {
                            if (o == garageGuides[i].limits[j].dimension) {
                                if (shapeData[o] < ~~garageGuides[i].limits[j].value
                                    && conf.Control.id == garageGuides[i].limits[j].control
                                ) {
                                    IssuesService.simpleRegister(
                                        'incorrect-garage-dimensions',
                                        'Podane wymiary są nieprawidłowe.',
                                        translate.instant('CONFIGURATOR|Wymiar') + ' "'
                                            + o.toUpperCase() + '" '
                                            + translate.instant('CONFIGURATOR|dla sterowania') + ' "'
                                            + conf.Control.name + '" '
                                            + translate.instant('CONFIGURATOR|i prowadzenia') + ' "'
                                            + conf.Guide.symbol.toUpperCase() + '" '
                                            + translate.instant('CONFIGURATOR|musi wynosić co najmniej') + ' '
                                            + garageGuides[i].limits[j].value + 'mm',
                                        conf,
                                        {
                                            logLevel: IssueLevel.NONE
                                        }
                                    );
                                    return false;
                                }
                            }
                        }
                    }
                }
            }
        }
        IssuesService.unregister('no-garage-dimensions', conf);
        IssuesService.unregister('incorrect-garage-dimensions', conf);
        return true;
    }
}
