/**
 * Kontroler falbany markiz
 * @param {object} $scope                   Scope
 * @param {object} AwningFrillFactory       Fabryka falbany markiz
 */
export default function FrillCtrl($scope, AwningFrillFactory, EventBusService) {
    'ngInject';

    var vm = this;

    vm.subStep         = 5;
    vm.valances        = [];
    vm.inscription     = AwningFrillFactory.inscription;
    vm.allFrill        = true;
    vm.emptyValance    = AwningFrillFactory.emptyValance;
    vm.text            = {};
    vm.rollUpValance   = AwningFrillFactory.rollUpValance;
    vm.rollUp          = {};

    vm.selectValance     = selectValance;
    vm.changeInscription = changeInscription;
    vm.changeRollUp      = changeRollUp;

    if (AwningFrillFactory.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    /**
     * Funkcja inicjujaca
     */
    function init() {
        vm.valances = AwningFrillFactory.valances;
        vm.inscription = AwningFrillFactory.inscription;
        vm.rollUpValance = AwningFrillFactory.rollUpValance;

        if (vm.inscription !== '') {
            vm.text.selected = true;
        } else {
            vm.text.selected = false;
        }

        if (vm.rollUpValance) {
            vm.rollUp.selected = true;
        } else {
            vm.rollUp.selected = false;
        }
    }

    /**
     * Wybór lambrekinu
     * @param  {Object} valance Lambrekin
     */
    function selectValance(valance) {
        AwningFrillFactory.selectValance(valance, false, true);
    }

    /**
     * Wybór napisu lambrekinu
     * @param  {Object} inscription Napis
     */
    function selectValanceInscription(inscription) {
        AwningFrillFactory.selectValanceInscription(inscription, false, true);
    }

    /**
     * Zmiana napisu
     */
    function changeInscription() {
        var text = vm.text;
        var inscription = vm.inscription;

        if (!text.selected) {
            inscription = '';
            vm.inscription = '';
        }

        selectValanceInscription(inscription);
    }

    /**
     * Zmiana zwijania
     */
    function changeRollUp() {
        if (vm.rollUp.selected) {
            AwningFrillFactory.setRollUpValance(true, false, true);
        } else {
            AwningFrillFactory.setRollUpValance(false, false, true);
        }
    }
}
