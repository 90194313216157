import angular from 'angular';

/**
 * @ngdoc controller
 * @name ICC.Configurators.ColorsCtrl
 *
 * @param {Service} $scope        Scope
 * @param {Service} ColorsFactory ColorsFactory
 * @param {Service} PriceService  PriceService
 *
 * @description
 *
 * Krok kolor w oknach, drzwiach zewn. i drzwiach tarasowych.
 */
export default function ColorsCtrl($scope, $rootScope, ColorsFactory, PriceService, DrawService) {
    'ngInject';

    var vm = this;

    /**
     * Waliduje kolory.
     * @memberof ColorsCtrl
     * @function
     */
    vm.valid             = ColorsFactory.validate;
    /**
     * Ustawia układ kolorów.
     * @memberof ColorsCtrl
     * @function
     */
    vm.setColorType      = ColorsFactory.setColorType;
    /**
     * Ustawia kolory przy zmianie niezależnego koloru skrzydła.
     * @memberof ColorsCtrl
     * @function
     */
    vm.setColorsSashExt  = ColorsFactory.setColorsSashExt;
    /**
     * Otwiera popup wyboru rodzaju drewna.
     * @memberof ColorsCtrl
     * @function
     */
    vm.openModalWood     = ColorsFactory.openModalWood;

    /**
     * Usunięcie wybranego koloru przy aluminium szczotkowanym
     * @memberof ColorsCtrl
     * @function
     */
    vm.clearAlushellColor = ColorsFactory.clearAlushellColor;

    var j = 0;
    var hasCream = false;
    for (j = 0; j < ColorsFactory.windowColors.length; j++) {
        if (ColorsFactory.windowColors[j].sides && ColorsFactory.windowColors[j].sides.indexOf('F|C') > -1 && ColorsFactory.windowColors[j].type === 'cream') {
            hasCream = true;
            break;
        }
    }

    /**
     * Czy istnieje "krem w masie"
     * @type bool
     */
    vm.hasCream = hasCream;

    vm.showOuterSide = false;

    vm.drawOptionsInner = angular.merge({}, DrawService.options, {
        base: {
            side: 'inner',
        },
    });

    vm.drawOptionsOuter = angular.merge({}, DrawService.options, {
        base: {
            side: 'outer',
        },
    });

    vm.setAlushellColor = ColorsFactory.setAlushellColor;

    $scope.$on('changedStep', function(event, newVal, oldVal) {
        if (newVal.code != 'color' && oldVal.code == 'color' && newVal.code !== oldVal.code) {
            vm.valid();
        }
    });

    vm.refreshPrice = function() {
        PriceService.count();
    };
}
