/**
 * Kontroler rozmiaru markiz
 * @param {object} $scope                   Scope
 * @param {object} AwningDimensionsFactory  Fabryka kolorów do markiz
 */
export default function DimensionsCtrl($scope, AwningDimensionsFactory, EventBusService) {
    'ngInject';

    var vm = this;

    vm.subStep         = 2;
    vm.outreaches      = AwningDimensionsFactory.outreaches;
    vm.outreachesWidth = AwningDimensionsFactory.outreachesWidth;
    vm.outreach        = AwningDimensionsFactory.outreach;
    vm.width           = AwningDimensionsFactory.width;

    vm.selectSize      = selectSize;
    vm.valid           = AwningDimensionsFactory.valid;
    vm.changeOutreach  = changeOutreach;
    vm.changeWidth     = changeWidth;

    $scope.$on('changedStep', function(event, newVal, oldVal) {
        if (newVal.code !== 'dimensions' && vm.valid(vm.width) && oldVal.code === 'dimensions') {
            selectSize(vm.outreach,vm.width);
        }
    });

    if (AwningDimensionsFactory.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    /**
     * Funkcja inicjujaca
     */
    function init() {
        vm.outreaches = AwningDimensionsFactory.outreaches;
        vm.outreachesWidth = AwningDimensionsFactory.outreachesWidth;
        vm.outreach = AwningDimensionsFactory.outreach;
        vm.width = AwningDimensionsFactory.width;
    }

    /**
     * Wybór rozmiatu
     * @param  {Object} outreach Zasięg
     * @param  {Number} width    Szerokość
     */
    function selectSize(outreach, width) {
        AwningDimensionsFactory.selectSize(outreach, width, false, true);
    }

    /**
     * Zmiana zasięgu
     */
    function changeOutreach() {
        if (vm.width < vm.outreachesWidth[vm.outreach].width_from) {
            vm.width = vm.outreachesWidth[vm.outreach].width_from;
            selectSize(vm.outreach, vm.width);
        }
    }

    /**
     * Zmiana szerokości
     */
    function changeWidth() {
        selectSize(vm.outreach, vm.width);
    }
}
