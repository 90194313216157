import {Injectable, Inject} from '@angular/core';
import Common from '../Common';
import {ConfiguratorsDataService} from 'configurators/configurators-data.service';
import UserService from 'user.service';
import {EventBusService} from 'event-bus.service';
import {core} from 'helpers';
import {FIX} from 'configurations/parts/window/sash-types';
import {APP_CONFIG, AppConfig} from 'config';

@Injectable()
export default class ReinforcementsService {
    limitations = [];
    reinforcements = [];
    sizeRanges = [];
    colorGroups = [];
    loadedData = false;

    constructor(
        private ConfiguratorsDataService: ConfiguratorsDataService,
        private UserService: UserService,
        protected EventBusService: EventBusService,
        @Inject(APP_CONFIG) private config: AppConfig,
    ) {
        if (this.ConfiguratorsDataService.loaded) {
            this.init();
        }
        this.EventBusService.subscribe('loadedConfiguratorsData', data => {
            this.init();
        });
    }

    init() {
        this.limitations = this.ConfiguratorsDataService.data.constructionLimitations;
        this.reinforcements = this.ConfiguratorsDataService.data.reinforcements;
        this.sizeRanges = this.ConfiguratorsDataService.data.sizeRanges;
        this.loadedData = true;
    }

    findReinforcement(conf) {
        let limitations = [];
        let noConstructionLimitations = false;
        let noMatchedConstructionLimitations = false;
        let invalid;
        const user = this.UserService.get();
        const marketDefaults = user.marketDefaults;
        const verticalMullions = conf.Mullions.filter(mullion => (
            mullion.direction === 'vertical'
            && (!this.config.IccConfig.Configurators.constructionLimitationForFalseMullions
                || this.config.IccConfig.Configurators.constructionLimitationForFalseMullions && mullion.type === 'false_mullion'
            )
        ));
        const sashesWithoutMullions  = conf.Sashes.filter(sash => {
            const leftMullion = conf.Mullions.find(m => m.id == sash.nearMullions.left);
            const rightMullion = conf.Mullions.find(m => m.id == sash.nearMullions.right);
            return (this.config.IccConfig.Configurators.noMullionCheckInReinforcement || ((!leftMullion || leftMullion.type !== 'false_mullion') && (!rightMullion || rightMullion.type !== 'false_mullion'))) && FIX.indexOf(sash.type.type) === -1;
        });

        if (!this.config.IccConfig.Configurators.noMullionCheckInReinforcement) {
            verticalMullions.forEach(mullion => {
                invalid = this.limitationsForSashMullionSash(mullion, conf.Colors, marketDefaults, conf);
                noConstructionLimitations = noConstructionLimitations || invalid === 1;
                noMatchedConstructionLimitations = noMatchedConstructionLimitations || invalid === 2;
            });
        }

        sashesWithoutMullions.forEach(sash => {
            limitations = this.findLimitationsForSingleSash(sash, conf, conf.Colors, marketDefaults);
            invalid = false;
            if (limitations.length === 0) {
                invalid = 1;
            }
            invalid = invalid || this.setReinforcementsInSingleSash(sash, limitations, conf);
            noConstructionLimitations = noConstructionLimitations || invalid === 1;
            noMatchedConstructionLimitations = noMatchedConstructionLimitations || invalid === 2;
        });

        return {noConstructionLimitations, noMatchedConstructionLimitations};
    }

    limitationsForSashMullionSash(mullion, colors, marketDefaults, conf): boolean | number {
        let invalid: boolean | number = false;
        const leftSashes = mullion.multiAlignLeft;
        const rightSashes = mullion.multiAlignRight;
        const commonReinforcementsLeft = leftSashes.map(sash => {
            const limitations = this.findLimitationsForSingleSash(sash, conf, colors, marketDefaults, mullion.profileId);
            if (limitations.length === 0) {
                invalid = 1;
            }
            const reinforcement = this.intersectArrays(this.getCommonReinforcementsInSingleSash(sash, limitations, conf, mullion.profileId));
            return reinforcement;
        });
        const commonReinforcementsRight = rightSashes.map(sash => {
            const limitations = this.findLimitationsForSingleSash(sash, conf, colors, marketDefaults, mullion.profileId);
            if (limitations.length === 0) {
                invalid = 1;
            }
            const reinforcement = this.intersectArrays(this.getCommonReinforcementsInSingleSash(sash, limitations, conf, mullion.profileId));
            return reinforcement;
        });
        const commonMuntinReinforcements = this.intersectArrays([this.intersectArrays(commonReinforcementsLeft), this.intersectArrays(commonReinforcementsRight)]);
        if (commonMuntinReinforcements.length > 0) {
            leftSashes.forEach(sash => {
                const limitations = this.findLimitationsForSingleSash(sash, conf, colors, marketDefaults, mullion.profileId, commonMuntinReinforcements[0]);
                const invalidSize = this.setReinforcementsInSingleSash(sash, limitations, conf, mullion.profileId);
                invalid = invalid || invalidSize;
            });
            rightSashes.forEach(sash => {
                const limitations = this.findLimitationsForSingleSash(sash, conf, colors, marketDefaults, mullion.profileId, commonMuntinReinforcements[0]);
                const invalidSize = this.setReinforcementsInSingleSash(sash, limitations, conf, mullion.profileId);
                invalid = invalid || invalidSize;
            });
            mullion.reinforcement = this.getReinforcementFromList(commonMuntinReinforcements);
            return invalid;
        } else {
            return invalid || 2;
        }
    }

    findLimitationsForSingleSash(sash, conf, colors, marketDefaults, mullionId = null, mullionReinforcementId?) {

        const sashStraightSides = this.getSashStraightSides(sash, conf);
        return (this.limitations || []).filter((e) => {
            let checked = true;

            checked = checked && Common.isArray(e.sashTypes) && e.sashTypes.map(Number).indexOf(sash.type.id * 1) >= 0;

            if (Common.isArray(colors.frame.outer.groups)) {
                checked = checked && colors.frame.outer.groups.map(Number).indexOf(e.window_color_group_id * 1) > -1;
            } else if (Common.isArray(colors.frame.core.groups)) {
                checked = checked && colors.frame.core.groups.map(Number).indexOf(e.window_color_group_id * 1) > -1;
            }

            checked = checked && (!e.handle_position
                || (e.handle_position === 'top' && sash.type.handle_position === 'T')
                || (e.handle_position === 'side' && (sash.type.handle_position === 'R' || sash.type.handle_position === 'L'))
                || (e.handle_position === 'bottom' && sash.type.handle_position === 'B')
            );

            checked = checked && Number(marketDefaults.default_wind_load) <= Number(e.wind_load);

            checked = checked && sashStraightSides.every(side => (
                e.sizes.some(size => (
                    Number(size.sash) === sash.frame[side].profileId
                    && size.mullion === mullionId
                    && (
                        Common.isUndefined(mullionReinforcementId)
                        || size.mullionReinforcement === mullionReinforcementId
                    )
                ))
            ));

            if (sash.intSashes.length === 0 && !sash.bondedGlazing || sash.intSashes.length > 0 && sash.intSashes.every(field => !field.bondedGlazing)) {
                checked = checked && !(e.bonded_glazing);
            }

            return checked;
        });
    }

    getCommonReinforcementsInSingleSash(sash, limitations, conf, mullionId = null) {
        return this.getSashStraightSides(sash, conf).map(side => {
            const sizesMatchedToDimensions = this.getSizesMatchedToDimensions(side, sash, limitations, conf, mullionId);
            return sizesMatchedToDimensions.map(size => size.mullionReinforcement);
        }, []);
    }

    setReinforcementsInSingleSash(sash, limitations, conf, mullionId = null) {
        let invalid: any = false;

        this.getSashStraightSides(sash, conf).forEach(side => {
            const sizesMatchedToDimensions = this.getSizesMatchedToDimensions(side, sash, limitations, conf, mullionId);
            const matchedReinforcements = sizesMatchedToDimensions.map(size => size.sashReinforcement);
            if (matchedReinforcements.length > 0) {
                sash.frame[side].reinforcement = this.getReinforcementFromList(matchedReinforcements);
            } else {
                invalid = 2;
            }
        });
        return invalid;
    }

    sashDimensionsMatchToSizeRange(sash, id, conf) {
        let sashDimensions = conf.drawData.sashFrame.find(s => s.sashId == sash.id);
        if (!sashDimensions && sash.type.type === 'F') {
            sashDimensions = conf.drawData.sash.find(s => s.sashId == sash.id);
        }
        if (sashDimensions) {
            const width = sashDimensions.outer.rect.width;
            const height = sashDimensions.outer.rect.height;
            const sizeRange = this.sizeRanges.find(range => Number(range.id) === Number(id));
            if (sizeRange) {
                return core.pointInPolygon(sizeRange.sizes, width, height);
            }
        }
        return false;
    }

    getSizesMatchedToDimensions(side, sash, limitations, conf, mullionId = null) {
        const sizes = [].concat(...limitations.map(cur => cur.sizes.filter(size => Number(size.sash) === sash.frame[side].profileId && size.mullion === mullionId)).filter(s => s.length));
        return sizes.filter(size => this.sashDimensionsMatchToSizeRange(sash, size.sizeRangeId, conf));
    }

    /**
     * Filtruje ograniczenia
     * @param  {object} sash   Kwatera
     * @param  {object} frame  Rama okna
     * @param  {object} colors Zestaw kolorow
     * @return {array}         Lista przefiltrowanych ograniczen
     */
    findLimitations(sash, frame, colors) {
        return (this.limitations || []).filter((e) => {
            let checked = true;

            checked = checked && Common.isArray(e.sashTypes) && e.sashTypes.map(Number).indexOf(sash.type.id * 1) >= 0;

            checked = checked && (
                sash.type.type != 'F'
                || Common.isObject(frame) && Common.isArray(e.frames) && e.frames.map(Number).indexOf(frame.id * 1) >= 0
            );

            checked = checked && (
                sash.type.type == 'F'
                || Common.isObject(sash.frame) && Common.isArray(e.sashFrames) && e.sashFrames.map(Number).indexOf(sash.frame.id * 1) >= 0
            );

            if (Common.isArray(colors.frame.outer.groups)) {
                checked = checked && colors.frame.outer.groups.map(Number).indexOf(e.window_color_group_id * 1) > -1;
            } else {
                checked = checked && colors.frame.core.groups.map(Number).indexOf(e.window_color_group_id * 1) > -1;
            }

            return checked;
        });
    }


    intersectArrays(arrays) {
        arrays = core.copy(arrays);
        for (let i = arrays.length - 2; i >= 0; i--) {
            arrays[arrays.length - 1].splice(i - 1, 2, this.intersect(arrays[arrays.length - 1], arrays[i]));
        }
        return arrays[0];
    }

    intersect(a, b) {
        let setA = new Set(a);
        let setB = new Set(b);
        let intersection = new Set([...setA].filter(x => setB.has(x)));
        return Array.from(intersection);
    }

    getReinforcementFromList(ids) {
        const reinforcements = ids.map(id => {
            return (this.reinforcements ? this.reinforcements.find(r => r.id == id) : null) || null;
        }).sort((a, b) => {
            if (a === null) {
                return -1;
            }
            if (b === null) {
                return 1;
            }
            return a.price - b.price;
        });
        let reinforcement = {
            id  : null,
            name: null,
        };
        if (reinforcements && reinforcements.length && reinforcements[0]) {
            reinforcement = {
                id  : reinforcements[0].id,
                name: reinforcements[0].name,
            };
        }
        return reinforcement;
    }

    /**
     * Sprawdza czy wszystkie kwatery maja takie zamo wzmocnienie
     * @param  {object} conf Konfiguracja
     */
    checkIsOneReinforcement(conf) {
        let same = true;
        let first = conf.Sashes[0].reinforcement ? conf.Sashes[0].reinforcement.id : undefined;
        let current;

        for (let i = 0; i < conf.Sashes.length; i++) {
            current = conf.Sashes[i].reinforcement ? conf.Sashes[i].reinforcement.id : undefined;
            if (current != first) {
                same = false;
                break;
            }
        }

        conf.OneReinforcement = same;
    }

    /**
     * Funkcja tymczasowa - szyka czy punkt jest w wielokacie
     * @param  {array} vs  Lista wierzcholkow wielokata
     * @param  {number} x  Punkt na osi X
     * @param  {number} y  Punkt na osi Y
     * @return {number}    Jak < 0 to wewnatrz
     */
    classifyPoint(vs, x, y) {
        let inside = false;
        for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
            let xi = vs[i][0], yi = vs[i][1];
            let xj = vs[j][0], yj = vs[j][1];

            let intersect = ((yi > y) != (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) {
                inside = !inside;
            }
        }

        return inside ? -1 : 1;
    }

    getSashStraightSides(sash, conf) {
        const sashDimensions = conf.drawData.sashFrame.find(s => s.sashId === sash.id);
        if (!sashDimensions && sash.type.type === 'F') {
            return ['bottom', 'right', 'top', 'left'];
        }
        if (!sashDimensions) {
            return [];
        }

        return sashDimensions.sides.filter(side => !side.outerEdge.circle).map(side => side.outerEdge.side);
    }

}
