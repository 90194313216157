const GuidesModalTemplate = require<string>('ngtemplate-loader!./guides-modal.component.html');
import GuidesModalController from './guides-modal.controller';

export const GuidesModalComponent: ng.IComponentOptions = {
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  templateUrl: GuidesModalTemplate,
  controller: GuidesModalController
};
