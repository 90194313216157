import {TimeLimit, TimeLimitSegment} from './time_limit_functions';

export default class TimeLimitRollerShutterColorGroupService {

    /**
     * Pobiera termin realizacji koloru rolety.
     *
     * @param {object} roller   Roleta
     * @param {object} colorGroups   Grupy kolorów
     * @return {number} Termin realizacji koloru rolety.
     */
    @TimeLimit({
        shortName: 'rollerShutterColorGroup',
        data: {
            rollerShutter: 'conf.RollerShutter',
            colorGroups: 'data.windowColorGroups',
        }
    })
    rollerShutterColorGroupTimeLimit({timeLimits, rollerShutter, colorGroups}): TimeLimitSegment[] {
        if (rollerShutter == null || !rollerShutter.colors) {
            return null;
        }
        const segments: TimeLimitSegment[] = [];

        ['box', 'boxGuideOuter', 'boxInner', 'boxSideSurface', 'endslat', 'guideRail', 'profile', 'revision']
            .forEach(type => {
                if (!rollerShutter.colors[type] || !rollerShutter.colors[type].groups || !rollerShutter.colors[type].groups.length) {
                    return;
                }
                const colorGroup = colorGroups.find(group => Number(group.id) === Number(rollerShutter.colors[type].groups[0]));
                if (colorGroup && colorGroup.time_limit_id) {
                    segments.push({
                        type: 'rollerShutterColorGroup',
                        value: timeLimits[colorGroup.time_limit_id],
                        data: {
                            id  : rollerShutter.colorGroup.id,
                            name: rollerShutter.colorGroup.name,
                        }
                    });
                }
            });

        return segments;
    }
}