const SlatsModalTemplate = require<string>('ngtemplate-loader!./slats-modal.component.html');
import SlatsModalController from './slats-modal.controller';

export const SlatsModalComponent: ng.IComponentOptions = {
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  templateUrl: SlatsModalTemplate,
  controller: SlatsModalController
};
