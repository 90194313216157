import PriceService from 'price/price.service';
import Common from 'Common';

/**
 * Kontroler kolorów rolet
 * @param {object} RollerColorsFactory   Fabryka kolorów rolet
 * @param {object} ConfigurationsService Fabryka konfiguracji
 */
export default class ColorsCtrl {
    drawOptions = Common.merge({}, this.DrawService.options, {
        extendedRollerInWindow: IccConfig.Configurators.extendedRollerInWindow,
    });

    constructor(
        private $rootScope,
        private DrawService,
        private ConfigurationsService,
        private RollerColorsFactory,
        private RollerDimensionsFactory,
        private PriceService: PriceService
    ) {
        'ngInject';

        this.RollerDimensionsFactory.updateDimensions();
    }

    openModalRollerColor(type) {
        this.RollerColorsFactory.openModalRollerColor(
            type,
            this.ConfigurationsService.conf.Current
        );
    }

    refreshPrice() {
        this.PriceService.count();
    }
}
