/**
 * Kontroler akcesoriów do markiz
 * @param {object} $scope                   Scope
 * @param {object} AwningAccessoryFactory   Fabryka akcesoriów do markiz
 */
export default function AccessoryCtrl($scope, AwningAccessoryFactory, EventBusService) {
    'ngInject';

    var vm = this;

    vm.subStep         = 8;
    vm.accessories     = AwningAccessoryFactory.accessories;
    vm.selAccessories  = AwningAccessoryFactory.selAccessories;
    vm.allAccessory    = true;

    vm.selectAccessory = selectAccessory;

    if (AwningAccessoryFactory.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    /**
     * Funkcja inicjujaca
     */
    function init() {
        vm.accessories = AwningAccessoryFactory.accessories;
    }

    /**
     * Wybór akcesoria
     * @param  {Object} accessory Akcesorium
     */
    function selectAccessory(accessory) {
        vm.selAccessories = AwningAccessoryFactory.selectAccessory(vm.selAccessories, accessory, true);
    }

}
