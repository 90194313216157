const ProfilesModalTemplate = require<string>('ngtemplate-loader!./profiles-modal.component.html');
import ProfilesModalController from './profiles-modal.controller';

export const ProfilesModalComponent: ng.IComponentOptions = {
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  templateUrl: ProfilesModalTemplate,
  controller: ProfilesModalController
};
