import * as angular from 'angular';
import {downgradeInjectable} from '@angular/upgrade/static';

import HandleCatFilter from './HandleCatFilter';
import HandlesCtrl from './HandlesCtrl';
import HandlesFactory from './HandlesFactory';
import HandleSubCatsFilter from './HandleSubCatsFilter';
import ModalHandleCtrl from './ModalHandleCtrl';
import ModalFittingCtrl from './ModalFittingCtrl';
import ModalHingeCtrl from './ModalHingeCtrl';
import HandleHeightService from 'configurators/handle-height.service';

export default angular.module('icc.configurator.steps.window.handles', [])
    .controller('HandlesCtrl', HandlesCtrl)
    .controller('ModalHandleCtrl', ModalHandleCtrl)
    .controller('ModalFittingCtrl', ModalFittingCtrl)
    .controller('ModalHingeCtrl', ModalHingeCtrl)
    .factory('HandlesFactory', HandlesFactory)
    .factory('HandleHeightService', downgradeInjectable(HandleHeightService))
    .filter('handleCat', HandleCatFilter)
    .filter('handleSubCats', HandleSubCatsFilter)
    .name;
