/**
 * Segment terminu realizacji
 *
 * @export
 * @interface TimeLimitSegment
 */
export interface TimeLimitSegment {
    /**
     * Id segmentu
     *
     * @type {number}
     * @memberOf TimeLimitSegment
     */
    id?: number;
    /**
     * Czego dotyczy termin realizacji.
     *
     * @type {string}
     * @memberOf TimeLimitSegment
     */
    type: string;
    /**
     * Ilość dni.
     *
     * @type {(?number)}
     * @memberOf TimeLimitSegment
     */
    value: number | null;
    /**
     * Dodatkowe dane segmentu
     *
     * @type {*}
     * @memberOf TimeLimitSegment
     */
    data: any;
}

/**
 * Opis funkcji liczącej termin realizacji
 *
 * @interface TimeLimitFunctionDesc
 */
interface TimeLimitFunctionDesc {
    /**
     * Informacje o danych, do przekazania w drugim parametrze do funkcji.
     *
     * @type {Object}
     * @memberOf TimeLimitFunctionDesc
     */
    data: any;
    /**
     * Krótna nazwa (alias)
     *
     * @type {string}
     * @memberOf TimeLimitFunctionDesc
     */
    shortName: string;
}

/**
 * Funkcja licząca termin realizacji
 *
 * @export
 * @interface TimeLimitFunction
 */
export interface TimeLimitFunction {
    (data: Object): TimeLimitSegment[];
    requiredData: Object;
    className: string;
}

export class TimeLimitFunctions {
    /**
     * Mapa zawierająca pary alias - funkcja.
     *
     */
    static map = new Map<string, TimeLimitFunction>();

    /**
     * Aliasy funkcji
     *
     */
    static timeLimitsFn = {
        window: [
            'system',
            'filling',
            'sealColor',
            'accessory',
            'sideAccessory',
            'sashAccessory',
            'handle',
            'hinge',
            'fitting',
            'shape',
            'rollerShutterSystem',
            'rollerShutterProfile',
            'rollerShutterDrive',
            'profile',
        ],
        door: [
            'system',
            'filling',
            'sealColor',
            'accessory',
            'sideAccessory',
            'sashAccessory',
            'handle',
            'hinge',
            'lock',
            'fitting',
            'profile',
        ],
        folding_door: 'window',
        hs: 'window',
        accessory: ['accessory'],
        roller_shutter: [
            'accessory',
            'rollerShutterSystem',
            'rollerShutterProfile',
            'rollerShutterDrive',
            'WindowColorGroup',
        ],
        complementary_goods: ['complementaryGood'],
        garage: ['system', 'accessory', 'garageControl', 'garagePanelStructureColor'],
        coupled_window: [],
    };

    /**
     * Dane potrzebne do wywołania metody.
     *
     * @param {{}} requiredData Dane wymagane do wywołania metody.
     *
     */
    static TimeLimit(config: TimeLimitFunctionDesc) {
        return function(target: any, propertyKey: string) {
            const x = target[propertyKey];
            x.requiredData = config.data;
            x.className = target.constructor.name;
            TimeLimitFunctions.map.set(config.shortName, x);
        };
    }
}

export const TimeLimit = TimeLimitFunctions.TimeLimit;
