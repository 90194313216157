import {NgModule} from '@angular/core';
import {LicenseService} from 'license/license.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [LicenseService],
})
export class LicenseModule { }
