import * as angular from 'angular';
import {downgradeInjectable, downgradeComponent} from '@angular/upgrade/static';

import DimensionsCtrl from './DimensionsCtrl';
import DimensionsFactory from './DimensionsFactory';
import ModalDimensionChangeCtrl from './ModalDimensionChangeCtrl';
import ConstructionLimitationFactory from './ConstructionLimitationFactory';
import ReinforcementsService from 'configurators/reinforcements.service';
import {MeasurementsComponent}   from './measurements.component';
import MeasurementsService from './measurements.service';
import {MountingMethodModalComponent} from './mounting-method-modal/mounting-method-modal.component';

export default angular.module('icc.configurator.steps.window.dimensions', [])
    .controller('DimensionsCtrl', DimensionsCtrl)
    .controller('ModalDimensionChangeCtrl', ModalDimensionChangeCtrl)
    .component('mountingMethodModal', MountingMethodModalComponent)
    .directive('measurements', downgradeComponent({
        component: MeasurementsComponent,
        inputs: [],
        outputs: []
    }))
    .factory('DimensionsFactory', DimensionsFactory)
    .factory('ConstructionLimitationFactory', ConstructionLimitationFactory)
    .factory('ReinforcementsService', downgradeInjectable(ReinforcementsService))
    .factory('MeasurementsService', downgradeInjectable(MeasurementsService))
    .name;
