import { IccDrawMathService } from '@icc/draw';

export class DrawData {
    innerEdge: any;
    outerEdge: any;

    direction: string;
    length: number;

    poly: any[];
    polyArea: number;
    polyEdge: number;

    rect: any;
    rectArea: number;
    rectEdge: number;

    constructor(poly: any[], params: any = {}, getInfo?: boolean) {
        Object.assign(this, params);

        this.poly = poly;
        this.polyArea = IccDrawMathService.getAreaFromPoly(this.poly) / 1e6;
        this.polyEdge = IccDrawMathService.getPerimeterFromPoly(this.poly) / 1e3;

        this.rect = IccDrawMathService.getRectFromPoly(this.poly);
        this.rectArea = IccDrawMathService.getAreaFromRect(this.rect) / 1e6;
        this.rectEdge = IccDrawMathService.getPerimeterFromRect(this.rect) / 1e3;

        if (getInfo) {
            this.innerEdge = IccDrawMathService.getLineInfo([poly[2], poly[3]], poly);
            this.outerEdge = IccDrawMathService.getLineInfo([poly[0], poly[1]], poly);
        }

        if (params.direction) {
            this.length = params.direction === 'horizontal' ? this.rect.width : this.rect.height;
        } else if (getInfo) {
            this.length = this.outerEdge.length;
        }
    }
}
