import Common from '../Common';
import core from '../Core';
import {logger} from '../helpers';

export default function UnitMMFilter(UserService, IccConfig) {
    'ngInject';

    /**
     * Filtr do konwersji wymarów.
     * @param  {number} data           Liczba do konwersji
     * @param  {number} hasunit        1 lub 0 - czy do wymiaru dołączany ma być symbol wymiaru (mm, ″)
     * @param  {number} inmm           Czy do wymiaru innego niż w mm dołączyć informacje o wymiarze w jednostkach mm?
     * @param  {string} dimension_unit Docelowa jednostka, przy braku bierze domyślną jednostkę użytkownika / rynku
     * @param  {number} round          Ilość miejsc po przecinku
     * @return {string}                Skonwertowany wymiar.
     */
    return (data, hasunit = 0, inmm = 0, dimension_unit = '', round = null) => {
        const Core = new core(logger);
        const dimensionUnit = dimension_unit || UserService.get().dimension_unit;
        const unit = {
            type     : dimensionUnit === 'mm' ? 0 : 1,
            unit     : dimensionUnit,
            separator: dimensionUnit === 'mm' ? ',' : '.'
        };

        if (Common.isString(data)) {
            data = +data;
        }
        if (!Common.isNumber(data) || isNaN(data) || !Common.isDefined(data)) {
            return 'NaN';
        }

        let unitext = '';
        if (hasunit) {
            if (unit.type === 0) {
                unitext = 'mm';
            } else {
                unitext = '″';
            }
        }

        if (round == null) {
            round = 2;
        } else {
            data = Core.formatNumber(data, round, 100);
        }

        // milimetry, zwróć po prostu - nie dodawaj nawet drugiego wymiaru, bo po co
        if (unit.type === 0) {
            return data + unitext;
        // do konwertowanych jednostek dodaj wymiar
        } else if (inmm) {
            return Core.formatNumber(data / IccConfig.Configurators.inchToMilimeter, round, 100, ' ', unit.separator)
                + unitext + ' (' + data + 'mm)';
        } else {
            return Core.formatNumber(data / IccConfig.Configurators.inchToMilimeter, round, 100, ' ', unit.separator)
                + unitext;
        }
    };
}
