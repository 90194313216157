/**
 * Kontroler typów siatek
 * @param {object} $scope               Angular Scope
 * @param {object} $uibModal               Dostarcza obsługę okien modalnych
 * @param {object} filterFilter         Filtry
 * @param {object} MosquitoTypesFactory Fabryka typów siatek
 * @param {object} CurConfService       Bieżaca konfiguracja
 */
export default function TypesCtrl($scope, $uibModal, filterFilter, MosquitoTypesFactory, CurConfService, EventBusService) { // jshint ignore:line
    'ngInject';

    var vm = this;

    vm.subStep    = 1;
    vm.types      = [];
    vm.selectType = selectType;

    if (MosquitoTypesFactory.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        vm.types = MosquitoTypesFactory.types;
    }

    /**
     * Funkcja wyboru typu
     * @param  {object} type Typ
     */
    function selectType(type) {
        MosquitoTypesFactory.selectType(type, true);
    }

}
