import * as angular from 'angular';

import GlassCatFilter from './GlassCatFilter';
import GlassColorFilter from './GlassColorFilter';
import GlassCountFilter from './GlassCountFilter';
import GlassOftenFilter from './GlassOftenFilter';
import GlassOrnamentFilter from './GlassOrnamentFilter';
import GlassRwFilter from './GlassRwFilter';
import GlassSecurityFilter from './GlassSecurityFilter';
import GlassSubCatsFilter from './GlassSubCatsFilter';
import GlassThicknessFilter from './GlassThicknessFilter';
import DecoPanelTypeFilter from './DecoPanelTypeFilter';
import GlassUgFilter from './GlassUgFilter';
import GlazingCtrl from './GlazingCtrl';
import GlazingFactory from './GlazingFactory';
import ModalBeadColorCtrl from './ModalBeadColorCtrl';
import ModalDecoPanelOptionsCtrl from './ModalDecoPanelOptionsCtrl';
import ModalGlazingBeadCtrl from './ModalGlazingBeadCtrl';
import ModalGlazingCtrl from './ModalGlazingCtrl';
import ModalSealColorCtrl from './ModalSealColorCtrl';
import ModalSiliconeColorCtrl from './ModalSiliconeColorCtrl';
import ModalWarmEdgeCtrl from './ModalWarmEdgeCtrl';
import {GlazingFiltersComponent} from './GlazingFiltersComponent';
import {downgradeInjectable} from '@angular/upgrade/static';
import GlazingBeadsService from 'glazing-beads.service';
import {GlazingBeadColorsService} from './glazing-bead-colors.service';
import {SealColorsService} from './seal-colors.service';
import {WarmEdgesService} from './warm-edges.service';
import {FillingsService} from './fillings.service';
import {GlazingUnitModalComponent} from './glazing-unit-modal/glazing-unit-modal.component';
import GlazingUnitsService from './glazing-units.service';
import GlazingSizesService from 'configurators/glazing-sizes.service';
import {SiliconeColorsService} from './silicone-colors.service';

export default angular.module('icc.configurator.steps.window.glazings', [])
    .controller('GlazingCtrl', GlazingCtrl)
    .controller('ModalBeadColorCtrl', ModalBeadColorCtrl)
    .controller('ModalDecoPanelOptionsCtrl', ModalDecoPanelOptionsCtrl)
    .controller('ModalGlazingBeadCtrl', ModalGlazingBeadCtrl)
    .controller('ModalGlazingCtrl', ModalGlazingCtrl)
    .controller('ModalSealColorCtrl', ModalSealColorCtrl)
    .controller('ModalSiliconeColorCtrl', ModalSiliconeColorCtrl)
    .controller('ModalWarmEdgeCtrl', ModalWarmEdgeCtrl)
    .component('glazingFilters', new GlazingFiltersComponent())
    .component('glazingUnitModal', GlazingUnitModalComponent)
    .factory('GlazingFactory', GlazingFactory)
    .factory('GlazingBeadsService', downgradeInjectable(GlazingBeadsService))
    .factory('GlazingBeadColorsService', downgradeInjectable(GlazingBeadColorsService))
    .factory('SealColorsService', downgradeInjectable(SealColorsService))
    .factory('WarmEdgesService', downgradeInjectable(WarmEdgesService))
    .factory('FillingsService', downgradeInjectable(FillingsService))
    .factory('GlazingUnitsService', downgradeInjectable(GlazingUnitsService))
    .factory('GlazingSizesService', downgradeInjectable(GlazingSizesService))
    .factory('SiliconeColorsService', downgradeInjectable(SiliconeColorsService))
    .filter('glassCat', GlassCatFilter)
    .filter('glassColor', GlassColorFilter)
    .filter('glassCount', GlassCountFilter)
    .filter('glassOften', GlassOftenFilter)
    .filter('glassOrnament', GlassOrnamentFilter)
    .filter('glassRw', GlassRwFilter)
    .filter('glassSecurity', GlassSecurityFilter)
    .filter('glassSubCats', GlassSubCatsFilter)
    .filter('glassThickness', GlassThicknessFilter)
    .filter('decoPanelType', DecoPanelTypeFilter)
    .filter('glassUg', GlassUgFilter)
    .name;
