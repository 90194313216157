// import 'whatwg-fetch';
import {logger} from 'app/helpers';

export default function getUser(resolve, reject) {
    try {
        let data: any;
        doRequest(d => {
            data = d;
            parseUserData(data, resolve, reject);
        }, error => {
            logger.error(error);
            parseUserData(data, resolve, reject);
        });
    } catch (err) {
        logger.error(err);
    }
}

function doRequest(resolve, reject) {
    const req = new XMLHttpRequest();
    req.open('GET', (window.remoteHost ? window.remoteHost : '' ) + '/sync/sync/get_user_data.json?token=' + localStorage.getItem('token') + '&iccAppVersion=' + VERSION + (window.cordova ? '&phonegap' : ''), true);
    req.onload = (e) => {
        if (req.readyState === 4 && req.status === 200) {
            resolve(JSON.parse(req.responseText));
        }
    };
    req.onabort = (e) => {
        reject(req.statusText);
    };
    req.onerror = (e) => {
        reject(req.statusText);
    };
    req.send(null);
}

function parseUserData(data, resolve, reject) {
    if (!data) {
        logger.error('Brak odpowiedzi z danymi użytkownika!');
        data = JSON.parse(localStorage.getItem('config')) || {};
        data.user_type = JSON.parse(localStorage.getItem('user'));
        data.dealer = JSON.parse(localStorage.getItem('dealer'));
        data.seller = JSON.parse(localStorage.getItem('seller'));
        data.dealer_seller = JSON.parse(localStorage.getItem('dealer_seller'));
        data.client = JSON.parse(localStorage.getItem('client'));
    }
    if (localStorage && data) {
        localStorage.setItem('user', (JSON.stringify(data.user_type)));
        localStorage.setItem('offline_profile_' + data.user_type.email, JSON.stringify(data.user_type));
        const config = Object.assign({}, data, {
            user_type: undefined,
            dealer: undefined,
            seller: undefined,
            dealer_seller: undefined,
            client: undefined
        });
        localStorage.setItem('config', JSON.stringify(config));

        // dealer
        if (!!data.dealer) {
            localStorage.setItem('dealer', (JSON.stringify(data.dealer)));
            localStorage.setItem('dealer_' + data.dealer.user_id, JSON.stringify(data.dealer));
        } else {
            localStorage.removeItem('dealer');
        }

        // producent
        if (!!data.seller) {
            localStorage.setItem('seller', (JSON.stringify(data.seller)));
            localStorage.setItem('seller_' + data.seller.user_id, JSON.stringify(data.seller));
        } else {
            localStorage.removeItem('seller');
        }

        // handlowiec dealera
        if (!!data.dealer_seller) {
            localStorage.setItem('dealer_seller', (JSON.stringify(data.dealer_seller)));
            localStorage.setItem('dealer_seller_' + data.dealer_seller.user_id, JSON.stringify(data.dealer_seller));
        } else {
            localStorage.removeItem('dealer_seller');
        }

        // handlowiec dealera
        if (!!data.client) {
            localStorage.setItem('client', (JSON.stringify(data.client)));
            localStorage.setItem('client_' + data.client.user_id, JSON.stringify(data.client));
        } else {
            localStorage.removeItem('client');
        }
    } else {
        throw new Error('Brak danych użytkownika i konfiguracji');
    }

    resolve(data);
}
