import {TimeLimit, TimeLimitSegment} from './time_limit_functions';

export default class TimeLimitHandleService {

    /**
     * Pobiera terminy realizacji klamek.
     *
     * @param {object} sashes   Kwatery
     * @return {number} Terminy realizacji klamek.
     */
    @TimeLimit({
        shortName: 'handle',
        data: {
            sashes : 'conf.Sashes',
        }
    })
    handleTimeLimit({timeLimits, sashes}): TimeLimitSegment[] {
        if (sashes == null || !sashes.length) {
            return null;
        }

        const segments = [];

        sashes.forEach(sash => {
            segments.push(this.getTimeLimitFromHandle(timeLimits, sash));
        });

        return segments;
    }

    getTimeLimitFromHandle(timeLimits, sash) {
        if (!sash.handle || !sash.handle.time_limit_id) {
            return;
        }

        const timeLimitId = sash.handle.time_limit_id;

        return {
            type: 'handle',
            value: timeLimits[timeLimitId],
            data: {
                id  : sash.handle.id,
                name: sash.handle.name,
            }
        };
    }
}
