/**
 * Kontroler poszycia markiz
 * @param {object} $scope                   Scope
 * @param {object} AwningSheathingFactory   Fabryka poszycia markiz
 */
export default function SheathingCtrl($scope, AwningSheathingFactory, EventBusService) {
    'ngInject';

    var vm = this;

    vm.subStep        = 4;
    vm.fabrics        = [];
    vm.fabric         = AwningSheathingFactory.fabric;
    vm.style          = AwningSheathingFactory.style;
    vm.selectFabric   = selectFabric;
    vm.openModalStyle = openModalStyle;
    vm.valid          = AwningSheathingFactory.valid;

    $scope.$on('changedStep', function(event, newVal, oldVal) {
        if (newVal.code !== 'sheathing' && oldVal.code === 'sheathing') {
            vm.valid();
        }
    });

    if (AwningSheathingFactory.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    /**
     * Funkcja inicjujaca
     */
    function init() {
        vm.fabrics = AwningSheathingFactory.fabrics;
        vm.fabric = AwningSheathingFactory.fabric;
        vm.style = AwningSheathingFactory.style;
    }

    /**
     * Wybieranie matriału
     * @param  {Object} fabric Materiał
     */
    function selectFabric(fabric) {
        AwningSheathingFactory.selectFabric(fabric, false, true);
    }

    /**
     * Styl otwartego modalu
     */
    function openModalStyle() {
        AwningSheathingFactory.openModalStyle();
    }
}
