import {
    Accessory
} from '../common';
import {ConfiguratorsDataService} from '../../../configurators/configurators-data.service';
import Common from '../../../Common';

export class ActiveAccessory {

    count = 0;
    amount = 0;
    position = 0;
    width = 0;
    height = 0;
    weight = 0;
    accessory: any = {};
    pos = 0;
    side: any = null;
    color: any = null;
    category: string = '';
    colorOptions: string = null;
    comment: string = null;

    constructor(accessory: Accessory, place = null, configuratorsDataService?: ConfiguratorsDataService) {
            this.count = accessory.count;
            this.position = accessory.position;
            this.amount = accessory.amount;
            this.width = accessory.width;
            this.height = accessory.height;
            this.weight = accessory.weight;
            this.accessory = configuratorsDataService.get('windowAccessories', accessory.id);
            this.accessory.currentLength = accessory.currentLength;
            this.accessory.currentWidth = accessory.width;
            this.accessory.currentHeight = accessory.height;
            this.accessory.fromDependency = accessory.fromDependency;
            this.pos = 0;
            this.side = place;
            this.color = null;
            this.category = '';
            this.colorOptions = accessory.colorSet;
            this.comment = accessory.comment;
            if (accessory.priceSource === 'table') {
                this.accessory.colorOptions = accessory.colorSet;
            }
            if (accessory.priceSource === 'colors') {
                this.color = accessory.color.accessory.id;
                this.accessory.selectedColor = accessory.color.accessory.id;
                this.accessory.color = configuratorsDataService.get('windowHandlesColors', accessory.color.accessory.id);
                this.accessory.color.isDefault = false;
            }
            if (accessory.priceSource === 'confColors') {
                this.accessory.selectedColor = {
                    frame: {
                        outer: {},
                        inner: {},
                        core: {}
                    },
                    sash: {
                        outer: {},
                        inner: {},
                        core: {}
                    }
                };
                ['outer', 'inner', 'core'].forEach(side => {
                    if (accessory.color.profile[side] && accessory.color.profile[side].id) {
                        if (accessory.color.profile[side].RAL) {
                            this.accessory.selectedColor.frame[side] = configuratorsDataService.get('windowColorRals', accessory.color.profile[side].id);
                            this.accessory.selectedColor.sash[side] = configuratorsDataService.get('windowColorRals', accessory.color.profile[side].id);
                        } else {
                            this.accessory.selectedColor.frame[side] = configuratorsDataService.get('windowColorsAll', accessory.color.profile[side].id);
                            this.accessory.selectedColor.sash[side] = configuratorsDataService.get('windowColorsAll', accessory.color.profile[side].id);
                        }
                        this.accessory.selectedColor.frame[side].isDefault = accessory.color.profile[side].isDefault;
                        this.accessory.selectedColor.sash[side].isDefault = accessory.color.profile[side].isDefault;
                    }
                });
                if (accessory.wood) {
                    this.accessory.selectedWood = configuratorsDataService.get('woodTypes', accessory.wood.id);
                }
            }
    }
}
