import { ActiveConfiguration } from './ActiveConfiguration';
import AccessoriesConfiguration from './AccessoriesConfiguration';
import { PriceSegment, DiscountGroups, PricePart } from '../price/Prices';
import { TimeLimitSegment } from '../../application/src/time_limit/time_limit_functions';
import { ActiveAccessory } from './parts/common';
import { ConfiguratorsDataService } from '../configurators/configurators-data.service';

export default class AccessoriesActiveConfiguration implements ActiveConfiguration {
    public static is(configuration): configuration is AccessoriesActiveConfiguration {
        return configuration instanceof AccessoriesActiveConfiguration || !configuration.$version;
    }
    type: 'accessory' = 'accessory';
    Name: string = '';
    Price: number = 0;
    PriceNoMargin: number = 0;
    PriceAfterDiscounts: number = 0;
    Quantity: number = 1;
    Issues: any[] = [];
    PriceSegments: PriceSegment[] = [];
    PriceSegmentsNoMargin: PriceSegment[] = [];
    PriceParts: PricePart[] = [];
    PricePartsNoMargin: PricePart[] = [];
    DiscountGroups: DiscountGroups = {};
    DiscountGroupsNoMargin: DiscountGroups = {};
    timeLimit: number = 0;
    timeLimitsStack: TimeLimitSegment[] = [];
    Title = '';
    Description = '';
    Attachments = [];
    drawData: any;
    valid: {
        accessories: null | boolean;
    } = {
        accessories: null,
    };
    PriceValid = false;

    Accessories: any[] = [];
    SideAccessories = {
        sizes: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
        top: [],
        bottom: [],
        left: [],
        right: [],
    };

    constructor(configuration?, private configuratorsDataService?: ConfiguratorsDataService) {
        if (configuration) {
            if (AccessoriesActiveConfiguration.is(configuration)) {
                for (const propName in configuration) {
                    if (this.hasOwnProperty(propName)) {
                        this[propName] = configuration[propName];
                    }
                }
            } else {
                if (AccessoriesConfiguration.is(configuration) && configuratorsDataService) {
                    this.Name = configuration.name;
                    this.Price = configuration.price;
                    this.PriceNoMargin = configuration.priceNoMargin;
                    this.PriceAfterDiscounts = configuration.priceAfterDiscounts;
                    this.Quantity = configuration.quantity;
                    this.PriceSegments = configuration.priceSegments;
                    this.PriceSegmentsNoMargin = configuration.priceSegmentsNoMargin;
                    this.PriceParts = configuration.priceParts;
                    this.PricePartsNoMargin = configuration.pricePartsNoMargin;
                    this.DiscountGroups = configuration.discountGroups;
                    this.DiscountGroupsNoMargin = configuration.discountGroupsNoMargin;
                    this.Title = configuration.title;
                    this.Description = configuration.description;
                    this.valid = configuration.valid;
                    this.timeLimit = configuration.timeLimit;
                    this.timeLimitsStack = configuration.timeLimitsStack;

                    this.Accessories = configuration.accessories.map(
                        accessory => new ActiveAccessory(accessory, null, configuratorsDataService)
                    );
                    this.SideAccessories.top = configuration.sideAccessories.top.map(
                        accessory => new ActiveAccessory(accessory, 'top', configuratorsDataService)
                    );
                    this.SideAccessories.bottom = configuration.sideAccessories.bottom.map(
                        accessory =>
                            new ActiveAccessory(accessory, 'bottom', configuratorsDataService)
                    );
                    this.SideAccessories.left = configuration.sideAccessories.left.map(
                        accessory =>
                            new ActiveAccessory(accessory, 'left', configuratorsDataService)
                    );
                    this.SideAccessories.right = configuration.sideAccessories.right.map(
                        accessory =>
                            new ActiveAccessory(accessory, 'right', configuratorsDataService)
                    );
                    this.SideAccessories.sizes.top = this.SideAccessories.top.reduce(
                        (prev, accessory) => (prev += accessory.count * accessory.accessory.height),
                        0
                    );
                    this.SideAccessories.sizes.bottom = this.SideAccessories.bottom.reduce(
                        (prev, accessory) => (prev += accessory.count * accessory.accessory.height),
                        0
                    );
                    this.SideAccessories.sizes.left = this.SideAccessories.left.reduce(
                        (prev, accessory) => (prev += accessory.count * accessory.accessory.height),
                        0
                    );
                    this.SideAccessories.sizes.right = this.SideAccessories.right.reduce(
                        (prev, accessory) => (prev += accessory.count * accessory.accessory.height),
                        0
                    );

                    ['top', 'bottom', 'left', 'right'].forEach(side => {
                        this.SideAccessories[side].reduce((prev, cur) => {
                            if (prev) {
                                cur.pos = prev.pos + prev.count * prev.accessory.height;
                            }
                            return cur;
                        }, null);
                    });
                }
            }
        }
        this.configuratorsDataService = null;
    }
}
