import 'angular-ui-bootstrap';
import { core } from 'helpers';
import ConfigurationsService from 'configurations/configurations.service';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import { EventBusService } from 'event-bus.service';

export default class ModalProfileColorsCtrl {
    config = {
        type: 'window',
        Colors:
            this.selectedProfile
            && this.selectedProfile.selectedColor
            && this.selectedProfile.selectedColor.frame
                ? this.selectedProfile.selectedColor
                : {
                      frame: {
                          core: {},
                          outer: {},
                          inner: {},
                          alushell: {},
                      },
                      sash: {
                          core: {},
                          outer: {},
                          inner: {},
                          alushell: {},
                      },
                  },
        System: {
            type: 'pvc',
        },
        ColorType: 'White',
        Wood:
            this.selectedProfile && this.selectedProfile.selectedWood
                ? this.selectedProfile.selectedWood
                : {},
        valid: {},
    };

    configDefault = {
        type: 'window',
        Colors:
            this.selectedProfile
            && this.selectedProfile.selectedColor
            && this.selectedProfile.selectedColor.frame
                ? this.selectedProfile.selectedColor
                : {
                      frame: {
                          core: {},
                          outer: {},
                          inner: {},
                          alushell: {},
                      },
                      sash: {
                          core: {},
                          outer: {},
                          inner: {},
                          alushell: {},
                      },
                  },
        System: {
            type: 'pvc',
        },
        ColorType: 'White',
        Wood:
            this.selectedProfile && this.selectedProfile.selectedWood
                ? this.selectedProfile.selectedWood
                : {},
        valid: {},
    };

    hasCream = false;

    constructor(
        private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        private profile,
        private profilesPrices,
        private selectedProfile,
        private ColorsFactory,
        private configurationsService: ConfigurationsService,
        private eventBusService: EventBusService
    ) {
        const conf = this.configurationsService.conf.Current;

        if (WindowActiveConfiguration.is(conf)) {
            this.config.System.type = this.configDefault.System.type = conf.System.type || null;
        }

        this.hasCream = ColorsFactory.windowColors.some(
            el => el.sides && el.sides.indexOf('F|C') && el.type === 'cream'
        );

        ColorsFactory.loadData();
        this.setDefaults();

        if (
            this.profilesPrices
            && this.profilesPrices[profile.id]
            && this.profilesPrices[profile.id][
                WindowActiveConfiguration.is(conf) ? conf.System.id : 'default'
            ]
            && this.profilesPrices[profile.id][
                WindowActiveConfiguration.is(conf) ? conf.System.id : 'default'
            ][profile.type]
        ) {
            this.filter = group =>
                group.systems
                && ((WindowActiveConfiguration.is(conf) && group.systems.includes(conf.System.id))
                    || !WindowActiveConfiguration.is(conf))
                && group.target.includes('show')
                && this.profilesPrices[profile.id][
                    WindowActiveConfiguration.is(conf) ? conf.System.id : 'default'
                ][profile.type].some(
                    o => o.colorGroup === group.id || o.colorGroupOut === group.id
                );
        } else {
            this.filter = group =>
                ((WindowActiveConfiguration.is(conf)
                    && group.systems
                    && group.systems.includes(conf.System.id))
                    || !WindowActiveConfiguration.is(conf))
                && group.target.includes('show')
                && ((WindowActiveConfiguration.is(conf)
                    && (conf.System.type !== 'wood'
                        || (Array.isArray(group.woodTypes)
                            && group.woodTypes.includes(conf.Wood.id))))
                    || !WindowActiveConfiguration.is(conf));
        }
    }

    setColorType(type) {
        this.ColorsFactory.setColorType(
            type,
            false,
            this.config,
            this.configDefault,
            this.filterColors.bind(this)
        );

        this.config.Colors.frame.outer = this.configDefault.Colors.frame.outer = {};
        this.config.Colors.frame.inner = this.configDefault.Colors.frame.inner = {};

        this.config.Colors.sash = this.config.Colors.frame;
        this.configDefault.Colors.sash = this.configDefault.Colors.frame;

        this.eventBusService.post({
            key: 'setProfileColor',
            value: {
                color: this.config.Colors,
                profile: this.profile,
            },
        });
    }

    openModalWood() {
        this.ColorsFactory.openModalWood(
            this.config,
            this.configDefault,
            this.setDefaults.bind(this)
        );
    }

    filter = group => true;
    filterColors = color => true;

    setDefaults() {
        this.ColorsFactory.loadData();
        this.ColorsFactory.loadColorsBySystem(this.filterColors.bind(this));

        this.ColorsFactory.setDefaultColorTypeForSystem(this.config, this.configDefault);
        this.ColorsFactory.setDefaultWood(this.config, this.configDefault);
        this.ColorsFactory.loadColorsByWood(this.config);
        this.ColorsFactory.setDefaultColors(
            false,
            this.config,
            this.configDefault,
            this.filter.bind(this)
        );
        this.ColorsFactory.setDefaultColorTypeForColors(this.config, this.configDefault);
    }

    close() {
        this.$uibModalInstance.close({ colors: this.config.Colors, wood: this.config.Wood });
    }
}
ModalProfileColorsCtrl.$inject = [
    '$uibModalInstance',
    'profile',
    'profilesPrices',
    'selectedProfile',
    'ColorsFactory',
    'ConfigurationsService',
    'EventBusService',
];
