import { Injectable, Inject } from '@angular/core';
import { ActiveConfiguration } from 'configurations/ActiveConfiguration';
import { EventBusService } from '../../../common/event-bus.service';
import StepFactory, { ChangedStepValue } from './steps/StepFactory';
import { IssuesService, IssueLevel } from 'issues.service';
import { Issue } from 'issues/Issue';

interface RegisterDataProblemOptions {
    level?: IssueLevel;
    logLevel?: IssueLevel;
    showMessage?: boolean;
    blockAddToOffer?: boolean;
    blockStepsAfter?: string | null;
    noPrice?: boolean;
    tags?: {
        [id: string]: string;
    };
    extra?: any;
}

interface SimpleRegisterOptions {
    level?: IssueLevel;
    logLevel?: IssueLevel;
    showMessage?: boolean;
    blockAddToOffer?: boolean;
    blockStepsAfter?: string | null;
    noPrice?: boolean;
    tags?: {
        [id: string]: string;
    };
    extra?: any;
}

@Injectable()
export class BrowserIssuesService extends IssuesService {
    constructor(
        @Inject('$translate') private $translate,
        @Inject('InfoFactory') private infoFactory,
        private eventBusService: EventBusService,
        private stepFactory: StepFactory
    ) {
        super();
        this.eventBusService.subscribe<ChangedStepValue>('changedStep', data => {
            const issuesBlockingSteps = data.activeConfiguration.Issues.filter(
                (issue: Issue) => issue.blockStepsAfter
            );

            if (issuesBlockingSteps.length > 0) {
                const minAllowedStep = issuesBlockingSteps
                    .map((issue: Issue) => stepFactory.getStepByCode(issue.blockStepsAfter))
                    .reduce((prev, cur) => (cur < prev ? cur : prev), Infinity);
                if (data.value.nextStep.i > minAllowedStep) {
                    stepFactory.selectStep(data.value.prevStep.i);
                }
            }
        });
    }

    registerDataProblem(
        key: string,
        description: string,
        conf: ActiveConfiguration,
        options: RegisterDataProblemOptions = {}
    ) {
        const registerOptions: SimpleRegisterOptions = options;
        this.simpleRegister(
            key,
            description,
            this.$translate.instant(
                'INTERFACE|Wybrany produkt nie może być aktualnie skonfigurowany. Skontaktuj się ze swoim opiekunem po stronie producenta'
            ),
            conf,
            registerOptions
        );
    }

    showMessage(issue) {
        if (this.modalOpened) {
            this.modalOpened = false;
            this.infoFactory.openWarning(issue.message).finally(() => {
                this.modalOpened = true;
            });
        }
    }
}
