import angular from 'angular';

/**
 * Kontroler typów
 * @param {object} $scope       Angular Scope
 * @param {object} TypesFactory Fabryka typów
 */
export default function TypesCtrl($scope, $rootScope, TypesFactory, RollerDimensionsFactory, EventBusService) {
    'ngInject';

    var vm = this;

    vm.types = [];
    vm.selectRollerType = TypesFactory.selectRollerType;

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        vm.types = TypesFactory.types;

        RollerDimensionsFactory.updateDimensions();
    }

    if (TypesFactory.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));
}
