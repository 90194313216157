import Common from '../Common';
import $ from 'imports-loader?this=>window!jquery';
import {logger} from '../helpers';

export default function Base64DecoderFilter($window, $rootScope) {
    'ngInject';

    return function(data) {
        try {
            if (Common.isDefined(data)) {
                var str = $(decodeURIComponent(escape(window.atob(data))));
                str.find("rect[style*='fill:url']").each(function(el) {
                    var attr = $(this).attr('style');
                    attr = attr.replace(/https?:.*?#/, window.location.href + '#');
                    $(this).attr('style', attr);
                });
                str.find("rect[style*='stroke:url']").each(function(el) {
                    var attr = $(this).attr('style');
                    attr = attr.replace(/https?:.*?#/, window.location.href + '#');
                    $(this).attr('style', attr);
                });
                str.find("polygon[style*='fill:url']").each(function(el) {
                    var attr = $(this).attr('style');
                    attr = attr.replace(/https?:.*?#/, window.location.href + '#');
                    $(this).attr('style', attr);
                });
                str.find("polygon[style*='stroke:url']").each(function(el) {
                    var attr = $(this).attr('style');
                    attr = attr.replace(/https?:.*?#/, window.location.href + '#');
                    $(this).attr('style', attr);
                });
                str.find("circle[style*='fill:url']").each(function(el) {
                    var attr = $(this).attr('style');
                    attr = attr.replace(/https?:.*?#/, window.location.href + '#');
                    $(this).attr('style', attr);
                });
                str.find("circle[style*='stroke:url']").each(function(el) {
                    var attr = $(this).attr('style');
                    attr = attr.replace(/https?:.*?#/, window.location.href + '#');
                    $(this).attr('style', attr);
                });
                str.find("path[style*='fill:url']").each(function(el) {
                    var attr = $(this).attr('style');
                    attr = attr.replace(/https?:.*?#/, window.location.href + '#');
                    $(this).attr('style', attr);
                });
                str.find("path[style*='stroke:url']").each(function(el) {
                    var attr = $(this).attr('style');
                    attr = attr.replace(/https?:.*?#/, window.location.href + '#');
                    $(this).attr('style', attr);
                });
                str.find("line[style*='marker-start: url']").each(function(el) {
                    var attr = $(this).attr('style');
                    attr = attr.replace(/https?:.*?#/g, window.location.href + '#');
                    $(this).attr('style', attr);
                });
                str.find("line[style*='marker: url']").each(function(el) {
                    var attr = $(this).attr('style');
                    attr = attr.replace(/https?:.*?#/g, window.location.href + '#');
                    $(this).attr('style', attr);
                });
                str.find("*[mask*='url']").each(function(el) {
                    var attr = $(this).attr('mask');
                    attr = attr.replace(/https?:.*?#/g, window.location.href + '#');
                    $(this).attr('mask', attr);
                });
                str.find("*[fill*='url']").each(function(el) {
                    var attr = $(this).attr('fill');
                    attr = attr.replace(/https?:.*?#/g, window.location.href + '#');
                    $(this).attr('fill', attr);
                });
                str.find("*[stroke*='url']").each(function(el) {
                    var attr = $(this).attr('stroke');
                    attr = attr.replace(/https?:.*?#/g, window.location.href + '#');
                    $(this).attr('stroke', attr);
                });
                return str[0].outerHTML;
            }
        } catch (err) {
            logger.error(err);
        }
    };
}
