import Common from '../Common';
import {PriceFunc, PriceElemsData, PriceSegment} from './Prices';
import {CustomPricesRecords} from './CustomPrice';
import PriceAccessoryService from './price-accessory.service';
import {Injectable, Inject} from '@angular/core';
import {APP_CONFIG, AppConfig} from 'config';

/**
 * Factory dopłat za okucie.
 *
 * @export
 * @param {object} Core Core
 * @return {object} Factory
 */
@Injectable()
export default class PriceFittingService {

    constructor(
        @Inject(APP_CONFIG) private config: AppConfig,
        private PriceAccessoryService: PriceAccessoryService
    ) {}

    /**
     * Dolicza dopłatę procentową za okucie bezpieczne.
     *
     * @param {number} price         Wejsciowa cena
     * @param {object} PriceElems    Składowe wyceny
     * @param {array}  NoPriceCauses Powody braku wyceny
     * @param {array}  constrElems   Lista elementów konstrukcyjnych, do przeliczenia przez dopłatę.
     * @param {object} fitting       Okucie bezpieczne
     * @return {number} Cena z doliczoną dopłatą.
     */
    @PriceFunc({
        shortName: 'fittingPercent',
        data: {
            fitting    : 'conf.Fitting',
            system     : 'conf.System',
            customPrice: 'price.WindowFitting'
        }
    })
    suppFittingPercent({}: PriceElemsData, {fitting, system, customPrice}: {fitting, system, customPrice: CustomPricesRecords}): PriceSegment[] {
        let factors = 1;
        if (Common.isObject(fitting) && ~~fitting.price_type === 1) {
            let fittingPrice;
            if (Common.isObject(customPrice) && customPrice[fitting.id]) {
                fittingPrice = customPrice[fitting.id].getPrice('price');
            } else {
                fittingPrice = parseFloat(fitting.price);
            }

            factors *= (100 + fittingPrice) / 100;

            return <PriceSegment[]>[{
                type: 'fittingsPercent',
                baseValue: factors,
                value: factors,
                valueType: 'percent',
                data: {
                    id  : fitting.id,
                    name: fitting.name,
                }
            }];
        }
        return [];
    }

    /**
     * Dolicza dopłatę kwotową za okucie bezpieczne.
     * @param  {number} price       Wejsciowa cena
     * @param  {object} PriceElems  Wycena
     * @param  {Sash[]} sashes      Skrzydła
     * @param  {object} fitting     Okuсie bezpieczne
     * @return {number}             Cena z doliczonym okuciem
     */
    @PriceFunc({
        shortName: 'fitting',
        data: {
            fitting: 'conf.Fitting',
            sashes: 'conf.Sashes',
            customPrice: 'price.WindowFitting'
        }
    })
    suppFitting({}: PriceElemsData, {sashes, fitting, customPrice}: {sashes, fitting, customPrice: CustomPricesRecords}): PriceSegment[] {
        const priceStack: PriceSegment[] = [];
        if (Common.isObject(fitting) && ~~fitting.price_type === 0) {
            for (let i = 0; i < sashes.length; i++) {
                if (sashes[i].type.type !== 'F' && sashes[i].type.type !== 'FF' && sashes[i].type.type !== 'OFF') {
                    let fittingPrice;
                    if (Common.isObject(customPrice) && customPrice[fitting.id]) {
                        fittingPrice = customPrice[fitting.id].getPrice('price');
                    } else {
                        fittingPrice = parseFloat(fitting.price);
                    }
                    priceStack.push({
                        type: 'fittings',
                        baseValue: fittingPrice,
                        value: fittingPrice,
                        valueType: 'value',
                        data: {
                            id  : fitting.id,
                            name: fitting.name,
                        }
                    });
                }
            }
        }
        return priceStack;
    }

    /**
     * Wylicza dopłatę za zawias w danym kolorze.
     * @param  {object} handle        Klamka
     * @param  {object} PriceElems    Wycena
     * @param  {object} NoPriceCauses Powody braku ceny
     * @param  {object} handleColor   Kolor klamki
     * @param  {Sash}   sash          Skrzydło
     * @return {number}               Dopłata za klamkę
     */
    @PriceFunc({
        shortName: 'hinge',
        data: {
            hinge: 'conf.Hinge',
            hingeColor: 'conf.HingeColor',
            sashes: 'conf.Sashes'
        }
    })
    suppHinge({NoPriceCauses}: PriceElemsData, {hinge, hingeColor, sashes}): PriceSegment[] {
        if (Common.isObject(hinge)) {
            if (Common.isObject(hinge.colors_prices) && Common.isObject(hingeColor) && Common.isDefined(hinge.colors_prices[hingeColor.id])) {
                let price = parseFloat(hinge.colors_prices[hingeColor.id]);
                price += parseFloat(hinge.price_for_assembly);
                const count = sashes.reduce((prev, cur) => {
                    return prev + (['DRA', 'DOA', 'DRP', 'DOP'].indexOf(cur.type.type) > -1 ? 1 : 0);
                }, 0);
                return <PriceSegment[]>[{
                    type: 'hinge',
                    baseValue: price * count,
                    value: price * count,
                    valueType: 'value',
                    data: {
                        id  : hinge.id,
                        name: hinge.name,
                        colorName: hingeColor.name,
                        count,
                    }
                }];
            } else {
                if (this.config.IccConfig.Configurators.door.version === 2) {
                    NoPriceCauses.push('no hinge price');
                    return <PriceSegment[]>[{
                        type: 'hinge',
                        baseValue: null,
                        value: null,
                        valueType: 'value',
                        data: {
                            id  : hinge.id,
                            name: hinge.name,
                            colorName: hingeColor.name,
                        }
                    }];
                } else {
                    return [];
                }
            }
        } else {
            return [];
        }
    }

    /**
     * Dolicza dopłatę za zamek.
     * @param  {number} price        Cena wejściowa
     * @param  {object} PriceElems   Wycena
     * @param  {object} NoPriceElems Elementy bez wyceny
     * @param  {array}  accessories  Tablica z akcesoriami
     * @param  {object} colors       Kolory konstrukcji
     * @param  {string} type         Rodzaj konfiguracji
     * @return {number}              Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'lock',
        data: {
            lock: 'conf.Lock',
            colors: 'conf.Colors',
            conf: 'conf.type'
        }
    })
    suppLock({}: PriceElemsData, {lock, colors, conf}): PriceSegment[] {
        let supp      = 0;
        if (this.config.IccConfig.Configurators.door.version < 2 || this.config.IccConfig.Configurators.door.singleFix && !lock.id) {
            return [];
        }

        let field = this.PriceAccessoryService.getAccessoryPriceField(colors, conf);
        if (!lock.no_price) {
            supp = parseFloat(lock[field]);
            supp += parseFloat(lock.price_for_assembly || 0);
        } else {
            supp = NaN;
        }
        return <PriceSegment[]>[{
            type: 'lock',
            baseValue: !isNaN(supp) ? supp : null,
            value: !isNaN(supp) ? supp : null,
            valueType: 'value',
            data: {
                id  : lock.id,
                name: lock.name,
                colorField: this.PriceAccessoryService.getAccessoryPriceField(colors, conf),
            }
        }];
    }
}
