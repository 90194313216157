import Common from 'Common';

import {core} from 'helpers';
import {Injectable, Inject} from '@angular/core';
import {EventBusService} from 'event-bus.service';
import {DatabaseManagerToken} from 'sync/Databases/DatabaseManager';
import {CustomPricesService} from '../../../common/price/custom-prices.service';

/**
 * Usługa indywidualnych cen.
 *
 * @export
 * @class CustomPricesService
 */
@Injectable()
export class BrowserCustomPricesService extends CustomPricesService {
    /**
     * Creates an instance of CustomPricesService.
     *
     * @param {DatabaseManager} DatabaseManager
     *
     * @memberOf CustomPricesService
     */
    constructor(
        @Inject(DatabaseManagerToken) private DatabaseManager,
        private eventBusService: EventBusService,
    ) {
        super();
        this.load();

        this.eventBusService.subscribeWithoutConfiguration('syncedCustomPrices', data => {
            this.load();
        });
    }

    /**
     * Ładuje indywidaulne ceny z bazy.
     *
     * @async
     * @memberOf CustomPricesService
     */
    async load() {
        const data: any = await this.DatabaseManager.get('CustomPrices').get();
        if (data && data.data) {
            const parsedData = core.parseJson(data.data);
            let allDealers = false;
            if (Common.isObject(parsedData)) {
                for (const i in parsedData.customPrices) {
                    if (!isNaN(parseFloat(i))) {
                        allDealers = true;
                        for (const j in parsedData.customPrices[i]) {
                            this.parseRecords(parsedData.customPrices[i][j]);
                        }
                    } else {
                        this.parseRecords(parsedData.customPrices[i]);
                    }
                }
            }
            if (allDealers) {
                this.customPricesDealers = parsedData.customPrices || {};
            } else {
                this.customPrices = parsedData.customPrices || {};
            }
        }
    }
}
