import angular from 'angular';
import { OUTWARD_OPENING } from 'configurations/parts/window/sash-types';
import { IssueLevel } from 'issues.service';

/**
 * Fabryka skrzydeł
 * @param {object} $uibModal                   Dostarcza obsługę okien modalnych
 * @param {object} $rootScope               Angular Root Scope
 * @param {object} $timeout                 Timeout
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} ConfiguratorsDataService Fabryka danych konfiguracji
 * @param {object} CurConfService           Bieżaca konfiguracja
 * @param {object} Core                     Core
 * @param {object} LayoutFactory            Fabryka layoutów
 * @param {obejct} PriceService             Liczenie cen
 * @param {object} GlazingFactory           Fabryka szklenia
 * @param {object} ParametersService        Fabryka parametrów
 */
export default function SashesFactory(
    $uibModal,
    $rootScope,
    ConfigurationsService,
    ConfiguratorsDataService,
    CurConfService,
    Core,
    PriceService,
    IccConfig,
    ProfilesService,
    EventBusService,
    IssuesService,
    $translate,
    SashTypesService
) {
    'ngInject';

    var b2c =
        !$rootScope.user
        || !$rootScope.user.access
        || $rootScope.user.access == 'klient'
        || IccConfig.Configurators.B2CSashesInB2B;
    var sashTypes = [];

    var factory = {
        sills: [],
        layouts: [],
        loadedData: false,

        openSashModal,
        openSashTypeModal,
        addSill,
        setSill,
        canBeSashTypeChanged
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });
    EventBusService.subscribe('changedSashes', data => validateSashes(data.activeConfiguration));
    $rootScope.$on('openSashTypeModalOne', () => {
        openSashTypeModalOne();
    });

    return factory;

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        if (
            CurConfService.conf == 'accessory'
            || CurConfService.conf == 'roller_shutter'
            || CurConfService.conf == 'winter_garden'
        ) {
            return;
        }
        sashTypes = ConfiguratorsDataService.data.sashTypes;

        factory.sills = ConfiguratorsDataService.data.windowSills;
        factory.layouts = ConfiguratorsDataService.layouts;
        factory.loadedData = true;
    }

    /**
     * Funkcja otwierania okna modal skzydeł
     */
    function openSashModal() {
        $rootScope.hideDraw = true;
        var modalInstance = $uibModal.open({
            templateUrl: 'modalSashLayout.html',
            controller: 'ModalSashCtrl as msash',
            windowClass: 'modal-sash',
        });
        if (IccConfig.Configurators.tutorialAvailable) {
            EventBusService.post({
                key: 'tutorialSteps',
                value: 'sashesModal',
            });
        }

        modalInstance.result.finally(() => {
            if (IccConfig.Configurators.dependencies) {
                EventBusService.post({ key: 'processDependencies', value: null });
            }

            // przerysuj rysunek
            EventBusService.post({
                key: 'icc-redraw',
                value: 'frame',
            });
        });

        modalInstance.closed.then(() => {
            if (IccConfig.Configurators.tutorialAvailable) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'editSash',
                });
            }
        });
    }

    /**
     * Funkcja otwierania okna modal typów skrzydeł
     */
    function openSashTypeModalOne() {
        openSashTypeModal(ConfigurationsService.conf.Current.Sashes[0]);
    }

    /**
     * Funkcja otwierania okna modal typów skrzydeł
     * @param  {[type]} sash [description]
     * @return {[type]}      [description]
     */
    function openSashTypeModal(sash) {
        // brak skrzydła, bład powodowany za szybkim klikaniem (kliknięciem w podział i kliknięciem w wybór skrzydła przed podzieleniem)
        if (angular.isUndefined(sash) || sash === null) {
            return;
        }

        const conf = ConfigurationsService.conf.Current;
        const filteredProfiles = ProfilesService.getFilteredProfiles(conf, 'sash', {and: ['bottom']});

        var modalInstance = $uibModal.open({
            templateUrl: 'modalSashType.html',
            controller: 'ModalSashTypeCtrl as msashType',
            resolve: {
                profiles() {
                    return filteredProfiles;
                },
                sashTypes() {
                    return sashTypes;
                },
                sash() {
                    return sash;
                },
            },
        });

        modalInstance.closed.then(() => {});

        modalInstance.result.then(selectedSashType => {
            if (typeof selectedSashType !== 'undefined') {
                var divs = ConfigurationsService.conf.Current.Mullions;
                var lDiv = divs[Core.fId(divs, sash.nearMullions.left)];
                var rDiv = divs[Core.fId(divs, sash.nearMullions.right)];

                const falseMullionsMap = {
                    DS: 'DK',
                    DSH: 'DK',
                    DSC: 'DK',
                    ODS: 'OD',
                    ODSH: 'OD',
                    DRP: 'DRA',
                    DOP: 'DOA',
                    S: 'S',
                    HS: 'HS',
                    PSK: 'PSK',
                };
                SashTypesService.setType(
                    sash,
                    selectedSashType,
                    ConfigurationsService.conf.Current
                );

                if (falseMullionsMap[selectedSashType.type] && selectedSashType.passive) {
                    if (selectedSashType.handle_position === 'R' && rDiv.multiAlignRight[0]) {
                        const sashType = sashTypes.find(
                            s =>
                                s.type === falseMullionsMap[selectedSashType.type]
                                && s.handle_position === 'L'
                                && !s.passive
                        );
                        if (sashType) {
                            SashTypesService.setType(
                                rDiv.multiAlignRight[0],
                                sashType,
                                ConfigurationsService.conf.Current
                            );
                        }
                    }
                    if (selectedSashType.handle_position === 'L' && lDiv.multiAlignLeft[0]) {
                        const sashType = sashTypes.find(
                            s =>
                                s.type === falseMullionsMap[selectedSashType.type]
                                && s.handle_position === 'R'
                                && !s.passive
                        );
                        if (sashType) {
                            SashTypesService.setType(
                                lDiv.multiAlignLeft[0],
                                sashType,
                                ConfigurationsService.conf.Current
                            );
                        }
                    }
                }

                // przerysuj rysunek
                EventBusService.post({
                    key: 'icc-redraw',
                    value: 'frame',
                });
            }
        });
    }

    /**
     * Funkcja dodawania parapetu (listwy?)
     */
    function addSill() {
        if (ConfigurationsService.conf.Current.HasSill) {
            ConfigurationsService.conf.Current.Sill = angular.copy(factory.sills[0]);
        }
        PriceService.count();
    }

    /**
     * Ustawianie parapetu
     */
    function setSill() {
        var newSill = angular.copy(
            Core.fIdO(factory.sills, ConfigurationsService.conf.Current.Sill.id)
        );
        ConfigurationsService.conf.Current.Sill = angular.copy(newSill);
        PriceService.count();
    }

    /**
     * Funkcja sprawdzająca poprawność skrzydeł
     * @param  {object} conf Konfiguracja
     */
    function validateSashes(conf) {
        if (
            !conf.Sashes.filter(sash => sash.type.type === 'F').every(
                sash =>
                    (sash.nearMullions.left === -1
                        || !conf.Mullions.find(
                            mullion => mullion.id == sash.nearMullions.left
                        ).multiAlignLeft.some(
                            el =>
                                el.type.out_open
                                && (!IccConfig.Configurators.allowFixAndOutOpenSash
                                    || [...OUTWARD_OPENING, 'OFF'].indexOf(el.type.type) == -1)
                        ))
                    && (sash.nearMullions.right === -1
                        || !conf.Mullions.find(
                            mullion => mullion.id == sash.nearMullions.right
                        ).multiAlignRight.some(
                            el =>
                                el.type.out_open
                                && (!IccConfig.Configurators.allowFixAndOutOpenSash
                                    || [...OUTWARD_OPENING, 'OFF'].indexOf(el.type.type) == -1)
                        ))
                    && (sash.nearMullions.top === -1
                        || !conf.Mullions.find(
                            mullion => mullion.id == sash.nearMullions.top
                        ).multiAlignTop.some(
                            el =>
                                el.type.out_open
                                && (!IccConfig.Configurators.allowFixAndOutOpenSash
                                    || [...OUTWARD_OPENING, 'OFF'].indexOf(el.type.type) == -1)
                        ))
                    && (sash.nearMullions.bottom === -1
                        || !conf.Mullions.find(
                            mullion => mullion.id == sash.nearMullions.bottom
                        ).multiAlignBottom.some(
                            el =>
                                el.type.out_open
                                && (!IccConfig.Configurators.allowFixAndOutOpenSash
                                    || [...OUTWARD_OPENING, 'OFF'].indexOf(el.type.type) == -1)
                        ))
            )
        ) {
            IssuesService.simpleRegister(
                'incorrect-sashes',
                'Konstrukcję należy skonfigurować w dwóch ramach.',
                $translate.instant('WINDOW|Konstrukcję należy skonfigurować w dwóch ramach.'),
                conf,
                {
                    logLevel: IssueLevel.NONE,
                }
            );
        } else {
            IssuesService.unregister('incorrect-sashes', conf);
        }
    }

    function canBeSashTypeChanged(conf, sash) {
        const availableSashTypes = conf.System.available_sash_types;
        const minWidthGlass = IccConfig.Configurators.minWidthGlass;

        let canBeInner =
            availableSashTypes && availableSashTypes.length
                ? availableSashTypes.some(t =>
                      sashTypes.some(st => st.id === t && !st.out_open)
                  )
                : true;
        let canBeOuter =
            availableSashTypes && availableSashTypes.length
                ? availableSashTypes.some(t =>
                      sashTypes.some(st => st.id === t && st.out_open)
                  )
                : true;

        if (conf.drawData) {
            const glazingBeadData = conf.drawData.glazingBead.find(gb => gb.sashId === sash.id);
            const sashData = conf.drawData.sash.find(s => s.sashId === sash.id);
            const profileInner =  ProfilesService.getProfile(conf.ProfileSet['sash']);
            const profileOuter =  ProfilesService.getProfile(conf.ProfileSet['sashOutward']);
            if (glazingBeadData && sashData && (profileInner || profileOuter)) {
                const minWidthSash = glazingBeadData.inner.rect.y - sashData.outer.rect.y + minWidthGlass + (sashData.outer.rect.y + sashData.outer.rect.height) - ( glazingBeadData.inner.rect.y + glazingBeadData.inner.rect.height);
                if ((!profileInner || profileInner && canBeInner && sash.width < minWidthSash + profileInner.width * 2)
                    && (!profileOuter || profileOuter && canBeOuter && sash.width < minWidthSash + profileOuter.width * 2)) {
                    return false;
                }
            }
        }
        return true;
    }

}
