import angular from 'angular';
import { IssueLevel } from 'issues.service';

/**
 * Fabryka poszycia markiz
 * @param {object} $rootScope               RootScope
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} ConfiguratorsDataService Fabryka z danymi do konfiguratorów
 * @param {object} CurConfService           Informacje o bieżącym konfiguratorze
 * @param {object} StepFactory              StepFactory
 * @param {object} PriceService             Liczenie cen
 * @param {object} $filter                  Filtry angularowe
 * @param {object} IssuesService            Obługa błędów
 * @param {object} $uibModal                   Dostarcza obsługę okien modalnych
 */
export default function SheathingFactory($rootScope, ConfigurationsService, ConfiguratorsDataService, CurConfService,
    StepFactory, PriceService, $filter, IssuesService, $uibModal, EventBusService) {
    'ngInject';

    var factory = {
        fabrics         : [],
        fabric          : {},
        style           : {},
        selectedStyles  : [],
        loadedData      : false,
        selectFabric    : selectFabric,
        selectStyle     : selectStyle,
        setDefaultValues: setDefaultValues,
        openModalStyle  : openModalStyle,
        valid           : valid
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });
    $rootScope.$on('changedStep', init);

    return factory;

    /**
     * Funkcja inicjujaca
     */
    function init() {
        if (CurConfService.conf === 'awning') {
            setValues();
            if (angular.isUndefined(ConfigurationsService.conf.Current.Fabric.id)) {
                factory.setDefaultValues();
            }
            factory.loadedData = true;
        } else {
            return;
        }
    }

    /**
     * Ustawianie wartości
     */
    function setValues() {
        factory.fabrics = getFabrics();
    }

    /**
     * Ustawianie marteriałó
     * @return {Object} Materiały
     */
    function getFabrics() {
        return ConfiguratorsDataService.data.awningFabrics;
    }

    /**
     * Wybór materiału
     * @param  {Object} fabric   Materiał
     * @param  {Boolean} nextStep Następny krok
     * @param  {Number} price    Cena
     */
    function selectFabric(fabric, nextStep, price) {
        factory.fabric = fabric;

        ConfigurationsService.conf.Current.Fabric = fabric;
        ConfigurationsService.conf.Default.Fabric = fabric;
        ConfigurationsService.conf.Current.FabricStyle = fabric.styles[0];
        ConfigurationsService.conf.Default.FabricStyle = fabric.styles[0];

        if (!angular.isUndefined(factory.selectedStyles[factory.fabric.id])) {
            selectStyle(factory.selectedStyles[factory.fabric.id], false, true);
        } else {
            factory.style = {};
        }

        if (nextStep) {
            StepFactory.goToNextStep(true);
        }
        if (price) {
            PriceService.count();
        }
    }

    /**
     * Wybór stylu
     * @param  {Object} style    Styl
     * @param  {Boolean} nextStep Następny krok
     * @param  {Number} price    Cena
     */
    function selectStyle(style, nextStep, price) {
        factory.style = style;
        factory.selectedStyles[factory.fabric.id] = style;
        ConfigurationsService.conf.Current.FabricStyle = style;
        ConfigurationsService.conf.Default.FabricStyle = style;

        if (nextStep) {
            StepFactory.goToNextStep(true);
        }
        if (price) {
            PriceService.count();
        }
    }

    /**
     * Ustawianie wartości domyślnych
     * @param {Boolean} reset Reset
     */
    function setDefaultValues(reset) {

        if (angular.isUndefined(ConfigurationsService.conf)) {
            return;
        }

        setValues();

        if (angular.isUndefined(ConfigurationsService.conf.Current.Fabric.id) || reset) {
            factory.selectFabric(factory.fabrics[0], false, true);
            factory.selectStyle(factory.fabrics[0].styles[0], false, true);
        }
    }

    /**
     * Styl otwartego modalu
     */
    function openModalStyle() {

        var modalInstance = $uibModal.open({
            templateUrl: 'modalFabricStyles.html',
            controller : 'ModalFabricStylesCtrl as mFabricStyles',
            resolve    : {
                styles: function() {
                    return factory.fabric.styles;
                }
            }
        });

        modalInstance.result.then(function(selectedStyle) {
            factory.selectStyle(selectedStyle[0], false, true);
        });
    }

    /**
     * Walidacja
     * @return {Object} Walidacja
     */
    function valid() {

        if (!ConfigurationsService.conf.Current.FabricStyle.id) {
            IssuesService.simpleRegister(
                'sheathing',
                'Wybierz poszycie.',
                $filter('translate')('AWNING|Wybierz poszycie.'),
                ConfigurationsService.conf.Current,
                {
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }

        IssuesService.unregister('sheathing', ConfigurationsService.conf.Current);
        return true;
    }
}
