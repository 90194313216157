import { Configuration } from './Configuration';
import { PriceSegment, DiscountGroups, PricePart } from '../price/Prices';
import {
    System,
    Variant,
    Colors,
    Montage,
    WindowLine,
    ProfilePositions,
    MuntinPosition,
} from './parts/mosquito';

import MosquitoActiveConfiguration from './MosquitoActiveConfiguration';

export default class MosquitoConfiguration implements Configuration {

    public static is(configuration): configuration is MosquitoConfiguration {
        return configuration instanceof MosquitoConfiguration || configuration.$version;
    }
    $version = 5;
    type: 'mosquito' = 'mosquito';
    name = '';
    price: number | null = null;
    priceNoMargin: number | null = null;
    priceAfterDiscounts: number | null = null;
    quantity: number = 1;
    priceSegments: PriceSegment[] = [];
    priceSegmentsNoMargin: PriceSegment[] = [];
    priceParts: PricePart[] = [];
    pricePartsNoMargin: PricePart[] = [];
    discountGroups: DiscountGroups = {};
    discountGroupsNoMargin: DiscountGroups = {};
    title = '';
    description = '';
    attachments: any[] = [];
    valid: {} = {};

    system: System = null;
    height: number = 1300;
    width: number = 1300;
    heightModify: number = 0;
    widthModify: number = 0;
    circuit: number = 0;
    variant: Variant = null;
    colors: Colors = {
        frame: null,
        net: null,
        panel: null,
    };
    direction: 'L' | 'R' | null = null;
    montage: Montage = null;
    windowLine: WindowLine = null;
    profilePositions: ProfilePositions = {};
    holdersWidth: number = null;
    muntinPosition: MuntinPosition = null;

    constructor(configuration) {
        if (MosquitoConfiguration.is(configuration)) {
            for (const propName in configuration) {
                if (this.hasOwnProperty(propName)) {
                    this[propName] = configuration[propName];
                }
            }
        } else {
            if (MosquitoActiveConfiguration.is(configuration)) {
                this.name = configuration.Name;
                this.price = configuration.Price;
                this.priceNoMargin = configuration.PriceNoMargin;
                this.priceAfterDiscounts = configuration.PriceAfterDiscounts || configuration.Price;
                this.quantity = configuration.Quantity;
                this.priceSegments = configuration.PriceSegments;
                this.priceSegmentsNoMargin = configuration.PriceSegmentsNoMargin;
                this.priceParts = configuration.PriceParts;
                this.pricePartsNoMargin = configuration.PricePartsNoMargin;
                this.discountGroups = configuration.DiscountGroups;
                this.discountGroupsNoMargin = configuration.DiscountGroupsNoMargin;
                this.title = configuration.Title;
                this.description = configuration.Description;
                this.attachments = configuration.Attachments;
                this.valid = configuration.valid;

                this.system = {
                    id: Number(configuration.System.id),
                    name: configuration.System.name,
                    titleImage: configuration.System.title_image,
                };
                this.height = Number(configuration.Height);
                this.width = Number(configuration.Width);
                this.heightModify = Number(configuration.HeightModify);
                this.widthModify = Number(configuration.WidthModify);
                this.circuit = Number(configuration.Shape.circuit);
                if (configuration.Type && configuration.Type.id) {
                    this.variant = {
                        id: Number(configuration.Type.id),
                        name: configuration.Type.name,
                        hasDirection: configuration.Type.has_direction,
                        profilePosition: configuration.Type.profile_position,
                        extraColor: configuration.Type.extra_color,
                    };
                }

                ['frame', 'net', 'panel'].forEach(side => {
                    if (configuration.Colors[side] && configuration.Colors[side].name) {
                        this.colors[side] = {
                            id: Number(configuration.Colors[side].id),
                            name: configuration.Colors[side].name,
                            code: configuration.Colors[side].code,
                            RAL: Boolean(configuration.Colors[side].RAL),
                            isDefault: configuration.Colors[side].isDefault,
                        };
                    }
                });
                if (this.variant && this.variant.hasDirection) {
                    this.direction = configuration.Direction === 'left' ? 'L' : 'R';
                }
                if (configuration.Montage && configuration.Montage.id) {
                    this.montage = {
                        id: Number(configuration.Montage.id),
                        name: configuration.Montage.name,
                    };
                }
                if (configuration.WindowLine && configuration.WindowLine.id) {
                    this.windowLine = {
                        id: Number(configuration.WindowLine.id),
                        name: configuration.WindowLine.content,
                        windowLineId: Number(configuration.WindowLine.window_line_id),
                    };
                }
                ['up', 'down', 'left', 'right'].forEach(side => {
                    if (configuration.ProfilePositions[side]) {
                        this.profilePositions[side] = configuration.ProfilePositions[side];
                    }
                });
                this.holdersWidth = Number(configuration.HoldersWidth);
                this.muntinPosition = {
                    type: configuration.MuntinType,
                };
                if (this.muntinPosition.type === 'custom') {
                    this.muntinPosition.position = Number(configuration.MuntinPosition);
                }
            }
        }
    }
}
