import { ActiveConfiguration } from './ActiveConfiguration';

import WindowActiveConfiguration from './WindowActiveConfiguration';
import DoorConfiguration from './DoorConfiguration';
import { ConfiguratorsDataService } from '../configurators/configurators-data.service';

export default class DoorActiveConfiguration extends WindowActiveConfiguration
    implements ActiveConfiguration {
    public static is(configuration): configuration is DoorActiveConfiguration {
        return configuration instanceof DoorActiveConfiguration || !configuration.$version;
    }
    type: 'door' = 'door';

    Height = 2000;
    Width = 1000;

    Model: any = {};
    ModelGroup: any = {};
    SashesType: 'Fix' | 'FixSash' | 'Func' | 'Mix' = 'Func';
    OwnedSashesTypes = {
        window: false,
        doorActive: true,
        doorPassive: false,
    };
    HandleType = 'DoubleLever';

    constructor(configuration?, configuratorsDataService?: ConfiguratorsDataService) {
        super(configuration, configuratorsDataService);
        if (configuration) {
            if (DoorActiveConfiguration.is(configuration)) {
                for (const propName in configuration) {
                    if (this.hasOwnProperty(propName)) {
                        this[propName] = configuration[propName];
                    }
                }
            } else {
                if (DoorConfiguration.is(configuration) && configuratorsDataService) {
                    if (configuration.model) {
                        this.Model = configuratorsDataService.get(
                            'doorModels',
                            configuration.model.id
                        );
                    }
                    if (configuration.modelGroup) {
                        this.ModelGroup = configuratorsDataService.get(
                            'doorModelsGroups',
                            configuration.modelGroup.id
                        );
                    }

                    this.Height = configuration.height;
                    this.SashesType = configuration.sashesType;
                    this.Width = configuration.width;
                    this.HandleType = configuration.handleType;
                    this.OwnedSashesTypes = configuration.ownedSashesTypes;
                }
            }
        }
    }
}
