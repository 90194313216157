import { core } from '../helpers';
import Common from '../Common';
import Position from './Position';
import OfferDiscountsService from './OfferDiscountsService';
import PositionDetailedSummaryService from '../PositionDetailedSummaryService';

export default class OfferTransportCostService {
    static splitTransportCost(positions, offer, IccConfig, dealer, allDiscounts, user) {
        if (offer.split_transport_cost) {
            positions = positions.map(p =>
                this.removeTransportCost(p, offer, IccConfig, dealer, allDiscounts)
            );
        }
        const offerPriceWithoutDiscount = positions
            .filter(p => p.confType !== 'additional' && !p.coupled_position_id)
            .reduce((sum, p) => sum + p.dealer_price * p.quantity, 0);
        positions = positions.map(p =>
            this.addTransportCost(
                p,
                offer,
                IccConfig,
                dealer,
                allDiscounts,
                offerPriceWithoutDiscount
            )
        );

        return this.recalculateOfferPrice(positions, offer, IccConfig, true, user);
    }

    static joinTransportCost(positions, offer, IccConfig, dealer, allDiscounts, user) {
        positions = positions.map(p =>
            this.removeTransportCost(p, offer, IccConfig, dealer, allDiscounts)
        );
        return this.recalculateOfferPrice(positions, offer, IccConfig, false, user);
    }

    static recalculateOfferPrice(positions, offer, IccConfig, split, user) {
        Common.extend(offer, {
            dealer_price_before_discount_position: 0,
            dealer_price_before_discount: 0,
            dealer_price: 0,
            split_transport_cost: split,

            summary: {
                client: {
                    additionals: {
                        product: 0,
                        service: 0,
                    },
                    others: {
                        product: 0,
                        service: 0,
                    },
                    components: {
                        glass: 0,
                        fitting: 0,
                        addons: 0,
                        base: 0,
                        roller: 0,
                        mosquito: 0,
                        colorCost: 0,
                    },
                },
                dealer: {
                    additionals: {
                        product: 0,
                        service: 0,
                    },
                    others: {
                        product: 0,
                        service: 0,
                    },
                    components: {
                        glass: 0,
                        fitting: 0,
                        addons: 0,
                        base: 0,
                        roller: 0,
                        mosquito: 0,
                        colorCost: 0,
                    },
                },
            },
        });

        positions
            .filter(p => p.confType !== 'additional' && !p.coupled_position_id)
            .forEach(p => {
                p.details = core.parseJson(p.details);
                offer.dealer_price_before_discount_position += core.roundPrice(
                    p.dealer_price_before_discount * p.quantity
                );
                offer.dealer_price_before_discount += core.roundPrice(p.dealer_price * p.quantity);
                offer.dealer_price +=
                    core.roundPrice(p.dealer_price * p.quantity)
                    - core.roundPrice(
                        (1 / 100)
                            * p.dealer_price
                            * p.quantity
                            * offer.dealer_discount_producer_special
                    );

                if (p.details.priceParts) {
                    p.details.pricePartsNoMargin = p.details.pricePartsNoMargin.filter(
                        part => part.valueType !== 'transport'
                    );
                    if (split) {
                        const transportPart = {
                            basePercent: null,
                            baseValue: p.transport_cost,
                            percentId: null,
                            percentType: null,
                            value: p.transport_cost,
                            valueId: 0,
                            valueType: 'transport',
                        };
                        p.details.pricePartsNoMargin.push(transportPart);
                    }
                }
                if (IccConfig.Offer.detailedSummary) {
                    offer.summary = PositionDetailedSummaryService.detailedSummary(
                        p,
                        IccConfig,
                        offer.summary
                    );
                }
                p.details = JSON.stringify(p.details);
            });

        if (!split) {
            this.addTransportService(offer);
        }

        if (IccConfig.Offer.offerDiscountsMulti) {
            const offerGroupDiscounts = OfferDiscountsService.groupDiscounts(
                offer.group_discounts,
                offer.dealer_price_before_discount,
                user,
                offer
            );
            offer.dealer_price = offerGroupDiscounts.length
                ? offerGroupDiscounts[offerGroupDiscounts.length - 1].price
                : offer.dealer_price;
        }

        return {
            positions,
            offer,
        };
    }

    static removeTransportCost(p, offer, IccConfig, dealer, allDiscounts) {
        if (p.confType === 'additional' || p.coupled_position_id) {
            return p;
        }
        const pos = new Position(
            Common.extend({}, p, {
                offer,
                transport_cost: null,
                dealer_price_before_discount: p.dealer_price_without_transport,
                dealer_price: null,
                recalculateDiscount: true,
            }),
            IccConfig,
            dealer,
            allDiscounts
        );
        pos.modified = pos.modified_tmp;
        delete pos.modified_tmp;
        delete pos.synced;
        return pos;
    }

    static addTransportCost(p, offer, IccConfig, dealer, allDiscounts, offerPriceWithoutDiscount) {
        if (p.confType === 'additional' || p.coupled_position_id) {
            return p;
        }

        let transportCost = (offer.transport_cost * p.dealer_price) / offerPriceWithoutDiscount;
        let groupDiscounts = p.group_discounts;
        if (!Common.isObject(groupDiscounts)) {
            groupDiscounts = core.parseJson(groupDiscounts);
        }
        const groupDiscount = groupDiscounts.find(el => el.dealer.type === 'system');
        const discount = groupDiscount ? groupDiscount.dealer.discount : 0;
        transportCost = (transportCost * 100) / (100 - discount);

        const pos = new Position(
            Common.extend({}, p, {
                offer,
                transport_cost: transportCost,
                dealer_price_without_transport: p.dealer_price_before_discount,
                dealer_price_before_discount: p.dealer_price_before_discount + transportCost,
                dealer_price: null,
                recalculateDiscount: true,
            }),
            IccConfig,
            dealer,
            allDiscounts
        );
        pos.modified = pos.modified_tmp;
        delete pos.modified_tmp;
        delete pos.synced;
        return pos;
    }

    static addTransportService(offer, modifyDealerPrice = true) {
        offer.summary = PositionDetailedSummaryService.detailedSummary(
            {
                quantity: 1,
                details: {
                    goodType: 'service',
                },
                confType: 'other',
                client_price_before_discount: 0,
                dealer_price_before_discount: offer.transport_cost,
            },
            IccConfig,
            offer.summary
        );

        if (modifyDealerPrice) {
            offer.dealer_price_before_discount_position += offer.transport_cost;
            offer.dealer_price_before_discount += offer.transport_cost;
            offer.dealer_price +=
                offer.transport_cost
                - core.roundPrice(
                    (1 / 100) * offer.transport_cost * offer.dealer_discount_producer_special
                );
        }
    }

    static splitClientTransportCost(positions, offer, IccConfig, dealer, allDiscounts) {
        const offerPriceWithoutDiscount = positions.reduce(
            (sum, p) => sum + p.client_price * p.quantity,
            0
        );
        positions = positions.map(p =>
            this.addClientTransportCost(
                p,
                offer,
                IccConfig,
                dealer,
                allDiscounts,
                offerPriceWithoutDiscount
            )
        );

        return this.recalculateOfferClientPrice(positions, offer, IccConfig, true);
    }

    static joinClientTransportCost(positions, offer, IccConfig, dealer, allDiscounts) {
        positions = positions.map(p =>
            this.removeClientTransportCost(p, offer, IccConfig, dealer, allDiscounts)
        );
        return this.recalculateOfferClientPrice(positions, offer, IccConfig, false);
    }

    static recalculateOfferClientPrice(positions, offer, IccConfig, split) {
        Common.extend(offer, {
            dealer_client_price_before_discount_position: 0,
            dealer_client_price_before_discount: 0,
            dealer_client_price: 0,

            client_price_before_discount_position: 0,
            client_price_before_discount: 0,
            client_price: 0,

            summary: {
                client: {
                    additionals: {
                        product: 0,
                        service: 0,
                    },
                    others: {
                        product: 0,
                        service: 0,
                    },
                    components: {
                        glass: 0,
                        fitting: 0,
                        addons: 0,
                        base: 0,
                        roller: 0,
                        mosquito: 0,
                        colorCost: 0,
                    },
                },
                dealer: {
                    additionals: {
                        product: 0,
                        service: 0,
                    },
                    others: {
                        product: 0,
                        service: 0,
                    },
                    components: {
                        glass: 0,
                        fitting: 0,
                        addons: 0,
                        base: 0,
                        roller: 0,
                        mosquito: 0,
                        colorCost: 0,
                    },
                },
            },
        });

        positions.forEach(p => {
            p.details = core.parseJson(p.details);

            if (!p.coupled_position_id) {
                if (p.confType !== 'additional') {
                    offer.dealer_client_price_before_discount_position += core.roundPrice(
                        p.client_price_before_discount * p.quantity
                    );
                    offer.dealer_client_price_before_discount += core.roundPrice(
                        p.client_price * p.quantity
                    );
                    offer.dealer_client_price +=
                        core.roundPrice(p.client_price * p.quantity)
                        - core.roundPrice(
                            (1 / 100) * p.client_price * p.quantity * offer.client_discount_special
                        );
                }

                offer.client_price_before_discount_position += core.roundPrice(
                    p.client_price_before_discount * p.quantity
                );
                offer.client_price_before_discount += core.roundPrice(p.client_price * p.quantity);
                offer.client_price +=
                    core.roundPrice(p.client_price * p.quantity)
                    - core.roundPrice(
                        (1 / 100) * p.client_price * p.quantity * offer.client_discount_special
                    );

                if (p.details.priceParts) {
                    p.details.priceParts = p.details.priceParts.filter(
                        part => part.valueType !== 'transport'
                    );
                    if (split) {
                        const transportPart = {
                            basePercent: null,
                            baseValue: p.client_transport_cost,
                            percentId: null,
                            percentType: null,
                            value: p.client_transport_cost,
                            valueId: 0,
                            valueType: 'transport',
                        };
                        p.details.priceParts.push(transportPart);
                    }
                }
                if (IccConfig.Offer.detailedSummary) {
                    offer.summary = PositionDetailedSummaryService.detailedSummary(
                        p,
                        IccConfig,
                        offer.summary
                    );
                }
            }
            p.details = JSON.stringify(p.details);
        });

        if (offer.transport_cost) {
            this.addTransportService(offer, false);
        }

        if (!split) {
            this.addClientTransportService(offer);
        }

        return {
            positions,
            offer,
        };
    }

    static removeClientTransportCost(p, offer, IccConfig, dealer, allDiscounts) {
        const pos = new Position(
            Common.extend({}, p, {
                offer,
                client_transport_cost: null,
                client_price_before_discount: p.client_price_without_transport,
                client_price: null,
                recalculateClientDiscount: true,
            }),
            IccConfig,
            dealer,
            allDiscounts
        );
        pos.modified = pos.modified_tmp;
        delete pos.modified_tmp;
        delete pos.synced;
        return pos;
    }

    static addClientTransportCost(
        p,
        offer,
        IccConfig,
        dealer,
        allDiscounts,
        offerPriceWithoutDiscount
    ) {
        let transportCost =
            offerPriceWithoutDiscount > 0
                ? (offer.client_transport_cost * p.client_price) / offerPriceWithoutDiscount
                : offer.client_transport_cost;
        let groupDiscounts = p.group_discounts;
        if (!Common.isObject(groupDiscounts)) {
            groupDiscounts = core.parseJson(groupDiscounts);
        }
        const groupDiscount = groupDiscounts.find(el => el.client.type === 'system');
        const discount = groupDiscount ? groupDiscount.client.discount : 0;
        transportCost = (transportCost * 100) / (100 - discount);

        const pos = new Position(
            Common.extend({}, p, {
                offer,
                client_transport_cost: transportCost,
                client_price_without_transport: p.client_price_before_discount,
                client_price_before_discount: p.client_price_before_discount + transportCost,
                client_price: null,
                recalculateClientDiscount: true,
            }),
            IccConfig,
            dealer,
            allDiscounts
        );
        pos.modified = pos.modified_tmp;
        delete pos.modified_tmp;
        delete pos.synced;
        return pos;
    }

    static addClientTransportService(offer) {
        const priceBeforeDiscount = core.roundPrice(
            offer.client_transport_cost / (1 - offer.client_discount_position / 100)
        );
        offer.summary = PositionDetailedSummaryService.detailedSummary(
            {
                quantity: 1,
                details: {
                    goodType: 'service',
                },
                confType: 'additional',
                client_price_before_discount: priceBeforeDiscount,
            },
            IccConfig,
            offer.summary
        );

        offer.client_price_before_discount_position += priceBeforeDiscount;
        offer.client_price_before_discount += offer.client_transport_cost;
        offer.client_price +=
            offer.client_transport_cost
            - core.roundPrice(
                (1 / 100) * offer.client_transport_cost * offer.client_discount_special
            );
    }

    static belowLogisticMinimum(user, offer) {
        return (
            user
            && (isNaN(offer.dealer_price)
                || offer.dealer_price < Number(user.transport_logistic_min))
        );
    }

    static transportM2Cost(user, offer) {
        if (!user) {
            return 0;
        }
        const costM2 = offer.area * Number(user.transport_m2_cost);
        return costM2 > Number(user.transport_min_cost) ? costM2 : Number(user.transport_min_cost);
    }

    static transportWeightCost(user, offer) {
        if (!user) {
            return 0;
        }
        const costWeight = offer.weight * Number(user.transport_weight_cost);
        return costWeight > Number(user.transport_min_cost)
            ? costWeight
            : Number(user.transport_min_cost);
    }
}
