/**
 * Kontroler kolorow
 * @param {object} $scope              Scope
 * @param {object} MosquitoColorsFactory Fabryka kolorow bram
 */
export default function ColorsCtrl($scope, MosquitoColorsFactory) {
    'ngInject';

    var vm = this;

    vm.valid             = MosquitoColorsFactory.validate;
    vm.openModalColor    = MosquitoColorsFactory.openModalColor;

    $scope.$on('changedStep', function(event, newVal, oldVal) {
        if (newVal.code != 'color' && oldVal.code == 'color' && newVal.code !== oldVal.code) {
            vm.valid();
        }
    });
}
