import angular from 'angular';

/**
 * Kontroler skrzydeł
 * @param {object} $scope                Angular Scope
 * @param {object} $rootScope            Angular Root Scope
 * @param {object} $filter               Filtruj
 * @param {object} Core                  Core
 * @param {object} LayoutFactory         Fabryka layoutów
 * @param {object} ConfigurationsService Fabryka konfiguracji
 * @param {object} SashesFactory         Fabryka skrzydeł
 * @param {object} StepFactory           Fabryka kroków
 * @param {object} PopularLayoutsFactory Fabryka popluarnych widoków
 * @param {object} DatabaseManager      Fabryka widoków DB
 * @param {object} IccConfig             Konfiguracja ICC
 */
export default function SashesCtrl(
    $scope,
    $rootScope,
    $filter,
    Core,
    LayoutFactory,
    ConfigurationsService,
    SashesFactory,
    DrawService,
    StepFactory,
    PopularLayoutsFactory,
    IccConfig,
    PriceService,
    ProfilesService,
    ThresholdsService,
    EventBusService
) {
    'ngInject';

    var vm = this;

    let defaultLayouts = [];

    vm.sills = [];
    vm.defaultLayouts = [];
    vm.popularLayouts = [];
    vm.selectLayout = selectLayout;
    vm.selectDoorsLayout = LayoutFactory.selectDoorsLayout;
    vm.setSill = SashesFactory.setSill;
    vm.addSill = SashesFactory.addSill;
    vm.canBeBalcony = ThresholdsService.canBeBalcony.bind(ThresholdsService);
    vm.setLowThreshold = ThresholdsService.setThreshold.bind(ThresholdsService);
    vm.unsetLowThreshold = ThresholdsService.unsetThreshold.bind(ThresholdsService);
    vm.refreshLowThreshold = ThresholdsService.changeLowThreshold.bind(ThresholdsService);
    vm.hasThreshold = ThresholdsService.hasThreshold.bind(ThresholdsService);
    vm.lowThresholds = ProfilesService.getFilteredProfiles(
        ConfigurationsService.conf.Current,
        'threshold'
    );
    vm.selectedLowThresholdId = ProfilesService.getUsedThresholdId(
        ConfigurationsService.conf.Current
    );
    vm.changeBalcony = ThresholdsService.changeBalcony.bind(ThresholdsService);
    vm.valid = LayoutFactory.validateLayout;
    vm.resetSashes = resetSashes;
    vm.b2c =
        !$rootScope.user
        || !$rootScope.user.access
        || $rootScope.user.access == 'klient'
        || IccConfig.Configurators.B2CSashesInB2B;
    vm.doorSide = 'W';

    vm.openSashTypeModal = SashesFactory.openSashTypeModal;
    vm.openSashModal = SashesFactory.openSashModal;
    vm.changedDoorSide = changedDoorSide;
    vm.canChangeDoorSide = true;
    vm.adminThumb = ~~sessionStorage.getItem('adminThumb');

    vm.drawOptions = angular.merge({}, DrawService.options, {
        dimensions: false,
    });

    vm.goToSubstep = function(subStep) {
        vm.subStep = subStep;
        if (IccConfig.Configurators.tutorialAvailable) {
            if (subStep == 1) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            } else if (subStep == 2) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'editSash',
                });
            }
        }
    };

    if (SashesFactory.loadedData) {
        init();
    }

    if (
        angular.isDefined(ConfigurationsService.conf.Current.Sashes)
        && ConfigurationsService.conf.Current.Sashes.length > 0
    ) {
        vm.subStep = 2;
        EventBusService.post({
            key: 'tutorialSteps',
            value: 'editSash',
        });
    } else {
        vm.subStep = 1;
        EventBusService.post({
            key: 'tutorialSteps',
            value: 'getStepImg',
        });
    }

    $scope.$on('Sashes_goToNextSubstep', () => {
        vm.goToSubstep(2);
    });

    const subscriptions = [];
    subscriptions.push(
        EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init())
    );
    subscriptions.push(
        EventBusService.subscribe('setFrameProfile', () => {
            vm.selectedLowThresholdId = ProfilesService.getUsedThresholdId(
                ConfigurationsService.conf.Current
            );
        })
    );
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    /**
     * Funkcja inicjalizacyjna
     */
    function init() {
        vm.sills = SashesFactory.sills;
        if (ConfigurationsService.conf.Current.Sashes.length) {
            vm.doorSide = ConfigurationsService.conf.Current.Sashes.some(s => s.type.type === 'DOA')
                ? 'Z'
                : 'W';
        }
        loadLayouts();
    }

    /**
     * Funkcjaresetowania skrzydeł
     */
    function resetSashes() {
        LayoutFactory.prepareWindowSashesData(ConfigurationsService.conf.Current.Layout);
        vm.goToSubstep(2);
    }

    /**
     * Wywoływana przy zmianie strony otwierania drzwi.
     */
    function changedDoorSide() {
        loadLayouts();
    }

    /**
     * Ładuje gotowe układy.
     */
    function loadLayouts() {
        const conf = ConfigurationsService.conf.Current;
        const layouts = SashesFactory.layouts;
        defaultLayouts = layouts
            .filter(
                layout =>
                    layout.SashesLayout.type === conf.type
                    && layout.SashesLayout.shape_id === conf.Shape.shape
                    && layout.SashesLayoutsVariant.config
            )
            .filter(el => {
                if (
                    angular.isObject(conf.System)
                    && angular.isDefined(conf.System.id)
                    && angular.isArray(el.SashesLayoutsVariant.not_systems)
                ) {
                    return el.SashesLayoutsVariant.not_systems.indexOf(conf.System.id) === -1;
                }
                return true;
            })
            .filter(el => {
                if (conf.Shape.shape == 'rect' || conf.Shape.shape == 'circle') {
                    return true;
                }
                return (
                    el.SashesLayoutsVariant.shape !== null
                    && (el.SashesLayoutsVariant.shape.type == conf.Shape.type || vm.adminThumb)
                );
            });
        checkCanChangeDoorSide();
        loadLayoutsForDoorSide();
        PriceService.count();
    }

    function loadLayoutsForDoorSide() {
        if (ConfigurationsService.conf.Current.type === 'door') {
            vm.defaultLayouts = defaultLayouts.filter(layout => {
                const doorSide =
                    (layout.Neighbours
                        && (layout.Neighbours.middle.some(s => s.type === 'DOA')
                            ? 'Z'
                            : 'W'))
                    || (layout.Sashes
                        && (layout.Sashes.some(s => s.type === 'DOA') ? 'Z' : 'W'));
                return (
                    IccConfig.Configurators.showMismatchedVariants
                    || (!vm.doorSide || vm.doorSide === doorSide)
                );
            });
        } else {
            vm.defaultLayouts = defaultLayouts;
        }
    }

    function checkCanChangeDoorSide() {
        const areDoorSideLayoutsW =
            defaultLayouts.filter(
                layout =>
                    IccConfig.Configurators.showMismatchedVariants
                    || (layout.Neighbours && layout.Neighbours.middle.some(s => s.type === 'DRA'))
                    || (layout.Sashes && layout.Sashes.some(s => s.type === 'DRA'))
            ).length > 0;
        const areDoorSideLayoutsZ =
            defaultLayouts.filter(
                layout =>
                    IccConfig.Configurators.showMismatchedVariants
                    || (layout.Neighbours && layout.Neighbours.middle.some(s => s.type === 'DOA'))
                    || (layout.Sashes && layout.Sashes.some(s => s.type === 'DOA'))
            ).length > 0;
        vm.canChangeDoorSide = areDoorSideLayoutsW && areDoorSideLayoutsZ;

        if (vm.doorSide === 'W' && !areDoorSideLayoutsW) {
            vm.doorSide = 'Z';
        } else if (vm.doorSide === 'Z' && !areDoorSideLayoutsZ) {
            vm.doorSide = 'W';
        }
    }

    function selectLayout(layout) {
        LayoutFactory.selectLayout(layout);
    }
}
