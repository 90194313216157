import Common from '../Common';

/**
 * Pole indywidualnych cen.
 *
 * @export
 * @interface CustomPriceField
 */
export interface CustomPriceField {
    /**
     * Nazwa pola
     *
     * @type {string}
     * @memberOf CustomPriceField
     */
    field: string;
    /**
     * Cena
     *
     * @type {number}
     * @memberOf CustomPriceField
     */
    price: number;
    /**
     * Jeśli pole typu JSON, nazwa klucza
     *
     * @type {string | null}
     * @memberOf CustomPriceField
     */
    json?: string | any | null;
    /**
     * Indeks relacji
     *
     * @type {(number | null)}
     * @memberOf CustomPriceField
     */
    relation: number | null;
}

/**
 * Rekordy indywidualnych cen.
 *
 * @export
 * @interface CustomPricesRecords
 */
export interface CustomPricesRecords {
    [propName: number]: CustomPrice;
}

/**
 * Indywidualne ceny danego dealera.
 *
 * @export
 * @interface CustomPrices
 */
export interface CustomPrices {
    [propName: string]: CustomPricesRecords;
}

/**
 * Indywidualne ceny dealerów.
 *
 * @export
 * @interface CustomPricesDealers
 */
export interface CustomPricesDealers {
    [propName: number]: CustomPrices;
}

/**
 * Indywidaulne ceny.
 *
 * @export
 * @class CustomPrice
 */
export class CustomPrice {
    /**
     * Pola indewidualnych cen.
     *
     * @type {CustomPriceField[]}
     * @memberOf CustomPrice
     */
    fields: CustomPriceField[];

    /**
     * Creates an instance of CustomPrice.
     *
     * @param {CustomPriceField[]} fields
     *
     * @memberOf CustomPrice
     */
    constructor(fields: CustomPriceField[]) {
        this.fields = fields;
    }

    /**
     * Zwraca cenę danego pola.
     *
     * @param   {string}          fieldName       Nazwa pola
     * @param   {(number | null)} [relation=null] Indeks relacji
     * @param   {(string | null)} [json=null]     Nazwa klucza
     * @returns {(number | null)}                 Cena
     *
     * @memberOf CustomPrice
     */
    getPrice(
        fieldName: string,
        relation: number | null = null,
        json: string | null = null
    ): number | null {
        let field;
        if (
            Common.isDefined(relation)
            && relation !== null
            && Common.isDefined(json)
            && json !== null
        ) {
            field = this.fields.filter(
                f => f.field === fieldName && f.relation === relation && f.json === json
            );
        } else if (Common.isDefined(relation) && relation !== null) {
            field = this.fields.filter(
                f => f.field === fieldName && f.relation === relation && !f.json
            );
        } else {
            field = this.fields.filter(f => f.field === fieldName && !f.relation && !f.json);
        }
        field = field[field.length - 1];

        if (field) {
            return parseFloat(field.price);
        } else {
            return null;
        }
    }
}
