import TokenService from 'authorization/token.service';

export class LoginCtrl {
    constructor($location, tokenService: TokenService) {
        if (tokenService.getToken()) {
            $location.path('/app/logout');
        }
    }
}
LoginCtrl.$inject = ['$location', 'TokenService'];
