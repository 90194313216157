import { Pipe, PipeTransform, Inject } from '@angular/core';
import SystemsByTagFilter from './SystemsByTagFilter';

@Pipe({name: 'systemsByTag'})
export class SystemsByTagPipe implements PipeTransform {

    constructor(@Inject(SystemsByTagFilter) private systemsByTagFilter: any) {}

    transform(data: string) {
        return this.systemsByTagFilter.instant(data);
    }
}
