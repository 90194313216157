import { NgModule } from '@angular/core';

import PriceService from './price.service';
import { CustomPricesService } from '../../../common/price/custom-prices.service';
import { BrowserCustomPricesService } from './custom-prices.service';
import { DiscountsAndMultipliersService } from '../../../common/price/discounts-and-multipliers.service';
import BrowserDiscountsAndMultipliersService from './discounts-and-multipliers.service';
import { warrantyFactoryProvider } from './ajs-upgraded-providers';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        PriceService,
        { provide: CustomPricesService, useClass: BrowserCustomPricesService },
        BrowserDiscountsAndMultipliersService,
        {
            provide: DiscountsAndMultipliersService,
            useExisting: BrowserDiscountsAndMultipliersService,
        },
        warrantyFactoryProvider,
    ],
})
export class ConfiguratorPriceModule {}
