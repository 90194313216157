var Worker = require('worker-loader!./worker/sync.worker');
import {clearIDB} from '../Databases/IDB';
import {redirectOnChangedUser} from './RedirectUser';
import {CONFIG} from '../../app/config';
import {logger} from 'helpers';

/**
 * Fabryka synchronizacji w workerze
 * @param {object} $window    Angular Window
 * @param {object} $rootScope Angular RootScope
 * @param {string} machine    Identyfikator sprzetu
 * @param {object} IccConfig  Konfiguracja aplikacji
 */
export default function SyncWorkerFactory($routeParams, $window, $rootScope, $filter, $location, machine, debug, IccConfig, UserService, StateService, TokenService, EventBusService, USER_ID, Core) {
    'ngInject';

    var factory = {};
    var worker = new Worker();
    var workerRun = false;

    worker.addEventListener('message', function(msg) {
        switch (msg.data.subject) {
            case 'database_info':
                logger.log('%cWORKER', 'background: cyan; font-size:bigger', 'Info o bazie danych: ', msg.data.info);
                break;
            case 'posList':
                logger.log('%cWORKER', 'background: cyan; font-size:bigger', 'Wszystkie pozycje: ', msg.data.data);
                break;
            case 'localStorage':
                switch (msg.data.func) {
                    case 'getItem':
                        var data = localStorage.getItem(msg.data.item);
                        worker.postMessage({
                            subject: 'localStorage',
                            func   : 'read',
                            data   : data
                        });
                        break;
                    case 'setItem':
                        localStorage.setItem(msg.data.item, msg.data.data);
                        break;
                    default:
                        throw 'Niepoprawna funkcja!';
                }
                break;
            case 'synchronize':
                $rootScope.$emit(msg.data.event, msg.data.syncedInfo);
                EventBusService.post({
                    key: msg.data.event,
                    value: msg.data.syncedInfo
                });
                break;
            case 'iDB':
                var iDBs = JSON.parse(window.localStorage.iDBs || '[]');
                if (iDBs.indexOf(name) < 0) {
                    iDBs.push(name);
                    window.localStorage.iDBs = JSON.stringify(iDBs);
                }
                break;
            case 'iDBQuestion':
                $rootScope.showInfo($filter('translate')('SYNCHRONIZE|Wystąpił błąd synchronizacji...'), null, function() {clearIDB();}, 2000);
                break;
            case 'availableStorage':
                logger.error(msg.data.ravenMessage);
                alert(msg.data.message);
                break;
            case 'refresh':
                redirectOnChangedUser();
                break;
            case 'logout':
                if (TokenService.getToken()) {
                    $location.path('/app/logout');
                }
                break;
            case 'emittedEvent':
                EventBusService.post({
                    key  : msg.data.name,
                    value: msg.data.value
                });
                break;
            default:
                throw 'no subject';
        }
    }, false);

    factory.startSyncWorker = function startSyncWorker() {
        if (!workerRun) {
            worker.postMessage({
                subject  : 'user_id',
                user_id  : USER_ID,
                machine  : machine,
                IccConfig: IccConfig,
                user     : UserService.get(),
                state    : StateService.state,
                debug    : debug,
                CONFIG   : Core.copy(CONFIG),
                token    : TokenService.getToken(),
                offerId  : getOfferId() || StateService.getKey('offer_id')
            });
            workerRun = true;
        }
    };

    EventBusService.subscribeWithoutConfiguration('changedState', (data) => {
        worker.postMessage({
            subject  : 'state',
            newState : data.value
        });
    });

    EventBusService.subscribeWithoutConfiguration('changedToken', (data) => {
        worker.postMessage({
            subject  : 'token',
            newToken : data.value
        });
    });

    $rootScope.$on('terminateWorker', function() {
        worker.terminate();
    });

    $rootScope.$on('updatedDB', (event, name) => {
        worker.postMessage({
            subject: 'emittedEvent',
            name   : 'updated' + name
        });
    });

    EventBusService.subscribeWithoutConfiguration('modifiedOffer', () => {
        worker.postMessage({
            subject: 'emittedEvent',
            name   : 'modifiedOffer'
        });
    });

    EventBusService.subscribeWithoutConfiguration('isUser', (data) => {
        worker.postMessage({
            subject: 'user',
            newUser: data.value
        });
    });

    EventBusService.subscribeWithoutConfiguration('changedLang', (data) => {
        if (data.value.worker) {
            worker.postMessage({
                subject: 'user',
                newUser: data.value.user
            });
        }
    });

    function getOfferId() {
        return $routeParams.id || (window.location.pathname.match('tmp_offer_.*') !== null ? window.location.pathname.match('tmp_offer_.*')[0] : null);
    }


    return factory;

}
