import angular from 'angular';
import { IssueLevel } from 'issues.service';

/**
 * Fabryka rozmiarów siatek
 * @param {object} $rootScope               Angular Root Scope
 * @param {object} $filter                  Filtry
 * @param {object} Core                     Core
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} CurConfService           Bieżaca konfiguracja
 * @param {object} IssuesService            Fabryka 'Issues'
 * @param {object} ConfiguratorsDataService Fabryka danych konfiguracji
 * @param {object} PriceService             Liczenie cen
 */
export default function DimensionsFactory(
    $rootScope,
    $filter,
    Core,
    ConfigurationsService,
    CurConfService,
    IssuesService,
    ConfiguratorsDataService,
    PriceService,
    StepFactory,
    EventBusService,
    ProfilesService,
    IccConfig
) {
    'ngInject';

    var factory = {
        defaultDimensions: {
            width: ConfigurationsService.conf.Current.Width,
            height: ConfigurationsService.conf.Current.Height,
            circuit:
                (ConfigurationsService.conf.Current.Width
                    + ConfigurationsService.conf.Current.Height)
                * 2,
        },
        loadedData: false,
        setGlobalDimensions,
        recalculateCircuit,
        setShapeData,
        valid,
        setDimensions,
        getFrameLightOuterWidth,
        getFrameLightOuterHeight,
        validateAndUpdateDimensions,
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    $rootScope.$on('resetData', init);
    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });
    EventBusService.subscribe(['changedSashes', 'setFrameProfile', 'setMullionProfile'], () => {
        validateAndUpdateDimensions();
    });

    return factory;

    function init() {
        if (CurConfService.conf == 'mosquito') {
            if (
                angular.isUndefined(ConfigurationsService.conf.Current.Shape)
                || angular.equals(ConfigurationsService.conf.Current.Shape, {})
            ) {
                setShapeData();
            }
            if (
                ConfigurationsService.conf.Current.Width === null
                || ConfigurationsService.conf.Current.Height === null
            ) {
                setGlobalDimensions();
            }
            if (
                angular.isArray(
                    ConfiguratorsDataService.data.mosquitoTypes[
                        ConfigurationsService.conf.Current.System.id
                    ]
                )
                && ConfiguratorsDataService.data.mosquitoTypes[
                    ConfigurationsService.conf.Current.System.id
                ].length
            ) {
                StepFactory.enable('types');
            } else {
                StepFactory.disable('types');
            }
            factory.loadedData = true;
        }
    }

    /**
     * Wstawienie wymiarow konfiguracji
     */
    function setGlobalDimensions() {
        ConfigurationsService.conf.Current.Width = angular.copy(
            parseInt(ConfigurationsService.conf.Current.Shape.width)
        );
        ConfigurationsService.conf.Current.Height = angular.copy(
            parseInt(ConfigurationsService.conf.Current.Shape.height)
        );
    }

    /**
     * Przeliczanie obwodu konstrukcji
     */
    function recalculateCircuit() {
        ConfigurationsService.conf.Current.Shape.circuit =
            (ConfigurationsService.conf.Current.Width + ConfigurationsService.conf.Current.Height)
            * 2;
    }

    /**
     * Ustawienie wymiarow w konfiguracji
     */
    function setShapeData() {
        ConfigurationsService.conf.Current.Shape.width = ConfigurationsService.conf.Current.Width;
        ConfigurationsService.conf.Current.Shape.height = ConfigurationsService.conf.Current.Height;
    }

    /**
     * Ustawienie nowego wymiaru
     * @param {object} dimensions Obiekt z wymiarami
     */
    function setDimensions(dimensions) {
        ConfigurationsService.conf.Current.Shape = Core.copy(dimensions);
        // setShapeData();
        setGlobalDimensions();
        PriceService.count();
        recalculateCircuit();
    }

    /**
     * Walidacja wymiarow
     * @param  {object} shapeData Wymiary
     * @return {boolean}          Czy zwalidowano poprawnie
     */
    function valid(shapeData) {
        if (!shapeData.width || !shapeData.height) {
            IssuesService.simpleRegister(
                'no-mosquito-dimensions',
                'Podane wymiary są nieprawidłowe.',
                $filter('translate')('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                ConfigurationsService.conf.Current,
                {
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }
        if (shapeData.width < 250 || shapeData.height < 250) {
            IssuesService.simpleRegister(
                'incorrect-mosquito-dimensions',
                'Podane wymiary są nieprawidłowe.',
                $filter('translate')('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                ConfigurationsService.conf.Current,
                {
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }
        IssuesService.unregister('no-mosquito-dimensions', ConfigurationsService.conf.Current);
        IssuesService.unregister(
            'incorrect-mosquito-dimensions',
            ConfigurationsService.conf.Current
        );
        return true;
    }

    function getFrameLightOuterWidth(conf, sash) {
        const sashDimensions = conf.drawData.sash.find(s => s.sashId == sash.id);
        const frame = conf.Frames.find(f => f.id === sash.frameId);
        const frameSides = ProfilesService.getFrameSides(frame, conf);
        let width = 0;
        if (sashDimensions) {
            width = ['left', 'right'].reduce((val, side) => {
                let mullion = false;
                let nearSash;
                if (sash.nearMullions[side] !== -1) {
                    mullion = conf.Mullions.find(m => m.id === sash.nearMullions[side]);
                    nearSash = mullion['multiAlign' + Core.capitalize(side)][0];
                }
                if (
                    mullion
                    && mullion.type === 'false_mullion'
                    && ['F', 'FF', 'OFF'].indexOf(nearSash.type.type) == -1
                ) {
                    const falseMullionProfile = conf.UsedProfiles.find(
                        p => p.id === mullion.profileId
                    );
                    const nearSashDimensions = conf.drawData.sash.find(
                        s => s.sashId == nearSash.id
                    );
                    const profile = conf.UsedProfiles.find(
                        p =>
                            p.id
                            === (nearSash.nearMullions[side] !== -1
                                ? conf.Mullions.find(m => m.id === nearSash.nearMullions[side])
                                      .profileId
                                : frame.frame[frameSides.findIndex(s => s.side === side)].profileId)
                    );
                    const profileRatio = nearSash.nearMullions[side] !== -1 ? 2 : 1;
                    return (
                        val
                        + falseMullionProfile.width
                        + (profile
                            ? nearSashDimensions.inner.rect.width
                              + profile.width / profileRatio
                              - profile.widthOut / profileRatio
                            : 0)
                    );
                } else {
                    const profile = conf.UsedProfiles.find(
                        p =>
                            p.id
                            === (mullion
                                ? mullion.profileId
                                : frame.frame[frameSides.findIndex(s => s.side === side)].profileId)
                    );
                    const profileRatio = mullion ? 2 : 1;
                    return profile
                        ? val
                              + (profile.type === 'false_mullion'
                                  ? profile.width
                                  : profile.width / profileRatio - profile.widthOut / profileRatio)
                        : 0;
                }
            }, sashDimensions.inner.rect.width);
        }

        return Math.floor(width) + IccConfig.Configurators.window.mosquitoAdditionalSize * 2;
    }

    function getFrameLightOuterHeight(conf, sash) {
        const sashDimensions = conf.drawData.sash.find(s => s.sashId == sash.id);
        const frame = conf.Frames.find(f => f.id === sash.frameId);
        const frameSides = ProfilesService.getFrameSides(frame, conf);
        let height = 0;
        if (sashDimensions) {
            height = ['top', 'bottom'].reduce((val, side) => {
                const profile = conf.UsedProfiles.find(
                    p =>
                        p.id
                        === (sash.nearMullions[side] !== -1
                            ? conf.Mullions.find(m => m.id === sash.nearMullions[side]).profileId
                            : frame.frame[frameSides.findIndex(s => s.side === side)].profileId)
                );
                const profileRatio = sash.nearMullions[side] !== -1 ? 2 : 1;
                return profile
                    ? val + profile.width / profileRatio - profile.widthOut / profileRatio
                    : 0;
            }, sashDimensions.inner.rect.height);
        }

        return Math.floor(height) + IccConfig.Configurators.window.mosquitoAdditionalSize * 2;
    }

    function validateAndUpdateDimensions() {
        const conf = ConfigurationsService.conf.Current;
        if (conf.type !== 'mosquito') {
            conf.Sashes.forEach(sash => {
                if (sash.mosquito) {
                    if (['F', 'FF', 'OFF'].indexOf(sash.type.type) > -1) {
                        sash.mosquito = null;
                    } else {
                        sash.mosquito.Height =
                            getFrameLightOuterHeight(conf, sash) + sash.mosquito.HeightModify;
                        sash.mosquito.Width =
                            getFrameLightOuterWidth(conf, sash) + sash.mosquito.WidthModify;
                    }
                }
            });
        }
    }
}
