import 'angular';
import {logger} from 'helpers';

export default function IccInterceptor($q, ProjectName, $injector, debug, IccConfig, LocaleVersion, isPhonegap) {
    'ngInject';

    var interceptor = {
        request: function(config) {
            var src = config.url;
            if (src.search(/.*\.project\.(html|js|json)$/) != -1) {
                config.url = src.replace('project', ProjectName);
            }
            if (src.search(/^\/(application|common)\/.*\.html$/) != -1) {
                config.url = config.url + '?' + IccConfig.Version;
            }
            if (src.search(/.*\.js$/) != -1) {
                config.url = config.url + '?' + IccConfig.Version;
            }
            const found = config.url.match(/locale\/(.*?\.json)$/);
            const foundx = config.url.match(/localex\/(.*?\.json\?[a-z\d\.-]+)$/);
            if (found && isPhonegap) {
                config.url = 'cdvfile://localhost/persistent/files/locale/' + found[1];
            }
            if (foundx && isPhonegap) {
                config.url = 'locale/' + foundx[1];
            }
            if (src.search(/.*\.json$/) != -1) {
                config.url = config.url + '?' + LocaleVersion;
            }
            if (src.search(/^\/application\/.*\.html$/) != -1) {
                if (isPhonegap && config.url[0] === '/') {
                    config.url = config.url.substring(1);
                }
                config.url = '/application/dist' + config.url;
            }
            if (src.search(/^\/common\/.*\.html$/) != -1) {
                if (isPhonegap && config.url[0] === '/') {
                    config.url = config.url.substring(1);
                }
                config.url = '/application/dist' + config.url;
            }
            if (src.search(/^.*\.html$/) == -1 && src.search(/.*\/locale\/.*/) == -1 && src.search(/.*googleapis.*/) == -1) {
                config.headers['token'] = localStorage.getItem('token');
            }
            if (src.search(/^http:\/\//) == -1 && logger.isOffline) {
                config.timeout = config.timeout || $q.defer();
                config.timeout.resolve();
            }
            return config;
        },
        'requestError': function(rejection) {
            logger.error(new Error('requestError' + rejection));
            return $q.reject(rejection);
        },
        'response': function(response) {
            return response;
        },
        'responseError': function(rejection) {
            var $http = $injector.get('$http');
            const projectRegex = new RegExp('\\.' + ProjectName + '\\.(png|json|html|css)');
            const phonegapLocaleFound =rejection.config.url.match(/^cdvfile:\/\/localhost\/persistent\/files\/locale\/(.*?)$/);
            if (rejection.config && phonegapLocaleFound && isPhonegap) {
                rejection.config.url = 'localex/' + phonegapLocaleFound[1];
                return $http(rejection.config);
            }
            if (rejection.config && rejection.config.url.match(projectRegex)) {
                rejection.config.url = rejection.config.url.replace('.' + ProjectName, '');
                return $http(rejection.config);
            }
            if (rejection.config.url.search(/^http:\/\//) == -1 && logger.isOffline) {
                return $q.reject(rejection);
            }
            logger.error(new Error('responseError ' + rejection.config.url));
            return $q.reject(rejection);
        }
    };

    return interceptor;
}
