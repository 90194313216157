import * as angular from 'angular';
import {downgradeInjectable} from '@angular/upgrade/static';

import PriceAccessoryService from './price-accessory.service';
import PriceAwningService from './price-awning.service';
import PriceBaseService from './price-base.service';
import PriceColorsService from './price-colors.service';
import PriceDoorService from './price-door.service';
import PriceFittingService from './price-fitting.service';
import PriceGarageService from './price-garage.service';
import PriceGlazingService from './price-glazing.service';
import PriceHandlesService from './price-handles.service';
import PriceMosquitoService from './price-mosquito.service';
import PriceMuntinsService from './price-muntins.service';
import PriceProfileService from './price-profile.service';
import PriceRollerService from './price-roller.service';
import PriceSashService from './price-sash.service';
import PriceShapeService from './price-shape.service';
import PriceSystemService from './price-system.service';
import PriceDictionary from './PriceDictionary';
import {CustomPricesService} from './custom-prices.service';
import {DiscountsAndMultipliersService} from './discounts-and-multipliers.service';
import PriceSealService from './price-seal.service';
import PriceSiliconeService from './price-silicone.service';
import PriceGlazingBeadService from './price-glazing-bead.service';
import PriceDependenciesService from './price-dependencies.service';
import PriceCassonettoService from './price-cassonetto.service';
import PriceOuterDoorService from './price-outer-door.service';
import PriceHandlePositionService from './price-handle-position.service';

export default angular.module('icc.price.common', [])
    .factory('PriceAccessoryService', downgradeInjectable(PriceAccessoryService))
    .factory('PriceBaseService', downgradeInjectable(PriceBaseService))
    .factory('PriceColorsService', downgradeInjectable(PriceColorsService))
    .factory('PriceFittingService', downgradeInjectable(PriceFittingService))
    .factory('PriceProfileService', downgradeInjectable(PriceProfileService))
    .factory('PriceSashService', downgradeInjectable(PriceSashService))
    .factory('PriceSystemService', downgradeInjectable(PriceSystemService))
    .factory('CustomPricesService', downgradeInjectable(CustomPricesService))
    .factory('DiscountsAndMultipliersService', downgradeInjectable(DiscountsAndMultipliersService))
    .factory('PriceCassonettoService', downgradeInjectable(PriceCassonettoService))
    .factory('PriceMuntinsService', downgradeInjectable(PriceMuntinsService))
    .factory('PriceHandlesService', downgradeInjectable(PriceHandlesService))
    .factory('PriceGlazingService', downgradeInjectable(PriceGlazingService))
    .factory('PriceSealService', downgradeInjectable(PriceSealService))
    .factory('PriceSiliconeService', downgradeInjectable(PriceSiliconeService))
    .factory('PriceGlazingBeadService', downgradeInjectable(PriceGlazingBeadService))
    .factory('PriceMosquitoService', downgradeInjectable(PriceMosquitoService))
    .factory('PriceDependenciesService', downgradeInjectable(PriceDependenciesService))
    .factory('PriceHandlePositionService', downgradeInjectable(PriceHandlePositionService))
    .factory('PriceRollerService', downgradeInjectable(PriceRollerService))
    .factory('PriceDoorService', downgradeInjectable(PriceDoorService))
    .factory('PriceAwningService', downgradeInjectable(PriceAwningService))
    .factory('PriceGarageService', downgradeInjectable(PriceGarageService))
    .factory('PriceOuterDoorService', downgradeInjectable(PriceOuterDoorService))
    .factory('PriceShapeService', downgradeInjectable(PriceShapeService))
    .service('PriceDictionary', PriceDictionary)
    .name;
