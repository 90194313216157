import * as angular from 'angular';

const GlazingFiltersComponentTemplate = require<string>('ngtemplate-loader!./glazing-filters.component.html');

interface GlazingFilters {
    selectedRw;
    selectedUg;
    selectedSecurity;
    selectedOrnament;
    selectedColored;
}

export class GlazingFiltersComponent implements ng.IComponentOptions {

    public bindings = {
        iccConfig: '<',
        filters  : '<',
        filtered : '&',
        glassColors: '<',
        areOrnaments: '<'
    };
    public controller = GlazingFiltersComponentController;
    public templateUrl = GlazingFiltersComponentTemplate;
}

class GlazingFiltersComponentController {

    iccConfig: any;
    filters: GlazingFilters;
    filtered: (data: any) => any;

    selectedOften = false;
    glazingCount: any = '';
    q = '';

    glassColors: any[];

    areOrnaments: boolean;

    type = 'simple';

    $onInit() {
        this.type = this.iccConfig.Configurators.glazingFilters;
    }

    changed() {
        this.filtered({value: angular.extend({}, this.filters, {
            glazingCount : this.glazingCount,
            selectedOften: this.selectedOften,
            q            : this.q
        })});
    }
}
