const ProfileSetsModalTemplate = require<string>('ngtemplate-loader!./profile-sets-modal.component.html');
import ProfileSetsModalController from './profile-sets-modal.controller';

export const ProfileSetsModalComponent: ng.IComponentOptions = {
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  templateUrl: ProfileSetsModalTemplate,
  controller: ProfileSetsModalController
};
