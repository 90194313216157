import angular from 'angular';

import Awning from './awning/awning';
import ComplementaryGoods from './complementary_goods/complementary-goods.module.ajs';
import Door from './door/door';
import Garage from './garage/garage';
import Mosquito from './mosquito/mosquito';
import RollerShutter from './roller_shutter/roller_shutter';
import WindowModule from './window/window.module.ajs';
import WinterGarden from './winter_garden/winter_garden';
import CoupledWindow from './coupled_window/coupled_window.module.ajs';

import MainStepsCtrl from './MainStepsCtrl';
import StepFactory from './StepFactory';
import SummaryCtrl from './SummaryCtrl';

export default angular
    .module('icc.configurator.steps', [
        Awning,
        ComplementaryGoods,
        Door,
        Garage,
        Mosquito,
        RollerShutter,
        WindowModule,
        WinterGarden,
        CoupledWindow,
    ])
    .controller('MainStepsCtrl', MainStepsCtrl)
    .controller('SummaryCtrl', SummaryCtrl)
    .service('StepFactory', StepFactory).name;
