import angular from 'angular';

import ColorsCtrl from './ColorsCtrl';
import ColorsFactory from './ColorsFactory';
import DimensionsCtrl from './DimensionsCtrl';
import DimensionsFactory from './DimensionsFactory';
import ModalColorCtrl from './ModalColorCtrl';
import ModalMosquitoCtrl from './ModalMosquitoCtrl';
import MontagesCtrl from './MontagesCtrl';
import MontagesFactory from './MontagesFactory';
import SystemsCtrl from './SystemsCtrl';
import SystemsFactory from './SystemsFactory';
import TypesCtrl from './TypesCtrl';
import TypesFactory from './TypesFactory';

export default angular.module('icc.configurator.steps.mosquito', [])
    .controller('ModalMosquitoCtrl', ModalMosquitoCtrl)
    .controller('ModalMosquitoColorCtrl', ModalColorCtrl)
    .controller('MosquitoColorsCtrl', ColorsCtrl)
    .controller('MosquitoDimensionsCtrl', DimensionsCtrl)
    .controller('MosquitoMontagesCtrl', MontagesCtrl)
    .controller('MosquitoSystemsCtrl', SystemsCtrl)
    .controller('MosquitoTypesCtrl', TypesCtrl)
    .factory('MosquitoColorsFactory', ColorsFactory)
    .factory('MosquitoDimensionsFactory', DimensionsFactory)
    .factory('MosquitoMontagesFactory', MontagesFactory)
    .factory('MosquitoSystemsFactory', SystemsFactory)
    .factory('MosquitoTypesFactory', TypesFactory)
    .name;
