import {NgModule} from '@angular/core';

import {accessoriesFactoryProvider} from './ajs-upgraded-providers';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  entryComponents: [],
  providers:    [
    accessoriesFactoryProvider
  ]
})
export class AccessoriesModule { }
