import angular from 'angular';

/**
 * Fabryka do sprawdzania gwarancji na konstrukcję
 *
 * @class
 * @param      {Service}  $rootScope                rootScope
 * @param      {Service}  ConfiguratorsDataService  ConfiguratorsDataService
 * @param      {Service}  ConfigurationsService     ConfigurationsService
 * @param      {Service}  IccConfig                 IccConfig
 * @return     {Object}   Fabryka
 */
export default function WarrantyFactory($rootScope, ConfiguratorsDataService, ConfigurationsService, IccConfig) {
    'ngInject';

    let factory = {check: check};

    return factory;

    /**
     * Sprawdza czy konfiguracja jest na gwarancji
     *
     * @method     check
     * @param      {Object}  conf    Konfiguracja
     * @return     {bool}            Czy konfiguracja jest na gwarancji
     */
    function check(conf) {
        if (angular.isUndefined(conf)) {
            conf = ConfigurationsService.conf.Current;
        }
        let priceMaps = getRestrictionsForSystem(ConfiguratorsDataService.data.warrantyRestrictionsDepends, conf.System);
        let sash;
        conf.Warranty = true;
        for (let i = conf.Sashes.length - 1; i >= 0; i--) {
            sash = conf.Sashes[i];
            conf.Warranty = conf.Warranty && checkForSash(sash, priceMaps, conf.Balcony, ConfiguratorsDataService.data.warrantyRestrictions);
            if (!conf.Warranty) {
                break;
            }
        }
        if (!conf.Warranty) {
            $rootScope.$emit('warranty', conf.Warranty);
        }
        return conf.Warranty;
    }

    /**
     * Zwraca ograniczenia gwarancyjne dla systemu.
     *
     * @method     getRestrictionsForSystem
     * @param      {object}  priceDepends  Macierz ograniczeń
     * @param      {object}  system        System
     * @return     {Object}  Ograniczenia gwarancyjne
     */
    function getRestrictionsForSystem(priceDepends, system) {
        if (angular.isObject(priceDepends)) {
            if (angular.isDefined(priceDepends[system.id])) {
                return priceDepends[system.id];
            }
        }
        return {};
    }

    /**
     * Sprawdza gwarancję dla skrzydła
     *
     * @method     checkForSash
     * @param      {Sash}     sash       Skrzydło
     * @param      {object}   priceMaps  Macierz ograniczeń
     * @param      {bool}     balcony    Czy okno balkonowe
     * @param      {array}    matrixes   Tablica ograniczeń
     * @return     {boolean}  Czy skrzydło ma gwarancję
     */
    function checkForSash(sash, priceMaps, balcony, matrixes) {
        if (IccConfig.Configurators.warrantyRestrictions) {
            if (angular.isObject(priceMaps[sash.type.type])) {
                let priceData = priceMaps[sash.type.type];
                let priceId;
                if (angular.isDefined(priceData.one)) {
                    priceId = priceData.one;
                } else {
                    if (priceData.height < priceData.over.from) {
                        priceId = priceData.win;
                    } else if (priceData.height >= priceData.over.from && priceData.height <= priceData.over.to) {
                        if (balcony) {
                            priceId = priceData.bal;
                        } else {
                            priceId = priceData.win;
                        }
                    } else {
                        priceId = priceData.bal;
                    }
                }
                if (angular.isDefined(priceId) && priceId !== null) {
                    let matrix = matrixes[priceId];
                    if (angular.isDefined(matrix) && matrix !== null) {
                        let priceField = matrix.data.filter((el) =>
                            el.height_from <= sash.rHeight && el.height_to >= sash.rHeight
                                && el.width_from <= sash.rWidth && el.width_to >= sash.rWidth
                        );
                        if (angular.isArray(priceField) && priceField.length > 0) {
                            return parseFloat(priceField[0].price) > 0;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
}
