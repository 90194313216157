import { logger, core } from '../helpers';
import Common from '../Common';

/**
 * Konstruktor fabryki rabatów
 * @param {object} core  Boblioteki systemowe
 * @return {object}      Publiczne metody
 */
export default class OfferDiscountsService {
    /**
     * Dolicznie wielu rabatow na oferice
     * @param  {array} discounts   Lista definicji rabatow
     * @param  {number} startPrice Cena przed rabatami
     * @param  {object} offer      Oferta
     * @return {array}             Lista z rabatami
     */
    static groupDiscounts(discounts, startPrice, user, offer: any = false) {
        discounts = core.parseJson(discounts);

        let i = 0;
        const newDiscounts = [];
        let discount = {};

        let prevPrice = startPrice;
        let newValue = 0;
        let newPrice = 0;

        const today = new Date();
        let dateFrom;
        let dateTo;

        if (Common.isArray(discounts)) {
            for (i = 0; i < discounts.length; i++) {
                if (
                    user.access !== 'producent'
                    || discounts[i].dealer_id === null
                    || discounts[i].custom
                    || (offer && Number(offer.dealer_id) === Number(discounts[i].dealer_id))
                ) {
                    dateFrom = new Date(discounts[i].date_from);
                    dateFrom.setHours(0, 0, 0);
                    dateTo = new Date(discounts[i].date_to);
                    dateTo.setHours(23, 59, 59);

                    if (
                        !startPrice
                        || (startPrice
                            && ((discounts[i].date_from && today >= dateFrom)
                                || !discounts[i].date_from)
                            && ((discounts[i].date_to && today <= dateTo) || !discounts[i].date_to))
                    ) {
                        newValue =
                            discounts[i].auto || discounts[i].checked
                                ? core.roundPrice(
                                      (prevPrice * parseFloat(discounts[i].discount)) / 100
                                  )
                                : 0;
                        newPrice = prevPrice - newValue;

                        discount = Common.deepCopy(discounts[i]);
                        newDiscounts.push(
                            Common.extend(discount, {
                                price: newPrice,
                                value: newValue,
                                discount: parseFloat(discounts[i].discount),
                                checked: discounts[i].auto || discounts[i].checked,
                                custom: discounts[i].custom || false,
                            })
                        );

                        prevPrice = newPrice;
                    }
                }
            }
        }

        return newDiscounts;
    }

    /**
     * Ustawia domyślne rabaty w ofercie wg typu konta
     * @param {object} offer Nowa oferta
     * @param {object} param Parametry predefiniowane
     */
    static setDefaultDiscounts(offer, param, user, dealer, dealerh?) {
        if (user.access === 'dealer' || user.access === 'dealerh') {
            const today = new Date();
            const toCheckMinDate = new Date(dealer.discount_special_from);
            const toCheckMaxDate = new Date(dealer.discount_special_to);
            if (today >= toCheckMinDate && today <= toCheckMaxDate) {
                offer.dealer_discount_producer_special =
                    param.dealer_discount_producer_special
                    || parseFloat(dealer.discount_special)
                    || 0;
                offer.dealer_discount_producer_special_date_from =
                    param.dealer_discount_producer_special_date_from
                    || dealer.discount_special_from;
                offer.dealer_discount_producer_special_date_to =
                    param.dealer_discount_producer_special_date_to || dealer.discount_special_to;
            } else {
                offer.dealer_discount_producer_special =
                    param.dealer_discount_producer_special || 0;
            }
            offer.client_discount_special = param.client_discount_special || 0;
            offer.dealer_margin = param.dealer_margin || dealer.margin || 0;
            offer.client_discount_position =
                param.client_discount_position
                || (user.access === 'dealerh'
                    ? parseFloat(dealerh.discount_standart)
                    : parseFloat(dealer.discount_default))
                || 0;
            offer.dealer_discount_producer =
                param.dealer_discount_producer || parseFloat(dealer.discount) || 0;
            offer.dealer_tax_rate =
                param.dealer_tax_rate
                || (param.dealer_tax_rate === 0
                    ? 0
                    : dealer.vat !== null
                    ? parseFloat(dealer.vat)
                    : null);
        } else {
            offer.dealer_margin = param.dealer_margin || 0;
            offer.dealer_discount_producer_special = param.dealer_discount_producer_special || 0;
            offer.client_discount_special = param.client_discount_special || 0;
            offer.client_discount_position = param.client_discount_position || 0;
            offer.dealer_discount_producer = param.dealer_discount_producer || 0;
            offer.dealer_tax_rate =
                param.dealer_tax_rate || (param.dealer_tax_rate === 0 ? 0 : null);
        }
    }

    /**
     * Generowanie grub rabatowych i liczenie wartości rabatów
     * @memberof PositionsFactory
     * @param  {object} params lementy tworzenia pozycji
     * @return {object}        grupy rabatowe, wartości rabatów dealera i klienta
     */
    static generateGroupDiscounts(
        params,
        IccConfig,
        dealer,
        buyDiscounts,
        saleDiscounts,
        dealerMargin = 0
    ) {
        const discounts = {
            client: {
                system: 0,
                addons: 0,
                glazing: 0,
                accessory: 0,
                roller: 0,
                drive: 0,
                windowsill: 0,
                cassonetto: 0,
            },
            dealer: {
                system: 0,
                addons: 0,
                glazing: 0,
                accessory: 0,
                roller: 0,
                drive: 0,
                windowsill: 0,
                cassonetto: 0,
            },
            descriptions: {},
            clientDiscount: 0,
            dealerDiscount: 0,
        };
        const discountsGroupsOrder = [
            'system',
            'glazing',
            'accessory',
            'addons',
            'roller',
            'drive',
            'windowsill',
            'cassonetto',
        ];

        if (~~IccConfig.Dealer.oneProductDiscount) {
            // g, o, v
            discounts.client.system = parseFloat(params.offer.client_discount_position);
            discounts.dealer.system = parseFloat(params.offer.dealer_discount_producer);
            if (~~IccConfig.Dealer.discountAddons) {
                /* g
                 system
                 addons
                */

                discounts.client.addons = 0;
                discounts.dealer.addons = dealer ? parseFloat(dealer.discount_addons) : 0;
                if (
                    Common.isObject(params.details)
                    && Common.isObject(params.details.discountGroups)
                ) {
                    if (Common.isUndefined(params.details.discountGroups.system)) {
                        params.details.discountGroups.system = 0;
                    }
                    params.details.discountGroups.system += params.details.discountGroups.addons;
                    params.details.discountGroups.addons = 0;
                }
                if (
                    Common.isObject(params.details)
                    && Common.isObject(params.details.discountGroupsNoMargin)
                ) {
                    if (Common.isUndefined(params.details.discountGroupsNoMargin.system)) {
                        params.details.discountGroupsNoMargin.system = 0;
                    }
                    params.details.discountGroupsNoMargin.system +=
                        params.details.discountGroupsNoMargin.addons;
                    params.details.discountGroupsNoMargin.addons = 0;
                }
            } else {
                /* o, v
                  system
                 */
            }
        } else {
            // f, b
            // tworzenie grup tylko dla elementów, ktore istnieja w konfiguracji
            if (params.details.type === 'coupled_window') {
                params.details.windows.forEach(window => {
                    OfferDiscountsService.setDiscountsValues(
                        saleDiscounts,
                        window.details,
                        discounts,
                        IccConfig,
                        buyDiscounts
                    );
                });
                params.details.rollerShutters.forEach(rollerShutter => {
                    OfferDiscountsService.setDiscountsValues(
                        saleDiscounts,
                        rollerShutter.details,
                        discounts,
                        IccConfig,
                        buyDiscounts
                    );
                });
                OfferDiscountsService.setDiscountsValues(
                    saleDiscounts,
                    params.details,
                    discounts,
                    IccConfig,
                    buyDiscounts
                );
            } else {
                OfferDiscountsService.setDiscountsValues(
                    saleDiscounts,
                    params.details,
                    discounts,
                    IccConfig,
                    buyDiscounts
                );
            }
        }

        let groupDiscounts;
        if (Common.isDefined(params.group_discounts) && params.group_discounts !== null) {
            let discountGroups = ['system'];
            if (
                Common.isDefined(params.details.discountGroups)
                && params.details.discountGroups !== null
            ) {
                discountGroups = Object.keys(params.details.discountGroups).sort((key1, key2) => {
                    return discountsGroupsOrder.indexOf(key1) - discountsGroupsOrder.indexOf(key2);
                });
            } else {
                discountGroups = core.parseJson(params.group_discounts).map(discount => discount.dealer.type).sort((key1, key2) => {
                    return discountsGroupsOrder.indexOf(key1) - discountsGroupsOrder.indexOf(key2);
                });
            }
            const oldDiscountGroups = core
                .parseJson(params.group_discounts)
                .map(discount => discount.dealer.type);
            groupDiscounts = core
                .parseJson(params.group_discounts)
                .filter(discount => discountGroups.indexOf(discount.dealer.type) > -1)
                .map(discount => {
                    if (
                        Common.isDefined(params.details.discountGroups)
                        && params.details.discountGroups !== null
                    ) {
                        discount.dealer.price =
                            params.details.discountGroupsNoMargin[discount.dealer.type] || 0;
                        discount.dealer.value =
                            (1 / 100)
                            * (discount.dealer.discount || 0)
                            * (params.details.discountGroupsNoMargin[discount.dealer.type] || 0);
                        if (
                            discount.dealer.type === 'system'
                            && ['logistic-minimum', 'm2-cost', 'weight'].includes(
                                IccConfig.Offer.transportCostType
                            )
                            && params.transport_cost
                        ) {
                            discount.dealer.price += params.transport_cost || 0;
                            discount.dealer.value +=
                                (1 / 100)
                                * (discount.dealer.discount || 0)
                                * (params.transport_cost || 0);
                        }
                        if (discount.client) {
                            discount.client.price =
                                params.details.discountGroups[discount.dealer.type] || 0;
                            discount.client.value =
                                (1 / 100)
                                * (discount.client.discount || 0)
                                * (params.details.discountGroups[discount.dealer.type] || 0);
                            if (
                                discount.client.type === 'system'
                                && ['logistic-minimum', 'm2-cost', 'weight'].includes(
                                    IccConfig.Offer.transportCostType
                                )
                                && params.client_transport_cost
                            ) {
                                discount.client.price += params.client_transport_cost || 0;
                                discount.client.value +=
                                    (1 / 100)
                                    * (discount.client.discount || 0)
                                    * (params.client_transport_cost || 0);
                            }
                        }
                    } else {
                        discount.dealer.price =
                            params.dealer_price_before_discount || params.price || 0;
                        discount.dealer.value =
                            (1 / 100)
                            * (discount.dealer.discount || 0)
                            * (params.dealer_price_before_discount || params.price || 0);
                        if (discount.client) {
                            discount.client.price =
                                params.client_price_before_discount || params.price || 0;
                            discount.client.value =
                                (1 / 100)
                                * (discount.client.discount || 0)
                                * (params.client_price_before_discount || params.price || 0);
                        }
                    }
                    if (discount.client) {
                        discounts.clientDiscount += discount.client.value;
                    }
                    discounts.dealerDiscount += discount.dealer.value;
                    return discount;
                });
            if (
                Common.isDefined(params.details.discountGroups)
                && params.details.discountGroups !== null
            ) {
                groupDiscounts.push(
                    ...discountGroups
                        .filter(key => oldDiscountGroups.indexOf(key) === -1)
                        .map(
                            OfferDiscountsService.createDiscountFromKey(
                                params,
                                discounts,
                                IccConfig
                            )
                        )
                );
            }
            groupDiscounts = core.stringJson(groupDiscounts);
        } else {
            if (
                Common.isDefined(params.details.discountGroups)
                && params.details.discountGroups !== null
            ) {
                groupDiscounts = core.stringJson(
                    Object.keys(params.details.discountGroups)
                        .sort((key1, key2) => {
                            return (
                                discountsGroupsOrder.indexOf(key1)
                                - discountsGroupsOrder.indexOf(key2)
                            );
                        })
                        .map(
                            OfferDiscountsService.createDiscountFromKey(
                                params,
                                discounts,
                                IccConfig
                            )
                        )
                );
            } else {
                const discount = {
                    client: {
                        price: params.client_price_before_discount || params.price || 0,
                        type: 'system',
                        value:
                            (1 / 100)
                            * (discounts.client.system || 0)
                            * (params.client_price_before_discount || params.price || 0),
                        discount: discounts.client.system,
                    },
                    dealer: {
                        price: params.dealer_price_before_discount || params.price || 0,
                        type: 'system',
                        value:
                            (1 / 100)
                            * (discounts.dealer.system || 0)
                            * (params.dealer_price_before_discount || params.price || 0),
                        discount: discounts.dealer.system,
                    },
                };
                if (
                    (params.confType || params.configuration.type) === 'additional'
                    && !params.recalculateClientDiscount
                ) {
                    const clientPrice = core.round(
                        params.client_price / ((100 - (discounts.client.system || 0)) / 100)
                    );
                    discount.client = {
                        price: clientPrice,
                        type: 'system',
                        value: clientPrice - params.client_price,
                        discount: discounts.client.system,
                    };
                }
                if (
                    (params.confType || params.configuration.type) === 'other'
                    && !params.recalculateDiscount
                ) {
                    const dealerPrice = core.round(
                        params.dealer_price / ((100 - (discounts.dealer.system || 0)) / 100)
                    );
                    discount.client = {
                        price:
                            params.client_price_before_discount
                            || dealerPrice * (1 + dealerMargin / 100)
                            || 0,
                        type: 'system',
                        value:
                            (1 / 100)
                            * (discounts.client.system || 0)
                            * (params.client_price_before_discount
                                || dealerPrice * (1 + dealerMargin / 100)
                                || 0),
                        discount: discounts.client.system,
                    };
                    discount.dealer = {
                        price: dealerPrice,
                        type: 'system',
                        value: dealerPrice - params.dealer_price,
                        discount: discounts.dealer.system,
                    };
                }

                groupDiscounts = core.stringJson([discount]);
                discounts.clientDiscount += discount.client.value;
                discounts.dealerDiscount += discount.dealer.value;
            }
        }
        return {
            group_discounts: groupDiscounts,
            client_discount: discounts.clientDiscount,
            dealer_discount: discounts.dealerDiscount,
        };
    }

    static setDiscounts(details, discounts, groups, descriptions, IccConfig) {
        // na system
        if (details.system) {
            const name = 'system' + details.system.id;
            descriptions[name] = details.system.name;
            if (discounts.WindowLine && Common.isDefined(discounts.WindowLine[details.system.id])) {
                groups.system = parseFloat(discounts.WindowLine[details.system.id]);
                groups[name] = parseFloat(discounts.WindowLine[details.system.id]);
            }
        }

        // na rolety
        if (details.rollerShutter) {
            const type =
                details.system || !Common.isObject(IccConfig.Offer.discountGroups)
                    ? 'roller'
                    : 'system';
            const name = type + details.rollerShutter.type.id;
            descriptions[name] = details.rollerShutter.type.name;
            if (
                discounts.WindowLine
                && Common.isDefined(discounts.WindowLine[details.rollerShutter.type.id])
            ) {
                groups[type] = parseFloat(discounts.WindowLine[details.rollerShutter.type.id]);
                groups[name] = parseFloat(discounts.WindowLine[details.rollerShutter.type.id]);
            }
        }

        if (IccConfig.Dealer.discountPerAllAccessoriesCategory) {
            // f
            /*
            system
            roller
            accessory[id]
            */
            // na akcesoria
            if (Common.isArray(details.accessories)) {
                details.accessories.forEach(accessory => {
                    const name = 'accessory' + accessory.category.id;
                    descriptions[name] = accessory.category.name;
                    if (discounts.WindowAccessoriesCategory) {
                        groups[name] = parseFloat(
                            discounts.WindowAccessoriesCategory[accessory.category.id]
                        );
                    }
                });
            }
            if (Common.isObject(details.sideAccessories)) {
                for (const i in details.sideAccessories) {
                    if (Common.isArray(details.sideAccessories[i])) {
                        details.sideAccessories[i].forEach(accessory => {
                            const name = 'accessory' + accessory.category.id;
                            descriptions[name] = accessory.category.name;
                            if (discounts.WindowAccessoriesCategory) {
                                groups[name] = parseFloat(
                                    discounts.WindowAccessoriesCategory[accessory.category.id]
                                );
                            }
                        });
                    }
                }
            }
            if (Common.isArray(details.sashes)) {
                details.sashes.forEach(sash => {
                    if (Common.isArray(sash.hardware)) {
                        sash.hardware.forEach(accessory => {
                            const name = 'accessory' + accessory.category.id;
                            descriptions[name] = accessory.category.name;
                            if (discounts.WindowAccessoriesCategory) {
                                groups[name] = parseFloat(
                                    discounts.WindowAccessoriesCategory[accessory.category.id]
                                );
                            }
                        });
                    }
                });
            }

            if (Common.isArray(details.usedProfiles)) {
                details.usedProfiles.forEach(profile => {
                    if (profile.priceLevelId && profile.priceLevelName) {
                        const name = 'priceLevel' + profile.priceLevelId;
                        descriptions[name] = profile.priceLevelName;
                        if (discounts.PriceLevel) {
                            groups[name] = parseFloat(discounts.PriceLevel[profile.priceLevelId]);
                        }
                    }
                });
            }
        } else {
            // b
            /*
            system
            roller
            drive
            accessory (+ drive)
            glazing (+ szprosy)
            cassonetto
            windowsill
            */

            // na akcesoria
            if (
                discounts.Accessory
                && (Common.isArray(details.accessories)  // jscs:ignore
                    && details.accessories.length)
            ) {
                groups.accessory = parseFloat(discounts.Accessory[0]);
            }

            // na szyby
            if (
                discounts.Glass
                && ((details.fillings
                && Common.isArray(details.fillings)  // jscs:ignore
                    && details.fillings.length)
                    || (Common.isArray(details.sashes) && details.sashes.length))
            ) {
                groups.glazing = parseFloat(discounts.Glass[0]);
            }

            // na kasonetki
            if (
                discounts.Cassonetto
                && Common.isArray(details.cassonettos)  // jscs:ignore
                && details.cassonettos.length
            ) {
                groups.cassonetto = parseFloat(discounts.Cassonetto[0]);
            }

            // na parapety
            if (
                discounts.WindowSill
                && Common.isArray(details.windowSills)  // jscs:ignore
                && details.windowSills.length
            ) {
                groups.windowsill = parseFloat(discounts.WindowSill[0]);
            }
        }
    }

    static createDiscountFromKey(params, discounts, IccConfig) {
        return key => {
            const discount: {
                dealer?: {
                    price: number;
                    type: string;
                    value: number;
                    discount: number;
                    name: string;
                };
                client?: {
                    price: number;
                    type: string;
                    value: number;
                    discount: number;
                    name: string;
                };
            } = {};
            discount.dealer = {
                price: params.details.discountGroupsNoMargin[key] || 0,
                type: key,
                value:
                    (1 / 100)
                    * (discounts.dealer[key] || 0)
                    * (params.details.discountGroupsNoMargin[key] || 0),
                discount: discounts.dealer[key] || 0,
                name: discounts.descriptions[key],
            };
            discounts.dealerDiscount += discount.dealer.value;
            if (!Boolean(IccConfig.Dealer.discountAddons) || key !== 'addons') {
                discount.client = {
                    price: params.details.discountGroups[key] || 0,
                    type: key,
                    value:
                        (1 / 100)
                        * (discounts.client[key] || 0)
                        * (params.details.discountGroups[key] || 0),
                    discount: discounts.client[key] || 0,
                    name: discounts.descriptions[key],
                };
                discounts.clientDiscount += discount.client.value;
            }
            return discount;
        };
    }

    private static setDiscountsValues(
        saleDiscounts: any,
        details: any,
        discounts: {
            client: {
                system: number;
                addons: number;
                glazing: number;
                accessory: number;
                roller: number;
                drive: number;
                windowsill: number;
                cassonetto: number;
            };
            dealer: {
                system: number;
                addons: number;
                glazing: number;
                accessory: number;
                roller: number;
                drive: number;
                windowsill: number;
                cassonetto: number;
            };
            descriptions: {};
            clientDiscount: number;
            dealerDiscount: number;
        },
        IccConfig: any,
        buyDiscounts: any
    ) {
        if (saleDiscounts) {
            OfferDiscountsService.setDiscounts(
                details,
                saleDiscounts,
                discounts.client,
                discounts.descriptions,
                IccConfig
            );
        }
        if (buyDiscounts) {
            OfferDiscountsService.setDiscounts(
                details,
                buyDiscounts,
                discounts.dealer,
                discounts.descriptions,
                IccConfig
            );
        }
    }
}
