import {PouchDBNew, clearIDB, PouchDBConfiguration} from './IDB';
import {
    ISimpleDatabase
} from './ISimpleDatabase';

export class IccSimpleDatabase implements ISimpleDatabase {
    private DB: PouchDB.Database<{}> | null = null;
    private options: PouchDBConfiguration = {};

    name: string | null = null;
    private user: any = null;
    type: 'simple' = 'simple';
    private mainRecordId = '1';

    constructor({name, user}: {name: string, user: any}, options?: PouchDBConfiguration) {
        this.name = name;
        this.user = user;
        this.options = options;
    }

    init() {
        this.DB = PouchDBNew<any>(this.name, this.user, this.options);
    }

    async get(): Promise<any> {
        let document = {};
        try {
        document = await this.DB.get(this.mainRecordId);
        } catch (err) {};
        return document;
    }

    async put(document: any, {internalRev}: {internalRev?: string} = {}): Promise<any> {
        await this.appendAdditionalProperties(document, {internalRev});
        await this.DB.put(document);
        return document;
    }

    async compact(): Promise<void> {
        await this.DB.compact();
    }

    async refreshIndexes() {}

    getUserId() {
        return this.user.id;
    }

    getUserMarketId() {
        return this.user.marketId;
    }

    getUserLanguage() {
        return this.user.user_language;
    }

    private async appendAdditionalProperties(document, {internalRev}: {internalRev?: string} = {}) {
        document._id = this.mainRecordId;
        if (!internalRev && !document._rev) {
            let documentFromDatabase;
            try {
                documentFromDatabase = await this.get();
            } catch (error) {}
            if (documentFromDatabase && documentFromDatabase._rev) {
                document._rev = documentFromDatabase._rev;
            }
        } else {
            document._rev = internalRev || document._rev;
        }
        return document;
    }
}