import * as io from 'socket.io-client';
import {core} from 'helpers';
import {Injectable, Inject} from '@angular/core';
import {APP_CONFIG, AppConfig} from 'config';
import TokenService from '../authorization/token.service';
import {EventBusService} from 'event-bus.service';

@Injectable()
export class SocketService {
    socket: SocketIO.Socket;

    constructor (
        @Inject(APP_CONFIG) private config: AppConfig,
        private tokenService: TokenService,
        EventBusService: EventBusService
    ) {
        'ngInject';

        this.socket = io((this.config.EnvConfig.remoteHost || window.location.origin), {
            path: '/api/socket.io',
            query: {
                worker: core.isWorker(),
                token: this.tokenService.getToken(),
                machine: config.machine
            }
        });

        EventBusService.subscribeWithoutConfiguration('changedToken', (data) => {
            this.socket.emit('changedToken', data.value);
        });
    }
}
