import { TranslateService } from 'translate.service';
import Common from 'Common';
import { DrawService } from 'configurators/draw.service';
import NewPositionService from 'panel/NewPositionService';
import StateService from 'state.service';
import { CoupledWindowService } from 'configurator/coupled/coupled-window.service';
import { EventBusService } from 'event-bus.service';
import { core } from 'helpers';
import { ExtensionsService } from 'configurator/layout/extensions.service';
import { EditingCoupledWindowService } from 'configurator/coupled/editing-coupled-window.service';
import { RootScope } from 'app/RootScope';
import ConfigurationsService from 'configurations/configurations.service';

export class CanvasCtrl {
    drawOptions: any = {};
    constructor(
        private $translate: ng.translate.ITranslateService,
        drawService: DrawService,
        $location: ng.ILocationService,
        private $rootScope: RootScope,
        private $scope: ng.IScope,
        private stateService: StateService,
        private coupledWindowService: CoupledWindowService,
        private editingCoupledWindowService: EditingCoupledWindowService,
        private configurationsService: ConfigurationsService<'coupled_window'>,
        private eventBusService: EventBusService
    ) {
        if (!coupledWindowService.currentCoupledWindow) {
            $location.path('/app/offers_view/' + stateService.state.offer_id);
            return;
        }

        $(window).on('resize.doResize', () => {
            $scope.$apply(() => {
                this.resize();
            });
        });

        $scope.$on('$destroy', () => {
            $(window).off('resize.doResize');
        });

        this.drawOptions = Common.merge({}, drawService.options, {
            shutterBox: {
                buttons: [
                    {
                        class: 'ico-gear',
                        title: $translate.instant('INTERFACE|Edytuj roletę'),
                        buttons: [
                            {
                                class: 'ico-remove',
                                title: $translate.instant('INTERFACE|Usuń'),
                                condition: response => {
                                    return true;
                                },
                                onClick: response => {
                                    this.coupledWindowService.confirmRemoveRollerShutter(
                                        response.positionId
                                    );
                                },
                            },
                            {
                                class: 'ico-edit',
                                title: $translate.instant('INTERFACE|Edytuj'),
                                condition: response => {
                                    return true;
                                },
                                onClick: response => {
                                    this.editingCoupledWindowService.editPosition(
                                        response.positionId
                                    );
                                },
                            },
                            {
                                class: 'ico-move',
                                title: $translate.instant('INTERFACE|Przesuń'),
                                condition: response =>
                                    this.coupledWindowService.canBeMoved(response.positionId),
                                onClick: response => {
                                    this.coupledWindowService.moveRollerShutter(
                                        response.positionId
                                    );
                                },
                            },
                        ],
                    },
                ],
            },
            frame: {
                buttons: [
                    {
                        class: 'ico-gear',
                        title: $translate.instant('INTERFACE|Opcje okna'),
                        buttons: [
                            {
                                class: 'ico-gear',
                                title: $translate.instant('INTERFACE|Opcje okna'),
                                buttons: [
                                    {
                                        class: 'ico-remove',
                                        title: $translate.instant('INTERFACE|Usuń okno'),
                                        condition: response =>
                                            this.coupledWindowService.canWindowBeRemoved(
                                                response.positionId
                                            ),
                                        onClick: response => {
                                            this.coupledWindowService.confirmRemoveWindow(
                                                response.positionId
                                            );
                                        },
                                    },
                                    {
                                        class: 'ico-edit',
                                        title: $translate.instant('INTERFACE|Edytuj okno'),
                                        condition: response => {
                                            return true;
                                        },
                                        onClick: response => {
                                            this.editingCoupledWindowService.editPosition(
                                                response.positionId
                                            );
                                        },
                                    },
                                    {
                                        class: 'ico-move',
                                        title: $translate.instant('INTERFACE|Przesuń okno'),
                                        condition: response =>
                                            this.coupledWindowService.canBeMoved(
                                                response.positionId
                                            ),
                                        onClick: response => {
                                            this.coupledWindowService.moveWindow(
                                                response.positionId
                                            );
                                        },
                                    },
                                ],
                            },
                            {
                                class: 'ico-plus',
                                title: this.$translate.instant('INTERFACE|Dodaj konstrukcję'),
                                buttons: [
                                    {
                                        class: 'ico-plus',
                                        fields: 'profiles',
                                        positions: ['center-profile'],
                                        title: this.$translate.instant('INTERFACE|Dodaj konstrukcję'),
                                        condition: response => true,
                                        onClick: response =>
                                        this.joinNewElement({
                                            frameId: response.frame.id,
                                            frameEdgeIndex: response.profileIndex,
                                            positionId: response.positionId,
                                        }),
                                    }
                                ]
                            },
                            {
                                class: 'ico-sill',
                                title: this.$translate.instant('INTERFACE|Parapety'),
                                buttons: [
                                    {
                                        class: 'ico-sill',
                                        fields: 'profiles',
                                        positions: ['center-profile'],
                                        title: this.$translate.instant('INTERFACE|Parapety'),
                                        condition: response => true,
                                        onClick: response =>
                                            this.coupledWindowService.openSillsModal(
                                                response.frame.id,
                                                response.profileIndex,
                                                response.positionId
                                            ),
                                    }
                                ]
                            },
                        ],
                    },
                ],
            },
            coupling: {
                buttons: [
                    {
                        class: 'ico-gear',
                        title: $translate.instant('INTERFACE|Edytuj łącznik'),
                        buttons: [
                            {
                                class: 'ico-plus',
                                title: this.$translate.instant('INTERFACE|Dodaj konstrukcję'),
                                condition: response =>
                                    response.coupling.framesId.length === 0
                                    || response.coupling.otherFramesId.length === 0,
                                onClick: response => {
                                    this.joinNewElement(null, response.coupling);
                                },
                            },
                            {
                                class: 'ico-link',
                                title: this.$translate.instant('INTERFACE|Połącz z konstrukcją'),
                                condition: response =>
                                    response.coupling.framesId.length === 0
                                    || response.coupling.otherFramesId.length === 0,
                                onClick: response => {
                                    this.joinElementFromCanvas(null, response.coupling);
                                },
                            },
                            {
                                class: 'ico-link',
                                title: this.$translate.instant(
                                    'INTERFACE|Połącz z konstrukcją z oferty'
                                ),
                                condition: response =>
                                    response.coupling.framesId.length === 0
                                    || response.coupling.otherFramesId.length === 0,
                                onClick: response => {
                                    this.joinElementFromOffer(null, response.coupling);
                                },
                            },
                            {
                                class: 'ico-remove',
                                title: this.$translate.instant('INTERFACE|Usuń łącznik'),
                                condition: response =>
                                    response.coupling.framesId.length === 0
                                    || response.coupling.otherFramesId.length === 0,
                                onClick: response => {
                                    this.coupledWindowService.removeCoupling(response.coupling.id);
                                },
                            },
                            {
                                class: 'ico-move-submit',
                                title: this.$translate.instant('INTERFACE|Zmień łącznik'),
                                condition: response => true,
                                onClick: response => {
                                    this.coupledWindowService.changeCouplingProfile(
                                        response.coupling.id
                                    );
                                },
                            },
                            {
                                class: 'ico-move',
                                title: this.$translate.instant('INTERFACE|Przesuń/Wydłuż łącznik'),
                                condition: response => true,
                                onClick: response => {
                                    this.coupledWindowService.moveCoupling(response.coupling.id);
                                },
                            },
                        ],
                    },
                ],
            },
            extension: {
                buttons: [
                    {
                        class: 'ico-gear',
                        title: $translate.instant('INTERFACE|Edytuj poszerzenie'),
                        buttons: [
                            {
                                class: 'ico-plus',
                                title: this.$translate.instant('INTERFACE|Dodaj poszerzenie'),
                                condition: response =>
                                    response.extension.adjacentOtherSideProfileId.length === 0,
                                onClick: response => {
                                    const edge = response.extension.id;
                                    this.joinNewExtension(edge);
                                },
                            },
                            {
                                class: 'ico-remove',
                                title: this.$translate.instant('INTERFACE|Usuń poszerzenie'),
                                condition: response =>
                                    response.extension.adjacentOtherSideProfileId.length === 0,
                                onClick: response => {
                                    const edge = response.extension.id;
                                    this.coupledWindowService.removeExtension(edge);
                                },
                            },
                            {
                                class: 'ico-move',
                                title: this.$translate.instant(
                                    'INTERFACE|Przesuń/Wydłuż poszerzenie'
                                ),
                                condition: response => true,
                                onClick: response => {
                                    const edge = response.extension.id;
                                    this.coupledWindowService.moveExtension(edge);
                                },
                            },
                        ],
                    },
                ],
            },
            dimensions: {
                type: 'extended',
            },
            indexes: true,
        });
        this.coupledWindowService.refreshDraw();
        this.resize();

        const subscriptions = [];
        subscriptions.push(this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.coupledWindowService.refreshDraw();
            this.redrawn();
        }));
        $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));
    }

    switchDimensionsType() {
        this.coupledWindowService.refreshDraw();
        this.resize();
    }

    resize(digest = false) {
        setTimeout(() => {
            const parentElement = $('#canvas-content')[0];
            const el = $('#canvas-draw')[0];
            const elDiv = $('#canvas-draw >  div.icc-draw')[0];
            const drawWidth = this.configurationsService.conf.Current.width;
            const drawHeight = this.configurationsService.conf.Current.height;
            const windowWidth = drawWidth;
            const windowHeight = drawHeight;
            const elDivStyle = window.getComputedStyle(elDiv);
            const marginRight = elDivStyle.marginRight;
            const marginTop = elDivStyle.marginTop;
            const marginBottom = elDivStyle.marginBottom;
            const marginLeft = elDivStyle.marginLeft;
            const maxWidth =
                parentElement.offsetWidth - 50 - parseInt(marginRight) - parseInt(marginLeft);
            const maxHeight =
                parentElement.offsetHeight - 120 - parseInt(marginTop) - parseInt(marginBottom);

            const width =
                windowWidth / windowHeight < maxWidth / maxHeight
                    ? (maxHeight * windowWidth) / windowHeight
                      + (parseInt(marginRight) + parseInt(marginLeft))
                    : maxWidth + parseInt(marginRight) + parseInt(marginLeft);
            el.style.width = width + 'px';
            this.$scope.$apply();
        }, 0);
    }

    redrawn() {
        this.resize(true);
    }

    joinNewElement(
        edge: {
            frameId: number;
            frameEdgeIndex: number;
            positionId: string;
        } | null = null,
        coupling = null
    ) {
        const offer = this.stateService.getCurrentOffer();
        this.coupledWindowService.openJoinNewToWindowModal(offer, edge, coupling);
    }

    joinNewExtension(extensionId: number) {
        const offer = this.stateService.getCurrentOffer();

        this.coupledWindowService.openJoinExtensionToExtensionModal(offer, extensionId);
    }

    joinElementFromCanvas(
        edge: {
            frameId: number;
            frameEdgeIndex: number;
            positionId: string;
        } | null = null,
        coupling = null
    ) {
        const offer = this.stateService.getCurrentOffer();
        this.coupledWindowService.openJoinExistingInCouplingToWindowsModal(offer, edge, coupling);
    }

    joinElementFromOffer(
        edge: {
            frameId: number;
            frameEdgeIndex: number;
            positionId: string;
        } | null = null,
        coupling = null
    ) {
        const offer = this.stateService.getCurrentOffer();
        this.coupledWindowService.openJoinExistingInOfferToWindowsModal(offer, edge, coupling);
    }
}
CanvasCtrl.$inject = [
    '$translate',
    'DrawService',
    '$location',
    '$rootScope',
    '$scope',
    'StateService',
    'CoupledWindowService',
    'EditingCoupledWindowService',
    'ConfigurationsService',
    'EventBusService'
];
