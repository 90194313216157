import * as angular from 'angular';
import 'angular-ui-bootstrap';

import PriceDictionary from '../../../../common/price/PriceDictionary';

/**
 * Kontroler modala szczegółów wyceny.
 *
 * @export
 * @class ModalPriceDetailsCtrl
 */
export default class ModalPriceDetailsCtrl {

    isArray  = angular.isArray;
    isObject = angular.isObject;
    segments = [];
    parts = [];

    page = 'segments';

    /**
     * Creates an instance of ModalPriceDetailsCtrl.
     *
     * @param {ng.ui.bootstrap.IModalServiceInstance} $uibModalInstance $uibModalInstance
     * @param {any} conf conf
     * @param {any} Core Core
     * @param {PriceDictionary} PriceDictionary PriceDictionary
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    constructor(
        private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        private $filter,
        private conf,
        private Core,
        private PriceDictionary: PriceDictionary) {
        'ngInject';

        const collator = new Intl.Collator();
        this.segments = Core.copy(conf.PriceSegments).filter(seg => seg).map(seg => {
            if (!seg) {
                return seg;
            }
            seg.name = this.getSegmentName(seg);

            seg.type = this.locale(seg.type);
            seg.data = this.locale(seg.data);
            seg.to = this.locale(seg.to);
            if (angular.isArray(seg.to)) {
                seg.to = seg.to.map(t => {
                    if (angular.isObject(t)) {
                        return this.describeObject(t);
                    }
                    return t;
                });
            }
            seg.noPrice = false;
            if (isNaN(parseFloat(seg.baseValue))) {
                seg.baseValue = '—';
                seg.noPrice = true;
            }
            if (isNaN(parseFloat(seg.value))) {
                seg.value = '—';
                seg.noPrice = true;
            }
            return seg;
        }).sort((seg1, seg2) => {
            if (!seg1 || !seg2) {
                return 0;
            }
            if (seg1.valueType === seg2.valueType) {
                return collator.compare(seg1.type, seg2.type);
            } else if (seg1.valueType == 'value' && seg2.valueType == 'percent') {
                return -1;
            } else if (seg1.valueType == 'value' && seg2.valueType == 'multiplier') {
                return -1;
            } else if (seg2.valueType == 'value' && seg1.valueType == 'percent') {
                return 1;
            } else if (seg2.valueType == 'value' && seg1.valueType == 'multiplier') {
                return 1;
            } else if (seg2.valueType == 'percent' && seg1.valueType == 'multiplier') {
                return 1;
            } else {
                return 0;
            }
        });

        this.parts = Core.copy(conf.PriceParts).filter(part => part && part.value !== 0).map(part => {
            if (!part) {
                return part;
            }
            let segValue = Core.copy(conf.PriceSegments.filter(seg => seg && seg.id === part.valueId)[0]);
            let segPercent = part.percentId ? Core.copy(conf.PriceSegments.filter(seg => seg && seg.id === part.percentId)[0]) : null;
            let partValueType = `${this.locale(segValue.type)}`;
            let partValueName = `#${this.getSegmentName(segValue) || partValueType}`;
            partValueType = '#' + partValueType;
            let percentSymbol = segPercent && segPercent.valueType === 'percent' ? '%' : '*';
            let partPercentType = segPercent ?  `${this.locale(segPercent.type)}` : '';
            let partPercentName = segPercent ? `${percentSymbol}${this.getSegmentName(segPercent) || partPercentType}` : '';
            partPercentType = percentSymbol + partPercentType;
            part.name = segPercent ? `${partValueName} × ${partPercentName}` : `${partValueName}`;
            part.type = segPercent ? `${partValueType} × ${partPercentType}` : `${partValueType}`;

            part.basePercent = segPercent && segPercent.valueType === 'percent' ? this.percentFormat(part.basePercent) + '%' : this.Core.round10(part.basePercent, -4);

            part.valueData = this.locale(segValue.data);
            part.percentData = segPercent ? this.locale(segPercent.data) : {};
            part.noPrice = false;
            if (isNaN(parseFloat(part.value))) {
                part.value = '—';
                part.noPrice = true;
            }
            return part;
        }).sort((part1, part2) => {
            if (!part1 || !part2) {
                return 0;
            }
            let seg1Value = Core.copy(conf.PriceSegments.filter(seg => seg && seg.id === part1.valueId)[0]);
            let seg1Percent = part1.percentId ? Core.copy(conf.PriceSegments.filter(seg => seg && seg.id === part1.percentId)[0]) : null;
            let seg2Value = Core.copy(conf.PriceSegments.filter(seg => seg && seg.id === part2.valueId)[0]);
            let seg2Percent = part2.percentId ? Core.copy(conf.PriceSegments.filter(seg => seg && seg.id === part2.percentId)[0]) : null;
            if (seg1Value.id === seg2Value.id) {
                if (seg1Percent == null) {
                    return -1;
                } else if (seg2Percent == null) {
                    return 1;
                } else if (seg1Percent.valueType === seg2Percent.valueType) {
                    return collator.compare(seg1Percent.type, seg2Percent.type);
                } else if (seg1Percent.valueType == 'value' && seg2Percent.valueType == 'percent') {
                    return -1;
                } else if (seg1Percent.valueType == 'value' && seg2Percent.valueType == 'multiplier') {
                    return -1;
                } else if (seg2Percent.valueType == 'value' && seg1Percent.valueType == 'percent') {
                    return 1;
                } else if (seg2Percent.valueType == 'value' && seg1Percent.valueType == 'multiplier') {
                    return 1;
                } else if (seg2Percent.valueType == 'percent' && seg1Percent.valueType == 'multiplier') {
                    return -1;
                } else {
                    return 0;
                }
            } else {
                if (seg1Value.valueType === seg2Value.valueType) {
                    return collator.compare(seg1Value.type, seg2Value.type);
                } else if (seg1Value.valueType == 'value' && seg2Value.valueType == 'percent') {
                    return -1;
                } else if (seg1Value.valueType == 'value' && seg2Value.valueType == 'multiplier') {
                    return -1;
                } else if (seg2Value.valueType == 'value' && seg1Value.valueType == 'percent') {
                    return 1;
                } else if (seg2Value.valueType == 'value' && seg1Value.valueType == 'multiplier') {
                    return 1;
                } else if (seg2Value.valueType == 'percent' && seg1Value.valueType == 'multiplier') {
                    return 1;
                } else {
                    return 0;
                }
            }
        });
    }

    getSegmentName(seg) {
        let segmentName = null;
        if (angular.isDefined(seg.data.name)) {
            segmentName = seg.data.name;
        } else if (angular.isDefined(seg.data.width) && angular.isDefined(seg.data.height) && angular.isDefined(seg.data.typesSymbol)) {
            segmentName = seg.data.typesSymbol.join(', ') + ', ' + seg.data.width + '×' + seg.data.height;
        } else if (angular.isDefined(seg.data.width) && angular.isDefined(seg.data.height)) {
            segmentName = seg.data.width + '×' + seg.data.height;
        } else if (angular.isDefined(seg.data.length)) {
            segmentName = seg.data.length;
        } else if (angular.isDefined(seg.data.shape)) {
            segmentName = seg.data.shape;
        }

        return segmentName;
    }

    /**
     * Zwraca tłumaczenie właściwości
     *
     * @param {any} elems
     * @returns {any} Przetłumaczone właściwości
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    locale(elems) {
        let elemsString = JSON.stringify(elems);
        let re;
        if (angular.isArray(elems)) {
            for (let k in this.PriceDictionary.dictionary) {
                re = new RegExp('"' + k + '"', 'g');
                elemsString = elemsString.replace(re, '"' + this.PriceDictionary.dictionary[k] + '"');
                re = new RegExp('"data\.' + k + '"', 'g');
                elemsString = elemsString.replace(re, '"' + this.PriceDictionary.dictionary[k] + '"');
            }

            return JSON.parse(elemsString);
        } else if (angular.isObject(elems)) {
            for (let k in this.PriceDictionary.dictionary) {
                re = new RegExp('"' + k + '":', 'g');
                elemsString = elemsString.replace(re, '"' + this.PriceDictionary.dictionary[k] + '":');
            }

            return JSON.parse(elemsString);
        } else if (angular.isString(elems)) {
            if (this.PriceDictionary.dictionary.hasOwnProperty(elems)) {
                return this.PriceDictionary.dictionary[elems];
            }
        }
        return elems;
    }

    describeObject(obj) {
        let description = '';
        let index = 0;
        for (const key in obj) {
            if (index > 0) {
                description += this.$filter('translate')('INTERFACE|i');
            }
            description += ` ${key} ${this.$filter('translate')('INTERFACE|jest')} ${obj[key]} `;
            index++;
        }
        return description;
    }

    /**
     * Zamienia ułamki na procenty
     *
     * @param {number} percent Ułamek
     * @returns {number} Procent
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    percentConvert(percent) {
        if (!isNaN(parseFloat(percent))) {
            return this.Core.round10(percent * 100 - 100, -3);
        }
        return percent;
    }

    /**
     * Formatuje procent
     *
     * @param {number} percent Ułamek
     * @returns {number} Procent
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    percentFormat(percent) {
        if (!isNaN(parseFloat(percent))) {
            return this.Core.round10(percent * 100, -3);
        }
        return percent;
    }

    /**
     * Zamyka modal.
     *
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    close() {
        this.$uibModalInstance.close();
    }
}
