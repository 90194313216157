/**
 * Kontroler wymiarow bram garazowych
 * @param {object} $rootScope              rootScope
 * @param {object} $filter                 Filtry angularowe
 * @param {object} Core                    Biblioteko podrecznych funkcji
 * @param {object} GarageDimensionsFactory Fabryka wymiarow
 * @param {object} ConfigurationsService   Info o biezacej konfiguracji
 */
export default function DimensionsCtrl($rootScope, $scope, $filter, Core, GarageDimensionsFactory, ConfigurationsService, EventBusService) {
    'ngInject';

    var vm = this;

    vm.init            = init;
    vm.boxSizes        = [];
    vm.subStep         = 1;
    vm.shapeData       = GarageDimensionsFactory.defaultDimensions;
    vm.defaultHeights  = {};
    vm.defaultWidths   = [];
    vm.valid           = GarageDimensionsFactory.valid;
    vm.changeDimension = changeDimension;
    vm.guidesBySymbol = {};
    vm.guides = [];
    vm.changedGuide = GarageDimensionsFactory.changedGuide;
    vm.refreshSizes    = refreshSizes;
    vm.mountLocation   = [
        {
            'value': 'in',
            'label': $filter('translate')('GARAGE|Montaż w otworze')
        },
        {
            'value': 'out',
            'label': $filter('translate')('GARAGE|Montaż poza otworem')
        }
    ];

    const broadcastSubscriptions = [];
    broadcastSubscriptions.push($rootScope.$on('changedStep', function(event, newVal, oldVal) {
        if (ConfigurationsService.conf.conf === 'garage') {
            GarageDimensionsFactory.setDimensions(vm.shapeData);
        }

        if (newVal.code != 'dimensions' && newVal.code !== oldVal.code && oldVal.code == 'dimensions') {
            vm.valid(vm.shapeData);
        }
    }));

    if (GarageDimensionsFactory.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration(['initializedConfigurator'], () => init()));
    $scope.$on('$destroy', () => {
        subscriptions.map(el => el.unsubscribe());
        broadcastSubscriptions.map(el => el());
    });

    /**
     * Inicjowanie poczatkowych wartosci
     */
    function init() {
        if (ConfigurationsService.conf.Current.type === 'garage') {
            vm.shapeData = Core.copy(ConfigurationsService.conf.Current.Shape);
            vm.defaultHeights = GarageDimensionsFactory.defaultSizes;
            vm.defaultWidths  = Object.keys(GarageDimensionsFactory.defaultSizes);
            vm.guides = GarageDimensionsFactory.garageGuides;
            vm.guidesBySymbol = GarageDimensionsFactory.getGuidesBySymbol();
        }
    }

    /**
     * Funkcja ustawiajaca wystepujace wymiary
     */
    function refreshSizes() {
        if (ConfigurationsService.conf.Current.DimensionsType == 'standard') {
            if (Object.keys(vm.defaultHeights).indexOf(vm.shapeData.width + '') === -1) {
                vm.shapeData.width = Object.keys(vm.defaultHeights)[0];
            }
            if (vm.defaultHeights[vm.shapeData.width].indexOf(vm.shapeData.height) === -1) {
                vm.shapeData.height = vm.defaultHeights[vm.shapeData.width][0];
            }
            GarageDimensionsFactory.changeDimension(vm.shapeData);
        }
    }

    function changeDimension(dimension, type) {
        if (type === 'width' && vm.defaultHeights && vm.defaultHeights[dimension.width] && vm.defaultHeights[dimension.width].indexOf(dimension.height) == -1) {
            dimension.height = vm.defaultHeights[dimension.width][0];
        }
        GarageDimensionsFactory.changeDimension(dimension);
    }
}
