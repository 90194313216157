export default class RoundIntegerDirective {
    constructor() {
        'ngInject';
        this.restrict = 'A';
        this.require  = 'ngModel';
    }
    link(scope, element, attr, ngModel) {
        element.bind("blur",function(e){
            ngModel.$parsers.push((data)=>{
                element.val(Math.round(data));
                return Math.round(data);
            })
        })
    }
    
}
