import { Color, SideColors, Wood } from '../common';
import { SimpleAccessory } from './SimpleAccessory';

export class Accessory implements SimpleAccessory {
    id: number;
    name: string;
    priceSource: 'table' | 'colors' | 'confColors';
    type: string;
    manufacturerCode: string;
    count: number;
    position: number;
    amount: number;
    width: number;
    height: number;
    currentLength?: number;
    comment: string;
    colorSet?: 'white' | 'color-white' | 'color-color' | 'ral';
    color?: {
        type?: string;
        accessory?: Color;
        profile?: SideColors;
    };
    category: {
        id: number;
        name: string;
    };
    wood?: Wood;
    weight: number;
    priceType: number;
    image: string | null;
    fromDependency?: boolean;

    constructor(accessory: any, configuratorsDataService?) {
        if (accessory && configuratorsDataService) {
            const accessoryData = configuratorsDataService.get('windowAccessories', accessory.id);
            if (accessoryData) {
                this.id = Number(accessoryData.id);
                this.name = accessoryData.name;
                this.priceSource = accessoryData.price_source;
                this.type = accessoryData.type;
                this.manufacturerCode = accessoryData.manufacturer_code;
                this.count = Number(accessory.count);
                this.amount = Number(accessory.amount);
                this.width = Number(accessoryData.width) || 0;
                this.height = Number(accessoryData.height) || 0;
                this.currentLength = Number(accessoryData.currentLength) || 0;
                this.comment = accessoryData.comment;
                this.weight = accessoryData.weight;
                this.fromDependency = accessory.accessory.fromDependency;
                this.priceType = Number(accessoryData.price_type);
                const accessoryCategoryData = configuratorsDataService.get(
                    'windowAccessoriesCategories',
                    accessoryData.window_accessories_category_id
                );
                this.category = {
                    id: accessoryData.window_accessories_category_id,
                    name: accessoryCategoryData ? accessoryCategoryData.name : accessory.category,
                };
                this.image = accessoryData.colors_imgs[accessory.accessory.selectedColor] !== ''
                    && accessoryData.colors_imgs[accessory.accessory.selectedColor] != null
                    ? '/files/windowhandlescolorswindowaccessory/'
                        + accessoryData.colors_imgs[accessory.accessory.selectedColor]
                    : (accessory.accessory.img
                        ? '/files/windowaccessory/' + accessory.accessory.img
                        : null);

                if (this.priceSource === 'table' && accessoryData.colorOptions) {
                    this.colorSet = accessoryData.colorOptions;
                }
                if (this.priceSource === 'colors' && accessoryData.color) {
                    this.color = {
                        accessory: {
                            id: Number(accessory.color.id),
                            name: accessory.color.name,
                            isDefault: false,
                        },
                    };
                }
                if (this.priceSource === 'confColors') {
                    this.color = {
                        profile: {
                            outer: null,
                            inner: null,
                            core: null,
                        },
                    };
                    ['outer', 'inner', 'core'].forEach(side => {
                        if (accessoryData.selectedColor.frame[side].id) {
                            this.color.profile[side] = {
                                id: Number(accessory.selectedColor.frame[side].id),
                                name: accessory.selectedColor.frame[side].name,
                                code: accessory.selectedColor.frame[side].code,
                                RAL: Boolean(accessory.selectedColor.frame[side].RAL),
                                isDefault: accessory.selectedColor.frame[side].isDefault,
                            };
                        }
                    });
                    if (
                        accessoryData.material === 'wood'
                        && accessoryData.selectedWood
                        && accessoryData.selectedWood.id
                    ) {
                        this.wood = {
                            id: Number(accessoryData.selectedWood.id),
                            name: accessoryData.selectedWood.name,
                        };
                    }
                }
            }
        } else if (accessory) {
            this.id = Number(accessory.accessory.id);
            this.name = accessory.accessory.name;
            this.priceSource = accessory.accessory.price_source;
            this.type = accessory.accessory.type;
            this.manufacturerCode = accessory.accessory.manufacturer_code;
            this.count = Number(accessory.count);
            this.position = Number(accessory.position);
            this.amount = Number(accessory.amount);
            this.width = Number(accessory.width) || Number(accessory.accessory.currentWidth) || 0;
            this.height =
                Number(accessory.height) || Number(accessory.accessory.currentHeight) || 0;
            this.currentLength = Number(accessory.accessory.currentLength) || 0;
            this.comment = accessory.comment;
            this.weight = accessory.weight;
            this.priceType = Number(accessory.accessory.price_type);
            this.fromDependency = accessory.accessory.fromDependency;
            this.category = {
                id: accessory.accessory.window_accessories_category_id,
                name: accessory.category,
            };
            this.image = accessory.accessory.colors_imgs[accessory.selectedColor] !== ''
                    && accessory.accessory.colors_imgs[accessory.selectedColor] != null
                    ? '/files/windowhandlescolorswindowaccessory/'
                        + accessory.accessory.colors_imgs[accessory.selectedColor]
                    : (accessory.accessory.img
                        ? '/files/windowaccessory/' + accessory.accessory.img
                        : null);

            if (this.priceSource === 'table' && accessory.colorOptions) {
                this.colorSet = accessory.colorOptions;
            }
            if (this.priceSource === 'colors' && accessory.accessory.color) {
                this.color = {
                    accessory: {
                        id: Number(accessory.accessory.color.id),
                        name: accessory.accessory.color.name,
                        isDefault: false,
                    },
                };
            }
            if (this.priceSource === 'confColors') {
                this.color = {
                    profile: {
                        outer: null,
                        inner: null,
                        core: null,
                    },
                };
                ['outer', 'inner', 'core'].forEach(side => {
                    if (accessory.accessory.selectedColor.frame[side].id) {
                        this.color.profile[side] = {
                            id: Number(accessory.accessory.selectedColor.frame[side].id),
                            name: accessory.accessory.selectedColor.frame[side].name,
                            code: accessory.accessory.selectedColor.frame[side].code,
                            RAL: Boolean(accessory.accessory.selectedColor.frame[side].RAL),
                            isDefault: accessory.accessory.selectedColor.frame[side].isDefault,
                        };
                    }
                });
                if (
                    accessory.accessory.material === 'wood'
                    && accessory.accessory.selectedWood
                    && accessory.accessory.selectedWood.id
                ) {
                    this.wood = {
                        id: Number(accessory.accessory.selectedWood.id),
                        name: accessory.accessory.selectedWood.name,
                    };
                }
            }
        }
    }
}
