const ProfilesShapesModalTemplate = require<string>('ngtemplate-loader!./profiles-shapes-modal.component.html');
import ProfilesShapesModalController from './profiles-shapes-modal.controller';

export const ProfilesShapesModalComponent: ng.IComponentOptions = {
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  templateUrl: ProfilesShapesModalTemplate,
  controller: ProfilesShapesModalController
};
