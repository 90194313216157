import Common from '../Common';
import { PriceFunc, PriceElemsData, PriceSegment } from './Prices';
import { CustomPricesRecords } from './CustomPrice';
import { Injectable, Inject } from '@angular/core';
import PriceColorsService from './price-colors.service';
import { APP_CONFIG, AppConfig } from '../config';
import { Sill } from 'configurations/parts/complementary_goods';

@Injectable()
export default class PriceAccessoryService {
    constructor(
        private PriceColorsService: PriceColorsService,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {}

    /**
     * Zwraca pole ceny dla danego zestawu kolorów.
     * @param  {object} colors Kolory konstrukcji
     * @param  {string} type   Rodzaj konfiguracji
     * @return {string}        Pole ceny
     */
    getAccessoryPriceField(colors, type) {
        let field = 'price_white';
        if (type === 'window' || type === 'door' || type === 'hs') {
            const colorType = this.PriceColorsService.getColorTypeForColors(colors, 2);

            if (['White'].indexOf(colorType) > -1) {
                field = 'price_white';
            } else if (['Inner', 'Outer'].indexOf(colorType) > -1) {
                field = 'price_white_colour';
            } else if (['Cream', 'Bilateral', 'Bicolor'].indexOf(colorType) > -1) {
                field = 'price_colour_colour';
            }

            if (
                colors.frame.inner.RAL
                || colors.frame.outer.RAL
                || colors.sash.inner.RAL
                || colors.sash.outer.RAL
            ) {
                field = 'price_ral';
            }
        }

        return field;
    }

    getAccessoryColorField(colors, type) {
        let colorField = 'white';
        if (type === 'window' || type === 'door' || type === 'hs') {
            const colorType = this.PriceColorsService.getColorTypeForColors(colors, 2);

            if (['White'].indexOf(colorType) > -1) {
                colorField = 'white';
            } else if (['Inner', 'Outer'].indexOf(colorType) > -1) {
                colorField = 'color-white';
            } else if (['Cream', 'Bilateral', 'Bicolor'].indexOf(colorType) > -1) {
                colorField = 'color-color';
            }

            if (
                colors.frame.inner.RAL
                || colors.frame.outer.RAL
                || colors.sash.inner.RAL
                || colors.sash.outer.RAL
            ) {
                colorField = 'ral';
            }
        }

        return colorField;
    }

    /**
     * Zwraca pole ceny dla ręcznie wybranego zestawu kolorów.
     * @param  {object}             accessory   Akcesorium
     * @param  {object}             color       Ręczny kolor
     * @param  {CustomPriceRecords} customPrice Indywidualne ceny
     * @return {string}                         Pole ceny
     */
    getAccessoryPriceCustom(accessory, color, customPrice: CustomPricesRecords) {
        let field = 'price_white';

        if (color === 'white') {
            field = 'price_white';
        } else if (color === 'color-white') {
            field = 'price_white_colour';
        } else if (color === 'color-color') {
            field = 'price_colour_colour';
        } else if (color === 'ral') {
            field = 'price_ral';
        }

        let price = parseFloat(accessory[field]);
        if (Common.isObject(customPrice) && customPrice[accessory.id]) {
            const customPriceAccessory = customPrice[accessory.id].getPrice(field);
            if (!isNaN(customPriceAccessory)) {
                price = customPriceAccessory;
            }
        }
        return price;
    }

    /**
     * Przelicza cenę dodatków przez ilość.
     * @param  {number} price     Cena jednostkowa
     * @param  {object} accessory Akcesorium
     * @return {number}           Cena za akcesorium
     */
    priceAccessoryUnit(price, accessory, sides, width, height) {
        switch (~~accessory.accessory.price_type) {
            // szt
            case 0:
                return parseFloat(price) * parseFloat(accessory.count);
            // m2
            case 1:
                return (
                    parseFloat(price) * parseFloat(accessory.count) * parseFloat(accessory.amount)
                );
            // mb
            case 2:
                let amount = accessory.amount;
                if (accessory.accessory.place.indexOf('side') > -1) {
                    switch (accessory.side) {
                        case 'left':
                        case 'right':
                            amount = height;
                            break;
                        case 'top':
                        case 'bottom':
                            amount = width + sides.left + sides.right;
                            break;
                    }
                }
                return (
                    (parseFloat(price) * parseFloat(accessory.count) * parseFloat(amount)) / 1000
                );
            // cena wyliczona indywidualnie w dobrach
            default:
                return parseFloat(price);
        }
    }

    /**
     * Zwraca cenę jednostkową dla dodatku z cenami  per kolor.
     * @param  {object}             accessory   Akcesorium
     * @param  {CustomPriceRecords} customPrice Indywidualne ceny
     * @return {number}                         Cena jednostkowa
     */
    priceAccessoryColor(accessory, customPrice: CustomPricesRecords) {
        let customPriceAccessory;
        if (Common.isObject(customPrice) && customPrice[accessory.id]) {
            customPriceAccessory = customPrice[accessory.id].getPrice(
                'WindowHandlesColor',
                accessory.selectedColor
            );
        }
        if (!isNaN(parseFloat(customPriceAccessory))) {
            return customPriceAccessory;
        }
        return parseFloat(accessory.colors_prices[accessory.selectedColor]);
    }

    priceAccessoryConfColor(accessory, customPrice: CustomPricesRecords) {
        if (!accessory.selectedColor) {
            return NaN;
        }
        const price = this.getAccessoryPrice(
            accessory,
            { type: accessory.material },
            accessory.selectedColor,
            customPrice
        );
        return price;
    }

    getAccessoryPrice(accessory, system, colors, customPrice: CustomPricesRecords) {
        const colorPrices = accessory.prices_colors || [];
        const colorSide = this.PriceColorsService.getColorTypeForColors(colors, 1);
        let colorGroups = [];
        let colorGroupsOut = [];
        let side = 'double';
        switch (colorSide) {
            case 'White':
            case 'Cream':
                colorGroups = colors.frame.core.groups;
                break;
            case 'Outer':
                colorGroups = colors.frame.outer.groups;
                side = 'single';
                break;
            case 'Inner':
                colorGroups = colors.frame.inner.groups;
                side = 'single';
                break;
            case 'Bilateral':
                colorGroups = colors.frame.inner.groups;
                break;
            case 'Bicolor':
                colorGroups = colors.frame.inner.groups;
                colorGroupsOut = colors.frame.outer.groups;
                side = 'bicolor';
                break;
        }
        const accessoryPrice = colorPrices
            .map((p, index) => {
                p.id = index;
                return p;
            })
            .filter(
                p =>
                    p.material === system.type
                    && Common.isArray(colorGroups)
                    && colorGroups.indexOf(p.colorGroup) > -1
                    && (side !== 'bicolor'
                        || (side === 'bicolor'
                            && Common.isArray(colorGroupsOut)
                            && colorGroupsOut.indexOf(p.colorGroupOut) > -1))
                    && p.side === side
            )[0];
        if (accessoryPrice) {
            let accessoryPriceCustom;
            if (Common.isObject(customPrice) && customPrice[accessory.id]) {
                accessoryPriceCustom = customPrice[accessory.id].getPrice(
                    'prices_colors',
                    accessoryPrice.id,
                    'price'
                );
            }
            if (accessoryPriceCustom && !isNaN(accessoryPriceCustom)) {
                return accessoryPriceCustom;
            }
            return Common.isNumber(accessoryPrice.price) ? accessoryPrice.price : NaN;
        }
        return NaN;
    }

    /**
     * Zwraca ceną jednostkową dla dodatku z cenami za układ kolorów.
     * @param  {object}             accessory   Akcesorium
     * @param  {array}              colors      Kolory konstrukcji
     * @param  {string}             type        Rodzaj konfiguracji
     * @param  {CustomPriceRecords} customPrice Indywidualne ceny
     * @return {number}                         Cena jednostkowa
     */
    priceAccessoryOwn(accessory, colors, type, customPrice: CustomPricesRecords) {
        const field = this.getAccessoryPriceField(colors, type);
        let price = parseFloat(accessory[field]);
        if (Common.isObject(customPrice) && customPrice[accessory.id]) {
            const customPriceAccessory = customPrice[accessory.id].getPrice(field);
            if (!isNaN(customPriceAccessory)) {
                price = customPriceAccessory;
            }
        }
        return price;
    }

    /**
     * Zwraca cenę akcesorium.
     * @param  {object} accessory   Akcesorium
     * @param  {object} colors      Kolory konstrukcji
     * @param  {string} type        Rodzaj konfiguracji
     * @return {number}             Cena za akcesorium
     */
    priceAccessory(
        accessory,
        colors,
        customPrice: CustomPricesRecords,
        sides?,
        width?,
        height?,
        type?,
        multiplier = 1
    ) {
        if (!accessory.accessory.no_price) {
            let price = 0;

            if (accessory.accessory.show_colors && accessory.accessory.price_source === 'table') {
                price = this.getAccessoryPriceCustom(
                    accessory.accessory,
                    accessory.colorOptions,
                    customPrice
                );
            } else if (accessory.accessory.price_source === 'table') {
                price = this.priceAccessoryOwn(accessory.accessory, colors, type, customPrice);
            } else if (accessory.accessory.price_source === 'colors') {
                price = this.priceAccessoryColor(accessory.accessory, customPrice);
            } else if (accessory.accessory.price_source === 'confColors') {
                price += this.priceAccessoryConfColor(accessory.accessory, customPrice);
            } else {
                price = parseFloat(accessory.accessory.price);
            }

            if (['window', 'door', 'hs', 'folding_door'].includes(type)) {
                price += Number(accessory.accessory.price_for_assembly);
            }

            return this.priceAccessoryUnit(price, accessory, sides, width, height) * multiplier;
        } else {
            return NaN;
        }
    }

    /**
     * Zwraca cenę jednostkową akcesorium.
     * @param  {object} accessory   Akcesorium
     * @param  {object} colors      Kolory konstrukcji
     * @param  {string} type        Rodzaj konfiguracji
     * @return {number}             Cena za akcesorium
     */
    priceAccessoryOne(
        accessory,
        colors,
        customPrice: CustomPricesRecords,
        type,
        color,
        multiplier = 1
    ) {
        if (!accessory.no_price) {
            let price = 0;

            if (accessory.show_colors && accessory.price_source === 'table') {
                price += this.getAccessoryPriceCustom(accessory, color, customPrice);
            } else if (accessory.price_source === 'table') {
                price += this.priceAccessoryOwn(accessory, colors, type, customPrice);
            } else if (accessory.price_source === 'confColors') {
                price += this.priceAccessoryConfColor(accessory, customPrice);
            } else if (accessory.price_source === 'colors') {
                price += this.priceAccessoryColor(accessory, customPrice);
            }

            if (['window', 'door', 'hs', 'folding_door'].includes(type)) {
                price += Number(accessory.price_for_assembly);
            }

            return price * multiplier;
        } else {
            return NaN;
        }
    }

    /**
     * Dolicza dopłatę za dodatki.
     * @param  {number} price        Cena wejściowa
     * @param  {object} PriceElems   Wycena
     * @param  {object} NoPriceElems Elementy bez wyceny
     * @param  {array}  accessories  Tablica z akcesoriami
     * @param  {object} colors       Kolory konstrukcji
     * @param  {string} type         Rodzaj konfiguracji
     * @return {number}              Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'accessories',
        data: {
            accessories: 'conf.Accessories',
            colors: 'conf.Colors',
            type: 'conf.type',
            customPrice: 'price.WindowAccessory',
        },
    })
    suppAccessories(
        { PriceElems, NoPriceElems }: PriceElemsData,
        {
            accessories,
            colors,
            type,
            customPrice,
        }: { accessories; colors; type; customPrice: CustomPricesRecords }
    ): PriceSegment[] {
        let elemPrice = 0;
        const priceSegments: PriceSegment[] = [];

        for (let i = accessories.length - 1; i >= 0; i--) {
            if (!accessories[i].accessory.no_price) {
                elemPrice = this.priceAccessory(
                    accessories[i],
                    colors || {},
                    customPrice,
                    null,
                    null,
                    null,
                    type
                );
                if (!isNaN(elemPrice)) {
                    PriceElems.accessories.push({
                        id: Number(accessories[i].accessory.id),
                        name: accessories[i].accessory.name,
                        count: accessories[i].count,
                        amount: accessories[i].amount,
                        type: accessories[i].accessory.price_type,
                        comment: accessories[i].comment,
                        price: elemPrice,
                        colorField: accessories[i].colorOptions,
                        colorSash:
                            accessories[i].accessory.selectedColor
                            && accessories[i].accessory.selectedColor.frame
                                ? accessories[i].accessory.selectedColor
                                : '',
                        color: accessories[i].accessory.color
                            ? accessories[i].accessory.color.name
                            : '',
                        colorId: accessories[i].accessory.color
                            ? Number(accessories[i].accessory.color.id)
                            : null,
                    });
                    priceSegments.push({
                        type: 'accessories',
                        baseValue: elemPrice,
                        value: elemPrice,
                        valueType: 'value',
                        data: {
                            id: Number(accessories[i].accessory.id),
                            name: accessories[i].accessory.name,
                            count: accessories[i].count,
                            amount: accessories[i].amount,
                            type: accessories[i].accessory.price_type,
                            comment: accessories[i].comment,
                            colorField: accessories[i].colorOptions,
                            color: accessories[i].accessory.color
                                ? accessories[i].accessory.color.name
                                : '',
                            colorSash:
                                accessories[i].accessory.selectedColor
                                && accessories[i].accessory.selectedColor.frame
                                    ? accessories[i].accessory.selectedColor
                                    : '',
                            categoryId: accessories[i].accessory.window_accessories_category_id,
                            colorId: accessories[i].accessory.color
                                ? Number(accessories[i].accessory.color.id)
                                : null,
                        },
                    });
                    continue;
                }
            }
            NoPriceElems.accessories.push({
                id: Number(accessories[i].accessory.id),
                name: accessories[i].accessory.name,
                count: accessories[i].count,
                amount: accessories[i].amount,
                type: accessories[i].accessory.price_type,
                comment: accessories[i].comment,
                price: NaN,
                colorField: accessories[i].colorOptions,
                color: accessories[i].accessory.color ? accessories[i].accessory.color.name : '',
                colorSash:
                    accessories[i].accessory.selectedColor
                    && accessories[i].accessory.selectedColor.frame
                        ? accessories[i].accessory.selectedColor
                        : '',
                colorId: accessories[i].accessory.color ? Number(accessories[i].accessory.color.id) : null,
                cause: 'no price',
            });
            priceSegments.push({
                type: 'accessories',
                baseValue: null,
                value: null,
                valueType: 'value',
                data: {
                    id: Number(accessories[i].accessory.id),
                    name: accessories[i].accessory.name,
                    count: accessories[i].count,
                    amount: accessories[i].amount,
                    type: accessories[i].accessory.price_type,
                    comment: accessories[i].comment,
                    colorField: accessories[i].colorOptions,
                    color: accessories[i].accessory.color
                        ? accessories[i].accessory.color.name
                        : '',
                    colorSash:
                        accessories[i].accessory.selectedColor
                        && accessories[i].accessory.selectedColor.frame
                            ? accessories[i].accessory.selectedColor
                            : '',
                    categoryId: accessories[i].accessory.window_accessories_category_id,
                    colorId: accessories[i].accessory.color
                        ? Number(accessories[i].accessory.color.id)
                        : null,
                    cause: 'no price',
                },
            });
        }

        return priceSegments;
    }

    /**
     * Dolicza dopłatę za dodatki per kwatera.
     * @param  {number} price        Cena wejściowa
     * @param  {object} PriceElems   Wycena
     * @param  {object} NoPriceElems Elementy bez wyceny
     * @param  {array}  sashes       Skrzydła
     * @param  {object} colors       Kolory konstrukcji
     * @return {number}              Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'sashesHardware',
        data: {
            sashes: 'conf.Sashes',
            colors: 'conf.Colors',
            type: 'conf.type',
            customPrice: 'price.WindowAccessory',
        },
    })
    suppSashesHardware(
        { PriceElems, NoPriceElems }: PriceElemsData,
        {
            sashes,
            colors,
            type,
            customPrice,
        }: { sashes; colors; type; customPrice: CustomPricesRecords }
    ): PriceSegment[] {
        let supp = 0,
            elemPrice = 0;
        let sash: any = {};
        let i = 0,
            j = 0;
        const priceSegments: PriceSegment[] = [];

        for (i = sashes.length - 1; i >= 0; i--) {
            sash = sashes[i];
            for (j = sash.hardware.length - 1; j >= 0; j--) {
                if (!sash.hardware[j].accessory.no_price) {
                    elemPrice = this.priceAccessory(
                        sash.hardware[j],
                        colors,
                        customPrice,
                        null,
                        null,
                        null,
                        type
                    );
                    if (!isNaN(supp)) {
                        PriceElems.sashAccessories.push({
                            id: Number(sash.hardware[j].accessory.id),
                            sashId: sash.id,
                            name: sash.hardware[j].accessory.name,
                            count: sash.hardware[j].count,
                            amount: sash.hardware[j].amount,
                            type: sash.hardware[j].accessory.price_type,
                            comment: sash.hardware[j].comment,
                            price: elemPrice,
                            colorField: sash.hardware[j].colorOptions,
                            color: sash.hardware[j].accessory.color
                                ? sash.hardware[j].accessory.color.name
                                : '',
                            colorSash:
                                sash.hardware[j].accessory.selectedColor
                                && sash.hardware[j].accessory.selectedColor.frame
                                    ? sash.hardware[j].accessory.selectedColor
                                    : '',
                            colorId: sash.hardware[j].accessory.color
                                ? Number(sash.hardware[j].accessory.color.id)
                                : null,
                        });
                        priceSegments.push({
                            type: 'sashesHardware',
                            baseValue: elemPrice,
                            value: elemPrice,
                            valueType: 'value',
                            data: {
                                id: Number(sash.hardware[j].accessory.id),
                                sashId: sash.id,
                                name: sash.hardware[j].accessory.name,
                                count: sash.hardware[j].count,
                                amount: sash.hardware[j].amount,
                                type: sash.hardware[j].accessory.price_type,
                                comment: sash.hardware[j].comment,
                                colorField: sash.hardware[j].colorOptions,
                                color: sash.hardware[j].accessory.color
                                    ? sash.hardware[j].accessory.color.name
                                    : '',
                                colorSash:
                                    sash.hardware[j].accessory.selectedColor
                                    && sash.hardware[j].accessory.selectedColor.frame
                                        ? sash.hardware[j].accessory.selectedColor
                                        : '',
                                categoryId:
                                    sash.hardware[j].accessory.window_accessories_category_id,
                                colorId: sash.hardware[j].accessory.color
                                    ? Number(sash.hardware[j].accessory.color.id)
                                    : null,
                            },
                        });
                        continue;
                    }
                }
                NoPriceElems.sashAccessories.push({
                    id: Number(sash.hardware[j].accessory.id),
                    sashId: sash.id,
                    name: sash.hardware[j].accessory.name,
                    count: sash.hardware[j].count,
                    amount: sash.hardware[j].amount,
                    type: sash.hardware[j].accessory.price_type,
                    comment: sash.hardware[j].comment,
                    price: NaN,
                    colorField: sash.hardware[j].colorOptions,
                    color: sash.hardware[j].accessory.color
                        ? sash.hardware[j].accessory.color.name
                        : '',
                    colorSash:
                        sash.hardware[j].accessory.selectedColor
                        && sash.hardware[j].accessory.selectedColor.frame
                            ? sash.hardware[j].accessory.selectedColor
                            : '',
                    colorId: sash.hardware[j].accessory.color
                        ? Number(sash.hardware[j].accessory.color.id)
                        : null,
                    cause: 'no price',
                });
                priceSegments.push({
                    type: 'sashesHardware',
                    baseValue: null,
                    value: null,
                    valueType: 'value',
                    data: {
                        id: Number(sash.hardware[j].accessory.id),
                        sashId: sash.id,
                        name: sash.hardware[j].accessory.name,
                        count: sash.hardware[j].count,
                        amount: sash.hardware[j].amount,
                        type: sash.hardware[j].accessory.price_type,
                        comment: sash.hardware[j].comment,
                        colorField: sash.hardware[j].colorOptions,
                        color: sash.hardware[j].accessory.color
                            ? sash.hardware[j].accessory.color.name
                            : '',
                        colorSash:
                            sash.hardware[j].accessory.selectedColor
                            && sash.hardware[j].accessory.selectedColor.frame
                                ? sash.hardware[j].accessory.selectedColor
                                : '',
                        categoryId: sash.hardware[j].accessory.window_accessories_category_id,
                        colorId: sash.hardware[j].accessory.color
                            ? Number(sash.hardware[j].accessory.color.id)
                            : null,
                        cause: 'no price',
                    },
                });
            }
        }

        return priceSegments;
    }

    /**
     * Dolicza dopłatę za dodatki boczne.
     * @param  {number} price        Cena wejściowa
     * @param  {object} PriceElems   Wycena
     * @param  {object} NoPriceElems Elementy bez wyceny
     * @param  {array}  sashes       Skrzydła
     * @param  {object} colors       Kolory konstrukcji
     * @return {number}              Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'sideAccessories',
        data: {
            sideAccessories: 'conf.SideAccessories',
            colors: 'conf.Colors',
            width: 'conf.Width',
            height: 'conf.Height',
            type: 'conf.type',
            customPrice: 'price.WindowAccessory',
        },
    })
    suppSidesAccessories(
        { PriceElems, NoPriceElems }: PriceElemsData,
        {
            sideAccessories,
            colors,
            width,
            height,
            type,
            customPrice,
        }: { sideAccessories; colors; width; height; type; customPrice: CustomPricesRecords }
    ): PriceSegment[] {
        let supp = 0,
            elemPrice = 0;
        let side = '',
            accessories = [];
        let i = 0,
            j = 0;
        const sides = ['left', 'right', 'top', 'bottom'];
        const priceSegments: PriceSegment[] = [];

        for (i = sides.length - 1; i >= 0; i--) {
            side = sides[i];
            accessories = sideAccessories[side];
            for (j = accessories.length - 1; j >= 0; j--) {
                if (!accessories[j].accessory.no_price) {
                    elemPrice = this.priceAccessory(
                        accessories[j],
                        colors,
                        customPrice,
                        sideAccessories.sizes,
                        width,
                        height,
                        type
                    );
                    if (!isNaN(supp)) {
                        PriceElems.sideAccessories.push({
                            id: Number(accessories[j].accessory.id),
                            side,
                            name: accessories[j].accessory.name,
                            count: accessories[j].count,
                            amount: accessories[j].amount,
                            type: accessories[j].accessory.price_type,
                            comment: accessories[j].comment,
                            price: elemPrice,
                            colorField: accessories[j].colorOptions,
                            color: accessories[j].accessory.color
                                ? accessories[j].accessory.color.name
                                : '',
                            colorSash:
                                accessories[j].accessory.selectedColor
                                && accessories[j].accessory.selectedColor.frame
                                    ? accessories[j].accessory.selectedColor
                                    : '',
                            colorId: accessories[j].accessory.color
                                ? Number(accessories[j].accessory.color.id)
                                : null,
                        });
                        priceSegments.push({
                            type: 'sideAccessories',
                            baseValue: elemPrice,
                            value: elemPrice,
                            valueType: 'value',
                            data: {
                                id: Number(accessories[j].accessory.id),
                                side,
                                name: accessories[j].accessory.name,
                                count: accessories[j].count,
                                amount: accessories[j].amount,
                                type: accessories[j].accessory.price_type,
                                comment: accessories[j].comment,
                                colorField: accessories[j].colorOptions,
                                color: accessories[j].accessory.color
                                    ? accessories[j].accessory.color.name
                                    : '',
                                colorSash:
                                    accessories[j].accessory.selectedColor
                                    && accessories[j].accessory.selectedColor.frame
                                        ? accessories[j].accessory.selectedColor
                                        : '',
                                categoryId: accessories[j].accessory.window_accessories_category_id,
                                colorId: accessories[j].accessory.color
                                    ? Number(accessories[j].accessory.color.id)
                                    : null,
                            },
                        });
                        continue;
                    }
                }
                supp = NaN;
                NoPriceElems.sideAccessories.push({
                    id: Number(accessories[j].accessory.id),
                    side,
                    name: accessories[j].accessory.name,
                    count: accessories[j].count,
                    amount: accessories[j].amount,
                    type: accessories[j].accessory.price_type,
                    comment: accessories[j].comment,
                    price: NaN,
                    colorField: accessories[j].colorOptions,
                    color: accessories[j].accessory.color
                        ? accessories[j].accessory.color.name
                        : '',
                    colorSash:
                        accessories[j].accessory.selectedColor
                        && accessories[j].accessory.selectedColor.frame
                            ? accessories[j].accessory.selectedColor
                            : '',
                    cause: 'no price',
                });
                priceSegments.push({
                    type: 'sideAccessories',
                    baseValue: null,
                    value: null,
                    valueType: 'value',
                    data: {
                        id: Number(accessories[j].accessory.id),
                        side,
                        name: accessories[j].accessory.name,
                        count: accessories[j].count,
                        amount: accessories[j].amount,
                        type: accessories[j].accessory.price_type,
                        comment: accessories[j].comment,
                        colorField: accessories[j].colorOptions,
                        color: accessories[j].accessory.color
                            ? accessories[j].accessory.color.name
                            : '',
                        colorSash:
                            accessories[j].accessory.selectedColor
                            && accessories[j].accessory.selectedColor.frame
                                ? accessories[j].accessory.selectedColor
                                : '',
                        categoryId: accessories[j].accessory.window_accessories_category_id,
                        colorId: accessories[j].accessory.color
                            ? Number(accessories[j].accessory.color.id)
                            : null,
                        cause: 'no price',
                    },
                });
            }
        }
        return priceSegments;
    }

    /**
     * Dolicza dopłatę za dobra komplementarne.
     * @param  {number} price        Cena wejściowa
     * @param  {object} PriceElems   Wycena
     * @param  {object} NoPriceElems Elementy bez wyceny
     * @param  {object} colors       Kolory konstrukcji
     * @return {number}              Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'complementaryGoods',
        data: {
            complementaryGoods: 'conf.ComplementaryGoods',
            customPrice: 'price.WindowAccessory',
        },
    })
    suppComplementaryGoods(
        { PriceElems, NoPriceElems }: PriceElemsData,
        {
            complementaryGoods,
            customPrice,
        }: { complementaryGoods; customPrice: CustomPricesRecords }
    ): PriceSegment[] {
        let supp = 0,
            elemPrice = 0;
        let side = '',
            accessories = [];
        let i = 0,
            j = 0;
        const sides = ['windowsill', 'glass', 'accessory', 'profile'];
        const priceSegments: PriceSegment[] = [];

        for (i = sides.length - 1; i >= 0; i--) {
            side = sides[i];
            accessories = complementaryGoods[side];
            for (j = accessories.length - 1; j >= 0; j--) {
                if (!accessories[j].accessory.no_price) {
                    // elemPrice = parseFloat(accessories[j].accessory.price) * accessories[j].count;
                    elemPrice = this.priceAccessory(
                        accessories[j],
                        {},
                        customPrice,
                        null,
                        null,
                        null,
                        'complementary_goods'
                    );
                    if (!isNaN(elemPrice)) {
                        PriceElems.complementaryGoods[side].push({
                            id: Number(accessories[j].accessory.id),
                            good: side,
                            name: accessories[j].accessory.name,
                            count: accessories[j].count,
                            amount: accessories[j].amount,
                            type: accessories[j].accessory.price_type,
                            warmEdge: accessories[j].accessory.warmEdge,
                            comment: accessories[j].comment,
                            price: elemPrice,
                            color: accessories[j].accessory.color
                                ? accessories[j].accessory.color.name
                                : accessories[j].accessory.colorName
                                ? accessories[j].accessory.colorName
                                : '',
                            colorSash:
                                accessories[j].accessory.selectedColor
                                && accessories[j].accessory.selectedColor.frame
                                    ? accessories[j].accessory.selectedColor
                                    : '',
                            colorId: accessories[j].accessory.color
                                ? Number(accessories[j].accessory.color.id)
                                : null,
                            colorField: accessories[j].colorOptions
                                ? accessories[j].colorOptions
                                : null,
                            categoryId: accessories[j].accessory.window_accessories_category_id,
                            currentWidth: accessories[j].accessory.currentWidth,
                            currentHeight: accessories[j].accessory.currentHeight,
                            currentLength: accessories[j].accessory.currentLength,
                            plugs: !!~~accessories[j].accessory.plugs,
                        });
                        priceSegments.push({
                            type: 'complementaryGoods',
                            baseValue: elemPrice,
                            value: elemPrice,
                            valueType: 'value',
                            data: {
                                id: Number(accessories[j].accessory.id),
                                good: side,
                                name: accessories[j].accessory.name,
                                count: accessories[j].count,
                                amount: accessories[j].amount,
                                type: accessories[j].accessory.price_type,
                                warmEdge: accessories[j].accessory.warmEdge,
                                comment: accessories[j].comment,
                                color: accessories[j].accessory.color
                                    ? accessories[j].accessory.color.name
                                    : accessories[j].accessory.colorName
                                    ? accessories[j].accessory.colorName
                                    : '',
                                colorSash:
                                    accessories[j].accessory.selectedColor
                                    && accessories[j].accessory.selectedColor.frame
                                        ? accessories[j].accessory.selectedColor
                                        : '',
                                colorId: accessories[j].accessory.color
                                    ? Number(accessories[j].accessory.color.id)
                                    : null,
                                colorField: accessories[j].colorOptions
                                    ? accessories[j].colorOptions
                                    : null,
                                categoryId: accessories[j].accessory.window_accessories_category_id,
                                currentWidth: accessories[j].accessory.currentWidth,
                                currentHeight: accessories[j].accessory.currentHeight,
                                currentLength: accessories[j].accessory.currentLength,
                                plugs: !!~~accessories[j].accessory.plugs,
                            },
                        });
                        continue;
                    }
                }
                supp = NaN;
                NoPriceElems.complementaryGoods[side].push({
                    id: Number(accessories[j].accessory.id),
                    good: side,
                    name: accessories[j].accessory.name,
                    count: accessories[j].count,
                    amount: accessories[j].amount,
                    type: accessories[j].accessory.price_type,
                    warmEdge: accessories[j].accessory.warmEdge,
                    comment: accessories[j].comment,
                    price: NaN,
                    color: accessories[j].accessory.color
                        ? accessories[j].accessory.color.name
                        : '',
                    colorId: accessories[j].accessory.color
                        ? Number(accessories[j].accessory.color.id)
                        : null,
                    colorField: accessories[j].colorOptions ? accessories[j].colorOptions : null,
                    categoryId: accessories[j].accessory.window_accessories_category_id,
                    cause: 'no price',
                });
                priceSegments.push({
                    type: 'complementaryGoods',
                    baseValue: null,
                    value: null,
                    valueType: 'value',
                    data: {
                        id: Number(accessories[j].accessory.id),
                        good: side,
                        name: accessories[j].accessory.name,
                        count: accessories[j].count,
                        amount: accessories[j].amount,
                        type: accessories[j].accessory.price_type,
                        warmEdge: accessories[j].accessory.warmEdge,
                        comment: accessories[j].comment,
                        color: accessories[j].accessory.color
                            ? accessories[j].accessory.color.name
                            : '',
                        colorId: accessories[j].accessory.color
                            ? Number(accessories[j].accessory.color.id)
                            : null,
                        colorField: accessories[j].colorOptions
                            ? accessories[j].colorOptions
                            : null,
                        categoryId: accessories[j].accessory.window_accessories_category_id,
                    },
                });
            }
        }

        return priceSegments;
    }

    /**
     * Dolicza dopłatę za dobra komplementarne.
     * @param  {number} price        Cena wejściowa
     * @param  {object} PriceElems   Wycena
     * @param  {object} NoPriceElems Elementy bez wyceny
     * @param  {object} colors       Kolory konstrukcji
     * @return {number}              Cena po dopłacie
     */
    @PriceFunc({
        shortName: 'sills',
        data: {
            windowSills: 'conf.windowSills',
            customPrice: 'price.WindowAccessory',
        },
    })
    suppWindowSills(
        {  }: PriceElemsData,
        { windowSills }: { windowSills: Sill[] }
    ): PriceSegment[] {
        let elemPrice = 0;
        const side = '';
        let j = 0;
        const priceSegments: PriceSegment[] = [];

        for (j = windowSills.length - 1; j >= 0; j--) {
            elemPrice = Number(windowSills[j].price);
            if (!isNaN(elemPrice)) {
                priceSegments.push({
                    type: 'complementaryGoods',
                    baseValue: elemPrice,
                    value: elemPrice,
                    valueType: 'value',
                    data: {
                        id: Number(windowSills[j].id),
                        good: 'windowsill',
                        name: windowSills[j].name,
                        count: windowSills[j].count,
                        amount: windowSills[j].length,
                        comment: windowSills[j].comment,
                        color: windowSills[j].color ? windowSills[j].color.name : '',
                        colorId: windowSills[j].color ? Number(windowSills[j].color.id) : null,
                        currentWidth: windowSills[j].width,
                        currentLength: windowSills[j].length,
                        plugs: !!~~windowSills[j].plugs,
                    },
                });
                continue;
            }

            priceSegments.push({
                type: 'complementaryGoods',
                baseValue: null,
                value: null,
                valueType: 'value',
                data: {
                    id: Number(windowSills[j].id),
                    good: 'windowsill',
                    name: windowSills[j].name,
                    count: windowSills[j].count,
                    amount: windowSills[j].length,
                    comment: windowSills[j].comment,
                    color: windowSills[j].color ? windowSills[j].color.name : '',
                    colorId: windowSills[j].color ? Number(windowSills[j].color.id) : null,
                    currentWidth: windowSills[j].width,
                    currentLength: windowSills[j].length,
                    plugs: !!~~windowSills[j].plugs,
                },
            });
        }

        return priceSegments;
    }
}
