export default function ModalOfferHistoryCtrl($uibModalInstance, $location, offers, current) {
    'ngInject';

    var vm = this;
    vm.offers    = offers;
    vm.current   = current;
    vm.close     = close;
    vm.showOrder = showOrder;

    /**
     * Zamyka okno modalne
     */
    function close() {
        $uibModalInstance.close();
    }

    /**
     * Przejdź do zamówienia i zamknij okno
     * @param  {string} id Id zamówienia
     */
    function showOrder(id) {
        $uibModalInstance.close();
        $location.url('/app/offers_view/' + id);
    }
}
