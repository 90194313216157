import 'angular-ui-bootstrap';

const ModalParametersTemplate = require<string>('ngtemplate-loader!./ModalParametersTemplate.html');

/**
 * Usługa liczenia parametrów Uw/Sw
 *
 * @export
 * @class ParametersService
 */
export default class ParametersModalService {
    /**
     * Konstruktor
     */
    constructor(
        private $uibModal: ng.ui.bootstrap.IModalService,
    ) {
        'ngInject';
    }

    /**
     * Modal
     */
    showModal(conf) {
        this.$uibModal.open({
            templateUrl: ModalParametersTemplate,
            controller : 'ModalParametersCtrl as $ctrl',
            resolve    : {
                conf: () => conf,
            },
        });
    }
}
