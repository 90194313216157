import { Configuration } from './Configuration';
import { PriceSegment, DiscountGroups, PricePart } from '../price/Prices';
import { TimeLimitSegment } from '../../application/src/time_limit/time_limit_functions';
import { RollerShutter, RollerShutterParameters } from './parts/roller_shutter';
import { Accessory } from './parts/common';

import RollerShutterActiveConfiguration from './RollerShutterActiveConfiguration';
import { core } from '../helpers';

export default class RollerShutterConfiguration implements Configuration {

    public static is(configuration): configuration is RollerShutterConfiguration {
        return configuration instanceof RollerShutterConfiguration || configuration.$version;
    }
    $version = 6;
    type: 'roller_shutter' = 'roller_shutter';
    name = '';
    price: number | null = null;
    priceNoMargin: number | null = null;
    priceAfterDiscounts: number | null = null;
    quantity: number = 1;
    priceSegments: PriceSegment[] = [];
    priceSegmentsNoMargin: PriceSegment[] = [];
    priceParts: PricePart[] = [];
    pricePartsNoMargin: PricePart[] = [];
    discountGroups: DiscountGroups = {};
    discountGroupsNoMargin: DiscountGroups = {};
    title = '';
    description = '';
    attachments: any[] = [];
    valid: {} = {};
    accessories: Accessory[] = [];
    rollerShutter: RollerShutter = null;
    timeLimit: number | null = null;
    timeLimitsStack: TimeLimitSegment[] = [];
    parameters: RollerShutterParameters = {
        weight: null,
    };

    constructor(configuration, dataRequiredToUpdate?: any, conversion = true) {
        if (RollerShutterConfiguration.is(configuration) && conversion) {
            this.runConversion(configuration, dataRequiredToUpdate);
        } else {
            if (RollerShutterConfiguration.is(configuration)) {
                for (const propName in configuration) {
                    if (this.hasOwnProperty(propName)) {
                        this[propName] = configuration[propName];
                    }
                }
            } else {
                if (RollerShutterActiveConfiguration.is(configuration)) {
                    this.name = configuration.Name;
                    this.price = configuration.Price;
                    this.priceNoMargin = configuration.PriceNoMargin;
                    this.priceAfterDiscounts =
                        configuration.PriceAfterDiscounts || configuration.Price;
                    this.quantity = configuration.Quantity;
                    this.priceSegments = configuration.PriceSegments;
                    this.priceSegmentsNoMargin = configuration.PriceSegmentsNoMargin;
                    this.priceParts = configuration.PriceParts;
                    this.pricePartsNoMargin = configuration.PricePartsNoMargin;
                    this.discountGroups = configuration.DiscountGroups;
                    this.discountGroupsNoMargin = configuration.DiscountGroupsNoMargin;
                    this.title = configuration.Title;
                    this.description = configuration.Description;
                    this.attachments = configuration.Attachments;
                    this.valid = configuration.valid;
                    this.timeLimit = configuration.timeLimit;
                    this.timeLimitsStack = configuration.timeLimitsStack;
                    if (configuration.Accessories) {
                        this.accessories = configuration.Accessories.map<Accessory>(
                            accessory => new Accessory(accessory)
                        );
                    }

                    this.rollerShutter = new RollerShutter(configuration.RollerShutter);
                    this.parameters = {
                        weight: configuration.Weight,
                    };
                }
            }
        }
    }

    protected runConversion(configuration, dataRequiredToUpdate: any) {
        let updatedConfiguration = core.copy(configuration);
        let updateFunction;
        for (let version = configuration.$version + 1; version <= this.$version; version++) {
            updatedConfiguration.$version = version;
            updateFunction = `changesInVersion${version}`;
            if (this[updateFunction] && typeof this[updateFunction] === 'function') {
                updatedConfiguration = this[updateFunction](
                    updatedConfiguration,
                    dataRequiredToUpdate
                );
            }
        }
        (Object as any).entries(updatedConfiguration).forEach(([key, value]) => (this[key] = value));
    }

    /**
     * Aktualizacja konfiguracji z wersji 3.x
     */
    private changesInVersion4(oldConfiguration, dataRequiredToUpdate) {
        const configuration = {
            $version: 4,
            type: 'roller_shutter',
            name: '',
            price: null,
            priceNoMargin: null,
            priceAfterDiscounts: null,
            quantity: 1,
            priceSegments: [],
            priceSegmentsNoMargin: [],
            priceParts: [],
            pricePartsNoMargin: [],
            discountGroups: {},
            discountGroupsNoMargin: {},
            title: '',
            description: '',
            attachments: [],
            valid: {},
            accessories: [],
            rollerShutter: null,
            timeLimit: null,
            timeLimitsStack: [],
            parameters: {
                weight: null,
            },
        };
        configuration.name = oldConfiguration.Name;
        configuration.price = oldConfiguration.Price;
        configuration.priceNoMargin = oldConfiguration.PriceNoMargin;
        configuration.priceAfterDiscounts =
            oldConfiguration.PriceAfterDiscounts || oldConfiguration.Price;
        configuration.quantity = oldConfiguration.Quantity;
        configuration.priceSegments = oldConfiguration.PriceSegments;
        configuration.priceSegmentsNoMargin = oldConfiguration.PriceSegmentsNoMargin;
        configuration.priceParts = oldConfiguration.PriceParts;
        configuration.pricePartsNoMargin = oldConfiguration.PricePartsNoMargin;
        configuration.discountGroups = oldConfiguration.DiscountGroups;
        configuration.discountGroupsNoMargin = oldConfiguration.DiscountGroupsNoMargin;
        configuration.title = oldConfiguration.Title;
        configuration.description = oldConfiguration.Description;
        configuration.attachments = oldConfiguration.Attachments;
        configuration.valid = oldConfiguration.valid;
        configuration.timeLimit = oldConfiguration.timeLimit;
        configuration.timeLimitsStack = oldConfiguration.timeLimitsStack;
        if (oldConfiguration.Accessories) {
            configuration.accessories = oldConfiguration.Accessories.map(
                accessory => new Accessory(accessory)
            );
        }

        configuration.rollerShutter = new RollerShutter(oldConfiguration.RollerShutter);
        configuration.parameters = {
            weight: oldConfiguration.Weight,
        };
        return configuration;
    }

    /**
     * Przeniesienie profili rolet do profili
     */
    private changesInVersion6(oldConfiguration, dataRequiredToUpdate) {
        const configuration = core.copy(oldConfiguration);
        if (configuration.rollerShutter && configuration.rollerShutter.profile) {
            configuration.rollerShutter.profile.id =
                dataRequiredToUpdate.rollerShutterProfilesMap[
                    configuration.rollerShutter.profile.id
                ];
        }
        return configuration;
    }
}
