import { WindowInCoupling } from 'configurations/parts/coupled_window';
import { core } from 'helpers';
import Common from 'Common';
import { DrawService } from 'configurators/draw.service';

export default class JoinPositionModalController {
    resolve: { windows: WindowInCoupling[] };
    close: ({ $value: any }) => any;
    dismiss: () => any;

    drawOptions = Common.merge({}, this.drawService.options, {
        indexes: { multiplier: 3 },
    });

    constructor(private drawService: DrawService) {}

    indexLetter(index: number) {
        return core.indexToLetter(index);
    }

    selectWindow(window: WindowInCoupling) {
        this.close({ $value: window });
    }

    closeModal() {
        this.close({ $value: null });
    }
}
JoinPositionModalController.$inject = ['DrawService'];
