import angular from 'angular';
import moment from 'moment';

export default function ComplaintFactory($rootScope, $http, $routeParams, $filter, EnvConfig, $uibModal) {
    'ngInject';


    const factory = {
        getData,
        saveComplaint,
        addFile,
        removeFile,
        addComment,
        commentEmails,
        lackOfCheckboxes,
        lackOfDefects,
    };

    return factory;

    /**
     * Pobieranie danych
     * @param {string} callback   Callback
     */
    function getData(callback) {
        const tmpId    = $routeParams.id;
        const checksum = $routeParams.id2;
        var url;
        if (angular.isDefined(tmpId) && angular.isDefined(checksum)) {
            url = (EnvConfig.remoteHost || window.location.origin) + '/landing/landing/add_complaiment/' + tmpId + '/' + checksum + '.json';
        } else if (angular.isDefined(tmpId)) {
            url = (EnvConfig.remoteHost || window.location.origin) + '/landing/landing/add_complaiment/' + tmpId + '.json';
        } else {
            url = (EnvConfig.remoteHost || window.location.origin) + '/landing/landing/add_complaiment.json';
        }

        $http
            .get(url)
            .then((res) => {
                callback(res.data);
            });
    }

    /**
     * Zapis zgłoszenia reklamacyjnego
     * @param {object} complaint     Zgłoszenie reklamacyjne
     * @param {object} attachments   Załączniki
     */
    function saveComplaint(complaint, attachments, changeStatus, afterEdit) {
        var url;
        const tmpId    = $routeParams.id;
        const checksum = $routeParams.id2;
        if (angular.isDefined(tmpId) && angular.isDefined(checksum)) {
            url = `${EnvConfig.remoteHost || window.location.origin}/landing/landing/add_complaiment/${tmpId}/${checksum}.json`;
        } else if (angular.isDefined(tmpId)) {
            url = `${EnvConfig.remoteHost || window.location.origin}/landing/landing/add_complaiment/${tmpId}.json`;
        } else {
            url = `${EnvConfig.remoteHost || window.location.origin}/landing/landing/add_complaiment.json`;
        }
        complaint.newStatus = changeStatus;
        complaint.afterEdit = afterEdit;
        complaint.deadline = complaint.deadline !== null ? moment(complaint.deadline).format('YYYY-MM-DD') : null;
        complaint.attachments = [];
        for (let i = 0; i < attachments.length; i++) {
            complaint.attachments.push(attachments[i].id);
        }
        $http
            .post(url, {data: complaint})
            .then((res) => {
                if (res.data.success) {
                    $rootScope.showInfo($filter('translate')('QR|Zgłoszenie zostało poprawnie wysłane.'));
                    if (angular.isDefined(tmpId) && angular.isDefined(checksum)) {
                        window.location.replace('/app/landing/' + tmpId + '/' + checksum);
                    } else if ($rootScope.user.access == 'Super Admin'
                        || $rootScope.user.access == 'Administratorzy'
                        || $rootScope.user.access == 'market_leader'
                        || $rootScope.user.access == 'complaint_admin'
                    ) {
                        window.location.replace('/admin/complaint/complaints/');
                    } else {
                        window.location.replace('/app/complaints/');
                    }
                } else {
                    if (res.data.message) {
                        $rootScope.showInfo(res.data.message);
                    } else {
                        $rootScope.showInfo($filter('translate')('QR|Wystąpił błąd podczas zapisu, popraw formularz i spróbuj ponownie.'));
                    }
                    if (res.data.reload) {
                        setTimeout(() =>location.reload(), 3000);
                    }
                }
            });
    }

    /**
     * Dodawanie załączników
     * @param {File}     files      Pliki
     * @param {function} callback   Callback
     */
    function addFile(files, callback) {
        var formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('data[files][' + i + ']', files[i]);
        }
        $http
            .post(`${EnvConfig.remoteHost || window.location.origin}/landing/landing/add_attachment.json`, formData, {
                transformRequest: angular.identity,
                headers         : {'Content-Type': undefined}
            })
            .then((res) => {
                callback(res.data);
            });
    }

    /**
     * Usuwanie załączników
     * @param {File}     file       plik
     * @param {function} callback   Callback
     */
    function removeFile(file, callback) {
        $http
            .post(`${EnvConfig.remoteHost || window.location.origin}/landing/landing/remove_attachment`, {id: file.id})
            .then(() => {
                callback();
            });
    }

    /**
     * Dodawanie komentarza
     * @param {string}   comment    Komentarz
     * @param {function} callback   Callback
     */
    function addComment(comment, complaintId, emails, callback) {
        $http
            .post(`${EnvConfig.remoteHost || window.location.origin}/landing/landing/add_comment.json`, {complaint_id: complaintId, emails: emails, comment})
            .then((res) => {
                callback(res.data.comment);
                $rootScope.showInfo($filter('translate')('QR|Dodano komentarz.'));
            });
    }


    /**
     * Lista emaili na jakie zostało wysłane powiadomienie o dodaniu komentarza
     * @param {string}   comment    Komentarz
     * @param {function} callback   Callback
     */
    function commentEmails(comment) {
        $uibModal.open({
            templateUrl: 'modalComplaintCommentEmails.html',
            controller : 'ModalComplaintCommentEmailsCtrl as mcommentemails',
            resolve    : {comment}
        });
    }

    /**
     * Pokazanie informacji o braku dodanego typu.
     */
    function lackOfCheckboxes() {
        $rootScope.showInfo($filter('translate')('OR|Nie można wysłać formularza z powodu braku dodanego typu.'))
    }

    /**
     * Pokazanie informacji o braku dodanych usterek.
     */
    function lackOfDefects() {
        $rootScope.showInfo($filter('translate')('OR|Nie można wysłać formularza z powodu braku dodanych usterek.'))
    }
}
