import $ from 'jquery';

/**
 * Kontroler okna modal listwy przyszybowej
 * @param {object} $scope         Angular scope
 * @param {object} $uibModalInstance Okno modal
 * @param {object} $timeout       Timeout
 * @param {object} beads          Listwa
 * @param {object} selBead        Uszczelka
 */
export default function ModalGlazingBeadCtrl($scope, $filter, $uibModalInstance, $timeout, beads, selBead, InfoFactory, ProfilesService, EventBusService, ScrollbarService) {
    'ngInject';

    var vm = this;
    vm.glazingBeads = beads;
    vm.selBead = selBead;

    vm.refresh = refresh;
    vm.selectBead = selectBead;
    vm.close = close;
    vm.openInfoGlass = openInfoGlass;
    vm.profileShapes = ProfilesService.getProfileShapesNames();

    /**
     * Funkcja odświezajaca
     */
    function refresh() {
        ScrollbarService.update();
    }

    /**
     * Funkcja wybierajaca lstwe
     * @param  {object} bead Listwa
     */
    function selectBead(bead) {
        $uibModalInstance.close({glazingBead: bead});
    }

    /**
     * Funkcja zamykajaca okno modal
     */
    function close() {
        $uibModalInstance.close();
    }

    /**
     * Funkcja otwierajaca okno informacji dla szyb
     * @param  {object} glass Szyba
     */
    function openInfoGlass(glass) {
        const imageSrc = `/files/glazingbead/${glass.image}`;
        InfoFactory.openModal({template: 'imagePreview.html', title: $filter('translate')('INTERFACE|Podgląd'), data: {imageSrc}});
    }

}
