const WindowSillsTemplate = require<string>('ngtemplate-loader!./window-sills.component.html');
import WindowSillsController from './window-sills.controller';

export const WindowSillsComponent: ng.IComponentOptions = {
    bindings: {
        selectedSillId: '<',
        added: '&',
        singleSelect: '<',
    },
    templateUrl: WindowSillsTemplate,
    controller: WindowSillsController,
};
