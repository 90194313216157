import angular from 'angular';

export default function PostCodeCtrl($rootScope, $scope, $cookies, $window, IccConfig, PostCodeService, EventBusService, GtmService, SynchronizeService) {
    'ngInject';

    if ($rootScope.b2cShop == 1) {
        redirectToHome();
    }

    SynchronizeService.synchronizeFirst().then(() =>  localStorage.setItem('post_codes_synced' + USER_ID, true));

    var vm = this;
    vm.checkCode = checkCode;
    vm.setCookie = setCookie;
    vm.checkCookie = checkCookie;
    vm.checkLength = checkLength;
    vm.redirect = redirect;
    vm.postCodes = {};
    vm.validate = false;
    vm.city = '';
    vm.code = '';
    vm.country = '';
    vm.break = 1;
    vm.cookie;
    vm.shortestLength = 0;
    vm.activeButton = 0;
    vm.inputCode = 0;
    vm.id = '';

    getData();
    checkCookie();
    if (IccConfig.GTM.allowTags) {
        EventBusService.post({
            key: 'googleTags',
            value: 'postCodes'
        });
    }

    if (typeof ga !== 'undefined') {
        ga('send', 'pageview', '/kod-pocztowy-' + $rootScope.user.market.toLowerCase());
    }
    /**
    * Przekierowanie na podstawie cookie
    */
    function redirectToHome() {
        window.location.href = '//' + $rootScope.defaultUrl;
    }
    /**
     * Pobieranie danych, ustawianie warotsci startowych
     */
    function getData() {
        PostCodeService.getData().then(postCodes => {
            vm.postCodes = [];
            angular.forEach(postCodes.postCodes, (value) => {
                if (value.country_id == $rootScope.user.country) {
                    vm.postCodes.push(value);
                }
            });
            checkLength();
            var cookie = $cookies.get('postCodeVisited');
            if (cookie && cookie != '1') {
                vm.id = cookie.replace(/\_.*/, '');
            }
            checkCode();
        });

    }
    /**
     * Sprawdzanie kodu pocztowego
     */
    function checkCode() {
        angular.forEach(vm.postCodes, (value) => {
            if (vm.break) {
                var databaseCode = value.code.toUpperCase().replace(/[^A-Za-z0-9]/g, '');
                if (vm.id) {
                    vm.inputCode = vm.id.toUpperCase().replace(/[^A-Za-z0-9]/g, '');
                }
                if (vm.inputCode == databaseCode && value.country_id == $rootScope.user.country) {
                    vm.validate = true;
                    vm.city = value.city;
                    vm.code = value.code;
                    vm.country = value.country_id;
                    vm.break = 0;
                } else {
                    vm.validate = false;
                    vm.city = '';
                    vm.country = '';
                }
            }
        }); vm.break = 1;
        if (vm.inputCode.length < vm.shortestLength) {
            vm.activeButton = 0;
        } else {
            vm.activeButton = 1;
        }
    }

    /**
    * Ustawianie cookie
    */
    function setCookie() {
        if (vm.validate == true) {
            $cookies.put('postCodeVisited', vm.code + "_" + vm.country, ['domain']);
        } else {
            $cookies.put('postCodeVisited', 1, ['domain']);
        }
    }

    /**
     * Pobieranie cookie
     */
    function checkCookie() {
        vm.cookie = $cookies.get('postCodeVisited');
    }

    /**
     * Przekierowanie na podstawie formularza
     */
    function redirect() {
        if (vm.validate) {
            window.location.href = '/app/sync';
        } else {
            window.location.href = '//' + $rootScope.defaultUrl;
        }
    }

    /**
     * Sprawdzanie dlugosci najkrotszego kodu pocztowego
     */
    function checkLength() {
        var tempCode = 0;
        vm.shortestLength = vm.postCodes[0].code.length;

        angular.forEach(vm.postCodes, (value) => {
            if (value.code !== null) {
                tempCode = value.code.toUpperCase().replace(/[^A-Za-z0-9]/g, '');
                if (tempCode.length < vm.shortestLength) {
                    vm.shortestLength = tempCode.length;
                }
            }
        });

    }
}
