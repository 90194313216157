/**
 * Elementy wyceny
 *
 * @export
 * @class PriceElems
 */
export class PriceElems {
    /**
     * Elementy wyceny okien
     *
     * @static
     *
     * @memberOf PriceElems
     */
    static window = {
        sashes            : [],
        colors            : {},
        wood              : {},
        frame             : {},
        constructSupp     : 0,
        alushell          : [],
        dividers          : [],
        dividersCross     : [],
        sashFrames        : [],
        intMullions       : [],
        glassTypes        : [],
        panelTypes        : [],
        handles           : [],
        hinge             : {},
        lock              : {},
        handlesHeights    : [],
        bars              : [],
        fittings          : [],
        sashAccessories   : [],
        sideAccessories   : [],
        lowThreshold      : {},
        warmEdge          : [],
        complementaryGoods: {},
        accessories       : [],
        doorModel         : {},
        oversize          : {},
        sashTypes         : [],
        panelSizes        : [],
        shape             : [],
        renoFrame         : [],
        rollers           : {
            boxes         : [],
            boxSize       : {},
            changedSize   : {},
            console       : {},
            overload      : {},
            drive         : {},
            driveManual   : {},
            driveElems    : [],
            colors        : [],
            colorGroup    : [],
            concealedStrip: {},
            assembly      : 0
        },
        systemFactors: {
            system          : 0,
            colors          : 0,
            fitting         : 0,
            wood            : 0,
            multiSash       : 0,
            alushell        : 0,
            shape           : 0,
            steel           : 0,
            sealColor       : 0,
            siliconeColor   : 0,
            glazingBeadColor: 0,
            frame           : 0
        },
        mosquito: {
            size : [],
            color: []
        },
        factor: 0
    };

    /**
     * Elementy wyceny rolet
     *
     * @static
     *
     * @memberOf PriceElems
     */
    static roller_shutter = {
        accessories: [],
        rollers: {
            boxes         : [],
            boxSize       : {},
            changedSize   : {},
            console       : {},
            overload      : {},
            drive         : {},
            driveManual   : {},
            driveElems    : [],
            colors        : [],
            colorGroup    : [],
            concealedStrip: {},
            assembly      : 0
        },
        systemFactors: {
            system   : 0,
            colors   : 0,
            fitting  : 0,
            wood     : 0,
            multiSash: 0,
            alushell : 0
        },
        factor: 0
    };

    /**
     * Elementy wyceny markiz
     *
     * @static
     *
     * @memberOf PriceElems
     */
    static awning = {
        size       : {},
        line       : {},
        fabric     : {},
        valance    : {},
        mounting   : {},
        accessories: [],
        drive      : {options: []}
    };

    /**
     * Elementy wyceny bram garażowych
     *
     * @static
     *
     * @memberOf PriceElems
     */
    static garage = {
        size       : {},
        colors     : {},
        control    : {},
        accessories: [],
        factor     : 0
    };

    /**
     * Elementy wyceny siatek
     *
     * @static
     *
     * @memberOf PriceElems
     */
    static mosquito = {
        size       : {},
        color      : {},
        montage    : {},
        profile    : {},
        accessories: [],
        factor     : 0
    };

    /**
     * Elementy wyceny drzwi
     *
     * @static
     *
     * @memberOf PriceElems
     */
    static door = 'window';

    /**
     * Elementy wyceny drzwi
     *
     * @static
     *
     * @memberOf PriceElems
     */
    static folding_door = 'window';

    /**
     * Elementy wyceny HS
     *
     * @static
     *
     * @memberOf PriceElems
     */
    static hs = 'window';

    /**
     * Elementy wyceny akcesoriów
     *
     * @static
     *
     * @memberOf PriceElems
     */
    static accessory = {
        sashAccessories: [],
        accessories    : [],
        factor         : 1
    };

    /**
     * Elementy wyceny dóbr komplementarnych
     *
     * @static
     *
     * @memberOf PriceElems
     */
    static complementary_goods = {
        complementaryGoods: {
            windowsill: [],
            cassonetto: [],
            glass     : [],
            accessory : [],
            profile   : []
        },
        factor: 1
    };
}
