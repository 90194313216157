import {TimeLimit, TimeLimitSegment} from './time_limit_functions';

export default class TimeLimitRollerShutterSystemService {

    /**
     * Pobiera termin realizacji systemu rolety.
     *
     * @param {object} roller   Roleta
     * @return {number} Termin realizacji systemu rolety.
     */
    @TimeLimit({
        shortName: 'rollerShutterSystem',
        data: {
            rollerShutter : 'conf.RollerShutter',
        }
    })
    rollerShutterSystemTimeLimit({timeLimits, rollerShutter}): TimeLimitSegment[] {
        if (rollerShutter == null || !rollerShutter.system || !rollerShutter.system.time_limit_id) {
            return null;
        }

        const timeLimitId = rollerShutter.system.time_limit_id;

        return <TimeLimitSegment[]>[{
            type: 'rollerShutterSystem',
            value: timeLimits[timeLimitId],
            data: {
                id  : rollerShutter.system.id,
                name: rollerShutter.system.name,
            }
        }];
    }
}
