import angular from 'angular';
import { logger } from 'helpers';
import { IssueLevel } from 'issues.service';

const colorsModalTemplate = require('ngtemplate-loader!../../complementary_goods/colors/modal.html');

/**
 * Fabryka wypełnień.
 *
 * @export
 * @param {object} $rootScope                Angular Root Scope
 * @param {object} $uibModal                 Dostarcza obsługę okien modalnych
 * @param {object} $location                 Lokalizacja
 * @param {object} $filter                   Filtry
 * @param {object} ConfigurationsService     Fabryka konfiguracji
 * @param {object} ConfiguratorsDataService  Fabryka danych konfiguracji
 * @param {object} CurConfService            Bieżaca konfiguracja
 * @param {object} Core                      Core
 * @param {object} IssuesService             Fabryka Issues
 * @param {object} PriceService              Liczenie ceny
 * @param {object} ParametersService         Fabryka parametrów
 * @param {object} PriceColorsService PriceColorsService
 * @param {object} StepFactory               Fabryka kroków
 * @param {object} UserService               Fabryka użytkownika
 * @param {object} IccConfig                 IccConfig
 * @return {object} Factory
 */
export default function GlazingFactory(
    $rootScope,
    $uibModal,
    $location,
    $filter,
    ConfigurationsService,
    FillingsService, // eslint-disable-line max-params
    ConfiguratorsDataService,
    CurConfService,
    Core,
    IssuesService,
    PriceService,
    ParametersService,
    PriceColorsService,
    MuntinsFactory,
    StepFactory,
    UserService,
    IccConfig,
    DrawService,
    EventBusService,
    GlazingSizesService,
    InfoFactory
) {
    'ngInject';

    const factory = {
        glazingRestricts: {},
        checkGlass,
        valid,
        openModalColors,
        openModalDecoPanelColors,
        selectColor,
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });

    EventBusService.subscribe('changedSashes', () => {
        valid();
    });

    return factory;

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        IssuesService.addValidateFunction(valid);
        factory.glazingRestricts = ConfiguratorsDataService.data.glazingRestrictions;
        if (CurConfService.conf != 'complementary_goods') {
            GlazingSizesService.count(
                ConfigurationsService.conf ? ConfigurationsService.conf.Current : null
            );
        }

        factory.loadedData = true;
    }

    /**
     * Funkcja sprawdzania szklenia
     * @param  {object} sash Szkydło
     * @return {bool}        Czy szklenie pasuje w danym skrzydle.
     */
    function checkGlass(glazing, width = 0, height = 0, area = 0) {
        let restrict, noWarrantyRestrict;
        let warranty = true;
        if (!angular.isArray(factory.glazingRestricts)) {
            logger.warn('Brak ograniczeń szklenia!');
            return { valid: true };
        }
        for (var i = 0; i < factory.glazingRestricts.length; i++) {
            var rest = factory.glazingRestricts[i];
            if (angular.isUndefined(glazing)) {
                continue;
            }

            if (
                parseInt(glazing.thinkness_glass) == parseInt(rest.thickness)
                && parseInt(glazing.frame_width) >= parseInt(rest.frame_width)
            ) {
                if (rest.no_warranty) {
                    noWarrantyRestrict = rest;
                } else {
                    restrict = rest;
                }
            }
        }
        if (angular.isObject(restrict) || angular.isObject(noWarrantyRestrict)) {
            let validGlazing = true,
                reason = '',
                type;
            const glazingWidth = width;
            const glazingHeight = height;
            const glazingArea = area;

            if (angular.isObject(restrict)) {
                let ratio = restrict.ratio.split(':');
                ratio = ratio[1] / ratio[0];
                if (parseFloat(glazingArea) > parseFloat(restrict.area)) {
                    validGlazing = false;
                    reason =
                        reason
                        + $filter('translate')(
                            'WINDOW|<br>Maksymalna powierzchnia tego szklenia wynosi: <b> {restrict_area}m<sup>2</sup></b>, a aktualna powierzchnia: <b> {glazing_area}m<sup>2</sup></b></li>',
                            { restrict_area: restrict.area, glazing_area: Core.round(glazingArea) }
                        );
                    type = 'max';
                }
                if (glazingWidth < restrict.length_min) {
                    validGlazing = false;
                    reason =
                        reason
                        + $filter('translate')(
                            'WINDOW|<br>Minimalna szerokość tego szklenia wynosi: <b> {restrict_length_min}mm</b>, a aktualna szerokość: <b> {glazing_width}mm</b></li>',
                            {
                                restrict_length_min: restrict.length_min,
                                glazing_width: Core.round(glazingWidth),
                            }
                        );
                    type = 'min';
                }
                if (glazingHeight < restrict.length_min) {
                    validGlazing = false;
                    reason =
                        reason
                        + $filter('translate')(
                            'WINDOW|<br>Minimalna wysokość tego szklenia wynosi: <b> {restrict_length_min}mm</b>, a aktualna wysokość: <b> {glazing_height}mm</b></li>',
                            {
                                restrict_length_min: restrict.length_min,
                                glazing_height: Core.round(glazingHeight),
                            }
                        );
                    type = 'min';
                }
                if (glazingWidth > restrict.length_max) {
                    validGlazing = false;
                    reason =
                        reason
                        + $filter('translate')(
                            'WINDOW|<br>Maksymalna szerokość tego szklenia wynosi: <b> {restrict_length_max}mm</b>, a aktualna szerokość: <b> {glazing_width}mm</b></li>',
                            {
                                restrict_length_max: restrict.length_max,
                                glazing_width: Core.round(glazingWidth),
                            }
                        );
                    type = 'max';
                }
                if (glazingHeight > restrict.length_max) {
                    validGlazing = false;
                    reason =
                        reason
                        + $filter('translate')(
                            'WINDOW|<br>Maksymalna wysokość tego szklenia wynosi: <b> {restrict_length_max}mm</b>, a aktualna wysokość: <b> {glazing_height}mm</b></li>',
                            {
                                restrict_length_max: restrict.length_max,
                                glazing_height: Core.round(glazingHeight),
                            }
                        );
                    type = 'max';
                }
                if (glazingWidth >= glazingHeight && glazingWidth / glazingHeight > ratio) {
                    validGlazing = false;
                    reason =
                        reason
                        + $filter('translate')(
                            'WINDOW|<br>Maksymalna proporcja boków tego szklenia wynosi: <b> {restrict_ratio}</b>, a aktualne proporcje: <b> {glazing_ratio}</b></li>',
                            {
                                restrict_ratio: restrict.ratio,
                                glazing_ratio: '1:' + Core.round(glazingWidth / glazingHeight),
                            }
                        );
                    type = 'ratio';
                } else if (glazingHeight / glazingWidth > ratio) {
                    validGlazing = false;
                    reason =
                        reason
                        + $filter('translate')(
                            'WINDOW|<br>Maksymalna proporcja boków tego szklenia wynosi: <b> {restrict_ratio}</b>, a aktualne proporcje: <b> {glazing_ratio}</b></li>',
                            {
                                restrict_ratio: restrict.ratio,
                                glazing_ratio: '1:' + Core.round(glazingHeight / glazingWidth),
                            }
                        );
                    type = 'ratio';
                }
            }

            if (
                IccConfig.Configurators.noWarrantyGlazing
                && (!validGlazing || (validGlazing && !angular.isObject(restrict)))
                && noWarrantyRestrict
            ) {
                let noWarrantyRestrictRatio = noWarrantyRestrict.ratio.split(':');
                noWarrantyRestrictRatio = noWarrantyRestrictRatio[1] / noWarrantyRestrictRatio[0];
                if (
                    parseFloat(glazingArea) < parseFloat(noWarrantyRestrict.area)
                    && glazingWidth > noWarrantyRestrict.length_min
                    && glazingHeight > noWarrantyRestrict.length_min
                    && glazingWidth < noWarrantyRestrict.length_max
                    && glazingHeight < noWarrantyRestrict.length_max
                    && ((glazingWidth >= glazingHeight
                        && glazingWidth / glazingHeight < noWarrantyRestrictRatio)
                        || glazingHeight / glazingWidth < noWarrantyRestrictRatio)
                ) {
                    validGlazing = true;
                    reason = '';
                    type = null;
                    warranty = false;
                    InfoFactory.showInfo(
                        $filter('translate')(
                            'OFFER|BRAK GWARANCJI: jeden lub więcej pakietów szybowych w konstrukcji przekracza wymiary - na pakiety szybowe nie można udzielić gwarancji.'
                        ),
                        null
                    );
                }
            }

            return { valid: validGlazing, reason, type, warranty };
        }
        return { valid: true, warranty };
    }

    function checkGlassInSash(sash) {
        const filling =
            ConfigurationsService.conf.Current.drawData
            && ConfigurationsService.conf.Current.drawData.filling
            && ConfigurationsService.conf.Current.drawData.filling.find(o => o.sashId === sash.id);
        const glazingWidth = filling ? filling.rect.width : 0;
        const glazingHeight = filling ? filling.rect.height : 0;
        const glazingArea = filling ? filling.rectArea : 0;
        return checkGlass(sash.glazing, glazingWidth, glazingHeight, glazingArea);
    }

    function getSashMessage(sash, check) {
        let message = '';

        const glazingArea = (sash.glazingSizes.height * sash.glazingSizes.width) / 1e6;
        let glazingRatio;
        if (sash.glazingSizes.height >= sash.glazingSizes.width) {
            glazingRatio = sash.glazingSizes.height / sash.glazingSizes.width;
        } else {
            glazingRatio = sash.glazingSizes.width / sash.glazingSizes.height;
        }

        const restriction = factory.glazingRestricts.find(rest => {
            let ratio = rest.ratio.split(':');
            ratio = ratio[1] / ratio[0];

            return (
                rest.area > glazingArea
                && rest.length_max > sash.glazingSizes.width
                && rest.length_max > sash.glazingSizes.height
                && rest.length_min < sash.glazingSizes.width
                && rest.length_min < sash.glazingSizes.height
                && glazingRatio < ratio
            );
        });
        let proposedGlazing;
        if (restriction && restriction.frame_width) {
            proposedGlazing = FillingsService.fillings.find(glazing => {
                if (
                    parseFloat(glazing.thinkness_glass) >= parseFloat(restriction.thickness)
                    && parseFloat(glazing.frame_width) >= parseFloat(restriction.frame_width)
                ) {
                    return glazing;
                }
            });
        }
        if (restriction) {
            message =
                $filter('translate')(
                    'WINDOW|<br>Wybierz pakiet szybowy gdzie:<br><li>Najcieńsza szyba w pakiecie ma grubość: <b>{restriction_thickness}mm</b></li><li>Minimalna szerokość ramki międzyszybowej wynosi:{restriction_frame_width}mm',
                    {
                        restriction_thickness: restriction.thickness,
                        restriction_frame_width: restriction.frame_width,
                    }
                ) + '</li>';
            if (proposedGlazing && proposedGlazing.code) {
                message = `${message} <li>${$filter('translate')(
                    'WINDOW|Przykładowo szklenie:'
                )}<b> ${proposedGlazing.code} mm</b></li>`;
            }
        }

        if (check.type == 'max' || check.type == 'ratio') {
            if (message) {
                message = `${message} <br> ${$filter('translate')(
                    'WINDOW|lub podziel kwatery na mniejsze'
                )} `;
            } else {
                message = `${message} <br> ${$filter('translate')(
                    'WINDOW|Podziel kwatery na mniejsze'
                )}`;
            }
        }

        if (check.type == 'min' && message == '') {
            message = `${$filter('translate')('WINDOW|Nie można wykonać tak małego szklenia')}`;
        }

        const parentSash = ConfigurationsService.conf.Current.Sashes.find(
            e => e.id === sash.parentId
        );
        const sashIndex = parentSash
            ? `${parentSash.index}${sash.index ? '.' + sash.index : ''}`
            : `${sash.index}`;
        message =
            $filter('translate')(
                'WINDOW|Szklenie <b>{glazing_code}</b> w kwaterze: <b>{sash_index}</b> nie spełnia następujących warunków:',
                { glazing_code: sash.glazing.code, sash_index: sashIndex }
            )
            + check.reason
            + message;

        return { message, hasSashIndex: sashIndex != null };
    }

    /**
     * Funkcja walidujaca. Sprawdza czy wypełnienia w konfiguracji są poprawne.
     * @return {bool} Czy wypełnienia w konfiguracji są poprawne.
     */
    function valid() {
        if (
            !['window', 'hs', 'door', 'folding_door'].includes(
                ConfigurationsService.conf.Current.type
            )
        ) {
            IssuesService.unregister('incorrect-filling', ConfigurationsService.conf.Current);
            return true;
        }

        const messages = [];
        let warranty = true;

        ConfigurationsService.conf.Current.Sashes.reduce(
            (sashes, sash) => sashes.concat([sash], sash.intSashes),
            []
        )
            .filter(sash => !sash.intSashes || !sash.intSashes.length)
            .map(sash => {
                const check = checkGlassInSash(sash);
                warranty = warranty && check.warranty;
                if (!check.valid) {
                    const { message, hasSashIndex } = getSashMessage(sash, check);
                    if (hasSashIndex) {
                        messages.push(message);
                    }
                }
            });

        ConfigurationsService.conf.Current.GlazingWarranty = warranty;

        if (messages.length) {
            IssuesService.simpleRegister(
                'incorrect-filling',
                'Niepoprawne wypełnienie',
                messages.join('<br><br>'),
                ConfigurationsService.conf.Current,
                {
                    logLevel: IssueLevel.NONE,
                    extra: {
                        messages
                    }
                }
            );
            return false;
        } else {
            IssuesService.unregister('incorrect-filling', ConfigurationsService.conf.Current);
            return true;
        }
    }

    /**
     * Otwieranie modala wyboru  koloru panela wypełnieniowegi
     * @param  {Object} accessory Panel wypełnieniowy
     */
    function openModalColors(sash, novalidate) {
        const modalInstance = $uibModal.open({
            templateUrl: colorsModalTemplate,
            controller: 'ModalPvcColorsCtrl as $ctrl',
            resolve: { sash: () => sash, novalidate: () => novalidate },
        });

        modalInstance.result.then(selection => {
            sash.selectedColor = Core.copy(selection.colors);
            sash.selectedWood = Core.copy(selection.wood);
            EventBusService.post({
                key: 'changedSashes',
                value: {},
            });
        });

        modalInstance.closed.then(() => {
            if (IccConfig.Configurators.tutorialAvailable) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });

        if (IccConfig.Configurators.tutorialAvailable) {
            EventBusService.post({
                key: 'tutorialSteps',
                value: 'pvcModal',
            });
        }
    }

    /**
     * Otwieranie modala wyboru  koloru panelu deco
     * @param  {Object} conf Przekazana konfiguracja
     * @param  {Object} sashId Id sasha
     * @param  {Object} dualColor Rodzaj wybieranego koloru - true kolor2, false kolor1 panelu
     * @param  {Object} novalidate Czy walidować kolor
     */
    function openModalDecoPanelColors(conf, sashId, dualColor, novalidate) {
        const field = dualColor ? 'selectedColorSecond' : 'selectedColor';
        const sash = conf.Sashes.reduce((sashes, s) => sashes.concat([s], s.intSashes), []).find(
            s => s.id === sashId
        );
        const modalInstance = $uibModal.open({
            templateUrl: colorsModalTemplate,
            controller: 'ModalDecoPanelColorsCtrl as $ctrl',
            resolve: {
                sash: () => sash.glazing,
                dualColor: () => dualColor,
                novalidate: () => novalidate,
            },
        });

        modalInstance.result.then(selection => {
            EventBusService.post({
                key: 'changedSashes',
                value: {},
            });
            sash.glazing[field] = Core.copy(selection.colors);
            if (typeof sash.parentId !== 'undefined') {
                conf.Sashes.find(s => s.id === sash.parentId).glazing[field] = Core.copy(
                    selection.colors
                );
            }
            sash.selectedWood = Core.copy(selection.wood);
            EventBusService.post({
                key: 'icc-redraw',
                value: 'frame',
            });
        });

        modalInstance.closed.then(() => {
            EventBusService.post({
                key: 'reloadColorsData',
                value: null,
            });
            if (IccConfig.Configurators.tutorialAvailable) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }

    /**
     * Otwieranie modala wyboru  kolorów wszystkich paneli wypełnieniowych
     * @param  {Object} accessory Panel wypełnieniowy
     */
    function selectColor(glazing, novalidate) {
        $uibModal
            .open({
                templateUrl: colorsModalTemplate,
                controller: 'ModalPvcColorsCtrl as $ctrl',
                resolve: { sash: () => glazing, novalidate: () => novalidate },
            })
            .result.then(selection => {
                ConfigurationsService.conf.Current.Sashes.forEach(sash => {
                    sash.glazing.selectedColor = Core.copy(selection.colors);
                    sash.glazing.selectedWood = Core.copy(selection.wood);
                    sash.intSashes.forEach(intSash => {
                        intSash.glazing.selectedColor = Core.copy(selection.colors);
                        intSash.glazing.selectedWood = Core.copy(selection.wood);
                    });
                });
                EventBusService.post({
                    key: 'changedSashes',
                    value: {},
                });
            });
    }
}
