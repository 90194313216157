import { core } from 'helpers';
import * as angular from 'angular';
import { Injectable } from '@angular/core';
import { ConfiguratorsDataService } from 'configurators/configurators-data.service';
import { EventBusService } from 'event-bus.service';

/**
 * Usługa mapowania kolorów.
 *
 * @export
 * @class ColorMappingService
 */
@Injectable()
export default class ColorMappingService {
    /**
     * Mapowanie kolorów
     *
     *
     * @memberOf ColorMappingService
     */
    colorMaps = [];

    /**
     * Czy załadowne dane.
     *
     *
     * @memberOf ColorMappingService
     */
    loadedData = false;

    /**
     * Creates an instance of ColorMappingService.
     *
     * @param {any} $rootScope
     * @param {any} configuratorsDataService
     *
     * @memberOf ColorMappingService
     */
    constructor(
        private configuratorsDataService: ConfiguratorsDataService,
        private eventBusService: EventBusService
    ) {
        'ngInject';

        if (configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('loadedConfiguratorsData', () =>
            this.init()
        );
    }

    /**
     * Inicjalizuje dane.
     *
     *
     * @memberOf ColorMappingService
     */
    init() {
        this.colorMaps = this.configuratorsDataService.data.colorMaps || [];
        this.loadedData = true;
    }

    /**
     * Zamienia typ na model Cake
     *
     * @param {string} type Typ
     * @returns {string} Model Cake
     *
     * @memberOf ColorMappingService
     */
    convertTypeToModel(type) {
        let model = 'Window.WindowColor';
        switch (type) {
            case 'window':
                model = 'Window.WindowColor';
                break;
            case 'ral':
                model = 'Window.WindowColorRal';
                break;
            case 'roller':
                model = 'RollerShutter.RollerShutterColor';
                break;
            case 'accessory':
                model = 'Window.WindowHandlesColor';
                break;
            default:
                model = 'Window.WindowColor';
        }
        return model;
    }

    /**
     * Zwraca kolory pasujące do przekazanego koloru.
     *
     * @param {any} sourceColorId   Id źródłowego koloru.
     * @param {any} sourceColorType Rodzaj źródłowego koloru.
     * @param {any} targetColorType Rodzaj zwracanego koloru.
     * @return {any[]} Kolory pasujące do przekazanego koloru.
     *
     * @memberOf ColorMappingService
     */
    getColors(sourceColorId, sourceColorType, targetColorType) {
        const sourceColorModel = this.convertTypeToModel(sourceColorType);
        const targetColorModel = this.convertTypeToModel(targetColorType);
        const matchedColorMaps = this.colorMaps.filter(
            c => ~~c.source_id === ~~sourceColorId && c.source_model === sourceColorModel
        );
        const defaultMapping = sourceColorType === targetColorType ? [~~sourceColorId] : [];
        if (matchedColorMaps.length > 0) {
            const mapping = core.parseJson(matchedColorMaps[0].target) || {};
            if (mapping && angular.isArray(mapping[targetColorModel])) {
                return mapping[targetColorModel].map(Number).concat(defaultMapping);
            }
        }
        return defaultMapping;
    }

    /**
     * Zwrca id koloru okna.
     *
     * @param {any} conf Konfiguracja.
     * @param {string} [side='outer'] Strona okna.
     * @param {string} [field='id'] Nazwa pola.
     * @returns Wartość pola koloru.
     *
     * @memberOf ColorMappingService
     */
    getWindowColorId(conf, side: 'outer' | 'inner' = 'outer', field = 'id') {
        if (
            ['window', 'hs', 'door', 'folding_door'].indexOf(conf.type) === -1
            || !angular.isObject(conf.Colors)
            || !angular.isObject(conf.Colors.frame)
        ) {
            return null;
        }
        if (side === 'inner') {
            if (conf.Colors.frame.inner && conf.Colors.frame.inner[field] != null) {
                return ~~conf.Colors.frame.inner[field];
            } else if (conf.Colors.frame.core && conf.Colors.frame.core[field] != null) {
                return ~~conf.Colors.frame.core[field];
            }
        } else {
            if (conf.Colors.frame.alushell && conf.Colors.frame.alushell[field] != null) {
                return ~~conf.Colors.frame.alushell[field];
            } else if (conf.Colors.frame.outer && conf.Colors.frame.outer[field] != null) {
                return ~~conf.Colors.frame.outer[field];
            } else if (conf.Colors.frame.core && conf.Colors.frame.core[field] != null) {
                return ~~conf.Colors.frame.core[field];
            }
        }
        return null;
    }
}
