/**
 * Kontroler montażu siatek
 * @param {object} $scope                  Angular Scope
 * @param {object} $uibModal                  Dostarcza obsługę okien modalnych
 * @param {object} filterFilter            Filtry
 * @param {object} MosquitoMontagesFactory Fabryka montażu siatek
 * @param {object} CurConfService          Bieżaca konfiguracja
 */
export default function MontagesCtrl($scope, $uibModal, filterFilter, MosquitoMontagesFactory, CurConfService, EventBusService) { // jshint ignore:line
    'ngInject';

    var vm = this;

    vm.subStep                   = 1;
    vm.montages                  = [];
    vm.selectMontage             = selectMontage;
    vm.profilePositions          = MosquitoMontagesFactory.profilePositions;
    vm.setDefaultMountinPosition = MosquitoMontagesFactory.setDefaultMountinPosition;
    vm.setLine                   = MosquitoMontagesFactory.setLine;
    vm.selectProfile             = MosquitoMontagesFactory.selectProfile;
    vm.windowLines               = {};

    if (MosquitoMontagesFactory.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        vm.montages    = MosquitoMontagesFactory.montages;
        vm.windowLines = MosquitoMontagesFactory.windowLines;
    }

    /**
     * Funkcja wyboru montażu
     * @param  {object} montage Montaz
     */
    function selectMontage(montage) {
        MosquitoMontagesFactory.selectMontage(montage, true);
    }

}
