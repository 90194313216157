import angular from 'angular';
import $ from 'jquery';
import {logger} from 'helpers';

export default function MainCtrl($scope, $http, $location, $sce, $timeout, IccConfig, EnvConfig) {
    'ngInject';

    $scope.shutters        = true;
    $scope.hoverProject    = 0;
    $scope.selectedProject = IccConfig.Visualizer.selectProject;
    $scope.selectedElement = 0;
    $scope.selectedOption  = 0;
    $scope.optionGroup     = 0;
    $scope.configuration   = false;
    $scope.hoverOption     = -1;
    $scope.projectPicker   = true;
    $scope.roletaAnim      = 100;
    $scope.shutterCustomAnim = 0;
    $scope.showProjects    = false;

    $scope.getsvgdata = function(input, part) {
        var spl = input.split('|');
        if (part == 1) {
            return spl[0].substr(1, spl[0].length - 1);
        } else {
            return spl[1];
        }
    };

    $scope.roletaAnimateUp = function() {
        // alert('aa');
        if ($scope.shutters === true) {
            $scope.roletaAnim = $scope.roletaAnim - 2;
            // logger.log($scope.roletaAnim);
            if ($scope.roletaAnim > 0) {
                $timeout(function() {
                    $scope.roletaAnimateUp($scope.roletaAnim);
                }, 5);
            } else {
                $scope.shutters = false;
            }
        }
    };

    $scope.roletaCustomAnimateUp = function(rollersHeight) {
        if ($scope.shutters === true) {
            $scope.shutterCustomAnim -= 2;
            if ($scope.shutterCustomAnim * -1 < rollersHeight) {
                $timeout(function() {
                    $scope.roletaCustomAnimateUp(rollersHeight);
                }, 5);
            } else {
                $scope.shutters = false;
            }
        }
    };

    $scope.roletaCustomAnimateDown = function(rollersHeight) {

        if ($scope.shutters === false) {
            $scope.shutterCustomAnim += 2;
            if ($scope.shutterCustomAnim < 0) {
                $timeout(function() {
                    $scope.roletaCustomAnimateDown(rollersHeight);
                }, 5);
            } else {
                $scope.shutters = true;
                $scope.shutterCustomAnim = 0;
            }
        }
    };

    $scope.roletaAnimateDown = function() {
        // alert('aa');
        if ($scope.shutters === false) {
            $scope.roletaAnim = $scope.roletaAnim + 2;
            // logger.log($scope.roletaAnim);
            if ($scope.roletaAnim < 100) {
                $timeout(function() {
                    $scope.roletaAnimateDown();
                }, 5);
            } else {
                $scope.shutters = true;
            }
        }
    };

    $scope.shutterPolygonAnimateUp = function() {
        if ($scope.shutters === true) {

            var e = $('#roletaAnimMask');
            var GetInitCoordx = e.clientX;
            var GetInitCoordy = e.clientY;

            $scope.transform = 'translate(' + String(e.clientX - GetInitCoordx) + ',' + String(e.clientY - GetInitCoordy) + ')';
            $scope.roletaAnim = $scope.roletaAnim - 2;

            if ($scope.roletaAnim > 0) {
                $timeout(function() {
                    $scope.shutterPolygonAnimateUp();
                }, 5);
            } else {
                $scope.shutters = false;
            }
        }
    };
    $scope.shutterPolygonAnimateDown = function() {
        if ($scope.shutters === false) {

            var e = $('#roletaAnimMask');
            var GetInitCoordx = e.clientX;
            var GetInitCoordy = e.clientY;

            $scope.transform = 'translate(' + String(e.clientX - GetInitCoordx) + ',' + String(e.clientY - GetInitCoordy) + ')';
            $scope.roletaAnim = $scope.roletaAnim + 2;

            if ($scope.roletaAnim < 100) {
                $timeout(function() {
                    $scope.shutterPolygonAnimateDown();
                }, 5);
            } else {
                $scope.shutters = true;
            }
        }
    };

    $scope.defaultColors = IccConfig.Visualizer.defaultColors;
    $scope.colors = $scope.defaultColors[0];

    $scope.debug = function() {
        logger.log($scope);
    };

    $scope.setHoverOption = function(key) {
        $scope.hoverOption = key;
    };

    $scope.pagin_r = true;
    $scope.paginLeft = function() {
        $scope.pagin--;
        var total = ($scope.data.projects[$scope.selectedProject - 1].elements[$scope.selectedElement - 1].options[$scope.selectedOption - 1].groups[$scope.optionGroup].colors.length);
        if (total > 11) {
            $scope.pagin_r = true;
        }
    };

    $scope.paginRight = function() {
        $scope.pagin++;
        var total = ($scope.data.projects[$scope.selectedProject - 1].elements[$scope.selectedElement - 1].options[$scope.selectedOption - 1].groups[$scope.optionGroup].colors.length);
        if ($scope.pagin * 11 >= total) {
            $scope.pagin_r = false;
        }

        logger.log($scope.selectedElement);
    };

    $http.get((EnvConfig.remoteHost || window.location.origin) + '/' + IccConfig.Visualizer.name + '/visualizer.json').then(function(res) {
        var i, j, k;
        const data = res.data;
        // set current values from default
        for (i = 0; i < data.projects.length; i++) {
            for (j = 0; j < data.projects[i].elements.length; j++) {
                for (k = 0; k < data.projects[i].elements[j].options.length; k++) {
                    if (data.projects[i].elements[j].options[k].type == 'svg_png' || data.projects[i].elements[j].options[k].type == 'png') {
                        data.projects[i].elements[j].options[k].currentGroup = 0;
                        data.projects[i].elements[j].options[k].currentItem
                            = parseInt(data.projects[i].elements[j].options[k].groups[data.projects[i].elements[j].options[k].currentGroup].default);
                        // logger.log(data.projects[i].elements[j].options[k]);
                    }
                }
            }
        }

        for (i = 0; i < data.projects.length; i++) {
            for (j = 0; j < data.projects[i].elements.length; j++) {
                for (k = 0; k < data.projects[i].elements[j].options.length; k++) {
                    if (data.projects[i].elements[j].options[k].type == 'color_svg') {
                        var svgOpt = data.projects[i].elements[j].options[data.projects[i].elements[j].options[k].svgOption];
                        var colOpt = (svgOpt.groups[svgOpt.currentGroup].colors[svgOpt.currentItem].layers.split(','));
                        data.projects[i].elements[j].options[k].key = colOpt[0];
                    }
                }
            }
        }

        $scope.data = data;
        $scope.loaded = true;

        if (angular.isDefined($location.search().back)) {
            if (localStorage.getItem('visualizerData') && localStorage.getItem('visualizerProject')) {
                $scope.hoverProject(localStorage.getItem('visualizerProject'));
                $scope.selectProject(localStorage.getItem('visualizerProject'));
                $scope.colors = JSON.parse(localStorage.getItem('visualizerData'));
                $scope.configuration = true;
            } else {
                $scope.hoverProject(1);
                $scope.colors = $scope.defaultColors[0];
                $scope.configuration = false;
            }
        } else {
            $scope.hoverProject(1);
            $scope.colors = $scope.defaultColors[0];
            $scope.configuration = false;
        }
        $scope.selectProject(1);
    });
    $scope.projDD = false;
    $scope.switchShutters = function() {
        if ($scope.roletaAnim > 0) {
            $scope.roletaAnimateUp();
            $scope.shutterPolygonAnimateUp();
        } else {
            $scope.roletaAnimateDown();
            $scope.shutterPolygonAnimateDown();
        }
    };

    $scope.switchCustomShutters = function(rollersHeight) {
        if ($scope.shutters == true) {
            $scope.roletaCustomAnimateUp(rollersHeight);
        } else {
            $scope.roletaCustomAnimateDown(rollersHeight);
        }
    };

    $scope.setActiveProject = function(proj) {
        $scope.selectedOption = 0;
        $scope.hoverProject(proj);
        $scope.selectProject(proj);
        $scope.projDD = false;
    };

    $scope.plPos = -1;
    $scope.projNav = function(direction) {
        $scope.plPos = direction;
    };

    $scope.state = 'vis';
    $scope.hideLogin = function() {
        $scope.form_id = 1;
        $scope.state = 'vis';
    };

    $scope.showLogin = function(form_id) {
        $scope.state = 'form';
        $scope.form_id = form_id;
    };

    $scope.selectOption = function(id) {
        $scope.selectedOption = id;
        $scope.optionGroup = 0;
        $scope.pagin_r = true;
        $scope.pagin = 1;
    };
    $scope.resetPadding = function() {
        $scope.pagin_r = true;
        $scope.pagin = 1;
    };

    $scope.selectGroup = function(id) {
        $scope.optionGroup = id;
        $scope.pagin_r = true;
        $scope.pagin = 1;
    };

    $scope.projectPreview = function(index) {
        $scope.hoverProject = index;
        logger.log(index);
    };

    $scope.hoverProject = function(id) {
        $scope.selectedProject = id;
        $scope.proj = $scope.data.projects[id - 1];
    };

    $scope.backToSelect = function() {
        $scope.selectedOption = 0;
        $scope.state = 'vis';
        $scope.configuration = false;
    };

    $scope.selectProject = function(id) {
        $scope.selectedProject = id;
        $scope.proj = $scope.data.projects[id - 1];
        $scope.colors = $scope.defaultColors[id - 1] || $scope.defaultColors[0];
        $scope.configuration = true;
        $scope.hideElement();
    };

    $scope.size = 0;
    $scope.setSize = function(optionKey, size) {
        $scope.size = size;
        $scope.setPNG(optionKey, $scope.data.projects[$scope.selectedProject - 1].elements[$scope.selectedElement - 1].options[optionKey].currentGroup, $scope.data.projects[$scope.selectedProject - 1].elements[$scope.selectedElement - 1].options[optionKey].currentItem, size);
    };

    $scope.selectElement = function(id) {
        $scope.selectedElement = id;
        $scope.elem = $scope.proj.elements[id - 1];
        $scope.pagin = 1;
        $scope.pagin_r = true;
    };

    $scope.hideElement = function() {
        $scope.selectOption(0);
        $scope.selectElement(0);
        $scope.showProjects = false;
    };

    $scope.setColor = function(key, color) {
        $scope.colors[key] = color;
        $scope.colors.time = new Date().toJSON();
        localStorage.setItem('colors', JSON.stringify($scope.colors));
        $scope.updateVisData();
    };

    $scope.setSVGElement = function(optionKey, groupKey, colorKey) {
        let selectedDoorModel;
        $scope.data.projects[$scope.selectedProject - 1].elements[$scope.selectedElement - 1].options[optionKey].currentGroup = groupKey;
        $scope.data.projects[$scope.selectedProject - 1].elements[$scope.selectedElement - 1].options[optionKey].currentItem = colorKey;
        selectedDoorModel = $scope.data.projects[$scope.selectedProject - 1].elements[$scope.selectedElement - 1].options[optionKey].groups[0].colors[colorKey];

        localStorage.setItem('selectedDoorModel', JSON.stringify(selectedDoorModel));
    };

    $scope.pagin = 1;
    $scope.setPNG = function(optionKey, groupKey, colorKey, size) {
        let selectedHandleModel;
        $scope.size = size;
        $scope.data.projects[$scope.selectedProject - 1].elements[$scope.selectedElement - 1].options[optionKey].currentGroup = groupKey;
        $scope.data.projects[$scope.selectedProject - 1].elements[$scope.selectedElement - 1].options[optionKey].currentItem = colorKey;
        selectedHandleModel = $scope.data.projects[$scope.selectedProject - 1].elements[$scope.selectedElement - 1].options[optionKey].groups[0].colors[colorKey];
        selectedHandleModel.size = size;
        localStorage.setItem('selectedHandleModel', JSON.stringify(selectedHandleModel));
    };

    $scope.updateVisData = function() {
        localStorage.setItem('visualizerData', JSON.stringify($scope.colors));
        localStorage.setItem('visualizerProject', $scope.selectedProject);
    };

    $scope.showProjectsSwitch = function() {
        $scope.showProjects = true;
    };

}
