import { Configuration } from './Configuration';
import { PriceSegment, DiscountGroups, PricePart } from '../price/Prices';
import { System, Fabric, Valance, Drive, Mounting } from './parts/awning';
import { SimpleAccessory, Color } from './parts/common';

import AwningActiveConfiguration from './AwningActiveConfiguration';

export default class AwningConfiguration implements Configuration {

    public static is(configuration): configuration is AwningConfiguration {
        return configuration instanceof AwningConfiguration || configuration.$version;
    }
    $version = 5;
    type: 'awning' = 'awning';
    name = '';
    price: number | null = null;
    priceNoMargin: number | null = null;
    priceAfterDiscounts: number | null = null;
    quantity: number = 1;
    priceSegments: PriceSegment[] = [];
    priceSegmentsNoMargin: PriceSegment[] = [];
    priceParts: PricePart[] = [];
    pricePartsNoMargin: PricePart[] = [];
    discountGroups: DiscountGroups = {};
    discountGroupsNoMargin: DiscountGroups = {};
    title = '';
    description = '';
    attachments: any[] = [];
    valid: {} = {};

    system: System;
    outreach: number;
    width: number;
    color: Color;
    fabric: Fabric;
    valance: Valance;
    drive: Drive;
    mounting: Mounting;
    accessories: SimpleAccessory[];

    constructor(configuration) {
        if (AwningConfiguration.is(configuration)) {
            for (const propName in configuration) {
                if (this.hasOwnProperty(propName)) {
                    this[propName] = configuration[propName];
                }
            }
        } else {
            if (AwningActiveConfiguration.is(configuration)) {
                this.name = configuration.Name;
                this.price = configuration.Price;
                this.priceNoMargin = configuration.PriceNoMargin;
                this.priceAfterDiscounts = configuration.PriceAfterDiscounts || configuration.Price;
                this.quantity = configuration.Quantity;
                this.priceSegments = configuration.PriceSegments;
                this.priceSegmentsNoMargin = configuration.PriceSegmentsNoMargin;
                this.priceParts = configuration.PriceParts;
                this.pricePartsNoMargin = configuration.PricePartsNoMargin;
                this.discountGroups = configuration.DiscountGroups;
                this.discountGroupsNoMargin = configuration.DiscountGroupsNoMargin;
                this.title = configuration.Title;
                this.description = configuration.Description;
                this.attachments = configuration.Attachments;
                this.valid = configuration.valid;

                this.system = {
                    id: Number(configuration.System.id),
                    name: configuration.System.name,
                };
                this.outreach = Number(configuration.Outreach);
                this.width = Number(configuration.Width);
                this.color = {
                    id: Number(configuration.Colour.id),
                    name: configuration.Colour.name,
                    isDefault: false,
                };
                this.fabric = {
                    id: Number(configuration.Fabric.id),
                    name: configuration.Fabric.name,
                    style: {
                        id: Number(configuration.FabricStyle.id),
                        name: configuration.FabricStyle.title,
                    },
                };
                this.valance = {
                    id: Number(configuration.Valance.id),
                    name: configuration.Valance.name,
                    inscription: configuration.ValanceInscription,
                    rollUp: Boolean(configuration.RollUpValance),
                };
                this.drive = {
                    type: configuration.DriveType,
                    side: configuration.DriveSide === 'left' ? 'L' : 'R',
                    id:
                        configuration.DriveType === 'motor'
                            ? Number(configuration.Motor.id)
                            : Number(configuration.Crank.id),
                    name:
                        configuration.DriveType === 'motor'
                            ? configuration.Motor.name
                            : configuration.Crank.name,
                    options: configuration.MotorOptions.map(option => ({
                        id: Number(option.id),
                        name: option.name,
                    })),
                };
                this.mounting = {
                    id: Number(configuration.Mounting.id),
                    name: configuration.Mounting.name,
                    quantity: Number(configuration.MountingQuantity),
                };
                this.accessories = configuration.Addons.map<SimpleAccessory>(addon => ({
                    id: Number(addon.id),
                    name: addon.name,
                    manufacturerCode: addon.manufacturer_code,
                    priceSource: addon.price_model,
                    type: 'awningAccessory',
                }));
            }
        }
    }
}
