import angular from 'angular';

/**
 * Kontroler rozmiarów rolet
 * @param {object} $scope                  Angular Scope
 * @param {object} RollerDimensionsFactory Fabryka rozmiarów rolet
 * @param {object} ConfigurationsService   Fabryka konfiguracji
 */
export default function DimensionsCtrl(
    $filter,
    $scope,
    DrawService,
    RollerDimensionsFactory,
    ConfigurationsService,
    SchemasFactory,
    RollerColorsFactory,
    EventBusService,
    IccConfig
) {
    'ngInject';

    var vm = this;

    vm.letters = ['A', 'B', 'C'];
    vm.subStep = 1;
    vm.profiles = [];
    vm.roundReels = [];
    vm.boxSizes = [];
    vm.railCutAngles = [];
    vm.onBoxHeightChange = RollerDimensionsFactory.onBoxHeightChange;
    vm.updateDimensions = RollerDimensionsFactory.updateDimensions;
    vm.updateRailsBottomCut = RollerDimensionsFactory.updateRailsBottomCut;
    vm.changeProfile = changeProfile;
    vm.changeRoundReel = changeRoundReel;
    vm.changeSchema = SchemasFactory.setShuttersBySchema;
    vm.toggleMosquito = RollerDimensionsFactory.toggleMosquito;
    vm.montagesInfo = RollerDimensionsFactory.montagesInfo;
    vm.modalMontagesInfo = RollerDimensionsFactory.openModalMontagesInfo;
    vm.profile = null;
    vm.goToSubstep = goToSubstep;
    vm.guidesEnable = IccConfig.Configurators.roller_shutter.guides || false;
    vm.slatsEnable = IccConfig.Configurators.roller_shutter.slats || false;

    const shutters = ConfigurationsService.conf.Current.RollerShutter.shutters;

    vm.drawService = DrawService;
    vm.drawOptions = angular.merge({}, DrawService.options, {
        shutter: {
            buttons: [
                {
                    class: 'ico-minus common-icon',
                    condition: response => response.shutter.commonRail && response.shutter.id,
                    onClick: response => toggleCommonRail(response.shutter.id),
                    positions: ['left'],
                    title: $filter('translate')('INTERFACE|Ustaw osobne prowadnice'),
                },
                {
                    class: 'ico-plus common-icon',
                    condition: response => !response.shutter.commonRail && response.shutter.id,
                    onClick: response => toggleCommonRail(response.shutter.id),
                    positions: ['left'],
                    title: $filter('translate')('INTERFACE|Ustaw wspólną prowadnicę'),
                },
                {
                    class: 'ico-move-vertical',
                    condition: response => !response.shutter.commonRail,
                    onClick: response => toggleControlSide(response.shutter.id),
                    positions: ['top'],
                    title: $filter('translate')('INTERFACE|Zmień stronę sterowania'),
                },
                {
                    class: 'ico-left guide-icon',
                    condition: response =>
                        vm.guidesEnable && response.shutter.id === shutters[0].id,
                    onClick: response => guideSelection(response.shutter.id, 'left'),
                    positions: ['left'],
                    title: $filter('translate')('INTERFACE|Wybierz prowadnicę'),
                },
                {
                    class: 'ico-right guide-icon',
                    condition: response =>
                        vm.guidesEnable && response.shutter.id === shutters[shutters.length - 1].id,
                    onClick: response => guideSelection(response.shutter.id, 'right'),
                    positions: ['right'],
                    title: $filter('translate')('INTERFACE|Wybierz prowadnicę'),
                },
                {
                    class: 'ico-both',
                    condition: response =>
                        vm.guidesEnable && response.shutter.commonRail && response.shutter.id,
                    onClick: response =>
                        guideSelection(response.shutter.id, `common${response.shutter.id}`),
                    positions: ['left'],
                    title: $filter('translate')('INTERFACE|Wybierz prowadnicę'),
                },
                {
                    class: 'ico-baseline',
                    condition: response =>
                        vm.guidesEnable && !response.shutter.commonRail && response.shutter.id,
                    positions: ['left'],
                    title: $filter('translate')('INTERFACE|Wybierz prowadnicę'),
                    buttons: [
                        {
                            class: 'ico-right baseline-icon',
                            condition: response => vm.guidesEnable,
                            onClick: response =>
                                guideSelection(
                                    response.shutter.id,
                                    `middleRight${response.shutter.id}`
                                ),
                            positions: ['left'],
                            title: $filter('translate')('INTERFACE|Wybierz prowadnicę'),
                        },
                        {
                            class: 'ico-left baseline-icon',
                            condition: response => vm.guidesEnable,
                            onClick: response =>
                                guideSelection(
                                    response.shutter.id - 1,
                                    `middleLeft${response.shutter.id}`
                                ),
                            positions: ['left'],
                            title: $filter('translate')('INTERFACE|Wybierz prowadnicę'),
                        },
                    ],
                },
                {
                    class: 'ico-border-bottom',
                    condition: response => vm.slatsEnable,
                    onClick: response => slatSelection(response.shutter.id),
                    positions: ['bottom'],
                    title: $filter('translate')('INTERFACE|Wybierz listwę końcową'),
                },
            ],
        },
        extendedRollerInWindow: IccConfig.Configurators.extendedRollerInWindow,
        onInterfaceClick: () => {
            $scope.$digest();
        },
    });

    if (RollerDimensionsFactory.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(
        EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init())
    );
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        vm.profiles = RollerDimensionsFactory.profiles;
        vm.roundReels = RollerDimensionsFactory.roundReels;
        vm.boxSizes = RollerDimensionsFactory.boxSizes;
        vm.railCutAngles = RollerDimensionsFactory.railCutAngles;
        if (angular.isObject(ConfigurationsService.conf.Current.RollerShutter.profile)) {
            vm.profile = ConfigurationsService.conf.Current.RollerShutter.profile.id;
        } else {
            vm.profile = angular.isObject(RollerDimensionsFactory.profiles[0])
                ? RollerDimensionsFactory.profiles[0].id
                : null;
            RollerDimensionsFactory.changeProfile(vm.profile);
        }
        if (angular.isObject(ConfigurationsService.conf.Current.RollerShutter.roundReel)) {
            vm.roundReel = ConfigurationsService.conf.Current.RollerShutter.roundReel.id;
        } else {
            vm.roundReel = angular.isObject(RollerDimensionsFactory.roundReels[0])
                ? RollerDimensionsFactory.roundReels[0].id
                : null;
            RollerDimensionsFactory.changeRoundReel(vm.roundReel);
        }
        vm.updateDimensions();
    }

    /**
     * Funkcja ustavia profil
     */
    function changeProfile() {
        var profile = (vm.profiles || []).filter(function filterProfiles(e) {
            return e.id == vm.profile;
        });
        RollerDimensionsFactory.changeProfile(angular.isDefined(profile[0]) ? profile[0] : null);
        RollerColorsFactory.init();
    }

    /**
     * Funkcja ustawia rurę nawojową
     */
    function changeRoundReel() {
        var roundReel = (vm.roundReels || []).filter(e => e.id == vm.roundReel);
        RollerDimensionsFactory.changeRoundReel(
            angular.isDefined(roundReel[0]) ? roundReel[0] : null
        );
        vm.updateDimensions();
    }

    /**
     * Funkcja ustawiania prowadnicy
     * @param {object} shutter Roleta
     * @param {object} idx     Index
     */
    function toggleCommonRail(shutterId) {
        const index = ConfigurationsService.conf.Current.RollerShutter.shutters.findIndex(
            o => o.id === shutterId
        );
        const shutter = ConfigurationsService.conf.Current.RollerShutter.shutters[index];

        if (shutter.commonRail) {
            shutter.commonRail = false;
        } else {
            shutter.commonRail = true;

            ConfigurationsService.conf.Current.RollerShutter.shutters[
                index - 1
            ].realHeight = angular.copy(shutter.realHeight);
        }

        RollerDimensionsFactory.setGuideProfiles();
        RollerDimensionsFactory.setSlatProfiles();
        vm.updateDimensions();
    }

    function toggleControlSide(shutterId) {
        const shutter = ConfigurationsService.conf.Current.RollerShutter.shutters.find(
            o => o.id === shutterId
        );

        shutter.handling = shutter.handling === 'l' ? 'r' : 'l';

        vm.updateDimensions();
    }

    /**
     * Funkcja otwierajaca dialog wyboru prowadnic
     * @param {object} shutterId
     */
    function guideSelection(shutterId, side = null) {
        RollerDimensionsFactory.openModalGuideSelection(
            shutterId,
            side,
            ConfigurationsService.conf.Current
        );
    }

    /**
     * Funkcja otwierajaca dialog wyboru prowadnic
     * @param {object} shutterId
     */
    function slatSelection(shutterId) {
        RollerDimensionsFactory.openModalSlatSelection(
            shutterId,
            ConfigurationsService.conf.Current
        );
    }

    /**
     * Funkcja przechodzi do podkroku
     * @param  {string} subStep Kod kroku
     */
    function goToSubstep(subStep) {
        vm.subStep = subStep;
    }
}
