import { IssueLevel } from 'issues.service';

/**
 * Fabryka ograniczeń rozmiarów
 */
export default function ConstructionLimitationFactory($rootScope, $filter, CurConfService, ConfiguratorsDataService, IccConfig, EventBusService, IssuesService, ReinforcementsService) {
    'ngInject';

    var configurators = ['window', 'hs', 'door', 'folding_door'];
    var factory = {
        findReinforcement
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    $rootScope.$on('resetData', init);
    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });
    EventBusService.subscribe(['setBondedGlazingInSash', 'setMullionProfile', 'setSashProfile'], (data) => {
        findReinforcement(data.activeConfiguration);
    });

    return factory;

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        if (configurators.indexOf(CurConfService.conf) === -1 || IccConfig.Configurators.constructionLimitation === false) {
            return;
        }
        ReinforcementsService.init();
    }

    /**
     * Szuka czy wsrod ograniczen jest wzmocnienie
     * @param  {object} conf Konfiguracja
     */
    function findReinforcement(conf) {
        const {noConstructionLimitations, noMatchedConstructionLimitations} = ReinforcementsService.findReinforcement(conf);

        if (noConstructionLimitations && IccConfig.Configurators.noConstructionLimitationInfo) {
            IssuesService.simpleRegister(
                'no-construction-limitations',
                'UWAGA! Brak informacji o ograniczeniach wymiarowych dla wybranej konstrukcji',
                $filter('translate')('WINDOW|UWAGA! Brak informacji o ograniczeniach wymiarowych dla wybranej konstrukcji'),
                conf,
                {
                    blockAddToOffer: false,
                    noPrice        : false,
                    logLevel: IssueLevel.NONE
                }
            );
        } else {
            IssuesService.unregister('no-construction-limitations', conf);
        }

        if (noMatchedConstructionLimitations) {
            IssuesService.simpleRegister(
                'no-matched-construction-limitations',
                'UWAGA! Konstrukcja nie spełnia ograniczeń wymiarowych',
                $filter('translate')('WINDOW|UWAGA! Konstrukcja nie spełnia ograniczeń wymiarowych'),
                conf,
                {
                    logLevel: IssueLevel.NONE
                }
            );
        } else {
            IssuesService.unregister('no-matched-construction-limitations', conf);
        }
    }

}
