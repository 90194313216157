// JQuery
require('expose-loader?$!jquery');
require('expose-loader?jQuery!jquery');
require('../../../libs/jquery.override');
require('jquery-ui');
require('jquery-ui-touch-punch');
require('jquery.cookie');

// AngularJS
import 'angular';
import * as angularRoute from 'angular-route';
import * as angularResource from 'angular-resource';
import * as angularCookies from 'angular-cookies';
import * as angularSanitize from 'angular-sanitize';
import * as angularTouch from 'angular-touch';
import 'angular-bind-notifier';

// Angular
import '@angular/platform-browser';
import '@angular/platform-browser-dynamic';
import '@angular/core';
import '@angular/common';
import '@angular/http';
import '@angular/router';
import '@angular/upgrade/static';

// RxJS
import 'rxjs';

// AngularJS UI
import * as angularUiBootstrap from 'angular-ui-bootstrap';
import uiSelect from 'ui-select';

// AngularJS-translate MessageFormat
const dynamicLocale = require('angular-dynamic-locale');
import 'messageformat';
import 'imports-loader?MessageFormat=messageformat!../../../libs/locale/atc';
import 'imports-loader?MessageFormat=messageformat!../../../libs/locale/ces';
import 'imports-loader?MessageFormat=messageformat!../../../libs/locale/deu';
import 'imports-loader?MessageFormat=messageformat!../../../libs/locale/dec';
import 'imports-loader?MessageFormat=messageformat!../../../libs/locale/eng';
import 'imports-loader?MessageFormat=messageformat!../../../libs/locale/fra';
import 'imports-loader?MessageFormat=messageformat!../../../libs/locale/ita';
import 'imports-loader?MessageFormat=messageformat!../../../libs/locale/pol';
import 'imports-loader?MessageFormat=messageformat!../../../libs/locale/qps';
import 'imports-loader?MessageFormat=messageformat!../../../libs/locale/slk';
import 'imports-loader?MessageFormat=messageformat!../../../libs/locale/swe';
import 'imports-loader?MessageFormat=messageformat!../../../libs/locale/enc';
import 'imports-loader?MessageFormat=messageformat!../../../libs/locale/frc';
import 'imports-loader?MessageFormat=messageformat!../../../libs/locale/nld';
import 'imports-loader?MessageFormat=messageformat!../../../libs/locale/fla';
import '../../../libs/angular-translate';
import 'angular-translate-loader-static-files';
import 'angular-translate-interpolation-messageformat';
import 'angular-translate-handler-log';

import * as ngMap from '../../../libs/ng-map.js';
import 'angular-hotkeys';
import 'angular-carousel';
require('angular-hotkeys/build/hotkeys.min.css');
require('angular-carousel/dist/angular-carousel.min.css');
import 'angular-local-storage';
import '../../../libs/angular-inarray-filter';
import '../../../libs/angular-file-model';
import '../../../libs/svgDirs';
import '../../../libs/extra/flashMessage';
import 'angularjs-slider';
import ngInfiniteScroll from 'ng-infinite-scroll';
require('bootstrap');

import PerfectScrollbar from 'perfect-scrollbar';

const vendorModules = [
    angularRoute,
    'ngLocale',
    dynamicLocale,
    angularCookies,
    angularResource,
    angularSanitize,
    angularTouch,
    angularUiBootstrap,
    'angular.bind.notifier',
    'angular-carousel',
    'cfp.hotkeys',
    'filters-inArrayFilter',
    'flashMessage',
    'file-model',
    'pascalprecht.translate',
    ngMap,
    'ngSVGAttributes',
    'rzModule',
    uiSelect,
    ngInfiniteScroll,
];

export {vendorModules};
