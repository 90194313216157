import 'angular-ui-bootstrap';

export default class ModalOfferAddFormCtrl {

    constructor(
        private $filter,
        private $rootScope,
        private $uibModalInstance,

        private Core,
        private ConfigurationsService,
        private IssuesService,
        private LayoutFactory,
        private PositionsFactory,
        private PriceService,
        private StepFactory,

        private offerConf,
        private offer,
        private stepCode,
    ) {
        'ngInject';
    }

    addToOffer(config) {
        this.PriceService.count(config.currentConfig);

        if (!this.IssuesService.isBlockedAddToOffer(config.currentConfig)) {
            this.PositionsFactory.add({
                configuration  : config.currentConfig,
                details        : this.ConfigurationsService.createSimpleConfiguration(config.currentConfig),
                offer          : this.offer,
                image          : this.Core.svgToPngData($('.prepareVerticalPosition .smallWindowPreview')[0]),
                price          : config.currentConfig.Price,
                price_no_margin: config.currentConfig.PriceNoMargin,
                custom_title   : config.currentConfig.Title,
                description    : config.currentConfig.Description,
                standard       : !isNaN(config.currentConfig.Price) && config.currentConfig.Price !== null && this.Core.isEmpty(config.currentConfig.NoPriceElems)
                               ? true : false
            }).then(response => {
                this.$rootScope.showInfo(this.$filter('translate')('OFFER|Konstrukcja została dodana do oferty'), null, null);
                this.$uibModalInstance.close(true);
            });
        } else {
            this.$rootScope.showInfo(this.$filter('translate')('CONFIGURATOR|Niepoprawna konfiguracja'), null, null);
        }
    }

    changeSizes() {
        this.$rootScope.$broadcast('changedStep', {
            i   : 1,
            code: 'system'
        }, {
            i   : this.StepFactory.getStepByCode('dimensions'),
            code: 'dimensions'
        });

        this.LayoutFactory.selectLayoutBySizes();
    }

    close() {
        this.$uibModalInstance.close(false);
    }
}
