import { Injectable, Inject } from '@angular/core';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import { EventBusService } from 'event-bus.service';
import Common from 'Common';
import { logger } from 'helpers';
import { APP_CONFIG, AppConfig } from 'config';
import { IssuesService, IssueLevel } from 'issues.service';
import { TranslateService } from 'translate.service';

@Injectable()
export class BondedGlazingService {
    constructor(
        private eventBusService: EventBusService,
        @Inject(APP_CONFIG) private config: AppConfig,
        private issuesService: IssuesService,
        private translateService: TranslateService
    ) {
        this.eventBusService.subscribe(['setBondedGlazingInSash', 'changedSashes'], data => {
            try {
                this.checkSingleBondedGlazing(
                    <WindowActiveConfiguration>data.activeConfiguration,
                    <WindowActiveConfiguration>data.defaultConfiguration
                );
            } catch (err) {
                logger.error(err);
            }
        });
        this.eventBusService.subscribe(
            ['setBondedGlazingInSash', 'changedFitting', 'changedSashes'],
            data => {
                try {
                    this.valid(<WindowActiveConfiguration>data.activeConfiguration);
                } catch (err) {
                    logger.error(err);
                }
            }
        );
    }

    setBondedGlazingInAllSashes(conf: WindowActiveConfiguration, bondedGlazing: boolean) {
        const pId = this.eventBusService.pause(['setBondedGlazingInSash']);
        try {
            conf.Sashes.forEach(sash => {
                this.setBondedGlazingInSash(sash, sash, bondedGlazing);
                sash.intSashes.forEach(field => {
                    this.setBondedGlazingInSash(field, sash, bondedGlazing);
                });
            });
        } finally {
            this.eventBusService.resume(['setBondedGlazingInSash'], pId);
        }
    }

    setBondedGlazingInSash(field, sash, bondedGlazing: boolean) {
        field.bondedGlazing = bondedGlazing;
        if (Common.isDefined(field.parentId) && field.parentId !== null) {
            this.setBondedGlazingInSash(sash, sash, bondedGlazing);
        }
        this.eventBusService.post({
            key: 'setBondedGlazingInSash',
            value: {
                bondedGlazing: bondedGlazing,
                sashId: sash,
            },
        });
    }

    checkSingleBondedGlazing(conf: WindowActiveConfiguration, defaultConf: any) {
        let isSingleFilling = true;
        let isSingleFillingInWindowSashes = true;
        let isSingleFillingInDoorActiveSashes = true;
        let isSingleFillingInDoorPassiveSashes = true;
        let firstFillingId = null;
        let firstFillingInWindowSashesId = null;
        let firstFillingInDoorActiveSashesId = null;
        let firstFillingInDoorPassiveSashesId = null;

        conf.Sashes.forEach((sash, i) => {
            let isSingleFillingInSash = true;
            let firstFillingIdInSash = sash.bondedGlazing;

            isSingleFillingInSash = sash.intSashes.every((intSash, j) => {
                if (j === 0) {
                    firstFillingIdInSash = intSash.bondedGlazing;
                }
                return firstFillingIdInSash === intSash.bondedGlazing;
            });

            if (i === 0) {
                firstFillingId = firstFillingIdInSash;
            }

            if (sash.type.type === 'DRA' || sash.type.type === 'DOA') {
                if (!firstFillingInDoorActiveSashesId) {
                    firstFillingInDoorActiveSashesId = firstFillingIdInSash;
                } else {
                    isSingleFillingInDoorActiveSashes =
                        isSingleFillingInDoorActiveSashes
                        && isSingleFillingInSash
                        && firstFillingInDoorActiveSashesId === firstFillingIdInSash;
                }
            } else if (sash.type.type === 'DRP' || sash.type.type === 'DOP') {
                if (!firstFillingInDoorPassiveSashesId) {
                    firstFillingInDoorPassiveSashesId = firstFillingIdInSash;
                } else {
                    firstFillingInDoorPassiveSashesId =
                        firstFillingInDoorPassiveSashesId
                        && isSingleFillingInSash
                        && firstFillingInDoorPassiveSashesId === firstFillingIdInSash;
                }
            } else {
                if (!firstFillingInWindowSashesId) {
                    firstFillingInWindowSashesId = firstFillingIdInSash;
                } else {
                    isSingleFillingInWindowSashes =
                        isSingleFillingInWindowSashes
                        && isSingleFillingInSash
                        && firstFillingInWindowSashesId === firstFillingIdInSash;
                }
            }

            sash.oneBondedGlazing = isSingleFillingInSash;
            isSingleFilling =
                isSingleFilling && isSingleFillingInSash && firstFillingId === firstFillingIdInSash;
        });

        conf.OneBondedGlazing = isSingleFilling;
        defaultConf.OneBondedGlazing = isSingleFilling;
        conf.OneBondedGlazingPerSash = {
            window: false,
            doorActive: false,
            doorPassive: false,
        };
        if (isSingleFillingInWindowSashes) {
            conf.OneBondedGlazingPerSash.window = firstFillingInWindowSashesId;
        }
        if (isSingleFillingInDoorActiveSashes) {
            conf.OneBondedGlazingPerSash.doorActive = firstFillingInDoorActiveSashesId;
        }
        if (isSingleFillingInDoorPassiveSashes) {
            conf.OneBondedGlazingPerSash.doorPassive = firstFillingInDoorPassiveSashesId;
        }
    }

    valid(conf: WindowActiveConfiguration) {
        if (this.config.IccConfig.Configurators.bondedGlazingAvailable) {
            const reasons = [];
            let blockAddToOffer = false;
            for (let i = 0; i < conf.Sashes.length; i++) {
                const sash = conf.Sashes[i];
                if (
                    Common.isObject(conf.Fitting)
                    && conf.Fitting.required_bonded_glazing
                    && !sash.bondedGlazing
                ) {
                    reasons.push({
                        sashId: i + 1,
                        sashIndex: sash.index,
                        reason: this.translateService.instant('WINDOW|Wymagane wklejanie szyby'),
                        handle: 'INTERFACE|Brak',
                        fitting: conf.Fitting.name,
                    });
                    blockAddToOffer = true;
                }
            }

            if (reasons.length) {
                const reason =
                    reasons.reduce(
                        (prev, e) =>
                            prev
                            + `<li>${e.reason}<b>
                                ${this.translateService.instant('WINDOW|Okucie')}: ${e.fitting},
                                ${this.translateService.instant('WINDOW|Kwatera')}: ${e.sashIndex}
                                </b></li>`,
                        '<ul>'
                    ) + '</ul>';
                this.issuesService.simpleRegister('required-bonded-glazing', 'Wymagane wklejanie szyby', reason, conf, {
                    blockAddToOffer,
                    logLevel: IssueLevel.NONE,
                    extra: {
                        reasons
                    }
                });
                return false;
            } else {
                this.issuesService.unregister('required-bonded-glazing', conf);
                return true;
            }
        }
    }
}
