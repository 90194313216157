import {logger} from 'helpers';

let fullProgress = {
    speed: null,
    percent: {
        all_down: 0,
        all_zip: 0,
        diff_down: 0,
        diff_zip: 0
    }
};

let loggedError = false;

export default async function startFileSync(host: string, onceSynced, onProgress) {
    return fileSyncHandler(host, event, onceSynced, onProgress);
}

async function fileSyncHandler(host, event, onceSynced, onProgress) {
    fullProgress = {
        speed: null,
        percent: {
            all_down: 0,
            all_zip: 0,
            diff_down: 0,
            diff_zip: 0
        }
    };
    loggedError = false;
    setBasicAuth(host);
    if (!onceSynced) {
        await syncFilesAll(host, onProgress).catch(() => fileSyncHandler(host, event, onceSynced, onProgress));
    }
    await syncFilesDiff(host, onProgress, onceSynced).catch(() => fileSyncHandler(host, event, onceSynced, onProgress));
}

async function syncFilesDiff(host: string, onProgress, onceSynced) {
    return new Promise((resolve, reject) => {
        filesIndex().then((index) => {
            window.resolveLocalFileSystemURL('cdvfile://localhost/persistent/tmp.zip', (fileEntry) => {
                cordova.plugin.http.setDataSerializer('json');
                cordova.plugin.http.setRequestTimeout(90.0);
                let timeEntry = Date.now();
                cordova.plugin.http.downloadFilePost( host + '/api/sync/files/diff', index, {
                    lengthHeader: 'x-files-size'
                }, fileEntry.toURL(), function(response) {
                    if (response.loaded === response.total && response.loaded) {
                        timeEntry = Date.now();
                        window.zip.unzip(fileEntry.toURL(), 'cdvfile://localhost/persistent/files', (status) => {
                            if (status === 0) {
                                resolve();
                            }

                            if (status === -1) {
                                reject();
                            }
                        }, (progressEvent) => {
                            progressEvent.speed = response.loaded / (Date.now() - timeEntry) * 1000;
                            progressHandler(onProgress, 'diff', 'zip', progressEvent, onceSynced, err => {
                                reject(err);
                            });
                        });
                    } else {
                        response.speed = response.loaded / (Date.now() - timeEntry) * 1000;
                        progressHandler(onProgress, 'diff', 'down', response, onceSynced, err => {
                            reject(err);
                        });
                    }
                }, function(response) {
                    reject(response.error);
                });
            });
        })
        .catch(error => reject(error));
    });
}

async function syncFilesAll(host: string, onProgress) {
    return new Promise((resolve, reject) => {
        window.resolveLocalFileSystemURL('cdvfile://localhost/persistent/', (dir) => {
            dir.getFile('tmp.zip', {create: true}, fileEntry => {
                let timeEntry = Date.now();
                cordova.plugin.http.downloadFile(host + '/api/sync/files/all', {}, {}, fileEntry.toURL(), function(response) {
                    if (response.loaded === response.total && response.loaded) {
                        timeEntry = Date.now();
                        window.zip.unzip(fileEntry.toURL(), 'cdvfile://localhost/persistent/files', (status) => {
                            if (status === 0) {
                                resolve();
                            }

                            if (status === -1) {
                                reject();
                            }
                        }, (progressEvent) => {
                            progressEvent.speed = response.loaded / (Date.now() - timeEntry) * 1000;
                            progressHandler(onProgress, 'all', 'zip', progressEvent, false, err => {
                                reject(err);
                            });
                        });
                    } else {
                        response.speed = response.loaded / (Date.now() - timeEntry) * 1000;
                        progressHandler(onProgress, 'all', 'down', response, false, err => {
                            reject(err);
                        });
                    }
                }, function(response) {
                    reject(response.error);
                });
            }, function(err) {
                reject(err);
            });
        }, function(err) {
            reject(err);
        });

    });
}

async function filesIndex() {
    return new Promise((resolve, reject) => {
        window.resolveLocalFileSystemURL('cdvfile://localhost/persistent/files/index.json', (fileEntry) => {
            fileEntry.file((file) => {
                const reader = new FileReader();

                reader.onloadend = (e) => {
                    try {
                        resolve(JSON.parse(<string>reader.result));
                    } catch (err) {
                        reject(err);
                    }
                };
                reader.onerror = (err) => {
                    reject(err);
                };
                reader.readAsText(file);
            }, (err) => {
                reject(err);
            });
        }, function(err) {
            reject(err);
        });
    });
}

function progressHandler(onProgress, step, substep, state, onceSynced = false, onError = (err: Error) => {}) {
    let weight = 0.25;
    if (onceSynced) {
        weight *= 2;
    }
    fullProgress.percent[step + '_' + substep] = state.loaded / state.total;
    const percent = fullProgress.percent.all_down * weight
        + fullProgress.percent.all_zip * weight
        + fullProgress.percent.diff_down * weight
        + fullProgress.percent.diff_zip * weight;

    if (percent > 1 && !loggedError) {
        loggedError = true;
        logger.error(new Error('Błąd synchronizacji plików'));
        onError(new Error('Błąd synchronizacji plików'));
    }

    onProgress({
        speed: state.speed,
        percent: percent > 1 ? 1 : percent
    });
}

function setBasicAuth(host: string) {
    const userInfoRegex = /^(https?:\/\/)?(.*?):(.*?)@/;
    const founded = host.match(userInfoRegex);
    if (founded) {
        cordova.plugin.http.useBasicAuth(founded[2], founded[3]);
    }
}
