import moment from 'moment';

export default function OfferAttachmentsFactory($q, Core, $rootScope, DatabaseManager, machine, UserService, EventBusService, OffersService) {
    'ngInject';

    var factory = {
        add,
        addMulti,
        update,
        remove,
        list,
        get,
        getForOffer,
        multiCopy
    };

    return factory;

    /**
     * Funkcja załcznika do oferty
     * @param {object} params parametry
     */
    function OfferAttachment(params) {
        var tmpId = 'tmp_OfferAttachment_' + Core.generateUUID();
        var user = UserService.get();

        this.id              = params.id || tmpId;
        this.tmp_id           = params.tmp_id || tmpId;
        this.dealer_offer_id  = params.dealer_offer_id || params.offer.tmp_id;
        this.name             = params.name || params.attachment.name;
        this.data             = params.data || params.attachment;
        this.type             = params.type || params.attachment.type;
        this.deleted          = false;
        this.synced           = false;
        this.dealer_read_only = user.access == 'producent' ? true : false;
        this.internal         = params.internal || false;
        this.created          = params.created || moment().format('YYYY-MM-DD HH:mm:ss');
    }

    /**
     * Funkcja dodawania parametrów
     * @param {object} params parametry
     * @return {object}       Obietnica
     */
    function add(params, emit = true) {
        var deferred = $q.defer();

        var newAttachment  = new OfferAttachment(params);
        var EditAttachment = DatabaseManager.get('OfferAttachment');

        EditAttachment.create(newAttachment).then(function afterAddAttachment(res) {
            if (emit) {
                OffersService.emitModifiedOffer();
            }
            deferred.resolve(res);
        });
        return deferred.promise;
    }

    /**
     * Funckja ddawania wieu załczników
     * @param {object} attachments załczniki
     * @param {object} offer       oferta
     * @return {object}            Obietnica
     */
    function addMulti(attachments, offer, internal, emit = true) {
        var promises = attachments.map((att) => {
            return factory.add({
                attachment: att,
                offer,
                internal
            }, false);
        });
        return $q.all(promises).then((rows) => {
            if (emit) {
                OffersService.emitModifiedOffer();
            }
            return $q.resolve(rows);
        });
    }

    /**
     * Funkcja edycji obiektu
     * @param  {string}   _id      id
     * @param  {object}   object   obiekt
     * @param  {Function} callback funkcja
     * @return {object}            Obietnica
     */
    function update(_id, object, callback) {
        var deferred = $q.defer();
        DatabaseManager.get('OfferAttachment').update(object, {internalId: _id, machine}).then((res) => {
            OffersService.emitModifiedOffer();
            deferred.resolve(res, callback);
        });
        return deferred.promise;
    }

    /**
     * Funkcja usuwajca obiekt
     * @param  {string} _id     id
     * @param  {object} object  obiekt
     * @return {object}         Obietnica
     */
    function remove(_id, object) {
        var deferred = $q.defer();
        var EditAttachment = DatabaseManager.get('OfferAttachment');
        EditAttachment.remove(object).then(function afterAttachmentsRemove(res) {
            OffersService.emitModifiedOffer();
            deferred.resolve(res);
        });
        return deferred.promise;
    }

    /**
     * Funkcja listy załącznika
     * @return {object}     obietnica
     */
    function list() {
        var deferred = $q.defer();
        DatabaseManager.get('OfferAttachment').getAll().then((res) => {
            deferred.resolve(res);
        });
        return deferred.promise;
    }

    /**
     * Funckja pobieania zalacznika
     * @param  {string} _id     Id
     * @return {object}         Obietnica
     */
    function get(_id) {
        var deferred = $q.defer();
        var EditAttachment = DatabaseManager.get('OfferAttachment');
        EditAttachment.get(_id).then(function afterGetAttachments(res) {
            deferred.resolve(res);
        });
        return deferred.promise;
    }

    /**
     * Funkcja pobierania zalacznikow dla oferty
     * @param  {string} _id        Id
     * @param  {function} callback Funkcja po pobraniu
     * @param  {boolean}  getAtts  Czy pobierac zalaczniki
     * @return {object}            Obietnica
     */
    function getForOffer(_id, callback, getAtts) {
        var deferred = $q.defer();

        var EditAttachment = DatabaseManager.get('OfferAttachment');

        EditAttachment.getAll('by_dealer_offer_id', {key: _id, attachments: getAtts}).then(function successGetAttachments(res) {
            deferred.resolve(res);
        }, function errorGetAttachments(err) {
            deferred.reject(err);
        });

        return deferred.promise;
    }

    /**
     * Kopiuje wszystkie załczniki między ofertami
     * @param  {string} source      Id oferty zrodlowej
     * @param  {string} destination Id oferty docelowej
     * @return {object}             Angular Promise
     */
    function multiCopy(source, destination) {
        var deferred = $q.defer();
        getForOffer(source, undefined, true).then((res) => {
            addMulti(res.map((e) => {
                return Core.stringToBlob(e.doc._attachments[e.doc.name].data, e.doc.type, e.doc.name);
            }), {tmp_id: destination}, false, false).then(() => {
                OffersService.emitModifiedOffer();
                deferred.resolve();
            });
        });
        return deferred.promise;
    }

}
