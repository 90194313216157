import { NgModule } from '@angular/core';
import HandleHeightService from 'configurators/handle-height.service';
import { handlesFactoryProvider } from './ajs-upgraded-providers';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [HandleHeightService, handlesFactoryProvider],
})
export class HandlesModule {
    constructor() {}
}
