/*** IMPORTS FROM imports-loader ***/
var MessageFormat = require("messageformat");

MessageFormat.plurals.ces = function (n) {
  if (n == 1) {
    return 'one';
  }
  if (n == 2 || n == 3 || n == 4) {
    return 'few';
  }
  return 'other';
};

