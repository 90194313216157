import {TimeLimit, TimeLimitSegment} from './time_limit_functions';

export default class TimeLimitShapeService {

    /**
     * Pobiera termin realizacji kształtu.
     *
     * @param {object} shape   Kształt
     * @return {number} Termin realizacji kształtu.
     */
    @TimeLimit({
        shortName: 'shape',
        data: {
            shape : 'conf.Shape',
            timeLimitsShapes: 'data.timeLimitsShapes',
        }
    })
    shapeTimeLimit({timeLimits, shape, timeLimitsShapes}): TimeLimitSegment[] {
        if (shape == null || !shape.shape || !timeLimitsShapes || !timeLimitsShapes[shape.shape]) {
            return null;
        }

        const timeLimitId = timeLimitsShapes[shape.shape];

        return <TimeLimitSegment[]>[{
            type: 'shape',
            value: timeLimits[timeLimitId],
            data: {
                name: shape.shape,
            }
        }];
    }
}
