import angular from 'angular';
import angularSanitize from 'angular-sanitize';

import CheckIsLightColorFilter from './CheckIsLightColorFilter';
import SizeFilter from './SizeFilter';
import ProjectNumberPaddingFilter from './ProjectNumberPaddingFilter';
import MceilFilter from './MceilFilter';
import GetSvgDataFilter from './GetSvgDataFilter';
import MainCtrl from './MainCtrl';

export default angular.module('icc.visualizer', [angularSanitize])
    .filter('checkIsLightColor', CheckIsLightColorFilter)
    .filter('sizeFilter', SizeFilter)
    .filter('projectNumberPadding', ProjectNumberPaddingFilter)
    .filter('mceil', MceilFilter)
    .filter('getsvgdata', GetSvgDataFilter)
    .controller('MainCtrl', MainCtrl)
    .config(function ($translateProvider) {
        'ngInject';

        $translateProvider.useStaticFilesLoader({
            prefix: '/application/dist/locale/',
            suffix: '.project.json'
        });
        $translateProvider.useSanitizeValueStrategy(null);
        $translateProvider.useMessageFormatInterpolation();
        $translateProvider.preferredLanguage(LANG_CODE);
    })
    .name;

