import angular from 'angular';

/**
 * Kontroler okna modal akcesoriow
 * @param {object} $scope                Angular Scope
 * @param {object} $uibModalInstance        Okno modal
 * @param {object} $timeout              Timeout
 * @param {object} filterFilter          Filtry
 * @param {object} Core                  Core
 * @param {object} AccessoriesFactory    Fabryka akcesoriow
 * @param {object} ConfigurationsService Fabryka konfiguracji
 * @param {object} accessoriesFilter     Filtry akcesoriów
 * @param {object} accessories           Akcesoria
 * @param {object} categories            Kategorie
 * @param {object} subcategories         Subkategorie
 * @param {object} sash                  Skrzydło
 * @param {object} sashNum               Liczba skrzydeł
 * @param {object} forIt                 forIt
 * @param {object} colors                Kolory
 * @param {object} noPrice               Brak ceny
 */
export default function ModalAccessoriesCtrl($scope, $uibModalInstance, $timeout, $filter, filterFilter, Core,  AccessoriesFactory,
                            ConfigurationsService, accessoriesFilter, accessories, categories,
                            subcategories, sash, sashNum, forIt, colors, noPrice, PriceAccessoryService, InfoFactory, EventBusService, ScrollbarService) {
    'ngInject';

    var vm = this;
    var colorOptions = {
        price_white        : 'white',
        price_white_colour : 'color-white',
        price_colour_colour: 'color-color',
        price_ral          : 'ral'
    };
    colorOptions = colorOptions[PriceAccessoryService.getAccessoryPriceField(ConfigurationsService.conf.Current.Colors, ConfigurationsService.conf.Current.type)];

    vm.forIt           = forIt;
    vm.sash            = sash;
    vm.accessories     = accessories;
    vm.categories      = categories;
    vm.subcategories   = subcategories;
    vm.config          = ConfigurationsService.conf.Current;
    vm.sashNum         = sashNum;
    vm.colors          = colors;
    vm.add             = add;
    vm.refresh         = refresh;
    vm.close           = closeModal;
    vm.remove          = AccessoriesFactory.remove;
    vm.noPrice         = noPrice;
    vm.countUp         = AccessoriesFactory.countUp;
    vm.countDown       = AccessoriesFactory.countDown;
    vm.countPrice      = AccessoriesFactory.countPrice;
    vm.colorOptions    = colorOptions;
    vm.openModalColors = AccessoriesFactory.openModalColors;
    vm.openPreview     = openPreview;
    vm.isEmpty         = isEmpty;
    vm.hasDowelHole    = hasDowelHole;
    vm.selectedVentilatorType =  AccessoriesFactory.selectedVentilatorType(vm.sashNum);
    vm.updateAfterRemove = updateAfterRemove;
    vm.changeAccessoryColor = changeAccessoryColor;
    if (IccConfig.Configurators.ventilatorPositioning && vm.sash && vm.sash.frame && vm.sash.frame.top) {
        vm.topFrameProfile = vm.config.UsedProfiles.find(o => o.id === vm.sash.frame.top.profileId);
    }

    function updateAfterRemove () {
        vm.selectedVentilatorType =  AccessoriesFactory.selectedVentilatorType(vm.sashNum);
    }

    vm.accessories
    .filter(accessory => angular.isArray(accessory.colors_ids) && accessory.price_source === 'colors')
    .forEach(accessory =>
        accessory.selectedColor = vm.colors
        .find(color => accessory.colors_ids.some(id => id == color.id)).id
    );

    /**
     * Funkcja zamykajaca modal
     */
    function closeModal() {
        $uibModalInstance.close([vm.selection]);
    }

    /**
     * Sprawdza czy dany obiekt/tablica jest pusta lub ma wszystkie lementy puste
     * @param  {object}  o Sprawdzany obiekt
     * @return {Boolean}   true jezeli pusty
     */
    function isEmpty(o) {
        return Core.isEmpty(o);
    }

    /**
     * Funkcja sprawdzajaca dyble
     * @param  {object}  accessories Akcesoria
     */
    function hasDowelHole(access) {
        return access.some(function(el) {
            return el.accessory.type == 'dowel_hole';
        });
    }

    /**
     * Funkcja odświeżajaca
     */
    function refresh() {
        ScrollbarService.update();
        vm.selectedVentilatorType = AccessoriesFactory.selectedVentilatorType(vm.sashNum);
    }

    /**
     * Funkcja dodajaca
     * @param {object} accessory Akcesoria
     * @param {object} color     Kolor
     */
    function add(accessory, color) {
        AccessoriesFactory.add(sash, accessory, Core.fIdO(colors, color));
        refresh();
    }

    function changeAccessoryColor(access) {
        access.accessory.changedColor = true;
    }

    /**
     * Funkcja otwierajaca podgląd
     * @param  {object} accessory Klamka
     */
    function openPreview(access) {
        const imageSrc = access.colors_imgs[access.selectedColor] != '' && access.colors_imgs[access.selectedColor] != null
            ? '/files/windowhandlescolorswindowaccessory/' + access.colors_imgs[access.selectedColor]
            : '/files/windowaccessory/' + access.img;
        InfoFactory.openModal({template: 'imagePreview.html', title: $filter('translate')('INTERFACE|Podgląd'), data: {imageSrc}});
    }
}
