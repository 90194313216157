import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { HttpClientModule } from '@angular/common/http';
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
    HashLocationStrategy,
} from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import * as Raven from 'raven-js';

import { IccDrawModule } from '@icc/draw';

import { ConfiguratorModule } from 'configurator/configurator.module';
import { APP_CONFIG } from 'config';
import { CONFIG } from './config';
import { PanelModule } from 'panel/panel.module';
import { ConfiguratorPriceModule } from '../price/price.module';
import { SyncModule } from '../sync/sync.module';
import { PriceModule } from '../../../common/price/price.module';
import { ColorsModule } from '../../../common/colors/colors.module';
import { DependenciesModule } from '../dependencies/dependencies.module';
// import BrowserStateService from './state.service';
import { logger } from 'helpers';
import StateService from 'state.service';
import TokenService from '../authorization/token.service';
import AuthService from '../authorization/auth.service';
import BrowserConfiguratorsDataService from 'configurator/configurators-data.service';
import { ConfiguratorsDataService } from 'configurators/configurators-data.service';
import { BrowserTranslateService } from './translate.service';
import { TranslateService } from 'translate.service';
import { LicenseModule } from '../license/license.module';
import LangService from './lang.service';
import { AccessoriesModule } from '../configurator/steps/window/accessories/accessories.module';

export class RavenErrorHandler implements ErrorHandler {
    handleError(err: any): void {
        logger.error(err.originalError || err);
    }
}

@NgModule({
    imports: [
        BrowserModule,
        UpgradeModule,
        HttpClientModule,
        IccDrawModule,
        ConfiguratorModule,
        PriceModule,
        PanelModule,
        ConfiguratorPriceModule,
        SyncModule,
        ColorsModule,
        LicenseModule,
        DependenciesModule,
        AccessoriesModule,
    ],
    providers: [
        BrowserTranslateService,
        { provide: TranslateService, useExisting: BrowserTranslateService },
        StateService,
        TokenService,
        AuthService,
        LangService,
        { provide: APP_CONFIG, useValue: CONFIG },
        Location,
        {
            provide: LocationStrategy,
            useClass: CONFIG.isPhonegap ? HashLocationStrategy : PathLocationStrategy,
        },
        { provide: ErrorHandler, useClass: RavenErrorHandler },
        BrowserConfiguratorsDataService,
        { provide: ConfiguratorsDataService, useExisting: BrowserConfiguratorsDataService },
    ],
})
export class AppModule {
    ngDoBootstrap() {}
}
