import angular from 'angular';
import $ from 'jquery';
import {logger} from 'helpers';

export default function Base64Decode2Filter($window, $rootScope, $sce) {
    'ngInject';

    return function(data) {
        try {
            if (angular.isDefined(data)) {
                var str = $(decodeURIComponent(escape(window.atob(data))));
                str.attr('viewBox','0 0 300 300');
                str.find("rect[style*='fill:url']").each(function(el) {
                    var attr = $(this).attr('style');
                    attr = attr.replace(/https?:.*?#/, window.location.href + '#');
                    $(this).attr('style', attr);
                });
                str.find("line[style*='marker-start: url']").each(function(el) {
                    var attr = $(this).attr('style');
                    attr = attr.replace(/https?:.*?#/g, window.location.href + '#');
                    $(this).attr('style', attr);
                });
                return $sce.trustAsHtml(str[0].outerHTML);
            }
        } catch (err) {
            logger.error(err);
        }
    };
}
