import { IccColor, IccSideColors } from 'data-types';
import { Color, SideColors } from 'configurations/parts/common';
import { cpus } from 'os';

export function iccColorToColor(color: Partial<IccColor>): Color {
    return {
        id: Number(color.id),
        name: color.name,
        code: color.code,
        color: color.color,
        type: color.type,
        color_img: color.color_img,
        RAL: Boolean(color.RAL),
        isDefault: Boolean(color.isDefault),
        groups: color.groups.map(Number),
    };
}

export function iccSideColorsToSideColors(color: Partial<IccSideColors>): SideColors {
    return {
        outer: color.outer && color.outer.id ? iccColorToColor(color.outer) : null,
        inner: color.inner && color.inner.id ? iccColorToColor(color.inner) : null,
        core: color.core && color.core.id ? iccColorToColor(color.core) : null,
        alushell: color.alushell && color.alushell.id ? iccColorToColor(color.alushell) : null,
    };
}
