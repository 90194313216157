/**
 * Słownik dla szczegółow wyceny
 *
 * @export
 * @class PriceDictionary
 */
export default class PriceDictionary {
    /**
     * Słownik
     *
     *
     * @memberOf PriceDictionary
     */
    dictionary = {
        accessories: this.$filter('translate')('ACCESSORY|Akcesoria'),
        alignment: this.$filter('translate')('WINDOW|Wyrównanie'),
        all: this.$filter('translate')('INTERFACE|Wszystko'),
        alushell: this.$filter('translate')('WINDOW|Nakładka alum.'),
        alushellPercent: this.$filter('translate')('WINDOW|Nakładka alum. - dopł. procentowa'),
        amount: this.$filter('translate')('DIMENSION|Rozmiar'),
        angles: this.$filter('translate')('DIMENSION|Kąty'),
        area: this.$filter('translate')('DIMENSION|Pow.'),
        assembly: this.$filter('translate')('CONFIGURATOR|Montaż'),
        bars: this.$filter('translate')('WINDOW|Szprosy'),
        baseValue: this.$filter('translate')('INTERFACE|Wartość bazowa'),
        beadsPrice: this.$filter('translate')('WINDOW|Dopłata za listwy przyszybowe'),
        bottom: this.$filter('translate')('INTERFACE|Dół'),
        bottomSashesCount: this.$filter('translate')('CONFIGURATOR|Liczba kwater u dołu'),
        box: this.$filter('translate')('ROLLERSHUTTER|Skrzynka'),
        boxPrice: this.$filter('translate')('ROLLERSHUTTER|Cena skrzynki'),
        cassonetto: this.$filter('translate')('GOODS|Kasonetka'),
        categoryId: this.$filter('translate')('INTERFACE|Id kategorii'),
        cause: this.$filter('translate')('CONFIGURATOR|Powód'),
        changedHandlesHeights: this.$filter('translate')('WINDOW|Zmiany wysokości klamek'),
        handlePosition: this.$filter('translate')('WINDOW|Zmiany pozycji klamek'),
        changedSize: this.$filter('translate')('CONFIGURATOR|Zmiana rozmiaru'),
        charge: this.$filter('translate')('CONFIGURATOR|Dopłata'),
        chargeType: this.$filter('translate')('CONFIGURATOR|Rodzaj dopłaty'),
        code: this.$filter('translate')('WINDOW|Kod'),
        color: this.$filter('translate')('COLOR|Kolor'),
        colorAlushell: this.$filter('translate')('COLOR|Kolor nakładki alum.'),
        colorField: this.$filter('translate')('COLOR|Pole koloru'),
        colorGroup: this.$filter('translate')('COLOR|Grupa koloru'),
        colorName: this.$filter('translate')('COLOR|Nazwa koloru'),
        colorOut: this.$filter('translate')('COLOR|Kolor zewn.'),
        colors: this.$filter('translate')('COLOR|Kolory'),
        complementaryGoods: this.$filter('translate')('GOODS|Dobra komplementarne'),
        constructionLimitations: this.$filter('translate')('WINDOW|Ograniczenia konstrukcji'),
        constructSupp: this.$filter('translate')('WINDOW|Rabat za wiele skrzydeł'),
        control: this.$filter('translate')('GARAGE|Napęd/mechanizm otwierania garażu'),
        count: this.$filter('translate')('INTERFACE|Ilość'),
        data: this.$filter('translate')('INTERFACE|Dane'),
        discountGroup: this.$filter('translate')('OFFER|Grupa rabatów'),
        discountGroups: this.$filter('translate')('OFFER|Grupy rabatów'),
        dividers: this.$filter('translate')('WINDOW|Słupki/Poprzeczki'),
        dividersCross: this.$filter('translate')('WINDOW|Łączenia szczeblin'),
        couplings: this.$filter('translate')('WINDOW|Łączniki'),
        doorModel: this.$filter('translate')('DOOR|Model drzwi'),
        outerDoor: this.$filter('translate')('DOOR|Dopłata za drzwi otwierane na zewnątrz'),
        drive: this.$filter('translate')('ROLLERSHUTTER|Sterowanie'),
        driveElems: this.$filter('translate')('ACCESSORY|Akcesoria do sterowania'),
        driveManual: this.$filter('translate')('ROLLERSHUTTER|Sterowanie ręczne'),
        duplex: this.$filter('translate')('WINDOW|Dupleks'),
        fabric: this.$filter('translate')('AWNING|Materiał'),
        factor: this.$filter('translate')('WINDOW|Współczynnik'),
        factorArea: this.$filter('translate')('WINDOW|Współczynnik za pole'),
        fields: this.$filter('translate')('WINDOW|Pola'),
        filling: this.$filter('translate')('WINDOW|Wypełnienie'),
        fillingShape: this.$filter('translate')('WINDOW|Kształt wypełnienia'),
        fitting: this.$filter('translate')('WINDOW|Okucie bezp.'),
        fittings: this.$filter('translate')('WINDOW|Okucia bezpieczne'),
        fittingsPercent: this.$filter('translate')('WINDOW|Okucia bezpieczne (dopł. procentowa)'),
        frame: this.$filter('translate')('WINDOW|Rama'),
        glass: this.$filter('translate')('WINDOW|Szyba'),
        glassTypes: this.$filter('translate')('WINDOW|Szyby'),
        glazingCount: this.$filter('translate')('WINDOW|Liczba szyb'),
        glazingBeadColor: this.$filter('translate')('WINDOW|Kolor listwy przyszybowej'),
        glazingPrice: this.$filter('translate')('WINDOW|Cena za pakiet szybowy'),
        good: this.$filter('translate')('GOODS|Dobro'),
        handles: this.$filter('translate')('WINDOW|Klamki'),
        handlesHeights: this.$filter('translate')('WINDOW|Wysokości klamek'),
        height: this.$filter('translate')('DIMENSION|Wysokość'),
        hinge: this.$filter('translate')('DOOR|Zawias'),
        id: this.$filter('translate')('INTERFACE|ID'),
        idOuter: this.$filter('translate')('INTERFACE|ID zewn.'),
        index: this.$filter('translate')('INTERFACE|Indeks'),
        inscription: this.$filter('translate')('AWNING|Napis na falbanie'),
        intMullions: this.$filter('translate')(
            'WINDOW|Słupki/Poprzeczki/Szczebliny/Szprosy konstrukcyjne'
        ),
        isashId: this.$filter('translate')('WINDOW|Nr pola'),
        kicker: this.$filter('translate')('WINDOW|Kopacz'),
        left: this.$filter('translate')('INTERFACE|Lewy'),
        length: this.$filter('translate')('DIMENSION|Długość'),
        line: this.$filter('translate')('CONFIGURATOR|Linia'),
        lock: this.$filter('translate')('DOOR|Zamek'),
        lowThreshold: this.$filter('translate')('WINDOW|Niski próg'),
        margin: this.$filter('translate')('OFFER|Marża'),
        market: this.$filter('translate')('OFFER|Rynek'),
        marketMargin: this.$filter('translate')('OFFER|Marża rynku'),
        montage: this.$filter('translate')('CONFIGURATOR|Montaż'),
        mosquitoColor: this.$filter('translate')('MOSQUITO|Kolor siatki'),
        mosquitoMontage: this.$filter('translate')('MOSQUITO|Montaż siatki'),
        mosquitoProfile: this.$filter('translate')('MOSQUITO|Profil siatki'),
        mosquitoSize: this.$filter('translate')('MOSQUITO|Rozmiar siatki'),
        mounting: this.$filter('translate')('AWNING|Sposób montażu markizy'),
        multiSash: this.$filter('translate')('WINDOW|Konstr. wieloskrzydłowa'),
        muntins: this.$filter('translate')('WINDOW|Szprosy'),
        name: this.$filter('translate')('INTERFACE|Nazwa'),
        nameOuter: this.$filter('translate')('INTERFACE|Nazwa zewn.'),
        outerColorName: this.$filter('translate')('COLOR|Nazwa koloru zewn.'),
        outreach: this.$filter('translate')('AWNING|Wysięg'),
        overlay: this.$filter('translate')('WINDOW|Nakładka'),
        overload: this.$filter('translate')('ROLLERSHUTTER|Przeciążenie'),
        overRuCount: this.$filter('translate')('WINDOW|Liczba skrzydeł RU'),
        oversize: this.$filter('translate')('WINDOW|Ponadgabaryt'),
        panelPrice: this.$filter('translate')('WINDOW|Cena panelu'),
        panelSizes: this.$filter('translate')('WINDOW|Rozmiar paneli'),
        panelType: this.$filter('translate')('WINDOW|Rodzaj panelu'),
        panelTypes: this.$filter('translate')('WINDOW|Rodzaje panelu'),
        perimeter: this.$filter('translate')('INTERFACE|Obwód'),
        price: this.$filter('translate')('OFFER|Cena'),
        price_per_m: this.$filter('translate')('OFFER|Cena za mb'),
        price_per_q: this.$filter('translate')('OFFER|Cena za szt.'),
        priceAmount: this.$filter('translate')('OFFER|Cena jednostkowa'),
        priceId: this.$filter('translate')('CONFIGURATOR|Id cennika'),
        priceType: this.$filter('translate')('CONFIGURATOR|Typ ceny'),
        profile: this.$filter('translate')('CONFIGURATOR|Profil'),
        profile_id: this.$filter('translate')('CONFIGURATOR|Id profilu'),
        profiles: this.$filter('translate')('CONFIGURATOR|Profile'),
        quantity: this.$filter('translate')('INTERFACE|Liczba sztuk'),
        renoFrame: this.$filter('translate')('INTERFACE|Rama renowacyjna'),
        right: this.$filter('translate')('INTERFACE|Prawy'),
        rollerAssembly: this.$filter('translate')('ROLLERSHUTTER|Montaż rolety'),
        rollerBoxes: this.$filter('translate')('ROLLERSHUTTER|Rolety'),
        rollerBoxSize: this.$filter('translate')('ROLLERSHUTTER|Rozmiar skrzydnki rolety'),
        rollerShutterMosquito: this.$filter('translate')(
            'ROLLERSHUTTER|Dopłata za siatkę antyinsektową'
        ),
        rollerColor: this.$filter('translate')('ROLLERSHUTTER|Kolor rolety'),
        rollerColors: this.$filter('translate')('ROLLERSHUTTER|Kolory rolety'),
        rollerConcealedStrip: this.$filter('translate')(
            'ROLLERSHUTTER|Listwa podtynkowa zewnętrzna'
        ),
        rollerConsole: this.$filter('translate')('ROLLERSHUTTER|Konsola rolety'),
        rollerDrive: this.$filter('translate')('ROLLERSHUTTER|Sterowanie rolety'),
        rollerDriveElems: this.$filter('translate')(
            'ROLLERSHUTTER|Dodatki do sterowania elektrycznego'
        ),
        rollerDriveManual: this.$filter('translate')(
            'ROLLERSHUTTER|Dodatki do sterowania ręcznego'
        ),
        rollers: this.$filter('translate')('ROLLERSHUTTER|Rolety'),
        rollerSlatDivision: this.$filter('translate')('ROLLERSHUTTER|Dopłata za podział pancerza'),
        rollUpPrice: this.$filter('translate')('AWNING|Cena falbany zwijanej'),
        sashAccessories: this.$filter('translate')('ACCESSORY|Akcesoria do kwater'),
        sashes: this.$filter('translate')('WINDOW|Kwatery'),
        sashesHardware: this.$filter('translate')('ACCESSORY|Akcesoria do kwater'),
        sashFrames: this.$filter('translate')('WINDOW|Skrzydła'),
        sashId: this.$filter('translate')('WINDOW|Nr kwatery'),
        sashShape: this.$filter('translate')('WINDOW|Kształt kwatery'),
        sashType: this.$filter('translate')('WINDOW|Typ kwatery'),
        sealColor: this.$filter('translate')('WINDOW|Kolor uszczelki'),
        siliconeColor: this.$filter('translate')('WINDOW|Kolor silikonu'),
        shape: this.$filter('translate')('CONFIGURATOR|Kształt'),
        side: this.$filter('translate')('INTERFACE|Bok'),
        sideAccessories: this.$filter('translate')('ACCESSORY|Akcesoria do krawędzi'),
        size: this.$filter('translate')('DIMENSION|Rozmiar'),
        steel: this.$filter('translate')('WINDOW|Rodzaj stali'),
        sticked: this.$filter('translate')('WINDOW|Naklejane'),
        suppId: this.$filter('translate')('CONFIGURATOR|Id dopłaty'),
        system: this.$filter('translate')('CONFIGURATOR|System'),
        systemFactors: this.$filter('translate')('CONFIGURATOR|Dopłaty procentowe'),
        text_price: this.$filter('translate')('AWNING|Cena za tekst'),
        to: this.$filter('translate')('INTERFACE|Do'),
        top: this.$filter('translate')('INTERFACE|Góra'),
        type: this.$filter('translate')('INTERFACE|Rodzaj'),
        typeId: this.$filter('translate')('INTERFACE|Id rodzaju'),
        typeName: this.$filter('translate')('INTERFACE|Rodzaj'),
        typeCode: this.$filter('translate')('INTERFACE|Kod rodzaju'),
        types: this.$filter('translate')('INTERFACE|Rodzaje'),
        typesSymbol: this.$filter('translate')('INTERFACE|Rodzaje (symbole)'),
        u: this.$filter('translate')('INTERFACE|U'),
        valance: this.$filter('translate')('AWNING|Falbana'),
        value: this.$filter('translate')('INTERFACE|Wartość'),
        valueType: this.$filter('translate')('INTERFACE|Rodzaj wartości'),
        warmEdge: this.$filter('translate')('WINDOW|Ciepła ramka'),
        width: this.$filter('translate')('DIMENSION|Szerokość'),
        widthOuter: this.$filter('translate')('DIMENSION|Szerokość zewn.'),
        windowsill: this.$filter('translate')('ACCESSORY|Parapet'),
        wood: this.$filter('translate')('CONFIGURATOR|Drewno'),
        commodityMultiplier: this.$filter('translate')('CONFIGURATOR|Towary'),
        systemMultiplier: this.$filter('translate')('CONFIGURATOR|System'),
        supplementMultiplier: this.$filter('translate')('CONFIGURATOR|Dopłaty'),
        rollerMultiplier: this.$filter('translate')('CONFIGURATOR|Roleta'),
        sashDependencies: this.$filter('translate')('CONFIGURATOR|Zależności dla skrzydła'),
        dependencies: this.$filter('translate')('CONFIGURATOR|Zależności dla konstrukcji'),
    };

    /**
     * Creates an instance of PriceDictionary.
     *
     * @param {any} $filter $filter
     *
     * @memberOf PriceDictionary
     */
    constructor(private $filter) {
        'ngInject';
    }
}
