export default class RoundNumberDirective {
    constructor() {
        'ngInject';

        this.restrict = 'A';
        this.require  = 'ngModel';
        this.scope    = {roundNumber: '<'};
    }

    link(scope, element, attr, ngModel) {
        const factor = Math.pow(10, scope.roundNumber || 2);
        ngModel.$formatters.push((value) => Math.round(factor * (parseFloat(value) || 0)) / factor);
    }
}
