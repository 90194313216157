import { Injectable, Inject } from '@angular/core';
import EditPositionFactory from 'offer/EditPositionFactory';
import PositionsFactory from 'panel/PositionsFactory';
import { CoupledWindowService } from './coupled-window.service';

@Injectable()
export class EditingCoupledWindowService {
    constructor(
        @Inject('PositionsFactory') private positionsFactory: ReturnType<typeof PositionsFactory>,
        @Inject('EditPositionFactory')
        private editPositionFactory: ReturnType<typeof EditPositionFactory>,
        @Inject('$rootScope') private $rootScope,
        private coupledWindowService: CoupledWindowService
    ) {}

    async editPosition(positionId: string) {
        this.$rootScope.coupledPosition = true;
        this.coupledWindowService.setMatchingWindowSystemsForEditing(positionId);
        const position = await this.positionsFactory.get(positionId);
        this.editPositionFactory.editPosition({ doc: position });
    }
}
