import * as angular from 'angular';
import {downgradeInjectable} from '@angular/upgrade/static';

import WarrantyFactory from './WarrantyFactory';
import CurrencyInputDirective from './CurrencyInputDirective';
import CustomPricesCtrl from './CustomPricesCtrl';
import ModalPriceDetailsCtrl from './details-modal/ModalPriceDetailsCtrl';
import PriceService from './price.service';

export default angular.module('icc.price', [])
    .controller('CustomPricesCtrl', CustomPricesCtrl)
    .controller('ModalPriceDetailsCtrl', ModalPriceDetailsCtrl)
    .directive('currency', () => new CurrencyInputDirective())
    .factory('PriceService', downgradeInjectable(PriceService))
    .factory('WarrantyFactory', WarrantyFactory)
    .name;
