import { Injectable } from '@angular/core';

@Injectable()
export default class GlazingSizesService {
    constructor() {}

    count(conf) {
        if (!conf || !conf.Sashes || !conf.Sashes.length) {
            return;
        }

        let filling;

        conf.Sashes.map(sash => {
            filling = conf.drawData.filling.find(o => o.sashId === sash.id);

            sash.margins = {
                top: filling ? filling.rect.y - sash.ry : 0,
                left: filling ? filling.rect.x - sash.rx : 0,
                right: filling ? sash.rx + sash.rWidth - filling.rect.x - filling.rect.width : 0,
                bottom: filling ? sash.ry + sash.rHeight - filling.rect.y - filling.rect.height : 0,
            };

            sash.glazingSizes = {
                area: filling ? filling.rectArea : 0,
                x: filling ? filling.rect.x - sash.rx : 0,
                y: filling ? filling.rect.y - sash.ry : 0,
                width: filling ? filling.rect.width : sash.rWidth,
                height: filling ? filling.rect.height : sash.rHeight,
            };

            sash.intSashes.map(intSash => {
                filling = conf.drawData.filling.find(o => o.sashId === intSash.id);

                intSash.margins = {
                    top: filling ? filling.rect.y - intSash.ry : 0,
                    left: filling ? filling.rect.x - intSash.rx : 0,
                    right: filling
                        ? intSash.rx + intSash.rWidth - filling.rect.x - filling.rect.width
                        : 0,
                    bottom: filling
                        ? intSash.ry + intSash.rHeight - filling.rect.y - filling.rect.height
                        : 0,
                };

                intSash.glazingSizes = {
                    area: filling ? filling.rectArea : 0,
                    x: filling ? filling.rect.x - intSash.rx - sash.rx : 0,
                    y: filling ? filling.rect.y - intSash.ry - sash.ry : 0,
                    width: filling ? filling.rect.width : intSash.rWidth,
                    height: filling ? filling.rect.height : intSash.rHeight,
                };
            });
        });
    }
}
