import * as angular from 'angular';

import { MullionsLayoutService } from './mullions-layout.service';
import ElementsAccessoriesFactory from './ElementsAccessoriesFactory';
import ElementsFactory from './ElementsFactory';
import LayoutFactory from './LayoutFactory';
import { SashesLayoutService } from './sashes-layout.service';
import ShapeService from 'shape.service';
import { downgradeInjectable } from '@angular/upgrade/static';
import { MullionsService } from 'profiles/mullions.service';
import { AlignmentsService } from './alignments.service';
import { ExtensionsService } from './extensions.service';
import { ThresholdsService } from './thresholds.service';
import { RemoveSashService } from './remove-sash.service';
import { SashTypesService } from './sash-types.service';
import { SplitSashService } from './split-sash.service';
import { ResizeService } from './resize.service';
import { ResizeSashService } from './resize-sash.service';
import { BrowserFramesService } from './frames.service';
import { EqualDivisionService } from './equal-division.service';
import { ConstructionCodeService } from 'layout/construction-code.service';

export default angular
    .module('icc.configurator.layout', [])
    .factory('MullionsLayoutService', downgradeInjectable(MullionsLayoutService))
    .factory('ElementsAccessoriesFactory', ElementsAccessoriesFactory)
    .factory('ElementsFactory', ElementsFactory)
    .factory('LayoutFactory', LayoutFactory)
    .factory('FramesService', downgradeInjectable(BrowserFramesService))
    .factory('RemoveSashService', downgradeInjectable(RemoveSashService))
    .factory('SashesLayoutService', downgradeInjectable(SashesLayoutService))
    .factory('MullionsService', downgradeInjectable(MullionsService))
    .factory('AlignmentsService', downgradeInjectable(AlignmentsService))
    .factory('ExtensionsService', downgradeInjectable(ExtensionsService))
    .factory('ThresholdsService', downgradeInjectable(ThresholdsService))
    .factory('SashTypesService', downgradeInjectable(SashTypesService))
    .factory('SplitSashService', downgradeInjectable(SplitSashService))
    .factory('ResizeService', downgradeInjectable(ResizeService))
    .factory('ResizeSashService', downgradeInjectable(ResizeSashService))
    .factory('ShapeService', downgradeInjectable(ShapeService))
    .factory('EqualDivisionService', downgradeInjectable(EqualDivisionService))
    .factory('ConstructionCodeService', downgradeInjectable(ConstructionCodeService)).name;
