import angular from 'angular';

/**
 * Kontroler rolet
 * @param {object} $scope                  Angular scope
 * @param {object} $window                 Okno
 * @param {object} $rootScope              Angular Root Scope
 * @param {object} $uibModal                  Dostarcza obsługę okien modalnych
 * @param {object} PriceService            Liczenie cen
 * @param {object} ConfigurationsService   Fabryka konfiguracji
 * @param {object} RollerColorsFactory     Fabryka kolorów rolet
 * @param {object} SchemasFactory          Fabryka schematów
 * @param {object} DrivesFactory           Fabryka napedow
 * @param {object} RollerDimensionsFactory Fabryka rozmiarów rolet
 */
export default function RollerShutterCtrl(
    $scope,
    $filter,
    $window,
    $rootScope,
    $uibModal,
    PriceService,
    ConfigurationsService,
    ProfilesService,
    RollerColorsFactory,
    SchemasFactory,
    StepFactory,
    DrivesFactory,
    RollerDimensionsFactory,
    ParametersService,
    Core,
    IccConfig,
    EventBusService
) {
    'ngInject';

    var vm = this;
    vm.subStep = 1;
    vm.profiles = [];
    vm.roundReels = [];
    vm.boxSizes = [];
    vm.manuals = [];
    vm.profile = {};
    vm.roundReel = {};
    vm.systems = [];
    vm.railCutAngles = [];

    vm.openModalDrives = DrivesFactory.openModalDrives;
    vm.openModalSystems = openModalSystems;
    vm.openModalDriveElements = DrivesFactory.openModalDriveElements;
    vm.openModalDrivesManuals = DrivesFactory.openModalDrivesManuals;
    vm.onBoxHeightChange = RollerDimensionsFactory.onBoxHeightChange;
    vm.updateDimensions = RollerDimensionsFactory.updateDimensions;
    vm.updateRailsBottomCut = RollerDimensionsFactory.updateRailsBottomCut;
    vm.changeProfile = changeProfile;
    vm.changeRoundReel = changeRoundReel;
    vm.rollerStep = 'rollerschema';
    vm.changeDriveType = DrivesFactory.changeDriveType;
    vm.changeDriveManual = DrivesFactory.changeDriveManual;
    vm.driveType = DrivesFactory.driveType;
    vm.driveManualType = null;
    vm.setRoller = setRoller;
    vm.updateDimensions = RollerDimensionsFactory.updateDimensions;

    /**
     * Funkcja inicjalizacyjna
     */
    function init() {
        vm.profiles = RollerDimensionsFactory.profiles;
        vm.roundReels = RollerDimensionsFactory.roundReels;
        vm.boxSizes = RollerDimensionsFactory.boxSizes;
        vm.manuals = DrivesFactory.driveManuals;
        vm.systems = SchemasFactory.systems;
        vm.railCutAngles = RollerDimensionsFactory.railCutAngles;
        vm.driveManuals = DrivesFactory.driveManuals.reduce((prev, cur) => {
            if (!cur.type || cur.type == 'other') {
                prev.push(cur);
            } else if (
                cur.type.indexOf('retractor') > -1
                && prev.filter(p => p.type == 'retractor').length === 0
            ) {
                prev.push({
                    id: 'r',
                    price: '0',
                    type: 'retractor',
                    name: $filter('translate')('ROLLERSHUTTER|Zwijacz taśmy'),
                });
            }
            return prev;
        }, []);
        if (vm.driveManuals.length > 0) {
            vm.driveManualType = vm.driveManuals[0].id;
        }

        if (angular.isObject(ConfigurationsService.conf.Current.RollerShutter.profile)) {
            vm.profile = ConfigurationsService.conf.Current.RollerShutter.profile.id;
        }
        if (angular.isObject(ConfigurationsService.conf.Current.RollerShutter.roundReel)) {
            vm.roundReel = ConfigurationsService.conf.Current.RollerShutter.roundReel.id;
        }
        RollerDimensionsFactory.setDimensions();
    }

    if (RollerDimensionsFactory.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(
        EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init())
    );
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));
    $scope.$on('refreshedRollerHeights', init);

    vm.goToSubstep = function(subStep) {
        vm.subStep = subStep;
        tutorialSubsteps();
    };

    vm.openModalRollerColor = function(type) {
        RollerColorsFactory.openModalRollerColor(type, ConfigurationsService.conf.Current);
    };

    vm.changeDrive = function() {
        PriceService.count();
        ParametersService.count(ConfigurationsService.conf.Current);
    };

    /**
     * Funkcja zmieniajaca obraz tutoriala w zaleznosci od substepa
     */
    function tutorialSubsteps() {
        if (IccConfig.Configurators.tutorialAvailable) {
            if (vm.subStep == 2) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'rollerControl',
                });
            } else if (vm.subStep == 3) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'rollerColor',
                });
            } else if (vm.subStep == 1) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'rollerType',
                });
            }
        }
    }

    /**
     * Funkcja zmiany profilu
     * @param  {number} profile Id wybranego profilu
     */
    function changeProfile(profile) {
        RollerDimensionsFactory.changeProfile(Core.fIdO(vm.profiles, profile));
        RollerColorsFactory.init();
        EventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
    }

    /**
     * Funkcja zmiany rury nawojowej
     * @param  {number} profile Id wybranego profilu
     */
    function changeRoundReel(roundReel) {
        RollerDimensionsFactory.changeRoundReel(Core.fIdO(vm.roundReels, roundReel));
    }

    /**
     * Ustawianie rolety w korku Roleta
     */
    function setRoller() {
        ConfigurationsService.conf.Current.RollerShutter.hasAssembly = IccConfig.Configurators.roller_shutter.defaultAssemblyToWindow;
        if (ConfigurationsService.conf.Current.System.type_frame == 'monoblock') {
            if (ConfigurationsService.conf.Current.hasRoller) {
                ConfigurationsService.conf.Current.Monoblock.top =
                    IccConfig.Configurators.monoblockTopValueWithRoller;
            } else {
                ConfigurationsService.conf.Current.Monoblock.top =
                    ConfigurationsService.conf.Current.Monoblock.sides;
            }
        }
        ProfilesService.validFinWidths(ConfigurationsService.conf.Current);
        if (ConfigurationsService.conf.Current.hasRoller) {
            tutorialSubsteps();
        } else {
            if (IccConfig.Configurators.tutorialAvailable) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        }
        RollerColorsFactory.loadDefaultProfileColor();
        RollerDimensionsFactory.loadBoxHeights(undefined, true);
        vm.countPrice();
        EventBusService.post({
            key: 'icc-redraw',
            value: null,
        });
    }

    /**
     * Modal z wyborem systemu rolety
     */
    function openModalSystems() {
        SchemasFactory.openModalSystems().then(() => {
            vm.profiles = RollerDimensionsFactory.profiles;
            vm.profile = vm.profiles[0].id;
            ConfigurationsService.conf.Current.RollerShutter.profile = vm.profiles[0];
            RollerDimensionsFactory.loadBoxHeights(undefined, true);
            EventBusService.post({
                key: 'icc-redraw',
                value: 'frame',
            });
        });
    }

    vm.countPrice = function() {
        PriceService.count();
        ParametersService.count(ConfigurationsService.conf.Current);
    };

    vm.setAssembly = function() {
        vm.countPrice();
    };

    vm.changeOverride = function() {
        DrivesFactory.changeOverrideHanger();
        PriceService.count();
    };
}
