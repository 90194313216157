const SillsModalTemplate = require<string>('ngtemplate-loader!./sills-modal.component.html');
import SillsModalController from './sills-modal.controller';

export const SillsModalComponent: ng.IComponentOptions = {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
    },
    templateUrl: SillsModalTemplate,
    controller: SillsModalController,
};
