import * as angular from 'angular';

import Alternatives from './alternatives/alternatives';
import DrawModule from './draw/draw.module.ajs';
import LayoutModule from './layout/layout.module.ajs';
import StepsModule from './steps/steps.module.ajs';
import ColorsModule from './colors/colors.module.ajs';

import ConfigurationsService from 'configurations/configurations.service';
import ConfiguratorsAvailabilityService from './configurators-availability.service';
import ConfiguratorCtrl from './ConfiguratorCtrl';
import { ConfiguratorsDataService } from 'configurators/configurators-data.service';
import CurrentConfiguratorService from './current-configurator.service';
import InfoFactory from './InfoFactory';
import ModalConfirmCtrl from './ModalConfirmCtrl';
import ModalInfoCtrl from './ModalInfoCtrl';
import ModalParametersCtrl from './ModalParametersCtrl';
import ModalWarningCtrl from './ModalWarningCtrl';
import OrdersPositionsElementsModalCtrl from './OrdersPositionsElementsModalCtrl';
import ParametersService from 'configurators/parameters.service';
import ParametersModalService from './parameters-modal.service';
import PopularLayoutsFactory from './PopularLayoutsFactory';
import ProductMistakesFactory from './ProductMistakesFactory';
import { downgradeInjectable } from '@angular/upgrade/static';
import { DrawService } from 'configurators/draw.service';
import { EventBusService } from 'event-bus.service';
import { IssuesService } from 'issues.service';
import { ValidationService } from 'configurators/validation.service';
import CoupledWindowModule from './coupled/coupled-window.module.ajs';

export default angular
    .module('icc.configurators', [
        Alternatives,
        DrawModule,
        LayoutModule,
        StepsModule,
        ColorsModule,
        CoupledWindowModule,
    ])
    .controller('ConfiguratorCtrl', ConfiguratorCtrl)
    .controller('ModalConfirmCtrl', ModalConfirmCtrl)
    .controller('ModalInfoCtrl', ModalInfoCtrl)
    .controller('ModalParametersCtrl', ModalParametersCtrl)
    .controller('ModalWarningCtrl', ModalWarningCtrl)
    .controller('OrdersPositionsElementsModalCtrl', OrdersPositionsElementsModalCtrl)
    .factory('InfoFactory', InfoFactory)
    .factory('PopularLayoutsFactory', PopularLayoutsFactory)
    .factory('ProductMistakesFactory', ProductMistakesFactory)
    .factory('ParametersService', downgradeInjectable(ParametersService))
    .factory('DrawService', downgradeInjectable(DrawService))
    .factory('ConfiguratorsDataService', downgradeInjectable(ConfiguratorsDataService))
    .factory('ConfigurationsService', downgradeInjectable(ConfigurationsService))
    .factory(
        'ConfiguratorsAvailabilityService',
        downgradeInjectable(ConfiguratorsAvailabilityService)
    )
    .factory('EventBusService', downgradeInjectable(EventBusService))
    .factory('IssuesService', downgradeInjectable(IssuesService))
    .factory('ValidationService', downgradeInjectable(ValidationService))
    .service('CurConfService', CurrentConfiguratorService)
    .service('ParametersModalService', ParametersModalService).name;
