import angular from 'angular';

import SashesCtrl from './SashesCtrl';
import ModalSashTypeCtrl from './ModalSashTypeCtrl';
import ModalSashCtrl from './ModalSashCtrl';
import ModalFrameDividerCtrl from './ModalFrameDividerCtrl';
import SashesFactory from './SashesFactory';
import SashTypeFilter from './SashTypeFilter';

export default angular.module('icc.configurator.steps.window.sashes', [])
    .controller('SashesCtrl', SashesCtrl)
    .controller('ModalSashTypeCtrl', ModalSashTypeCtrl)
    .controller('ModalSashCtrl', ModalSashCtrl)
    .controller('ModalFrameDividerCtrl', ModalFrameDividerCtrl)
    .factory('SashesFactory', SashesFactory)
    .filter('sashTypeFilter', SashTypeFilter)
    .name;
