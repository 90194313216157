import 'angular-ui-bootstrap';
/**
 * Kontroler modala kolorów dodatku.
 *
 * @export
 * @class ModalPriceDetailsCtrl
 */
export default class ModalPvcColorsCtrl {

    config = {
        type: 'window',
        Colors: this.sash.selectedColor || {
            frame: {
                outer: {},
                inner: {},
                alushell: {},
            },
            sash: {
                outer: {},
                inner: {},
                alushell: {},
            }
        },
        System: {
            type: this.sash.system_types[0]
        },
        ColorType: 'White',
        Wood: this.sash.selectedWood || {},
        valid: {}
    };

    configDefault = {
        type: 'window',
        Colors: this.sash.selectedColor || {
            frame: {
                outer: {},
                inner: {},
                alushell: {},
            },
            sash: {
                outer: {},
                inner: {},
                alushell: {},
            }
        },
        System: {
            type: this.sash.system_types[0]
        },
        ColorType: 'White',
        Wood: this.sash.selectedWood || {},
        valid: {}
    };

    hasCream = false;
    setColorType(type) {
        this.ColorsFactory.setColorType(type, false, this.config, this.configDefault, this.filterColors.bind(this));

        this.config.Colors.frame.outer = this.configDefault.Colors.frame.outer = {};
        this.config.Colors.frame.inner = this.configDefault.Colors.frame.inner = {};

        this.config.Colors.sash = this.config.Colors.frame;

        this.EventBusService.post({key: 'setFillingColor', value: {
            color: this.config.Colors,
            sash: this.sash,
        }});
    }
    openModalWood() {
        this.ColorsFactory.openModalWood(this.config, this.configDefault, this.setDefaults.bind(this));
    }

    filter = (group) => true;
    filterColors = (color) => true;

    /**
     * Creates an instance of ModalPriceDetailsCtrl.
     *
     * @param {ng.ui.bootstrap.IModalServiceInstance} $uibModalInstance $uibModalInstance
     * @param {any} conf conf
     * @param {any} Core Core
     * @param {PriceDictionary} PriceDictionary PriceDictionary
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    constructor(
        private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        private sash,
        private ColorsFactory,
        private EventBusService,
        private novalidate,
    ) {
        'ngInject';

        for (let j = 0; j < ColorsFactory.windowColors.length; j++) {
            if (ColorsFactory.windowColors[j].sides.indexOf('F|C') > -1 && ColorsFactory.windowColors[j].type === 'cream') {
                this.hasCream = true;
                break;
            }
        }
        ColorsFactory.loadData();
        this.setDefaults();
            this.filter = (group) => (typeof sash.color_groups_ids !== 'undefined') && sash.color_groups_ids.map(Number).indexOf(parseInt(group.id)) > -1;
            this.filterColors = (color) => color.groups && this.sash.color_groups_ids.map(Number).some(c => color.groups.map(Number).indexOf(~~c) > -1);
    }



    setDefaults() {
        this.ColorsFactory.loadData();
        this.ColorsFactory.loadColorsBySystem(this.filterColors.bind(this));

        this.ColorsFactory.setDefaultColorTypeForSystem(this.config, this.configDefault);
        this.ColorsFactory.setDefaultWood(this.config, this.configDefault);
        this.ColorsFactory.loadColorsByWood(this.config);
        this.ColorsFactory.setDefaultColors(false, this.config, this.configDefault, this.filter.bind(this));
        this.ColorsFactory.setDefaultColorTypeForColors(this.config, this.configDefault);
    }


    /**
     * Zamyka modal.
     *
     *
     * @memberOf ModalPriceDetailsCtrl
     */
    close() {
        this.$uibModalInstance.close({colors: this.config.Colors, wood: this.config.Wood});
    }
}
