/**
 * Kontroler zamków
 * @param {object} $rootScope     Angular Root Scope
 * @param {object} $scope         Angular Scope
 * @param {object} $window        Okno
 * @param {object} LocksFactory   Fabryka zamków
 * @param {object} StepFactory    Fabryka krokow
 */
export default function LocksCtrl($rootScope, $scope, $window, LocksFactory, StepFactory, EventBusService) {
    'ngInject';

    var vm = this;
    vm.selectLock = LocksFactory.selectLock;
    vm.locks      = [];
    vm.isLastStep = false;

    /**
     * Funkcja inicjalizacyjna
     */
    function init() {
        var lastStep = StepFactory.getLastStep();

        vm.locks   = LocksFactory.locks;
        vm.isLastStep = lastStep.code === 'locks';
    }

    if (LocksFactory.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));
    $scope.$on('refreshLocksColors', init);

}
