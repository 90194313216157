/**
 * Fabryka komunikatów.
 *
 * @export
 * @param {object} $uibModal   $uibModal
 * @param {object} $q          $q
 * @param {object} $filter     $filter
 * @param {object} $rootScope  $rootScope
 * @param {object} $location   $location
 * @param {object} $timeout    $timeout
 * @return {object} Fabryka
 */
export default function InfoFactory(
    $uibModal,
    $q,
    $filter,
    $rootScope,
    $location,
    $timeout,
    $sce,
    IccConfig,
    EventBusService
) {
    // eslint-disable-line max-params
    'ngInject';

    const factory = {
        openModal: openInfoModal,
        confirmModal: openConfirmModal,
        openWarning,
        hideInfo,
        showInfo,
    };
    return factory;

    /**
     * Otwiera modal informacyjny.
     *
     * @param {string}   template Link do szablonu.
     * @param {string}   message  Komunikat
     * @param {string}   title    Tytuł
     * @param {bool}     footer   Czy wyświetlać ok.
     * @param {object}   data     Dane
     * @param {function} callback Callback
     *
     * @return {Promise} Promise
     */
    function openInfoModal({
        template,
        message,
        title,
        footer = false,
        data = {},
        ownTemplateUrl,
        callback,
    }) {
        return $q(resolve => {
            const modalInstance = $uibModal.open({
                templateUrl: ownTemplateUrl ? ownTemplateUrl : 'modalInfo.html',
                controller: 'ModalInfoCtrl as info',
                windowClass: 'alert-dimensions',
                resolve: {
                    template: () => template,
                    message: () => $sce.trustAsHtml(message),
                    title: () => title,
                    footer: () => footer,
                    data: () => data,
                },
            });

            modalInstance.closed.finally(() => {
                if (typeof callback === 'function') {
                    callback();
                }
                resolve();
            });
        });
    }

    /**
     * Otwiera ostrzeżenie.
     *
     * @param {string}   message Komunikat
     * @param {function} callback Callback
     * @return {Promise} Promise
     */
    function openWarning(message, callback) {
        return openInfoModal({
            message,
            title: $filter('translate')('INTERFACE|Ostrzeżenie'),
            callback,
        });
    }

    /**
     * Otwiera okno z pytaniem o potwierdzenie wykonania operacji
     * @param  {string}   title     Tytul okna
     * @param  {string}   question  Tresc komunikatu
     * @param  {Function} callback  Funkcja w przypadku potwierdzenia
     * @param  {Function} callback2 Funkcja w przypadku zaniechania
     */
    function openConfirmModal(title, question, options) {
        var modalInstance = $uibModal.open({
            templateUrl: 'modalConfirm.html',
            controller: 'ModalConfirmCtrl as mconfirm',
            windowClass: 'confirm-modal',
            resolve: {
                title: () => title,
                question: () => question,
                options: () => options,
            },
        });
        modalInstance.result.then(function afterConfirmModal(result) {
            result.callback();
        });
    }

    /**
     * Ukrywa info.
     */
    function hideInfo() {
        $rootScope.text = false;
        sessionStorage.removeItem('text');
    }

    /**
     * Wyświetla info.
     *
     * @param {string} text    Tekst
     * @param {string} style   Styl
     * @param {string} url     Url
     * @param {number} timeout Timeout
     */
    function showInfo(text, style, url, timeout = 9000) {
        $rootScope.text = false;

        if (typeof url === 'string') {
            $location.url(url);
        } else if (typeof url === 'function') {
            url();
        }

        $rootScope.text = text;

        if (style) {
            $rootScope.style = style;
        } else {
            $rootScope.style = 'popup-info';
        }
        if (timeout) {
            $timeout(() => {
                $rootScope.text = false;
                sessionStorage.removeItem('text');
            }, timeout);
        }
    }
}
