import angular from 'angular';
import {downgradeComponent} from '@angular/upgrade/static';

import SystemsCtrl from './SystemsCtrl';
import ModalSystemInfoCtrl from './ModalSystemInfoCtrl';
import SystemsFactory from './SystemsFactory';
import SystemsByTagFilter from './SystemsByTagFilter';
import {TagsComponent} from './tags.component';

export default angular.module('icc.configurator.steps.window.systems', [])
    .controller('SystemsCtrl', SystemsCtrl)
    .controller('ModalSystemInfoCtrl', ModalSystemInfoCtrl)
    .directive('tags', downgradeComponent({
        component: TagsComponent,
        inputs: ['iccConfig', 'tags', 'items'],
        outputs: ['filtered']
    }))
    .factory('SystemsFactory', SystemsFactory)
    .filter('systemsByTag', SystemsByTagFilter)
    .name;
