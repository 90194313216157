/**
 * Kontroler sposobu montażu do markiz
 * @param {object} $scope                   Scope
 * @param {object} AwningAssemblyFactory    Fabryka akcesoriów do markiz
 */
export default function AssemblyCtrl($scope, AwningAssemblyFactory, EventBusService) {
    'ngInject';

    var vm = this;

    vm.subStep           = 7;
    vm.mountings         = AwningAssemblyFactory.mountings;
    vm.mountingsQuantity = AwningAssemblyFactory.mountingsQuantity;
    vm.mounting          = AwningAssemblyFactory.mounting;
    vm.count             = AwningAssemblyFactory.count;
    vm.allAssembly       = true;

    vm.selectMounting    = selectMounting;

    if (AwningAssemblyFactory.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    /**
     * Funkcja inicjujaca
     */
    function init() {
        vm.mountings = AwningAssemblyFactory.mountings;
        vm.mountingsQuantity = AwningAssemblyFactory.mountingsQuantity;
        vm.mounting = AwningAssemblyFactory.mounting;
        vm.count = AwningAssemblyFactory.count;
    }

    /**
     * Wybór montażu
     * @param  {Object} mounting Montaż
     */
    function selectMounting(mounting) {
        vm.mounting = mounting;
        AwningAssemblyFactory.selectMounting(mounting, false, true);
    }

}
