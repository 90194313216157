import angular from 'angular';
import { IssueLevel } from 'issues.service';

/**
 * Fabryka rozmiarów markiz
 * @param {object} $rootScope               RootScope
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} ConfiguratorsDataService Fabryka z danymi do konfiguratorów
 * @param {object} CurConfService           Informacje o bieżącym konfiguratorze
 * @param {object} StepFactory              StepFactory
 * @param {object} PriceService             Liczenie cen
 * @param {object} $filter                  Filtry angularowe
 * @param {object} IssuesService            Obługa błędów
 * @param {object} AwningAssemblyFactory    Fabryka sposobu montażu do markiz
 */
export default function DimensionsFactory($rootScope, ConfigurationsService, ConfiguratorsDataService, CurConfService, StepFactory, PriceService, $filter, IssuesService, AwningAssemblyFactory, EventBusService) {
    'ngInject';

    var factory = {
        outreaches      : [],
        outreachesWidth : [],
        sizes           : [],
        outreach        : 0,
        width           : 0,
        loadedData      : false,
        selectSize      : selectSize,
        setDefaultValues: setDefaultValues,
        valid           : valid
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });
    $rootScope.$on('changedStep', init);

    return factory;

    /**
     * Funkcja inicjujaca
     */
    function init() {
        if (CurConfService.conf === 'awning') {
            setValues();
            if (angular.isUndefined(ConfigurationsService.conf.Current.Outreach) || angular.isUndefined(ConfigurationsService.conf.Current.Width)) {
                factory.setDefaultValues();
            } else if (ConfigurationsService.conf.Current.Outreach === 0 || ConfigurationsService.conf.Current.Width === 0) {
                factory.setDefaultValues();
            } else {
                factory.outreach = ConfigurationsService.conf.Current.Outreach;
                factory.width = ConfigurationsService.conf.Current.Width;
            }

            factory.loadedData = true;

        } else {
            return;
        }
    }

    /**
     * Ustawianie wartosci
     */
    function setValues() {

        factory.outreaches = getOutreaches();
        factory.outreachesWidth = getOutreachesWidth();
        factory.sizes = getSizes();
    }

    /**
     * Pobieranie zasięgu
     * @return {Array} Zasięti
     */
    function getOutreaches() {
        var systemID = ConfigurationsService.conf.Current.SystemId;
        if (systemID > 0) {
            return ConfiguratorsDataService.data.awningOutreach[systemID];
        } else {
            return [];
        }
    }
    /**
     * Pobieranie szerokości zasięgów
     * @return {Array} Szerokości zasięgów
     */
    function getOutreachesWidth() {
        var systemID = ConfigurationsService.conf.Current.SystemId;
        if (systemID > 0) {
            return ConfiguratorsDataService.data.awningOutreachWidth[systemID];
        } else {
            return [];
        }
    }

    /**
     * Pobieranie rozmiarów
     * @return {Array} Rozmiary
     */
    function getSizes() {
        var systemID = ConfigurationsService.conf.Current.SystemId;
        if (systemID > 0) {
            return ConfiguratorsDataService.data.awningSizes[systemID];
        } else {
            return [];
        }
    }

    /**
     * Wybór rozmiaru
     * @param  {Object} outreach Zasięg
     * @param  {Number} width    Szerokość
     * @param  {Boolean} nextStep Następny krok
     * @param  {Number} price    Cena
     */
    function selectSize(outreach, width, nextStep, price) {

        var sizes = factory.sizes[outreach];
        var i = 0;

        ConfigurationsService.conf.Current.Outreach = outreach;
        ConfigurationsService.conf.Current.Width = width;

        // ConfigurationsService.conf.Default.Outreach = outreach;
        // ConfigurationsService.conf.Default.Width = width;

        for (i = 0; i < sizes.length; i++) {
            if (sizes[i].width_from <= width && width <= sizes[i].width_to) {
                ConfigurationsService.conf.Current.SizePrice = sizes[i].price;
                // ConfigurationsService.conf.Default.SizePrice = sizes[i].price;
            }
        }

        AwningAssemblyFactory.setDefaultValues(true);

        if (nextStep) {
            StepFactory.goToNextStep(true);
        }
        if (price) {
            PriceService.count();
        }
    }

    /**
     * Ustawianie wartości domyślnych
     * @param {Bookean} reset Reset
     */
    function setDefaultValues(reset) {

        if (angular.isUndefined(ConfigurationsService.conf)) {
            return;
        }

        setValues();

        if (
            ConfigurationsService.conf.Current.Outreach === 0 ||
            ConfigurationsService.conf.Current.Width === 0 ||
            reset
        ) {
            var defO = 0;
            var i = 0;

            for (i in factory.outreaches) {
                if (defO === 0) {
                    defO = factory.outreaches[i];
                }
            }
            if (defO === 0) {
                return;
            }
            factory.outreach = defO;
            factory.width = factory.outreachesWidth[defO].width_from;

            factory.selectSize(factory.outreach, factory.width, false, true);
        }
    }

    /**
     * Walidacja
     * @param  {Number} width Szerokość
     * @return {Object}       Walidacja
     */
    function valid(width) {
        if (width < 1) {
            IssuesService.simpleRegister(
                'dimensions',
                'Podane wymiary są nieprawidłowe.',
                $filter('translate')('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                ConfigurationsService.conf.Current,
                {
                    logLevel: IssueLevel.NONE
                }
            );
            return false;
        }

        IssuesService.unregister('dimensions', ConfigurationsService.conf.Current);
        return true;
    }
}
